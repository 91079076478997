import { v4 as uuidv4 } from "uuid";

const initState = {
  queue: [],
};

const alertsReducerTypes = {
  ADD: "ADD",
  REMOVE: "REMOVE",
  REMOVE_ALL: "REMOVE_ALL",
};

const alertsReducers = (state = initState, action) => {
  switch (action.type) {
    case alertsReducerTypes.ADD:
      const newAlert = {
        id: action.payload.id,
        severity: action.payload.severity || "info",
        content: action.payload.content,
      };
      return {
        ...state,
        queue: [newAlert, ...state.queue],
      };
    case alertsReducerTypes.REMOVE:
      const newQueue = state.queue.filter((ele) => ele.id !== action.payload.id);
      return {
        ...state,
        queue: newQueue,
      };
    case alertsReducerTypes.REMOVE_ALL:
      return {
        ...state,
        queue: [],
      };
    default:
      return state;
  }
};

export const alertsActions = {
  // insert an INFO level alert
  // payload => { content }
  addInfo: (dispatch, payload) => {
    const newAlertId = uuidv4();
    dispatch({
      type: alertsReducerTypes.ADD,
      payload: { ...payload, id: newAlertId },
    });
    setTimeout(() => {
      dispatch({
        type: alertsReducerTypes.REMOVE,
        payload: { id: newAlertId },
      });
    }, 5000);
  },
  // insert an ERROR level alert
  // payload => { content }
  addError: (dispatch, payload) => {
    const newAlertId = uuidv4();
    dispatch({
      type: alertsReducerTypes.ADD,
      payload: { ...payload, id: newAlertId, severity: "error" },
    });
    setTimeout(() => {
      dispatch({
        type: alertsReducerTypes.REMOVE,
        payload: { id: newAlertId },
      });
    }, 5000);
  },
  remove: (dispatch, payload) => {
    dispatch({
      type: alertsReducerTypes.REMOVE,
      payload,
    });
  },
  removeAll: (dispatch) => {
    dispatch({
      type: alertsReducerTypes.REMOVE_ALL,
    });
  },
};

export default alertsReducers;
