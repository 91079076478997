import { useTranslation } from "react-i18next";
import i18n from "i18next";
import _ from "lodash";

const formatFallback = (fallback, key) => {
  if (fallback) return fallback;
  const fallbackOverride = _.last(_.split(key, "."));
  return fallbackOverride;
};

const Text = ({ prefix, suffix, fallback, children }) => {
  const { t } = useTranslation();
  const translateTry = t(children);
  const tranlsatedSuccess = translateTry !== children;
  const translated = tranlsatedSuccess ? translateTry : `${formatFallback(fallback, children)}`;
  return `${prefix || ""}${translated}${suffix || ""}`;
};

export const getText = (key, fallback) => {
  if (key === "fields.p_feed") return "Feed Cost (Products)";
  const translated = i18n.t(key);
  const tranlsatedSuccess = translated !== key;
  return tranlsatedSuccess ? translated : `${formatFallback(fallback, key)}`;
};

export const getGeneralText = (key, fallback) => {
  return getText(`interface.general.${key}`, fallback);
};

export const getFieldText = (key, fallback) => {
  return getText(`fields.${key}`, fallback);
};

export default Text;
