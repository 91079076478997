import { Box, Grid, Stack, Typography } from "@mui/material";
import CountryFlagSvgIcon from "components/Icons/CountryFlagSvgIcon";
import { ListIcon } from "components/Icons/MaterialIcons";
import Text from "components/text/Text";
import NumericFormatters from "helpers/NumericFormatters";
import TimeFormatters from "helpers/TimeFormatters";
import React from "react";
import WidgetCard from "ui/Card/WidgetCard";

const FeatureProfile = ({ feature }) => {
  const { properties } = feature;

  const TheRow = ({ title, content }) => {
    return (
      <Stack>
        <Typography color="grey" fontSize={10} letterSpacing={1} textTransform={"uppercase"} fontWeight={800}>
          {title}
        </Typography>
        <Typography fontSize={12} fontWeight={800}>
          {content}
        </Typography>
      </Stack>
    );
  };

  return (
    <Box p={1}>
      <Stack direction={"row"} alignItems={"center"} spacing={2} mb={1}>
        <Box>{properties.country_code ? <CountryFlagSvgIcon countryCode={properties.country_code} /> : <ListIcon />}</Box>
        <Typography fontWeight={"bold"}>{`${properties.label}`}</Typography>
      </Stack>
      <Stack spacing={0.5}>
        <TheRow title={<Text>interface.general.area</Text>} content={NumericFormatters.formatAreaInHa({ value: properties.area })} />
        <TheRow title={<Text>interface.general.group</Text>} content={properties.group} />
        <TheRow title={<Text>interface.general.country</Text>} content={properties.country} />
        <TheRow title={<Text>interface.general.location</Text>} content={`${properties.region || "-"} / ${properties.place || "-"}`} />
        <TheRow title={"lat/lng"} content={`${properties.lat}, ${properties.lng}`} />
        <TheRow title={"category/subcateogry"} content={`${properties.category || "-"} / ${properties.sub_category || "-"}`} />
        <Typography fontSize={10}>
          Created At: <strong>{TimeFormatters.formatUtcDatetime(properties.created)}</strong>
        </Typography>
        <Typography fontSize={10}>
          Updated At: <strong>{TimeFormatters.formatUtcDatetime(properties.updated)}</strong>
        </Typography>
        <Typography fontSize={10}>
          ID: <strong>{properties.id}</strong>
        </Typography>
      </Stack>
    </Box>
  );
};

export default FeatureProfile;
