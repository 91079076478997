import { PlusOneOutlined } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import { VerticalAlertBoxCollections } from "components/Alerts/AlertBox";
import ProgressBar from "components/ProgressBar/ProgressBar";
import Text from "components/text/Text";
import _ from "lodash";
import React, { useState } from "react";
import { BiPlusCircle } from "react-icons/bi";
import { AppSelectors } from "redux/AppReducers";
import CycleManagementEditPanel from "screens/Aquaculture/components/CycleManager/CycleEditPanel";
import MobileWidgetCard from "screens/Mobile/components/ui/MobileWidgetCard";

const CycleCard = ({ cycle, action }) => {
  return (
    <MobileWidgetCard title={cycle?.label} onClick={() => action(cycle)}>
      <Box>
        <Stack direction={"row"} spacing={"1"} justifyContent={"space-between"}>
          <Typography fontWeight={800} fontSize={10}>
            {cycle?.start_date}
          </Typography>
          <Typography fontWeight={800} fontSize={10}>
            {cycle?.end_date}
          </Typography>
        </Stack>
        <Box>
          <ProgressBar progress={cycle?.progress * 100 || 0} color={cycle?.ended && "grey"} />
        </Box>
      </Box>
    </MobileWidgetCard>
  );
};

const MobilePondCycleManager = ({ pond }) => {
  const [cycleToEdit, setCycleToEdit] = useState(null);
  const [cycleToCreate, setCycleToCreate] = useState(null);

  const pondManagementStore = AppSelectors.pondManagementStore();
  const { selectedFarm } = pondManagementStore;

  const cycleStore = AppSelectors.cycleStore();
  const cyclesForPond = _.orderBy(cycleStore.cycles?.[pond?.id] || [], "start_date", "desc");

  const Actions = {
    onCreateCycle: () => {
      setCycleToCreate({});
    },
    onSelectCycle: (cycle) => {
      setCycleToEdit(cycle);
    },
  };

  if (cycleToCreate) {
    return (
      <Box p={1}>
        <CycleManagementEditPanel
          cycle={cycleToCreate}
          pond={pond}
          farm={selectedFarm}
          onExit={() => {
            setCycleToCreate(null);
          }}
        />
      </Box>
    );
  }

  if (cycleToEdit) {
    return (
      <Box p={1}>
        <CycleManagementEditPanel
          cycle={cycleToEdit}
          pond={pond}
          farm={selectedFarm}
          onExit={() => {
            setCycleToEdit(null);
          }}
        />
      </Box>
    );
  }

  return (
    <Box p={1}>
      <Stack spacing={1}>
        <Stack alignItems={"center"} py={1} onClick={Actions.onCreateCycle}>
          <BiPlusCircle size={60} color="#BBB" />
        </Stack>
        {cyclesForPond.length === 0 && <VerticalAlertBoxCollections.NoCycle />}
        {cyclesForPond.map((cycle, idx) => (
          <CycleCard key={idx} cycle={cycle} action={Actions.onSelectCycle} />
        ))}
      </Stack>
    </Box>
  );
};

export default MobilePondCycleManager;
