import React, { useEffect } from "react";
import styled from "styled-components";
import { TextField, Button, Stack, IconButton, Grid } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import ReactQuill from "react-quill";

const FormWrapper = styled.form`
  display: flex;
  justify-content: center;
  margin: 0 auto;
`;

const TextWrapper = styled(TextField)`
  width: 100%;
`;

const StyledButton = styled(Button)`
  @media (max-width: 100px) {
    display: none;
  }
`;

const TextInput = ({ isFloatingMessage }) => {
  const qullModules = {
    toolbar: isFloatingMessage
      ? []
      : [
          [{ header: [1, 2, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          // ["link", "image"],
          ["clean"],
        ],
  };

  return (
    <FormWrapper noValidate autoComplete="off">
      <Grid container alignItems={"center"} spacing={1}>
        <Grid item xs>
          <ReactQuill
            modules={qullModules}
            // onChange={(v, d, s) => {
            //   console.log(v);
            // }}
          />
        </Grid>
        <Grid item width={"40px"}>
          <IconButton variant="text" color="primary">
            <SendIcon />
          </IconButton>
        </Grid>
      </Grid>

      {/* {isFloatingMessage ? (
        <TextField size="small" sx={{ width: "70%" }} />
      ) : (
        <div style={{ width: "100%", height: 50 }}>
          <div ref={quillRef} />
        </div>
      )} */}
    </FormWrapper>
  );
};

export default TextInput;
