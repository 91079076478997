import React, { Component } from "react";
import Select from "react-select";

/**
 *
 * React Selection Wrapper Component using "react-select"
 *
 * props
 * -----
 * 
 * <RcSelect
        options={
            [{label: xxxxx, value: xxxxxx}, ...]
        }
        value={{
            label: xxxxxx
            value: xxxxxx
        }}
        onChange={(e) => { <FUNC>}}
    />
 *
 *
 *
 */

class RcSelect extends Component {
  constructor(p) {
    super(p);
    this.state = {
      cnt: 0,
    };
    this.onMenuClose = this.onMenuClose.bind(this);
  }

  getValues() {
    return this.refs.ref_for_select.state.value;
  }

  setValues(nValue) {
    this.refs.ref_for_select.state.value = nValue;
  }

  onMenuClose() {
    this.setState({ cnt: this.state.cnt + 1 });
  }

  render() {
    const { cnt } = this.state;
    const isValid = (this.props.options || []).length > 0;
    return (
      <div style={{ minWidth: this.props.width || "200px" }}>
        {this.props.label ? (
          <div className="RcSelectLabel">{this.props.label}</div>
        ) : (
          ""
        )}

        <Select
          className="RcSelect"
          classNamePrefix="RcSelect"
          ref={"ref_for_select"}
          key={cnt}
          isDisabled={!isValid}
          formatGroupLabel={(data) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "5px 0",
              }}
            >
              <span
                style={{
                  fontFamily: "Open Sans",

                  color: "#333",
                }}
              >
                <i
                  className="fas fa-archive"
                  style={{
                    marginRight: "2px",
                    animation: "scaleUp .2s",
                  }}
                ></i>
                <span style={{ color: "#999" }}> | </span>
                {data.label}
              </span>
              <span style={{}}>{data.options.length}</span>
            </div>
          )}
          backspaceRemovesValue={0}
          {...this.props}
          onChange={this.props.onChange}
        />
      </div>
    );
  }
}

export default RcSelect;
