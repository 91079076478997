import React, { useState, useRef, useMemo, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import DocumentForm from "../common/DocumentForm";
import { Box, TextField, Grid, Button, IconButton, Typography, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import moment from "moment";
import { CloseIcon, FilterAltOffIcon } from "components/Icons/MaterialIcons";

const FarmDataTableView = ({ docs, FarmDocumentTypes, Actions }) => {
  const [searchTerm, setTerm] = useState("");
  const [selectBox, setSelectBox] = useState("All");
  const [filterInput, setFilterInput] = useState("");
  const gridRef = useRef();

  const columnDefsInitialState = [
    {
      flex: 1,
      field: "type",
      headerName: "Type",
    },
    {
      flex: 1,
      field: "label",
      headerName: "Label",
    },
    {
      flex: 1,
      field: "file_extension",
      headerName: "File",
    },
    {
      flex: 1,
      field: "tag",
      headerName: "Tag",
      cellRenderer: (params) => <div> {params.data?.tag ? params.data?.tag : "-"} </div>,
    },
    {
      flex: 1,
      field: "note",
      headerName: "Note",
    },
    {
      flex: 1,
      field: "created_at",
      headerName: "Created",
      cellRenderer: (params) => moment(params.data?.created_at, "YYYY-MM-DD HH:mm:ss").format("YYYY/MM/DD"),
    },
  ];

  const [columnDefs, setColumnDefs] = useState(columnDefsInitialState);
  const [selectedDoc, setSelectedDoc] = useState(null);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
    }),
    []
  );

  const cellClickedListener = useCallback((e) => {
    setSelectedDoc(e.data);
  }, []);

  const onFilterTextBoxChanged = (e) => {
    const filterText = e.target.value;
    setFilterInput(filterText);
    setTerm(filterText);
    applyFilters(filterText, selectBox);
  };

  const handleSelect = (e) => {
    const selectedType = e.target.value;
    setSelectBox(selectedType);
    applyFilters(searchTerm, selectedType);
  };

  const applyFilters = (labelFilter, typeFilter) => {
    const filteredDocs = docs.filter((doc) => {
      const labelMatch = doc.label.toLowerCase().includes(labelFilter.toLowerCase());
      const typeMatch = typeFilter === "" || typeFilter === "All" || doc.type === typeFilter;
      return labelMatch && typeMatch;
    });

    gridRef.current.api.setRowData(filteredDocs);
  };

  const handleFormClose = () => setSelectedDoc(null);
  const handleFilterClear = () => {
    setTerm("");
    setSelectBox("All");
    setFilterInput("");
    gridRef.current.api.setRowData(docs);
  };

  return (
    <Grid
      container
      sx={{
        position: "relative",
        height: "calc(100vh - 340px)",
      }}
    >
      <Grid my={2} item xs={12} spacing={1} container>
        <Grid item xs={4}>
          <Box>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Filter by Type</InputLabel>
              <Select labelId="demo-simple-select-label" id="demo-simple-select" value={selectBox} multiline fullWidth onChange={handleSelect} label="Filter by Type">
                <MenuItem value={"All"}>All</MenuItem>
                {Object.values(FarmDocumentTypes).map((val, i) => (
                  <MenuItem key={i} value={val.id}>
                    {val.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box>
            <TextField size="small" id="outlined-multiline-flexible" label="Filter by label" value={filterInput} multiline fullWidth onChange={onFilterTextBoxChanged} />
          </Box>
        </Grid>

        <Grid item xs={2}>
          <Box>
            <IconButton onClick={handleFilterClear}>
              <FilterAltOffIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          border: "1px solid #eee",
          height: "100%",
        }}
        container
      >
        <Grid
          item
          xs={selectedDoc ? 6 : 12}
          sx={{
            height: "100%",
          }}
        >
          <Box
            className="ag-theme-shrimpl"
            sx={{
              height: "100%",
            }}
          >
            <AgGridReact
              ref={gridRef}
              rowData={docs}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              animateRows={false}
              rowSelection="multiple"
              onCellClicked={cellClickedListener}
              rowHeight={30}
              headerHeight={30}
            />
          </Box>
        </Grid>
        {selectedDoc && (
          <Grid
            item
            xs={selectedDoc ? 6 : 0}
            sx={{
              height: "100%",
              overflow: "auto",
            }}
          >
            <Box p={3}>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <IconButton onClick={handleFormClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <DocumentForm
                docId={selectedDoc.id}
                document={selectedDoc}
                documentTypes={_.values(FarmDocumentTypes)}
                onSubmit={Actions.submitUpdateForm}
                downloadDocumentUrl={Actions.downloadDocumentUrl}
                key={selectedDoc.id}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default FarmDataTableView;
