import { Box, Stack, Typography, colors } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import palette from "themes/palette";

const MobileAppButton = ({ name, path, faClass, icon }) => {
  return (
    <Box
      sx={{
        a: {
          color: "unset",
        },
      }}
    >
      <NavLink to={path}>
        <Stack
          direction={"row"}
          sx={{
            p: 1,
            px: 2,
            borderRadius: 4,
            bgcolor: "#FFF",
            ":active": {
              transform: "translateY(2px)",
              bgcolor: "#FFF",
            },
            overflow: "hidden",
          }}
          spacing={1}
          justifyContent={"space-between"}
          alignItems={"flex-end"}
          mt={1}
        >
          <Box p={1}>
            <Box
              sx={{
                "svg, i": {
                  fontSize: 30,
                  transform: "translateY(1px)",
                  color: colors.grey[300],
                },
              }}
            >
              {icon || <i className={faClass || "fal fa-window"}></i>}
            </Box>
          </Box>
          <Box>
            <Typography fontWeight={800} fontSize={16} color={palette.primary.main}>
              {name}
            </Typography>
          </Box>
        </Stack>
      </NavLink>
    </Box>
  );
};

export default MobileAppButton;
