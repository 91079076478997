import { Box, Divider, IconButton, Stack, Typography, colors } from "@mui/material";
import { AddIcon, ArrowBackIcon, ArrowForwardIcon, RefreshIcon, RemoveIcon } from "components/Icons/MaterialIcons";
import { MuiBorderIconButton } from "components/buttons/MuiButtonVariants";
import Formatters from "helpers/Formatters";
import NumericFormatters from "helpers/NumericFormatters";
import _ from "lodash";
import PondResultCharts from "screens/Aquaculture/components/ScenarioBuilder/components/PondResultCharts";
import PondResultsTable from "screens/Aquaculture/components/ScenarioBuilder/components/PondResultsTable";
import MiniLabelAndValueCard from "ui/Card/MiniLabelAndValueCard";

const PondResults = ({ pondId, ponds, pondsResults, pondsStats, actions }) => {
  const selectedPond = _.find(ponds, { id: pondId });
  const selectedPondData = _.find(pondsResults, { p__id: pondId });
  const plannedHarvestDateOffset = selectedPondData?.stats?.plannedHarvestDocOffset;

  const tableRows = pondsResults;

  const FarmStatusBar = () => {
    return (
      <Box mt={2} border="1px solid #EEE" p={0.5} borderRadius={1}>
        <Typography fontSize={12} sx={{ px: 1 }}>
          <strong>Farm Summary</strong>
        </Typography>
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <MiniLabelAndValueCard label="OH. Profit" value={Formatters.formatDollar({ value: pondsStats?.optimalHarvestNetProfit })} />
          <MiniLabelAndValueCard
            label="PH. Profit"
            value={NumericFormatters.formatDollarValueWithDifference({ value: pondsStats?.plannedHarvestNetProfit, diff: pondsStats?.plannedHarvestNetProfitDiff })}
          />
          <MiniLabelAndValueCard label="OH. Cost" value={Formatters.formatDollar({ value: pondsStats?.optimalHarvestCost })} />
          <MiniLabelAndValueCard label="PH. Cost" value={NumericFormatters.formatDollarValueWithDifference({ value: pondsStats?.plannedHarvestCost, diff: pondsStats?.plannedHarvestCostDiff })} />
        </Stack>
        {/* <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <MuiBorderIconButton icon={<RemoveIcon />} />
            <MuiBorderIconButton icon={<AddIcon />} />
            <MuiBorderIconButton icon={<RefreshIcon />} />
          </Stack>
        </Stack> */}
      </Box>
    );
  };

  const PondStatusBar = () => {
    if (!pondId)
      return (
        <Box my={1} border="1px solid #EEE" p={1} borderRadius={1}>
          <Typography>Select a pond below:</Typography>
        </Box>
      );
    return (
      <Box my={1} border="1px solid #EEE" p={1} borderRadius={1}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Stack direction={"row"} spacing={1} alignItems={"center"} divider={<Divider orientation="vertical" flexItem />}>
            <Box>
              <Typography fontSize={12}>
                Pd: <strong>{selectedPond?.label}</strong>
              </Typography>
            </Box>
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <Box>
                <Typography fontSize={12}>Adjust Harvest DoC:</Typography>
              </Box>
              <MuiBorderIconButton
                onClick={() =>
                  actions.onUpdatePondOverrideValues({
                    key: "harvestOffset",
                    value: plannedHarvestDateOffset === undefined ? -1 : plannedHarvestDateOffset - 1,
                  })
                }
                icon={<RemoveIcon />}
                size="sm"
              />
              <MuiBorderIconButton
                onClick={() =>
                  actions.onUpdatePondOverrideValues({
                    key: "harvestOffset",
                    value: plannedHarvestDateOffset === undefined ? 1 : plannedHarvestDateOffset + 1,
                  })
                }
                icon={<AddIcon />}
                size="sm"
              />
              <MuiBorderIconButton
                onClick={() =>
                  actions.onUpdatePondOverrideValues({
                    key: "harvestOffset",
                    isReset: true,
                  })
                }
                icon={<RefreshIcon />}
                size="sm"
              />
            </Stack>
            <Box>
              <Typography fontSize={12}>
                Harvest DoC: <strong>{selectedPondData?.stats?.optimalHarvestDoc}</strong>
              </Typography>
              <Typography fontSize={12} color={plannedHarvestDateOffset !== 0 ? colors.orange[600] : ""}>
                <strong>{selectedPondData?.stats?.plannedHarvestDoc}</strong>{" "}
                <span>
                  <strong>{plannedHarvestDateOffset ? `(${plannedHarvestDateOffset > 0 ? "+" : ""}${plannedHarvestDateOffset}d)` : ""}</strong>
                </span>
              </Typography>
            </Box>
            <Box>
              <Typography fontSize={12}>
                Profit: <strong>{Formatters.formatDollar({ value: selectedPondData?.stats?.optimalHarvestNetProfit })}</strong>
              </Typography>
              <Typography fontSize={12}>
                <strong>
                  {NumericFormatters.formatDollarValueWithDifference({ value: selectedPondData?.stats?.plannedHarvestNetProfit, diff: selectedPondData?.stats?.plannedHarvestNetProfitDiff })}
                </strong>
              </Typography>
            </Box>
            <Box>
              <Typography fontSize={12}>
                Cost: <strong>{Formatters.formatDollar({ value: selectedPondData?.stats?.optimalHarvestCost })}</strong>
              </Typography>
              <Typography fontSize={12}>
                <strong>{NumericFormatters.formatDollarValueWithDifference({ value: selectedPondData?.stats?.plannedHarvestCost, diff: selectedPondData?.stats?.plannedHarvestCostDiff })}</strong>
              </Typography>
            </Box>
          </Stack>
          <Stack direction={"row"} spacing={1} divider={<Divider orientation="vertical" flexItem />} alignItems={"center"}></Stack>
        </Stack>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        p: 2,
      }}
    >
      <Box mt={2}>
        <FarmStatusBar />
        <PondStatusBar />
        <PondResultsTable
          pondId={pondId}
          tableRows={tableRows}
          actions={{
            onSelectPondId: actions.onSelectPondId,
          }}
        />
      </Box>
      <Box mt={2}>{pondId && <PondResultCharts pondId={pondId} pondsResults={pondsResults} />}</Box>
    </Box>
  );
};

export default PondResults;
