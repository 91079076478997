import CentralLogoImg_ from "img/intelligon-logo-no-bg.png";
import DarkNoLabelLogoImg_ from "img/intelligon-favicon-grey.png";
import MapPinIcon from "img/map-pin-icon.png";
import ShrimplIconOnlyGrey from "assets/Shrimpl/Production/Shrimpl_Digital_Icon_Grey.svg";
import ShrimplIconOnly from "assets/Shrimpl/Production/Shrimpl_Digital_Icon_Coral.svg";
import ShrimplLogo from "assets/Shrimpl/Production/Shrimpl_Digital_Primarylogo_Navy_RGB.svg";
import ShrimplLogoWhite from "assets/Shrimpl/Production/Shrimpl_Digital_Primarylogo_White_RGB.svg";
import PondDrawingGuide from "img/pond_draw_quickguide.gif";
import ShrimplImagePlaceholer from "assets/Shrimpl/ImagePlaceholderLight.png";
import NoMessageFoundSvg_ from "img/No_Message_found.svg";

import SatEyeGreyPng from "img/SATEYE_grey.png";
import SatEyeBlackPng from "img/SATEYE_black.png";

export const CentralLogoImg = CentralLogoImg_;
export const IntelligonLogoImge = CentralLogoImg_;
export const DarkNoLabelLogoImg = DarkNoLabelLogoImg_;
export const MapPinIconImg = MapPinIcon;
export const ShrimplIconOnlySvg = ShrimplIconOnly;
export const ShrimplIconOnlyGreySvg = ShrimplIconOnlyGrey;
export const ShrimplLogoSvg = ShrimplLogo;
export const ShrimplLogoWhiteSvg = ShrimplLogoWhite;
export const PondDrawingGuideGif = PondDrawingGuide;
export const ShrimplImagePlaceholerImg = ShrimplImagePlaceholer;
export const NoMessageFoundSvg = NoMessageFoundSvg_;
export const SatEyeLogoGrey = SatEyeGreyPng;
export const SatEyeLogoBlack = SatEyeBlackPng;
