import styled from "styled-components";
import Avatar from "@mui/material/Avatar";
import { deepOrange } from "@mui/material/colors";
import { Badge } from "@mui/material";

const LeftAvatar = ({ displayName, photoURL, badgeCount }) => {
  const ShrimplLeftAvatar = styled(Avatar)`
    width: 32px;
    height: 32px;
    background-color: ${deepOrange[500]};
  `;
  return badgeCount ? (
    <Badge badgeContent={badgeCount} color="primary">
      <ShrimplLeftAvatar displayName={displayName} photoURL={photoURL} />
    </Badge>
  ) : (
    <ShrimplLeftAvatar displayName={displayName} photoURL={photoURL} />
  );
};

export default LeftAvatar;
