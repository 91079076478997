import React, { Component } from "react";
import styled from "styled-components";
import { DATAINDEX } from "../../index/DataIndices";
import WaterMarketChart from "./components/WaterMarketChart";
import { Card, CardContent, Grid, MenuItem, Select } from "@mui/material";
import WidgetCard from "ui/Card/WidgetCard";

const Layout = styled.div`
  padding: 12px;
  .chart_grid {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  @media (max-width: 900px) {
    .chart_grid {
      grid-template-columns: 100%;
    }
  }

  @media (min-width: 1920px) {
    .chart_grid {
      grid-template-columns: 33% 33% 33%;
    }
  }

  .select_bar {
    font-family: var(--main-font);
    margin: 20px 0px;
  }

  .Select-control {
    border-radius: 0;
  }

  .chartWrapper {
    background: #fff;
    margin: 10px;
    padding: 10px 30px;
    border-radius: var(--radius-normal);
    box-shadow: var(--boxshadow-widget);
  }
`;

const IDX = DATAINDEX.water;

const options = [
  { label: "Temporary Allocation (VIC)", value: "allc_vic" },
  { label: "Temporary Allocation (NSW)", value: "allc_nsw" },
  { label: "Temporary Allocation (SA)", value: "allc_sa" },
  { label: "Permanent Entitlement (High Reliability)", value: "ent_h" },
  { label: "Permanent Entitlement (Low/General Reliability)", value: "ent_l" },
];

class WaterMarketDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      marketType: "allc_vic",
      selectedOption: options[0]["value"],
    };
    this.handleOptionChange = this.handleOptionChange.bind(this);
  }

  handleOptionChange(option) {
    if (option !== null) {
      if (this.state.selectedOption !== option) {
        this.setState({
          selectedOption: option,
          marketType: option,
        });
      }
    }
  }

  render() {
    let selectedIndex = IDX.filter((element) => {
      return element.subType === this.state.marketType;
    });

    return (
      <Layout>
        <div className="select_bar">
          <Select value={this.state.selectedOption} onChange={(e) => this.handleOptionChange(e.target.value)} fullWidth>
            {options.map((ele) => (
              <MenuItem value={ele.value}>{ele.label}</MenuItem>
            ))}
          </Select>
        </div>
        <Grid container spacing={2}>
          {selectedIndex.map((item, idx) => {
            return (
              <Grid item xs={12} md={6}>
                <WidgetCard title={item.name}>
                  <WaterMarketChart key={item.id} zoneName={item.name} zoneId={item.id} marketType={item.type} marketSubType={item.subType} />
                </WidgetCard>
              </Grid>
            );
          })}
        </Grid>
      </Layout>
    );
  }
}

export default WaterMarketDashboard;
