import React, { useEffect, useRef, useState } from "react";
import { AdminManager } from "api/api";
import { Box, Button, ButtonGroup, Card, CardContent, CardHeader, Chip, colors, FormControl, InputLabel, LinearProgress, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";

import _ from "lodash";
import FieldEditModal from "screens/Admin/AquaCulture/components/AquaFieldEditModal";
import { AddCircleOutlineIcon, FileCopyIcon } from "components/Icons/MaterialIcons";
import { RefreshIcon } from "components/Icons/MaterialIcons";
import { EditIcon } from "components/Icons/MaterialIcons";
import LoadingBox from "components/Loading/LoadingBox";
import { AgGridReact } from "ag-grid-react";
import { MuiBorderIconButton } from "components/buttons/MuiButtonVariants";
import { useDispatch } from "react-redux";
import { dialogReducerActions } from "redux/dialog";
import { alertsActions } from "redux/alerts";
import WidgetCard from "ui/Card/WidgetCard";

const FIELD_DATA_TEMPLATE = {
  field_id: "",
  field_name: "",
  field_group: "",
  field_unit: "",
  description: "",
};

const AquaFieldsManager = () => {
  const ref = useRef();

  const dispatch = useDispatch();

  const [fields, setFields] = useState([]);
  const [selectedFieldData, setFieldData] = useState(null);
  const [filters, setFilters] = useState({
    is_active: true,
    field_group: "",
    field_type: "",
  });
  const [searchTerm, setSearchTerm] = useState(null);
  const [colDef, setColDef] = useState([
    {
      headerName: "field_id",
      cellRenderer: ({ data }) => (
        <Stack direction={"row"} spacing={0.3}>
          <MuiBorderIconButton size={"sm"} onClick={() => setFieldData(data)} icon={<EditIcon />} />
          <MuiBorderIconButton size={"sm"} onClick={() => Actions.onCloneField(data)} icon={<FileCopyIcon />} />
        </Stack>
      ),
      pinned: "left",
      width: 90,
      cellStyle: { display: "flex", alignItems: "center", justifyContent: "center" },
    },
    {
      headerName: "Field ID",
      field: "field_id",
      pinned: "left",
    },
    {
      headerName: "Field Name",
      field: "field_name",
      editable: true,
    },
    {
      headerName: "Field Unit",
      field: "field_unit",
      editable: true,
    },
    {
      headerName: "Field Level",
      field: "field_type",
      editable: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["pond", "farm"],
      },
      cellRenderer: ({ value }) => {
        const colorSet =
          {
            pond: colors.green,
            farm: colors.orange,
          }?.[value] || colors.grey;
        return (
          <Box bgcolor={colorSet[50]} px={0.4} py={0.1}>
            <Typography color={colorSet[600]} fontWeight={800} fontSize={11}>
              {value}
            </Typography>
          </Box>
        );
      },
    },
    {
      headerName: "Group",
      field: "field_group",
      editable: true,
      cellRenderer: ({ value }) => {
        if (!value) return "";
        return (
          <Box bgcolor={colors.blue[50]} px={0.4} py={0.1}>
            <Typography color={colors.blue[600]} fontWeight={800} fontSize={11}>
              # {value}
            </Typography>
          </Box>
        );
      },
    },
    {
      headerName: "Sub Group",
      field: "field_sub_group",
      editable: true,
      cellRenderer: ({ value }) => {
        if (!value) return "";
        return (
          <Box bgcolor={colors.teal[50]} px={0.4} py={0.1}>
            <Typography color={colors.teal[600]} fontWeight={800} fontSize={11}>
              # {value}
            </Typography>
          </Box>
        );
      },
    },
    {
      headerName: "Is Derived?",
      field: "is_derived",
      editable: true,
      width: 130,
    },
    {
      headerName: "Enabled",
      field: "is_active",
      editable: true,
      width: 130,
    },
    {
      headerName: "Bulk Upload Enabled",
      field: "bulk_upload_enabled",
      editable: true,
      width: 130,
    },
    {
      headerName: "Display Pos",
      field: "position",
      editable: true,
    },
  ]);
  const [loading, setloading] = useState(true);

  const editModalOpen = selectedFieldData !== null;

  const initData = async () => {
    setloading(true);
    const result = await AdminManager.getFields();
    setFields(result);
    setloading(false);
  };

  useEffect(() => {
    initData();
  }, []);

  // handle quick filter
  useEffect(() => {
    ref?.current?.api?.setGridOption("quickFilterText", searchTerm);
  }, [searchTerm]);

  const handleDisableField = (fieldId) => {
    const confirmed = window.confirm(`Do you want to DISABLE field ${fieldId}?`);
    confirmed &&
      AdminManager.disableField(fieldId).then((res) => {
        initData();
      });
  };

  const handleEnableField = (fieldId) => {
    const confirmed = window.confirm(`Do you want to ENABLE field ${fieldId}?`);
    confirmed &&
      AdminManager.enableField(fieldId).then((res) => {
        initData();
      });
  };

  const handleFieldUploadEnabled = (fieldId, enabled) => {
    const confirmed = window.confirm(`Do you want to ${enabled ? "enable" : "disable"} field ${fieldId} for uploading?`);
    if (confirmed) {
      if (enabled) {
        AdminManager.enableFieldUpload(fieldId).then((res) => {
          initData();
        });
      } else {
        AdminManager.disableFieldUpload(fieldId).then((res) => {
          initData();
        });
      }
    }
  };

  const Actions = {
    onEditField: async (field) => {
      await AdminManager.createOrUpdateField(field);
      alertsActions.addInfo(dispatch, { content: "success" });
    },
    onCloneField: (field) => {
      setFieldData({
        field_type: field.field_type,
        field_group: field.field_group,
        field_sub_group: field.field_sub_group,
        position: 999,
      });
    },
    onCellEditingStopped: (e) => {
      if (!e.valueChanged) return;
      const editCellColumn = e.column.colId;
      const cellData = e.data;
      Actions.onEditField({
        [editCellColumn]: cellData[editCellColumn],
        field_id: cellData.field_id,
      });
    },
  };

  /** options for filtesr */
  const optionsForActive = [
    {
      label: "ALL",
      value: "",
    },
    {
      label: "Enabled",
      value: true,
    },
    {
      label: "Disabled",
      value: false,
    },
  ];
  const optionsForFieldTypes = [
    {
      label: "ALL",
      value: "",
    },
    {
      label: "Pond",
      value: "pond",
    },
    {
      label: "Farm",
      value: "farm",
    },
  ];
  const optionsForFieldGroups = [
    {
      label: "ALL",
      value: "",
    },
    ..._.unionBy(
      fields
        .map((ele) => ele.field_group)
        .filter((ele) => ele)
        .sort()
    ).map((f) => ({
      label: f,
      value: f,
    })),
  ];

  const filteredFields = fields
    .filter((f) => (filters.is_active !== "" ? f.is_active === filters.is_active : true))
    .filter((f) => (filters.field_type !== "" ? f.field_type === filters.field_type : true))
    .filter((f) => (filters.field_group !== "" ? f.field_group === filters.field_group : true));

  const rows_to_display = _.orderBy(filteredFields, ["field_group", "field_sub_group", "is_active", "position", "field_id"], ["asc", "asc", "desc", "asc", "asc"]);

  const handleCreateNewField = () => {
    setFieldData(FIELD_DATA_TEMPLATE);
  };

  const fieldGroups = _.unionBy(
    fields
      .map((ele) => ele.field_group)
      .filter((ele) => ele)
      .sort()
  );
  const fieldUnits = _.unionBy(
    fields
      .map((ele) => ele.field_unit)
      .filter((ele) => ele)
      .sort()
  );

  // if (loading) {
  //   return (
  //     <Box p={2}>
  //       <Card p={2}>
  //         <CardContent>
  //           <LoadingBox />
  //         </CardContent>
  //       </Card>
  //     </Box>
  //   );
  // }

  return (
    <Box p={2}>
      <FieldEditModal
        open={editModalOpen}
        fieldData={selectedFieldData}
        handleClose={(refresh) => {
          setFieldData(null);
          if (refresh) initData();
        }}
        fieldGroups={fieldGroups}
        fieldUnits={fieldUnits}
      />
      <WidgetCard title="Field Manager">
        {loading ? (
          <LoadingBox />
        ) : (
          <Box>
            <Stack direction="row" alignItems={"center"} justifyContent={"space-between"}>
              <Stack direction="row" spacing={1}>
                <Button variant="outlined" onClick={handleCreateNewField} startIcon={<AddCircleOutlineIcon />}>
                  New Field
                </Button>
                <Button variant="outlined" onClick={initData} startIcon={<RefreshIcon />}>
                  Refresh
                </Button>
              </Stack>
              <Stack direction="row" alignItems={"center"} justifyContent={"space-between"} spacing={1}>
                <FormControl sx={{ minWidth: "180px" }} size="small">
                  <InputLabel id="enabled-select">Filter Enabled</InputLabel>
                  <Select
                    labelId="enabled-select"
                    value={filters.is_active}
                    placeholder="--ALL--"
                    label="Filter Enabled"
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        is_active: e.target.value,
                      })
                    }
                  >
                    {optionsForActive.map((o) => (
                      <MenuItem key={o.value} value={o.value}>
                        {o.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ minWidth: "180px" }} size="small">
                  <InputLabel id="type-select">Filter Type</InputLabel>
                  <Select
                    labelId="type-select"
                    value={filters.field_type}
                    placeholder="--ALL--"
                    label="Filter Type"
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        field_type: e.target.value,
                      })
                    }
                  >
                    {optionsForFieldTypes.map((o) => (
                      <MenuItem key={o.value} value={o.value}>
                        {o.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ minWidth: "180px" }} size="small">
                  <InputLabel id="group-select">Filter Group</InputLabel>
                  <Select
                    labelId="group-select"
                    value={filters.field_group}
                    placeholder="--ALL--"
                    label="Filter Group"
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        field_group: e.target.value,
                      })
                    }
                  >
                    {optionsForFieldGroups.map((o) => (
                      <MenuItem key={o.value} value={o.value}>
                        {o.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </Stack>

            <Box className="ag-theme-excel" height={"calc(100vh - 300px)"} mt={2} mb={5}>
              <Box mb={1}>
                <TextField
                  variant="standard"
                  size="small"
                  label="Quick Filter:"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  sx={{
                    width: 300,
                  }}
                ></TextField>
              </Box>
              <AgGridReact
                defaultColDef={{
                  cellStyle: { display: "flex", alignItems: "center" },
                }}
                animateRows={false}
                ref={ref}
                rowHeight={30}
                columnDefs={colDef}
                rowData={rows_to_display}
                onCellEditingStopped={Actions.onCellEditingStopped}
              />
            </Box>
          </Box>
        )}
      </WidgetCard>
    </Box>
  );
};

export default AquaFieldsManager;
