import { Box, IconButton, Modal, Stack, Typography, alpha, colors } from "@mui/material";
import { CloseIcon } from "components/Icons/MaterialIcons";
import { useDispatch } from "react-redux";
import { AppSelectors } from "redux/AppReducers";
import { modalReducersActions } from "redux/modal";
import PropTypes from "prop-types";

export const CommonModalSizes = {
  normal: "normal",
  large: "large",
};

const sizes = {
  [CommonModalSizes.normal]: {
    maxWidth: "600px",
    maxHeight: "90vh",
  },
  [CommonModalSizes.large]: {
    maxWidth: "90vw",
    width: "80vw",
    height: "80vh",
  },
};

const CommonModal = ({ id, title, size, nobg, children }) => {
  const modalStore = AppSelectors.modalStore();
  const dispatch = useDispatch();
  return (
    <Modal
      open={modalStore.opens?.[id]}
      onClose={() => modalReducersActions.closeModal(dispatch, { id })}
      sx={{
        width: "100%",
        bgcolor: alpha(colors.grey[900], 0.9),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      slotProps={{
        backdrop: {
          sx: {
            //Your style here....
            backgroundColor: "rgba(0, 0, 0, 0.004)",
          },
        },
      }}
    >
      <Box
        sx={{
          maxWidth: "600px",
          maxHeight: "90vh",
          overflowY: "auto",
          bgcolor: nobg ? "transparent" : colors.common.white,
          borderRadius: 3,
          ...sizes[size],
        }}
        p={4}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          flexDirection={"row-reverse"}
          sx={{
            position: "relative",
          }}
        >
          <IconButton
            size="medium"
            onClick={() => modalReducersActions.closeModal(dispatch, { id })}
            sx={{
              color: nobg ? "white" : "primary",
              bgcolor: nobg && colors.grey[800],
              position: "absolute",
              right: 0,
              top: 0,
              zIndex: 999,
            }}
          >
            <CloseIcon />
          </IconButton>
          {title && (
            <Box
              sx={{
                position: "absolute",
                left: 0,
                top: 0,
                zIndex: 999,
              }}
            >
              <Typography fontSize={16} fontWeight={800}>
                {title}
              </Typography>
            </Box>
          )}
        </Stack>

        {children}
      </Box>
    </Modal>
  );
};

CommonModal.propTypes = {
  id: PropTypes.string.isRequired,
};

export default CommonModal;
