import { Box, Button, IconButton, LinearProgress, Stack, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import { GOOGLE_MAP_KEY } from "secrets";
import _ from "lodash";
import { AddIcon, AdminPanelSettingsIcon, DashboardIcon } from "components/Icons/MaterialIcons";
import { commonInitMaps } from "components/map/googlemapHelper";
import { PondManagerServices } from "api/pondManagerServices";
import FeatureCard from "screens/Aquaculture/AquaExplorer/components/FeatureCard";
import { ShrimplLogoWhiteSvg } from "components/Images";
import { Fade } from "react-reveal";
import FeatureAdd from "screens/Aquaculture/AquaExplorer/components/FeatureAdd";
import { useDispatch } from "react-redux";
import { alertsActions } from "redux/alerts";
import { getText } from "components/text/Text";
import MapStatsBar from "screens/Aquaculture/AquaExplorer/components/MapStatsBar";
import { AppSelectors } from "redux/AppReducers";
import AppLocalStorage from "helpers/LocalStorage";
import AquaExplorerDashboard from "screens/Aquaculture/AquaExplorer/components/AquaExplorerDashboard";
import WidgetCard from "ui/Card/WidgetCard";
import NumericFormatters from "helpers/NumericFormatters";
import AoiCard from "screens/Aquaculture/AquaExplorer/components/AoiCard";
import { MotionPhotosAuto, Settings } from "@mui/icons-material";

const createMapOptions = (maps) => {
  return {
    fullscreenControlOptions: {
      position: maps.ControlPosition.BOTTOM_RIGHT,
    },
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_BOTTOM,
    },
    mapTypeId: maps.MapTypeId.HYBRID,
    minZoom: 5,
  };
};

const shapeStyles = {
  normal: {
    fillColor: "#00b7ff",
    fillOpacity: 0.4,
    strokeColor: "#e0f6ff",
    strokeWeight: 1,
  },
  selected: {
    fillColor: "#ffffff",
    fillOpacity: 0.7,
    strokeColor: "#ffffff",
    strokeWeight: 1,
  },
  aoi_pending: {
    fillColor: "#5e5e5e",
    fillOpacity: 0.4,
    strokeColor: "#ffffff",
    strokeWeight: 3,
  },
};

const MapTooltip = ({ feature }) => {
  return (
    <Box
      sx={{
        zIndex: 999,
        bgcolor: "#FFF",
        position: "absolute",
        transform: "translate(-50%, -130%)",
        borderRadius: 1,
        p: 1,
        minWidth: 200,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: "2px",
          height: 30,
          background: "#ffffff",
          left: "50%",
          top: "80%",
          zIndex: 998,
        }}
      ></Box>
      <Box
        sx={{
          position: "absolute",
          width: "8px",
          height: "8px",
          background: "#ffffff",
          left: "48.5%",
          top: "128%",
          borderRadius: 3,
          zIndex: 998,
        }}
      ></Box>
      <Typography fontSize={10}>{feature.label}</Typography>
      <Typography fontSize={20} fontWeight={800}>
        {NumericFormatters.formatAreaInHa({ value: feature.area })}
      </Typography>
    </Box>
  );
};

const AquaExplorer = () => {
  const localStore = new AppLocalStorage();
  const lastViewedZoom = Number.parseInt(localStore.get("AQEXP-LAST-ZOOM"));
  const lastViewedCenter = localStore.getAsJson("AQEXP-LAST-CENTER");
  const saveLastViewedZoom = (zoom) => localStore.save("AQEXP-LAST-ZOOM", zoom);
  const saveLastViewedCenter = (center) => localStore.saveJson("AQEXP-LAST-CENTER", center);

  const [data, setData] = useState(null);
  const [mapCenter, setMapCenter] = useState(lastViewedCenter || [-2.585671742540449, -80.01776784245965]);
  const [mapZoom, setMapZoom] = useState(lastViewedZoom || 13);
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  const [loading, setLoading] = useState(false);
  // map interaction
  const [selectedFeature, setFeature] = useState(null);
  const [hoveredFeature, setHoveredFeature] = useState(null);
  const [showPondAdd, setShowPondAdd] = useState(false);
  const [showDashboard, setShowDashboard] = useState(false);
  const [aoi, setAoi] = useState(null);
  const [hoveredAoi, setHoveredAoi] = useState(null);

  const [query, setQuery] = useState({});

  const mapRef = useRef(null);

  const userStore = AppSelectors.userStore();
  const isUserInternal = userStore.isInternal;

  const dispatch = useDispatch();

  const visibility = {
    edit: userStore.isInternal,
  };

  const handleApiLoaded = async (map, maps) => {
    commonInitMaps(maps);
    setMap(map);
    setMaps(maps);
    // first load
    const bounds = map.getBounds().toJSON();
    const bbox = [bounds.west, bounds.south, bounds.east, bounds.north];
    const zoom = map.getZoom();
    const center = [map.getCenter().lat(), map.getCenter().lng()];
    setQuery({
      bbox: bbox,
      zoom: zoom,
      center: center,
    });
  };

  useEffect(() => {
    if (!_.isEmpty(query)) {
      const handler = setTimeout(() => {
        Actions.loadData();
      }, 300);
      return () => {
        clearTimeout(handler);
      };
    }
  }, [query]);

  const Actions = {
    clearMapData: async () => {
      if (map) {
        await map.data.forEach((ft) => {
          map.data.remove(ft);
        });
        maps.event.clearListeners(map.data, "click");
        maps.event.clearListeners(map.data, "mouseover");
        maps.event.clearListeners(map.data, "mouseout");
      }
    },
    handleMapChange: (e) => {
      if (map) {
        const bounds = map.getBounds().toJSON();
        const bbox = [bounds.west, bounds.south, bounds.east, bounds.north];
        const zoom = map.getZoom();
        const center = [map.getCenter().lat(), map.getCenter().lng()];
        setQuery({
          bbox: bbox,
          zoom: zoom,
          center: center,
        });
        // save view history
        saveLastViewedZoom(zoom);
        saveLastViewedCenter(center);
      }
    },
    loadData: async () => {
      if (_.isEmpty(query)) return;
      if (!map || !maps) return;
      setLoading(true);

      // Remove all data
      Actions.clearMapData();

      const result = await PondManagerServices.fetchAquaExplorerResult(query);

      // render to map
      const d = map.data.addGeoJson(result);
      // now loop d to take some actions
      d.forEach((ft) => {
        // override style
        const water_clf = ft.getProperty("dt_water_clf");
        let style = null;
        if (!water_clf) {
          style = shapeStyles.normal;
        } else {
          if (water_clf === 0) {
            style = {
              fillColor: "#ff6200",
              fillOpacity: 0.4,
              strokeColor: "#fff1e0",
              strokeWeight: 1,
            };
          } else if (water_clf === 1) {
            style = {
              fillColor: "#00b7ff",
              fillOpacity: 0.4,
              strokeColor: "#e0f6ff",
              strokeWeight: 1,
            };
          } else {
            style = {
              fillColor: "#fffb00",
              fillOpacity: 0.4,
              strokeColor: "#ffffe0",
              strokeWeight: 1,
            };
          }
        }
        map.data.overrideStyle(ft, style);
        // add listeners
        // - add click event
        map.data.addListener("click", function (e) {
          if (e.feature === ft) {
            e.feature.toGeoJson((props) => {
              setFeature(props);
            });
          }
        });
        // - add mouseover event
        map.data.addListener("mouseover", function (e) {
          if (e.feature === ft) {
            e.feature.toGeoJson((geojson) => {
              const props = geojson.properties;
              setHoveredFeature(props);
            });
          }
        });
        // - add mouseout event
        map.data.addListener("mouseout", function (e) {
          setHoveredFeature(null);
        });
      });

      // save data
      setData(result);

      /**
       *
       * if admin
       *
       * */
      if (isUserInternal) {
        const aoiResult = await PondManagerServices.fetchAoiResult({ bbox: query.bbox });
        const aoiGeojson = {
          type: "FeatureCollection",
          features: aoiResult.rows.map((ele) => ({
            ...ele.geojson,
            properties: {
              id: ele.id,
              label: ele.label,
              status: ele.status,
              prioritised: ele.prioritised,
              size: ele.size,
              geojson: ele.geojson,
              grid: ele.grid_geojson,
              created: ele.created,
              lat: ele.lat,
              lng: ele.lng,
            },
          })),
        };

        const d2 = map.data.addGeoJson(aoiGeojson);

        d2.forEach((ft) => {
          const status = ft.getProperty("status");
          let style = shapeStyles.aoi_pending;
          map.data.overrideStyle(ft, style);
          // // add listeners
          // // - add click event
          map.data.addListener("click", function (e) {
            if (e.feature === ft) {
              e.feature.toGeoJson((props) => {
                setAoi(props);
              });
            }
          });
          // // - add mouseover event
          // map.data.addListener("mouseover", function (e) {
          //   if (e.feature === ft) {
          //     e.feature.toGeoJson((geojson) => {
          //       // console.log(geojson);
          //     });
          //   }
          // });
          // // - add mouseout event
          // map.data.addListener("mouseout", function (e) {
          //   setHoveredFeature(null);
          // });
        });
      }

      // stop loading
      setLoading(false);
    },
    onNewPondSubmit: async (feature) => {
      await PondManagerServices.createAquaExplorerFeature(feature);
      alertsActions.addInfo(dispatch, {
        content: getText("interface.alert.alert-success"),
      });
      setShowPondAdd(false);
      Actions.loadData();
    },
    onNewAoiSubmit: async (form) => {
      await PondManagerServices.addAoi({ aoi: form });
      alertsActions.addInfo(dispatch, {
        content: getText("interface.alert.alert-success"),
      });
      setShowPondAdd(false);
      Actions.loadData();
    },
    unselectFeature: () => {
      setFeature(null);
    },
    unselectAoi: () => {
      setAoi(null);
    },
    onDeleteAoi: async (feature) => {
      const featureid = feature.properties.id;
      console.log(featureid);
      await PondManagerServices.deleteAoi({ aoiId: featureid });
      alertsActions.addInfo(dispatch, {
        content: getText("interface.alert.alert-success"),
      });
      setAoi(null);
      Actions.loadData();
    },
  };

  return (
    <Box
      width={"100%"}
      height={"100%"}
      sx={{
        position: "relative",
      }}
      onKeyDown={Actions.handleMapKeyboardShortcuts}
    >
      <Box
        sx={{
          position: "absolute",
          right: 10,
          top: 25,
          height: 60,
          zIndex: 99,
        }}
      >
        <Stack spacing={1}>
          <Box>
            <IconButton
              sx={{
                bgcolor: "#EEE",
                "&:hover": {
                  bgcolor: "#CCC",
                },
              }}
              onClick={() => setShowDashboard(!showDashboard)}
            >
              <DashboardIcon />
            </IconButton>
          </Box>
          {visibility.edit && (
            <Box>
              <IconButton
                sx={{
                  bgcolor: "#EEE",
                  "&:hover": {
                    bgcolor: "#CCC",
                  },
                }}
                onClick={() => setShowPondAdd(true)}
              >
                <AddIcon />
              </IconButton>
            </Box>
          )}
        </Stack>
      </Box>

      <Box
        sx={{
          position: "absolute",
          left: 10,
          top: 15,
          height: 60,
          zIndex: 99,
        }}
      >
        <Fade top duration={500} distance={"20px"}>
          <Stack p={1} alignItems={"flex-end"}>
            <img src={ShrimplLogoWhiteSvg} alt="" width="120" />
            <Typography color="#00b7ff" fontSize={13} fontWeight={800} sx={{ marginTop: -1 }}>
              AquaExplorer
            </Typography>
          </Stack>
        </Fade>
      </Box>

      {data && (
        <Box
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            opacity: 1,
            overflow: "auto",
            zIndex: 99,
          }}
        >
          <MapStatsBar data={data} />
        </Box>
      )}

      {showPondAdd && visibility.edit && (
        <Box
          sx={{
            position: "absolute",
            left: "8%",
            right: "8%",
            top: "8%",
            bottom: "8%",
            zIndex: 999,
            opacity: 1,
            overflow: "auto",
          }}
        >
          <FeatureAdd overlayGeojson={data} mapViewpoint={query} onExit={() => setShowPondAdd(false)} onSubmit={Actions.onNewPondSubmit} onSubmitAoi={Actions.onNewAoiSubmit} />
        </Box>
      )}

      {/* 
      
      Overlay :: Dashboard Panel 
      
      */}
      {showDashboard && (
        <Box
          sx={{
            position: "absolute",
            left: "10%",
            right: "10%",
            top: "20px",
            zIndex: 999,
            overflow: "auto",
            animation: "textFloat 0.4s",
          }}
        >
          <WidgetCard>
            <AquaExplorerDashboard data={data} />
          </WidgetCard>
        </Box>
      )}

      {selectedFeature && (
        <Box
          sx={{
            position: "absolute",
            left: "10%",
            right: "10%",
            bottom: 26,
            zIndex: 999,
            opacity: 1,
            overflow: "auto",
          }}
        >
          <FeatureCard feature={selectedFeature} actions={Actions} />
        </Box>
      )}

      {/* 
      
      Overlay :: Loading bar

      */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          zIndex: 99,
        }}
      >
        {loading && <LinearProgress width="100%" color="secondary" sx={{ height: "2px" }} />}
      </Box>

      <GoogleMapReact
        ref={mapRef}
        bootstrapURLKeys={{ key: GOOGLE_MAP_KEY }}
        center={mapCenter}
        defaultZoom={mapZoom}
        options={createMapOptions}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        onChange={Actions.handleMapChange}
      >
        {hoveredFeature && <MapTooltip lat={hoveredFeature?.lat} lng={hoveredFeature?.lng} feature={hoveredFeature} />}

        {aoi && (
          <Box
            sx={{
              position: "absolute",
              zIndex: 999,
              opacity: 1,
              overflow: "auto",
              transform: "translate(-50%, -100%)",
              minWidth: 300,
            }}
            lat={aoi?.properties?.lat}
            lng={aoi?.properties?.lng}
          >
            <AoiCard feature={aoi} actions={Actions} />
          </Box>
        )}
      </GoogleMapReact>
    </Box>
  );
};

export default AquaExplorer;
