import { Box, IconButton, Stack, Typography, colors } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { PondManagerServices } from "api/pondManagerServices";
import { CircleIcon, DeleteIcon, LinkOffIcon } from "components/Icons/MaterialIcons";
import { getText } from "components/text/Text";
import TimeFormatters from "helpers/TimeFormatters";
import { transform } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { alertsActions } from "redux/alerts";

const OrganisationAdminFarmPage = ({ org }) => {
  const dispatch = useDispatch();
  const [farms, setFarms] = useState([]);
  const [colDef, setColDef] = useState([
    {
      width: 100,
      headerName: "Active",
      cellRenderer: ({ data }) => {
        const active = data.confirmed_by_org && data.confirmed_by_farm;
        return (
          <CircleIcon
            sx={{
              transform: "translateY(4px)",
              fontSize: 15,
              color: active ? colors.green[500] : colors.grey[600],
            }}
          />
        );
      },
    },
    {
      flex: 2,
      headerName: "Farm",
      field: "label",
      editable: true,
    },

    {
      flex: 1,
      headerName: "Confirmed by ORG",
      field: "confirmed_by_org",
      editable: ({ data }) => !data.confirmed_by_org,
    },
    {
      flex: 1,
      headerName: "Confirmed By FARM",
      field: "confirmed_by_farm",
    },
    {
      flex: 1,
      headerName: "Actions",
      cellRenderer: ({ data }) => {
        return (
          <Stack direction="row" alignItems={"center"} spacing={1}>
            <IconButton
              color="error"
              size="small"
              onClick={() => {
                Actions.removeOrgFarm(data.farm_id);
              }}
            >
              <LinkOffIcon />
            </IconButton>
          </Stack>
        );
      },
    },
    {
      flex: 1,
      headerName: "Created",
      field: "created",
      cellRenderer: ({ data }) => TimeFormatters.formatUtcDatetime(data.created),
    },
  ]);

  const Actions = {
    fetchOrgFarms: async () => {
      const orgFarms = await PondManagerServices.getOrgFarms(org?.id);
      setFarms(orgFarms);
    },
    removeOrgFarm: async (farmId) => {
      const confirmed = window.confirm(getText("interface.alert.alert-are-you-sure"));
      if (confirmed) {
        await PondManagerServices.removeOrgFarm(org.id, farmId);
        alertsActions.addInfo(dispatch, {
          content: "Farm is Removed",
        });
        Actions.fetchOrgFarms();
      }
    },
    onCellValueChanged: async (params) => {
      console.log(params.column.colId);
      if (params.column.colId === "confirmed_by_org") {
        await PondManagerServices.confirmOrgFarm(org.id, params.data.farm_id);
        alertsActions.addInfo(dispatch, {
          content: "You have confirmed the farm",
        });
        Actions.fetchOrgFarms();
      }
    },
  };

  useEffect(() => {
    Actions.fetchOrgFarms();
  }, [org]);

  return (
    <Box px={2}>
      <Box my={2}>
        <Typography variant="h6" fontWeight={800}>
          Farm-Connect
        </Typography>
      </Box>
      <Box
        className="ag-theme-quartz"
        sx={{
          height: 600,
        }}
      >
        <AgGridReact rowData={farms} columnDefs={colDef} onCellValueChanged={Actions.onCellValueChanged} />
      </Box>
    </Box>
  );
};

export default OrganisationAdminFarmPage;
