import { Box, Button, Stack, colors } from "@mui/material";
import HighchartsWrapper from "components/Highcharts/HighchartsWrapper";
import { getText } from "components/text/Text";
import _ from "lodash";
import { useState } from "react";
import { CSVLink } from "react-csv";
import palette from "themes/palette";
import MuiTabs, { MuiTabsThemes } from "ui/tabs/MuiTabs";

const viewModes = {
  all: "all",
  main: "main",
  costs: "costs",
  unitCost: "unitCost",
  feed: "feed",
  fcr: "fcr",
  growthRate: "growthRate",
};
const showView = (currentView, targetView) => {
  if (currentView === viewModes.all) return true;
  return currentView === targetView;
};

const PondResultCharts = ({ pondId, pondsResults }) => {
  const [viewMode, setViewMode] = useState(viewModes.main);

  const pondResults = _.find(pondsResults, { p__id: pondId });

  const timeseries = pondResults?.timeseries;

  const sizes = _.uniq(timeseries.map((ele) => ele.p__size).filter((ele) => ele !== ": "));

  const commonChartConfigs = {
    sizeBands: sizes.map((s, idx) => {
      const sizeName = s.split(": ")[0];
      const sizeWeightRange = s.split(": ")[1];
      const filteredRows = timeseries.filter((ele) => ele.p__size === s);
      const label = `<div><b>${sizeName}</b>: ${sizeWeightRange}</div>`;
      return {
        color: idx % 2 == 0 ? colors.grey[200] : colors.grey[300],
        from: _.minBy(filteredRows, "doc")?.doc,
        to: _.maxBy(filteredRows, "doc")?.doc + 1,
        label: {
          text: label,
          rotation: 90,
          align: "left",
          x: 5,
          y: 5,
          useHTML: true,
          style: {
            fontSize: "10px",
            color: colors.grey[500],
          },
        },
        zIndex: 1,
      };
    }),
    harvestLines: [
      {
        color: colors.green[800], // Red
        width: 1,
        dashStyle: "Dash",
        value: pondResults?.stats?.optimalHarvestDoc,
        zIndex: 2,
      },
      {
        color: colors.purple[800], // Red
        width: 1,
        dashStyle: "Solid",
        value: pondResults?.stats?.plannedHarvestDoc,
        zIndex: 2,
      },
    ],
  };

  const chartConfigs = {
    summary: {
      chart: {
        type: "line",
        height: "350px",
      },
      title: {
        text: "SUMMARY",
        align: "left",
      },
      tooltip: {
        positioner: function () {
          return { x: 40, y: 40 };
        },
        shared: true,
      },
      xAxis: {
        title: {
          text: "DOC",
        },
        gridLineWidth: 0,
        lineWidth: 1,
        plotBands: commonChartConfigs.sizeBands,
        plotLines: commonChartConfigs.harvestLines,
        crosshair: true,
      },
      yAxis: {
        title: {
          text: "",
        },
        crosshair: true,
      },
      legend: {
        enabled: true,
      },
      plotOptions: {
        bar: {
          borderRadius: "50%",
          dataLabels: {
            enabled: true,
          },
          groupPadding: 0.1,
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: "Cost",
          type: "area",
          color: colors.red[200],
          data: timeseries?.map((item) => ({
            x: item.doc,
            y: item.f__totalCost * -1,
          })),
        },
        {
          name: "Gross Income",
          type: "line",
          color: colors.orange[500],
          data: timeseries?.map((item) => ({
            x: item.doc,
            y: item.f__sellingValue,
          })),
        },
        {
          name: "Net Profit",
          type: "line",
          color: colors.green[500],
          data: timeseries?.map((item) => ({
            x: item.doc,
            y: item.f__netProfit,
          })),
        },
      ],
    },
    biomassResult: {
      chart: {
        type: "line",
        height: "350px",
      },
      title: {
        text: "BIOMASS",
        align: "left",
      },
      xAxis: {
        title: {
          text: "DOC",
        },
        gridLineWidth: 0,
        lineWidth: 1,
      },
      yAxis: {
        title: {
          text: "",
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          borderRadius: "50%",
          dataLabels: {
            enabled: true,
          },
          groupPadding: 0.1,
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: "Biomass Forecast",
          type: "line",
          color: colors.green[300],
          data: timeseries?.map((item) => ({
            x: item.doc,
            y: item.biomass,
          })),
        },
      ],
    },
    weightResult: {
      chart: {
        type: "line",
        height: "350px",
      },
      title: {
        text: "WEIGHT",
        align: "left",
      },
      xAxis: {
        title: {
          text: "DOC",
        },
        gridLineWidth: 0,
        lineWidth: 1,
      },
      yAxis: {
        title: {
          text: "",
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          borderRadius: "50%",
          dataLabels: {
            enabled: true,
          },
          groupPadding: 0.1,
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: "Biomass Forecast",
          type: "line",
          color: colors.orange[300],
          data: timeseries.map((item) => ({
            x: item.doc,
            y: item.weight,
          })),
        },
      ],
    },
    populationResult: {
      chart: {
        type: "line",
        height: "350px",
      },
      title: {
        text: "POPULATION",
        align: "left",
      },
      xAxis: {
        title: {
          text: "DOC",
        },
        gridLineWidth: 0,
        lineWidth: 1,
      },
      yAxis: {
        title: {
          text: "",
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          borderRadius: "50%",
          dataLabels: {
            enabled: true,
          },
          groupPadding: 0.1,
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: "Biomass Forecast",
          type: "line",
          color: colors.red[300],
          data: timeseries.map((item) => ({
            x: item.doc,
            y: item.population,
          })),
        },
      ],
    },
    feedResult: {
      chart: {
        type: "line",
        height: "350px",
      },
      title: {
        text: "ACCUMULATED FEED",
        align: "left",
      },
      xAxis: {
        title: {
          text: "DOC",
        },
        gridLineWidth: 0,
        lineWidth: 1,
      },
      yAxis: {
        title: {
          text: "",
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          borderRadius: "50%",
          dataLabels: {
            enabled: true,
          },
          groupPadding: 0.1,
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: "Feed",
          type: "line",
          color: colors.blue[300],
          data: timeseries.map((item) => ({
            x: item.doc,
            y: item.cumulative_feed,
          })),
        },
      ],
    },
    fcr: {
      chart: {
        type: "line",
        height: "350px",
      },
      title: {
        text: "FCR",
        align: "left",
      },
      xAxis: {
        title: {
          text: "DOC",
        },
        gridLineWidth: 0,
        lineWidth: 1,
        plotBands: commonChartConfigs.sizeBands,
        plotLines: commonChartConfigs.harvestLines,
        crosshair: true,
      },
      yAxis: {
        title: {
          text: "",
        },
      },
      legend: {
        enabled: true,
      },
      plotOptions: {
        bar: {
          borderRadius: "50%",
          dataLabels: {
            enabled: true,
          },
          groupPadding: 0.1,
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: "FCR",
          type: "line",
          color: palette.secondary.main,
          data: timeseries?.map((item) => ({
            x: item.doc,
            y: item.fcr,
          })),
        },
      ],
    },
    costs: {
      chart: {
        type: "line",
        height: "350px",
      },
      title: {
        text: "COST BREAKDOWN",
        align: "left",
      },
      xAxis: {
        title: {
          text: "DOC",
        },
        gridLineWidth: 0,
        lineWidth: 1,
        plotBands: commonChartConfigs.sizeBands,
        plotLines: commonChartConfigs.harvestLines,
        crosshair: true,
      },
      yAxis: {
        title: {
          text: "",
        },
      },
      plotOptions: {
        column: {
          stacking: "normal",
          borderWidth: 0,
        },
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: true,
      },
      tooltip: {
        shared: true,
      },
      series: [
        {
          name: "Labour",
          type: "column",
          color: colors.blue[500],
          data: timeseries?.map((item) => ({
            x: item.doc,
            y: item.f__labourFixedCost,
          })),
        },
        {
          name: "Admin",
          type: "column",
          color: colors.red[500],
          data: timeseries?.map((item) => ({
            x: item.doc,
            y: item.f__adminFixedCost,
          })),
        },
        {
          name: "Other",
          type: "column",
          color: colors.orange[500],
          data: timeseries?.map((item) => ({
            x: item.doc,
            y: item.f__otherFixedCost,
          })),
        },
        {
          name: "Feed",
          type: "column",
          color: colors.green[500],
          data: timeseries?.map((item) => ({
            x: item.doc,
            y: item.f__feedVarCost,
          })),
        },
        {
          name: "Energy",
          type: "column",
          color: colors.amber[500],
          data: timeseries?.map((item) => ({
            x: item.doc,
            y: item.f__energyVarCost,
          })),
        },
        {
          name: "Seed",
          type: "column",
          color: colors.cyan[500],
          data: timeseries?.map((item) => ({
            x: item.doc,
            y: item.f__seedVarCost,
          })),
        },
      ],
    },
    unitCost: {
      chart: {
        type: "line",
        height: "350px",
      },
      title: {
        text: "AVG UNIT COST ($/kg)",
        align: "left",
      },
      xAxis: {
        title: {
          text: "DOC",
        },
        gridLineWidth: 0,
        lineWidth: 1,
        plotBands: commonChartConfigs.sizeBands,
        plotLines: commonChartConfigs.harvestLines,
        crosshair: true,
      },
      yAxis: {
        title: {
          text: "",
        },
      },
      plotOptions: {
        column: {
          stacking: "normal",
          borderWidth: 0,
        },
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: true,
      },
      tooltip: {
        shared: true,
      },
      series: [
        {
          name: "Avg Unit Cost ($/kg)",
          type: "line",
          color: colors.red[500],
          data: timeseries?.map((item) => ({
            x: item.doc,
            y: item.f__averageUnitCost,
          })),
        },
      ],
    },
    feed: {
      chart: {
        type: "line",
        height: "350px",
      },
      title: {
        text: "FEED",
        align: "left",
      },
      xAxis: {
        title: {
          text: "DOC",
        },
        gridLineWidth: 0,
        lineWidth: 1,
        plotBands: commonChartConfigs.sizeBands,
        plotLines: commonChartConfigs.harvestLines,
        crosshair: true,
      },
      yAxis: {
        title: {
          text: "",
        },
      },
      plotOptions: {
        bar: {
          borderRadius: "50%",
          dataLabels: {
            enabled: true,
          },
          groupPadding: 0.1,
        },
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: true,
      },
      tooltip: {
        shared: true,
      },
      series: [
        {
          name: "Feed Stock",
          type: "column",
          color: colors.green[200],
          data: timeseries?.map((item) => ({
            x: item.doc,
            y: item.f__feedStock,
            color: item.f__feedStock > 0 ? colors.green[500] : colors.red[500],
          })),
        },
        {
          name: "Feed Comsumption",
          type: "line",
          color: colors.blue[500],
          data: timeseries?.map((item) => ({
            x: item.doc,
            y: item.cumulative_feed,
          })),
        },
      ],
    },
    growthRate: {
      chart: {
        type: "line",
        height: "350px",
      },
      title: {
        text: "GROWTH RATE",
        align: "left",
      },
      xAxis: {
        title: {
          text: "DOC",
        },
        gridLineWidth: 0,
        lineWidth: 1,
        plotBands: commonChartConfigs.sizeBands,
        plotLines: commonChartConfigs.harvestLines,
        crosshair: true,
      },
      yAxis: {
        title: {
          text: "",
        },
      },
      plotOptions: {
        bar: {
          borderRadius: "50%",
          dataLabels: {
            enabled: true,
          },
          groupPadding: 0.1,
        },
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: true,
      },
      tooltip: {
        shared: true,
        valueDecimals: 3,
      },
      series: [
        {
          name: "Growth Rate",
          type: "line",
          color: colors.blue[500],
          data: timeseries?.map((item) => ({
            x: item.doc,
            y: item.b__weight_diff,
          })),
        },
      ],
    },
  };

  return (
    <Box>
      <Box px={2}>
        <MuiTabs
          theme={MuiTabsThemes.chip}
          options={[
            { label: getText("interface.actions.show-all"), value: viewModes.all },
            { label: getText("interface.general.summary"), value: viewModes.main },
            { label: getText("interface.general.average-unit-cost"), value: viewModes.unitCost },
            { label: getText("interface.general.cost-breakdown"), value: viewModes.costs },
            { label: getText("interface.general.feed"), value: viewModes.feed },
            { label: "FCR", value: viewModes.fcr },
            { label: "Growth Rate", value: viewModes.growthRate },
          ]}
          value={viewMode}
          onNewValue={setViewMode}
          withBorder
        />
      </Box>
      <Stack spacing={1}>
        {showView(viewMode, viewModes.main) && <HighchartsWrapper options={chartConfigs.summary} />}
        {showView(viewMode, viewModes.unitCost) && <HighchartsWrapper options={chartConfigs.unitCost} />}
        {showView(viewMode, viewModes.costs) && <HighchartsWrapper options={chartConfigs.costs} />}
        {showView(viewMode, viewModes.feed) && <HighchartsWrapper options={chartConfigs.feed} />}
        {showView(viewMode, viewModes.fcr) && <HighchartsWrapper options={chartConfigs.fcr} />}
        {showView(viewMode, viewModes.growthRate) && <HighchartsWrapper options={chartConfigs.growthRate} />}
        {timeseries?.data && (
          <Box>
            <CSVLink className="csvlink" data={timeseries} filename={"download.csv"}>
              <Button>Download Full Result</Button>
            </CSVLink>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default PondResultCharts;
