export const overlays = [
  {
    url: "https://intelligon-public.s3.ap-southeast-2.amazonaws.com/gis-public/_overlays_/7a890327-f64b-446a-aba6-c7ef8cb7560e.png",
    bounds: {
      north: 9.088848011472622,
      south: 9.078120187308357,
      east: 105.2495792031095,
      west: 105.2373228271095,
    },
  },
  // Good Tom Farm
  // {
  //   url: "https://intelligon-public.s3.ap-southeast-2.amazonaws.com/gis-public/_overlays_/1d0de070-cf74-4b26-9fad-40be1b90102c.png",
  //   bounds: {
  //     north: 9.225803721739657,
  //     south: 9.222556579739654,
  //     east: 105.7716461231293,
  //     west: 105.76792870539137,
  //   },
  // },
  //
  // Darren Lee Farm
  {
    url: "https://intelligon-public.s3.ap-southeast-2.amazonaws.com/gis-public/_overlays_/f98ccece-d81f-4607-8ceb-f17c4fae64a4.jpg",
    bounds: {
      north: 1.772930498960883,
      south: 1.7525584759076744,
      east: 109.31730694209884,
      west: 109.29966940619451,
    },
  },
];
