import { IconButton, Stack, Box, Tooltip, colors } from "@mui/material";
import { AiFillReconciliation } from "react-icons/ai";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { useSelector } from "react-redux";

const ActionBar = ({ onClickFloating, onClickMenu }) => {
  const { isMenuCollapsed } = useSelector((state) => state.chat);
  return (
    <Stack
      sx={{
        bgcolor: colors.grey[200],
        borderRadius: 1,
        mb: 1,
        p: 0.5,
      }}
    >
      <Box sx={{ margin: "1px" }}>
        <Tooltip title="Chat Menu">
          <IconButton onClick={onClickMenu}>{isMenuCollapsed ? <MenuOpenIcon /> : <MenuIcon />}</IconButton>
        </Tooltip>
        {/* <Tooltip title="Floating Message">
          <IconButton onClick={onClickFloating}>
            <AiFillReconciliation />
          </IconButton>
        </Tooltip> */}
      </Box>
    </Stack>
  );
};

export default ActionBar;
