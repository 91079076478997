import { AgGridReact } from "ag-grid-react";
import { PondManagerServices } from "api/pondManagerServices";
import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, IconButton, Stack, TextField, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { alertsActions } from "redux/alerts";
import { DeleteIcon, RemoveIcon } from "components/Icons/MaterialIcons";
import TimeFormatters from "helpers/TimeFormatters";
import Text, { getText } from "components/text/Text";

const MemberInviteForm = ({ org, postSubmit }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const Actions = {
    onSubmit: async (form) => {
      setLoading(true);
      const rsp = await PondManagerServices.addMember(org.id, form.email);
      if (rsp?.error) {
        alertsActions.addError(dispatch, {
          content: rsp?.error,
        });
      } else {
        alertsActions.addInfo(dispatch, {
          content: "Member Added",
        });
        postSubmit();
      }
      setLoading(false);
    },
  };

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      onSubmit={Actions.onSubmit}
    >
      {({ values, handleChange }) => {
        return (
          <Form>
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <TextField
                label={getText("interface.account.email")}
                value={values.email}
                name="email"
                onChange={handleChange}
                type="email"
                size="small"
                sx={{
                  minWidth: 500,
                }}
                required
              />
              <Box>
                <Button
                  type="submit"
                  variant="outlined"
                  startIcon={loading && <CircularProgress size={20} />}
                  sx={{
                    width: 200,
                  }}
                >
                  <Text>interface.general.invite</Text>
                </Button>
              </Box>
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
};

const OrganisationAdminMemberList = ({ org }) => {
  const [colDefs, setColDefs] = useState([
    {
      flex: 1,
      headerName: getText("interface.general.person-name"),
      sortable: true,
      cellRenderer: ({ data }) => {
        return `${data.firstname} ${data.lastname}`;
      },
      filterable: true,
    },
    {
      flex: 1,
      headerName: getText("interface.account.email"),
      field: "email",
      sortable: true,
    },
    {
      flex: 1,
      headerName: getText("interface.general.activation"),
      field: "activated",
      cellRenderer: ({ data }) => {
        return data.activated ? "🟢 Activated" : "⚪ Pending";
      },
    },
    {
      flex: 1,
      headerName: getText("interface.general.time-created"),
      field: "created_at",
      cellRenderer: ({ data }) => {
        return TimeFormatters.formatUtcDatetime(data.created_at);
      },
    },
    {
      flex: 1,
      headerName: getText("interface.general.actions"),
      cellRenderer: ({ data }) => {
        return (
          <Box pt={0.4}>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <IconButton
                size="small"
                color="error"
                onClick={() => {
                  Actions.onDeleteMember(data.id);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Stack>
          </Box>
        );
      },
    },
  ]);
  const [data, setData] = useState([]);

  const Actions = {
    fetchMembers: async () => {
      const res = await PondManagerServices.getMembers(org.id);
      setData(res);
    },
    onDeleteMember: async (memberId) => {
      const confirmed = window.confirm(getText("interface.alert.alert-are-you-sure"));
      if (confirmed) {
        await PondManagerServices.deleteMember(org.id, memberId);
        Actions.fetchMembers();
      }
    },
  };

  useEffect(() => {
    Actions.fetchMembers();
  }, [org]);

  return (
    <Box>
      <Box my={2}>
        <MemberInviteForm org={org} postSubmit={Actions.fetchMembers} />
      </Box>
      <Box height={600} className="ag-theme-quartz">
        <AgGridReact columnDefs={colDefs} rowData={data} overlayNoRowsTemplate={"NO MEMBERS"} />
      </Box>
    </Box>
  );
};

export default OrganisationAdminMemberList;
