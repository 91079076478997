import { Box, Button, colors, Typography } from "@mui/material";
import { CommonHelmet } from "components/Helmets/helmets";
import { ShrimplIconOnlySvg } from "components/Images";
import { HomeIcon } from "components/Icons/MaterialIcons";
import { getHomepagePath } from "index/AppIndex";
import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";

const NotFoundPage = ({ linkTo, autoRedirectToHomepage }) => {
  const navigate = useNavigate();
  useEffect(() => {
    autoRedirectToHomepage && navigate(getHomepagePath());
  });
  return (
    <Box
      py={6}
      sx={{
        textAlign: "center",
      }}
    >
      <CommonHelmet title="Not Found" />
      <img src={ShrimplIconOnlySvg} alt="" width="100" />
      <Typography
        fontSize={{
          xs: 50,
          sm: "20rem",
        }}
        sx={{ color: colors.grey[400] }}
      >
        404
      </Typography>
      <Typography>There's nothing here!</Typography>
      <Box mt={5}>
        <NavLink to={linkTo || getHomepagePath()}>
          <Button variant="outlined" size="large" startIcon={<HomeIcon />}>
            back to home
          </Button>
        </NavLink>
      </Box>
    </Box>
  );
};

export default NotFoundPage;
