import { Box, colors } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import Formatters from "helpers/Formatters";
import _ from "lodash";
import React, { useState } from "react";
import NumberFormat from "react-number-format";
import { useDispatch } from "react-redux";
import { googleMapManagementActions } from "redux/pondManagement/googleMapManagement";

const PondResultsTable = ({ pondId, tableRows, actions }) => {
  const dispatch = useDispatch();

  const [colDef, setColDef] = useState([
    {
      headerName: "pond",
      field: "p__label",
    },
    {
      headerName: "Area",
      field: "p__area",
      hide: true,
      cellRenderer: ({ value }) => {
        return <NumberFormat value={value} decimalScale={1} suffix=" ha." displayType="text" />;
      },
    },
    {
      headerName: "Initial Biomass",
      field: "meta.seeded_population",
      cellRenderer: ({ data }) => {
        return <NumberFormat value={(data?.meta?.seeded_population * data?.meta?.initial_weight) / 1000} decimalScale={0} suffix=" kg" displayType="text" thousandSeparator="," />;
      },
    },
    {
      hide: true,
      headerName: "Seeded Population",
      field: "meta.seeded_population",
      cellRenderer: ({ value }) => {
        return <NumberFormat value={value} decimalScale={0} suffix="" displayType="text" thousandSeparator="," />;
      },
    },
    {
      headerName: "Harvest DoC",
      field: "stats.plannedHarvestDoc",
      cellRenderer: ({ value }) => {
        return <NumberFormat value={value} decimalScale={0} displayType="text" />;
      },
      cellStyle: ({ data }) => {
        if (data?.stats?.plannedHarvestHasOffset) return { fontWeight: 800, color: colors.orange[800], backgroundColor: colors.orange[50] };
      },
    },
    {
      headerName: "Harvest Date",
      field: "stats.plannedHarvestDate",
      cellRenderer: ({ value }) => {
        return Formatters.toDateString(value);
      },
      cellStyle: ({ data }) => {
        if (data?.stats?.plannedHarvestHasOffset) return { fontWeight: 800, color: colors.orange[800], backgroundColor: colors.orange[50] };
      },
    },
    {
      headerName: "FCR",
      field: "stats.plannedHarvestFcr",
      cellRenderer: ({ value }) => {
        return <NumberFormat value={value} decimalScale={2} displayType="text" />;
      },
    },
    {
      headerName: "Profit",
      field: "stats.plannedHarvestNetProfit",
      cellRenderer: ({ value }) => {
        return Formatters.formatDollar({ value });
      },
    },
    {
      headerName: "Cost",
      field: "stats.plannedHarvestCost",
      cellRenderer: ({ value }) => {
        return Formatters.formatDollar({ value });
      },
    },
    {
      headerName: "Feed (T)",
      field: "stats.plannedHarvestFeedAccumulated",
      cellRenderer: ({ value }) => {
        return Formatters.formatBiomassInTons({ value });
      },
    },
    {
      headerName: "Biomass (T)",
      field: "stats.plannedHarvestBiomass",
      cellRenderer: ({ value }) => {
        return Formatters.formatBiomassInTons({ value });
      },
    },
    {
      headerName: "Size",
      field: "stats.plannedHarvestSize",
    },
  ]);

  return (
    <Box>
      <Box
        className="ag-theme-excel"
        sx={{
          width: "100%",
          height: "300px",
        }}
      >
        <AgGridReact
          rowData={tableRows}
          columnDefs={colDef}
          defaultColDef={{
            width: 120,
          }}
          getRowStyle={(params) => {
            return {
              backgroundColor: params.node?.data?.p__id === pondId && "#EEE",
            };
          }}
          rowHeight={23}
          headerHeight={30}
          rowSelection={"multiple"}
          onRowClicked={(v) => {
            actions.onSelectPondId(v.data.pond.id);
            googleMapManagementActions.highlightMultiPonds(dispatch, [v.data.pond]);
          }}
          suppressScrollOnNewData
        />
      </Box>
    </Box>
  );
};

export default PondResultsTable;
