import { AspectRatioIcon, AttachMoneyIcon, KeyboardArrowRightIcon, LocationOnIcon, TagIcon } from "components/Icons/MaterialIcons";
import { Box, Stack, Typography, colors } from "@mui/material";
import { PondManagerLayoutStyles } from "screens/Aquaculture/components/PondManagerMapView";
import FarmDashboardHero from "screens/Aquaculture/components/Dashboard/components/FarmDashboardHero";
import { useEffect, useState } from "react";
import PondDashboardHero from "screens/Aquaculture/components/Dashboard/components/PondDashboardHero";
import palette from "themes/palette";
import PondDashboardView from "screens/Aquaculture/components/Dashboard/PondDashboardView";
import FarmDashboardView from "screens/Aquaculture/components/Dashboard/FarmDashboardView";
import OutlinedTag from "components/Tags/OutlinedTag";
import NumericFormatters from "helpers/NumericFormatters";
import Text, { getText } from "components/text/Text";
import NumberFormat from "react-number-format";
import styled from "styled-components";

const Layout = styled.div`
  padding: 0 16px;

  .Wrapper {
    background-color: ${palette.primary.main};
    border-radius: 0px 0px 16px 16px;
    overflow: hidden;
    &:hover {
      cursor: pointer;
    }

    .farm-label-title,
    .farm-label-value,
    .farm-tags,
    .PondWrapper {
      transition: all 0.5s;
    }

    .PondWrapper {
      transform: translateY(-100px);
      opacity: 0;
      visibility: hidden;
      border-radius: 0px 0px 16px 16px;
    }
  }

  .viewPond {
    .PondWrapper {
      transform: translateY(-20px);
      opacity: 1;
      visibility: visible;
    }
  }
`;

/**
 *
 * Pond Management / Dashboard Home
 *
 */
const DashboardHome = ({ farm, pond, ponds, group, pondGroupColorMapping, actions }) => {
  const [viewPond, setViewPond] = useState(false);

  useEffect(() => {
    if (pond) {
      if (!viewPond) {
        setViewPond(true);
      }
    } else {
      if (!pond) {
        setViewPond(false);
      }
    }
  }, [pond]);

  return (
    <Box
      sx={{
        ...PondManagerLayoutStyles.rightPanel,
        p: 0,
        bgcolor: colors.grey[50],
      }}
    >
      {/* <Layout>
        <Box className={"Wrapper" + (viewPond ? " viewPond" : "")} onClick={() => setViewPond(false)}>
          <Stack direction={"row"} alignItems={"center"} p={2}>
            <Box>
              <Typography className="farm-label-value" color="white" fontSize={16} fontWeight={800}>
                <span style={{ color: colors.grey[400] }}>
                  <Text>interface.general.farm</Text>
                </span>{" "}
                {farm?.label}
              </Typography>
              <Stack direction={"row"} spacing={0.5} className="farm-tags" mt={0.5}>
                <OutlinedTag color="#FFF" icon={<LocationOnIcon />} tooltip={getText("interface.general.location")} text={`${farm?.region}, ${farm?.country}`} />
                <OutlinedTag color="#FFF" icon={<AspectRatioIcon />} tooltip={getText("interface.general.farm-area")} text={NumericFormatters.formatAreaInHa({ value: farm?.total_area })} />
                <OutlinedTag
                  color="#FFF"
                  icon={<TagIcon />}
                  tooltip={getText("interface.general.ponds")}
                  text={<NumberFormat displayType="text" value={farm?.total_ponds} decimalScale={0} suffix=" Pds." />}
                />
                <OutlinedTag color="#FFF" icon={<AttachMoneyIcon />} tooltip={getText("interface.general.currency")} text={farm?.settings?.currency} />
              </Stack>
            </Box>
            <Stack className="PondWrapper" justifyContent={"space-between"} bgcolor={"#FFF"} onClick={(e) => e.preventDefault()} sx={{ pointerEvents: "none" }} p={1} px={2}>
              <Box>
                <Typography className="pond-label-value" fontSize={16} fontWeight={800}>
                  <span style={{ color: colors.grey[500] }}>
                    <Text>interface.general.pond</Text>
                  </span>{" "}
                  {pond?.name}
                </Typography>
              </Box>
              <Stack direction={"row"} spacing={0.5} sx={{ display: "inline-block" }}>
                <OutlinedTag icon={<AspectRatioIcon />} text={NumericFormatters.formatAreaInHa({ value: pond?.area })} tooltip={getText("interface.general.pond-area")} />
                <OutlinedTag text={"G: " + pond?.group} tooltip={getText("interface.general.group")} />
                <OutlinedTag text={"C: " + pond?.category} tooltip={getText("interface.general.category")} />
                <OutlinedTag text={"SC: " + pond?.sub_category} tooltip={getText("interface.general.sub-category")} />
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Layout> */}

      <Stack direction={"row"} alignItems={"center"} p={2}>
        <Box>
          <Box
            sx={{
              p: 2,
              transition: "all .2s",
              bgcolor: !viewPond && "#FFF",
              boxShadow: !viewPond && 1,
              borderRadius: 3,
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => setViewPond(false)}
          >
            <FarmDashboardHero farm={farm} />
          </Box>
        </Box>
        {pond && (
          <Box>
            <Box
              sx={{
                p: 2,
                transition: "all .3s",
                bgcolor: viewPond && "#FFF",
                boxShadow: viewPond && 1,
                borderRadius: 3,
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onClick={() => setViewPond(true)}
            >
              <PondDashboardHero pond={pond} />
            </Box>
          </Box>
        )}
      </Stack>

      <Box>
        {viewPond && pond ? (
          <PondDashboardView farm={farm} pond={pond} group={group} onSwitchToDataViewField={actions.onSwitchToDataViewField} />
        ) : (
          <FarmDashboardView
            farm={farm}
            ponds={ponds}
            group={group}
            onSwitchToDataViewField={actions.onSwitchToDataViewField}
            pondGroupColorMapping={pondGroupColorMapping}
            actions={{
              toggleAlertPonds: actions.toggleAlertPonds,
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default DashboardHome;
