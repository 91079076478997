import Text from "components/text/Text";
import { useEffect } from "react";
import { GiHouseKeys, GiSwapBag } from "react-icons/gi";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTE_POND_MANAGER_FARM_EDIT } from "routes/paths";
import { SettingsIcon } from "components/Icons/MaterialIcons";
import { Collections } from "@mui/icons-material";
import SideMenuRouteLayout from "screens/Aquaculture/components/Layout/SideMenuRouteLayout";
import { useDispatch } from "react-redux";
import { pondManagementActions } from "redux/pondManagement/pondManagement";
import { AppSelectors } from "redux/AppReducers";
import { Box, Typography } from "@mui/material";

/**
 *
 *
 * @param {} param0
 * @returns
 */
const FarmSettingsHome = () => {
  const { farmid } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const pondManagementStore = AppSelectors.pondManagementStore();
  const { selectedFarm } = pondManagementStore;

  const Actions = {
    onLoadFarm: async () => {
      if (farmid) {
        // load all farm
        await pondManagementActions.loadAllFarms(dispatch);
        // select a farm to view
        await pondManagementActions.selectFarm(dispatch, { farmId: farmid });
      }
    },
  };

  useEffect(() => {
    Actions.onLoadFarm();
  }, []);

  const TitlePart = () => {
    if (!selectedFarm) return "";
    return (
      <Box pb={3} px={1}>
        <Typography fontSize={20} fontWeight={800}>
          {selectedFarm?.label}
        </Typography>
      </Box>
    );
  };

  const MENULIST = [
    {
      label: <Text>interface.general.settings</Text>,
      path: `${ROUTE_POND_MANAGER_FARM_EDIT}/${farmid}/general`,
      icon: <SettingsIcon />,
    },
    {
      label: <Text>interface.general.gallery</Text>,
      path: `${ROUTE_POND_MANAGER_FARM_EDIT}/${farmid}/gallery`,
      icon: <Collections />,
    },
    {
      label: <Text>interface.general.farming-products</Text>,
      path: `${ROUTE_POND_MANAGER_FARM_EDIT}/${farmid}/farming-products`,
      icon: <GiSwapBag />,
    },
    {
      label: <Text>interface.access-control.access-control</Text>,
      path: `${ROUTE_POND_MANAGER_FARM_EDIT}/${farmid}/collab`,
      icon: <GiHouseKeys />,
    },
  ];

  return <SideMenuRouteLayout title={<TitlePart />} menulist={MENULIST} onReturn={() => navigate(`/aqua/pond-manager/${farmid}`)} />;
};

export default FarmSettingsHome;
