import { InfoOutlined } from "@mui/icons-material";
import { Avatar, Box, Stack, Tooltip, Typography, colors } from "@mui/material";
import CountryFlagSvgIcon from "components/Icons/CountryFlagSvgIcon";
import { AddIcon, CheckCircleIcon, InfoIcon } from "components/Icons/MaterialIcons";
import { MuiBorderIconButton } from "components/buttons/MuiButtonVariants";
import Text from "components/text/Text";
import Formatters from "helpers/Formatters";
import _ from "lodash";
import { useState } from "react";

export const ProductCardVariants = {
  full: "full",
  menuItem: "menuItem",
};

const ProductCard = ({ variant = ProductCardVariants.full, product, isRegistered, onRegister }) => {
  const [showDetails, setShowDetails] = useState(false);

  const buttons = [
    {
      icon: showDetails ? <InfoIcon /> : <InfoOutlined />,
      action: () => {
        setShowDetails(!showDetails);
      },
    },
    ...(onRegister
      ? [
          {
            icon: isRegistered ? <CheckCircleIcon /> : <AddIcon />,
            action: () => {
              if (!isRegistered && onRegister) {
                onRegister && onRegister(product);
              }
            },
          },
        ]
      : []),
  ];

  if (variant === ProductCardVariants.menuItem) {
    return (
      <Tooltip
        placement="right"
        title={
          <Stack direction={"row"} spacing={1}>
            <Box>
              <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
                <Typography fontSize={11}>{product?.producer_name}</Typography>
              </Stack>
              <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
                <Typography fontSize={11}>
                  {product?.net_weight} {product?.net_weight_unit}
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
                <Typography fontWeight={800} fontSize={10}>
                  {product.category || "-"}
                </Typography>
                <Typography fontWeight={800} fontSize={10}>
                  {"/"}
                </Typography>
                <Typography fontWeight={800} fontSize={10}>
                  {product.sub_category || "-"}
                </Typography>
              </Stack>
            </Box>
          </Stack>
        }
      >
        <Box p={0.5} width={"100%"} spacing={2}>
          <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <Box>
                <Avatar alt={_.first(product?.producer_name)} src={product?.producer_logo_url} sx={{ width: 20, height: 20 }}>
                  {_.first(product?.producer_name)}
                </Avatar>
              </Box>
              <Box>
                <Avatar alt={product.image_url} src={product.image_url} sx={{ width: 20, height: 20 }}>
                  {_.first(product?.name)}
                </Avatar>
              </Box>
              <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} spacing={1}>
                <Typography fontWeight={800} fontSize={13}>
                  {product?.name}
                </Typography>
              </Stack>
            </Stack>
            <Stack>{Formatters.Special.formatFarmingProductType({ value: product.product_type })}</Stack>
          </Stack>
        </Box>
      </Tooltip>
    );
  }

  return (
    <Box
      sx={{
        borderRadius: 1,
        border: "1px solid #DDD",
        p: 1,
        bgcolor: colors.common.white,
      }}
    >
      <Stack mb={2} direction={"row"} justifyContent={"space-between"}>
        <Box>{Formatters.Special.formatFarmingProductType({ value: product.product_type })}</Box>
        <Stack direction={"row"} spacing={0.5}>
          {buttons.map((btn, idx) => (
            <MuiBorderIconButton size="sm" onClick={btn.action} icon={btn.icon}></MuiBorderIconButton>
          ))}
        </Stack>
      </Stack>
      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} spacing={1}>
        <Stack direction={"row"} spacing={1}>
          <Box>
            <Box
              sx={{
                width: 80,
                height: 80,
                backgroundImage: `url(${product.image_url})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                borderRadius: 1,
                backgroundColor: "#EEE",
              }}
            ></Box>
          </Box>
          <Box>
            {/* <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
              <Typography fontWeight={800} fontSize={10} color={"grey"}>
                {product.category || "-"}
              </Typography>
              <Typography fontWeight={800} fontSize={10} color={"grey"}>
                {"•"}
              </Typography>
              <Typography fontWeight={800} fontSize={10} color={"grey"}>
                {product.sub_category || "-"}
              </Typography>
            </Stack> */}
            <Typography fontWeight={800} fontSize={13}>
              {product?.name}
            </Typography>
            <Typography fontStyle={"italic"} fontSize={11}>
              {product?.net_weight} {product?.net_weight_unit}
            </Typography>
          </Box>
        </Stack>
        <Stack spacing={0} alignItems={"flex-end"}>
          <Avatar alt={_.first(product?.producer_name)} src={product?.producer_logo_url}>
            {_.first(product?.producer_name)}
          </Avatar>
          <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
            <Typography fontWeight={800} fontSize={11}>
              <span style={{ color: "grey" }}>{product?.producer_name}</span>
            </Typography>
          </Stack>
          <Stack>
            <CountryFlagSvgIcon countryCode={product?.producer_country_code} />
          </Stack>
        </Stack>
      </Stack>
      <Box>
        {showDetails && (
          <Stack spacing={1} p={2}>
            <Box>
              <img src={product.image_url} width={"100%"} alt="" />
            </Box>
            <Box>
              <Typography color={"grey"}>
                <Text>interface.general.description</Text>
              </Typography>
              <Typography>{product.description}</Typography>
            </Box>
          </Stack>
        )}
      </Box>
    </Box>
  );
};

export default ProductCard;
