import {
  Alert,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  colors,
} from "@mui/material";
import AuthServices from "api/authServices";
import { theme } from "App";
import PrimaryButton from "components/buttons/PrimaryButton";
import { ShrimplLogoSvg } from "components/Images";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { userActions } from "redux/user";
import { ROUTE_FORGOT_PASSWORD, ROUTE_LOGIN } from "routes/paths";
import LoginPageLayout from "screens/Login/styles/LoginPageLayout";
import jwt_decode from "jwt-decode";
import moment from "moment";

const ResetPassword = () => {
  const [requested, setRequested] = useState(false);
  const [tokenInvalid, setTokenInvaid] = useState(false);
  const [error, setError] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get("token");

  const dispatch = useDispatch();

  useEffect(() => {
    if (!token) {
      navigate(ROUTE_LOGIN);
      return;
    }
    try {
      const tokenDecoded = jwt_decode(token);
      const tokenExpired = moment(tokenDecoded.exp * 1000) <= moment();
      if (tokenExpired) {
        setTokenInvaid(true);
      }
    } catch {
      navigate(ROUTE_LOGIN);
      return;
    }
  }, []);

  const Actions = {
    sendPasswordChange: (newpassword, token) => {
      if (token) {
        const payload = {
          new_password: newpassword,
          token: token,
        };
        userActions.resetPassword(dispatch, payload);
        setRequested(true);
        setTimeout(() => {
          navigate(ROUTE_LOGIN);
        }, 1000);
      }
    },
  };

  return (
    <LoginPageLayout title="Reset your password">
      {requested ? (
        <Box my={2}>
          <Typography>
            Your password is reset. Now log in with the new password!
          </Typography>
        </Box>
      ) : tokenInvalid ? (
        <Box>
          <Typography color={colors.red[400]}>
            The link is expired or invalid!
          </Typography>
          <Box mt={2}>
            <PrimaryButton
              fullWidth
              onClick={() => navigate(ROUTE_FORGOT_PASSWORD)}
            >
              Resend The Link
            </PrimaryButton>
          </Box>
        </Box>
      ) : (
        <Box mt={2}>
          <Formik
            initialValues={{ newpassword: "", newpasswordconfirm: "" }}
            validate={(values) => {
              const errors = {};
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              const { newpassword, newpasswordconfirm } = values;
              setError(null);
              if (newpassword !== newpasswordconfirm) {
                setError("You need to enter two identical passwords.");
                return;
              }
              if (newpassword.length < 8) {
                setError("Your new password needs at least 8 characters.");
                return;
              }
              Actions.sendPasswordChange(newpassword, token);
            }}
          >
            {({ isSubmitting, handleChange }) => (
              <Form>
                <Stack width={"100%"} spacing={2}>
                  <TextField
                    type="password"
                    name="newpassword"
                    onChange={handleChange}
                    label="New Password"
                    variant="standard"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  ></TextField>
                  <TextField
                    type="password"
                    name="newpasswordconfirm"
                    onChange={handleChange}
                    label="Confirm New Password"
                    variant="standard"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  ></TextField>
                  {error && <Alert severity="error">{error}</Alert>}
                  <PrimaryButton type="submit">Submit</PrimaryButton>
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      )}
    </LoginPageLayout>
  );
};

export default ResetPassword;
