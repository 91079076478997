import { Box, colors, Divider, IconButton, ImageList, ImageListItem, ImageListItemBar, LinearProgress, Stack, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { dialogReducerActions } from "redux/dialog";

import { CameraAltIcon, DeleteIcon, FileUploadIcon, FullscreenIcon, StarIcon } from "components/Icons/MaterialIcons";

import { PondManagerServices } from "api/pondManagerServices";

import moment from "moment";
import { useDispatch } from "react-redux";
import Text, { getText } from "components/text/Text";
import { modalIds, modalReducersActions } from "redux/modal";
import CommonModal from "components/Modal/CommonModal";
import { VerticalAlertBoxCollections } from "components/Alerts/AlertBox";
import CommonDialog, { CommonDialogIds } from "components/Dialogs/CommonDialog";
import { AppSelectors } from "redux/AppReducers";

const FarmImages = () => {
  const pondManagementStore = AppSelectors.pondManagementStore();
  const { selectedFarm } = pondManagementStore;

  const farmId = selectedFarm?.farm_id;

  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [imageToDelete, setImageToDelete] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    farmId && Actions.getFarmImages();
  }, [farmId]);

  const Actions = {
    getFarmImages: async () => {
      const res = await PondManagerServices.getFarmImages(farmId);
      setImages(res.payload.images);
    },
    uploadImage: async (farmId, farmData) => {
      setLoading(true);
      const res = await PondManagerServices.uploadFarmImages(farmId, farmData);
      await Actions.getFarmImages();
      setLoading(false);
      return res;
    },
    featureImage: async (farmId, image_id) => {
      const res = await PondManagerServices.featureFarmImage(farmId, image_id);
      await Actions.getFarmImages();
      return res;
    },
    deleteImageConfirm: (image) => {
      dialogReducerActions.openCommonDialog(dispatch, {
        id: CommonDialogIds.FARM_IMAGE_DELETE_CONFIRM,
      });
      setImageToDelete(image);
    },
    deleteImage: async () => {
      const image_id = imageToDelete?.id;
      await PondManagerServices.deleteFarmImage(farmId, image_id);
      await Actions.getFarmImages();
    },
    previewImage: async (url) => {
      setPreviewImage(url);
      modalReducersActions.openModal(dispatch, { id: modalIds.FARM_IMAGE_MODAL });
    },
  };

  return (
    <Box>
      <CommonDialog id={CommonDialogIds.FARM_IMAGE_DELETE_CONFIRM} title={getText("interface.alert.alert-are-you-sure")} action={Actions.deleteImage} />
      <CommonModal id={modalIds.FARM_IMAGE_MODAL} size="large" nobg>
        {previewImage ? (
          <Box
            sx={{
              height: "90%",
              width: "100%",
              textAlign: "center",
              img: {
                display: "inline-block",
                verticalAlign: "middle",
                height: "100%",
                maxWidth: "90%",
                boxShadow: "1",
                borderRadius: "10px",
              },
            }}
          >
            {" "}
            <img src={previewImage} />
          </Box>
        ) : (
          ""
        )}
      </CommonModal>
      <Formik
        initialValues={{
          file: null,
        }}
        onSubmit={async (values, { resetForm }) => {
          await Actions.uploadImage(farmId, values);
          resetForm();
        }}
      >
        {({ values, handleChanges, setFieldValue, setErrors }) => {
          return (
            <Form>
              <Stack spacing={1}>
                <Typography variant="pageTitle">
                  <Text>interface.general.gallery</Text>
                </Typography>
                <Box>
                  {values.file && (
                    <Box
                      height={200}
                      overflow={"hidden"}
                      sx={{
                        background: colors.grey[50],
                        backgroundImage: values.file ? `url(${URL.createObjectURL(values.file)})` : "none",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        borderRadius: 1,
                      }}
                    ></Box>
                  )}
                </Box>
                <Stack
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{
                    p: 1,
                    px: 2,
                  }}
                >
                  <Stack direction={"row"} spacing={1}>
                    <IconButton size="large" component="label">
                      <CameraAltIcon />{" "}
                      <input
                        type="file"
                        id="file"
                        name="file"
                        onChange={(e) => {
                          const file = e.currentTarget.files[0];
                          if (file && file.type.indexOf("image") >= 0) {
                            setFieldValue("file", e.currentTarget.files[0]);
                          }
                        }}
                        hidden
                      />
                    </IconButton>
                    <IconButton size="large" type="submit" disabled={!values.file}>
                      <FileUploadIcon />
                    </IconButton>
                  </Stack>
                </Stack>
                <Box height={5}>{loading && <LinearProgress />}</Box>
                <Divider></Divider>
                {images.length === 0 && <VerticalAlertBoxCollections.NoPhoto />}
                <ImageList
                  variant="standard"
                  cols={3}
                  gap={8}
                  rowHeight={300}
                  sx={{
                    overflow: "auto",
                    flexDirection: "row",
                  }}
                >
                  {images.map((img, idx) => {
                    const { id, url, compressed_url, file_name, label, created } = img;
                    const isFeatuerd = idx === 0;
                    return (
                      <ImageListItem
                        key={file_name}
                        sx={{
                          "&:hover": {
                            ".image-list-item-bar-container": {
                              opacity: 1,
                            },
                          },
                          borderRadius: 1,
                          overflow: "hidden",
                        }}
                      >
                        <img srcSet={`${compressed_url}`} src={`${compressed_url}`} alt={compressed_url} loading="lazy" />
                        <ImageListItemBar
                          position="top"
                          className="image-list-item-bar-container"
                          title={
                            <Box>
                              <Stack alignItems={"center"}>
                                <Typography fontWeight={"bold"}>{isFeatuerd ? "Profile Image" : ""}</Typography>
                                <Typography fontWeight={"bold"} fontSize={10}>
                                  {label}
                                </Typography>
                                <Typography fontSize={10}>{moment(created).format("LLL")}</Typography>
                              </Stack>
                              <Stack direction={"row"} justifyContent={"center"} spacing={0} mr={1}>
                                <IconButton
                                  size="small"
                                  onClick={(e) => {
                                    Actions.previewImage(url);
                                  }}
                                  sx={{
                                    color: colors.grey[50],
                                  }}
                                >
                                  <FullscreenIcon />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  sx={{
                                    color: isFeatuerd ? colors.amber[300] : colors.grey[300],
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    Actions.featureImage(farmId, id);
                                  }}
                                >
                                  <StarIcon />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  color="error"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    Actions.deleteImageConfirm(img);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Stack>
                            </Box>
                          }
                          // title={isFeatuerd ? "Profile Image" : ""}
                          // subtitle={moment(created).format("LLL")}
                          sx={{
                            opacity: 0,
                            transition: "all .2s",
                            bgcolor: "#2020207b",
                            backdropFilter: "blur(8px);",
                            height: "100%",
                            "&:hover": {
                              cursor: "pointer",
                            },
                          }}
                        />
                      </ImageListItem>
                    );
                  })}
                </ImageList>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default FarmImages;
