import React, { Component } from "react";
import styled from "styled-components";
import NumberFormat from "react-number-format";
import { COLORMAP } from "../../config";
import { Typography } from "@mui/material";

const Layout = styled.div`
  .price_container {
    font-family: var(--main-font);
    text-align: left;
  }

  .upper {
    display: flex;
    align-items: center;
  }

  .price_arrow,
  .price_current {
    font-weight: 800;
    font-size: 1.8rem;
  }

  .price_current {
    padding: 0 4px;
  }

  .price_change,
  .price_change_pct {
    font-size: 0.8rem;
  }

  .price_date {
    clear: both;
    font-family: var(--main-font);
    font-size: 1rem;
    text-align: left;
    color: #444;
  }

  .small_digit_wrapper {
  }
`;

const styleSelector = (trend) => {
  if (trend === "up") {
    return {
      color: "#00b894",
      icon: "fas fa-arrow-up",
    };
  } else if (trend === "down") {
    return {
      color: "#d63031",
      icon: "fas fa-arrow-down",
    };
  } else {
    return {
      color: COLORMAP.grey,
      icon: "fas fa-circle",
    };
  }
};

const PriceChangeCard = ({ change }) => {
  return (
    <Layout>
      <div className="price_container">
        <div className="upper">
          <div
            style={{
              color: styleSelector(change.trend).color,
            }}
            className="price_arrow"
          >
            <i className={styleSelector(change.trend).icon} />
          </div>
          <div className="price_current">
            <NumberFormat
              value={change.price}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
            />
          </div>
          <div
            className="small_digit_wrapper"
            style={{ color: styleSelector(change.trend).color }}
          >
            <div className="price_change">{change.change}</div>
            <div className="price_change_pct">{change.change_pct}</div>
          </div>
        </div>
        <Typography>{change.date}</Typography>
      </div>
    </Layout>
  );
};

export default PriceChangeCard;
