import React from "react";
import styled from "styled-components";

import { AppSettings } from "helpers/LocalStorage";

import ShrimplHomePage from "screens/HomPages/ShrimplHomePage";
import { Roles } from "configs/appConfigs";
import AdminHomePage from "screens/HomPages/AdminHomePage";
import UserRoles from "constants/UserRoles";

// ===============
// --+-- CSS --+--
// ===============

const Layout = styled.div`
  height: 100%;
  overflow: auto;
  position: relative;

  background: var(--color-light-background);

  .hp_fullpage {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .hp_section {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 1s;
  }

  .hp_spotlight {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-areas: "l r";
    background: var(--color-light-background);
    height: 600px;
  }

  .hp_spotlight_img {
    background-position: center;
    background-size: cover;
  }

  @keyframes backgroundScaleIn {
    0% {
      background-size: 100%;
    }
    100% {
      background-size: 105%;
    }
  }

  @keyframes backgroundFadeIn {
    0% {
      background: var(--main-color);
    }
    100% {
      background: var(--main-color-o);
    }
  }

  @keyframes goDownEffect {
    0% {
      transform: translateY(-20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
`;

const HomePage = () => {
  const role = AppSettings.Auth.getRole();
  return (
    <Layout pageHeight={window.innerHeight}>
      {role === Roles.SHRIMPSTAR_USER && <ShrimplHomePage />}
      {role === Roles.INTERNAL && <AdminHomePage />}
      {role === Roles.SHRIMPL_USER && <ShrimplHomePage />}
    </Layout>
  );
};

export default HomePage;
