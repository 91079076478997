import { Box, colors } from "@mui/material";
import HighchartsWrapper from "components/Highcharts/HighchartsWrapper";
import _ from "lodash";
import React from "react";
import { useDispatch } from "react-redux";
import { googleMapManagementActions } from "redux/pondManagement/googleMapManagement";

const HistogramChart = ({ data, ponds }) => {
  // validate data
  if (data.length === 0) return "";

  const dispatch = useDispatch();
  const Actions = {
    highlight: (pondIds) => {
      const highlightPonds = ponds.filter((p) => _.includes(pondIds, p.id));
      googleMapManagementActions.highlightMultiPonds(dispatch, highlightPonds);
    },
  };

  const chartConfigs = {
    title: {
      text: "",
    },
    xAxis: [
      {
        title: { text: "Data" },
        alignTicks: false,
        opposite: true,
        visible: false,
      },
      {
        title: { text: "Histogram" },
        opposite: false,
      },
    ],
    yAxis: [
      {
        title: { text: "Data" },
        visible: false,
      },
      {
        title: { text: "Histogram" },
      },
    ],
    tooltip: {
      useHTML: true,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      histogram: {
        tooltip: {
          headerFormat: "<table>",
          footerFormat: "</table>",
          pointFormatter: function () {
            const min = _.round(this.x, 2);
            const max = _.round(this.x + this.series.binWidth, 2);
            const count = this.y;
            return `
            <tr><td><strong>RANGE</strong>:</td><td>${min} ~ ${max}</td></tr>
            <tr><td><strong>COUNT</strong>:</td><td>${count} Ponds</td></tr>
            `;
          },
        },
        point: {
          events: {
            click: function (e) {
              const step = e.point.series.binWidth;
              const rangeMin = e.point.x;
              const rangeMax = e.point.x + step;
              const filteredPonds = data.filter(
                (ele) => ele.value >= rangeMin && ele.value < rangeMax
              );
              Actions.highlight(filteredPonds.map((ele) => ele.pondId));
            },
          },
        },
      },
    },
    series: [
      {
        name: "Histogram",
        type: "histogram",
        xAxis: 1,
        yAxis: 1,
        baseSeries: "s1",
        zIndex: -1,
        binsNumber: 10,
        color: colors.blue[800],
      },
      {
        color: colors.orange[800],
        name: "Data",
        type: "scatter",
        data: data.map((d) => d.value),
        id: "s1",
        marker: {
          radius: 3,
        },
      },
    ],
  };

  return (
    <Box>
      <HighchartsWrapper options={chartConfigs} />
    </Box>
  );
};

export default HistogramChart;
