import { Box, Button, Chip, CircularProgress, Grid, IconButton, Stack, TextField, Typography, colors } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { PondManagerServices } from "api/pondManagerServices";
import { ArrowForwardIcon, CloseIcon, DeleteIcon, EditNoteIcon, PeopleIcon, RefreshIcon } from "components/Icons/MaterialIcons";
import Text, { getText } from "components/text/Text";
import { Form, Formik } from "formik";
import TimeFormatters from "helpers/TimeFormatters";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { alertsActions } from "redux/alerts";
import OrganisationAdminGroupDetails from "screens/Aquaculture/Organisations/OrganisationAdminGroupDetails";

const AddGroupForm = ({ org, postSubmit }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const Actions = {
    onSubmit: async (form) => {
      setLoading(true);
      const formData = {
        name: form.name,
      };
      const rsp = await PondManagerServices.addGroup(org.id, formData);
      if (rsp?.error) {
        alertsActions.addError(dispatch, {
          content: rsp?.error,
        });
      } else {
        alertsActions.addInfo(dispatch, {
          content: "Group Added",
        });
        postSubmit();
      }
      setLoading(false);
    },
  };

  return (
    <Formik
      initialValues={{
        name: "",
      }}
      onSubmit={Actions.onSubmit}
    >
      {({ values, handleChange, setFieldValue }) => {
        return (
          <Form>
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <TextField
                label="Name"
                value={values.name}
                name="name"
                onChange={handleChange}
                size="small"
                sx={{
                  minWidth: 500,
                }}
                required
              />
              <Stack direction={"row"} spacing={1}>
                {["Technical", "Finance", "Admin", "Other", "Inspector"].map((term) => (
                  <Chip
                    size="small"
                    variant="outlined"
                    label={term}
                    onClick={() => setFieldValue("name", term)}
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                        bgcolor: colors.grey[300],
                      },
                    }}
                  ></Chip>
                ))}
              </Stack>

              <Box>
                <Button
                  type="submit"
                  variant="outlined"
                  startIcon={loading && <CircularProgress size={20} />}
                  sx={{
                    width: 200,
                  }}
                >
                  <Text>interface.actions.create</Text>
                </Button>
              </Box>
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
};

const OrganisationAdminGroupList = ({ org }) => {
  const [colDefs, setColDefs] = useState([
    {
      flex: 1,
      headerName: "Created",
      field: "created_at",
      cellRenderer: ({ data }) => {
        return TimeFormatters.formatUtcDatetime(data.created_at);
      },
    },
    // {
    //   flex: 1,
    //   headerName: "ID",
    //   field: "id",
    // },
    {
      flex: 2,
      headerName: "Name",
      field: "name",
      sortable: true,
      editable: true,
      cellRenderer: ({ data }) => {
        return (
          <Stack
            direction={"row"}
            alignItems={"center"}
            sx={{
              "&:hover": {
                cursor: "pointer",
                ".icon": {
                  color: colors.grey[800],
                },
              },
            }}
          >
            <EditNoteIcon
              className="icon"
              sx={{
                color: colors.grey[500],
              }}
            />
            <Box>{data.name}</Box>
          </Stack>
        );
      },
    },
    {
      flex: 1,
      headerName: "Actions",
      cellRenderer: ({ data }) => {
        return (
          <Box pt={0.4}>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <IconButton
                size="small"
                color="error"
                onClick={() => {
                  Actions.onDeleteGroup(data.id);
                }}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                size="small"
                color="info"
                onClick={() => {
                  setGroup(data);
                  // Actions.onDeleteGroup(data.id);
                }}
              >
                <ArrowForwardIcon />
              </IconButton>
            </Stack>
          </Box>
        );
      },
    },
  ]);

  const [data, setData] = useState([]);
  const [selectedGroup, setGroup] = useState(null);

  const dispatch = useDispatch();

  const Actions = {
    fetchGroups: async () => {
      const res = await PondManagerServices.getGroups(org.id);
      setData(res);
    },
    onDeleteGroup: async (groupId) => {
      const confirmed = window.confirm(getText("interface.alert.alert-are-you-sure"));
      if (confirmed) {
        setGroup(null);
        await PondManagerServices.removeOrgGroup(org.id, groupId);
        alertsActions.addInfo(dispatch, {
          content: "Group is removed",
        });
        Actions.fetchGroups();
      }
    },
    onUpdateGroup: async (group) => {
      await PondManagerServices.updateOrgGroup(org.id, group);
      alertsActions.addInfo(dispatch, {
        content: "Group is updated",
      });
    },
    onCellValueChanged: ({ data, value }) => {
      if (value) {
        Actions.onUpdateGroup({
          id: data.id,
          name: data.name,
        });
      } else {
        Actions.fetchGroups();
      }
    },
  };

  useEffect(() => {
    Actions.fetchGroups();
  }, [org]);

  return (
    <Box>
      <Box my={2}>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <AddGroupForm org={org} postSubmit={Actions.fetchGroups} />
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <IconButton onClick={Actions.fetchGroups}>
              <RefreshIcon />
            </IconButton>
          </Stack>
        </Stack>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={selectedGroup ? 8 : 12}>
          <Box height={600} className="ag-theme-quartz">
            <AgGridReact columnDefs={colDefs} rowData={data} overlayNoRowsTemplate={"NO GROUPS"} onCellValueChanged={Actions.onCellValueChanged} />
          </Box>
        </Grid>
        {selectedGroup && (
          <Grid item xs={4}>
            <Box
              sx={{
                borderRadius: 1,
                border: "1px solid #DDD",
                height: 600,
                overflow: "auto",
                p: 2,
                boxSizing: "border-box",
              }}
            >
              <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>
                <Typography>
                  <strong>Group:</strong> {selectedGroup.name}
                </Typography>
                <IconButton onClick={() => setGroup(null)}>
                  <CloseIcon></CloseIcon>
                </IconButton>
              </Stack>
              <OrganisationAdminGroupDetails key={selectedGroup?.id} org={org} group={selectedGroup} />
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default OrganisationAdminGroupList;
