import { Box, Grid, Stack } from "@mui/material";
import { PondManagerServices } from "api/pondManagerServices";
import { VerticalAlertBoxCollections } from "components/Alerts/AlertBox";
import NumericFormatters from "helpers/NumericFormatters";
import React, { useEffect, useState } from "react";
import MuiIconNumberCard from "ui/Card/MuiIconNumberCard";

const AquaExplorerAdminHome = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    Actions.onLoad();
  }, []);

  const Actions = {
    onLoad: async () => {
      const result = await PondManagerServices.fetchAquaExplorerAdminStats();
      setData(result);
    },
  };

  if (!data) return <VerticalAlertBoxCollections.NoData />;

  const keyMetrics = {
    totalPonds: data?.ponds?.total,
    totalAois: data?.aoi?.total,
  };

  return (
    <Box p={2}>
      <Stack direction={"row"} spacing={2}>
        <MuiIconNumberCard title="Total Ponds" value={NumericFormatters.format({ value: keyMetrics.totalPonds })} />
        <MuiIconNumberCard title="Total AOIs" value={NumericFormatters.format({ value: keyMetrics.totalAois })} />
      </Stack>
    </Box>
  );
};

export default AquaExplorerAdminHome;
