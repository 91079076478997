import { Box, Button, Stack, colors } from "@mui/material";
import { PondManagerServices } from "api/pondManagerServices";
import { VerticalAlertBoxCollections } from "components/Alerts/AlertBox";
import CommonDialog, { CommonDialogIds } from "components/Dialogs/CommonDialog";
import { AddCircleIcon, ArrowBackIcon, RefreshIcon } from "components/Icons/MaterialIcons";
import CommonModal from "components/Modal/CommonModal";
import PondManagerAccessWrapper from "components/Wrapper/PondManagerAccessWrapper";
import Text, { getText } from "components/text/Text";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { alertsActions } from "redux/alerts";
import { dialogReducerActions } from "redux/dialog";
import { modalIds, modalReducersActions } from "redux/modal";
import { ResourcePolicies } from "screens/Aquaculture/components/AccessControl/ResourcePolicies";
import ScenarioBuilderInterface from "screens/Aquaculture/components/ScenarioBuilder/components/ScenarioBuilderInterface";
import ScenarioForm from "screens/Aquaculture/components/ScenarioBuilder/components/ScenarioForm";
import ScenariosTable from "screens/Aquaculture/components/ScenarioBuilder/components/ScenariosTable";

const ScenarioBuilder = () => {
  const [list, setList] = useState([]);
  const [viewScenario, setViewScenario] = useState(null);
  const [scenarioFormData, setSceanrioFormData] = useState(null);

  const dispatch = useDispatch();

  const params = useParams();
  const orgid = params.orgid;
  const farmid = params.farmid;
  const viewAsOrg = orgid && farmid;

  const Actions = {
    fetchScenarios: async () => {
      if (viewAsOrg) {
        const result = await PondManagerServices.fetchOrgScenarios({ farmid, orgid });
        setList(result.rows);
      } else {
        const result = await PondManagerServices.fetchFarmScenarios({ farmid });
        setList(result.rows);
      }
    },
    handleFormSubmit: async (values) => {
      // to create a scenario
      if (!scenarioFormData) {
        if (!viewAsOrg) {
          const formData = {
            label: values.label,
            tags: values.tags,
            note: values.note,
          };
          await PondManagerServices.createFarmScenario({ farmid, form: formData });
          alertsActions.addInfo(dispatch, {
            content: getText("interface.alert.alert-success"),
          });
          modalReducersActions.closeModal(dispatch, { id: modalIds.SCENARIO_FORM });
          Actions.fetchScenarios();
        } else {
          const formData = {
            label: values.label,
            tags: values.tags,
            note: values.note,
          };
          await PondManagerServices.createOrgScenario({ farmid, orgid, form: formData });
          alertsActions.addInfo(dispatch, {
            content: getText("interface.alert.alert-success"),
          });
          modalReducersActions.closeModal(dispatch, { id: modalIds.SCENARIO_FORM });
          Actions.fetchScenarios();
        }
      }
      // to update a scenario
      else {
        if (!viewAsOrg) {
          const formData = {
            id: scenarioFormData.id,
            label: values.label,
            tags: values.tags,
            note: values.note,
          };
          await PondManagerServices.updateFarmScenario({ farmid, form: formData });
          alertsActions.addInfo(dispatch, {
            content: getText("interface.alert.alert-success"),
          });
          modalReducersActions.closeModal(dispatch, { id: modalIds.SCENARIO_FORM });
          Actions.fetchScenarios();
        } else {
          const formData = {
            id: scenarioFormData.id,
            label: values.label,
            tags: values.tags,
            note: values.note,
          };
          await PondManagerServices.updateOrgScenario({ farmid, orgid, form: formData });
          alertsActions.addInfo(dispatch, {
            content: getText("interface.alert.alert-success"),
          });
          modalReducersActions.closeModal(dispatch, { id: modalIds.SCENARIO_FORM });
          Actions.fetchScenarios();
        }
      }
    },
    handleDeleteConfirm: async (scenario) => {
      setSceanrioFormData(scenario);
      dialogReducerActions.openCommonDialog(dispatch, { id: CommonDialogIds.SCENARIO_CONFIRM_DELETE });
    },
    handleDelete: async () => {
      if (scenarioFormData) {
        if (!viewAsOrg) {
          await PondManagerServices.removeFarmScenario({ farmid, id: scenarioFormData.id });
        } else {
          await PondManagerServices.removeOrgScenario({ farmid, orgid, id: scenarioFormData.id });
        }
        Actions.fetchScenarios();
      }
    },
    openScenarioForm: async (formdata) => {
      setSceanrioFormData(formdata);
      modalReducersActions.openModal(dispatch, { id: modalIds.SCENARIO_FORM });
    },
    handleSelectView: (data) => {
      setViewScenario(data);
    },
  };
  useEffect(() => {
    Actions.fetchScenarios();
  }, [farmid, orgid]);

  if (viewScenario) {
    return (
      <Box p={2}>
        <Button
          size="small"
          onClick={() => {
            setViewScenario(null);
            Actions.fetchScenarios();
          }}
          startIcon={<ArrowBackIcon />}
        >
          <Text>interface.actions.return</Text>
        </Button>
        <Box>
          <ScenarioBuilderInterface scenario={viewScenario} viewAsOrg={viewAsOrg} />
        </Box>
      </Box>
    );
  }

  return (
    <PondManagerAccessWrapper policies={[ResourcePolicies.SCENARIOBUILDER]}>
      <Box p={2}>
        <CommonModal id={modalIds.SCENARIO_FORM}>
          <ScenarioForm scenario={scenarioFormData} onSubmit={Actions.handleFormSubmit} />
        </CommonModal>
        <CommonDialog id={CommonDialogIds.SCENARIO_CONFIRM_DELETE} action={Actions.handleDelete} title={getText("interface.alert.alert-are-you-sure")} />
        <Box mb={2}>
          <Stack direction="row" spacing={1} alignItems={"center"}>
            <Button variant="contained" startIcon={<AddCircleIcon />} onClick={() => Actions.openScenarioForm()}>
              <Text>interface.actions.create</Text>
            </Button>
            <Button variant="outlined" startIcon={<RefreshIcon />} onClick={Actions.fetchScenarios}>
              <Text>interface.actions.refresh</Text>
            </Button>
          </Stack>
        </Box>
        {list.length > 0 && <ScenariosTable rows={list} actions={Actions} />}
        {list.length === 0 && (
          <Box p={2} py={4} bgcolor={colors.grey[50]} borderRadius={2}>
            <Stack>
              <VerticalAlertBoxCollections.NothingShow />
            </Stack>
          </Box>
        )}
      </Box>
    </PondManagerAccessWrapper>
  );
};

export default ScenarioBuilder;
