import { Box, Button, Stack, Typography } from "@mui/material";

import { AgGridReact } from "ag-grid-react";

import { PondManagerServices } from "api/pondManagerServices";
import MuiDatePicker from "components/DatePicker/MuiDatePicker";
import LoadingBox from "components/Loading/LoadingBox";
import Text from "components/text/Text";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppSelectors } from "redux/AppReducers";
import palette from "themes/palette";

const helpers = {
  generateTableConfigs: (data, groupedData, endDate, farmFinancialFields) => {
    const columnNumbers = data.length;
    const yearmonthList = [
      endDate.clone().add(-11, "month"),
      endDate.clone().add(-10, "month"),
      endDate.clone().add(-9, "month"),
      endDate.clone().add(-8, "month"),
      endDate.clone().add(-7, "month"),
      endDate.clone().add(-6, "month"),
      endDate.clone().add(-5, "month"),
      endDate.clone().add(-4, "month"),
      endDate.clone().add(-3, "month"),
      endDate.clone().add(-2, "month"),
      endDate.clone().add(-1, "month"),
      endDate.clone(),
    ];
    const numberCellRender = (params) => {
      if (typeof params.value === "number") {
        if (params.value === 0) return "-";
        if (params.value > 0) return params.value.toLocaleString();
        else return <span style={{ color: "red", textAlign: "right", width: "100%" }}>({(params.value * -1).toLocaleString()})</span>;
      }
      return params.value;
    };
    const numberCellWidth = 120;
    const columnDefs = [
      { field: "0", headerName: "", pinned: "left" },
      ...yearmonthList.map((ym, idx) => ({
        headerName: `Month ${idx + 1}`,
        children: [
          {
            width: numberCellWidth,
            field: `${idx + 1}`,
            headerName: ym.format("MMM-YY"),
            cellRenderer: numberCellRender,
            cellStyle: { textAlign: "right" },
          },
        ],
      })),
      {
        width: numberCellWidth,
        field: "13",
        headerName: "Total",
        cellRenderer: numberCellRender,
        cellStyle: { textAlign: "right" },
        pinned: "right",
      },
    ];
    const rowDataRow = [
      [
        "Revenue",
        ...yearmonthList.map(
          (el) =>
            _.chain(groupedData)
              .find({ key: "Income" })
              .get("value")
              .find({ yearmonth: el.format("YYYY-MM-01") })
              .get("value")
              .value() || 0
        ),
        _.chain(groupedData).find({ key: "Income" }).get("value").sumBy("value").value() || 0,
      ],
      [],
      [
        "Direct Costs (Variable)",
        ...yearmonthList.map(
          (el) =>
            _.chain(groupedData)
              .find({ key: "Variable" })
              .get("value")
              .find({ yearmonth: el.format("YYYY-MM-01") })
              .get("value")
              .value() || 0
        ),
        _.chain(groupedData).find({ key: "Variable" }).get("value").sumBy("value").value() || 0,
        ,
      ],
      ...farmFinancialFields
        .filter((ele) => ele.field_sub_group === "Variable")
        .map((ele) => [
          `|--- ${ele.field_name}`,
          ...yearmonthList.map(
            (el) =>
              _.chain(data)
                .find({ yearmonth: el.format("YYYY-MM-01") })
                .get("list")
                .find({ field: { field_id: ele.field_id } })
                .get("value")
                .value() || 0
          ),
          _.chain(data)
            .map("list")
            .flatten()
            .filter({ field: { field_id: ele.field_id } })
            .map("value")
            .sum()
            .value(),
        ]),
      [],
      [
        "Gross Profit",
        ...yearmonthList.map(
          (el) =>
            _.chain(groupedData)
              .find({ key: "Gross Profit" })
              .get("value")
              .find({ yearmonth: el.format("YYYY-MM-01") })
              .get("value")
              .value() || 0
        ),
        _.chain(groupedData).find({ key: "Gross Profit" }).get("value").sumBy("value").value() || 0,
      ],
      [],
      [
        "Indirect Costs (Fixed)",
        ...yearmonthList.map(
          (el) =>
            _.chain(groupedData)
              .find({ key: "Fixed" })
              .get("value")
              .find({ yearmonth: el.format("YYYY-MM-01") })
              .get("value")
              .value() || 0
        ),
        "",
      ],
      ...farmFinancialFields
        .filter((ele) => ele.field_sub_group === "Fixed")
        .map((ele) => [
          `|--- ${ele.field_name}`,
          ...yearmonthList.map(
            (el) =>
              _.chain(data)
                .find({ yearmonth: el.format("YYYY-MM-01") })
                .get("list")
                .find({ field: { field_id: ele.field_id } })
                .get("value")
                .value() || 0
          ),
          _.chain(data)
            .map("list")
            .flatten()
            .filter({ field: { field_id: ele.field_id } })
            .map("value")
            .sum()
            .value(),
          ,
        ]),
      [],
      [
        "Operational EBITDA",
        ...yearmonthList.map(
          (el) =>
            _.chain(groupedData)
              .find({ key: "Operational EBITDA" })
              .get("value")
              .find({ yearmonth: el.format("YYYY-MM-01") })
              .get("value")
              .value() || 0
        ),
        _.chain(groupedData).find({ key: "Operational EBITDA" }).get("value").sumBy("value").value() || 0,
        ,
      ],
      [],
    ];
    const rowData = rowDataRow.map((ele) => ele.map((ele, idx) => [idx, ele]).reduce((a, b) => ({ ...a, ...{ [`${b[0]}`]: b[1] } }), {}));
    return {
      rowData,
      columnDefs,
      headerHeight: 0,
    };
  },
};

const ProfitLossReport = () => {
  const [startDate, setStartDate] = useState(moment().add(-1, "year").startOf("year"));
  const [endDate, setEndDate] = useState(moment().add(-1, "year").endOf("year"));
  const [tableConfigs, setTableConfigs] = useState(null);
  const [loading, setLoading] = useState(false);

  const params = useParams();
  const { farmid } = params;

  const pondManagerStore = AppSelectors.pondManagementStore();
  const farmFinancialFields = _.orderBy(
    pondManagerStore.fields.filter((ele) => ele.field_type === "farm" && !ele.is_derived && ele.field_unit === "$"),
    ["field_group", "field_sub_group", "field_label", "position"]
  );

  farmFinancialFields.map;

  useEffect(() => {
    Actions.onLoadData();
  }, [farmid]);

  const Actions = {
    onLoadData: async () => {
      setLoading(true);
      const result = await PondManagerServices.getFarmTimeseriesData(
        farmid,
        farmFinancialFields.map((ele) => ele.field_id),
        startDate.format("YYYY-MM-DD"),
        endDate.format("YYYY-MM-DD")
      );
      const rawData = result.rows;
      const data = _.chain(rawData)
        .groupBy((x) => moment(x["datetime"]).format("YYYY-MM-01"))
        .map((v, k) => ({
          yearmonth: k,
          list: farmFinancialFields.map((f) => ({
            field: f,
            value: (f.field_sub_group === "Income" ? 1 : -1) * _.sumBy(v, f.field_id) || 0,
          })),
        }))
        .value();
      const groupedData = [
        ...["Fixed", "Variable", "Income"].map((g) => ({
          key: g,
          value: data.map((row) => ({
            yearmonth: row.yearmonth,
            value: _.sum(row.list.filter((ele) => ele.field.field_sub_group === g).map((ele) => ele.value)),
          })),
        })),
        {
          key: "Gross Profit",
          value: data.map((row) => ({
            yearmonth: row.yearmonth,
            value: _.sum(row.list.filter((ele) => ele.field.field_sub_group === "Income" || ele.field.field_sub_group === "Variable").map((ele) => ele.value)),
          })),
        },
        {
          key: "Operational EBITDA",
          value: data.map((row) => ({
            yearmonth: row.yearmonth,
            value: _.sum(row.list.filter((ele) => ele.field.field_sub_group === "Income" || ele.field.field_sub_group === "Variable" || ele.field.field_sub_group === "Fixed").map((ele) => ele.value)),
          })),
        },
      ];

      const tConfigs = helpers.generateTableConfigs(data, groupedData, endDate, farmFinancialFields);
      setTableConfigs(tConfigs);
      setLoading(false);
    },
  };

  return (
    <Box p={2}>
      <Typography variant="h5">Profit & Loss Report</Typography>
      <Typography variant="caption">
        <Text>interface.general.farm</Text>#: {farmid}
      </Typography>
      <Box my={2}>
        <Stack direction={"row"} spacing={2}>
          <MuiDatePicker value={startDate} label="Start Date" size="small" disabled />
          <MuiDatePicker
            value={endDate}
            onAccept={(newValue) => {
              setStartDate(newValue.clone().add(-12, "month"));
              setEndDate(newValue);
            }}
            onChange={(newValue) => {
              setStartDate(newValue.clone().add(-12, "month"));
              setEndDate(newValue);
            }}
            label="End Date"
            size="small"
          />
          <Button onClick={Actions.onLoadData}>Generate</Button>
        </Stack>
      </Box>
      <Box my={2}>
        {loading || !tableConfigs ? (
          <LoadingBox />
        ) : (
          <Box
            height="600px"
            p={1}
            className="ag-theme-excel"
            sx={{
              border: "1px solid #ccc",
              borderRadius: 1,
              overflow: "hidden",
            }}
          >
            <AgGridReact {...tableConfigs} rowHeight={22} headerHeight={30}></AgGridReact>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ProfitLossReport;
