import { Box, Button, Drawer, IconButton, Stack, TextField, Typography } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { PondManagerServices } from "api/pondManagerServices";
import { ShrimplFieldCellRenderer } from "components/AgGridExtensions/CustomCellRenderer";
import MuiDatetimePicker from "components/DatePicker/MuiDatetimePicker";
import HighchartsWrapper from "components/Highcharts/HighchartsWrapper";
import { AddIcon, DeleteIcon } from "components/Icons/MaterialIcons";
import { MuiBorderIconButton } from "components/buttons/MuiButtonVariants";
import FarmingProductSelector from "components/input/FarmingProductSelector";
import Text, { getText } from "components/text/Text";
import FarmingProductTypes from "constants/FarmingProductTypes";
import { Form, Formik } from "formik";
import { getColorByFieldId } from "helpers/ColorHelpers";
import Formatters from "helpers/Formatters";
import { formatValueByFieldId } from "helpers/NumericFormatters";
import TimeFormatters from "helpers/TimeFormatters";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppSelectors } from "redux/AppReducers";
import { alertsActions } from "redux/alerts";

const getExtraFields = (fields, field) => {
  if (field.field_id === "weight") {
    return [_.find(fields, { field_id: "current_density" })];
  } else if (field.field_id === "current_density") {
    return [_.find(fields, { field_id: "weight" })];
  } else if (field.field_id === "feed_daily") {
    return [_.find(fields, { field_id: "feed_product" })];
  } else {
    return [];
  }
};

/**
 *
 * A simple data chart
 *
 * @param {*} param0
 * @returns
 */
const DataChart = ({ data, field, cycle, color }) => {
  if (!data) return "";

  const fieldId = field.field_id;
  const fieldName = getText(`fields.${fieldId}`);
  const dataToDisplay = data.filter((ele) => ele?.[fieldId]);

  const generateSeries = () => {
    const data = dataToDisplay.map((ele, idx) => ({
      x: TimeFormatters.convertUtcDatetimeStringToValue(ele.datetime),
      y: ele?.[fieldId],
    }));
    if (fieldId === "feed_daily") {
      return {
        name: fieldName,
        type: "column",
        color: color,
        data: data,
      };
    } else {
      return {
        name: fieldName,
        type: "areaspline",
        color: color,
        fillOpacity: 0.1,
        data: data,
      };
    }
  };

  const configs = {
    chart: {
      height: "200px",
      animation: false,
    },
    xAxis: {
      type: "datetime",
      min: Formatters.toLocalDatetimeValue(cycle?.start_date),
      max: Formatters.toLocalDatetimeValue(cycle?.end_date),
    },
    yAxis: {
      title: {
        text: "",
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        animation: false,
      },
    },
    series: [generateSeries()],
  };
  return (
    <Box>
      <HighchartsWrapper options={configs} />
    </Box>
  );
};

/**
 *
 * A simple data table
 *
 * @param {*} param0
 * @returns
 */
const DataTable = ({ data, pond, field, onDeleteData }) => {
  const pondManagementStore = AppSelectors.pondManagementStore();
  const { fields, registeredProducts } = pondManagementStore;
  const extraFields = getExtraFields(fields, field);

  const fieldId = field.field_id;
  const fieldName = getText(`fields.${fieldId}`);
  const uploadable = !field.is_derived && field.bulk_upload_enabled;
  const dataToDisplay = _.orderBy(
    data.filter((ele) => ele?.[fieldId]),
    "datetime",
    "desc"
  );
  const [dataRows, setDataRows] = useState(dataToDisplay);
  const [colDefs, setColDefs] = useState([
    {
      field: "datetime",
      headerName: getText("interface.time.timestamp"),
      flex: 1,
    },
    {
      field: fieldId,
      headerName: fieldName,
      flex: 1,
      cellRenderer: (params) => ShrimplFieldCellRenderer(params, { registeredProducts }),
    },
    ...extraFields.map((ele) => ({
      field: ele.field_id,
      headerName: getText(`fields.${ele.field_id}`),
      flex: 1,
      cellRenderer: (params) => ShrimplFieldCellRenderer(params, { registeredProducts }),
      cellStyle: {
        color: "#aaa",
      },
    })),
    {
      field: "datetime",
      headerName: "",
      width: 50,
      cellRenderer: ({ data }) => (
        <Stack direction={"row"} spacing={0.3}>
          {/* <MuiBorderIconButton size={"sm"} onClick={() => actions.onEditProduct({ product: data })} icon={<EditIcon />} /> */}
          {uploadable && (
            <MuiBorderIconButton
              size={"sm"}
              onClick={() =>
                onDeleteData([
                  {
                    pond_id: pond?.id,
                    datetime: data.datetime,
                    field_id: field?.field_id,
                    _toDelete: true,
                  },
                  ...extraFields.map((ele) => ({
                    pond_id: pond?.id,
                    datetime: data.datetime,
                    field_id: ele?.field_id,
                    _toDelete: true,
                  })),
                ])
              }
              icon={<DeleteIcon />}
            />
          )}
        </Stack>
      ),
      cellStyle: { display: "flex", alignItems: "center", justifyContent: "center" },
      hide: !uploadable,
    },
  ]);

  useEffect(() => {
    const dataToDisplay = _.orderBy(
      data.filter((ele) => ele?.[fieldId]),
      "datetime",
      "desc"
    );
    setDataRows(dataToDisplay);
  }, [data]);

  return (
    <Box
      className="ag-theme-excel"
      sx={{
        height: 300,
      }}
    >
      <AgGridReact
        rowData={dataRows}
        columnDefs={colDefs}
        headerHeight={28}
        rowHeight={30}
        defaultColDef={{
          suppressMovable: true,
        }}
      />
    </Box>
  );
};

/**
 *
 * A simple data input panel
 *
 * @param {*} param0
 * @returns
 */
const DataInputPanel = ({ pond, field, cycle, onSubmit }) => {
  const pondManagementStore = AppSelectors.pondManagementStore();
  const { fields } = pondManagementStore;

  const TheInputField = ({ field, handleChange, setFieldValue }) => {
    if (field.field_id === "feed_product") {
      return (
        <Box>
          <Typography fontSize={14} fontWeight={800} color="#000">
            <Text>{`fields.${field?.field_id}`}</Text> <span style={{ color: "grey" }}>{field?.field_unit}</span>
          </Typography>
          <FarmingProductSelector
            productType={FarmingProductTypes.FEED.value}
            name="feed_product"
            onChange={(e) => {
              setFieldValue("feed_product", e.target.value);
            }}
            farmid={pond?.farm_id}
          />
        </Box>
      );
    }
    return (
      <Box>
        <Typography fontSize={14} fontWeight={800} color="#000">
          <Text>{`fields.${field?.field_id}`}</Text> <span style={{ color: "grey" }}>{field?.field_unit}</span>
        </Typography>
        <TextField
          required
          variant="standard"
          name={field.field_id}
          type="number"
          onChange={handleChange}
          fullWidth
          placeholder="0.00"
          sx={{
            fontSize: 50,
            "&:after": {
              border: 0,
            },
          }}
          inputProps={{ style: { fontSize: 30 }, step: 0.01 }}
        />
      </Box>
    );
  };

  const extraFields = getExtraFields(fields, field);

  return (
    <Box
      p={2}
      sx={{
        width: "90vw",
        boxSizing: "border-box",
      }}
    >
      <Formik
        initialValues={{
          datetime: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
          [field.field_id]: null,
        }}
        onSubmit={(values) => {
          const payload = [
            {
              pond_id: pond?.id,
              field_id: field?.field_id,
              datetime: values.datetime,
              value: values[field?.field_id],
            },
            ...extraFields.map((f) => ({
              pond_id: pond?.id,
              field_id: f?.field_id,
              datetime: values.datetime,
              value: values[f?.field_id],
            })),
          ];
          onSubmit && onSubmit(payload);
        }}
        validateOnBlur={false}
      >
        {({ values, handleChange, setFieldValue }) => {
          return (
            <Form>
              <Stack spacing={1}>
                <MuiDatetimePicker
                  value={moment(values.datetime)}
                  onAccept={(newValue) => {
                    setFieldValue("datetime", newValue.format("YYYY-MM-DD HH:mm:ss"));
                  }}
                  onChange={(newValue) => {
                    setFieldValue("datetime", newValue.format("YYYY-MM-DD HH:mm:ss"));
                  }}
                  fullWidth
                />
                <TheInputField field={field} handleChange={handleChange} setFieldValue={setFieldValue} />
                {extraFields.length > 0 && (
                  <>
                    <Typography fontSize={10} color="grey">
                      <Text>interface.actions.you-also-need-to-enter</Text>:
                    </Typography>
                    {extraFields.map((f, idx) => (
                      <TheInputField key={f.field_id} field={f} handleChange={handleChange} setFieldValue={setFieldValue} />
                    ))}
                  </>
                )}
                <Box pt={3}>
                  <Button size="large" variant="contained" type="submit" fullWidth>
                    <Text>interface.actions.submit</Text>
                  </Button>
                </Box>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

const MobilePondViewDataUploader = ({ pond, field }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const cycleStore = AppSelectors.cycleStore();
  const selectedCycle = cycleStore?.selectedCycle?.id ? cycleStore?.selectedCycle : cycleStore?.latestCycles?.[pond?.id];

  const pondManagementStore = AppSelectors.pondManagementStore();
  const { fields } = pondManagementStore;
  const extraFields = getExtraFields(fields, field);

  const uploadable = !field.is_derived && field.bulk_upload_enabled;

  const fieldId = field.field_id;
  const fieldName = getText(`fields.${fieldId}`);
  const color = getColorByFieldId(fieldId);

  const dataToDisplay = data?.filter((ele) => ele?.[fieldId]);
  const latestValue = formatValueByFieldId(_.last(dataToDisplay)?.[fieldId], fieldId);
  const latestValueDt = _.last(dataToDisplay)?.datetime;

  useEffect(() => {
    if (pond?.id === selectedCycle?.pond_id) {
      Actions.loadData();
    }
  }, [pond?.id, selectedCycle?.id]);

  const Actions = {
    loadData: async () => {
      const response = await PondManagerServices.getTimeseriesData(pond?.id, [field.field_id, ...extraFields.map((f) => f.field_id)], selectedCycle?.start_date, selectedCycle?.end_date);
      setData(response.rows);
    },
    toggleDrawer: () => {
      setDrawerOpen(!drawerOpen);
    },
    onSubmitData: async (payload) => {
      const response = await PondManagerServices.putData({
        rows: payload,
      });
      alertsActions.addInfo(dispatch, {
        content: getText("interface.alert.alert-success"),
      });
      Actions.loadData();
      setDrawerOpen(false);
    },
    onDeleteData: async (payload) => {
      console.log(payload);
      const confirmed = window.confirm(getText("interface.alert.alert-are-you-sure"));
      confirmed && Actions.onSubmitData(payload);
    },
  };

  return (
    <Box>
      <Box my={2}>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} px={1}>
          <Stack justifyContent={"center"}>
            <Typography fontSize={12} fontWeight={800} color={color}>
              <Text>{fieldName}</Text>
            </Typography>
            {latestValue ? (
              <Typography fontSize={30} fontWeight={800}>
                {latestValue}
              </Typography>
            ) : (
              <Typography fontSize={26} fontWeight={800} color="#CCC">
                <Text>interface.alert.no-data</Text>
              </Typography>
            )}
            <Typography fontSize={10} fontWeight={800} color="grey">
              {latestValueDt ? TimeFormatters.formatDatetime(latestValueDt) : "--"}
            </Typography>
          </Stack>
          <Box>
            {uploadable ? (
              <IconButton
                sx={{
                  border: "1px solid #777",
                }}
                onClick={Actions.toggleDrawer}
              >
                <AddIcon fontSize="large" />
              </IconButton>
            ) : (
              ""
            )}
          </Box>
        </Stack>
      </Box>
      <Box>{data && <DataChart data={data} pond={pond} field={field} color={color} cycle={selectedCycle} />}</Box>
      <Box>{data && <DataTable data={data} pond={pond} field={field} color={color} cycle={selectedCycle} onDeleteData={Actions.onDeleteData} />}</Box>
      <Drawer anchor={"right"} open={drawerOpen} onClose={Actions.toggleDrawer}>
        <DataInputPanel data={data} pond={pond} field={field} cycle={selectedCycle} onSubmit={Actions.onSubmitData} />
      </Drawer>
    </Box>
  );
};

export default MobilePondViewDataUploader;
