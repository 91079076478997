import { Box, Chip, Stack, Typography, colors } from "@mui/material";
import React from "react";

const Digit4x4Card = ({ title, subTitle, value, subValue, icon, sx }) => {
  return (
    <Box
      sx={{
        p: 1,
        borderRadius: 1,
        minWidth: "180px",
        ...sx,
        "&:hover": {
          bgcolor: colors.grey[100],
        },
      }}
    >
      <Stack direction={"row"} justifyContent={"space-between"} mb={1}>
        <Box>
          <Box>
            <Typography fontSize={20} fontWeight={"bold"} color="primary">
              {value}
            </Typography>
          </Box>
          {subValue && (
            <Typography fontStyle={"italic"} fontSize={12} color="secondary">
              {subValue}
            </Typography>
          )}
        </Box>
        <Box>{icon}</Box>
      </Stack>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        spacing={4}
        alignItems={"flex-end"}
      >
        <Box>
          <Typography color="grey" fontSize={12}>
            {title}
          </Typography>
        </Box>
        {subTitle && (
          <Box>
            <Typography color="grey" fontSize={12}>
              <Chip label={subTitle} size="small" color="primary" />
            </Typography>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default Digit4x4Card;
