import moment from "moment";
import i18next from "i18next";
import { DateTime } from "luxon";

const TimeFormatters = {
  formatUtcDatetimeString: (dtStr, fromFormat = "yyyy-MM-dd HH:mm:ss", toFormat = "yyyy-MM-dd HH:mm:ss") => {
    return DateTime.fromFormat(dtStr, fromFormat, { zone: "utc" })
      .toLocal()
      .setLocale(i18next.language || "en")
      .toFormat(toFormat);
  },
  formatMilliseconds: (ms, toFmt = "yyyy-MM-dd HH:mm:ss") => {
    if (!ms) return "--";
    return DateTime.fromMillis(ms).toFormat(toFmt);
  },
  convertUtcDatetimeStringToValue: (dtStr, dtFmt = "yyyy-MM-dd HH:mm:ss") => {
    return DateTime.fromFormat(dtStr, dtFmt).valueOf();
  },
  formatDatetime: (dtStr, fmt = "yyyy-MM-dd HH:mm:ss", dtFmt = "yyyy-MM-dd HH:mm:ss") => {
    return DateTime.fromFormat(dtStr, dtFmt)
      .setLocale(i18next.language || "en")
      .toFormat(fmt);
  },
  formatDateTimeFromIso: (dtStr, fmt = "yyyy-MM-dd HH:mm:ss") => {
    return DateTime.fromISO(dtStr)
      .setLocale(i18next.language || "en")
      .toFormat(fmt);
  },
  formatLocalDateTimeFromIso: (dtStr, fmt = "yyyy-MM-dd HH:mm:ss") => {
    return DateTime.fromISO(dtStr)
      .toLocal()
      .setLocale(i18next.language || "en")
      .toFormat(fmt);
  },
  formatUtcDatetime: (dtStr, fmt) => {
    moment.locale(i18next.language || "en");
    return moment
      .utc(dtStr)
      .local()
      .format(fmt || "LLLL");
  },
  getMonthFull: (dtStr) => {
    moment.locale(i18next.language || "en");
    return moment(dtStr).format("MMMM");
  },
  getMonthShort: (dtStr) => {
    moment.locale(i18next.language || "en");
    return moment(dtStr).format("MMM");
  },
};

export default TimeFormatters;
