import { ApiGet, ApiPost, plainGet } from "api/apiUtils";
import { plainPost } from "api/apiUtils";

const AuthServicesUrls = {
  LOGIN: "/login",
  OAUTHVERIFY: "/oauth-verify"
};

const AuthServices = {
  login: ({ email, password, longLogin, ipResult }) =>
    plainPost(AuthServicesUrls.LOGIN, { email, password, longLogin, ipResult })
      .then((res) => res.data)
      .catch((err, s) => {
        if (!err.response) {
          return { error: "Server Issue!" };
        }
        if (err.response.status === 401) {
          return { error: "Your username or password is incorrect!" };
        }
        if (err.name === "AxiosError") return { error: err.message}
      }),
  signup: (data) => ApiPost("/signup", data).then((res) => res.data).catch((e) => e.response.data),
  resendEmailConfirmation: (requestId) => ApiPost(`/verify-email?requestId=${requestId}`).then((res) => res.data).catch((e) => e.response.data),
  forgotPassword: (payload) => ApiPost(`/forgot-password`, payload).then((res) => res.data).catch((e) => e.response.data),
  resetPassword: (payload) => ApiPost(`/reset-password`, payload).then((res) => res.data).catch((e) => e.response.data),
  verifyEmail: (token) => ApiGet(`/verify-email?token=${token}`).then((res) => res.data).catch((e) => e.response.data),
  oauthVerify: ({ idToken, platform, role, step }) => plainGet(`${AuthServicesUrls.OAUTHVERIFY}?platform=${platform}&token=${idToken}&role=${role}&step=${step}`).then((res) => res.data.payload).catch((e) => e.response.data),
};

export default AuthServices;