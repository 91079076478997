import { BuildCircleIcon } from "components/Icons/MaterialIcons";
import ScenarioBuilder from "screens/Aquaculture/components/ScenarioBuilder/ScenarioBuilder";
import SideMenuLayout from "screens/Aquaculture/components/Layout/SideMenuLayout";
import Text from "components/text/Text";

export const MENULIST = [
  {
    label: <Text>interface.general.scenario-builder</Text>,
    description: "",
    value: 0,
    icon: <BuildCircleIcon />,
    component: <ScenarioBuilder />,
  },
];

const ScenarioBuilderHome = () => {
  return <SideMenuLayout menulist={MENULIST} title={<Text>interface.general.scenario-builder</Text>} defaultViewMode={0} />;
};

export default ScenarioBuilderHome;
