import { Box, Stack, Typography, colors } from "@mui/material";
import HighchartsWrapper from "components/Highcharts/HighchartsWrapper";
import OutlinedTag from "components/Tags/OutlinedTag";
import { getText } from "components/text/Text";
import _ from "lodash";
import React from "react";
import NumberFormat from "react-number-format";
import { AppSelectors } from "redux/AppReducers";

const FarmHarvestWidget = (props) => {
  // ~~~ Redux ~~~
  const pondManagementStore = AppSelectors.pondManagementStore();

  const selectedGroup = pondManagementStore.selectedGroup;
  const groupColors = pondManagementStore.groupColors;

  const { farmDashboardData, ponds } = props;

  const totalHarvested = farmDashboardData?.aggregated_pond_data?._aggregated?.harvest_biomass?.total;

  const hasGroupSelected = !!selectedGroup;
  const filteredPonds = ponds.filter((p) => p.group === selectedGroup);

  const dataByPond = ponds.map((p) => ({
    label: p.label,
    group: p.group,
    value: farmDashboardData?.aggregated_pond_data?.[p.id]?.harvest_biomass_accumulated?.value / 1000 || 0,
  }));
  const dataByPondFiltered = dataByPond.filter((ele) => ele.group === selectedGroup);
  const dataByGroup = _.mapValues(_.groupBy(dataByPond, "group"), (lst) => _.sumBy(lst, "value"));

  const generateChartConfigs = () => {
    if (hasGroupSelected) {
      return {
        chart: {
          type: "",
          height: 250,
          animation: false,
        },
        title: {
          text: "",
        },
        xAxis: {
          title: {
            text: null,
          },
          gridLineWidth: 0,
          lineWidth: 0,
        },
        legend: {
          enabled: false,
        },
        yAxis: [
          {
            title: {
              text: null,
            },
            opposite: true,
          },
          {
            title: {
              text: null,
            },
            opposite: false,
          },
        ],
        plotOptions: {
          series: { animation: false },
        },
        tooltip: {
          pointFormat: "{series.name}{point.value:.2f} t",
        },
        series: [
          {
            type: "treemap",
            layoutAlgorithm: "squarified",
            yAxis: 0,

            data: [
              ...filteredPonds.map((ele) => ({
                name: ele.label,
                color: groupColors?.[ele.group]?.color,
                value: farmDashboardData?.aggregated_pond_data?.[ele.id]?.harvest_biomass_accumulated?.value / 1000,
              })),
            ],
          },
        ],
      };
    } else {
      return {
        chart: {
          type: "",
          height: 200,
          animation: false,
        },
        title: {
          text: "",
        },
        plotOptions: {
          pie: {
            animation: false,
          },
        },
        tooltip: {
          pointFormat: "{series.name}{point.y:.2f} t ({point.percentage:.0f}%)",
        },
        series: [
          {
            type: "pie",
            name: "",
            size: "100%",
            innerSize: "60%",
            data: _.orderBy(
              _.keys(dataByGroup).map((group) => ({
                name: group,
                y: dataByGroup[group],
                color: groupColors[group]?.color,
              })),
              "y"
            ),
          },
        ],
      };
    }
  };

  const chartConfigs = generateChartConfigs();

  return (
    <Box>
      <Stack direction={"row"} spacing={1}>
        <OutlinedTag text={<NumberFormat displayType="text" value={totalHarvested / 1000 || 0} prefix={getText("interface.general.total-harvested") + ": "} decimalScale={1} suffix="t" />} />
        {hasGroupSelected && <OutlinedTag text={<NumberFormat displayType="text" value={dataByGroup[selectedGroup] || 0} prefix={`G ${selectedGroup}: `} decimalScale={1} suffix="t" />} />}
      </Stack>
      <Box>
        <HighchartsWrapper options={chartConfigs} />
      </Box>
    </Box>
  );
};

export default FarmHarvestWidget;
