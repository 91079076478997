import React from "react";
import "./daily.component.css";
import { formatTime } from "../utilities";
import { Temp } from "./temp.component";
import WeatherIcon from "../WeatherIcon";
import { Box, Stack, Typography } from "@mui/material";
import moment from "moment";
import _ from "lodash";

const Daily = ({ daily }) => {
  return (
    <Stack spacing={1}>
      {_.take(daily.intervals, 10)?.map((hour, index) => (
        <Stack index={index} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <Box flex={1}>
            <Typography fontSize={12}>{moment(hour.startTime).format("DD/MM")}</Typography>
          </Box>
          <Box flex={1} textAlign={"right"}>
            <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"}>
              <Box>
                <WeatherIcon value={hour.values.weatherCode} size={30} />
              </Box>
              <Typography fontSize={14} fontWeight={"bold"} sx={{ minWidth: 30 }}>
                <Temp value={hour.values.temperature} />°
              </Typography>
            </Stack>
          </Box>
        </Stack>
      ))}
    </Stack>
  );
};

export { Daily };
