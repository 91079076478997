import _ from "lodash";

export const saveObjectAsJsonFile = ({ data, filename }) => {
  // determine if it's json or geojson
  const isGeoJson = _.last(filename.split(".")) === "geojson";

  // Convert the object to a JSON string
  const jsonString = JSON.stringify(data);

  // Create a blob with the JSON string
  const blob = new Blob([jsonString], { type: isGeoJson ? "application/geo+json" : "application/json" });

  // Create a link element
  const link = document.createElement("a");

  // Create a URL for the blob and set it as the href attribute of the link
  link.href = URL.createObjectURL(blob);
  link.download = filename; // Specify the filename for the download

  // Append the link to the body
  document.body.appendChild(link);

  // Programmatically click the link to trigger the download
  link.click();

  // Remove the link from the document
  document.body.removeChild(link);
};
