import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";
import { DateTimePicker } from "@mui/x-date-pickers";

const MuiDatetimePicker = ({ label, value, onChange, onClose, onAccept, size, fullWidth, width, ...props }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DateTimePicker
        label={label}
        value={value}
        onChange={onChange}
        onClose={onClose}
        onAccept={onAccept}
        slotProps={{
          textField: { size, fullWidth, sx: { width } },
        }}
        format="YYYY-MM-DD HH:mm:ss"
        {...props}
      />
    </LocalizationProvider>
  );
};

export default MuiDatetimePicker;
