import { Box, Stack, Typography } from "@mui/material";
import { AspectRatioIcon, AttachMoneyIcon, LocationOnIcon, TagIcon } from "components/Icons/MaterialIcons";
import StyledTag from "components/Tags/StyledTag";
import Text, { getText } from "components/text/Text";
import NumericFormatters from "helpers/NumericFormatters";
import _ from "lodash";
import NumberFormat from "react-number-format";
import palette from "themes/palette";

const FarmDashboardHero = ({ farm }) => {
  const imageList = farm?.images || [];
  const imageFirst = _.first(imageList);
  const displayImage = !!imageFirst;

  return (
    <Box>
      <Stack direction={"row"} spacing={1}>
        {displayImage && (
          <Box
            width={100}
            height={50}
            borderRadius={2}
            sx={{
              backgroundImage: `url(${imageFirst?.compressed_url})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></Box>
        )}
        <Box>
          <Stack direction={"row"} spacing={0.5} alignItems={"center"}>
            <Typography color="primary" fontSize={18} fontWeight={800} sx={{ opacity: 0.5 }}>
              <Text>interface.general.farm</Text>
            </Typography>
            <Typography color="primary" fontSize={18} fontWeight={800}>
              {farm?.label}
            </Typography>
          </Stack>

          <Stack direction={"row"} spacing={0.5} mt={0.2}>
            <StyledTag icon={<LocationOnIcon />} tooltip={getText("interface.general.location")} label={`${farm?.region}, ${farm?.country}`} color={palette.primary.main} />
            <StyledTag icon={<AspectRatioIcon />} tooltip={getText("interface.general.farm-area")} label={NumericFormatters.formatAreaInHa({ value: farm?.total_area })} />
            <StyledTag icon={<TagIcon />} tooltip={getText("interface.general.ponds")} label={<NumberFormat displayType="text" value={farm?.total_ponds} decimalScale={0} suffix=" Pds." />} />
            <StyledTag icon={<AttachMoneyIcon />} tooltip={getText("interface.general.currency")} label={farm?.settings?.currency} />
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default FarmDashboardHero;
