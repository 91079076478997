import { Box, colors, Stack, Typography } from "@mui/material";
import { HelpOutlineIcon } from "components/Icons/MaterialIcons";
import getGroupTimeseriesAggregation from "helpers/TimeSeriesHelpers";
import _ from "lodash";
import { useState } from "react";
import styled from "styled-components";
import { CHART_TYPES } from "screens/Aquaculture/components/DataExplorer/DataExplorer";
import Text, { getText } from "components/text/Text";
import MuiTabs, { MuiTabsThemes } from "ui/tabs/MuiTabs";
import HighchartsWrapper from "components/Highcharts/HighchartsWrapper";
import TimeFormatters from "helpers/TimeFormatters";
import { DateTime } from "luxon";

const Layout = styled.div`
  height: calc(100vh - 340px);
  width: 100%;
  position: relative;
`;

const MULTI_POND_AGGREGATE_MODES = {
  No: "",
  Total: "Total",
  Average: "Average",
  Minimum: "Minimum",
  Maximum: "Maximum",
  StandardDeviation: "Standard Deviation",
};

const MULTI_POND_AGGREGATE_INTERVALS = {
  intraday: "intraday",
  daily: "daily",
  weekly: "weekly",
};

const CHART_HEIGHT = "500px";

/** Make ChartJS Configs */
const makeChartConfigs = ({ ponds, data, field, chartType, aggregatorMode, pondsAggregatedData }) => {
  const { field_name, field_id, field_unit } = field;

  if (aggregatorMode) {
    const aggregated = pondsAggregatedData[aggregatorMode];
    return {
      chart: {
        type: "line",
        height: CHART_HEIGHT,
        animation: {
          duration: 0,
        },
      },
      xAxis: {
        crosshair: true,
        type: "datetime",
      },
      yAxis: {
        title: {
          text: `${getText(`fields.${field_id}`, field_name)} (${field_unit})`,
        },
        crosshair: true,
      },
      plotOptions: {
        series: {
          animation: {
            duration: 0,
          },
        },
      },
      series: [
        {
          marker: {
            symbol: "circle",
          },
          name: aggregatorMode,
          data: aggregated.map((item) => [TimeFormatters.convertUtcDatetimeStringToValue(item.datetime), item?.value]),
        },
      ],
    };
  } else {
    const transformData = (data) => {
      if (!data || data.length === 0) {
        return [];
      }
      const datetimeHead = _.first(data).datetime;
      const dataAfter = data.map((row, idx) => {
        const dt1 = DateTime.fromFormat(datetimeHead, "yyyy-MM-dd HH:mm:ss");
        const dt2 = DateTime.fromFormat(row.datetime, "yyyy-MM-dd HH:mm:ss");
        const days = dt2.diff(dt1, "days").days;
        return {
          ...row,
          d: days,
        };
      });
      return dataAfter;
    };

    const series = ponds.map((ele, idx) => ({
      pond: ele,
      data: transformData(data?.[idx]),
    }));

    const configs = {
      chart: {
        type: "line",
        height: CHART_HEIGHT,
        animation: false,
      },
      xAxis: {
        type: "categories",
        crosshair: true,
        title: {
          text: getText("interface.general.days"),
        },
      },
      yAxis: {
        title: {
          text: `${getText(`fields.${field_id}`, field_name)} (${field_unit})`,
        },
        crosshair: true,
      },
      tooltip: {
        shared: true,
      },
      plotOptions: {
        series: {
          animation: false,
        },
      },
      series: series.map((ele) => ({
        marker: {
          symbol: "circle",
        },
        name: ele.pond?.name,
        data: ele.data.map((item) => [item.d, item?.[field_id]]),
      })),
    };

    return configs;
  }
};

const DataExplorerMultiPondView = ({ selectedField, chartType, ponds, pondsData, aggregatorMode, handleAggregatorChange }) => {
  const [dataInterval, setDataInterval] = useState(MULTI_POND_AGGREGATE_INTERVALS.daily);

  /** Aggregate Multiple Lines */
  const pondsAggregatedResults = getGroupTimeseriesAggregation(pondsData, selectedField.field_id, dataInterval);

  const pondsAggregatedData = {
    [MULTI_POND_AGGREGATE_MODES.Total]: pondsAggregatedResults.total,
    [MULTI_POND_AGGREGATE_MODES.Average]: pondsAggregatedResults.average,
    [MULTI_POND_AGGREGATE_MODES.Minimum]: pondsAggregatedResults.min,
    [MULTI_POND_AGGREGATE_MODES.Maximum]: pondsAggregatedResults.max,
    [MULTI_POND_AGGREGATE_MODES.StandardDeviation]: pondsAggregatedResults.standardDeviation,
  };

  const chartConfigs = makeChartConfigs({
    chartType: chartType,
    ponds: ponds,
    data: pondsData,
    field: selectedField,
    aggregatorMode: aggregatorMode,
    pondsAggregatedData,
  });

  if (!pondsData || _.sum(pondsData.map((ele) => ele.length)) < 1) {
    return (
      <Layout>
        <Stack alignItems={"center"} direction="column" spacing={2}>
          <Typography color={colors.grey[400]}>
            <HelpOutlineIcon sx={{ fontSize: 100 }} />
          </Typography>
          <Typography variant="h3" color={colors.grey[500]}>
            {!_.isEmpty(selectedField) ? `This pond doesn't have "${selectedField.field_name}"` : <Text>description.select-data-from-menu</Text>}
          </Typography>
          <Typography color={colors.grey[500]}>Switch back to Single Mode for uploading data</Typography>
        </Stack>
      </Layout>
    );
  }

  return (
    <Layout>
      <Box>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <Box>
            <Typography fontSize={10} color="grey" fontWeight={800} lineHeight={1}>
              AGGREGATION:
            </Typography>
            <MuiTabs
              options={[
                { label: "N/A", value: "" },
                { label: "Sum", value: "Total" },
                { label: "Avg", value: "Average" },
                { label: "Min", value: "Minimum" },
                { label: "Max", value: "Maximum" },
                { label: "Std", value: "Standard Deviation" },
              ]}
              value={aggregatorMode}
              onNewValue={(newVal) => handleAggregatorChange({ target: { value: newVal } })}
              theme={MuiTabsThemes.chip}
            />
          </Box>

          {aggregatorMode && (
            <Box>
              <Typography fontSize={10} color="grey" fontWeight={800} lineHeight={1}>
                INTERVAL:
              </Typography>
              <MuiTabs
                options={[
                  { label: getText("interface.general.original"), value: MULTI_POND_AGGREGATE_INTERVALS.intraday },
                  { label: getText("interface.time.daily"), value: MULTI_POND_AGGREGATE_INTERVALS.daily },
                  { label: getText("interface.time.weekly"), value: MULTI_POND_AGGREGATE_INTERVALS.weekly },
                ]}
                value={dataInterval}
                onNewValue={setDataInterval}
                theme={MuiTabsThemes.chip}
              />
            </Box>
          )}
        </Stack>
      </Box>

      {chartType === CHART_TYPES.LINE && <HighchartsWrapper options={chartConfigs} />}
    </Layout>
  );
};

export default DataExplorerMultiPondView;
