import { Box, Button, Typography } from "@mui/material";
import { KeyboardArrowLeftIcon } from "components/Icons/MaterialIcons";
import Text, { getText } from "components/text/Text";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { dialogReducerActions } from "redux/dialog";
import { ROUTE_ORGANISATION_HOME } from "routes/paths";
import OrganisationForm from "screens/Aquaculture/Organisations/OrganisationForm";

const OrganisationCreatePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const Actions = {
    postSubmitAction: () => {
      dialogReducerActions.openDialog(dispatch, {
        title: getText("interface.alert.alert-success"),
      });
      navigate(ROUTE_ORGANISATION_HOME);
    },
  };

  return (
    <Box p={2}>
      <Button startIcon={<KeyboardArrowLeftIcon />} component={Link} to={ROUTE_ORGANISATION_HOME}>
        <Text>interface.actions.return</Text>
      </Button>
      <Box my={2}>
        <Typography variant="h6">Create a new organisation</Typography>
      </Box>
      <Box mt={2}>
        <OrganisationForm postSubmitAction={Actions.postSubmitAction} />
      </Box>
    </Box>
  );
};

export default OrganisationCreatePage;
