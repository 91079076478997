import { Box, Grid, Stack, Typography, colors } from "@mui/material";
import HighchartsWrapper from "components/Highcharts/HighchartsWrapper";
import Formatters from "helpers/Formatters";
import _ from "lodash";
import numeral from "numeral";
import React, { useEffect, useState } from "react";

const utils = {
  generateActiveRatioChartConfigs: (data) => {
    const timeseries = data?._active_ratio?.filter((item) => item.value === 1);
    const avg = _.meanBy(
      timeseries?.filter((ele) => ele.percentage),
      "percentage"
    );
    const chartConfigs = {
      chart: {
        height: "300px",
        animation: false,
      },
      xAxis: {
        type: "datetime",
      },
      yAxis: {
        title: {
          text: "",
        },
        labels: {
          formatter: function () {
            return this.value + "%"; // Add % to y-axis labels
          },
        },
        plotLines: [
          {
            value: avg, // The average value
            color: colors.red[500], // Color of the line
            dashStyle: "Dash", // Dashed line
            width: 1, // Width of the line
            zIndex: 5, // Position above other elements
            label: {
              text: `AVG=${numeral(avg).format("0.00")}%`, // Label text
              align: "right",
              style: {
                fontSize: "10px",
                color: colors.red[500], // Color of the label
              },
              useHTML: true,
            },
          },
        ],
      },
      tooltip: {
        pointFormatter: function () {
          return `<b>${this.y}%</b>`; // Add % to tooltip values
        },
      },
      series: [
        {
          name: "Active",
          type: "line",
          data: timeseries.map((ele) => [Formatters.toLocalDatetimeValue(ele.datetime), ele.percentage]),
          lineWidth: 2,
          fillOpacity: 0.1,
          color: colors.blue[600],
        },
      ],
    };
    return chartConfigs;
  },
};

const AquaExplorerDashboard = ({ data }) => {
  // number of features
  const numberOfFeatures = data?.features?.length;
  // total area of all features
  const totalArea = _.sumBy(data?.features || [], "properties.area");
  // average area of all features
  const avgArea = _.meanBy(data?.features || [], "properties.area");
  // polygon with water_clf = 1 ratio
  const activePercentage = data?.features?.filter((ele) => ele.properties.dt_water_clf === 1).length / data?.features?.length;

  const [activeChartChartConfigs, setActiveRatioChartConfigs] = useState(null);

  useEffect(() => {
    // generate Active Ratio chart configs
    const activeRatioChartConfigsNew = utils.generateActiveRatioChartConfigs(data);
    setActiveRatioChartConfigs(activeRatioChartConfigsNew);
  }, [data]);

  return (
    <Stack spacing={1}>
      <Box>
        <Grid container></Grid>
      </Box>
      {activeChartChartConfigs && (
        <Box>
          <Typography fontWeight={800}>Active Ratio %</Typography>
          <HighchartsWrapper options={activeChartChartConfigs} />
        </Box>
      )}
    </Stack>
  );
};

export default AquaExplorerDashboard;
