import { useEffect, useState } from "react";
import { Box, colors, Grid, IconButton, Stack, Typography } from "@mui/material";
import _ from "lodash";
import NumberFormat from "react-number-format";
import { ArrowDownwardIcon, ArrowUpwardIcon, CheckCircleOutlineIcon, WarningIcon } from "components/Icons/MaterialIcons";
import { AppSelectors } from "redux/AppReducers";
import Text, { getText } from "components/text/Text";
import HistogramChart from "screens/Aquaculture/components/PondManager/PondManagerFarmDashboard/charts/HistogramChart";
import BoxChart from "screens/Aquaculture/components/PondManager/PondManagerFarmDashboard/charts/BoxChart";
import MonitorTable from "screens/Aquaculture/components/PondManager/PondManagerFarmDashboard/charts/MonitorTable";
import { defaultAlertRules, getAlertResult } from "screens/Aquaculture/components/Dashboard/utils/alertHelpers";
import WidgetCard from "ui/Card/WidgetCard";

/** Helpers */
const getAlertsFromAggregatedData = (aggregatedPondData, rules) => {
  const alertResult = _.mapValues(aggregatedPondData, (o) =>
    _.mapValues(o, (v, vk) => {
      return getAlertResult({
        field_id: vk,
        value: v?.value,
        rules: rules,
      });
    })
  );
  return alertResult;
};

const MonitorItem = ({ title, subtitle, alerted, alertCount, total, mean, tablePart, histogramPart }) => {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <WidgetCard
      title={title}
      subtitle={subtitle}
      sx={{
        border: `solid 3px ${alerted ? colors.red[100] : colors.green[100]}`,
        ":hover": {
          cursor: "pointer",
          bgcolor: colors.grey[50],
        },
      }}
    >
      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} onClick={() => setShowDetails(!showDetails)}>
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
            {alerted ? <WarningIcon sx={{ fontSize: 30, color: colors.red[300] }} /> : <CheckCircleOutlineIcon sx={{ fontSize: 30, color: colors.green[300] }} />}
            {alerted ? (
              <Typography color={"red"} fontSize={11} fontWeight={800}>{`${alertCount} ${getText("interface.general.flags")}`}</Typography>
            ) : (
              <Typography color={"green"} fontSize={11} fontWeight={800}>
                Normal
              </Typography>
            )}
          </Stack>
          <Box>
            <Stack direction={"row"} spacing={2} alignItems="center">
              <Stack direction="row" spacing={2}>
                {total && (
                  <Typography fontWeight={800} fontSize={16}>
                    <span style={{ color: "grey" }}>Total</span> {total}
                  </Typography>
                )}
                {mean && (
                  <Typography fontWeight={800} fontSize={16}>
                    <span style={{ color: "grey" }}>Avg</span> {mean}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Box>
        </Stack>
        <Box>
          <IconButton>{!showDetails ? <ArrowDownwardIcon color="primary" /> : <ArrowUpwardIcon />}</IconButton>
        </Box>
      </Stack>

      {showDetails && (
        <Stack my={2} spacing={2}>
          <Box>{histogramPart()}</Box>
          <Box>{tablePart()}</Box>
        </Stack>
      )}
    </WidgetCard>
  );
};

const FarmMonitorDashboard = ({ farm, ponds, farmDashboardData, onSwitchToDataViewField, actions }) => {
  const farmAlertRules = farm?.settings?.alerts || defaultAlertRules;

  const pondManagementStore = AppSelectors.pondManagementStore();

  const fields = pondManagementStore.fields;

  const { toggleAlertPonds } = actions;

  useEffect(() => {
    return () => {
      toggleAlertPonds({
        isCancel: true,
      });
    };
  }, []);

  const fieldsToDisplay = [
    {
      field_id: "biomass",
      group: "biological",
      aggregated_excluded: [],
    },
    {
      field_id: "survival_rate",
      group: "biological",
      aggregated_excluded: ["total"],
      showHistogram: true,
    },
    {
      field_id: "weight",
      group: "biological",
      aggregated_excluded: ["total"],
      showHistogram: true,
    },
    {
      field_id: "current_density",
      group: "biological",
      aggregated_excluded: ["total"],
    },
    {
      field_id: "seeded_density",
      group: "biological",
      aggregated_excluded: ["total"],
    },
    {
      field_id: "ph",
      group: "water-quality",
      aggregated_excluded: ["total"],
    },
    {
      field_id: "salinity",
      group: "water-quality",
      aggregated_excluded: ["total"],
    },
    {
      field_id: "dissolved_oxygen",
      group: "water-quality",
      aggregated_excluded: ["total"],
    },
    {
      field_id: "turbidity",
      group: "water-quality",
      aggregated_excluded: ["total"],
    },
    {
      field_id: "water_temp",
      group: "water-quality",
      aggregated_excluded: ["total"],
    },
    {
      field_id: "average_depth",
      group: "water-quality",
      aggregated_excluded: ["total"],
    },
    {
      field_id: "water_exchange",
      group: "water-quality",
      aggregated_excluded: ["total"],
    },
  ];

  const alertResult = getAlertsFromAggregatedData(_.omit(farmDashboardData?.aggregated_pond_data, ["_aggregated"]), farmAlertRules);

  const getValuesDistributions = (data, field) => {
    const result = _.keys(data)
      .map((pId) => {
        return {
          pondId: pId,
          value: data[pId][field]?.value,
        };
      })
      .filter((ele) => ele.pondId !== "_aggregated" && ele.value);
    return result;
  };

  return (
    <Box>
      {["biological", "water-quality"].map((g, gIndex) => {
        return (
          <Box key={gIndex}>
            <Box my={2}>
              <Typography variant="cardTitle">
                <Text>{`interface.general.${g}`}</Text>
              </Typography>
            </Box>
            <Stack spacing={2}>
              {fieldsToDisplay
                .filter((ele) => ele.group === g)
                .map((item, index) => {
                  const field = item.field_id;
                  const fieldDetails = _.find(fields, { field_id: field });
                  const fieldAggregated = farmDashboardData?.aggregated_pond_data?._aggregated?.[field];
                  const alertCount = ponds.map((p) => alertResult?.[p.id]?.[field]?.isAlert).filter((e) => e).length;
                  const tablePart = () => {
                    const monitorTableRows = ponds.map((p) => {
                      const alertDetails = alertResult?.[p.id]?.[field];
                      const valueDisplay = farmDashboardData?.aggregated_pond_data?.[p.id]?.[field]?.value;
                      const timeDisplay = farmDashboardData?.aggregated_pond_data?.[p.id]?.[field]?.datetime;
                      const alertLevel = alertDetails?.alertLevelText;
                      const colorTextDisplay = alertDetails?.alertLevelText?.alertColor;
                      return {
                        ...p,
                        data: {
                          valueDisplay,
                          timeDisplay,
                          colorTextDisplay,
                          alertLevel,
                          alertDetails,
                          fieldDetails,
                        },
                      };
                    });
                    return <MonitorTable rows={monitorTableRows} />;
                  };
                  const histogramPart = () => {
                    if (!item.showHistogram) return "";
                    return (
                      <Grid container spacing={2} mb={2}>
                        <Grid item xs={8}>
                          <HistogramChart data={getValuesDistributions(farmDashboardData?.aggregated_pond_data, field)} ponds={ponds} />
                        </Grid>
                        <Grid item xs={4}>
                          <BoxChart data={getValuesDistributions(farmDashboardData?.aggregated_pond_data, field)} ponds={ponds} />
                        </Grid>
                      </Grid>
                    );
                  };

                  const itemProps = {
                    title: <Text>{`fields.${fieldDetails?.field_id}`}</Text>,
                    subtitle: fieldDetails?.field_unit,
                    alerted: alertCount > 0,
                    alertCount: alertCount,
                    total: !_.includes(item.aggregated_excluded, "total") && <NumberFormat displayType="text" value={fieldAggregated?.total} thousandSeparator={true} decimalScale={0} />,
                    mean: !_.includes(item.aggregated_excluded, "mean") && <NumberFormat displayType="text" value={fieldAggregated?.mean} thousandSeparator={true} decimalScale={0} />,
                    tablePart: tablePart,
                    histogramPart: histogramPart,
                  };

                  return <MonitorItem key={index} {...itemProps} />;
                })}
            </Stack>
          </Box>
        );
      })}
    </Box>
  );
};

export default FarmMonitorDashboard;
