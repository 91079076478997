import React from "react";
import styled from "styled-components";
// import Avatar from "@mui/material/Avatar";
// import { deepOrange } from "@mui/material/colors";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import moment from "moment";
import LeftAvatar from "./LeftAvatar";
import palette from "themes/palette";
import { Avatar, Stack, colors } from "@mui/material";
import { PersonIcon } from "components/Icons/MaterialIcons";
import parse from "html-react-parser";

export const MessageBox = (props) => {
  const { isMine } = props;

  return (
    <Box my={2}>
      {isMine ? <MessageRightBox {...props} /> : <MessageLeftBox {...props} />}
    </Box>
  );
};

export const MessageRightBox = ({ id, sender, body, timestamp }) => {
  return (
    <Stack direction={"row"} justifyContent={"flex-start"} spacing={2}>
      <Box>
        <Avatar>
          <PersonIcon />
        </Avatar>
        <Box>
          <Typography fontSize={12} fontWeight={800}>
            {sender}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            background: palette.secondary.light,
            borderRadius: "0px 20px 20px 20px",
          }}
        >
          <Box py={0.1} px={2}>
            <Typography
              sx={{
                p: {
                  margin: 0,
                },
              }}
            >
              {parse(body)}
            </Typography>
          </Box>
        </Box>
        <Box mt={0.5}>
          <Typography fontSize={11} color={colors.grey[700]}>
            {timestamp}
          </Typography>
        </Box>
      </Box>
    </Stack>
  );
};

export const MessageLeftBox = ({ id, sender, body, timestamp }) => {
  return (
    <Stack direction={"row"} justifyContent={"flex-end"}>
      <Box>
        <Box
          sx={{
            background: palette.primary.light,
            borderRadius: "20px 0px 20px 20px",
          }}
        >
          <Box py={0.1} px={2}>
            <Typography
              sx={{
                p: {
                  margin: 0,
                },
              }}
            >
              {parse(body)}
            </Typography>
          </Box>
        </Box>
        <Box mt={0.5}>
          <Typography
            fontSize={11}
            color={colors.grey[700]}
            textAlign={"right"}
          >
            {timestamp}
          </Typography>
        </Box>
      </Box>
    </Stack>
  );
};

// const MessageContainer = styled(Box)`
//   display: flex;
//   align-items: flex-start;
//   margin-bottom: 16px;
// `;

// const MessageContentContainer = styled(Box)`
//   position: relative;
//   margin-left: 20px;
//   margin-bottom: 10px;
//   padding: 10px;
//   background-color: ${palette.secondary.light};
//   width: 60%;
//   text-align: left;
//   font: 400 0.9em "Open Sans", sans-serif;
//   border: 1px solid ${palette.secondary.light};
//   border-radius: 0px 20px 20px 20px;
// `;

// const MessageText = styled(Typography)`
//   variant: body1;
//   margin-top: 10px;
// `;

// const Timestamp = styled(Typography)`
//   font-size: 11px !important;
//   color: #555555;
// `;

// const MessageLeft = (props) => {
//   const {
//     message = "no message",
//     timestamp = moment(),
//     photoURL = "",
//     displayName = "",
//   } = props;

//   return (
//     <MessageContainer>
//       <LeftAvatar alt={displayName} src={photoURL} />
//       <MessageContentContainer>
//         <Typography variant="subtitle2" color="textPrimary" mb={1}>
//           {displayName}
//         </Typography>
//         <MessageText>{message}</MessageText>
//         <Timestamp>{timestamp}</Timestamp>
//       </MessageContentContainer>
//     </MessageContainer>
//   );
// };

// const MessageRightContainer = styled(Box)`
//   display: flex;
//   justify-content: flex-end;
//   margin-bottom: 16px;
// `;

// const MessageRightContentContainer = styled(Box)`
//   position: relative;
//   margin-right: 20px;
//   margin-bottom: 10px;
//   padding: 10px;
//   background-color: ${palette.primary.light};
//   width: 60%;
//   text-align: left;
//   font: 400 0.9em "Open Sans", sans-serif;
//   border: 1px solid ${palette.primary.light};
//   border-radius: 20px 0px 20px 20px;
// `;

// const MessageRightText = styled(Typography)`
//   variant: body1;
//   margin-top: 10px;
// `;

// const MessageRight = (props) => {
//   const { message = "no message", timestamp = "" } = props;

//   return (
//     <MessageRightContainer>
//       <MessageRightContentContainer>
//         <MessageRightText>{message}</MessageRightText>
//         <Timestamp>{timestamp}</Timestamp>
//       </MessageRightContentContainer>
//     </MessageRightContainer>
//   );
// };

// export { MessageLeft, MessageRight };
