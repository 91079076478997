import { colors } from "@mui/material";
import { PondManagerServices } from "api/pondManagerServices";
import { ChartColorsRoundRobinDark, ChartColorsRoundRobinGetter } from "helpers/constants";

export const fetchPondFarmingProductData = async ({ pondid, cycle, productIdField, productUsageField, registeredProducts }) => {
  const response = await PondManagerServices.getTimeseriesData(
    pondid,
    [productIdField, productUsageField, "fcr_full_cycle", "feed_accumulated", "biomass", "harvest_biomass", "sonic_response"],
    cycle?.start_date,
    cycle?.end_date
  );

  const fields = [productUsageField, productIdField];

  const rows = response.raw;

  let data = _.groupBy(
    rows.filter((ele) => _.includes(fields, ele.field_id)),
    "datetime"
  );

  data = _.keys(data).map((k) => ({
    // datetime: Formatters.Time.toDateString(k),
    datetime: k,
    usage: _.find(data[k], { field_id: productUsageField })?.value,
    productId: _.find(data[k], { field_id: productIdField })?.value,
  }));
  data = _.uniq(data.map((ele) => ele.productId))
    .map((fId, idx) => {
      const product = _.find(registeredProducts, { id: fId }) || { name: "General", prices: [] };
      return {
        productId: fId,
        product: product,
        color: product.name === "General" ? colors.grey[500] : ChartColorsRoundRobinGetter(ChartColorsRoundRobinDark, idx),
        rows: data
          .filter((ele) => ele.productId === fId)
          .map((row) => {
            // find price
            const itemPrices = product["prices"];
            let price = 0;
            if (itemPrices.length > 0) {
              const prevPrices = itemPrices.filter((p) => p.datetime <= row.datetime);
              const postPrices = itemPrices.filter((p) => p.datetime > row.datetime);
              if (prevPrices.length === 0) {
                price = _.first(postPrices)?.unit_price;
              } else {
                price = _.last(prevPrices)?.unit_price;
              }
            }
            const cost = row.usage * price;
            return {
              ...row,
              cost: cost,
              unit_price: price,
            };
          }),
      };
    })
    .map((ele) => ({
      ...ele,
      stats: {
        total: _.sumBy(ele.rows, "usage"),
        totalCost: _.sumBy(ele.rows, "cost"),
      },
    }));

  const sonicResponseData = rows.filter((ele) => ele.field_id === "sonic_response");

  return {
    ...response,
    aggregated: data,
    sonicResponseData: sonicResponseData,
  };
};
