import { Grid } from "@mui/material";
import { ArticleIcon, AssignmentReturnedIconIcon, ErrorOutlineIcon, InsertChartIcon, RequestQuoteIcon, TableRowsIcon, ViewTimelineIcon } from "components/Icons/MaterialIcons";
import AccessWrapper from "components/Wrapper/AccessWrapper";
import { getText } from "components/text/Text";
import PlatformAccessPolices from "constants/PlatformAccessPolicies";
import { PiLeaf } from "react-icons/pi";
import { BiLeaf } from "react-icons/bi";
import FarmAlertsWidget from "screens/Aquaculture/components/Dashboard/components/FarmAlertsWidget";
import FarmCostsWidget from "screens/Aquaculture/components/Dashboard/components/FarmCostsWidget";
import FarmCycleWidget from "screens/Aquaculture/components/Dashboard/components/FarmCycleWidget";
import FarmDocumentWidget from "screens/Aquaculture/components/Dashboard/components/FarmDocumentWidget";
import FarmEmissionWidget from "screens/Aquaculture/components/Dashboard/components/FarmEmissionWidget";
import FarmHarvestWidget from "screens/Aquaculture/components/Dashboard/components/FarmHarvestWidget";
import FarmPondsSummaryWidget from "screens/Aquaculture/components/Dashboard/components/FarmPondsSummaryWidget";
import FarmProfileWidget from "screens/Aquaculture/components/Dashboard/components/FarmProfileWidget";
import FarmSRSWidget from "screens/Aquaculture/components/Dashboard/components/FarmSRSWidget";
import WidgetCard from "ui/Card/WidgetCard";

/**
 *
 * A widget wrapper template
 *
 * actions
 * -------
 * link: view more link
 *
 * @param {*} param0
 * @returns
 */
const WidgetGridItem = ({ gridProps, title, icon, link, children }) => {
  return (
    <Grid item xs={12} {...gridProps}>
      <WidgetCard title={title} icon={icon} sx={{ height: "100%" }}>
        {children}
      </WidgetCard>
    </Grid>
  );
};

const FarmSummaryDashboard = (props) => {
  const { farm } = props;

  const accessPolices = farm?.["org-access-test"];
  const accessAlways = accessPolices ? false : true;

  return (
    <Grid container spacing={2}>
      <WidgetGridItem
        title={getText("interface.general.cycles")}
        icon={<ViewTimelineIcon />}
        gridProps={{
          lg: 3,
        }}
      >
        <AccessWrapper target={PlatformAccessPolices.cycle.read} allowed={accessPolices} always={accessAlways}>
          <FarmCycleWidget {...props} />
        </AccessWrapper>
      </WidgetGridItem>

      {/* <WidgetGridItem
        title={farm.label}
        icon={<ArticleIcon />}
        gridProps={{
          lg: 6,
        }}
      >
        <AccessWrapper always>
          <FarmProfileWidget {...props} />
        </AccessWrapper>
      </WidgetGridItem> */}

      <WidgetGridItem
        title={getText("interface.general.alerts")}
        icon={<ErrorOutlineIcon />}
        gridProps={{
          lg: 3,
        }}
      >
        <AccessWrapper target={PlatformAccessPolices.alerts.read} allowed={accessPolices} always={accessAlways}>
          <FarmAlertsWidget {...props} />
        </AccessWrapper>
      </WidgetGridItem>

      <WidgetGridItem
        title={getText("interface.general.costs")}
        icon={<RequestQuoteIcon />}
        gridProps={{
          lg: 3,
        }}
      >
        <AccessWrapper target={PlatformAccessPolices.finance.read} allowed={accessPolices} always={accessAlways}>
          <FarmCostsWidget {...props} />
        </AccessWrapper>
      </WidgetGridItem>

      {/* <WidgetGridItem
        title={
          <span>
            CO<sub>2</sub>
          </span>
        }
        icon={<BiLeaf />}
        gridProps={{
          lg: 3,
        }}
      >
        <AccessWrapper always>
          <FarmEmissionWidget farm={farm} />
        </AccessWrapper>
      </WidgetGridItem> */}

      <WidgetGridItem
        title="SRS"
        icon={<InsertChartIcon />}
        gridProps={{
          lg: 3,
        }}
      >
        <AccessWrapper target={PlatformAccessPolices.srs.read} allowed={accessPolices} always={accessAlways}>
          <FarmSRSWidget {...props} />
        </AccessWrapper>
      </WidgetGridItem>
      <WidgetGridItem
        title={getText("interface.general.harvest")}
        icon={<AssignmentReturnedIconIcon />}
        gridProps={{
          lg: 6,
        }}
      >
        <AccessWrapper target={PlatformAccessPolices.biological.read} allowed={accessPolices} always={accessAlways}>
          <FarmHarvestWidget {...props} />
        </AccessWrapper>
      </WidgetGridItem>

      <WidgetGridItem
        title={
          <span>
            CO<sub>2</sub>
          </span>
        }
        icon={<BiLeaf />}
        gridProps={{
          lg: 3,
        }}
      >
        <AccessWrapper always>
          <FarmEmissionWidget farm={farm} />
        </AccessWrapper>
      </WidgetGridItem>

      <WidgetGridItem
        title={getText("interface.general.pond-summary")}
        icon={<TableRowsIcon />}
        gridProps={{
          lg: 12,
        }}
      >
        <AccessWrapper target={PlatformAccessPolices.biological.read} allowed={accessPolices} always={accessAlways}>
          <FarmPondsSummaryWidget {...props} />
        </AccessWrapper>
      </WidgetGridItem>

      {/* <WidgetGridItem
        title="Documents"
        gridProps={{
          lg: 6,
        }}
      >
        <AccessWrapper target={PlatformAccessPolices.document.read} allowed={accessPolices} always={accessAlways}>
          <FarmDocumentWidget {...props} />
        </AccessWrapper>
      </WidgetGridItem> */}
    </Grid>
  );
};

export default FarmSummaryDashboard;
