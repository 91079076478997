import { Box, Grid, Typography } from "@mui/material";

const StatsBar = ({ list }) => {
  const numberOfItems = list.length;

  return (
    <Grid container spacing={2} columns={numberOfItems}>
      {list.map((item, idx) => (
        <Grid key={idx} item xs={numberOfItems} lg={1}>
          <Box>
            <Typography fontSize={10} color="primary" sx={{ mb: -0.5 }}>
              {item.label}
            </Typography>
            <Typography variant="h4" color="primary">
              {item.value}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default StatsBar;
