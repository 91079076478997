import { Alert, Box, IconButton, Stack, Typography, colors } from "@mui/material";
import { CloseIcon } from "components/Icons/MaterialIcons";
import Text from "components/text/Text";
import { useDispatch, useSelector } from "react-redux";
import { Fade } from "react-reveal";
import { alertsActions } from "redux/alerts";
import { isMobile } from "themes/helpers";
import palette from "themes/palette";

const GlobalAlerts = () => {
  const alertsStates = useSelector((state) => state.alerts);
  const dispatch = useDispatch();

  if (alertsStates.queue.length === 0) return "";

  const mobileMode = isMobile();

  return (
    <Box
      sx={
        mobileMode
          ? {
              position: "absolute",
              right: "0px",
              bottom: "0px",
              width: "100vw",
              zIndex: "999",
              bgcolor: palette.primary.light,
              borderTop: "1px solid #CCC",
            }
          : {
              position: "absolute",
              right: "20px",
              bottom: "20px",
              zIndex: "999",
              bgcolor: colors.common.white,
              borderRadius: 3,
              boxShadow: 1,
            }
      }
    >
      <Fade bottom distance={"10px"} duration={500}>
        <Box p={2} borderRadius={2}>
          <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} mb={2}>
            <Typography fontWeight={"bold"}>{<Text>interface.general.notifications</Text>}</Typography>
            <IconButton onClick={() => alertsActions.removeAll(dispatch)}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack
            spacing={1}
            maxHeight={mobileMode ? "100px" : "50vh"}
            width={mobileMode ? "100%" : "300px"}
            sx={{
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {alertsStates.queue.map((ele, idx) => {
              return (
                <Fade right duration={500} distance={"40px"}>
                  <Alert
                    key={ele.id}
                    severity={ele.severity || "info"}
                    onClose={() =>
                      alertsActions.remove(dispatch, {
                        id: ele.id,
                      })
                    }
                    sx={{
                      px: 2,
                      py: 1,
                      boxShadow: "none",
                      borderRadius: 2,
                    }}
                  >
                    <Typography fontSize={12}>{ele.content}</Typography>
                  </Alert>
                </Fade>
              );
            })}
          </Stack>
        </Box>
      </Fade>
    </Box>
  );
};

export default GlobalAlerts;
