/**
 *
 * w: Write
 * r: Read
 *
 */

const PlatformAccessPolices = {
  modules: {
    dashboard: {
      r: "modules--dashboard::READ",
      w: "modules--dashboard::WRITE",
    },
    cycle: {
      r: "modules--cycle::READ",
      w: "modules--cycle::WRITE",
    },
    dataexplorer: {
      r: "modules--dataexplorer::READ",
      w: "modules--dataexplorer::WRITE",
    },
    datamanager: {
      r: "modules--datamanager::READ",
      w: "modules--datamanager::WRITE",
    },
    scenariobuilder: {
      r: "modules--scenariobuilder::READ",
      w: "modules--scenariobuilder::WRITE",
    },
    forecast: {
      r: "modules--forecast::READ",
      w: "modules--forecast::WRITE",
    },
    report: {
      r: "modules--report::READ",
      w: "modules--report::WRITE",
    },
    files: {
      r: "modules--files::READ",
      w: "modules--files::WRITE",
    },
    sateye: {
      r: "modules--sateye::READ",
      w: "modules--sateye::WRITE",
    },
  },
  srs: {
    read: "srs::READ",
    write: "srs::WRITE",
  },
  alerts: {
    read: "alerts::READ",
    write: "alerts::WRITE",
  },
  finance: {
    read: "finance::READ",
    write: "finance::WRITE",
  },
  biological: {
    read: "biological::READ",
    write: "biological::WRITE",
  },
  cycle: {
    read: "cycle::READ",
    write: "cycle::WRITE",
  },
  forecast: {
    read: "forecast::READ",
    write: "forecast::WRITE",
  },
  scenario_builder: {
    read: "scenario-builder::READ",
    write: "scenario-builder::WRITE",
  },
  gis: {
    read: "gis::READ",
    write: "gis::WRITE",
  },
  alerts: {
    read: "alerts::READ",
    write: "alerts::WRITE",
  },
  document: {
    read: "document::READ",
    write: "document::WRITE",
  },
};

export default PlatformAccessPolices;
