import { Box, Stack, Typography } from "@mui/material";
import Formatters from "helpers/Formatters";
import React from "react";

/**
 *
 * A country flag icon with text component
 *
 * @param {object} props
 * @param {string} props.countryCode The code for country, e.g. AU
 * @param {boolean} props.withName also print country name
 * @returns
 */
const CountryFlagSvgIcon = (props) => {
  const { countryCode, withName } = props;
  const imageUrl = `https://flagcdn.com/${countryCode?.toLowerCase()}.svg`;

  const icon = () => (
    <Box
      sx={{
        minWidth: "24px",
        minHeight: "16px",
        border: "1px solid #aaa",
        borderRadius: "4px",
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    ></Box>
  );

  if (withName) {
    const countryName = Formatters.formatCountryNameByCode({ value: countryCode });
    return (
      <Stack direction={"row"} spacing={1} alignItems={"center"}>
        {icon()}
        <Typography fontSize={11} fontWeight={600}>
          {countryName}
        </Typography>
      </Stack>
    );
  } else {
    return icon();
  }
};

export default CountryFlagSvgIcon;
