import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, colors, Box, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { dialogReducerActions } from "redux/dialog";
import { ErrorOutlineIcon, HelpOutlineIcon, InfoIcon } from "components/Icons/MaterialIcons";
import palette from "themes/palette";
import Text from "components/text/Text";

const GlobalDialog = () => {
  const dialogStates = useSelector((state) => state.dialog);
  const dispatch = useDispatch();

  const handleClose = () => {
    dialogReducerActions.closeDialog(dispatch);
  };

  return (
    <Dialog
      open={dialogStates.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          borderRadius: 4,
          bgcolor: palette.primary.light,
        },
      }}
    >
      <Box p={2} width={350} minHeight="200px">
        <DialogTitle fontSize="1.2rem" fontWeight="bold" color={colors.grey[800]} textAlign="center" id="alert-dialog-title">
          <Box>
            {dialogStates.level === "info" &&
              (dialogStates.action ? <HelpOutlineIcon sx={{ fontSize: "8rem", color: palette.primary.main }} /> : <InfoIcon sx={{ fontSize: "8rem", color: palette.primary.main }} />)}
            {dialogStates.level === "error" && <ErrorOutlineIcon sx={{ fontSize: "8rem", color: colors.red[500] }} />}
          </Box>
          <Box>{dialogStates.title}</Box>
        </DialogTitle>
        <DialogContent sx={{ textAlign: "center" }}>
          <DialogContentText id="alert-dialog-description">{dialogStates.content}</DialogContentText>
        </DialogContent>
      </Box>
      <Stack spacing={1} p={2} px={4} direction={"row"}>
        <Button variant="outlined" onClick={handleClose} autoFocus fullWidth size="large">
          <Text>interface.actions.close</Text>
        </Button>
        {dialogStates.action && (
          <Button
            variant="contained"
            onClick={async () => {
              await dialogStates.action();
              handleClose();
            }}
            autoFocus
            fullWidth
            size="large"
          >
            <Text>interface.actions.confirm</Text>
          </Button>
        )}
      </Stack>
    </Dialog>
  );
};

export default GlobalDialog;
