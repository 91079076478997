import { Box, IconButton, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import { CircleIcon } from "components/Icons/MaterialIcons";
import React, { useState } from "react";

const ColorIconSelect = ({ name, label, value, options, onChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Box>
        <Typography textAlign={"center"} fontSize={11} fontWeight={800} whiteSpace={"nowrap"}>
          {label}
        </Typography>
      </Box>
      <IconButton onClick={handleClick}>
        <CircleIcon
          sx={{
            color: value || "grey",
          }}
        />
      </IconButton>
      <Menu
        id="color-icon-select-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {options.map((ele) => (
          <MenuItem
            key={ele.value}
            value={ele.value}
            onClick={() => {
              onChange({
                target: {
                  name: name,
                  value: ele.value,
                  options: options,
                },
              });
              handleClose();
            }}
          >
            <CircleIcon
              sx={{
                color: ele.value,
              }}
            />
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default ColorIconSelect;
