import { Box, Modal, Slider, Button, Stack, Typography, IconButton } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import palette from "themes/palette";
import { DeleteIcon } from "components/Icons/MaterialIcons";

// Styles
const boxStyle = {
  width: "300px",
  height: "300px",
  display: "flex",
  flexFlow: "column",
  justifyContent: "center",
  alignItems: "center",
  background: "#FFF",
  p: 10,
  borderRadius: 3,
  boxShadow: 1,
};
const modalStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

// Modal
const CropperModal = ({ type, src, modalOpen, setModalOpen, setPreview, setUrl }) => {
  const [slideValue, setSlideValue] = useState(10);
  const cropRef = useRef(null);

  //handle save
  const handleSave = async () => {
    if (cropRef) {
      const dataUrl = cropRef.current.getImage().toDataURL("image/png"); // all convert to image/png
      const result = await fetch(dataUrl);
      const blob = await result.blob();
      setUrl && setUrl(result.url);
      setPreview(URL.createObjectURL(blob));
      setModalOpen(false);
    }
  };

  return (
    <Modal sx={modalStyle} open={modalOpen}>
      <Box sx={boxStyle}>
        <Box mb={2}>
          <AvatarEditor
            ref={cropRef}
            image={src}
            border={50}
            width={type.width}
            height={type.height}
            borderRadius={type.isRound ? 150 : 0}
            color={[0, 0, 0, 0.72]}
            scale={slideValue / 10}
            rotate={0}
          />
        </Box>

        {/* MUI Slider */}
        <Slider
          min={10}
          max={50}
          sx={{
            margin: "0 auto",
            width: "100%",
            color: palette.secondary.main,
          }}
          size="medium"
          defaultValue={slideValue}
          value={slideValue}
          onChange={(e) => setSlideValue(e.target.value)}
        />
        <Stack direction={"row"} justifyContent={"space-between"} spacing={1} width={"100%"} mt={2}>
          <Button fullWidth size="small" variant="outlined" onClick={(e) => setModalOpen(false)}>
            cancel
          </Button>
          <Button fullWidth size="small" variant="contained" onClick={handleSave}>
            Save
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

// Container
const ImageCropper = ({ initImage, onFile64Change, type }) => {
  const cropperTypes = {
    avatar: {
      height: 200,
      width: 200,
      isRound: true,
    },
    banner: {
      height: 200,
      width: 350,
      isRound: false,
    },
  };
  const defaultType = "avatar";
  const cropperType = cropperTypes[type || defaultType];

  const [initialPreview, setInitialPreview] = useState(initImage);
  const [src, setSrc] = useState(null);
  // preview
  const [preview, setPreview] = useState(null);
  // base64 file url
  const [fileUrl, setFileUrl] = useState(null);
  // modal state
  const [modalOpen, setModalOpen] = useState(false);
  // ref to control input element
  const inputRef = useRef(null);

  // handle Click
  const handleInputClick = (e) => {
    // console.log(inputRef.current);
    e.preventDefault();
    inputRef.current.click();
  };
  // handle Change
  const handleImgChange = (e) => {
    setSrc(e.target.files[0] && URL.createObjectURL(e.target.files[0]));
    setModalOpen(true);
  };

  const removeImage = () => {
    setSrc(null);
    setPreview(null);
    setFileUrl(null);
    setModalOpen(false);
    onFile64Change && onFile64Change(null);
    setInitialPreview(null);
  };

  useEffect(() => {
    if (fileUrl) {
      onFile64Change && onFile64Change(fileUrl);
    }
  }, [fileUrl]);

  return (
    <Box>
      <CropperModal modalOpen={modalOpen} src={src} setPreview={setPreview} setModalOpen={setModalOpen} setUrl={setFileUrl} type={cropperType} />
      <Stack alignItems="center">
        <input type="file" accept="image/*" ref={inputRef} onChange={handleImgChange} hidden />
        <Box
          sx={{
            width: cropperType.width,
            height: cropperType.height,
            borderRadius: cropperType.isRound ? "50%" : "20px",
            border: "3px solid #ddd",
            overflow: "hidden",
            transition: "all .3s",
            "&:hover": {
              cursor: "pointer",
              background: "#DDD",
              borderColor: palette.primary.main,
              ".logo-text": {
                color: palette.primary.main,
              },
            },
          }}
          onClick={handleInputClick}
        >
          {preview ? (
            <img src={preview} alt="" width={cropperType.width} height={cropperType.height} />
          ) : initialPreview ? (
            <img src={initImage} alt="" width={cropperType.width} height={cropperType.height} />
          ) : (
            <Stack width={"100%"} height="100%" justifyContent={"center"} alignItems={"center"}>
              <Typography
                className="logo-text"
                color="lightGrey"
                fontWeight={"bold"}
                fontSize={20}
                letterSpacing={2}
                sx={{
                  transition: "all .2s",
                }}
              >
                CLICK
              </Typography>
            </Stack>
          )}
        </Box>
        {(preview || initialPreview) && (
          <Box mt={1}>
            <IconButton color="error" onClick={removeImage}>
              <DeleteIcon />
            </IconButton>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default ImageCropper;
