import { Box, Grid, Stack, Typography, colors } from "@mui/material";
import { CircleIcon } from "components/Icons/MaterialIcons";
import _ from "lodash";
import { useState } from "react";
import Formatters from "helpers/Formatters";
import HighchartsWrapper from "components/Highcharts/HighchartsWrapper";
import { ChartColorsRoundRobinDark, ChartColorsRoundRobinGetter } from "helpers/constants";
import NumericFormatters from "helpers/NumericFormatters";
import Text from "components/text/Text";

/**
 *
 * productIdField:
 * - additive_product
 * - feed_product
 * - pond_treatment_product
 *
 * productUsageField
 * - feed_daily
 * - additive_product_usage
 * - pond_treatment_usage
 *
 * @param {*} param0
 * @returns
 */
const FarmingProductTimeseriesChart = ({ data, sonicResponseData, cycle }) => {
  const [viewProductId, setViewProductId] = useState(null);

  const showSonicResponseData = sonicResponseData && sonicResponseData.length > 0;

  /** ---------- Chart Configs :: Series */
  let series = _.take(data, 10)
    .filter((ele) => (!viewProductId ? true : ele.productId === viewProductId))
    .map((ele, idx) => ({
      name: ele?.product?.name,
      type: "column",
      color: ele.color,
      dashStyle: "Solid", // Dashed line
      data: ele.rows.map((ele) => [Formatters.toLocalDatetimeValue(ele.datetime), ele.usage]),
      yAxis: 0,
    }));
  // insert Sonic Response if available
  if (showSonicResponseData) {
    series = [
      {
        type: "areaspline",
        name: "SONIC",
        dashStyle: "Dash", // Dashed line
        data: sonicResponseData.map((ele) => [Formatters.toLocalDatetimeValue(ele.datetime), ele.value]),
        color: colors.teal[400],
        lineWidth: 1,
        fillOpacity: 0.4,
        yAxis: 1,
      },
      ...series,
    ];
  }

  /** ---------- Chart Configs :: Y Axis */
  let yAxis = [
    {
      title: {
        text: "Usage",
      },
    },
  ];
  // insert Sonic Response if available
  if (showSonicResponseData) {
    yAxis = [
      ...yAxis,
      {
        opposite: true,
        title: {
          text: "Response",
        },
      },
    ];
  }

  /** ---------- Chart Configs */
  const detailedChartConfigs = {
    chart: {
      height: "400px",
      animation: false,
    },
    xAxis: {
      type: "datetime",
      // min: Formatters.toLocalDatetimeValue(cycle?.start_date),
      // max: Formatters.toLocalDatetimeValue(cycle?.end_date),
    },
    yAxis: yAxis,
    plotOptions: {
      series: {
        stacking: "overlap",
        animation: false,
      },
      column: {
        maxPointWidth: 30,
        grouping: true,
      },
    },
    series: series,
  };

  const Actions = {
    handleViewProductChang: (productId) => {
      if (viewProductId && viewProductId === productId) {
        setViewProductId(null);
      } else {
        setViewProductId(productId);
      }
    },
  };

  return (
    <Box>
      <HighchartsWrapper options={detailedChartConfigs} />
      <Stack spacing={1}>
        <Typography fontSize={11}>
          <Text>interface.general.total-usage</Text>:
        </Typography>
        <Grid container spacing={0.5}>
          {data.map((ele) => (
            <Grid item xs={12} md={4} lg={3}>
              <Stack
                direction={"row"}
                spacing={0.4}
                alignItems={"center"}
                onClick={() => Actions.handleViewProductChang(ele?.productId)}
                sx={{
                  bgcolor: viewProductId === ele.productId && "#EEE",
                  borderRadius: 2,
                  pl: 0.5,
                  ":hover": {
                    cursor: "pointer",
                  },
                }}
              >
                <CircleIcon
                  sx={{
                    fontSize: 10,
                    color: ele.color,
                  }}
                />
                <Typography fontSize={11}>{ele?.product?.name}:</Typography>
                <Typography fontSize={11}>
                  <strong>{NumericFormatters.format({ value: ele?.stats?.total })}</strong>
                </Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Box>
  );
};

export default FarmingProductTimeseriesChart;
