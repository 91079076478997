import { Box, Stack } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { ArrowForwardIcon, CloseIcon, DeleteIcon, EditIcon } from "components/Icons/MaterialIcons";
import { MuiBorderIconButton } from "components/buttons/MuiButtonVariants";
import { getFieldText, getGeneralText } from "components/text/Text";
import Formatters from "helpers/Formatters";
import numeral from "numeral";
import { useState } from "react";
import palette from "themes/palette";

const ScenariosTable = ({ rows, actions }) => {
  const [selected, setSelected] = useState(null);

  const tableRows = rows.map((item, idx) => {
    return {
      ...item,
      seededDensity: item.latest_snapshot?.formValues?.seededDensity,
      optimalHarvestDoc: item.latest_snapshot?.result?.farm?.stats?.optimalHarvestDoc,
      optimalHarvestFcr: item.latest_snapshot?.result?.farm?.stats?.optimalHarvestFcr,
      optimalHarvestBiomass: item.latest_snapshot?.result?.farm?.stats?.optimalHarvestBiomass,
      optimalHarvestAverageUnitCost: item.latest_snapshot?.result?.farm?.stats?.optimalHarvestAverageUnitCost,
      optimalHarvestProfit: item.latest_snapshot?.result?.farm?.stats?.optimalHarvestProfit,
      optimalHarvestTotalCost: item.latest_snapshot?.result?.farm?.stats?.optimalHarvestTotalCost,
      optimalHarvestTotalFeed: item.latest_snapshot?.result?.farm?.stats?.optimalHarvestTotalFeed,
    };
  });

  const [colDef, setColDef] = useState([
    {
      headerName: getGeneralText("time-created"),
      field: "created",
      cellRenderer: ({ value }) => Formatters.toLocalDateTimeString(value),
      flex: 1,
    },
    {
      headerName: getGeneralText("label"),
      field: "label",
      flex: 2,
    },
    {
      headerName: getGeneralText("seeded-density"),
      field: "seededDensity",
      flex: 1,
    },
    {
      headerName: getGeneralText("DoC"),
      field: "optimalHarvestDoc",
      flex: 1,
    },
    {
      headerName: getGeneralText("fcr"),
      field: "optimalHarvestFcr",
      cellRenderer: ({ value }) => numeral(value).format("0,0.00"),
      flex: 1,
    },
    {
      headerName: getFieldText("biomass"),
      field: "optimalHarvestBiomass",
      cellRenderer: ({ value }) => Formatters.formatBiomass({ value: value / 1000 }) + " t",
      flex: 1,
    },
    {
      headerName: getGeneralText("average-unit-cost"),
      field: "optimalHarvestAverageUnitCost",
      cellRenderer: ({ value }) => numeral(value).format("0,0") + " $/kg",
      flex: 1,
    },
    {
      headerName: getGeneralText("net-profit"),
      field: "optimalHarvestProfit",
      cellRenderer: ({ value }) => Formatters.formatDollar({ value }),
      flex: 1,
    },
    {
      headerName: getGeneralText("total-cost"),
      field: "optimalHarvestTotalCost",
      cellRenderer: ({ value }) => Formatters.formatDollar({ value }),
      flex: 1,
    },
    {
      headerName: getGeneralText("total-feed"),
      field: "optimalHarvestTotalFeed",
      cellRenderer: ({ value }) => Formatters.formatBiomass({ value: value / 1000 }) + " t",
      flex: 1,
    },
  ]);

  return (
    <Box>
      <Stack direction={"row"} spacing={1} py={1}>
        <MuiBorderIconButton disabled={!selected} size="small" onClick={() => actions.handleDeleteConfirm(selected)} color="error" icon={<DeleteIcon />} />
        <MuiBorderIconButton disabled={!selected} size="small" onClick={() => actions.openScenarioForm(selected)} color="info" icon={<EditIcon />} />
        <MuiBorderIconButton disabled={!selected} size="small" onClick={() => actions.handleSelectView(selected)} icon={<ArrowForwardIcon />} />
        <MuiBorderIconButton disabled={!selected} size="small" onClick={() => setSelected(null)} icon={<CloseIcon />} />
      </Stack>

      <Box
        className="ag-theme-shrimpl"
        sx={{
          width: "100%",
          height: 600,
        }}
      >
        <AgGridReact
          defaultColDef={{
            minWidth: 110,
          }}
          rowData={tableRows}
          columnDefs={colDef}
          rowHeight={35}
          headerHeight={35}
          rowSelection={"single"}
          onRowClicked={(e) => {
            setSelected(e.data);
          }}
          getRowStyle={(params) => {
            return {
              backgroundColor: selected && params.node?.data?.id === selected?.id && palette.primary.light,
            };
          }}
          suppressScrollOnNewData
        />
      </Box>
    </Box>
  );
};

export default ScenariosTable;
