import _ from "lodash";
import * as turf from "@turf/turf";

export const getBoundsFromJson = (geoJson) => {
  const allPoints = _.flatten(_.flatten(geoJson.features.map((ele) => ele.geometry.coordinates)));
  const minX = _.min(allPoints.map((ele) => ele[1]));
  const minY = _.min(allPoints.map((ele) => ele[0]));
  const maxX = _.max(allPoints.map((ele) => ele[1]));
  const maxY = _.max(allPoints.map((ele) => ele[0]));
  return {
    minX,
    minY,
    maxX,
    maxY,
  };
};

export const commonInitMaps = (maps) => {
  // ---- Add "toGeoJson" function to Polygon Prototype
  maps.Polygon.prototype.toGeoJson = function () {
    let geoJSONGeometry = {
      type: "Polygon",
      coordinates: [],
    };
    let paths = this.getPaths().getArray();
    for (let path of paths) {
      let pathArray = [];
      let points = path.getArray();
      let firstPoint = false;
      for (let point of points) {
        if (firstPoint === false) {
          firstPoint = point;
        }
        pathArray.push([point.lng(), point.lat()]);
      }
      pathArray.push([firstPoint.lng(), firstPoint.lat()]);
      geoJSONGeometry.coordinates.push(pathArray);
    }
    const turfPolygon = turf.polygon(geoJSONGeometry.coordinates);
    const turfArea = turf.area(turfPolygon);
    const geoJSON = {
      type: "Feature",
      geometry: geoJSONGeometry,
      properties: {
        area: turfArea,
        areaHa: turfArea / 10000,
        areaKm: turfArea / 1000000,
      },
    };
    return geoJSON;
  };

  // ---- Add "toGeoJson" function to Rectangle Prototype
  maps.Rectangle.prototype.toGeoJson = function () {
    const bbox = this.getBounds().toJSON();
    let geoJSONGeometry = {
      type: "Polygon",
      coordinates: [
        [
          [bbox.west, bbox.north], // Top-left corner
          [bbox.east, bbox.north], // Top-right corner
          [bbox.east, bbox.south], // Bottom-right corner
          [bbox.west, bbox.south], // Bottom-left corner
          [bbox.west, bbox.north], // Closing the polygon
        ],
      ],
    };
    const turfPolygon = turf.polygon(geoJSONGeometry.coordinates);
    const turfArea = turf.area(turfPolygon);
    const geoJSON = {
      type: "Feature",
      geometry: geoJSONGeometry,
      properties: {
        area: turfArea,
        areaHa: turfArea / 10000,
        areaKm: turfArea / 1000000,
      },
    };
    return geoJSON;
  };
};

export const addGeoJsonGroup = (map, geojson, group) => {
  let NewGeojson = {
    ...geojson,
    features: geojson.features.map((ele) => ({
      ...ele,
      properties: {
        ...ele,
        _g: group,
      },
    })),
  };
  return map && map.data.addGeoJson(NewGeojson);
};

export const simplifyFeature = ({ feature, tolerance = 1 }) => {
  const simplified = turf.simplify(feature, { tolerance: tolerance, highQuality: true });
  return simplified;
};

export const simplifyGeojson = ({ geojson, tolerance = 1 }) => {
  console.log(geojson);
  const simplified = turf.simplify(geojson, { tolerance: tolerance, highQuality: true });
  console.log(simplified);
  return simplified;
};

export const calculatePoints = (geojson) => {
  if (!geojson) return 0;
  const count = _.flatten(geojson.features.map((ele) => _.flatten(ele.geometry.coordinates))).length;
  return count;
};
