import { Avatar, Box, Card, LinearProgress, Stack, Tooltip, Typography, colors } from "@mui/material";
import { InfoIcon } from "components/Icons/MaterialIcons";
import React from "react";
import palette from "themes/palette";

const MobileWidgetCard = ({ icon = undefined, tooltip = undefined, title = "", subtitle = "", borderRadius = 3, padding = 2, sx, onClick, loading, showLoading, children }) => {
  return (
    <Card
      sx={{
        borderRadius: borderRadius,
        position: "relative",
        boxShadow: "none",
        border: "1px solid #DDD",
        ...sx,
      }}
      onClick={() => onClick && onClick()}
    >
      {showLoading && loading && (
        <Box
          height={2}
          width={"100%"}
          sx={{
            position: "absolute",
            zIndex: 999,
            top: "0px",
          }}
        >
          <LinearProgress />
        </Box>
      )}
      <Box p={padding}>
        {(icon || title || subtitle) && (
          <Stack direction={"row"} alignItems={"center"} spacing={1} mb={1}>
            {icon && (
              <Avatar
                sx={{
                  width: 26,
                  height: 26,
                  bgcolor: palette.primary.light,
                  svg: {
                    color: palette.primary.main,
                    fontSize: 16,
                  },
                }}
              >
                {icon}
              </Avatar>
            )}
            {tooltip && (
              <Tooltip title={tooltip} placement="top">
                <Box>
                  <InfoIcon sx={{ color: colors.grey[500], fontSize: 14 }} />
                </Box>
              </Tooltip>
            )}
            <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
              {title && (
                <Typography fontWeight={800} fontSize={16} textTransform={"capitalize"}>
                  {title}
                </Typography>
              )}

              {subtitle && (
                <Typography fontWeight={800} fontSize={16} color={colors.grey[500]}>
                  {subtitle}
                </Typography>
              )}
            </Stack>
          </Stack>
        )}

        {children}
      </Box>
    </Card>
  );
};

export default MobileWidgetCard;
