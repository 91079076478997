import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PondManagerServices } from "api/pondManagerServices";

const initialState = {
  isMenuCollapsed: true,
  members: [],
  isInit: false,
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    /** init data when prefore chat is loaded */
    init(state, action) {
      const { farmId } = action.payload;

      const runner = async () => {
        const result = await PondManagerServices.getFarmAccessControl(farmId);
        console.log(result);
      };
      state.members = action.payload.members;
      state.isInit = true;
    },
    isMenuCollapsedFunc(state, action) {
      state.isMenuCollapsed = action.payload;
    },
  },
});

export const chatStoreActions = chatSlice.actions;

export const { isMenuCollapsedFunc } = chatSlice.actions;

export default chatSlice.reducer;
