const PlatformLanguages = [
  {
    code: "en",
    name: "English",
    nativeName: "English",
    country_code: "gb",
  },
  {
    code: "es",
    name: "Spanish",
    nativeName: "Español",
    country_code: "es",
  },
  {
    code: "pt",
    name: "Portuguese",
    nativeName: "Português",
    country_code: "pt",
  },
  {
    code: "zh-CN",
    name: "Chinese",
    nativeName: "简体中文",
    country_code: "ch",
  },
  {
    code: "vi",
    name: "Vietnamese",
    nativeName: "Tiếng Việt",
    country_code: "vi",
  },
  {
    code: "ja",
    name: "Japanese",
    nativeName: "日本語",
    country_code: "jp",
  },
];

export default PlatformLanguages;
