import { Box, Button, Stack, Typography } from "@mui/material";
import { theme } from "App";
import { ShrimplLogoSvg } from "components/Images";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { userActions } from "redux/user";

const EmailNotVerified = () => {
  const [emailSent, setEmailSent] = useState(false);

  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const userId = searchParams.get("requestId");

  const Actions = {
    resendConfirmationEmail: () => {
      userActions.resendConfirmationEmail(dispatch, { requestId: userId });
      setEmailSent(true);
    },
  };

  if (!userId)
    return (
      <Box backgroundColor={theme.palette.primary.main}>
        <Stack
          height="100vh"
          width="100vw"
          direction="row"
          justifyContent="center"
          alignItems="center"
        ></Stack>
      </Box>
    );
  return (
    <Box backgroundColor={theme.palette.primary.main}>
      <Stack
        height="100vh"
        width="100vw"
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {emailSent ? (
          <Box p={5} backgroundColor="white" borderRadius={1} maxWidth="400px">
            <img src={ShrimplLogoSvg} alt="" width="200px" />
            <Box my={2}>
              <Typography variant="h5">Confirmation Email is resent</Typography>
            </Box>
          </Box>
        ) : (
          <Box p={5} backgroundColor="white" borderRadius={1} maxWidth="400px">
            <img src={ShrimplLogoSvg} alt="" width="200px" />
            <Box my={2}>
              <Typography variant="h5">
                Your email is not yet confirmed
              </Typography>
              <br />
              <Typography variant="body1">
                Please check your inbox or click button below to resend the
                confirmation email.
              </Typography>
            </Box>
            <Box>
              <Button
                color="secondary"
                variant="contained"
                fullWidth
                onClick={Actions.resendConfirmationEmail}
              >
                Resend Confirmation Email
              </Button>
            </Box>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default EmailNotVerified;
