import LoadingBox from "components/Loading/LoadingBox";
import { useParams } from "react-router-dom";
import SideMenuLayout, { SideMenuLayoutThemes } from "screens/Aquaculture/components/Layout/SideMenuLayout";
import { MapIcon } from "components/Icons/MaterialIcons";
import GeoSketchPad from "screens/Dev/GeoSketchPad";
import GeojsonPondSelector from "screens/Dev/GeojsonPondSelector";

/**
 *
 *
 * @param {} param0
 * @returns
 */
const UtilsHome = () => {
  const { page } = useParams();

  const MENULIST = [
    {
      label: "GEO Sketchpad",
      description: "",
      value: 0,
      icon: <MapIcon />,
      component: <GeoSketchPad />,
    },
    {
      label: "Polygon Filter",
      description: "",
      value: 1,
      icon: <MapIcon />,
      component: <GeojsonPondSelector />,
    },
  ];

  return <SideMenuLayout theme={SideMenuLayoutThemes.buttons} menulist={MENULIST} defaultViewMode={0} title="Shrimpl Utils" height="100vh" />;
};

export default UtilsHome;
