const initState = {
  open: false,
  opens: {},
};

const modalReducerTypes = {
  OPEN_MODAL: "OPEN_MODAL",
  CLOSE_MODAL: "CLOSE_MODAL",
};

const modalReducers = (state = initState, action) => {
  switch (action.type) {
    case modalReducerTypes.OPEN_MODAL: {
      const modalId = action.payload.id;
      const modalOpen = true;
      if (modalId) {
        return {
          ...state,
          opens: {
            ...state.opens,
            [modalId]: modalOpen,
          },
        };
      }
    }
    case modalReducerTypes.CLOSE_MODAL: {
      const modalId = action.payload.id;
      const modalOpen = false;
      return {
        ...state,
        opens: {
          ...state.opens,
          [modalId]: modalOpen,
        },
      };
    }

    default:
      return state;
  }
};

export const modalReducersActions = {
  openModal: (dispatch, { id }) => {
    dispatch({
      type: modalReducerTypes.OPEN_MODAL,
      payload: {
        id,
      },
    });
  },
  closeModal: (dispatch, { id }) => {
    dispatch({
      type: modalReducerTypes.CLOSE_MODAL,
      payload: {
        id,
      },
    });
  },
};

export const modalIds = {
  POND_EDIT_MODAL: "POND_EDIT_MODAL",
  FARM_IMAGE_MODAL: "FARM_IMAGE_MODAL",
  PHOTO_LOGS_MODAL: "PHOTO_LOGS_MODAL",
  FARM_RECORD_MODAL: "FARM_RECORD_MODAL",
  FARM_ALERT_RULES_MODAL: "FARM_ALERT_RULES_MODAL",
  SCENARIO_FORM: "SCENARIO_FORM",
  ADMIN_PRODUCER_FORM: "ADMIN_PRODUCER_FORM",
  ADMIN_PRODUCT_FORM: "ADMIN_PRODUCT_FORM",
  FARMING_PRODUCT_PRICE_VIEW: "FARMING_PRODUCT_PRICE_VIEW",
  FARMING_PRODUCT_PREVIEW: "FARMING_PRODUCT_PREVIEW",
  CYCLE_EDIT_MODAL: "CYCLE_EDIT_MODAL",
};

export default modalReducers;
