import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import { ClimateServices } from "api/climateServices";
import HighchartsWrapper from "components/Highcharts/HighchartsWrapper";
import { TimelineIcon } from "components/Icons/MaterialIcons";
import LoadingBox from "components/Loading/LoadingBox";
import MuiToggleButtonGroup from "components/buttons/MuiToggleButtonGroup";
import Text, { getText } from "components/text/Text";
import TimeFormatters from "helpers/TimeFormatters";
import climateDashboardData from "json/climateDashboardData.json";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { BsBarChartLineFill } from "react-icons/bs";
import { generateHighChartBarData, generateHighChartData } from "screens/Aquaculture/components/Climate/ClimateChartDataSet/getUniqueValue";
import ClimateIconWrapper from "screens/Aquaculture/components/Climate/ClimateIconWrapper/ClimateIconWrapper";

/**
 *
 *
 * @param {farm} farm
 * @returns
 */

const ClimateDashboard = ({ farm }) => {
  const climateInitialData = {
    isLoading: true,
    data: [],
    err: {},
  };

  const [tabValue, setTabValue] = useState("temperature");
  const [climateData, setClimateData] = useState(climateInitialData);
  const [selectedDataType, setSelectedDataType] = useState("hourly");
  const [selectedChartType, setSelectedChartType] = useState("lineChart");

  const handleClimateSelectToggler = (selected) => setSelectedDataType(selected);

  const getClimateData = async () => {
    setClimateData((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });

    const hourlyStartDate = moment("12:00 AM", "h:mm A").utc().toISOString();
    const hourlyEndDate = moment("11:00 PM", "h:mm A").utc().toISOString();

    const climateDataFetch = await ClimateServices.getClimates({
      latitude: farm?.lat,
      longitude: farm?.lon,
      dataType: selectedDataType === "hourly" ? "hourly" : "daily",
      hourlyStartDate,
      hourlyEndDate,
    });

    setClimateData((prev) => {
      return {
        ...prev,
        isLoading: false,
        data: climateDataFetch?.data?.timelines[0].intervals,
      };
    });
  };

  useEffect(() => {
    setClimateData([]);
    getClimateData();
  }, [selectedDataType]);

  const generateHourlyLabel = () => {
    return climateData?.data?.map((climate) => {
      return TimeFormatters.formatLocalDateTimeFromIso(climate.startTime, "T");
    });
  };

  // console.log("generateHourlyLabel()", generateHourlyLabel());

  const getUniqueDailyValue = () => {
    return (climateData.data || []).map((val) => {
      if (selectedChartType === "lineChart") {
        return val.values[tabValue];
      } else {
        return {
          name: val?.startTime?.split("T")[0],
          y: val.values[tabValue],
        };
      }
    });
  };

  const handleTabChange = (_, value) => setTabValue(value);

  const getUniqueHourlyValue = (specificDate) => {
    return (climateData.data || [])
      ?.slice(0, 24)
      .map((val) => {
        if (selectedChartType === "lineChart") {
          return val.values[tabValue];
        } else {
          return {
            name: TimeFormatters.formatDateTimeFromIso(val?.startTime, "T"),
            y: val.values[tabValue],
          };
        }
      })
      .filter((allTheData) => allTheData !== null);
  };

  const gotDailyDates = () => {
    if (selectedDataType === "daily") {
      return climateData?.data?.map((resData) => {
        return TimeFormatters.formatDateTimeFromIso(resData.startTime, "DD", "");
      });
    } else {
      return [];
    }
  };

  const highChartTabSelector = generateHighChartData(getUniqueHourlyValue, getUniqueDailyValue, tabValue, selectedDataType, gotDailyDates, generateHourlyLabel);

  const highChartBarSelector = generateHighChartBarData(getUniqueHourlyValue, getUniqueDailyValue, tabValue, selectedDataType, gotDailyDates, generateHourlyLabel);

  const CustomTabLabel = ({ name, title, nameKey, short, value, unit }) => {
    return (
      <Stack
        sx={{
          height: 80,
          width: 80,
        }}
      >
        <Typography fontSize={9} fontWeight={800} sx={{}}>
          <Text>{`interface.climate.${nameKey || short}`}</Text>
        </Typography>
        <Box>
          <ClimateIconWrapper climateType={name} />
          <Typography fontWeight={800} fontSize={15} lineHeight={1}>
            {value || "--"}
          </Typography>
          <Typography fontWeight={800} fontSize={9}>
            {unit || "-"}
          </Typography>
        </Box>
      </Stack>
    );
  };

  const getLatestClimateValue = (climateType) => {
    const currentHour = moment().hour();

    const utcToLocalHour = (utcTime) => {
      const utcTimeToLocal = moment(utcTime).local();
      return utcTimeToLocal.format("HH");
    };

    const climateArray = climateData?.data
      ?.slice(0, 24)
      .map((val) => {
        const resTime = utcToLocalHour(val?.startTime);
        if (resTime == currentHour) {
          return val?.values[climateType];
        } else {
          return null;
        }
      })
      .filter((filteredData) => filteredData !== null);
    return climateArray;
  };

  const getLatestClimateDailyValue = (climateType) => {
    const currentDate = moment().format("YYYY-MM-DD");

    const utcToLocalDate = (utcTime) => {
      const utcTimeToLocal = moment(utcTime);
      return utcTimeToLocal.format("YYYY-MM-DD");
    };

    const climateArray = climateData?.data
      ?.slice(0, 24)
      .map((val) => {
        const resTime = utcToLocalDate(val?.startTime);
        if (resTime == currentDate) {
          return val?.values[climateType];
        } else {
          return null;
        }
      })
      .filter((filteredData) => filteredData !== null);
    return climateArray;
  };

  const climateDataDetials = _.find(climateDashboardData, { name: tabValue });

  const handleChartType = (data) => {
    setSelectedChartType(data);
  };

  const chartConfigs = selectedChartType === "lineChart" ? highChartTabSelector : highChartBarSelector;

  return (
    <Box sx={{ p: 2 }}>
      <Stack direction={"row"} spacing={2} alignItems={"center"}>
        <MuiToggleButtonGroup
          onChange={setSelectedChartType}
          value={selectedChartType}
          options={[
            {
              label: "Line",
              value: "lineChart",
              icon: <TimelineIcon />,
            },
            {
              label: "Bar",
              value: "barChart",
              icon: <BsBarChartLineFill />,
            },
          ]}
        />

        <MuiToggleButtonGroup
          onChange={handleClimateSelectToggler}
          value={selectedDataType}
          options={[
            {
              label: getText("interface.time.hourly"),
              value: "hourly",
            },
            {
              label: getText("interface.time.daily"),
              value: "daily",
            },
          ]}
        />
      </Stack>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "10px",
        }}
      ></Box>
      <Box
        sx={{
          borderBottom: "1px solid #ccc",
        }}
      >
        <Tabs value={tabValue} onChange={handleTabChange} variant="scrollable" scrollButtons="auto" aria-label="Climate tabs">
          {climateDashboardData.map((data, i) => (
            <Tab
              key={i}
              value={data.name}
              label={
                <CustomTabLabel
                  name={data.name}
                  value={selectedDataType === "hourly" ? getLatestClimateValue(data.name) : getLatestClimateDailyValue(data.name)}
                  title={data.title}
                  unit={data.unit}
                  short={data.short}
                  nameKey={data.nameKey}
                />
              }
            />
          ))}
        </Tabs>
      </Box>
      <Box my={2}>
        <Typography color="primary" fontWeight={"bold"} fontSize={20} textAlign={"center"}>
          {climateDataDetials?.title} <small>(unit: {climateDataDetials?.unit})</small>
        </Typography>
      </Box>
      {climateData?.isLoading ? <LoadingBox /> : <HighchartsWrapper options={chartConfigs} />}
    </Box>
  );
};

export default ClimateDashboard;
