import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React from "react";

/**
 *
 * Wrapper for Toggle Button Group (Icon Button Only)
 * ==================================================
 *
 * Example
 * -------
 *
 * ```
 * <MuiToggleButtonGroup
 *   onChange={handleListViewSelector}
 *   value={selectedListView}
 *   options={[
 *     {
 *       label: "Grid View",
 *       value: HomePageListViews.grid,
 *       icon: <ViewModuleIcon />,
 *     },
 *     {
 *       label: "Table View",
 *       value: HomePageListViews.table,
 *       icon: <ReorderIcon />,
 *     },
 *     {
 *       label: "Map View",
 *       value: HomePageListViews.map,
 *       icon: <MapIcon />,
 *     },
 *   ]}
 * />
 * ```
 *
 * @param {String}        value     Current Value
 * @param {Function}      onChange  Function called when value is updated
 * @param {String}        size      Size of the component 'small' | 'medium' (default) | 'large'
 * @param {List[Object]}  options   Options to render. Option :: { label, value*, icon* }
 *
 * @returns
 */
const MuiToggleButtonGroup = ({ value, onChange, size, options }) => {
  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={(e, val) => {
        if (val !== null) {
          onChange(val);
        }
      }}
      size={size || "small"}
      sx={{
        svg: {
          fontSize: 15,
        },
      }}
    >
      {options.map((option) => (
        <ToggleButton key={option.value} value={option.value} aria-label={option.value} title={option.label}>
          {option.icon || (
            <Typography fontSize={9} fontWeight={800}>
              {option.label}
            </Typography>
          )}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default MuiToggleButtonGroup;
