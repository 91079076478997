import { Box, Button, colors, Grid, Stack, Typography } from "@mui/material";
import { AddCircleOutlineIcon, ArrowForwardIcon, MapIcon, ReorderIcon, SettingsIcon, ViewModuleIcon } from "components/Icons/MaterialIcons";
import { getLocalStorageItem, saveLocalStorageItem } from "helpers/LocalStorage";
import _ from "lodash";
import { useState } from "react";
import { useSelector } from "react-redux";

import { Link, useNavigate } from "react-router-dom";
import { ROUTE_POND_MANAGER_FARM_CREATE, ROUTE_POND_MANAGER_FARM_EDIT } from "routes/paths";
import Text, { getText } from "components/text/Text";
import PondManagerHomePageTableView from "./PondManagerHomePageTableView";
import PondManagerHomePageMapView from "screens/Aquaculture/components/PondManager/PondManagerHomePageMapView";
import LoadingBox from "components/Loading/LoadingBox";
import MuiToggleButtonGroup from "components/buttons/MuiToggleButtonGroup";
import FarmCard from "screens/Aquaculture/components/common/FarmCard";
import OrganisationInviteTable from "screens/Aquaculture/Organisations/OrganisationInviteTable";
import { isMobile } from "themes/helpers";
import { RecentViewedFarms } from "screens/Aquaculture/components/PondManager/components/RecentViewedFarms";

const HomePageListViews = {
  grid: "grid",
  table: "table",
  map: "map",
};

/**
 *
 * Pond Manager Home Page - Farm View
 *
 * @param {*} param0
 * @returns
 */
const PondManagerHomePage = () => {
  const farmListViewStorageData = getLocalStorageItem("farmListView");

  const pondManagerStates = useSelector((state) => state.pond);

  const farms = pondManagerStates.farms;

  const [selectedListView, setSelectedListView] = useState(farmListViewStorageData || HomePageListViews.grid);

  const Variables = {
    stats: [
      {
        label: getText("interface.general.total-farms"),
        value: farms.length,
      },
      {
        label: getText("interface.general.total-ponds"),
        value: _.sumBy(farms, "total_ponds"),
      },
      {
        label: getText("interface.general.total-area") + " (ha.)",
        value: _.sumBy(farms, "total_area")?.toFixed(0),
      },
      {
        label: getText("interface.general.total-area") + " (ac.)",
        value: (_.sumBy(farms, "total_area") * 2.47105)?.toFixed(0),
      },
    ],
  };

  const navigate = useNavigate();

  const handleListViewSelector = (selectedType) => {
    setSelectedListView(selectedType);
    saveLocalStorageItem("farmListView", selectedType);
  };

  return (
    <Box p={isMobile() ? 1 : 2}>
      {/* <OrganisationInviteTable /> */}
      {/* Top Toolbar */}
      <Box mb={1}>
        <Stack direction={isMobile() ? "column" : "row"} justifyContent="space-between" alignItems={"center"} spacing={1}>
          {!isMobile() && (
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <Box>
                <Button variant="contained" startIcon={<AddCircleOutlineIcon />} component={Link} to={ROUTE_POND_MANAGER_FARM_CREATE}>
                  <Text>interface.general.farm</Text>
                </Button>
              </Box>
              <MuiToggleButtonGroup
                onChange={handleListViewSelector}
                value={selectedListView}
                options={[
                  {
                    label: "Grid View",
                    value: HomePageListViews.grid,
                    icon: <ViewModuleIcon />,
                  },
                  {
                    label: "Table View",
                    value: HomePageListViews.table,
                    icon: <ReorderIcon />,
                  },
                  {
                    label: "Map View",
                    value: HomePageListViews.map,
                    icon: <MapIcon />,
                  },
                ]}
              />
            </Stack>
          )}

          <Stack direction="row" alignItems={"center"} spacing={1}>
            {Variables.stats.map((ele, idx) => {
              return (
                <Box key={idx}>
                  <Typography variant="caption">{ele.label}</Typography>
                  <Typography fontWeight="bold">{ele.value}</Typography>
                </Box>
              );
            })}
          </Stack>
        </Stack>
      </Box>

      <Box mb={1}>
        <RecentViewedFarms farms={farms} />
      </Box>

      {/* ~~~~~ Placeholder ~~~~~ */}

      <Box>
        {!pondManagerStates.loading && farms.length === 0 && (
          <Box p={2}>
            <Stack alignItems={"center"} width="100%">
              <Box>
                <Typography fontSize="3rem" fontWeight="bold" color={colors.grey[500]}>
                  Create your first farm!
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography color={colors.grey[500]}>Click the add button above to claim your first farm</Typography>
              </Box>
            </Stack>
          </Box>
        )}

        {/* ~~~~~ MAIN ~~~~~ */}

        {pondManagerStates.loading ? (
          <LoadingBox />
        ) : (
          <>
            {/* ************* GRID VIEW ************* */}
            <Grid container rowSpacing={3} columnSpacing={3} mt={3}>
              {selectedListView === HomePageListViews.grid &&
                farms.map((f) => {
                  return (
                    <Grid key={f.farm_id} item xs={12} md={6} lg={4} xl={3}>
                      <FarmCard farm={f} />
                    </Grid>
                  );
                })}
            </Grid>

            {/* ************* TABLE VIEW ************* */}
            {selectedListView === HomePageListViews.table && farms.length > 0 && (
              <Box mt={2}>
                <PondManagerHomePageTableView farms={farms} farmEditPath={ROUTE_POND_MANAGER_FARM_EDIT} editIcon={<SettingsIcon />} viewIcon={<ArrowForwardIcon />} />
              </Box>
            )}

            {/* ************* MAP VIEW ************* */}
            {selectedListView === HomePageListViews.map && farms.length > 0 && (
              <Box mt={2}>
                <PondManagerHomePageMapView farms={farms} farmEditPath={ROUTE_POND_MANAGER_FARM_EDIT} editIcon={<SettingsIcon />} viewIcon={<ArrowForwardIcon />} />
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default PondManagerHomePage;
