import { colors } from "@mui/material";
import Text from "components/text/Text";
import { GiChemicalDrop, GiMedicinePills, GiSwapBag } from "react-icons/gi";

const FarmingProductTypes = {
  FEED: {
    value: "FEED",
    label: <Text>interface.general.feed</Text>,
    color: colors.orange[800],
    icon: <GiSwapBag />,
  },
  POND_TREATMENT: {
    value: "POND_TREATMENT",
    label: <Text>interface.general.pond-treatment</Text>,
    color: colors.blue[800],
    icon: <GiMedicinePills />,
  },
  ADDITIVES: {
    value: "ADDITIVES",
    label: <Text>interface.general.additive</Text>,
    color: colors.red[800],
    icon: <GiChemicalDrop />,
  },
};

export default FarmingProductTypes;
