import { Box, Grid, Stack, Tooltip, Typography, colors } from "@mui/material";
import LeftAvatar from "components/ChatBoard/LeftAvatar";
import moment from "moment";
import styled from "styled-components";
import palette from "themes/palette";

/**
 *
 * UI for sender box
 *
 * @param {*} param0
 * @returns
 */
const SenderBox = ({
  displayName,
  photoURL,
  lastMessage,
  timeGap,
  onClick,
  isSelected,
  isFloatingMessage,
  badgeCount,
}) => {
  if (isFloatingMessage) {
    return (
      <Box
        py={0.4}
        px={1}
        onClick={onClick}
        ml={1}
        sx={{
          bgcolor: isSelected ? palette.primary.main : colors.grey[50],
          transition: "all .2s",
          ml: isSelected ? 0.5 : 1,
          borderRadius: "5px 0 0 5px",
          display: "flex",
          justifyContent: "space-between",
          "&:hover": {
            bgcolor: !isSelected && colors.grey[200],
            cursor: "pointer",
          },
        }}
      >
        <Typography
          textAlign={"left"}
          fontSize={13}
          fontWeight={isSelected && 800}
          color={isSelected ? palette.primary.light : palette.primary.main}
        >
          {displayName}
        </Typography>
        <Typography fontWeight={800} color="green">
          {badgeCount >= 1 && badgeCount}
        </Typography>
      </Box>
    );
  }

  return (
    <Grid
      container
      alignItems={"center"}
      onClick={onClick}
      height={"60px"}
      sx={{
        p: 1,
        bgcolor: isSelected ? colors.grey[300] : "#FFF",
        cursor: "pointer",
        "&:hover": {
          bgcolor: colors.grey[200],
        },
        borderRadius: 1,
      }}
    >
      <Grid item width={"40px"} mr={1}>
        <LeftAvatar displayName={displayName} badgeCount={badgeCount} />
      </Grid>
      <Grid item xs>
        <Typography fontWeight={800} fontSize={12}>
          {displayName}
        </Typography>
        <Typography
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "200px",
          }}
          fontSize={12}
          color={colors.grey[800]}
        >
          {lastMessage}
        </Typography>
      </Grid>
      <Grid item width="60px" ml={1} color={colors.grey[700]}>
        <Typography fontSize={10} textAlign={"right"}>
          {moment(timeGap).fromNow()}
        </Typography>
      </Grid>
    </Grid>
    // <Stack
    //   direction="row"
    //   alignItems="center"
    //   onClick={onClick}
    //   sx={{
    //   bgcolor: isSelected ? colors.grey[300] : "#FFF",
    //   cursor: "pointer",
    //   m: 1,
    //   borderRadius: 2,
    //   "&:hover": {
    //     bgcolor: colors.grey[200],
    //   },
    // }}
    // >
    //   <AvatarContainer>
    //     <LeftAvatar displayName={displayName} />
    //   </AvatarContainer>
    //   <Box sx={{ flex: 1 }}>
    //     <Box>
    //       <Typography fontWeight={800}>{displayName}</Typography>
    //     </Box>
    //     <Box
    //       sx={{
    //         whiteSpace: "nowrap",
    //         overflow: "hidden",
    //         textOverflow: "ellipsis",
    //       }}
    //     >
    // <Typography fontSize={12} color={colors.grey[800]}>
    //   {lastMessage}
    // </Typography>
    //     </Box>
    //   </Box>
    //   <Box sx={{ width: "80px", color: "gray", fontWeight: "500", pr: 2 }}>
    //     <Typography textAlign={"right"} fontSize={11}>
    //       {moment(timeGap).fromNow()}
    //     </Typography>
    //   </Box>
    // </Stack>
  );
};

export default SenderBox;
