import { Box, Button, FormControl, InputLabel, LinearProgress, MenuItem, Select, Stack, TextField, Typography, colors } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { PondManagerServices } from "api/pondManagerServices";
import { VerticalAlertBoxCollections } from "components/Alerts/AlertBox";
import HighchartsWrapper from "components/Highcharts/HighchartsWrapper";
import Text, { getText } from "components/text/Text";
import NumericFormatters from "helpers/NumericFormatters";
import { DateTime } from "luxon";

import React, { useEffect, useState } from "react";
import { AppSelectors } from "redux/AppReducers";
import WidgetCard from "ui/Card/WidgetCard";

const today = DateTime.now();
const aYearAgo = today.plus({ months: -12 });

// component :: Cashflow

const PondCashflowTable = ({ data, farm }) => {
  const pondMangamentStore = AppSelectors.pondManagementStore();
  const { fields } = pondMangamentStore;

  const dataSum = data.filter((ele) => ele.datetime === "sum")?.[0];
  const dataMonths = data.filter((ele) => ele.datetime !== "sum");
  const months = dataMonths.map((ele) => ele.datetime);

  const mergeRow = (field) =>
    _.merge(
      {},
      ...[
        { field: field },
        ...dataMonths.map((item) => ({ [item.datetime]: item?.[field] })),
        {
          sum: dataSum?.[field],
        },
      ]
    );

  const currencySymbol = NumericFormatters.formatCurrencySymbol({ value: farm?.settings?.currency });

  const cashFlowCellRenderer = ({ value }) => {
    if (value === undefined || value === null) {
      return "";
    }
    const displayValue = value >= 0 ? NumericFormatters.format({ value, decimalScale: 2 }) : <span>({NumericFormatters.format({ value, decimalScale: 2 })})</span>;
    return (
      <Stack direction={"row"} width={"100%"} justifyContent={"space-between"}>
        <Typography fontSize={11} color="#aaa">
          {currencySymbol}
        </Typography>
        <Typography fontSize={11}>{displayValue}</Typography>
      </Stack>
    );
  };

  const tableRows = [
    mergeRow("f_revenue"),
    mergeRow("f_tax_credits"),
    { field: "" },
    mergeRow("f_interst_expense"),
    mergeRow("f_permits_costs"),
    mergeRow("f_labor"),
    mergeRow("f_maintenance_and_construction_costs"),
    mergeRow("f_depreciation"),
    mergeRow("f_infrastructure_costs"),
    mergeRow("f_admin_costs"),
    mergeRow("f_consulting_costs"),
    mergeRow("f_insurance_costs"),
    mergeRow("f_lease_costs"),
    mergeRow("sum_fixed_cost"),
    { field: "" },
    mergeRow("f_energy"),
    mergeRow("f_inventory_costs"),
    mergeRow("f_sales_costs"),
    mergeRow("f_seeding_costs"),
    mergeRow("f_travel_expense"),
    mergeRow("f_services_and_duties_costs"),
    mergeRow("f_harvesting_costs"),
    mergeRow("f_lab_costs"),
    mergeRow("f_processing_costs"),
    mergeRow("f_other_inputs"),
    mergeRow("f_other_costs"),
    mergeRow("f_feed"),
    mergeRow("f_desinfectant_and_pond_cleaning"),
    mergeRow("f_pond_treatment"),
    mergeRow("p_feed"),
    mergeRow("p_pond_treatment"),
    mergeRow("p_additives"),
    mergeRow("sum_var_cost"),
    { field: "" },
    mergeRow("sum"),
  ];

  const colDefs = [
    {
      field: "field",
      headerName: "",
      cellRenderer: ({ data, value }) => {
        const fieldDetails = _.find(fields, { field_id: value });
        if (fieldDetails) return getText(`fields.${value}`);
        if (value === "sum_fixed_cost") return getText("interface.general.subtotal");
        if (value === "sum_var_cost") return getText("interface.general.subtotal");
        if (value === "sum_product_cost") return getText("interface.general.subtotal");
        if (value === "sum") return getText("interface.general.profit");
        if (value === "p_feed") return "[P] " + getText("interface.general.feed");
        if (value === "p_pond_treatment") return "[P] " + getText("interface.general.pond-treatment");
        if (value === "p_additives") return "[P] " + getText("interface.general.additive");
        return value;
      },
      width: 200,
      pinned: "left",
    },
    ...months.map((item) => ({
      field: item,
      headerName: DateTime.fromFormat(item, "yyyy-MM-dd HH:mm:ss").toFormat("MMM-yy"),
      width: 120,
      cellRenderer: cashFlowCellRenderer,
    })),
    {
      field: "sum",
      headerName: getText("interface.general.subtotal"),
      cellRenderer: cashFlowCellRenderer,
      pinned: "right",
      width: 150,
    },
  ];

  return (
    <Box className="ag-theme-excel" height={720}>
      <AgGridReact
        rowData={tableRows}
        columnDefs={colDefs}
        headerHeight={30}
        rowHeight={20}
        getRowStyle={(params) => {
          if (_.includes(["f_revenue", "f_tax_credits"], params.node.data.field)) {
            return { background: colors.green[50] };
          }
          if (_.includes(["sum_fixed_cost", "sum_var_cost", "sum_product_cost"], params.node.data.field)) {
            return { background: colors.red[50] };
          }
        }}
      />
    </Box>
  );
};

const PondCashFlowChart = ({ data, farm }) => {
  console.log(data);
  const chartConfigs = {
    chart: {
      type: "column",
      height: "250px",
      animation: {
        duration: 0,
      },
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: data.map((item) => (item.datetime === "sum" ? "Sum" : DateTime.fromFormat(item.datetime, "yyyy-MM-dd HH:mm:ss").toFormat("MMM-yy"))),
    },
    plotOptions: {
      column: {
        stacking: "normal",
        animation: {
          duration: 0,
        },
      },
    },
    series: [
      {
        name: "Revenue",
        data: data.map((item) => item?.f_revenue),
        color: colors.green[500],
      },
      {
        name: "Cost of Fixed Costs",
        data: data.map((item) => item?.sum_fixed_cost),
        color: colors.red[500],
      },
      {
        name: "Cost of Variable Costs",
        data: data.map((item) => item?.sum_var_cost),
        color: colors.orange[500],
      },
      {
        name: "Cost of Farming Products",
        data: data.map((item) => item?.sum_product_cost),
        color: colors.brown[500],
      },
    ],
  };

  return (
    <Box>
      <HighchartsWrapper options={chartConfigs} />
    </Box>
  );
};

const PondFinancialReport = ({ pond, farm }) => {
  const [year, setYear] = useState(aYearAgo.year);
  const [month, setMonth] = useState(aYearAgo.month);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (year > 1900 && year <= today.year) {
      Actions.onSubmit();
    }
  }, [pond, year, month]);

  const Actions = {
    onShiftTime: (action) => {
      if (action === "+1M") {
        if (month === 12) {
          setMonth(1);
          setYear(year + 1);
        } else {
          setMonth(month + 1);
        }
      }
      if (action === "-1M") {
        if (month === 1) {
          setMonth(12);
          setYear(year - 1);
        } else {
          setMonth(month - 1);
        }
      }
      if (action === "+1Y") {
        setYear(year + 1);
      }
      if (action === "-1Y") {
        setYear(year - 1);
      }
    },
    onSubmit: async () => {
      setLoading(true);
      const data = await PondManagerServices.fetchPondFinancialData({ year, month, pondid: pond?.id });
      // TODO: handle error
      if (data.error) {
        setData([]);
        console.log(data.error);
      } else {
        // set data
        setData(data.rows);
      }
      setLoading(false);
    },
  };

  return (
    <Box p={2}>
      <Stack direction={"row"} spacing={1}>
        <TextField size="small" label={<Text>interface.time.year</Text>} type="number" value={year} onChange={(e) => setYear(e.target.value)} inputProps={{ max: `${today.year}` }}></TextField>
        <FormControl sx={{ minWidth: 180 }}>
          <InputLabel id="select-label">
            <Text>interface.time.month</Text>
          </InputLabel>
          <Select size="small" label={<Text>interface.time.month</Text>} labelId="select-label" value={month} onChange={(e) => setMonth(e.target.value)}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((o) => (
              <MenuItem key={o} value={o}>
                {o}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button variant="outlined" onClick={() => Actions.onShiftTime("+1M")}>
          +1M
        </Button>
        <Button variant="outlined" onClick={() => Actions.onShiftTime("-1M")}>
          -1M
        </Button>
        <Button variant="outlined" onClick={() => Actions.onShiftTime("+1Y")}>
          +1Y
        </Button>
        <Button variant="outlined" onClick={() => Actions.onShiftTime("-1Y")}>
          -1Y
        </Button>
      </Stack>
      <Stack mt={2} spacing={1}>
        <Box height={2}>{loading && <LinearProgress />}</Box>
        {data.length > 0 ? (
          <WidgetCard title={<Text>interface.general.cashflow</Text>}>
            <PondCashflowTable data={data} farm={farm} />
            <PondCashFlowChart data={data} farm={farm} />
          </WidgetCard>
        ) : (
          <VerticalAlertBoxCollections.NoData />
        )}
      </Stack>
    </Box>
  );
};

export default PondFinancialReport;
