import { Box, Popover, Stack, Typography, colors } from "@mui/material";
import _ from "lodash";
import { useState } from "react";
import NumberFormat from "react-number-format";
import useClimateQueryHook from "reactQueryHooks/useClimateQueryHook";
import WeatherIcon from "screens/Aquaculture/components/Climate/ClimateDashboard/widgets/WeatherIcon";
import { StaticForecastWidget } from "screens/Aquaculture/components/Climate/ClimateDashboard/widgets/components/widget.component";

const ClimateWidgetButtonInner = ({ data, location }) => {
  if (!data)
    return (
      <Stack
        direction={"row"}
        alignItems={"center"}
        sx={{
          filter: "grayscale(1)",
        }}
      >
        <WeatherIcon value={1000} />
        <Box ml={0.3}>
          <Typography textAlign={"left"} fontSize={14} fontWeight={"bold"}>
            ----°C
          </Typography>
        </Box>
      </Stack>
    );
  const { weatherCode, temperature } = data;
  return (
    <Stack direction={"row"} alignItems={"center"}>
      <WeatherIcon value={weatherCode} />
      {temperature && (
        <Box ml={0.3}>
          <Typography textAlign={"left"} fontSize={14} fontWeight={"bold"}>
            <NumberFormat value={temperature} decimalScale={0} displayType="text" suffix="°C" />
          </Typography>
        </Box>
      )}
    </Stack>
  );
};

/**
 * A weather forecast widget to forecast
 *
 * @param {*} param0
 * @returns
 */
const ClimateWidget = ({ farm }) => {
  const theQuery = useClimateQueryHook({ farm });
  const data = theQuery.data;
  const loading = theQuery.isFetching;

  const [anchorEl, setAnchorEl] = useState(null);
  const Actions = {
    handleClick: (e) => {
      setAnchorEl(e.currentTarget);
    },
    handleClose: (e) => {
      setAnchorEl(null);
    },
  };
  const open = Boolean(anchorEl);
  const id = open ? "climate-dashboard-popup" : undefined;
  const current = _.find(data?.timelines, { timestep: "current" })?.intervals[0]?.values;

  return (
    <Box>
      <Box height={"100%"}>
        <Stack justifyContent={"center"} alignItems={"center"} height="100%">
          <Box
            onMouseEnter={Actions.handleClick}
            onMouseLeave={Actions.handleClose}
            px={1}
            borderRadius={1}
            sx={{
              "&:hover": {
                cursor: "pointer",
                bgcolor: colors.grey[50],
              },
            }}
          >
            <ClimateWidgetButtonInner data={current} location={farm?.region} />
          </Box>
        </Stack>
      </Box>
      <Popover
        sx={{
          pointerEvents: "none",
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={Actions.handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box p={1}>
          <Box p={2}>{data && <StaticForecastWidget data={data} location={`${farm?.region}, ${farm?.country}`} />}</Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default ClimateWidget;
