import { Box, Stack, Typography, colors } from "@mui/material";
import { CircleIcon } from "components/Icons/MaterialIcons";
import OutlinedTag from "components/Tags/OutlinedTag";
import Text, { getText } from "components/text/Text";
import _ from "lodash";
import React from "react";

const AlertRow = ({ label, count }) => {
  const isAlert = count > 0;
  return (
    <Box
      alignItems={"center"}
      sx={{
        "&:hover": {
          bgcolor: colors.grey[100],
        },
      }}
    >
      <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
        <Stack direction={"row"} spacing={1}>
          <Box>
            <CircleIcon
              sx={{
                color: isAlert ? "red" : "green",
                fontSize: 12,
              }}
            />
          </Box>
          <Typography fontSize={12}>{label}</Typography>
        </Stack>
        <Stack>
          <Typography fontSize={12} color={isAlert && "red"}>
            {isAlert ? (
              <strong>
                {" "}
                {count} <Text>interface.general.alerts</Text>
              </strong>
            ) : (
              <Text>interface.general.normal</Text>
            )}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

const FarmAlertsWidget = (props) => {
  const { farmDashboardData } = props;
  const tableRows = farmDashboardData?.tableRows;

  const totalAlerts = _.sumBy(tableRows, "a__total_alerts");
  const alertAggregated = {
    ph: {
      ponds: tableRows.filter((ele) => ele.a__alerts.ph.isAlert),
      count: tableRows.filter((ele) => ele.a__alerts.ph.isAlert).length,
    },
    survival_rate: {
      ponds: tableRows.filter((ele) => ele.a__alerts.survival_rate.isAlert),
      count: tableRows.filter((ele) => ele.a__alerts.survival_rate.isAlert).length,
    },
    salinity: {
      ponds: tableRows.filter((ele) => ele.a__alerts.salinity.isAlert),
      count: tableRows.filter((ele) => ele.a__alerts.salinity.isAlert).length,
    },
    turbidity: {
      ponds: tableRows.filter((ele) => ele.a__alerts.turbidity.isAlert),
      count: tableRows.filter((ele) => ele.a__alerts.turbidity.isAlert).length,
    },
    dissolved_oxygen: {
      ponds: tableRows.filter((ele) => ele.a__alerts.dissolved_oxygen.isAlert),
      count: tableRows.filter((ele) => ele.a__alerts.dissolved_oxygen.isAlert).length,
    },
    water_temp: {
      ponds: tableRows.filter((ele) => ele.a__alerts.water_temp.isAlert),
      count: tableRows.filter((ele) => ele.a__alerts.water_temp.isAlert).length,
    },
    water_exchange: {
      ponds: tableRows.filter((ele) => ele.a__alerts.water_exchange.isAlert),
      count: tableRows.filter((ele) => ele.a__alerts.water_exchange.isAlert).length,
    },
    nitrate: {
      ponds: tableRows.filter((ele) => ele.a__alerts.nitrate.isAlert),
      count: tableRows.filter((ele) => ele.a__alerts.nitrate.isAlert).length,
    },
    nitrite: {
      ponds: tableRows.filter((ele) => ele.a__alerts.nitrite.isAlert),
      count: tableRows.filter((ele) => ele.a__alerts.nitrite.isAlert).length,
    },
    average_depth: {
      ponds: tableRows.filter((ele) => ele.a__alerts.average_depth.isAlert),
      count: tableRows.filter((ele) => ele.a__alerts.average_depth.isAlert).length,
    },
    alkalinity: {
      ponds: tableRows.filter((ele) => ele.a__alerts.alkalinity.isAlert),
      count: tableRows.filter((ele) => ele.a__alerts.alkalinity.isAlert).length,
    },
  };

  const fullList = [
    [getText("fields.ph"), alertAggregated.ph.count],
    [getText("fields.salinity"), alertAggregated.salinity.count],
    [getText("fields.survival_rate"), alertAggregated.survival_rate.count],
    [getText("fields.dissolved_oxygen"), alertAggregated.dissolved_oxygen.count],
    [getText("fields.turbidity"), alertAggregated.turbidity.count],
    [getText("fields.water_temp"), alertAggregated.water_temp.count],
    [getText("fields.water_exchange"), alertAggregated.water_exchange.count],
    [getText("fields.nitrite"), alertAggregated.nitrite.count],
    [getText("fields.average_depth"), alertAggregated.average_depth.count],
    [getText("fields.alkalinity"), alertAggregated.alkalinity.count],
  ];

  const alertList = fullList.filter((ele) => ele[1] > 0);
  const normalList = fullList.filter((ele) => ele[1] === 0);

  return (
    <Box>
      <Stack direction="row" spacing={1}>
        <OutlinedTag text={`${getText("interface.general.total-alerts")}: ${totalAlerts} `} />
      </Stack>
      <Stack mt={2} maxHeight={300} overflow={"auto"}>
        {alertList.map((ele) => {
          return <AlertRow key={ele[0]} label={ele[0]} count={ele[1]} />;
        })}
        {normalList.map((ele) => {
          return <AlertRow key={ele[0]} label={ele[0]} count={ele[1]} />;
        })}
      </Stack>
    </Box>
  );
};

export default FarmAlertsWidget;
