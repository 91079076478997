import { Box, Button, Menu, MenuItem } from "@mui/material";
import { LanguageIcon } from "components/Icons/MaterialIcons";
import React, { useState } from "react";
import i18next from "i18next";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import PlatformLanguages from "constants/PlatformLanguages";

const LanugagesIconSelect = ({ i18n }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const selectedLanguageName = _.find(PlatformLanguages, {
    code: selectedLanguage,
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const Actions = {
    onChangeLanguage: (lng) => {
      setSelectedLanguage(lng);
      i18next.changeLanguage(lng);
    },
  };

  return (
    <Box>
      <Button onClick={handleClick} size="small" aria-controls={open ? "account-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined} startIcon={<LanguageIcon />}>
        {selectedLanguageName?.nativeName}
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {PlatformLanguages.map(({ name, code, nativeName }, i) => (
          <MenuItem value={code} key={i} onClick={() => Actions.onChangeLanguage(code)}>
            {`${nativeName}`}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default withTranslation()(LanugagesIconSelect);
