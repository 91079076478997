import { Box, Stack, Typography, colors } from "@mui/material";
import { VerticalAlertBoxCollections } from "components/Alerts/AlertBox";
import LoadingBox from "components/Loading/LoadingBox";
import Text, { getText } from "components/text/Text";
import NumericFormatters from "helpers/NumericFormatters";
import { useEffect, useState } from "react";
import { AppSelectors } from "redux/AppReducers";
import { cycleManagementHelpers } from "redux/pondManagement/cycleManagement";
import { fetchPondFarmingProductData } from "screens/Aquaculture/components/Dashboard/utils/ farmingProductHelpers";
import FarmingProductTimeseriesChart from "screens/Aquaculture/components/DataManager/components/FarmingProductTimeseriesChart";
import FarmingProductUsageChart from "screens/Aquaculture/components/DataManager/components/FarmingProductUsageChart";
import WidgetCard from "ui/Card/WidgetCard";

const PondAdditiveDashboard = ({ farm, pond }) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  const cycleStore = AppSelectors.cycleStore();
  const selectedCycle = cycleManagementHelpers.getCurrentCycle({ pondId: pond?.id, cycle: cycleStore.selectedCycle, cycles: cycleStore.cycles });

  const pondManagementStore = AppSelectors.pondManagementStore();
  const { registeredProducts } = pondManagementStore;

  useEffect(() => {
    if (!_.isEmpty(selectedCycle) && selectedCycle.pond_id === pond.id) {
      Actions.fetchData();
    }
  }, [farm, pond, selectedCycle]);

  const Actions = {
    fetchData: async () => {
      const response = await fetchPondFarmingProductData({
        pondid: pond?.id,
        cycle: selectedCycle,
        productIdField: "additive_product",
        productUsageField: "additive_product_usage",
        registeredProducts: registeredProducts,
      });
      setData(response);
    },
  };

  if (_.isEmpty(selectedCycle)) {
    return <VerticalAlertBoxCollections.NoCycleSelected pondId={pond?.id} />;
  }

  if (loading) {
    return <LoadingBox />;
  }

  const stats = {
    totalCosts: _.sumBy(data?.aggregated, (ele) => ele?.stats?.totalCost || 0),
  };

  return (
    <Box p={2}>
      <Stack spacing={1}>
        <WidgetCard title={<Text>interface.general.summary</Text>}>
          <Stack direction={"row"} spacing={3}>
            <Typography fontSize={13} fontWeight={800}>
              <span style={{ color: colors.grey[500] }}>{getText("interface.general.total-cost")} </span> {NumericFormatters.formatDollar({ value: stats.totalCosts })}
            </Typography>
          </Stack>
        </WidgetCard>
        <WidgetCard>
          <FarmingProductTimeseriesChart data={data?.aggregated || []} cycle={selectedCycle} />
        </WidgetCard>
        <WidgetCard>
          <FarmingProductUsageChart data={data?.aggregated || []} cycle={selectedCycle} hidePieChart />
        </WidgetCard>
      </Stack>
    </Box>
  );
};

export default PondAdditiveDashboard;
