import React from "react";

import {
  BarLoader,
  BeatLoader,
  BounceLoader,
  CircleLoader,
  ClimbingBoxLoader,
  ClipLoader,
  DotLoader,
  FadeLoader,
  GridLoader,
  HashLoader,
  MoonLoader,
  PacmanLoader,
  PropagateLoader,
  PulseLoader,
  RingLoader,
  RiseLoader,
  RotateLoader,
  ScaleLoader,
  SyncLoader,
} from "react-spinners";

export function LoadingBar(type, text, color) {
  // ---- type ----
  // - BarLoader
  // - BeatLoader
  // - BounceLoader
  // - CircleLoader
  // - ClimbingBoxLoader
  // - ClipLoader
  // - ClockLoader
  // - DotLoader
  // - FadeLoader
  // - GridLoader
  // - HashLoader
  // - MoonLoader
  // - PacmanLoader
  // - PropagateLoader
  // - PulseLoader
  // - RingLoader
  // - RiseLoader
  // - RotateLoader
  // - ScaleLoader (DEFAULT)
  // - SyncLoader

  type = type || "HashLoader";
  text = text === "" ? text : text || "Loading...";
  color = color || "#999";

  const spinnerFactory = {
    BarLoader: <BarLoader color={color} />,
    BeatLoader: <BeatLoader color={color} />,
    BounceLoader: <BounceLoader color={color} />,
    CircleLoader: <CircleLoader color={color} />,
    ClimbingBoxLoader: <ClimbingBoxLoader color={color} />,
    ClipLoader: <ClipLoader color={color} />,
    DotLoader: <DotLoader color={color} />,
    FadeLoader: <FadeLoader color={color} />,
    GridLoader: <GridLoader color={color} />,
    HashLoader: <HashLoader color={color} />,
    MoonLoader: <MoonLoader color={color} />,
    PacmanLoader: <PacmanLoader color={color} />,
    PropagateLoader: <PropagateLoader color={color} />,
    PulseLoader: <PulseLoader color={color} />,
    RingLoader: <RingLoader color={color} />,
    RiseLoader: <RiseLoader color={color} />,
    RotateLoader: <RotateLoader color={color} />,
    ScaleLoader: <ScaleLoader color={color} />,
    SyncLoader: <SyncLoader color={color} />,
  };

  return (
    <div
      style={{
        padding: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          fontFamily: "var(--main-font)",
          fontSize: "1.2rem",
          color: color,
          margin: "10px 0",
          textTransform: "uppercase",
        }}
      >
        {text}
      </div>
      {spinnerFactory[type]}
    </div>
  );
}

const MyLoader = ({ type, text, color }) => {
  // ---- type ----
  // - BarLoader
  // - BeatLoader
  // - BounceLoader
  // - CircleLoader
  // - ClimbingBoxLoader
  // - ClipLoader
  // - ClockLoader
  // - DotLoader
  // - FadeLoader
  // - GridLoader
  // - HashLoader
  // - MoonLoader
  // - PacmanLoader
  // - PropagateLoader
  // - PulseLoader
  // - RingLoader
  // - RiseLoader
  // - RotateLoader
  // - ScaleLoader (DEFAULT)
  // - SyncLoader

  type = type || "ScaleLoader";
  text = text === "" ? text : text || "Loading...";
  color = color || "#999";

  const spinnerFactory = {
    BarLoader: <BarLoader color={color} />,
    BeatLoader: <BeatLoader color={color} />,
    BounceLoader: <BounceLoader color={color} />,
    CircleLoader: <CircleLoader color={color} />,
    ClimbingBoxLoader: <ClimbingBoxLoader color={color} />,
    ClipLoader: <ClipLoader color={color} />,
    DotLoader: <DotLoader color={color} />,
    FadeLoader: <FadeLoader color={color} />,
    GridLoader: <GridLoader color={color} />,
    HashLoader: <HashLoader color={color} />,
    MoonLoader: <MoonLoader color={color} />,
    PacmanLoader: <PacmanLoader color={color} />,
    PropagateLoader: <PropagateLoader color={color} />,
    PulseLoader: <PulseLoader color={color} />,
    RingLoader: <RingLoader color={color} />,
    RiseLoader: <RiseLoader color={color} />,
    RotateLoader: <RotateLoader color={color} />,
    ScaleLoader: <ScaleLoader color={color} />,
    SyncLoader: <SyncLoader color={color} />,
  };

  return (
    <div
      style={{
        padding: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          fontFamily: "var(--main-font)",
          fontSize: "1.2rem",
          color: color,
          margin: "10px 0",
          textTransform: "uppercase",
        }}
      >
        {text}
      </div>
      {spinnerFactory[type]}
    </div>
  );
};

export default MyLoader;
