import { Autocomplete, Box, Stack, TextField, Typography } from "@mui/material";
import CountryFlagIcon from "components/Icons/CountryFlagIcon";
import Text from "components/text/Text";
import Countries from "constants/Countries";

const CountriesAutocomplete = ({ id, name, onNewValue, ...props }) => {
  return (
    <Autocomplete
      id={id}
      name={name}
      options={Countries.map((e) => ({
        label: e.label,
        value: e.code,
      }))}
      onChange={(e, nVal) => {
        if (onNewValue) {
          onNewValue(nVal?.value);
        }
      }}
      renderInput={(params) => <TextField {...params} label={<Text>interface.account.country</Text>} variant="standard" InputLabelProps={{ shrink: true }} size="small" />}
      renderOption={(props, option) => (
        <Stack direction={"row"} alignItems={"center"} spacing={1} {...props}>
          <Box>
            <CountryFlagIcon countryCode={option.value} width={20} height={16} />
          </Box>
          <Typography>{option.label}</Typography>
        </Stack>
      )}
      {...props}
    />
  );
};

export default CountriesAutocomplete;
