import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AquaExplorerAdminAoiManager from "screens/Aquaculture/AquaExplorer/components/Admin/AquaExplorerAdminAoiManager";
import AquaExplorerAdminHome from "screens/Aquaculture/AquaExplorer/components/Admin/AquaExplorerAdminHome";
import MuiTabs from "ui/tabs/MuiTabs";

const AquaExplorerAdmin = () => {
  const [pageIndex, setPageIndex] = useState(0);

  const Actions = {};

  useEffect(() => {}, []);

  return (
    <Box>
      <Box p={1}>
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <Box>
            <Typography fontWeight={800} fontSize={15}>
              Aqua Explorer Admin
            </Typography>
          </Box>
          <Box>
            <MuiTabs
              value={pageIndex}
              onNewValue={setPageIndex}
              options={[
                { label: "Home", value: 0 },
                { label: "AOI Manager", value: 1 },
              ]}
            />
          </Box>
        </Stack>
      </Box>
      <Box>
        {pageIndex === 0 && <AquaExplorerAdminHome />}
        {pageIndex === 1 && <AquaExplorerAdminAoiManager />}
      </Box>
    </Box>
  );
};

export default AquaExplorerAdmin;
