import { Box, Card, IconButton, InputAdornment, Stack, TextField, Typography, colors } from "@mui/material";
import { CloseIcon, EditNoteIcon } from "components/Icons/MaterialIcons";
import { getText } from "components/text/Text";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { AppSelectors } from "redux/AppReducers";
import { smartSearchActions } from "redux/smartSearch";
import i18next from "i18next";
import { VerticalAlertBox, VerticalAlertBoxCollections } from "components/Alerts/AlertBox";
import ResultRow, { ResultTypes } from "components/GlobalSearch/components/ResultRow";
import CountryFlagIcon from "components/Icons/CountryFlagIcon";

const __TEST__ = false;
const __DEFAULT_TERM__ = __TEST__ ? "Demo" : null;

const ResultRenderer = ({ type, title, subtitle, link, payload }) => {
  if (type === ResultTypes.farm.value) {
    const icon = <CountryFlagIcon countryCode={payload.farm.country_code} width={"20px"} height="16px" />;
    return <ResultRow {...{ type, title, subtitle, link, icon }} />;
  }
  if (type === ResultTypes.app.value) {
    return <ResultRow {...{ type, title, subtitle, link }} />;
  }
};

const GlobalSearch = () => {
  const pondMangementStore = AppSelectors.pondManagementStore();
  const { farms } = pondMangementStore;

  const [term, setTerm] = useState(__DEFAULT_TERM__);
  const [showPopup, setShowPopup] = useState(false);
  const dispatch = useDispatch();
  const inputRef = useRef();

  function useKey(key, ref) {
    useEffect(() => {
      function hotkeyPress(e) {
        if (e.keyCode === key) {
          e.preventDefault();
          ref.current.focus();
          return;
        }
      }
      document.addEventListener("keydown", hotkeyPress);
      return () => document.removeEventListener("keydown", hotkeyPress);
    }, [key]);
  }

  useKey(192, inputRef);

  const smartSearchStore = AppSelectors.smartSearchStore();
  const smartSearchResultList = smartSearchStore.resultList;

  useEffect(() => {
    const initData = {
      farms: farms,
    };
    smartSearchActions.init(dispatch, initData);
  }, [farms]);

  const Actions = {
    onTermChange: (newTerm) => {
      if (newTerm) {
        let command = newTerm[0] === "/" && newTerm[1] !== " " ? newTerm.split(" ")[0].split("/")[1] : null;
      }
      setTerm(newTerm);
    },
  };

  const groupedResult = _.mapValues(_.groupBy(smartSearchResultList, "type"), (subList) => {
    return _.take(
      subList.filter(({ type, title, subtitle, payload }) => {
        if (term) {
          const matched = title.toLowerCase().indexOf(term.toLowerCase()) !== -1 || type.toLowerCase().indexOf(term.toLowerCase()) !== -1;
          return matched;
        } else {
          return false;
        }
      }),
      10
    );
  });

  const hasResult = _.flatten(_.values(groupedResult)).length > 0;

  const noTerm = !term || term === "";

  const placeholder = getText("interface.actions.search");

  return (
    <Box
      sx={{
        zIndex: 99999,
        position: "relative",
      }}
      key={i18next.language}
    >
      <TextField
        placeholder={placeholder}
        sx={{
          width: {
            xs: 100,
            md: 400,
            lg: 600,
          },
          ".MuiInputBase-root": {
            bgcolor: colors.grey[300],
            borderRadius: 10,
          },
          ".Mui-focused": {
            bgcolor: "#FFF",
          },
          input: {
            py: 0.8,
          },
        }}
        variant="outlined"
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FaSearch />
            </InputAdornment>
          ),
        }}
        onChange={(e) => Actions.onTermChange(e.target.value)}
        value={term}
        inputRef={inputRef}
        onFocus={() => setShowPopup(true)}
        onBlur={() => setShowPopup(false)}
      />
      {(showPopup || term || __TEST__) && (
        <Box
          sx={{
            position: "absolute",
            top: 50,
            left: 0,
            width: "100%",
          }}
        >
          <Stack direction={"row"} justifyContent={"center"}>
            <Card
              sx={{
                minWidth: "500px",
                width: "50vw",
                maxHeight: "50vh",
                overflow: "auto",
                border: "1px solid #CCC",
              }}
            >
              <Stack direction="row" justifyContent={"space-between"} alignItems={"center"} p={1}>
                <Typography variant="h6" color="primary"></Typography>
                <IconButton onClick={() => setTerm("")}>
                  <CloseIcon />
                </IconButton>
              </Stack>
              {noTerm ? (
                <Box mb={8}>
                  <VerticalAlertBox icon={<EditNoteIcon />} title={getText("interface.actions.enter-keywords")} />
                </Box>
              ) : !hasResult ? (
                <Box mb={8}>
                  <VerticalAlertBoxCollections.NoResultFor word={term || ""} />
                </Box>
              ) : (
                _.keys(groupedResult).map(
                  (k) =>
                    groupedResult[k].length > 0 && (
                      <Box borderTop={"1px solid #ccc"} py={1}>
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} px={1}>
                          <Typography fontSize={12} textTransform={"uppercase"} fontWeight={500} letterSpacing={1}>
                            {k}
                          </Typography>
                          <Typography fontSize={11} color={colors.grey[700]}>
                            {groupedResult[k].length} matched
                          </Typography>
                        </Stack>
                        <Stack mt={1}>
                          {groupedResult[k].map((item, idx) => (
                            <ResultRenderer {...item} />
                          ))}
                        </Stack>
                      </Box>
                    )
                )
              )}
            </Card>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default GlobalSearch;
