import { Box } from "@mui/material";
import React from "react";

const CountryFlagIcon = ({ countryCode, width, height }) => {
  if (!countryCode) return "";
  return (
    <Box
      sx={{
        minWidth: width || "30px",
        minHeight: height || "20px",
        border: "1px solid #ccc",
        backgroundImage: `url(http://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode.toUpperCase()}.svg)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        marginRight: "5px",
      }}
    ></Box>
  );
};

export default CountryFlagIcon;
