import { Box, Stack, Typography, colors } from "@mui/material";
import { ArrowForwardIcon, NavigateNextIcon } from "components/Icons/MaterialIcons";
import Text from "components/text/Text";

export const ResultTypes = {
  farm: {
    value: "farm",
    display: <Text>interface.general.farm</Text>,
    color: colors.green[600],
  },
  app: {
    value: "app",
    display: "App",
    color: colors.blue[600],
  },
};

const ResultRow = (item) => {
  return (
    <Box
      sx={{
        px: 1,
        py: 0.8,
        "&:hover": {
          cursor: "pointer",
          background: colors.grey[100],
          ".rhs": {
            visibility: "visible",
          },
        },
      }}
      onClick={() => {
        if (item.link) {
          window.location.href = item.link;
        }
      }}
    >
      <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>
        <Box>
          <Stack direction={"row"} spacing={0.7} alignItems={"center"}>
            <Box
              sx={{
                px: 0.4,
                borderRadius: 0.3,
                backgroundColor: ResultTypes?.[item.type]?.color || colors.grey[500],
              }}
            >
              <Typography
                fontWeight={"bold"}
                sx={{
                  fontSize: 10,
                  color: "#fff",
                }}
                textTransform={"uppercase"}
              >
                {item.type}
              </Typography>
            </Box>
            {item.icon && <Box>{item.icon}</Box>}
            <Typography fontWeight={"bold"} fontSize={12}>
              {item.title}
            </Typography>
          </Stack>
        </Box>
        {item.link && (
          <Box
            className="rhs"
            sx={{
              visibility: "hidden",
            }}
          >
            <Stack direction="row" spacing={1} alignItems={"center"}>
              <Typography variant="caption">{item.subtitle}</Typography>
              <ArrowForwardIcon />
            </Stack>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default ResultRow;
