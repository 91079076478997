import {
  Box,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { theme } from "App";
import mapboxgl from "mapbox-gl";
import React, { useState } from "react";
import ReactMapboxGl, { Layer, Source, ZoomControl } from "react-mapbox-gl";
import { MAPBOX_TOKEN } from "secrets";

const Map = ReactMapboxGl({
  accessToken: MAPBOX_TOKEN,
});

const demoPendingFarms = [
  {
    farm_id: 1,
    label: "ML_generated_#31222302",
    total_area: 234,
    country: "Maxico",
    country_code: "mx",
  },
  {
    farm_id: 2,
    label: "ML_generated_#41231234",
    total_area: 124,
    country: "Maxico",
    country_code: "mx",
  },
  {
    farm_id: 3,
    label: "ML_generated_#53242341",
    total_area: 32,
    country: "Maxico",
    country_code: "mx",
  },
];

const PondConfirmationPanel = ({ farm }) => {
  return (
    <Box>
      <Box>
        <Typography variant="h6">
          <strong>{farm.label}</strong>
        </Typography>
        <Typography variant="body2">#{farm.farm_id}</Typography>
        <Typography variant="body2">{farm.total_area} Ha.</Typography>
      </Box>
      <Box>
        <Map />
      </Box>
    </Box>
  );
};

/**
 *
 *
 *
 * @returns
 */
const PondConfirmation = () => {
  const [selectedFarm, setFarm] = useState(null);

  const pendingFarms = demoPendingFarms;

  return (
    <Box p={2}>
      <Typography variant="h4" my={2}>
        Pond Confirmation
      </Typography>
      <Box height={"80vh"}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={3}>
            <Card>
              <CardContent>
                <Typography variant="h6">Pending Farms</Typography>
                <Box>
                  <List>
                    {pendingFarms.map((ele, idx) => (
                      <ListItemButton
                        sx={{
                          borderLeft:
                            selectedFarm?.farm_id === ele.farm_id
                              ? `5px solid ${theme.palette.primary.main}`
                              : "5px solid transparent",
                          "&:hover": {
                            borderLeft: "5px solid #aaa",
                          },
                        }}
                        size="small"
                        key={idx}
                        onClick={() => setFarm(ele)}
                      >
                        <ListItemText
                          primary={ele.label}
                          secondary={`${ele.farm_id} / ${ele.total_area}ha. / ${ele.country}`}
                        />
                      </ListItemButton>
                    ))}
                  </List>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={8} lg={9}>
            <Card>
              <CardContent>
                {!selectedFarm ? (
                  <Typography variant="h4">Select a Farm</Typography>
                ) : (
                  <PondConfirmationPanel farm={selectedFarm} />
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PondConfirmation;
