import React from "react";
import styled from "styled-components";
import { NavLink, useNavigate } from "react-router-dom";
import { Box, Divider, IconButton, ListItemIcon, Menu, MenuItem, Stack, Typography, colors } from "@mui/material";
import { AccountCircleIcon, AppsIcon, LogoutIcon, PersonIcon } from "components/Icons/MaterialIcons";
import { Link } from "react-router-dom";
import { AppSelectors } from "redux/AppReducers";
import { ROUTE_USER_PROFILE } from "routes/paths";
import GlobalSearch from "components/GlobalSearch/GlobalSearch";
import Text from "components/text/Text";
import { signout } from "auth/UserAuth";
import { isMobile } from "themes/helpers";

const mobileMode = isMobile();

// CSS
const Layout = styled.div`
  grid-area: navbar;

  a {
    color: inherit;
  }

  border-bottom: 1px solid #cecece;

  font-family: var(--main-font);
  display: flex;
  justify-content: space-between;

  align-items: center;
  z-index: 999;

  .NavBarLHS {
    height: 100%;
    display: flex;
  }

  .navBar-logo-area {
    display: flex;
    align-items: center;
    position: absolute;
    right: 40px;
  }
`;

const NavButton = styled.div`
  height: 100%;
  box-sizing: border-box;
  & > a {
    transition: all 0.1s;
    font-size: 1.2rem;
    color: var(--main-color);
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 4px 8px;
    border-radius: 10px;
  }
  svg {
    font-size: 1.2rem;
  }
`;

const NavBarButton = (props) => {
  return (
    <NavButton>
      <NavLink to={props.linkto}>{props.name}</NavLink>
    </NavButton>
  );
};

const NavBar = ({ title }) => {
  const userStore = AppSelectors.userStore();
  const { firstname } = userStore;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();

  const Actions = {
    handleClick: (event) => setAnchorEl(event.currentTarget),
    handleClose: () => setAnchorEl(null),
    onClickLink: (link) => navigate(link),
  };

  return (
    <Layout>
      <Stack direction={"row"} alignItems="ceter" px={mobileMode ? 1 : 2}>
        <Stack direction="row" spacing={1} alignItems="center">
          <NavBarButton
            name={
              <Stack
                sx={{
                  p: 1,
                  borderRadius: 1,
                  transition: "all 0.1s",
                  "&:hover": {
                    backgroundColor: "#CCC",
                  },
                }}
                direction="row"
                alignItems={"center"}
                spacing={0.5}
              >
                <AppsIcon />
              </Stack>
            }
            linkto={`/${window.location.pathname.split("/")[1]}`}
          />
          <Box>
            <Typography textTransform="uppercase" letterSpacing="2px">
              {title}
            </Typography>
          </Box>
        </Stack>
      </Stack>
      <Stack
        display={{
          xs: "none",
          md: "block",
        }}
      >
        <GlobalSearch />
      </Stack>
      <Stack direction="row" spacing={1} alignItems="center" px={2}>
        <IconButton color="primary" onClick={Actions.handleClick}>
          <AccountCircleIcon />
        </IconButton>
      </Stack>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={Actions.handleClose}
        onClick={Actions.handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        slotProps={{
          paper: {
            sx: {
              width: 200,
            },
          },
        }}
      >
        <Box p={2}>
          <Typography fontWeight={"bold"} color={colors.grey[700]}>
            Hi, {firstname}!
          </Typography>
        </Box>
        <MenuItem onClick={() => Actions.onClickLink(ROUTE_USER_PROFILE)}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <Text>interface.general.profile</Text>
        </MenuItem>
        <Divider />
        <MenuItem onClick={signout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </Layout>
  );
};

export default NavBar;
