export const ResourcePolicies = {
  /** Admin */
  ADMIN: { code: "ADMIN", label: "Admin", description: "Grant member FULL ACCESS to this farm" },
  ACCESSCONTROL: { code: "ACCESSCONTROL", label: "Access Control", description: "Grant member permission to manage access" },
  FARMINGPRODUCTS: { code: "FARMINGPRODUCTS", label: "Farming Products", description: "Grant member permission to manage farming products" },
  SETTINGS: { code: "SETTINGS", label: "Settings", description: "Grant member permission to manage Farm Settings" },
  ORGCONNECT: { code: "ORGCONNECT", label: "OrgConnect", description: "Grant member permission to manage OrgConnect" },

  /** Data */
  "DATA:FULL": { code: "DATA:FULL", label: "Data Full Access", description: "Grant member FULL ACCESS permission to Data" },
  "DATA:BIOLOGICAL:READ": { code: "DATA:BIOLOGICAL:READ", label: "Biological (READ)", description: "Grant member permission to read Biological Data" },
  "DATA:BIOLOGICAL:WRITE": { code: "DATA:BIOLOGICAL:WRITE", label: "Biological (WRITE)", description: "Grant member permission to upload or modify Biological Data" },
  "DATA:WATER_QUALITY:READ": { code: "DATA:WATER_QUALITY:READ", label: "Water Quality (READ)", description: "Grant member permission to read Water Quality Data" },
  "DATA:WATER_QUALITY:WRITE": { code: "DATA:WATER_QUALITY:WRITE", label: "Water Quality (WRITE)", description: "Grant member permission to upload or modify Water Quality Data" },
  "DATA:FINANCE:READ": { code: "DATA:FINANCE:READ", label: "Fianance (READ)", description: "Grant member permission to read Fianance Data" },
  "DATA:FINANCE:WRITE": { code: "DATA:FINANCE:WRITE", label: "Fianance (WRITE)", description: "Grant member permission to upload or modify Fianance Data" },
  "DATA:FARMING_PRODUCT:READ": { code: "DATA:FARMING_PRODUCT:READ", label: "Farming Products (READ)", description: "Grant member permission to view Farming Products Data" },
  "DATA:FARMING_PRODUCT:WRITE": { code: "DATA:FARMING_PRODUCT:WRITE", label: "Farming Products (WRITE)", description: "Grant member permission to upload or modify Farming Products Data" },
  "DATA:DISEASES:READ": { code: "DATA:DISEASES:READ", label: "Diseases (READ)", description: "Grant member permission to view Disease Data" },
  "DATA:DISEASES:WRITE": { code: "DATA:DISEASES:WRITE", label: "Diseases (WRITE)", description: "Grant member permission to upload or modify Disease Data" },
  "DATA:HARVEST:READ": { code: "DATA:HARVEST:READ", label: "Harvest (READ)", description: "Grant member permission to view Harvest Data" },
  "DATA:HARVEST:WRITE": { code: "DATA:HARVEST:WRITE", label: "Harvest (WRITE)", description: "Grant member permission to upload or modify Harvest Data" },
  "DATA:BULKUPLOAD": { code: "DATA:BULKUPLOAD", label: "Bulk Upload", description: "Grant member permission to Bulk Upload in Data Manager" },

  /** Dashboard */
  "DASHBOARD:FULL": { code: "DASHBOARD:FULL", label: "Full Access", description: "Grant member FULL ACCESS permission to Dashboard" },
  "DASHBOARD:SUMMARY": { code: "DASHBOARD:SUMMARY", label: "Summary", description: "Grant member permission to view Summary Dashboard" },
  "DASHBOARD:FORECAST": { code: "DASHBOARD:FORECAST", label: "Forecast", description: "Grant member permission to view Forecast Dashboard" },
  "DASHBOARD:BIOLOGICAL": { code: "DASHBOARD:BIOLOGICAL", label: "Biological", description: "Grant member permission to view Biological Dashboard" },
  "DASHBOARD:WATERQUALITY": { code: "DASHBOARD:WATERQUALITY", label: "Water Quality", description: "Grant member permission to view Water Quality Dashboard" },
  "DASHBOARD:FINANCE": { code: "DASHBOARD:FINANCE", label: "Finance", description: "Grant member permission to view Finance Dashboard" },
  "DASHBOARD:SATEYE": { code: "DASHBOARD:SATEYE", label: "SatEYE", description: "Grant member permission to view SatEYE Dashboard" },
  "DASHBOARD:MONITOR": { code: "DASHBOARD:MONITOR", label: "Monitor", description: "Grant member permission to view Monitor Dashboard" },
  "DASHBOARD:FARMING_PRODUCT": { code: "DASHBOARD:FARMING_PRODUCT", label: "Farming Product", description: "Grant member permission to view Farming Products Dashboard" },

  /** Cycle */
  "CYCLE:READ": { code: "CYCLE:READ", label: "Cycle (READ)", description: "Grant member permission to read Cycles" },
  "CYCLE:WRITE": { code: "CYCLE:WRITE", label: "Cycle (WRITE)", description: "Grant member permission to modify Cycles" },

  /** Report */

  "REPORT:BIOLOGICAL": { code: "REPORT:BIOLOGICAL", label: "Biological Report", description: "Grant member permission to view Biological Reports" },
  "REPORT:FINANCE": { code: "REPORT:FINANCE", label: "Finance Report", description: "Grant member permission to view Finance Reports" },
  "REPORT:SRS": { code: "REPORT:SRS", label: "SRS Report", description: "Grant member permission to view SRS Reports" },

  /** Document */
  "DOCUMENT:READ": { code: "DOCUMENT:READ", label: "Document (READ)", description: "Grant member permission to view Documents" },
  "DOCUMENT:WRITE": { code: "DOCUMENT:WRITE", label: "Document (WRITE)", description: "Grant member permission to upload Documents" },
  "PHOTO-LOGS:READ": { code: "PHOTO-LOGS:READ", label: "Photo Logs (READ)", description: "Grant member permission to view Photo Logs" },
  "PHOTO-LOGS:WRITE": { code: "PHOTO-LOGS:WRITE", label: "Photo Logs (WRITE)", description: "Grant member permission to upload Photo Logs" },

  /** Scenario Builder */
  SCENARIOBUILDER: { code: "SCENARIOBUILDER", label: "Scenario Builder", description: "Grant member permission to Forecast" },

  /** Forecast */
  FORECAST: { code: "FORECAST", label: "Forecast", description: "Grant member permission to Forecast" },

  /** SatEYE */
  SATEYE: { code: "SATEYE", label: "SatEYE", description: "Grant member permission to view SatEYE" },
};

export const displayedPolices = [
  {
    group: "Admin",
    description: "Permissions at Admin Level",
    groupK: "admin",
    descriptionK: "admin-desc",
    policies: [ResourcePolicies["ADMIN"], ResourcePolicies["ACCESSCONTROL"], ResourcePolicies["SETTINGS"], ResourcePolicies["FARMINGPRODUCTS"]],
  },
  {
    group: "Data",
    description: "Permissions to Data",
    groupK: "data",
    descriptionK: "data-desc",
    policies: [
      ResourcePolicies["DATA:FULL"],
      ResourcePolicies["DATA:BIOLOGICAL:READ"],
      ResourcePolicies["DATA:BIOLOGICAL:WRITE"],
      ResourcePolicies["DATA:FINANCE:READ"],
      ResourcePolicies["DATA:FINANCE:WRITE"],
      ResourcePolicies["DATA:WATER_QUALITY:READ"],
      ResourcePolicies["DATA:WATER_QUALITY:WRITE"],
      ResourcePolicies["DATA:FARMING_PRODUCT:READ"],
      ResourcePolicies["DATA:FARMING_PRODUCT:WRITE"],
      ResourcePolicies["DATA:DISEASES:READ"],
      ResourcePolicies["DATA:DISEASES:WRITE"],
      ResourcePolicies["DATA:HARVEST:READ"],
      ResourcePolicies["DATA:HARVEST:WRITE"],
      ResourcePolicies["DATA:BULKUPLOAD"],
    ],
  },
  {
    group: "Dashboard",
    description: "Permissions to Dashboard",
    groupK: "dashboard",
    descriptionK: "dashboard-desc",
    policies: [
      ResourcePolicies["DASHBOARD:FULL"],
      ResourcePolicies["DASHBOARD:SUMMARY"],
      ResourcePolicies["DASHBOARD:BIOLOGICAL"],
      ResourcePolicies["DASHBOARD:WATERQUALITY"],
      ResourcePolicies["DASHBOARD:FINANCE"],
      ResourcePolicies["DASHBOARD:SATEYE"],
      ResourcePolicies["DASHBOARD:FORECAST"],
      ResourcePolicies["DASHBOARD:MONITOR"],
      ResourcePolicies["DASHBOARD:FARMING_PRODUCT"],
    ],
  },
  {
    group: "Cycle",
    description: "Permissions to Cycle",
    groupK: "cycle",
    descriptionK: "cycle-desc",
    policies: [ResourcePolicies["CYCLE:READ"], ResourcePolicies["CYCLE:WRITE"]],
  },
  {
    group: "Scenario Builder",
    description: "Permissions to Scenario Builder",
    groupK: "scenariobuilder",
    descriptionK: "scenariobuilder-desc",
    policies: [ResourcePolicies["SCENARIOBUILDER"]],
  },
  {
    group: "Report",
    description: "Permissions to Report",
    groupK: "report",
    descriptionK: "report-desc",
    policies: [ResourcePolicies["REPORT:BIOLOGICAL"], ResourcePolicies["REPORT:FINANCE"], ResourcePolicies["REPORT:SRS"]],
  },
  {
    group: "Forecast",
    description: "Permission to Forecast",
    groupK: "forecast",
    descriptionK: "forecast-desc",
    policies: [ResourcePolicies.FORECAST],
  },
  {
    group: "Document",
    description: "Permission to Document",
    groupK: "document",
    descriptionK: "document-desc",
    policies: [ResourcePolicies["DOCUMENT:READ"], ResourcePolicies["DOCUMENT:WRITE"], ResourcePolicies["PHOTO-LOGS:READ"], ResourcePolicies["PHOTO-LOGS:WRITE"]],
  },
];

export const validateUserPolicies = ({ farm, policies }) => {
  const isAllowed = _.some(policies, (item) =>
    _.includes(
      (farm?.policies || []).map((ele) => ele.code),
      item?.code
    )
  );
  return isAllowed;
};
