import {
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  colors,
} from "@mui/material";
import {
  DeleteIcon,
  DownloadIcon,
  EditNoteIcon,
} from "components/Icons/MaterialIcons";
import { getText } from "components/text/Text";
import React from "react";
import { BsFillFileEarmarkTextFill } from "react-icons/bs";
import palette from "themes/palette";

const DocumentCard = ({ document, onDownload, onDelete, onEdit }) => {
  const { filename, label, note, created_at, type, id } = document;
  return (
    <Tooltip
      title={
        <Box>
          <Typography fontSize={11} fontWeight={"bold"}>
            {label}
          </Typography>
          <Typography fontSize={10}>{filename}</Typography>
          <Typography fontSize={10}>{note}</Typography>
          <Typography fontSize={10}>{created_at} UTC</Typography>
        </Box>
      }
      placement="top"
    >
      <Box
        sx={{
          p: 3,
          pb: 1,
          borderRadius: 4,
          transition: "all .2s",
          overflow: "hidden",
          ".toolbar": {
            opacity: 0,
            transition: "all .2s",
            transform: "translateY(10px)",
          },
          "&:hover": {
            bgcolor: colors.grey[50],
            ".toolbar": {
              opacity: 1,
              transform: "translateY(0px)",
            },
          },
        }}
      >
        <Stack
          direction={"row"}
          spacing={3}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack alignItems={"center"}>
            <Box
              sx={{
                svg: {
                  fontSize: 80,
                  color: palette.secondary.main,
                },
              }}
            >
              <BsFillFileEarmarkTextFill />
            </Box>
            <Box
              sx={{
                width: 130,
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis ellipsis",
                textDecoration: "none",
                position: "relative",
                display: "inline-block",
              }}
            >
              <Typography fontWeight={800} fontSize={11} textAlign={"center"}>
                {label}
              </Typography>
              <Typography fontSize={10} color="grey" textAlign={"center"}>
                {filename}
              </Typography>
            </Box>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              className="toolbar"
              sx={{
                opacity: 0,
              }}
            >
              <Tooltip
                placement="bottom"
                title={getText("interface.actions.download")}
                onClick={onDownload}
              >
                <IconButton size="small">
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                placement="bottom"
                title={getText("interface.actions.edit")}
              >
                <IconButton size="small" color="info" onClick={onEdit}>
                  <EditNoteIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                placement="bottom"
                title={getText("interface.actions.delete")}
              >
                <IconButton size="small" color="error" onClick={onDelete}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Tooltip>
  );
};

export default DocumentCard;
