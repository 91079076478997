import { getText } from "components/text/Text";
import _ from "lodash";

const PondCategories = [
  { value: "grow-out-pond" },
  { value: "hatchery-pond" },
  { value: "nursery-pond" },
  { value: "treatment-pond" },
  { value: "holding-pond" },
  { value: "quarantine-pond" },
  { value: "inspection-pond" },
  { value: "feed-pond" },
  { value: "purification-pond" },
  { value: "sedimentation-pond" },
  { value: "reservoir-pond" },
  { value: "isolation-pond" },
];

export const PrettifyPondCategory = (catValue) => {
  const item = _.find(PondCategories, { value: catValue });
  if (!item) return "";
  return getText(`interface.general.${item?.value}`, item?.value);
};

export default PondCategories;
