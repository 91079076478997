import { PondManagerServices } from "api/pondManagerServices";
import _ from "lodash";
import moment from "moment";

const initState = {
  diseaseList: [],
  diseaseMap: {},
  labList: [],
  labMap: {},
};

const pathologyManagementTypes = {
  INIT: "INIT",
};

const pathologyManagementReducers = (state = initState, action) => {
  switch (action.type) {
    case pathologyManagementTypes.INIT:
      return {
        ...state,
        diseaseList: action.payload.diseaseList,
        diseaseMap: action.payload.diseaseMap,
        labList: action.payload.labList,
        labMap: action.payload.labMap,
      };
    default:
      return state;
  }
};

export const pathologyManagementActions = {
  initData: async (dispatch) => {
    const responseD = await PondManagerServices.fetchDiseases();
    const responseL = await PondManagerServices.fetchLabs();
    dispatch({
      type: pathologyManagementTypes.INIT,
      payload: {
        diseaseList: responseD.list,
        diseaseMap: responseD.map,
        labList: responseL.list,
        labMap: responseL.map,
      },
    });
  },
};

export default pathologyManagementReducers;
