import { useState, useRef, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import { Box, Stack, TextField } from "@mui/material";
import FarmAccessLevelBadge from "../common/FarmAccessLevelBadge";
import { getText } from "components/text/Text";
import NumberFormat from "react-number-format";
import moment from "moment";
import StyledLink from "ui/Link/StyledLink";
import CountryFlagIcon from "components/Icons/CountryFlagIcon";
import { AppSelectors } from "redux/AppReducers";
import { MuiBorderIconButton } from "components/buttons/MuiButtonVariants";
import { useNavigate } from "react-router-dom";
import { ArrowForwardIcon, SettingsIcon } from "components/Icons/MaterialIcons";
import TimeFormatters from "helpers/TimeFormatters";

const PondManagerHomePageTableView = ({ farms, farmEditPath, editIcon, viewIcon }) => {
  const organisationManagementStore = AppSelectors.orgStore();
  const viewAsOrg = organisationManagementStore.viewAsOrg;

  const [searchTerm, setTerm] = useState(null);

  const gridRef = useRef();

  const navigate = useNavigate();

  // Table Definiation for Individual Account Viewer
  const colsDefForIndividual = [
    {
      pinned: "left",
      width: 100,
      headerName: "",
      cellRenderer: (params) => (
        <Stack direction="row" alignItems={"center"} justifyContent={"center"} spacing={0.5}>
          <MuiBorderIconButton size="sm" icon={<SettingsIcon />} onClick={() => navigate(`${farmEditPath}/${params.data?.farm_id}`)} />
          <MuiBorderIconButton size="sm" icon={<ArrowForwardIcon />} onClick={() => navigate(`${location.pathname}/${params?.data?.farm_id}`)} />
        </Stack>
      ),
      sortable: false,
      filter: false,
      cellStyle: { justifyContent: "center" },
    },
    {
      field: "label",
      headerName: getText("interface.general.farm"),
      cellRenderer: (params) => {
        return <StyledLink to={`${location.pathname}/${params?.data?.farm_id}`}>{params.value}</StyledLink>;
      },
    },
    {
      headerName: getText("interface.account.country"),
      field: "country",
      filter: true,
      cellRenderer: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <CountryFlagIcon countryCode={params?.data?.country_code} width={"20px"} height="15px" />
          {params.value}
        </div>
      ),
    },
    {
      field: "access_level_label",
      headerName: getText("interface.access-control.access-control"),
      cellRenderer: (params) => <FarmAccessLevelBadge accessLevelId={params.data?.access_level_id} />,
    },
    {
      field: "total_area",
      headerName: getText("interface.general.pond-area"),
      cellRenderer: (params) => <NumberFormat value={params.value} decimalScale={1} thousandSeparator="," displayType="text" />,
    },
    {
      field: "total_ponds",
      headerName: getText("interface.general.total-ponds"),
    },
    {
      field: "settings.currency",
      headerName: getText("interface.general.currency"),
      sortable: true,
    },
    {
      field: "created_at",
      headerName: "Created",
      sortable: true,
      cellRenderer: (params) => TimeFormatters.formatDatetime(params.data?.created_at),
    },
  ];

  // Table Definiation for Organsation Account Viewer
  const colsDefForOrg = [
    {
      field: "label",
      headerName: getText("interface.general.farm"),
      cellRenderer: (params) => <StyledLink to={`${location.pathname}/${params?.data?.farm_id}`}>{params.value}</StyledLink>,
    },
    {
      headerName: getText("interface.account.country"),
      field: "country",
      filter: true,
      cellRenderer: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <CountryFlagIcon countryCode={params?.data?.country_code} width={"20px"} height="15px" />
          {params.value}
        </div>
      ),
    },
    {
      field: "total_area",
      headerName: getText("interface.general.pond-area"),
      cellRenderer: (params) => <NumberFormat value={params.value} decimalScale={1} thousandSeparator="," displayType="text" />,
    },
    {
      field: "total_ponds",
      headerName: getText("interface.general.total-ponds"),
    },
    {
      field: "settings.currency",
      headerName: getText("interface.general.currency"),
      sortable: true,
    },
    {
      field: "created",
      headerName: "Time Created",
      sortable: true,
      cellRenderer: (params) => moment(params.data?.created, "YYYY-MM-DD HH:mm:ss").format("YYYY/MM/DD"),
    },
    {
      field: "contact_name",
      headerName: "Contact Name",
      sortable: true,
    },
    {
      field: "contact_number",
      headerName: "Contact Number",
      sortable: true,
    },
    {
      field: "contact_email",
      headerName: "Contact Email",
      sortable: true,
    },
  ];

  const columnDefsInitialState = viewAsOrg?.id ? colsDefForOrg : colsDefForIndividual;

  const [columnDefs, setColumnDefs] = useState(columnDefsInitialState);

  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    cellStyle: { display: "flex", alignItems: "center" },
  }));

  const onFilterTextBoxChanged = (e) => {
    const filterText = e.target.value;
    setTerm(filterText);
  };

  return (
    <div>
      <Box mb={2}>
        <TextField
          sx={{
            width: 300,
          }}
          variant="standard"
          size="small"
          placeholder="Quick Filter..."
          onChange={onFilterTextBoxChanged}
        ></TextField>
      </Box>
      <Box overflow={"hidden"} className="ag-theme-clean" sx={{ width: "100%", height: "calc(100vh - 200px)" }}>
        <AgGridReact rowHeight={30} ref={gridRef} rowData={farms} columnDefs={columnDefs} defaultColDef={defaultColDef} animateRows={false} quickFilterText={searchTerm} />
      </Box>
    </div>
  );
};

export default PondManagerHomePageTableView;
