import { Box, Divider, Stack, Typography } from "@mui/material";
import { theme } from "App";
import AppButton from "components/buttons/AppButton";
import MobileAppButton from "layout/components/MobileAppButton";
import React, { useEffect } from "react";
import { Fade } from "react-reveal";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { isMobile } from "themes/helpers";

const MainBoardHome = ({ title, list, navigateToFirst }) => {
  const { pathname, hash } = useLocation();

  if (list.length === 1 && navigateToFirst) {
    const navigateTo = `${pathname}${_.first(list)?.path}`;
    return <Navigate to={navigateTo} />;
  }

  return (
    <Box
      p={{
        xs: 2,
        md: 2,
      }}
    >
      <Fade key={window.location.pathname} left distance={"0"} cascade duration={300}>
        <Typography
          fontSize={{
            xs: 24,
            md: 40,
          }}
          pl={{
            xs: 0,
            sm: 2,
          }}
          fontWeight="bold"
          color={theme.palette.primary.main}
          gutterBottom
        >
          {title}
        </Typography>
      </Fade>
      {isMobile() ? (
        <Stack direction={"column"} mt={2}>
          {list.map((ele, idx) => (
            <MobileAppButton key={`${pathname}${ele.path}`} name={ele.name} path={`${pathname}${ele.path}`} faClass={ele.faClass} icon={ele.icon} />
          ))}
        </Stack>
      ) : (
        <Stack direction={"row"} mt={2}>
          {list.map((ele, idx) => (
            <AppButton key={`${pathname}${ele.path}`} name={ele.name} path={`${pathname}${ele.path}`} faClass={ele.faClass} icon={ele.icon} navlink />
          ))}
        </Stack>
      )}
    </Box>
  );
};

export default MainBoardHome;
