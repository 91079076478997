import { Box, Container } from "@mui/material";
import PondManagerAccessWrapper from "components/Wrapper/PondManagerAccessWrapper";
import { ResourcePolicies } from "screens/Aquaculture/components/AccessControl/ResourcePolicies";
import FarmImages from "screens/Aquaculture/components/Files/FarmImages";

const FarmImagesSettings = () => {
  return (
    <PondManagerAccessWrapper policies={[ResourcePolicies.SETTINGS]}>
      <Box>
        <Container maxWidth="lg">
          <Box p={2}>
            <FarmImages />
          </Box>
        </Container>
      </Box>
    </PondManagerAccessWrapper>
  );
};

export default FarmImagesSettings;
