import { Box, Grid, Stack, Typography, colors } from "@mui/material";
import HighchartsWrapper from "components/Highcharts/HighchartsWrapper";
import { CircleIcon } from "components/Icons/MaterialIcons";
import OutlinedTag from "components/Tags/OutlinedTag";
import { getText } from "components/text/Text";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { demoSrsData } from "screens/Aquaculture/components/Report/SRSReport";
import palette from "themes/palette";

const FarmSRSWidget = ({ farm }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (demoSrsData[farm.farm_id]) {
      let result = demoSrsData[farm.farm_id];
      const aggregated = {
        score: _.sumBy(result.scores, "score") / _.sumBy(result.scores, "baseline"),
      };
      result["aggregated"] = aggregated;
      setData(result);
    }
  }, []);

  const generateChartConfigs = () => {
    return {
      chart: {
        type: "gauge",
        backgroundColor: "transparent",
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
        height: 200,
        margin: 0,
        padding: 0,
      },
      title: {
        text: "",
      },
      pane: {
        startAngle: -90,
        endAngle: 89.9,
        background: null,
        center: ["50%", "75%"],
        size: "90%",
      },
      tooltip: {
        enabled: false,
      },
      // the value axis
      yAxis: {
        min: 0,
        max: 2,
        tickPixelInterval: 72,
        tickPosition: "inside",
        tickColor: "#FFFFFF",
        tickLength: 20,
        tickWidth: 2,
        minorTickInterval: null,
        labels: {
          distance: 15,
          inside: true,
          style: {
            fontSize: "11px",
            fontWeight: 800,
          },
        },
        lineWidth: 0,
        plotBands: [
          {
            from: 0,
            to: 0.66,
            color: colors.red[400],
            thickness: 20,
          },
          {
            from: 0.66,
            to: 1.33,
            color: colors.yellow[400],
            thickness: 20,
          },
          {
            from: 1.33,
            to: 2,
            color: colors.green[400],
            thickness: 20,
          },
        ],
      },
      series: [
        {
          name: "SRS",
          data: [2 - data?.aggregated?.score || 0],
          dataLabels: {
            format: "{y:,.2f}",
            borderWidth: 0,
            color: "#333333",
            style: {
              fontSize: "20px",
            },
          },
          dial: {
            radius: "70%",
            backgroundColor: colors.grey[800],
            baseWidth: 12,
            baseLength: "0%",
            rearLength: "0%",
          },
          pivot: {
            backgroundColor: colors.grey[800],
            radius: 6,
          },
        },
      ],
    };
  };

  const generateBulletChartConfigs = ({ label, value }) => {
    return {
      chart: {
        height: 11,
        type: "bullet",
        inverted: true,
        padding: 0,
        margin: 0,
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: [``],
      },
      legend: {
        enabled: false,
      },
      yAxis: {
        gridLineWidth: 0,
        labels: { enabled: false },
        softMax: 2,
        plotBands: [
          {
            from: 0,
            to: 0.66,
            color: colors.red[300],
          },
          {
            from: 0.66,
            to: 1.33,
            color: colors.yellow[300],
          },
          {
            from: 1.33,
            to: 2,
            color: colors.green[300],
          },
        ],
        title: null,
      },
      series: [
        {
          data: [
            {
              y: 0,
              color: "#444",
              target: value || 0,
              name: label,
              targetOptions: {
                width: "200%",
                height: 6, // The height of the target
                borderWidth: 0, // The border width of the target
                borderColor: "#444", // The border color of the target
                color: "#444", // The color of the target
              },
            },
          ],
        },
      ],
      tooltip: {
        enabled: false,
      },
    };
  };

  const bulletDisplay = [
    { label: getText("SRS.disease"), value: _.find(data?.scores, { key: "disease" })?.srs },
    {
      label: getText("SRS.meteorological"),
      value: _.find(data?.scores, { key: "meteorological" })?.srs,
    },
    {
      label: getText("SRS.water-quality"),
      value: _.find(data?.scores, { key: "water-quality" })?.srs,
    },
    {
      label: getText("SRS.management"),
      value: _.find(data?.scores, { key: "management" })?.srs,
    },
  ];

  return (
    <Box>
      <HighchartsWrapper options={generateChartConfigs()} />
      <Grid container spacing={1}>
        {bulletDisplay.map((ele, idx) => {
          return (
            <Grid item xs={6} key={idx}>
              <Typography fontSize={12}>
                {ele.label}: <strong>{<NumberFormat displayType="text" value={ele.value || 0} decimalScale={2} />}</strong>
              </Typography>
              <Box>
                <HighchartsWrapper options={generateBulletChartConfigs(ele)} />
              </Box>
            </Grid>
          );
        })}
      </Grid>
      <Stack spacing={1}></Stack>
    </Box>
  );
};

export default FarmSRSWidget;
