import { Box, Typography } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const Layout = styled.div`
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 16px;
  transition: background-color 0.3s;
  &:hover {
    background-color: #ddd;
    .appIcon {
      color: #000;
      ~ .appTitle {
        color: #000;
      }
    }
  }
  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
    transition: background-color 0.3s, box-shadow 0.3s, transform 0.1s;
    padding: 10px;
    .appTitle,
    .appIcon {
      color: #666;
    }
    .appTitle {
      margin: 10px 0;
      text-align: center;
    }
    .appIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
      border-radius: var(--radius-large);
      background-color: #fff;
      box-shadow: var(--boxshadow-smooth);
      padding: 10px;
      width: 70px;
      height: 70px;
    }

    .appIcon:active {
      transform: translateY(3px);
    }
    &:hover {
      cursor: pointer;
    }
  }

  .MuiSvgIcon-root {
    font-size: 35px;
  }
`;

const AppButton = ({ navlink, path, faClass, name, icon }) => {
  if (navlink) {
    return (
      <Layout>
        <NavLink className="inner" to={path}>
          <Box className="appIcon">{icon || <i className={faClass || "fal fa-window"}></i>}</Box>
          <Typography className="appTitle" mt={1} fontWeight={600}>
            {name}
          </Typography>
        </NavLink>
      </Layout>
    );
  }
  return <Layout></Layout>;
};

export default AppButton;
