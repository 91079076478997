import React from "react"; // Make sure to import React

// Import your image assets as ES6 modules
import clearDayIcon from "./icons/clear_day.svg";
import cloudyIcon from "./icons/cloudy.svg";
import mostlyClearDayIcon from "./icons/mostly_clear_day.svg";
import partlyCloudyDayIcon from "./icons/partly_cloudy_day.svg";
import mostlyCloudyIcon from "./icons/mostly_cloudy.svg";
import fogIcon from "./icons/fog.svg";
import fogLightIcon from "./icons/fog_light.svg";
import drizzleIcon from "./icons/drizzle.svg";
import rainIcon from "./icons/rain.svg";
import rainLightIcon from "./icons/rain_light.svg";
import rainHeavyIcon from "./icons/rain_heavy.svg";
import snowIcon from "./icons/snow.svg";
import flurriesIcon from "./icons/flurries.svg";
import snowLightIcon from "./icons/snow_light.svg";
import snowHeavyIcon from "./icons/snow_heavy.svg";
import freezingDrizzleIcon from "./icons/freezing_drizzle.svg";
import freezingRainIcon from "./icons/freezing_rain.svg";
import freezingRainLightIcon from "./icons/freezing_rain_light.svg";
import freezingRainHeavyIcon from "./icons/freezing_rain_heavy.svg";
import icePelletsIcon from "./icons/ice_pellets.svg";
import icePelletsHeavyIcon from "./icons/ice_pellets_heavy.svg";
import icePelletsLightIcon from "./icons/ice_pellets_light.svg";
import tstormIcon from "./icons/tstorm.svg";
import { Box, Typography } from "@mui/material";

const weatherIcons = {
  1000: clearDayIcon,
  1001: cloudyIcon,
  1100: mostlyClearDayIcon,
  1101: partlyCloudyDayIcon,
  1102: mostlyCloudyIcon,
  2000: fogIcon,
  2100: fogLightIcon,
  4000: drizzleIcon,
  4001: rainIcon,
  4200: rainLightIcon,
  4201: rainHeavyIcon,
  5000: snowIcon,
  5001: flurriesIcon,
  5100: snowLightIcon,
  5101: snowHeavyIcon,
  6000: freezingDrizzleIcon,
  6001: freezingRainIcon,
  6200: freezingRainLightIcon,
  6201: freezingRainHeavyIcon,
  7000: icePelletsIcon,
  7101: icePelletsHeavyIcon,
  7102: icePelletsLightIcon,
  8000: tstormIcon,
};

function getIcon(weatherCode) {
  return weatherIcons[weatherCode];
}

function prettyPrintWeatherCode(weatherCode) {
  const weatherCodes = {
    0: "Unknown",
    1000: "Clear",
    1001: "Cloudy",
    1100: "Mostly Clear",
    1101: "Partly Cloudy",
    1102: "Mostly Cloudy",
    2000: "Fog",
    2100: "Light Fog",
    3000: "Light Wind",
    3001: "Wind",
    3002: "Strong Wind",
    4000: "Drizzle",
    4001: "Rain",
    4200: "Light Rain",
    4201: "Heavy Rain",
    5000: "Snow",
    5001: "Flurries",
    5100: "Light Snow",
    5101: "Heavy Snow",
    6000: "Freezing Drizzle",
    6001: "Freezing Rain",
    6200: "Light Freezing Rain",
    6201: "Heavy Freezing Rain",
    7000: "Ice Pellets",
    7101: "Heavy Ice Pellets",
    7102: "Light Ice Pellets",
    8000: "Thunderstorm",
  };
  return weatherCodes[weatherCode.toString()];
}

function WeatherIcon({ value, size }) {
  const iconSrc = getIcon(value);
  const pretty = prettyPrintWeatherCode(value);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        img: {
          width: `${size || "28"}px`,
          height: `${size || "28"}px`,
        },
      }}
    >
      <img src={iconSrc} alt={pretty} title={pretty} />
    </Box>
  );
}

export default WeatherIcon;
