//import { ApiPost } from "api/apiUtils";
import axios from "axios"
import climateDemoData  from "json/climateDemoData.json";

const api = axios.create({
    //baseURL: 'http://localhost:4040/api/',
    baseURL: 'https://api-shrimpl-climate.intelligon.com',
    // baseURL: 'http://localhost:5000',
    timeout: 8000,
    headers: {'X-Custom-Header': 'foobar'}
  });

  const ApiPost = api.post;

export const ClimateServices = {
    getClimates: (payload) => ApiPost("/api/weatherTimelines", payload).then(res => res.data),
    getClimatesDemoData: () => climateDemoData
}