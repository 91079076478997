import { IconButton } from "@mui/material";
import React from "react";

export const MuiBorderIconButton = ({ buttonProps, onClick, icon, size, disabled = false }) => {
  const styles = {
    sm: {
      padding: 0.4,
      border: "1px solid #AAA",
      iconSize: 16,
    },
    default: {
      padding: 0.8,
      border: "1px solid #AAA",
      iconSize: 20,
    },
  };

  return (
    <IconButton
      sx={{
        padding: styles?.[size]?.padding || styles.default.padding,
        border: styles?.[size]?.border || styles.default.border,
        svg: {
          fontSize: styles?.[size]?.iconSize || styles.default.iconSize,
        },
        "&:hover": {
          bgcolor: "#CCC",
        },
      }}
      {...buttonProps}
      onClick={onClick}
      color="primary"
      disabled={disabled}
    >
      {icon}
    </IconButton>
  );
};
