import { Box, colors, Grid, Stack, Typography, Select, MenuItem, FormControl, ListItemIcon, ListItemText, Avatar } from "@mui/material";
import { ArrowForwardIcon, BusinessIcon, MapIcon, PersonIcon, ReorderIcon, SettingsIcon, ViewModuleIcon } from "components/Icons/MaterialIcons";
import { AppSettings, getLocalStorageItem, saveLocalStorageItem } from "helpers/LocalStorage";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { pondManagementActions } from "redux/pondManagement/pondManagement";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ROUTE_CLIENT_MANAGER, ROUTE_POND_MANAGER_FARM_EDIT } from "routes/paths";
import Text, { getText } from "components/text/Text";
import PondManagerHomePageTableView from "screens/Aquaculture/components/PondManager/PondManagerHomePageTableView";
import PondManagerHomePageMapView from "screens/Aquaculture/components/PondManager/PondManagerHomePageMapView";
import LoadingBox from "components/Loading/LoadingBox";
import MuiToggleButtonGroup from "components/buttons/MuiToggleButtonGroup";
import { AppSelectors } from "redux/AppReducers";
import { organisationManagementActions } from "redux/pondManagement/organisationManagement";
import FarmCard from "screens/Aquaculture/components/common/FarmCard";
import OrganisationInviteTable from "screens/Aquaculture/Organisations/OrganisationInviteTable";
import OrgBadge from "screens/Aquaculture/Organisations/components/OrgBadge";

const HomePageListViews = {
  grid: "grid",
  table: "table",
  map: "map",
};

/**
 *
 * Pond Manager Home Page - Farm View
 *
 * @param {*} param0
 * @returns
 */
const ClientManagerHome = () => {
  // URL Query String
  const params = useParams();
  // Query String -- orgId
  const orgId = params?.["orgid"];

  const farmListViewStorageData = AppSettings.App.farmsViewMode.get();

  const pondManagerStates = useSelector((state) => state.pond);
  const organisationManagementStore = AppSelectors.orgStore();

  const userOrgs = organisationManagementStore.userOrgs;
  const farms = organisationManagementStore.viewAsOrg?.id ? organisationManagementStore.viewFarms : pondManagerStates.farms;
  const viewAsOrg = organisationManagementStore.viewAsOrg;

  const dispatch = useDispatch();

  const [selectedListView, setSelectedListView] = useState(farmListViewStorageData || HomePageListViews.grid);

  useEffect(() => {
    // handle if no org is selected
    if (!viewAsOrg?.id) {
      if (userOrgs.length > 0) {
        const theOrg = userOrgs[0];
        navigate(`${ROUTE_CLIENT_MANAGER}/${theOrg["org.id"]}`);
        Actions.onInit();
      }
    }
  }, [userOrgs, viewAsOrg]);

  useEffect(() => {
    // console.log("viewAsOrg", viewAsOrg);
    Actions.onInit();
  }, []);

  const Actions = {
    onInit: async () => {
      viewAsOrg?.id && navigate(`${ROUTE_CLIENT_MANAGER}/${viewAsOrg?.id}`);
      await organisationManagementActions.loadUserOrgs(dispatch);
      const initOrgId = orgId;
      if (initOrgId) {
        Actions.onSelectOrg(initOrgId);
      }
    },
    onSelectOrg: async (newOrgId) => {
      navigate(`${ROUTE_CLIENT_MANAGER}/${newOrgId}`);
      await organisationManagementActions.onSelectViewAsOrg(dispatch, newOrgId);
    },
  };

  const Variables = {
    stats: [
      {
        label: getText("interface.general.total-farms"),
        value: farms.length,
      },
      {
        label: getText("interface.general.total-ponds"),
        value: _.sumBy(farms, "total_ponds"),
      },
      {
        label: getText("interface.general.total-area") + " (ha.)",
        value: _.sumBy(farms, "total_area")?.toFixed(0),
      },
      {
        label: getText("interface.general.total-area") + " (ac.)",
        value: (_.sumBy(farms, "total_area") * 2.47105)?.toFixed(0),
      },
    ],
  };

  const navigate = useNavigate();

  const handleListViewSelector = (selectedType) => {
    setSelectedListView(selectedType);
    AppSettings.App.farmsViewMode.save(selectedType);
  };

  if (!viewAsOrg?.id) return "";

  return (
    <Box p={2}>
      <OrganisationInviteTable />
      {/* Top Toolbar */}
      <Box mb={4}>
        <Stack direction={"row"} justifyContent="space-between">
          <Stack direction="row" alignItems={"center"} spacing={2}>
            <Box>
              <FormControl size="small">
                <Select
                  autoWidth
                  displayEmpty
                  labelId="view-as-label"
                  id="view-as-select"
                  value={viewAsOrg?.id}
                  onChange={(e) => {
                    const newOrgId = e.target.value;
                    Actions.onSelectOrg(newOrgId);
                  }}
                  sx={{
                    minWidth: 200,
                  }}
                  renderValue={(val) => {
                    const org = _.find(userOrgs, { "org.id": val });
                    return val ? (
                      <OrgBadge org={org} />
                    ) : (
                      <Stack direction="row" alignItems={"center"} spacing={1}>
                        <Avatar sx={{ width: 25, height: 25 }}>
                          <PersonIcon />
                        </Avatar>
                        <Typography variant="caption" fontWeight={"bold"}>
                          INDIVIDUAL
                        </Typography>
                      </Stack>
                    );
                  }}
                >
                  {userOrgs.map((org, id) => (
                    <MenuItem key={id} value={org["org.id"]} disabled={!org?.["member.activated"]}>
                      <ListItemIcon
                        sx={{
                          width: 60,
                        }}
                      >
                        {org?.["org.logo_url"] ? <Avatar src={org?.["org.logo_url"]} sx={{ width: 40, height: 40 }} /> : <BusinessIcon fontSize="small" />}
                      </ListItemIcon>
                      <ListItemText>
                        <Typography fontWeight={"bold"}>{org["org.name"]} </Typography>
                        <Typography fontSize={10} color="text.secondary">
                          {org["org.org_type"]}, {org["role"]}{" "}
                        </Typography>
                        {!org?.["member.activated"] && (
                          <Typography color="error" fontWeight={"bold"} fontSize={11}>
                            [UNACTIVATED]
                          </Typography>
                        )}
                      </ListItemText>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <MuiToggleButtonGroup
              onChange={handleListViewSelector}
              value={selectedListView}
              options={[
                {
                  label: "Grid View",
                  value: HomePageListViews.grid,
                  icon: <ViewModuleIcon />,
                },
                {
                  label: "Table View",
                  value: HomePageListViews.table,
                  icon: <ReorderIcon />,
                },
                {
                  label: "Map View",
                  value: HomePageListViews.map,
                  icon: <MapIcon />,
                },
              ]}
            />
          </Stack>
          <Stack direction="row" alignItems={"center"} spacing={1}>
            {Variables.stats.map((ele, idx) => {
              return (
                <Box key={idx}>
                  <Typography variant="caption">{ele.label}</Typography>
                  <Typography fontWeight="bold">{ele.value}</Typography>
                </Box>
              );
            })}
          </Stack>
        </Stack>
      </Box>

      {/* ~~~~~ Placeholder ~~~~~ */}

      <Box>
        {!pondManagerStates.loading && farms.length === 0 && (
          <Box p={2}>
            <Stack alignItems={"center"} width="100%">
              <Box>
                <Typography fontSize="3rem" fontWeight="bold" color={colors.grey[500]}>
                  <Text>interface.alert.no-farm-connected</Text>
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography color={colors.grey[500]}>You don't have any farm connected to your organisation</Typography>
              </Box>
            </Stack>
          </Box>
        )}

        {/* ~~~~~ MAIN ~~~~~ */}

        {pondManagerStates.loading ? (
          <LoadingBox />
        ) : (
          <>
            {/* ************* GRID VIEW ************* */}
            <Grid container spacing={3}>
              {selectedListView === HomePageListViews.grid &&
                farms.map((f) => {
                  return (
                    <Grid key={f.farm_id} item xs={12} md={6} lg={4} xl={3}>
                      <FarmCard variant="org" farm={f} />
                    </Grid>
                  );
                })}
            </Grid>

            {/* ************* TABLE VIEW ************* */}
            {selectedListView === HomePageListViews.table && farms.length > 0 && (
              <Box mt={2}>
                <PondManagerHomePageTableView key={viewAsOrg?.id} farms={farms} farmEditPath={ROUTE_POND_MANAGER_FARM_EDIT} editIcon={<SettingsIcon />} viewIcon={<ArrowForwardIcon />} />
              </Box>
            )}

            {/* ************* MAP VIEW ************* */}
            {selectedListView === HomePageListViews.map && farms.length > 0 && (
              <Box mt={2}>
                <PondManagerHomePageMapView farms={farms} farmEditPath={ROUTE_POND_MANAGER_FARM_EDIT} editIcon={<SettingsIcon />} viewIcon={<ArrowForwardIcon />} />
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default ClientManagerHome;
