import { Box, Stack, Typography } from "@mui/material";
import { PersonIcon } from "components/Icons/MaterialIcons";
import { getText } from "components/text/Text";
import { getCollabAccessDetails } from "constants/FarmAccessLevels";

const FarmAccessLevelBadge = ({ accessLevelId, sx }) => {
  const accessLevelSettings = getCollabAccessDetails(accessLevelId);
  return (
    <Box
      sx={{
        fontSize: 12,
        fontWeight: "bold",
        color: accessLevelSettings.color,
        bgcolor: accessLevelSettings.bgColor,
        display: "inline-block",
        height: 15,
        position: "relative",
      }}
      pl={0.5}
      pr={0.8}
      borderRadius={0.5}
    >
      <Stack direction={"row"} alignItems={"center"} sx={{ height: "100%" }}>
        <Box
          sx={{
            svg: {
              fontSize: 13,
              transform: "translateY(1px)",
            },
          }}
        >
          <PersonIcon />
        </Box>
        <Typography fontWeight={"bold"} fontSize={10}>
          {accessLevelSettings.displayKey && getText(accessLevelSettings.displayKey)}
        </Typography>
      </Stack>
    </Box>
  );
};

export default FarmAccessLevelBadge;
