import { Box, Button, Grid, Stack, Typography, colors } from "@mui/material";
import { ArrowBackIcon } from "components/Icons/MaterialIcons";
import Text from "components/text/Text";
import React from "react";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import palette from "themes/palette";

const TheMenu = ({ title, menulist }) => {
  return (
    <Stack
      sx={{
        ".active div": {
          bgcolor: palette.primary.light,
        },
        ".active div *": {
          color: "#000",
          fontWeight: 800,
        },
      }}
      spacing={0.3}
    >
      {title}
      {menulist.map((ele, idx) => {
        const { path, label, icon } = ele;
        const selected = false;
        return (
          <NavLink to={path} key={idx}>
            <Box
              // onClick={() => navigate(path)}
              sx={{
                px: 1,
                py: 0.3,
                borderRadius: 2,
                "&:hover": {
                  cursor: "pointer",
                  bgcolor: colors.grey[200],
                },
                "&:active": {
                  transform: "translateY(1px)",
                },
                ".menu-icon": {
                  color: colors.grey[600],
                  fontSize: 20,
                  transform: "translateY(2px)",
                },
                ".menu-icon svg": {
                  color: selected ? colors.grey[900] : colors.grey[500],
                  fontSize: 20,
                },
                ".menu-label": {
                  fontSize: 13,
                  fontWeight: selected ? 800 : 400,
                },
              }}
            >
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <Box className="menu-icon">{icon}</Box>
                <Typography textTransform={"capitalize"} className="menu-label" color="grey">
                  {label}
                </Typography>
              </Stack>
            </Box>
          </NavLink>
        );
      })}
    </Stack>
  );
};

const SideMenuRouteLayout = ({ title, menulist, onReturn }) => {
  return (
    <Box sx={{ position: "relative", height: "100%", width: "100%", boxSizing: "border-box" }}>
      <Grid
        container
        sx={{
          height: "100%",
        }}
      >
        <Grid item width={300} sx={{ p: 2, bgcolor: "#FFF", borderRight: "1px solid #CCC" }}>
          {onReturn && (
            <Box mb={3}>
              <Button onClick={onReturn} startIcon={<ArrowBackIcon />}>
                <Text>interface.actions.return</Text>
              </Button>
            </Box>
          )}
          <TheMenu title={title} menulist={menulist} />
        </Grid>
        <Grid
          item
          xs
          sx={{
            height: "100%",
            overflow: "auto",
          }}
        >
          <Outlet />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SideMenuRouteLayout;
