import { Avatar, Box, Button, colors, Divider, FormControl, FormLabel, IconButton, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { PondManagerServices } from "api/pondManagerServices";
import CommonDialog, { CommonDialogIds } from "components/Dialogs/CommonDialog";
import { DeleteIcon } from "components/Icons/MaterialIcons";
import { AddIcon, CloseIcon, EditIcon, PersonIcon } from "components/Icons/MaterialIcons";
import Text from "components/text/Text";
import { isInternalUser } from "constants/InternalUserIds";
import Formatters from "helpers/Formatters";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { alertsActions } from "redux/alerts";
import { AppSelectors } from "redux/AppReducers";
import { dialogReducerActions } from "redux/dialog";
import FarmAccessLevelBadge from "screens/Aquaculture/components/common/FarmAccessLevelBadge";

const AccessLevelsSelections = [
  // {
  //   label: "Owner",
  //   description: "User can create, view, manage, export data or share",
  //   value: 4,
  // },
  {
    label: "Admin",
    description: "User can create, view, manage, export data or share",
    value: 3,
  },
  {
    label: "Manager",
    description: "User can create, view, manage or export data",
    value: 2,
  },
  {
    label: "Advisor",
    description: "Advisor can view and export data",
    value: 5,
  },
  {
    label: "Viewer",
    description: "User can only view data",
    value: 1,
  },
  {
    label: "Technicial",
    description: "User can only submit data",
    value: 6,
  },
];

const FarmAccessControlForm = ({ selectedMember, onCancel, onSubmit }) => {
  const [email, setEmail] = useState(selectedMember?.email || "");
  const [role, setRole] = useState(selectedMember?.access_level_id || AccessLevelsSelections[0].value);

  return (
    <Box py={2}>
      <Stack spacing={1}>
        <Typography fontWeight="bold">{selectedMember ? "Edit Access" : <Text>interface.actions.share-with</Text>}</Typography>
        <FormControl>
          <FormLabel>
            <Typography>Email</Typography>
          </FormLabel>
          <TextField value={email} onChange={(e) => setEmail(e.target.value)} variant="standard" disabled={selectedMember} />
        </FormControl>
        <FormControl fullWidth size="small">
          <FormLabel>
            <Text>interface.access-control.access-level</Text>
          </FormLabel>
          <Select value={role} onChange={(e) => setRole(Number.parseInt(e.target.value))} disabled={selectedMember?.access_level_id === 4}>
            {AccessLevelsSelections.map((ele, idx) => (
              <MenuItem key={idx} value={ele.value}>
                <Box>
                  <FarmAccessLevelBadge accessLevelId={ele.value} />
                  <Typography fontSize={10} color={colors.grey[800]}>
                    {ele.description}
                  </Typography>
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          onClick={() =>
            onSubmit({
              userId: selectedMember?.user_id,
              email,
              accessLevelId: role,
            })
          }
          disabled={!email}
        >
          <Text>interface.actions.confirm</Text>
        </Button>
        <Button variant="error" onClick={onCancel}>
          <Text>interface.actions.cancel</Text>
        </Button>
      </Stack>
    </Box>
  );
};

const FarmAccessControl = ({ farm, onClose }) => {
  const dispatch = useDispatch();

  const [accessControl, setAccessControl] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [selectedEditMember, setEditMember] = useState(null);
  const [selectedDeleteMember, setDeleteMember] = useState(null);
  const [error, setError] = useState("");

  const userStore = AppSelectors.userStore();
  const currentUserId = userStore.user_id;

  const initData = async () => {
    const result = await PondManagerServices.getFarmAccessControl(farm.farm_id);
    setAccessControl(
      result
        .map((ele) => ({
          ...ele,
          isme: ele.user_id === currentUserId,
        }))
        .filter((ele) => !isInternalUser(ele))
    );
  };

  useEffect(() => {
    initData();
  }, []);

  const Actions = {
    editMember: (memeberAccess) => {
      setEditMember(memeberAccess);
      setEditMode(true);
    },
    cancelEdit: () => {
      setEditMember(null);
      setEditMode(false);
      setError("");
    },
    onRemoveConfirm: (member) => {
      setDeleteMember(member);
      dialogReducerActions.openCommonDialog(dispatch, { id: CommonDialogIds.FARM_ACCESS_CONTROL_CONFIRM });
    },
    onRemove: async ({ userId }) => {
      const rsp = await PondManagerServices.deleteUserFarmAccess(userId, farm.farm_id);
      setEditMember(null);
      setEditMode(false);
      initData();
      alertsActions.addInfo(dispatch, {
        content: "Member has been removed",
      });
      setDeleteMember(null);
    },
    onSubmit: async ({ userId, email, accessLevelId }) => {
      const emailClaned = Formatters.cleanEmail(email);
      if (userId) {
        // update
        const rsp = await PondManagerServices.updateUserFarmAccess(userId, farm.farm_id, accessLevelId);
        if (rsp.status === "ok") {
          setEditMember(null);
          setEditMode(false);
          initData();
          alertsActions.addInfo(dispatch, {
            content: "Member's access is updated!",
          });
        }
      } else {
        // create
        const rsp = await PondManagerServices.createNewUserFarmAccess(emailClaned, farm.farm_id, accessLevelId);
        if (rsp.error) {
          alertsActions.addError(dispatch, { content: rsp.error });
        } else {
          setEditMember(null);
          setEditMode(false);
          initData();
          alertsActions.addInfo(dispatch, { content: "New member is added!" });
        }
      }
    },
  };

  return (
    <Box>
      <CommonDialog
        id={CommonDialogIds.FARM_ACCESS_CONTROL_CONFIRM}
        title={<Text>interface.alert.alert-are-you-sure</Text>}
        content={`You are removing ${selectedDeleteMember?.email}`}
        action={() => Actions.onRemove({ userId: selectedDeleteMember?.user_id })}
      />
      <Stack direction="row" alignItems={"flex-start"} justifyContent="space-between" width="100%" spacing={2} py={1}>
        <Box>
          <Typography fontSize={15} fontWeight={800}>
            {farm.label}
          </Typography>
          <Typography fontSize={10} color={colors.grey[700]}>
            <Text>interface.access-control.access-control-description</Text>
          </Typography>
        </Box>
        {onClose && (
          <Box>
            <IconButton size="small" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        )}
      </Stack>
      <Divider />
      <Box py={2}>
        <Stack spacing={1}>
          {[...accessControl.filter((ele) => ele.isme), ...accessControl.filter((ele) => !ele.isme)].map((ele, idx) => {
            return (
              <Stack key={idx}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  p={1}
                  borderRadius={2}
                  sx={{
                    transition: "all .3s",
                    ":hover": {
                      background: colors.grey[100],
                      cursor: "pointer",
                    },
                  }}
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Avatar sx={{ width: 30, height: 30 }}>
                      <PersonIcon />
                    </Avatar>
                    <Box>
                      <Stack direction={"row"} spacing={1}>
                        <Typography fontSize={12} fontWeight="bold">
                          {ele.firstname}
                          {ele.isme && " (Me)"}
                        </Typography>
                        <FarmAccessLevelBadge accessLevelId={ele.access_level_id} />
                      </Stack>
                      <Typography fontSize={10} color={colors.grey[700]}>
                        {ele.email}
                      </Typography>
                    </Box>
                  </Stack>
                  <Box sx={{ textAlign: "right" }}>
                    {!ele.isme && farm.access_level_share && (
                      <Stack direction="row" justifyContent={"center"}>
                        <Stack direction="row" spacing={1}>
                          {
                            // other owner can't be editted
                            ele.access_level_id !== 4 && (
                              <>
                                <IconButton size="small" color="info" onClick={() => Actions.editMember(ele)}>
                                  <EditIcon />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  color="error"
                                  onClick={() => {
                                    Actions.onRemoveConfirm(ele);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </>
                            )
                          }
                        </Stack>
                      </Stack>
                    )}
                  </Box>
                </Stack>
              </Stack>
            );
          })}
          {farm.access_level_share &&
            (editMode ? (
              <>
                <Divider />
                <FarmAccessControlForm selectedMember={selectedEditMember} onSubmit={Actions.onSubmit} onCancel={Actions.cancelEdit} />
              </>
            ) : (
              <Stack
                p={1}
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{
                  borderRadius: 2,
                  transition: ".2s all",
                  "&:hover": {
                    background: colors.green[50],
                    cursor: "pointer",
                  },
                }}
                onClick={() => setEditMode(true)}
              >
                <Avatar sx={{ width: 30, height: 30, bgcolor: colors.green[600] }}>
                  <AddIcon />
                </Avatar>
                <Box>
                  <Typography fontWeight="bold" color={colors.green[600]}>
                    <Text>interface.actions.add-more</Text>
                  </Typography>
                </Box>
              </Stack>
            ))}
        </Stack>
      </Box>
    </Box>
  );
};

export default FarmAccessControl;
