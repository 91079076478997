import { ResultTypes } from "components/GlobalSearch/components/ResultRow";
import { ROUTE_ORGANISATION_HOME, ROUTE_POND_MANAGER_HOME_PAGE, ROUTE_USER_PROFILE } from "routes/paths";

const initState = {
  term: null,
  resultList: [],
};

const smartSearchTypes = {
  INIT_RESULT_LIST: "INIT_RESULT_LIST",
  ON_CHANGE_TERM: "ON_CHANGE_TERM",
};

const smartSearchReducers = (state = initState, action) => {
  switch (action.type) {
    case smartSearchTypes.INIT_RESULT_LIST:
      return {
        ...state,
        resultList: action.payload.resultList,
      };
    default:
      return state;
  }
};

export const smartSearchActions = {
  init: async (dispatch, initData) => {
    // function results
    const appFunctionsList = [
      {
        type: ResultTypes.app.value,
        title: "Pond Manager",
        subtitle: "Click to navigate to Pond Manager",
        link: `${ROUTE_POND_MANAGER_HOME_PAGE}`,
        payload: {},
      },
      {
        type: ResultTypes.app.value,
        title: "Account Settings",
        subtitle: "Click to view your account settings",
        link: `${ROUTE_USER_PROFILE}`,
        payload: {},
      },
      {
        type: ResultTypes.app.value,
        title: "Organisations",
        subtitle: "Click to view my organisations",
        link: `${ROUTE_ORGANISATION_HOME}`,
        payload: {},
      },
    ];

    // farm results
    const farmResult = initData.farms;
    const farmResultList = farmResult.map((item) => ({
      type: ResultTypes.farm.value,
      title: item.label,
      subtitle: "Click to view the farm",
      link: `${ROUTE_POND_MANAGER_HOME_PAGE}/${item.farm_id}`,
      payload: {
        farm: item,
      },
    }));

    // merge all
    const resultList = [...farmResultList, ...appFunctionsList];

    dispatch({
      type: smartSearchTypes.INIT_RESULT_LIST,
      payload: {
        resultList: resultList,
      },
    });
  },
};

export default smartSearchReducers;
