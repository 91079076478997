import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, colors, Box, Stack } from "@mui/material";
import { useDispatch } from "react-redux";
import { dialogReducerActions } from "redux/dialog";
import { ErrorOutlineIcon, HelpOutlineIcon, InfoIcon } from "components/Icons/MaterialIcons";
import palette from "themes/palette";
import { AppSelectors } from "redux/AppReducers";
import Text from "components/text/Text";

export const CommonDialogIds = {
  FARM_ACCESS_CONTROL_CONFIRM: "FARM_ACCESS_CONTROL_CONFRIM",
  POND_DELETE_CONFIRM: "POND_DELETE_CONFIRM",
  IMAGE_MODEL_DELETE_CONFIRM: "IMAGE_MODEL_DELETE_CONFIRM",
  CYCLE_DELETE_CONFIRM: "CYCLE_DELETE_CONFIRM",
  FARM_IMAGE_DELETE_CONFIRM: "FARM_IMAGE_DELETE_CONFIRM",
  ORG_CONNECT_CONFIRM_DELETE: "ORG_CONNECT_CONFIRM_DELETE",
  SCENARIO_CONFIRM_DELETE: "SCENARIO_CONFIRM_DELETE",
  ADMIN_PRODUCER_DELETE_CONFIRM: "ADMIN_PRODUCER_DELETE_CONFIRM",
  ADMIN_PRODUCT_DELETE_CONFIRM: "ADMIN_PRODUCT_DELETE_CONFIRM",
  PRODUCT_DELETE_CONFIRM: "PRODUCT_DELETE_CONFIRM",
};

const CommonDialog = ({ id, level = "info", title, content, action }) => {
  const dialogStates = AppSelectors.dialogStore();
  const dispatch = useDispatch();

  const handleClose = () => {
    dialogReducerActions.closeCommonDialog(dispatch, { id: id });
  };

  const modelOpen = dialogStates.opens?.[id] || false;

  return (
    <Dialog
      open={modelOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          borderRadius: 4,
          bgcolor: palette.primary.light,
        },
      }}
    >
      <Box
        p={{
          sm: 1,
          md: 2,
        }}
        width={{
          sm: 100,
          md: 350,
        }}
        minHeight="200px"
      >
        <DialogTitle fontSize="1.2rem" fontWeight="bold" color={colors.grey[800]} textAlign="center" id="alert-dialog-title">
          <Box>
            {level === "info" && (action ? <HelpOutlineIcon sx={{ fontSize: "8rem", color: palette.primary.main }} /> : <InfoIcon sx={{ fontSize: "8rem", color: palette.primary.main }} />)}
            {level === "error" && <ErrorOutlineIcon sx={{ fontSize: "8rem", color: colors.red[500] }} />}
          </Box>
          <Box>{title}</Box>
        </DialogTitle>
        <DialogContent sx={{ textAlign: "center" }}>
          <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
        </DialogContent>
      </Box>
      <Stack spacing={1} p={2} px={4} direction={"row"}>
        <Button variant="outlined" onClick={handleClose} autoFocus fullWidth size="large">
          <Text>interface.actions.cancel</Text>
        </Button>
        {action && (
          <Button
            variant="contained"
            onClick={() => {
              action();
              handleClose();
            }}
            autoFocus
            fullWidth
            size="large"
          >
            <Text>interface.actions.confirm</Text>
          </Button>
        )}
      </Stack>
    </Dialog>
  );
};

export default CommonDialog;
