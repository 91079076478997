import CameraAlt from "@mui/icons-material/CameraAlt";
import { Box, Button, Grid, IconButton, Stack, Typography } from "@mui/material";
import { PondManagerServices } from "api/pondManagerServices";
import { VerticalAlertBoxCollections } from "components/Alerts/AlertBox";
import { ArrowBackIcon } from "components/Icons/MaterialIcons";
import Text from "components/text/Text";
import React, { useEffect, useState } from "react";
import MobilePhotoLogsImageViewer from "screens/Mobile/PondManager/components/PondView/PhotoLogs/MobilePhotoLogsImageViewer";

const MobilePhotoLogs = ({ pond }) => {
  const [images, setImages] = useState([]);
  const [page, setPage] = useState(0);
  const [reachedEnd, setReachedEnd] = useState(false);
  const [imageView, setImageView] = useState(null);

  useEffect(() => {
    if (pond) {
      Actions.onLoad();
    }
  }, []);

  const Actions = {
    onLoad: async () => {
      const result = await PondManagerServices.fetchPondImagesByPage({
        pondId: pond?.id,
        page: page,
      });
      if (result.rows.length > 0) {
        setImages([...result.rows, ...images]);
        setPage(page + 1);
      } else {
        setReachedEnd(true);
      }
    },
    onLoadMore: async () => {
      Actions.onLoad();
    },
    onSubmitUpdate: async (data) => {
      const formData = {
        label: data.form.label,
        image_id: data.image.id,
        tags: data.form.tags.join(","),
        note: data.form.note,
      };
      const pondId = data?.form?.pond_id;
      await PondManagerServices.updatePondImage({
        pondId,
        formData,
      });
      Actions.onLoad();
    },
    onDelete: async (image) => {
      await PondManagerServices.deletePondImage({ pondId: image.pond_id, imageId: image.id });
      Actions.fetchPondImages({ pondId });
    },
    onSelectImage: (image) => {
      setImageView(image);
    },
  };

  return (
    <Box p={1}>
      {/* --- Render Images */}

      {imageView && (
        <Box>
          <IconButton onClick={() => Actions.onSelectImage(null)}>
            <ArrowBackIcon />
          </IconButton>
          <MobilePhotoLogsImageViewer image={imageView} onSubmit={Actions.onSubmitUpdate} onDelete={Actions.onDelete} />
        </Box>
      )}

      {!imageView && (
        <Box>
          <Stack
            alignItems={"center"}
            py={2}
            mb={2}
            sx={{
              borderRadius: 2,
              background: "#CCC",
              "&:active": {
                background: "#DDD",
              },
            }}
          >
            <CameraAlt />
          </Stack>

          {images.length === 0 && <VerticalAlertBoxCollections.NoPhoto />}
          <Grid container columns={3} spacing={0.3}>
            {images.map((item) => (
              <Grid item xs={1} onClick={() => Actions.onSelectImage(item)}>
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    aspectRatio: 1,
                    background: `url(${item.compressed_url})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                  key={item.id}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      width: "100%",
                      display: "none",
                    }}
                    bgcolor={"#ffffffa9"}
                  >
                    <Typography textAlign={"center"} fontSize={10} fontWeight={800}>
                      {item.datetime}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
          {!reachedEnd && (
            <Box mt={1}>
              <Button fullWidth onClick={Actions.onLoadMore}>
                Load more
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default MobilePhotoLogs;
