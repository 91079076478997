import { Box, Button, CircularProgress, Divider, Grid, Stack, TextField, Typography } from "@mui/material";
import { PondManagerServices } from "api/pondManagerServices";
import MuiDatePicker from "components/DatePicker/MuiDatePicker";
import { InfoIcon, MenuIcon, MenuOpenIcon, PlayArrowIcon, SaveIcon } from "components/Icons/MaterialIcons";
import { MuiBorderIconButton } from "components/buttons/MuiButtonVariants";
import Text, { getText } from "components/text/Text";
import { Form, Formik } from "formik";
import { getShrimpSizeCategoryByWeight, getShrimpSizeFromCategory } from "helpers/Aqua";
import _ from "lodash";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { AppSelectors } from "redux/AppReducers";
import { alertsActions } from "redux/alerts";
import FarmShrimpUnitPricesForm from "screens/Aquaculture/components/Forms/FarmShrimpUnitPricesForm";
import FarmResultCharts from "screens/Aquaculture/components/ScenarioBuilder/components/FarmResultCharts";
import PondResults from "screens/Aquaculture/components/ScenarioBuilder/components/PondResults";
import ScenarioDetails from "screens/Aquaculture/components/ScenarioBuilder/components/ScenarioDetails";
import palette from "themes/palette";
import MuiTabs from "ui/tabs/MuiTabs";

const ScenarioBuilderModes = {
  farm: "farm",
  pond: "pond",
};

const ScenarioBuilderInterface = ({ scenario }) => {
  const params = useParams();
  const orgid = params.orgid;
  const farmid = params.farmid;
  const viewAsOrg = orgid && farmid;

  const dispatch = useDispatch();

  const [scenarioSnapshots, setScenarioSnapshots] = useState([]);

  const [formValues, setFormValues] = useState({
    initialWeight: 0.2,
    seededDensity: 18,
    ahead: 200,
    mortality: 0,
    dayOfMortality: 0,
    seededDate: moment(),
    growth_rate_deduction_ratio: 0,
    day_of_growth_rate_deduction_ratio: 0,
    cost_of_opportunity: 0.13,
  });
  const [dynamicFormValues, setDynamicFormValues] = useState({
    labourFixedCosts: 120000,
    adminFixedCosts: 140000,
    otherFixedCosts: 1000,
    feedVarCosts: 20,
    energyVarCosts: 150000,
    seedVarCosts: 20,
    sellingPrice: 80,
    totalFeedStock: 1000,
  });
  const [pondOverrideValues, setPondOverrideValues] = useState({});

  const [loading, setLoading] = useState(false);
  const [forecastResult, setForecastResult] = useState(null);

  const [selectedPondId, setPondId] = useState(null);

  const [financialResults, setFinanialResults] = useState(null);

  const [pondsResults, setPondsResults] = useState([]);
  const [pondsStats, setPondsStats] = useState(null);

  // REF
  const submitButtonRef = useRef();

  // UI
  const [showForm, setShowForm] = useState(true);
  const [sbMode, setSbMode] = useState(ScenarioBuilderModes.farm);

  // REDUX

  const store = AppSelectors.pondManagementStore();
  const { ponds, selectedFarm } = store;

  const pondManagementStore = AppSelectors.pondManagementStore();
  const { shrimpUnitPrices } = pondManagementStore;

  /** trigger if new scenario, to load snapshots */
  useEffect(() => {
    if (scenario?.id) {
      Actions.onFetchScenarioSnapshots();
    }
  }, [scenario]);

  /** trigger if forecastResult and dynamicFormValues are updated */
  useEffect(() => {
    if (!forecastResult) return;

    // ============================
    // calculate FARM level results
    // ============================

    const getResults = (data) => {
      const aggForecastResult = data;

      let financialResults = {
        data: data?.data?.map((row, idx) => {
          const doc = row["doc"];
          let result = {
            doc: doc,
            doc_y: row["doc_y"],
            date: row["date"],
            labourFixedCost: doc * ((dynamicFormValues?.labourFixedCosts * 12) / 365),
            adminFixedCost: doc * ((dynamicFormValues?.adminFixedCosts * 12) / 365),
            otherFixedCost: doc * ((dynamicFormValues?.otherFixedCosts * 12) / 365),
            feedVarCost: row?.cumulative_feed * dynamicFormValues?.feedVarCosts,
            energyVarCost: doc * ((dynamicFormValues?.energyVarCosts * 12) / 365),
            seedVarCost: (_.first(data?.data)?.population / 1000) * dynamicFormValues?.seedVarCosts,
            sellingValue: row["biomass"] * dynamicFormValues?.sellingPrice,
          };
          // calculate total cost
          result["totalCost"] = result.labourFixedCost + result.adminFixedCost + result.otherFixedCost + result.feedVarCost + result.energyVarCost + result.seedVarCost;
          // calculate average unit cost
          result["averageUnitCost"] = result["totalCost"] / row["biomass"];
          // calculate net profit: NET PROFIT = SELLING VALUE - TOTAL COST
          result["netProfit"] = result["sellingValue"] - result["totalCost"];
          // calculate NPV factor
          result["netProfitNpv"] = result["netProfit"] / (1 + formValues["cost_of_opportunity"] / 365) ** doc;
          return result;
        }),
      };

      const optimalHarvestDoc = _.maxBy(financialResults?.data, "netProfit")?.doc;
      const optimalHarvestProfit = financialResults["data"][optimalHarvestDoc]?.netProfit;
      const optimalHarvestTotalCost = financialResults["data"][optimalHarvestDoc]?.totalCost;
      const optimalHarvestLabourFixedCost = financialResults["data"][optimalHarvestDoc]?.labourFixedCost;
      const optimalHarvestAdminFixedCost = financialResults["data"][optimalHarvestDoc]?.adminFixedCost;
      const optimalHarvestOtherFixedCost = financialResults["data"][optimalHarvestDoc]?.otherFixedCost;
      const optimalHarvestEnergyVarCost = financialResults["data"][optimalHarvestDoc]?.energyVarCost;
      const optimalHarvestFeedVarCost = financialResults["data"][optimalHarvestDoc]?.feedVarCost;
      const optimalHarvestSeedVarCost = financialResults["data"][optimalHarvestDoc]?.seedVarCost;
      const optimalHarvestGrossIncome = financialResults["data"][optimalHarvestDoc]?.sellingValue;
      const optimalHarvestBiomass = aggForecastResult?.data[optimalHarvestDoc]?.biomass;
      const optimalHarvestAverageUnitCost = financialResults["data"][optimalHarvestDoc]?.averageUnitCost;
      const optimalHarvestFcr = aggForecastResult?.data[optimalHarvestDoc]?.fcr;
      const optimalHarvestTotalFeed = aggForecastResult?.data[optimalHarvestDoc]?.cumulative_feed;
      const optimalHarvestRemainingFeed = dynamicFormValues?.totalFeedStock * 1000 - aggForecastResult?.data[optimalHarvestDoc]?.cumulative_feed; // totalFeedStock in Tons

      const financialResultStats = {
        optimalHarvestDoc,
        optimalHarvestProfit,
        optimalHarvestTotalCost,
        optimalHarvestLabourFixedCost,
        optimalHarvestAdminFixedCost,
        optimalHarvestOtherFixedCost,
        optimalHarvestEnergyVarCost,
        optimalHarvestFeedVarCost,
        optimalHarvestSeedVarCost,
        optimalHarvestGrossIncome,
        optimalHarvestBiomass,
        optimalHarvestAverageUnitCost,
        optimalHarvestFcr,
        optimalHarvestTotalFeed,
        optimalHarvestRemainingFeed,
      };

      financialResults["stats"] = financialResultStats;

      return financialResults;
    };
    const aggForecastResult = forecastResult["_agg"];
    const aggFinancialResults = getResults(aggForecastResult);
    setFinanialResults(aggFinancialResults);

    // ============================
    // calculate POND level results
    // ============================

    const totalArea = _.sumBy(ponds, "area");

    const pondsResults = ponds.map((pond) => {
      const pondId = pond.id;
      const pondArea = pond.area;
      const pondLabel = pond.label;
      const pondAreaRatio = pondArea / totalArea;

      const timeseries = forecastResult?.[pondId]?.data?.map((ele, idx) => {
        const originalTimeseries = forecastResult?.[pondId]?.data;

        const weightDiff = idx === 0 ? 0 : ele["weight"] - originalTimeseries[idx - 1]["weight"];

        const shrimpSize = getShrimpSizeCategoryByWeight(ele.weight)?.category;
        const unitSellPrice = shrimpUnitPrices?.[shrimpSize] || 0;

        const netProfit = unitSellPrice * ele?.biomass - ele?.biomass * aggFinancialResults?.data?.[ele.doc]?.averageUnitCost;
        const netProfitNpv = netProfit / (1 + formValues["cost_of_opportunity"] / 365) ** ele.doc;

        return {
          ...ele,
          b__weight_diff: weightDiff,
          p__size: shrimpSize + ": " + getShrimpSizeFromCategory(shrimpSize),
          p__price: unitSellPrice,
          f__labourFixedCost: aggFinancialResults?.data?.[ele.doc]?.labourFixedCost * pondAreaRatio,
          f__adminFixedCost: aggFinancialResults?.data?.[ele.doc]?.adminFixedCost * pondAreaRatio,
          f__otherFixedCost: aggFinancialResults?.data?.[ele.doc]?.otherFixedCost * pondAreaRatio,
          f__feedVarCost: aggFinancialResults?.data?.[ele.doc]?.feedVarCost * pondAreaRatio,
          f__energyVarCost: aggFinancialResults?.data?.[ele.doc]?.energyVarCost * pondAreaRatio,
          f__seedVarCost: aggFinancialResults?.data?.[ele.doc]?.seedVarCost * pondAreaRatio,
          f__totalCost: aggFinancialResults?.data?.[ele.doc]?.averageUnitCost * ele?.biomass,
          f__averageUnitCost: aggFinancialResults?.data?.[ele.doc]?.averageUnitCost,
          f__sellingValue: unitSellPrice * ele?.biomass,
          f__netProfit: netProfit,
          f__netProfit_npv: netProfitNpv,
          f__feedStock: dynamicFormValues?.totalFeedStock * pondAreaRatio * 1000 - ele.cumulative_feed,
        };
      });

      // calculate stats
      const optimalHarvestRow = _.maxBy(timeseries, "f__netProfit");
      const optimalHarvestDoc = optimalHarvestRow?.doc;
      const optimalHarvestDate = optimalHarvestRow?.date;
      const optimalHarvestNetProfit = optimalHarvestRow?.f__netProfit;
      const optimalHarvestSellingValue = optimalHarvestRow?.f__sellingValue;
      const optimalHarvestCost = optimalHarvestRow?.f__totalCost;
      const optimalHarvestFeedStock = optimalHarvestRow?.f__feedStock;
      const optimalHarvestBiomass = optimalHarvestRow?.biomass;
      const optimalHarvestFeedAccumulated = optimalHarvestRow?.cumulative_feed;
      const optimalHarvestFcr = optimalHarvestRow?.fcr;
      const optimalHarvestSize = optimalHarvestRow?.p__size;

      // overrides
      const plannedHarvestDocOffset = pondOverrideValues?.[pondId]?.harvestOffset || 0;
      const plannedHarvestHasOffset = plannedHarvestDocOffset !== 0;
      const plannedHarvestDoc = optimalHarvestDoc + plannedHarvestDocOffset;
      const plannedHarvestRow = timeseries?.[plannedHarvestDoc];
      const plannedHarvestDate = plannedHarvestRow?.date;
      const plannedHarvestNetProfit = plannedHarvestRow?.f__netProfit;
      const plannedHarvestSellingValue = plannedHarvestRow?.f__sellingValue;
      const plannedHarvestCost = plannedHarvestRow?.f__totalCost;
      const plannedHarvestFeedStock = plannedHarvestRow?.f__feedStock;
      const plannedHarvestSize = plannedHarvestRow?.p__size;

      const plannedHarvestBiomass = plannedHarvestRow?.biomass;
      const plannedHarvestFeedAccumulated = plannedHarvestRow?.cumulative_feed;
      const plannedHarvestFcr = plannedHarvestRow?.fcr;

      const plannedHarvestNetProfitDiff = plannedHarvestNetProfit - optimalHarvestNetProfit;
      const plannedHarvestSellingValueDiff = plannedHarvestSellingValue - optimalHarvestSellingValue;
      const plannedHarvestCostDiff = plannedHarvestCost - optimalHarvestCost;
      const plannedHarvestFeedStockDiff = plannedHarvestFeedStock - optimalHarvestFeedStock;
      const plannedHarvestBiomassDiff = plannedHarvestBiomass - optimalHarvestBiomass;
      const plannedHarvestFeedAccumulatedDiff = plannedHarvestFeedAccumulated - optimalHarvestFeedAccumulated;
      const plannedHarvestFcrDiff = plannedHarvestFcr - optimalHarvestFcr;

      const stats = {
        optimalHarvestDoc,
        optimalHarvestDate,
        optimalHarvestNetProfit,
        optimalHarvestSellingValue,
        optimalHarvestCost,
        optimalHarvestFeedStock,
        optimalHarvestBiomass,
        optimalHarvestFeedAccumulated,
        optimalHarvestFcr,
        optimalHarvestSize,
        plannedHarvestDocOffset,
        plannedHarvestHasOffset,
        plannedHarvestDoc,
        plannedHarvestDate,
        plannedHarvestNetProfit,
        plannedHarvestSellingValue,
        plannedHarvestCost,
        plannedHarvestFeedStock,
        plannedHarvestBiomass,
        plannedHarvestFeedAccumulated,
        plannedHarvestFcr,
        plannedHarvestNetProfitDiff,
        plannedHarvestSellingValueDiff,
        plannedHarvestCostDiff,
        plannedHarvestFeedStockDiff,
        plannedHarvestBiomassDiff,
        plannedHarvestFeedAccumulatedDiff,
        plannedHarvestFcrDiff,
        plannedHarvestSize,
      };

      return {
        p__id: pondId,
        p__area: pondArea,
        p__label: pondLabel,
        pond: pond,
        timeseries: timeseries,
        meta: forecastResult?.[pondId]?.meta,
        stats: stats,
      };
    });

    const pondsResultsAgg = {
      optimalHarvestNetProfit: _.sumBy(pondsResults, "stats.optimalHarvestNetProfit"),
      optimalHarvestSellingValue: _.sumBy(pondsResults, "stats.optimalHarvestSellingValue"),
      optimalHarvestCost: _.sumBy(pondsResults, "stats.optimalHarvestCost"),
      plannedHarvestNetProfit: _.sumBy(pondsResults, "stats.plannedHarvestNetProfit"),
      plannedHarvestNetProfitDiff: _.sumBy(pondsResults, "stats.plannedHarvestNetProfitDiff"),
      plannedHarvestSellingValue: _.sumBy(pondsResults, "stats.plannedHarvestSellingValue"),
      plannedHarvestSellingValueDiff: _.sumBy(pondsResults, "stats.plannedHarvestSellingValueDiff"),
      plannedHarvestCost: _.sumBy(pondsResults, "stats.plannedHarvestCost"),
      plannedHarvestCostDiff: _.sumBy(pondsResults, "stats.plannedHarvestCostDiff"),
    };
    setPondsResults(pondsResults);
    setPondsStats(pondsResultsAgg);
  }, [forecastResult, dynamicFormValues, pondOverrideValues, shrimpUnitPrices]);

  const Actions = {
    toggleMenu: () => {
      setShowForm(!showForm);
    },
    fetchResult: async (formValues) => {
      setLoading(true);
      const formData = ponds.map((p) => ({
        pond_id: p.id,
        initial_weight: formValues.initialWeight,
        seeded_population: p.area * 10000 * formValues.seededDensity,
        seeded_date: formValues.seededDate.format("YYYY-MM-DD"),
        ahead: Math.floor(formValues?.ahead),
        mortality: formValues.mortality,
        day_of_mortality: formValues.dayOfMortality,
        growth_rate_deduction_ratio: formValues.growth_rate_deduction_ratio,
        day_of_growth_rate_deduction_ratio: formValues.day_of_growth_rate_deduction_ratio,
      }));
      const result = await PondManagerServices.fetchScenarioResult(formData);
      setLoading(false);
      setForecastResult(result);
    },
    onSelectPondId: (pondId) => {
      setPondId(pondId);
    },
    onUpdatePondOverrideValues: ({ key, value, isReset }) => {
      if (isReset) {
        let pondOverrideValues_ = structuredClone(pondOverrideValues);
        if (pondOverrideValues_[selectedPondId] && pondOverrideValues_[selectedPondId][key]) {
          pondOverrideValues_[selectedPondId][key] = undefined;
        }
        setPondOverrideValues(pondOverrideValues_);
      }
      if (selectedPondId && key && value !== undefined) {
        let pondOverrideValues_ = structuredClone(pondOverrideValues);
        if (!pondOverrideValues_[selectedPondId]) {
          pondOverrideValues_[selectedPondId] = {};
        }
        pondOverrideValues_[selectedPondId][key] = value;
        setPondOverrideValues(pondOverrideValues_);
      }
    },
    onFetchScenarioSnapshots: async () => {
      const result = viewAsOrg
        ? await PondManagerServices.fetchOrgScenarioSnapshots({ farmid: scenario.farm_id, scenarioid: scenario.id, orgid: orgid })
        : await PondManagerServices.fetchFarmScenarioSnapshots({ farmid: scenario.farm_id, scenarioid: scenario.id });
      if (result.size > 0) {
        setScenarioSnapshots(result.rows);
        const currentSnapshot = result.rows[0];
        setFormValues({
          ...currentSnapshot.payload.formValues,
          seededDate: moment(currentSnapshot.payload.formValues.seededDate),
        });
        setDynamicFormValues(currentSnapshot.payload.dynamicFormValues);
        setPondOverrideValues(currentSnapshot.payload.pondOverrideValues);
        setTimeout(() => {
          submitButtonRef.current?.click();
        }, 500);
      }
    },
    onDeleteSceanrioSnapshot: async (snapshotid) => {
      if (viewAsOrg) {
        await PondManagerServices.deleteOrgSceanrioSnapshot({ farmid: scenario.farm_id, orgid: orgid, scenarioid: scenario.id, snapshotid: snapshotid });
      } else {
        await PondManagerServices.deleteFarmSceanrioSnapshot({ farmid: scenario.farm_id, scenarioid: scenario.id, snapshotid: snapshotid });
      }
      Actions.onFetchScenarioSnapshots();
      alertsActions.addInfo(dispatch, { content: getText("interface.alert.alert-success") });
    },
    handleSaveResult: async () => {
      const snapshot = {
        formValues,
        dynamicFormValues,
        pondOverrideValues,
        shrimpUnitPrices,
        result: {
          farm: {
            stats: financialResults?.stats,
          },
        },
      };

      // console.log(forecastResult, financialResults, pondsResults, pondsStats);

      if (viewAsOrg) {
        await PondManagerServices.saveOrgScenarioSnapshot({
          farmid: scenario["farm_id"],
          scenarioid: scenario["id"],
          orgid: orgid,
          form: snapshot,
        });
      } else {
        await PondManagerServices.saveFarmScenarioSnapshot({
          farmid: scenario["farm_id"],
          scenarioid: scenario["id"],
          form: snapshot,
        });
      }

      alertsActions.addInfo(dispatch, { content: getText("interface.alert.alert-success") });
    },
    handleRun: () => {
      submitButtonRef.current?.click();
    },
  };

  const FormPart = () => {
    const [showInfo, setShowInfo] = useState(null);

    return (
      <Box bgcolor={palette.primary.light}>
        <Box
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 99,
            borderBottom: "1px solid #CCC",
          }}
          bgcolor={palette.primary.light}
          p={1}
          px={2}
        >
          <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
            <Box>
              <Typography>{getText("interface.general.scenario-builder")}</Typography>
            </Box>
            <Stack direction={"row"} spacing={1} justifyContent={"flex-end"}>
              <MuiBorderIconButton icon={<MenuOpenIcon />} onClick={Actions.toggleMenu} />
              <MuiBorderIconButton icon={<SaveIcon />} onClick={Actions.handleSaveResult} disabled={!forecastResult} />
              <MuiBorderIconButton icon={<InfoIcon />} onClick={() => setShowInfo(showInfo ? null : scenario)} />
              <MuiBorderIconButton icon={loading ? <CircularProgress size={20} color="grey" /> : <PlayArrowIcon />} onClick={Actions.handleRun} />
            </Stack>
          </Stack>
          {showInfo && (
            <Stack spacing={1} my={2} p={2} sx={{ bgcolor: "#FFF", borderRadius: 1 }}>
              <ScenarioDetails scenario={scenario} snapshots={scenarioSnapshots} actions={Actions} />
            </Stack>
          )}
        </Box>
        <Box p={2}>
          <Formik
            initialValues={formValues}
            onSubmit={(values) => {
              setFormValues(values);
              Actions.fetchResult(values);
            }}
          >
            {({ values, handleChange, setFieldValue }) => {
              return (
                <Form>
                  <Grid container spacing={1} alignItems={"center"}>
                    <Grid item xs={12}>
                      <Typography variant="inputLabel">
                        <Text>interface.general.forecast-range</Text> ( <Text>interface.time.days</Text> )
                      </Typography>
                      <TextField
                        inputProps={{
                          step: 1,
                        }}
                        fullWidth
                        name="ahead"
                        type="number"
                        onChange={handleChange}
                        size="small"
                        value={values.ahead}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="inputLabel">
                        <Text>interface.general.seeded-date</Text>
                      </Typography>
                      <MuiDatePicker
                        size="small"
                        value={values.seededDate}
                        onChange={(newV) => {
                          setFieldValue("seededDate", newV);
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="inputLabel">
                        <Text>interface.general.initial-weight</Text> (g)
                      </Typography>
                      <TextField step="any" fullWidth name="initialWeight" type="number" onChange={handleChange} size="small" value={values.initialWeight} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="inputLabel">
                        <Text>interface.general.seeded-density</Text>
                        (shrimps/m2)
                      </Typography>
                      <TextField step="any" fullWidth name="seededDensity" type="number" onChange={handleChange} size="small" value={values.seededDensity} />
                    </Grid>

                    <Grid item xs={12}>
                      <Box my={1}>
                        <Typography
                          fontSize={13}
                          fontWeight={800}
                          color="secondary"
                          sx={{
                            textDecoration: "underline",
                          }}
                        >
                          <Text>interface.general.advanced-settings</Text>
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="inputLabel">Mortality (0-1)</Typography>
                      <TextField
                        inputProps={{
                          step: "0.01",
                          min: -1,
                          max: 1,
                        }}
                        fullWidth
                        name="mortality"
                        type="number"
                        onChange={handleChange}
                        size="small"
                        value={values.mortality}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="inputLabel">Mortality (Day)</Typography>
                      <TextField
                        inputProps={{
                          step: "1",
                          min: 0,
                        }}
                        fullWidth
                        name="dayOfMortality"
                        type="number"
                        onChange={handleChange}
                        size="small"
                        value={values.dayOfMortality}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="inputLabel">Growth Rate Deduction Ratio (0-1)</Typography>
                      <TextField
                        inputProps={{
                          step: "0.01",
                          min: -1,
                          max: 1,
                        }}
                        fullWidth
                        name="growth_rate_deduction_ratio"
                        type="number"
                        onChange={handleChange}
                        size="small"
                        value={values.growth_rate_deduction_ratio}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="inputLabel">Growth Rate Deduction Ratio (Day)</Typography>
                      <TextField
                        inputProps={{
                          step: "1",
                          min: 0,
                        }}
                        fullWidth
                        name="day_of_growth_rate_deduction_ratio"
                        type="number"
                        onChange={handleChange}
                        size="small"
                        value={values.day_of_growth_rate_deduction_ratio}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="inputLabel">Cost of Opportunity (0-1)</Typography>
                      <TextField
                        inputProps={{
                          step: "0.01",
                          min: -1,
                          max: 1,
                        }}
                        fullWidth
                        name="cost_of_opportunity"
                        type="number"
                        onChange={handleChange}
                        size="small"
                        value={values.cost_of_opportunity}
                      />
                    </Grid>
                  </Grid>
                  <Box mt={2} display={"none"}>
                    <Button ref={submitButtonRef} fullWidth type="submit" variant="contained" startIcon={loading && <CircularProgress size={20} sx={{ color: "#FFF" }} />}>
                      <Text>interface.actions.submit</Text>
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>

          <Box my={2}>
            <Typography
              fontSize={13}
              fontWeight={800}
              color="secondary"
              sx={{
                textDecoration: "underline",
              }}
            >
              <Text>interface.general.dynamic-parameters</Text>
            </Typography>
          </Box>

          <Formik
            initialValues={dynamicFormValues}
            onSubmit={(values) => {
              setDynamicFormValues(values);
            }}
          >
            {({ values, handleChange, setFieldValue }) => {
              return (
                <Form>
                  <Grid container spacing={1}>
                    {/* Costs */}
                    <Grid item xs={12}>
                      <Typography variant="inputLabel">Labour Costs ($/month)</Typography>
                      <TextField step="any" fullWidth name="labourFixedCosts" type="number" onChange={handleChange} size="small" value={values.labourFixedCosts} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="inputLabel">Admin Costs ($/month)</Typography>
                      <TextField step="any" fullWidth name="adminFixedCosts" type="number" onChange={handleChange} size="small" value={values.adminFixedCosts} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="inputLabel">Other Costs ($/month)</Typography>
                      <TextField step="any" fullWidth name="otherFixedCosts" type="number" onChange={handleChange} size="small" value={values.otherFixedCosts} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="inputLabel">Feed Costs ($/kg)</Typography>
                      <TextField step="any" fullWidth name="feedVarCosts" type="number" onChange={handleChange} size="small" value={values.feedVarCosts} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="inputLabel">Energy Costs ($/month)</Typography>
                      <TextField step="any" fullWidth name="energyVarCosts" type="number" onChange={handleChange} size="small" value={values.energyVarCosts} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="inputLabel">Seed Costs ($/1000 PL)</Typography>
                      <TextField step="any" fullWidth name="seedVarCosts" type="number" onChange={handleChange} size="small" value={values.seedVarCosts} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="inputLabel">Selling Price ($/kg)</Typography>
                      <TextField step="any" fullWidth name="sellingPrice" type="number" onChange={handleChange} size="small" value={values.sellingPrice} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="inputLabel">Total Feed Stock (tons)</Typography>
                      <TextField step="any" fullWidth name="totalFeedStock" type="number" onChange={handleChange} size="small" value={values.totalFeedStock} />
                    </Grid>
                    <Grid item xs={12}>
                      <Button type="submit" variant="contained" fullWidth>
                        <Text>interface.actions.update</Text>
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
          <Box my={2}>
            <Divider />
          </Box>
          <FarmShrimpUnitPricesForm farmId={selectedFarm?.farm_id} />
        </Box>
      </Box>
    );
  };

  return (
    <Grid mt={1} container sx={{ height: "calc(100vh - 260px)", border: "1px solid #eee", borderRadius: 1, overflow: "hidden" }}>
      <Grid
        item
        xs={showForm ? 3 : 0}
        sx={{
          height: "100%",
          overflowY: "auto",
          borderRight: "1px solid #CCC",
          display: showForm ? "block" : "none",
        }}
      >
        <FormPart />
      </Grid>
      <Grid
        item
        xs={showForm ? 9 : 12}
        sx={{
          height: "100%",
          overflow: "auto",
        }}
      >
        <Box>
          {!showForm && (
            <Box p={1}>
              <MuiBorderIconButton icon={<MenuIcon />} onClick={Actions.toggleMenu} />
            </Box>
          )}
          <Box>
            <MuiTabs
              options={[
                { label: getText("interface.general.farm-scenario"), value: ScenarioBuilderModes.farm },
                { label: getText("interface.general.pond-scenario"), value: ScenarioBuilderModes.pond },
              ]}
              value={sbMode}
              onNewValue={(val) => setSbMode(val)}
              withBorder
            />
          </Box>
          {sbMode === ScenarioBuilderModes.farm && forecastResult && financialResults && (
            <FarmResultCharts forecastResult={forecastResult} financialResults={financialResults} dynamicFormValues={dynamicFormValues} ponds={ponds} />
          )}
          {sbMode === ScenarioBuilderModes.pond && forecastResult && pondsResults && pondsStats && (
            <PondResults pondId={selectedPondId} ponds={ponds} pondOverrideValues={pondOverrideValues} pondsResults={pondsResults} pondsStats={pondsStats} actions={Actions} />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ScenarioBuilderInterface;
