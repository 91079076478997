import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Text from "components/text/Text";
import React from "react";
import { useDispatch } from "react-redux";
import { AppSelectors } from "redux/AppReducers";
import { pondManagementActions } from "redux/pondManagement/pondManagement";

/**
 *
 * Group Selector
 *
 * @returns
 */
const GroupSelection = () => {
  const dispatch = useDispatch();

  const pondmanagementStore = AppSelectors.pondManagementStore();
  const { groups, ponds, selectedGroup } = pondmanagementStore;

  return (
    <FormControl sx={{ minWidth: 150 }} size="small">
      <InputLabel id="selectedGroupLabel">
        <Text>interface.general.group</Text>
      </InputLabel>
      <Select
        label={<Text>interface.general.group</Text>}
        labelId="selectedGroupLabel"
        id="selectedGroup"
        value={selectedGroup}
        onChange={(e) => pondManagementActions.selectGroup(dispatch, { group: e.target.value })}
      >
        {[{ label: "- All -", value: null }, ...groups.map((f) => ({ label: f, value: f }))].map((item, idx) => (
          <MenuItem key={idx} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default GroupSelection;
