import { FaSoundcloud, FaRegNewspaper } from "react-icons/fa";
import { FaIcicles } from "react-icons/fa6";
import { PiSnowflakeThin, PiCloudSnowFill } from "react-icons/pi";
import {
  WiCloud,
  WiDayRain,
  WiDaySunny,
  WiDust,
  WiFlood,
  WiGaleWarning,
  WiHumidity,
  WiRain,
  WiRaindrop,
  WiSleet,
  WiSnow,
  WiSnowflakeCold,
  WiThermometer,
  WiThermometerExterior,
  WiWindBeaufort10,
  WiWindy,
} from "react-icons/wi";

const climateTypeToIconMap = {
  cloudBase: WiCloud,
  cloudCeiling: FaSoundcloud,
  cloudCover: WiCloud,
  dewPoint: WiRaindrop,
  freezingRainIntensity: FaIcicles,
  humidity: WiHumidity,
  iceAccumulation: PiCloudSnowFill,
  iceAccumulationLwe: PiSnowflakeThin,
  precipitationProbability: WiRain,
  pressureSurfaceLevel: WiFlood,
  rainAccumulationLwe: WiDayRain,
  rainIntensity: WiDayRain,
  sleetAccumulation: WiSleet,
  sleetAccumulationLwe: WiSleet,
  sleetIntensity: WiSnow,
  snowAccumulationLwe: WiSnowflakeCold,
  snowDepth: WiSnow,
  snowIntensity: WiSnow,
  temperature: WiThermometer,
  temperatureApparent: WiThermometerExterior,
  uvHealthConcern: WiDaySunny,
  uvIndex: WiDaySunny,
  visibility: WiDust,
  weatherCode: FaRegNewspaper,
  windDirection: WiGaleWarning,
  windGust: WiWindy,
  windSpeed: WiWindBeaufort10,
};

const ClimateIconWrapper = ({ climateType }) => {
  const WeatherIcon = climateTypeToIconMap[climateType] || null;

  if (!WeatherIcon) {
    console.error("Invalid climateType:", climateType);
    return null;
  }

  return <WeatherIcon style={{ width: "30px", height: "30px" }} />;
};

export default ClimateIconWrapper;
