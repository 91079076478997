import { Box, Stack, Tooltip, Typography } from "@mui/material";
import Color from "color";
import React from "react";
import palette from "themes/palette";

/**
 *
 * UI Component
 * ------------
 *
 * A styled "Tag" like UI Component
 *
 * @param {*} param0
 * @returns
 */
const StyledTag = ({ label, tooltip, icon, color = palette.primary.main, revert = false }) => {
  const colorFront = revert ? "#FFF" : color;
  const colorBack = revert ? color : Color(color).alpha(0.1).rgb().string();

  return (
    <Tooltip title={tooltip || label} placement="top">
      <Box
        sx={{
          borderRadius: 2,
          bgcolor: colorBack,
          svg: {
            fontSize: 12,
          },
          px: 0.8,
          py: 0.2,
        }}
      >
        <Stack
          direction={"row"}
          spacing={0.3}
          alignItems={"center"}
          sx={{
            height: 16,
            color: colorFront,
          }}
        >
          {icon && <Box>{icon}</Box>}
          <Typography fontSize={10} fontWeight={800}>
            {label}
          </Typography>
        </Stack>
      </Box>
    </Tooltip>
  );
};

export default StyledTag;
