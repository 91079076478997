import { Box, Stack, Typography } from "@mui/material";
import { FileUploadIcon } from "components/Icons/MaterialIcons";
import React, { useRef, useState } from "react";
import palette from "themes/palette";

const FileInputWrapper = ({ id, name, onChange, accept, buttonLabel }) => {
  const [filePreview, setFilePreview] = useState(null);

  const inputRef = useRef(null);

  const handleFileChange = (e) => {
    setFilePreview(e.target.files[0]);
    onChange(e);
  };

  const handleInputClick = (e) => {
    e.preventDefault();
    inputRef.current.click();
  };

  return (
    <Box>
      <input id={id} name={name} type="file" ref={inputRef} onChange={handleFileChange} accept={accept} hidden />
      <Stack
        sx={{
          borderRadius: 4,
          border: "1px solid #ddd",
          overflow: "hidden",
          transition: "all .3s",
          p: 0.5,
          px: 1,
          "&:hover": {
            cursor: "pointer",
            background: "#DDD",
            borderColor: palette.primary.main,
            ".logo-text": {
              color: palette.primary.main,
            },
          },
        }}
        onClick={handleInputClick}
        justifyContent={"center"}
        alignItems={"center"}
        direction={"row"}
        spacing={0.2}
      >
        <FileUploadIcon />
        <Typography fontWeight={800} fontSize={13}>
          {filePreview ? filePreview?.name : buttonLabel || "Select a file"}
        </Typography>
      </Stack>
    </Box>
  );
};

export default FileInputWrapper;
