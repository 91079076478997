import { VerticalAlertBoxCollections } from "components/Alerts/AlertBox";
import _ from "lodash";
import React from "react";

const validatePolicy = ({ target, allowed, always }) => {
  if (always) return true;
  if (_.includes(allowed, target)) {
    return true;
  }
  return false;
};

const AccessWrapper = ({ target, allowed, always, hideOnly, children }) => {
  const validated = validatePolicy({ target, allowed, always });

  if (!validated) {
    if (!hideOnly) {
      return <VerticalAlertBoxCollections.NoAccess />;
    }
    return "";
  }

  return children;
};

export default AccessWrapper;
