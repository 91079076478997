import { Box, Card, Chip, Divider, Stack, Typography, colors } from "@mui/material";
import { AspectRatioIcon, Grid3x3Icon } from "components/Icons/MaterialIcons";
import Text from "components/text/Text";
import NumberFormat from "react-number-format";
import { useParams } from "react-router-dom";
import { AppSelectors } from "redux/AppReducers";
import OrgBadge from "screens/Aquaculture/Organisations/components/OrgBadge";

const OrganisationWelcome = () => {
  const { farmid, orgid } = useParams();
  const orgManagementStore = AppSelectors.orgStore();
  const org = _.find(orgManagementStore.userOrgs, { "org.id": orgid });
  const farm = _.find(orgManagementStore.viewFarms, { farm_id: farmid });

  return (
    <Box p={2}>
      <Stack direction={"row"} spacing={2} alignItems={"center"}>
        <Box>
          <OrgBadge org={org} />
        </Box>

        <Card>
          <Box p={2}>
            <Typography fontSize={12} color={colors.grey[500]}>
              {farm?.country}
            </Typography>
            <Typography fontWeight={"bold"} fontSize={15}>
              {farm?.label}
            </Typography>
            <Stack direction="row" spacing={1} mt={1}>
              <Chip icon={<AspectRatioIcon />} label={<NumberFormat displayType="text" value={farm?.total_area} suffix=" ha." decimalScale={1} />} size="small" />
              <Chip icon={<Grid3x3Icon />} label={<NumberFormat displayType="text" value={farm?.total_ponds} suffix=" pts." decimalScale={1} />} size="small" />
            </Stack>
            <Divider sx={{ my: 1 }} />
            <Typography fontSize={12}>
              <Text>interface.general.contact-name</Text>: {farm?.contact_name || "-"}
            </Typography>
            <Typography fontSize={12}>
              <Text>interface.general.contact-email</Text>: {farm?.contact_email || "-"}
            </Typography>
            <Typography fontSize={12}>
              <Text>interface.general.contact-number</Text>: {farm?.contact_number || "-"}
            </Typography>
          </Box>
        </Card>
      </Stack>
    </Box>
  );
};

export default OrganisationWelcome;
