import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import PlatformLanguages from "constants/PlatformLanguages";

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: PlatformLanguages.map(({ code }) => code),
    nsSeparator: "|",
    fallbackLng: "en",
    debug: false,
    detection: {
      order: ["localStorage", "htmlTag"],
      caches: ["localStorage"],
    },
    backend: {
      expirationTime: 365 * 24 * 60 * 60 * 1000,
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
    react: {
      wait: true,
      useSuspense: false,
    },
  });
