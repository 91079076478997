import _ from "lodash";
import React from "react";
import { AppSelectors } from "redux/AppReducers";
import DataExplorerSummary from "screens/Aquaculture/components/DataExplorer/DataExplorerSummary";

export const PondDataSummaryWidget = ({ pond, cycle }) => {
  if (_.isEmpty(cycle) || !pond) {
    return "";
  }

  return <DataExplorerSummary pond={pond} cycle={cycle} />;
};
