import MuiDatetimePicker from "components/DatePicker/MuiDatetimePicker";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Button, Stack } from "@mui/material";
import moment from "moment";
import { PondManagerServices } from "api/pondManagerServices";
import { formValidationBeforeSubmit } from "screens/Aquaculture/components/CycleManager/CycleEditPanel";
import { alertsActions } from "redux/alerts";
import Text from "components/text/Text";
import MuiDatePicker from "components/DatePicker/MuiDatePicker";

const CycleEndForm = ({ cycle, onExit }) => {
  const [confirmed, setConfirmed] = useState(false);

  const dispatch = useDispatch();

  const Actions = {
    onEndCycle: async (values) => {
      const cycleNew = {
        pond_id: cycle.pond_id,
        start_date: cycle.start_date,
        end_date: values.end_date.format("YYYY-MM-DD"),
        id: cycle.id,
        label: cycle.label,
        ended: true,
      };
      const formValidated = formValidationBeforeSubmit(cycleNew);
      if (formValidated.error) {
        alertsActions.addError(dispatch, {
          content: formValidated.message,
        });
        return;
      }
      await PondManagerServices.updateCycle(cycleNew);
      onExit();
    },
  };

  if (!confirmed) {
    return (
      <Box>
        <Button size="large" variant="contained" color="error" onClick={() => setConfirmed(true)}>
          <Text>interface.actions.end-this-cycle</Text>
        </Button>
      </Box>
    );
  }

  return (
    <Formik
      {...{
        initialValues: {
          end_date: moment(),
        },
        onSubmit: (values) => {
          Actions.onEndCycle(values);
        },
      }}
    >
      {({ values, handleChange, setFieldValue }) => {
        return (
          <Form>
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <MuiDatePicker
                name="end_date"
                value={values.end_date}
                onAccept={(newValue) => {
                  setFieldValue("end_date", newValue);
                }}
                onChange={(newValue) => {
                  setFieldValue("end_date", newValue);
                }}
                size="small"
              />
              <Button onClick={() => setConfirmed(false)} color="error">
                <Text>interface.actions.cancel</Text>
              </Button>
              <Button type="submit" variant="contained">
                <Text>interface.actions.confirm</Text>
              </Button>
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CycleEndForm;
