import { Box, Button, Grid, IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { UpdateIcon } from "components/Icons/MaterialIcons";
import Text from "components/text/Text";
import { Form, Formik } from "formik";
import { getShrimpSizeFromCategory } from "helpers/Aqua";
import TimeFormatters from "helpers/TimeFormatters";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { AppSelectors } from "redux/AppReducers";
import { pondManagementActions } from "redux/pondManagement/pondManagement";

const FarmShrimpUnitPricesForm = ({ farmId }) => {
  let farmid = useParams()?.["farmid"] || farmId;

  const pondManagementStore = AppSelectors.pondManagementStore();
  const { shrimpUnitPrices } = pondManagementStore;

  const dispatch = useDispatch();

  const { orgId } = useParams();
  const contentDisplayConfigs = {
    writable: orgId ? false : true,
  };

  const Actions = {
    getPrices: async () => {
      farmid && pondManagementActions.loadShrmipUnitPrices(dispatch, farmid);
    },
    addPrices: async (formValues) => {
      await pondManagementActions.saveShrimpUnitPrices(dispatch, farmid, formValues);
    },
  };

  return (
    <Formik
      initialValues={{
        ...shrimpUnitPrices,
      }}
      enableReinitialize
      onSubmit={Actions.addPrices}
    >
      {({ values, handleChange }) => {
        return (
          <Form>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <Typography textTransform={"uppercase"} fontWeight={"bold"}>
                <Text>interface.general.shrimp-unit-price</Text>
              </Typography>
              <IconButton onClick={Actions.getPrices}>
                <UpdateIcon />
              </IconButton>
            </Stack>
            <Box mb={2}>
              <Typography color={"#777"} fontSize={11}>
                {TimeFormatters.formatUtcDatetime(shrimpUnitPrices?._meta?.created)}
              </Typography>
            </Box>
            <Grid container spacing={1}>
              {["91-110", "71-90", "61-70", "51/60", "41/50", "36/40", "31/35", "26/30", "21/25", "16/20", "U15", "U12", "U10"].map((ele, idx) => (
                <Grid item key={idx} xs={12} lg={6}>
                  <TextField
                    label={`${ele} (${getShrimpSizeFromCategory(ele)})`}
                    InputProps={{
                      endAdornment: <InputAdornment position="start">$/kg</InputAdornment>,
                    }}
                    inputProps={{
                      step: "any",
                    }}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    size="small"
                    name={ele}
                    type="number"
                    value={values?.[ele]}
                    fullWidth
                    onChange={handleChange}
                    required
                    step={"any"}
                    disabled={!contentDisplayConfigs.writable}
                  />
                </Grid>
              ))}
            </Grid>
            {contentDisplayConfigs.writable && (
              <Box mt={2}>
                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                  <Button type="submit" variant="contained" fullWidth>
                    <Text>interface.actions.update</Text>
                  </Button>
                </Stack>
              </Box>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default FarmShrimpUnitPricesForm;
