import { ChevronRightSharp } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography, colors } from "@mui/material";
import { PondManagerServices } from "api/pondManagerServices";
import { VerticalAlertBoxCollections } from "components/Alerts/AlertBox";
import HighchartsWrapper from "components/Highcharts/HighchartsWrapper";
import { ArrowBackIcon } from "components/Icons/MaterialIcons";
import Text, { getText } from "components/text/Text";
import { getColorByFieldId } from "helpers/ColorHelpers";
import { formatValueByFieldId } from "helpers/NumericFormatters";
import TimeFormatters from "helpers/TimeFormatters";
import _ from "lodash";
import { useEffect, useState } from "react";
import { AppSelectors } from "redux/AppReducers";
import MobilePondViewDataUploader from "screens/Mobile/PondManager/components/PondView/DataManager/MobilePondViewDataUploader";
import MobileWidgetCard from "screens/Mobile/components/ui/MobileWidgetCard";
import palette from "themes/palette";

const DataCard = ({ data, field, onSelect }) => {
  if (!data) return {};
  const fieldId = field.field_id;
  const fieldName = getText(`fields.${fieldId}`);
  const fieldUnit = field.field_unit;

  const dataToDisplay = _.takeRight(
    data.filter((ele) => ele?.[fieldId]),
    30
  );

  const color = getColorByFieldId(fieldId);

  const latestValue = formatValueByFieldId(_.last(dataToDisplay)?.[fieldId], fieldId);
  const latestValueDt = _.last(dataToDisplay)?.datetime;

  const shouldChartDisplay = dataToDisplay.length > 1;

  const configs = {
    chart: {
      height: "80px",
      margin: [0, 0, 0, 0], // Removes all margins (top, right, bottom, left)
      spacing: [0, 0, 0, 0], // Removes all spacing around the chart
      animation: false,
    },
    xAxis: {
      type: "datetime",
      visible: false,
    },
    yAxis: {
      visible: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        animation: false,
      },
    },
    tooltip: {
      enabled: false,
    },
    series: [
      {
        name: fieldName,
        type: "column",
        data: dataToDisplay.map((ele, idx) => ({
          // x: TimeFormatters.convertUtcDatetimeStringToValue(ele.datetime),
          x: idx,
          y: ele?.[fieldId],
          color: idx === dataToDisplay.length - 1 ? color : colors.grey[300],
        })),
        borderRadius: 0,
      },
    ],
  };
  // console.log(configs);
  return (
    <MobileWidgetCard padding={0}>
      <Stack direction={"row"}>
        <Box flex={1} p={1.5}>
          <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} mb={1}>
            <Box>
              <Typography fontWeight={800} fontSize={15} color={color}>
                {fieldName} <span style={{ color: "#BBB", fontSize: 10 }}></span>
              </Typography>
            </Box>
          </Stack>

          <Stack direction={"row"} justifyContent={"space-between"} alignItems={"flex-end"}>
            <Box>
              {latestValue ? (
                <Typography fontSize={26} fontWeight={800}>
                  {latestValue}
                </Typography>
              ) : (
                <Typography fontSize={26} fontWeight={800} color="#CCC">
                  <Text>interface.alert.no-data</Text>
                </Typography>
              )}
              <Typography fontSize={10} fontWeight={800} color="grey">
                {latestValueDt && TimeFormatters.formatDatetime(latestValueDt)}
              </Typography>
            </Box>
            {shouldChartDisplay && (
              <Box width={150}>
                <HighchartsWrapper options={configs} />
              </Box>
            )}
          </Stack>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "30px",
          }}
        >
          {onSelect && (
            <Box onClick={() => onSelect(field)}>
              <ChevronRightSharp />
            </Box>
          )}
        </Box>
      </Stack>
    </MobileWidgetCard>
  );
};

const MobilePondViewDataManager = ({ pond }) => {
  const [data, setData] = useState(null);
  const [selectedField, setField] = useState(null);

  const cycleStore = AppSelectors.cycleStore();
  const pondmanagementStore = AppSelectors.pondManagementStore();
  const { fields } = pondmanagementStore;

  const selectedCycle = cycleStore?.selectedCycle?.id ? cycleStore?.selectedCycle : cycleStore?.latestCycles?.[pond?.id];

  useEffect(() => {
    if (pond?.id === selectedCycle?.pond_id || !selectedField) {
      if (_.isEmpty(selectedCycle)) {
        return;
      }
      Actions.loadData();
    }
  }, [pond?.id, selectedCycle?.id]);

  const Actions = {
    loadData: async () => {
      const response = await PondManagerServices.getTimeseriesData(
        pond?.id,
        [
          "weight",
          "current_density",
          "organisms",
          "biomass",
          "survival_rate",
          "harvest_biomass",
          "harvest_biomass_accumulated",
          // wq
          "ph",
          "water_temp",
          "dissolved_oxygen",
          "average_depth",
          "water_exchange",
          "turbidity",
          "water_exchange",
          "ammonia",
          "alkalinity",
          "calcium",
          "magnesium",
          "carbon_dioxide",
          "chlorophyll_a",
          "phosphorus",
          "suspended_solids",
          "ammonium",
          // feed
          "feed_daily",
          "feed_provided",
          "feed_accumulated",
          "fcr",
          "fcr_full_cycle",
        ],
        selectedCycle?.start_date,
        selectedCycle?.end_date
      );
      setData(response.rows);
    },
    onSelectField: (field) => {
      setField(field);
    },
    onUnselectField: () => {
      setField(null);
      Actions.loadData();
    },
  };

  if (_.isEmpty(selectedCycle)) {
    return <VerticalAlertBoxCollections.NoCycleSelected />;
  }

  if (selectedField) {
    return (
      <Box p={1}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <IconButton onClick={Actions.onUnselectField} size="small">
            <ArrowBackIcon />
          </IconButton>
        </Stack>
        <Box>
          <MobilePondViewDataUploader pond={pond} field={selectedField} />
        </Box>
      </Box>
    );
  }

  const SectionTitle = ({ children }) => {
    return (
      <Box
        sx={{
          pt: 2,
          px: 0,
        }}
      >
        <Typography fontWeight={800} fontSize={20}>
          {children}
        </Typography>
      </Box>
    );
  };

  return (
    <Box>
      {data && (
        <Stack spacing={1} p={1}>
          <SectionTitle>
            <Text>interface.general.biological</Text>
          </SectionTitle>
          <DataCard {...{ data, onSelect: Actions.onSelectField, field: _.find(fields, { field_id: "weight" }) }} />
          <DataCard {...{ data, onSelect: Actions.onSelectField, field: _.find(fields, { field_id: "current_density" }) }} />
          <DataCard {...{ data, onSelect: Actions.onSelectField, field: _.find(fields, { field_id: "organisms" }) }} />
          <DataCard {...{ data, onSelect: Actions.onSelectField, field: _.find(fields, { field_id: "biomass" }) }} />
          <DataCard {...{ data, onSelect: Actions.onSelectField, field: _.find(fields, { field_id: "survival_rate" }) }} />
          <SectionTitle>
            <Text>interface.general.harvest</Text>
          </SectionTitle>
          <DataCard {...{ data, onSelect: Actions.onSelectField, field: _.find(fields, { field_id: "harvest_biomass" }) }} />
          <DataCard {...{ data, onSelect: Actions.onSelectField, field: _.find(fields, { field_id: "harvest_biomass_accumulated" }) }} />
          <SectionTitle>
            <Text>interface.general.feed</Text>
          </SectionTitle>
          <DataCard {...{ data, onSelect: Actions.onSelectField, field: _.find(fields, { field_id: "feed_daily" }) }} />
          <DataCard {...{ data, onSelect: Actions.onSelectField, field: _.find(fields, { field_id: "feed_accumulated" }) }} />
          <DataCard {...{ data, onSelect: Actions.onSelectField, field: _.find(fields, { field_id: "feed_provided" }) }} />
          <DataCard {...{ data, onSelect: Actions.onSelectField, field: _.find(fields, { field_id: "fcr" }) }} />
          <DataCard {...{ data, onSelect: Actions.onSelectField, field: _.find(fields, { field_id: "fcr_full_cycle" }) }} />
          <SectionTitle>
            <Text>interface.general.water-quality</Text>
          </SectionTitle>
          <DataCard {...{ data, onSelect: Actions.onSelectField, field: _.find(fields, { field_id: "ph" }) }} />
          <DataCard {...{ data, onSelect: Actions.onSelectField, field: _.find(fields, { field_id: "water_temp" }) }} />
          <DataCard {...{ data, onSelect: Actions.onSelectField, field: _.find(fields, { field_id: "dissolved_oxygen" }) }} />
          <DataCard {...{ data, onSelect: Actions.onSelectField, field: _.find(fields, { field_id: "average_depth" }) }} />
          <DataCard {...{ data, onSelect: Actions.onSelectField, field: _.find(fields, { field_id: "turbidity" }) }} />
          <DataCard {...{ data, onSelect: Actions.onSelectField, field: _.find(fields, { field_id: "water_exchange" }) }} />
          <DataCard {...{ data, onSelect: Actions.onSelectField, field: _.find(fields, { field_id: "ammonia" }) }} />
          <DataCard {...{ data, onSelect: Actions.onSelectField, field: _.find(fields, { field_id: "alkalinity" }) }} />
          <DataCard {...{ data, onSelect: Actions.onSelectField, field: _.find(fields, { field_id: "calcium" }) }} />
          <DataCard {...{ data, onSelect: Actions.onSelectField, field: _.find(fields, { field_id: "magnesium" }) }} />
          <DataCard {...{ data, onSelect: Actions.onSelectField, field: _.find(fields, { field_id: "carbon_dioxide" }) }} />
          <DataCard {...{ data, onSelect: Actions.onSelectField, field: _.find(fields, { field_id: "chlorophyll_a" }) }} />
          <DataCard {...{ data, onSelect: Actions.onSelectField, field: _.find(fields, { field_id: "phosphorus" }) }} />
          <DataCard {...{ data, onSelect: Actions.onSelectField, field: _.find(fields, { field_id: "suspended_solids" }) }} />
          <DataCard {...{ data, onSelect: Actions.onSelectField, field: _.find(fields, { field_id: "ammonium" }) }} />
        </Stack>
      )}
    </Box>
  );
};

export default MobilePondViewDataManager;
