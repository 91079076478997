import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
  colors,
} from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { PondManagerServices } from "api/pondManagerServices";
import {
  ArrowForwardIcon,
  DeleteIcon,
  EditNoteIcon,
  PeopleIcon,
  RefreshIcon,
} from "components/Icons/MaterialIcons";
import Text, { getText } from "components/text/Text";
import { Form, Formik } from "formik";
import TimeFormatters from "helpers/TimeFormatters";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { alertsActions } from "redux/alerts";

const AddGroupMemberForm = ({
  org,
  group,
  allMembers,
  groupMembers,
  postSubmit,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedMemberIds, setMemberIds] = useState([]);
  const dispatch = useDispatch();

  const allMemberIds = allMembers
    .map((ele) => ele.id)
    .filter(
      (ele) =>
        !_.includes(
          groupMembers.map((ele) => ele.member_id),
          ele
        )
    );

  const Actions = {
    onSubmit: async (form) => {
      setLoading(true);
      form.newMembers.forEach(async (memberId) => {
        await PondManagerServices.addGroupMember(org.id, group.id, memberId);
      });
      postSubmit();
      setMemberIds([]);
      setLoading(false);
    },
  };

  return (
    <Formik
      initialValues={{
        newMembers: [],
      }}
      onSubmit={Actions.onSubmit}
    >
      {({ values, handleChange, setFieldValue }) => {
        return (
          <Form>
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <Box width={300}>
                <Autocomplete
                  size="small"
                  multiple
                  name="newMembers"
                  value={selectedMemberIds}
                  options={allMemberIds}
                  renderInput={(params) => (
                    <TextField size="small" {...params} label="Members" />
                  )}
                  onChange={(event, newValue) => {
                    setMemberIds(newValue);
                    setFieldValue("newMembers", newValue);
                  }}
                  renderOption={(props, option) => {
                    return (
                      <MenuItem
                        size="small"
                        sx={{
                          p: 0,
                        }}
                        {...props}
                      >
                        <Checkbox checked={props["aria-selected"]} />
                        {_.find(allMembers, { id: option })?.firstname}{" "}
                        {_.find(allMembers, { id: option })?.lastname}
                      </MenuItem>
                    );
                  }}
                  renderTags={(value) => {
                    return (
                      <Chip
                        size="small"
                        label={`${selectedMemberIds.length} selected`}
                      />
                    );
                  }}
                />
              </Box>
              <Box>
                <Button
                  type="submit"
                  variant="outlined"
                  startIcon={loading && <CircularProgress size={20} />}
                >
                  <Text>interface.actions.confirm</Text>
                </Button>
              </Box>
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
};

const OrganisationAdminGroupDetails = ({ org, group }) => {
  const [colDefs, setColDefs] = useState([
    {
      flex: 1,
      headerName: "name",
      cellRenderer: ({ data }) => `${data.firstname} ${data.lastname}`,
    },
    {
      flex: 1,
      headerName: "email",
      field: "email",
    },
    {
      flex: 1,
      headerName: "actions",
      field: "email",
      cellRenderer: ({ data }) => {
        return (
          <Box pt={0.4}>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <IconButton
                size="small"
                color="error"
                onClick={() => {
                  Actions.onRemoveGroupMember(data.member_id);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Stack>
          </Box>
        );
      },
    },
  ]);

  const [allMembers, setMembers] = useState([]);
  const [groupMembers, setGroupMembers] = useState([]);

  const dispatch = useDispatch();

  const Actions = {
    fetchGroupMembers: async () => {
      const groupMembers = await PondManagerServices.getGroupMembers(
        org.id,
        group.id
      );
      const orgMembers = await PondManagerServices.getMembers(org.id);
      setMembers(orgMembers);
      setGroupMembers(groupMembers.filter((ele) => ele.group_id === group.id));
    },
    onRemoveGroupMember: async (memberId) => {
      const confirmed = window.confirm(
        getText("interface.alert.alert-are-you-sure")
      );
      if (confirmed) {
        await PondManagerServices.removeGroupMember(org.id, group.id, memberId);
        alertsActions.addInfo(dispatch, {
          content: "Member is removed",
        });
        Actions.fetchGroupMembers();
      }
    },
  };

  useEffect(() => {
    Actions.fetchGroupMembers();
  }, [org]);

  return (
    <Box>
      <Box my={2}>
        <AddGroupMemberForm
          org={org}
          group={group}
          allMembers={allMembers}
          groupMembers={groupMembers}
          postSubmit={Actions.fetchGroupMembers}
        />
      </Box>
      <Box height={400} className="ag-theme-quartz">
        <AgGridReact
          columnDefs={colDefs}
          rowData={groupMembers}
          overlayNoRowsTemplate={"NO GROUP MEMBERS"}
        />
      </Box>
    </Box>
  );
};

export default OrganisationAdminGroupDetails;
