import { Avatar, Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { AppSelectors } from "redux/AppReducers";
import { PersonIcon } from "components/Icons/MaterialIcons";
import { Fade } from "react-reveal";
import { ShrimplLogoSvg } from "components/Images";
import MobileWidgetCard from "screens/Mobile/components/ui/MobileWidgetCard";
import { BiUser } from "react-icons/bi";
import Text from "components/text/Text";
import { useNavigate } from "react-router-dom";
import { ROUTE_POND_MANAGER_HOME_PAGE, ROUTE_USER_PROFILE } from "routes/paths";
import palette from "themes/palette";
import { GiShrimp } from "react-icons/gi";

const FunctionButton = ({ icon, text, onClick }) => {
  return (
    <Stack
      alignItems={"center"}
      onClick={() => onClick && onClick()}
      sx={{
        p: 1,
        borderRadius: 1,
        "&:active": {
          bgcolor: "#EEE",
        },
        color: palette.secondary.main,
      }}
    >
      <Typography sx={{ svg: { fontSize: 30 } }}>{icon}</Typography>
      <Typography textTransform={"uppercase"} letterSpacing={1} fontWeight={800} fontSize={10}>
        {text}
      </Typography>
    </Stack>
  );
};

const MobileHomePage = () => {
  // redux
  const userStore = AppSelectors.userStore();
  const navigate = useNavigate();

  const Actions = {
    onNavigateToUserProfile: () => {
      navigate(ROUTE_USER_PROFILE);
    },
    onNavigateToMyFarms: () => {
      navigate(ROUTE_POND_MANAGER_HOME_PAGE);
    },
  };

  return (
    <Box p={2}>
      <Stack spacing={2}>
        <Stack direction={"row"}>
          <Box>
            <img src={ShrimplLogoSvg} alt="" width={100} />
          </Box>
        </Stack>

        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} spacing={1}>
          <Fade left duration={500} distance={"40px"}>
            <Typography fontSize={20} lineHeight={1.2} color={"primary"}>
              Hi, <strong>{userStore.firstname}</strong>!
            </Typography>
          </Fade>
          <Fade top duration={0} distance={"40px"}>
            <Avatar
              onClick={Actions.onNavigateToUserProfile}
              sx={{
                width: 50,
                height: 50,
                border: "2px solid #aaa",
              }}
            >
              <PersonIcon sx={{ fontSize: 40 }} />
            </Avatar>
          </Fade>
        </Stack>

        <MobileWidgetCard>
          <Grid container>
            <Grid item xs={6}>
              <FunctionButton icon={<GiShrimp />} text={<Text>interface.general.my-farms</Text>} onClick={Actions.onNavigateToMyFarms} />
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mr: "-1px" }} />
            <Grid item xs={6}>
              <FunctionButton icon={<BiUser />} text={<Text>interface.general.profile</Text>} onClick={Actions.onNavigateToUserProfile} />
            </Grid>
          </Grid>
        </MobileWidgetCard>
      </Stack>
    </Box>
  );
};

export default MobileHomePage;
