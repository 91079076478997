import React from "react";
import { Link } from "react-router-dom";

/**
 * A styled Link wrapper
 *
 * example
 * -------
 *
 * ...
 * <StyledLink to="/home">Home</StyledLink>
 *
 *
 * @param {*} param0
 * @returns
 */
const StyledLink = ({ children, ...props }) => {
  return (
    <Link className="link-btn" {...props}>
      {children}
    </Link>
  );
};

export default StyledLink;
