const WhereDidYouHearAboutUs = [
  { label: "Facebook",    value: "facebook" },
  { label: "Linkedin",    value: "linkedin" },
  { label: "Twitter",     value: "twitter" },
  { label: "Google",      value: "google" },
  { label: "News",        value: "news" },
  { label: "Blogs",       value: "blogs" },
  { label: "Google",      value: "google" },
  { label: "Friend",      value: "friend" },
  { label: "Colleague",   value: "colleague" },
  { label: "Others",      value: "other" },
];

export default WhereDidYouHearAboutUs;