import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React from "react";

const MuiDatePicker = ({ label, value, onChange, onClose, onAccept, size, fullWidth, width, disabled, ...props }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        label={label}
        value={value}
        onChange={onChange}
        onClose={onClose}
        onAccept={onAccept}
        slotProps={{
          textField: { size, fullWidth, sx: { width } },
        }}
        format="YYYY-MM-DD"
        disabled={disabled}
        {...props}
      />
    </LocalizationProvider>
  );
};

export default MuiDatePicker;
