import { useEffect, useRef, useState } from "react";

import { Box, Stack, IconButton, Divider, TextField, BottomNavigation, BottomNavigationAction, Badge, Typography } from "@mui/material";
import { ListIcon, ScheduleSendIcon, SearchIcon, SendIcon } from "components/Icons/MaterialIcons";
import { Form, Formik } from "formik";
import { PondManagerServices } from "api/pondManagerServices";
import { VerticalAlertBoxCollections } from "components/Alerts/AlertBox";
import { AccessTime } from "@mui/icons-material";
import ActiveOrgsList from "screens/Aquaculture/components/OrgConnect/components/ActiveOrgsList";
import PendingSentOrgList from "screens/Aquaculture/components/OrgConnect/components/PendingSentOrgsList";
import PendingReceivedOrgsList from "screens/Aquaculture/components/OrgConnect/components/PendingReceivedOrgsList";
import OrgRow from "screens/Aquaculture/components/OrgConnect/components/OrgRow";
import { alertsActions } from "redux/alerts";
import { useDispatch } from "react-redux";

const OrgConnectForm = ({ farm, onExit }) => {
  const [orgsResult, setOrgsResults] = useState([]);
  const [connectResult, setConnectResult] = useState({});

  const [pageIndex, setPageIndex] = useState(2);

  const formRef = useRef();

  const dispatch = useDispatch();

  const Actions = {
    onSearchOrgs: async (keyword) => {
      const rsp = await PondManagerServices.searchOrgs({ keyword: keyword });
      setOrgsResults(rsp.rows);
    },
    onLoadConnectedOrgs: async () => {
      const rsp = await PondManagerServices.getConnectedOrgs({ farmid: farm.farm_id });
      setConnectResult(rsp);
    },
    onSendConnectionRequest: async ({ farmid, orgid }) => {
      const rsp = await PondManagerServices.sendConnectionRequestToOrg({ orgid, farmid });
      if (rsp?.error) {
        alertsActions.addError(dispatch, {
          content: rsp?.error,
        });
      } else {
        Actions.onLoadConnectedOrgs();
        alertsActions.addInfo(dispatch, {
          content: "Request sent!",
        });
        setPageIndex(3);
      }
    },
  };

  useEffect(() => {
    Actions.onLoadConnectedOrgs();
  }, [farm]);

  const formRefStats = {
    values: formRef?.current?.values,
    hasKeyword: formRef?.current?.values?.keyword,
  };

  return (
    <Box>
      {/* <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
        <Typography color={(theme) => theme.palette.org.main} fontWeight={800}>
          OrgConnect
        </Typography>
        <IconButton aria-label="" onClick={onExit}>
          <CloseIcon />
        </IconButton>
      </Stack> */}
      <Box>
        <BottomNavigation
          value={pageIndex}
          onChange={(event, newValue) => {
            setPageIndex(newValue);
          }}
          sx={{
            bgcolor: "purple",
            "& .Mui-selected": {
              "& .MuiBottomNavigationAction-label": {
                fontWeight: "bold",
              },
              "& .MuiSvgIcon-root, & .MuiBottomNavigationAction-label": {
                color: (theme) => theme.palette.org.main,
              },
            },
          }}
        >
          <BottomNavigationAction value={1} label="Search" icon={<SearchIcon />} />
          <BottomNavigationAction value={2} label="Active" icon={<ListIcon />} />
          <BottomNavigationAction
            value={3}
            label="Sent"
            icon={
              <Badge badgeContent={connectResult?.pending_sent?.size} color="org">
                <ScheduleSendIcon />
              </Badge>
            }
          />
          <BottomNavigationAction
            value={4}
            label="Received"
            icon={
              <Badge badgeContent={connectResult?.pending_received?.size} color="org">
                <AccessTime />
              </Badge>
            }
          />
        </BottomNavigation>
      </Box>
      <Divider />

      <Box p={2}>
        {pageIndex === 1 && (
          <Box>
            <Formik
              innerRef={formRef}
              initialValues={{
                keyword: "",
              }}
              onSubmit={(values) => {
                Actions.onSearchOrgs(values.keyword);
              }}
            >
              {({ values, handleChange }) => {
                return (
                  <Form>
                    <Stack direction={"row"} justifyContent={"space-between"} spacing={1}>
                      <TextField size="small" variant="outlined" fullWidth name="keyword" onChange={handleChange} value={values.keyword} placeholder="Search" />
                      <IconButton type="submit">
                        <SearchIcon />
                      </IconButton>
                    </Stack>
                  </Form>
                );
              }}
            </Formik>

            {/* Result */}
            {formRefStats.hasKeyword && (
              <Box
                sx={{
                  mt: 2,
                  overflow: "hidden",
                  maxHeight: 300,
                  overflow: "auto",
                }}
              >
                {formRefStats.hasKeyword && orgsResult.length == 0 && <VerticalAlertBoxCollections.NoResultFor word={formRefStats.hasKeyword} />}
                {orgsResult.map((ele) => {
                  return (
                    <OrgRow
                      key={ele.id}
                      data={ele}
                      action={{
                        icon: <SendIcon />,
                        action: () => Actions.onSendConnectionRequest({ farmid: farm.farm_id, orgid: ele.id }),
                      }}
                    />
                  );
                })}
              </Box>
            )}
          </Box>
        )}

        {pageIndex === 2 && <ActiveOrgsList farm={farm} actions={Actions} list={connectResult?.active?.rows} />}

        {pageIndex === 3 && <PendingSentOrgList farm={farm} actions={Actions} list={connectResult?.pending_sent?.rows} />}

        {pageIndex === 4 && <PendingReceivedOrgsList farm={farm} actions={Actions} list={connectResult?.pending_received?.rows} />}

        <Box mt={1}>
          <Typography fontSize={8} color={"#888"}>
            OrgConnect
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default OrgConnectForm;
