/* Platform Roles
 */
export const Roles = {
  INTELLIGON: "INTELLIGON",
  USER: "USER",
  INTERNAL: "INTERNAL",
  SHRIMPSTAR_USER: "SHRIMPSTAR_USER",
  SHRIMPSTAR_AGENT: "SHRIMPSTAR_AGENT",
  SHRIMPL_USER: "SHRIMPL_USER",
};

/** Pond */
export const variableCostsFields = [
  "f_feed",
  "f_energy",
  "f_inventory_costs",
  "f_sales_costs",
  "f_seeding_costs",
  "f_travel_expense",
  "f_services_and_duties_costs",
  "f_desinfectant_and_pond_cleaning",
  "f_harvesting_costs",
  "f_processing_costs",
  "f_lab_costs",
  "p_feed",
];

export const fixedCostsFields = [
  "f_labor",
  "f_permits_costs",
  "f_interst_expense",
  "f_maintenance_and_construction_costs",
  "f_depreciation",
  "f_infrastructure_costs",
  "f_admin_costs",
  "f_consulting_costs",
  "f_insurance_costs",
];
