import { Box } from "@mui/material";
import React from "react";
import { AppSelectors } from "redux/AppReducers";
import DataExplorerMultiTypeChart from "screens/Aquaculture/components/DataExplorer/components/DataExplorerMultiTypeChart";

const PondReferenceDataCharts = ({
  pondReferenceData,
  referenceFields,
  primaryPondData,
}) => {
  const pondManagementStore = AppSelectors.pondManagementStore();

  const fieldsMap = pondManagementStore.fieldsMap;

  const minDate = _.minBy(primaryPondData, "datetime");
  const maxDate = _.maxBy(primaryPondData, "datetime");

  return (
    <Box>
      {referenceFields.map((f) => {
        const singleTimeseries = pondReferenceData
          .filter((r) => r[f])
          .map((e) => ({
            datetime: e.datetime,
            value: e[f],
            field: f,
          }));
        if (singleTimeseries.length > 0) {
          return (
            <DataExplorerMultiTypeChart
              data={singleTimeseries}
              title={
                fieldsMap?.[f]
                  ? `${fieldsMap?.[f]?.field_name} (${fieldsMap?.[f]?.field_unit})`
                  : f
              }
              minDate={minDate}
              maxDate={maxDate}
            />
          );
        } else {
          return "";
        }
      })}
    </Box>
  );
};

export default PondReferenceDataCharts;
