import { useEffect, useState } from "react";

import { userActions } from "redux/user";
import { useDispatch } from "react-redux";
import { Alert, Box, Button, Stack, Typography } from "@mui/material";
import { KeyboardArrowRight } from "@mui/icons-material";
import { AppSelectors } from "redux/AppReducers";

const GoogleSignedIn = () => {
  const dispatch = useDispatch();

  const [accountNotFound, setAccountNotFound] = useState(false);

  const userStore = AppSelectors.userStore();

  const session = JSON.parse(sessionStorage.getItem("_GOOGLE_SSO_SESSION"));

  useEffect(() => {
    session && Actions.signin();
  }, []);

  const Actions = {
    signin: async () => {
      const token = session?.access_token;
      await userActions.login(dispatch, {
        googleToken: token,
        oauthStep: 0,
      });
    },
    signup: async () => {
      const token = session?.access_token;
      await userActions.login(dispatch, {
        googleToken: token,
        oauthStep: 1,
      });
    },
  };

  useEffect(() => {
    if (userStore?.loginError !== "") {
      setAccountNotFound(true);
    }
  }, [userStore]);

  return (
    <Box width="100vw" height="100vh" backgroundColor={"#0a2f3d"}>
      <Stack width="100%" height="100%" justifyContent="center" alignItems="center">
        {accountNotFound ? (
          <Box>
            <Alert severity="error">There's no account's email associated</Alert>
            <Box mt={5}>
              <Button endIcon={<KeyboardArrowRight />} color="secondary" variant="contained" onClick={Actions.signup}>
                Create an Shrimpl account for me!
              </Button>
            </Box>
          </Box>
        ) : (
          <Typography color="secondary">Loading...</Typography>
        )}
      </Stack>
    </Box>
  );
};

export default GoogleSignedIn;
