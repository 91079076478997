import { Box, Typography, colors } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import React, { useRef, useState } from "react";
import NumberFormat from "react-number-format";
import { useDispatch } from "react-redux";
import { googleMapManagementActions } from "redux/pondManagement/googleMapManagement";
import AlertBar from "screens/Aquaculture/components/PondManager/Widget/AlertBar";

const MonitorTable = ({ rows }) => {
  const tableRef = useRef(null);

  const dispatch = useDispatch();

  const Actions = {
    onRowSelected: (e) => {
      googleMapManagementActions.highlightMultiPonds(dispatch, [e.data]);
    },
  };

  const [colDef, setColDef] = useState([
    {
      headerName: "Pond",
      field: "label",
      flex: 1,
    },
    {
      headerName: "Value",
      flex: 1,
      type: "rightAligned",
      field: "data.valueDisplay",
      cellRenderer: ({ data }) => {
        return <NumberFormat displayType="text" decimalScale={0} thousandSeparator="," value={data?.data?.valueDisplay || "-"} />;
      },
    },
    {
      headerName: "Alert",
      flex: 1,
      cellRenderer: ({ data }) => {
        const alertDetails = data?.data?.alertDetails;
        return data?.data?.valueDisplay && <AlertBar {...alertDetails} />;
      },
    },
  ]);

  return (
    <Box>
      <Box
        className="ag-theme-clean"
        sx={{
          width: "100%",
          height: 600,
        }}
      >
        <AgGridReact ref={tableRef} rowData={rows} columnDefs={colDef} rowHeight={25} onRowClicked={Actions.onRowSelected} rowSelection={"single"} />
      </Box>
    </Box>
  );
};

export default MonitorTable;
