import _ from "lodash";

// WARNING: only put super admin (right now it's just Claude & Ciaron)
const InternalUserIds = ["709f2907-cf70-4b2c-9c26-c0b5cc0f3602"];

export const isInternalUser = (user) => {
  return _.includes(InternalUserIds, user?.user_id);
};

export default InternalUserIds;
