import { useEffect } from "react";
import { Box, Card, Stack, Typography } from "@mui/material";
import _ from "lodash";
import NumberFormat from "react-number-format";
import { AppSelectors } from "redux/AppReducers";
import Digit4x4Card from "components/card/Digit4x4Card";
import PieAndTableCard from "components/ui/Cards/PieAndTableCard";
import { fixedCostsFields, variableCostsFields } from "configs/appConfigs";
import Text, { getText } from "components/text/Text";
import { defaultAlertRules } from "screens/Aquaculture/components/Dashboard/utils/alertHelpers";
import WidgetCard from "ui/Card/WidgetCard";

/** Helpers */
const getAlertsFromAggregatedData = (aggregatedPondData, rules) => {
  const alertResult = _.mapValues(aggregatedPondData, (o) =>
    _.mapValues(o, (v, vk) => {
      if (v && rules[vk]) {
        if (rules[vk].max !== null && v.value > rules[vk].max) {
          return {
            alert: true,
            isUpper: true,
            rules: rules[vk],
          };
        }
        if (rules[vk].min !== null && v.value < rules[vk].min) {
          return {
            alert: true,
            isUpper: false,
            rules: rules[vk],
          };
        }
      }
      return {
        alert: false,
        isUpper: false,
        rules: rules[vk],
      };
    })
  );
  return alertResult;
};

const CurrentCostWidget = ({ data, fields }) => {
  const dataForVariableCostsCard = {
    pie: _.orderBy(
      variableCostsFields.map((k) => ({
        label: getText(`fields.${_.find(fields, (o) => o.field_id === k)?.field_id || k}`),
        value: data?.farm_cost_data_aggregated?.[k],
      })),
      "value",
      "desc"
    ),
    table: {
      rows: _.orderBy(
        variableCostsFields.map((k) => ({
          label: getText(`fields.${_.find(fields, (o) => o.field_id === k)?.field_id || k}`),
          value: data?.farm_cost_data_aggregated?.[k],
          unitcost: data?.farm_cost_data_aggregated_by_unit?.[k],
        })),
        "value",
        "desc"
      ).map((r) => ({
        ...r,
        value: (
          <Typography fontWeight={"bold"}>
            <NumberFormat value={r.value} decimalScale={0} displayType="text" prefix="$" thousandSeparator="," />
          </Typography>
        ),
        unitcost: (
          <Typography fontWeight={"bold"}>
            <NumberFormat value={r.unitcost} decimalScale={2} displayType="text" prefix="$" thousandSeparator="," suffix="/kg" />
          </Typography>
        ),
      })),
      columns: [
        {
          key: "label",
          display: <Text>interface.general.item</Text>,
        },
        {
          key: "value",
          display: <Text>interface.general.amount</Text>,
        },
        {
          key: "unitcost",
          display: <Text>interface.general.unit-cost</Text>,
        },
      ],
    },
  };

  const dataForFixedCostsCard = {
    pie: _.orderBy(
      fixedCostsFields.map((k) => ({
        label: getText(`fields.${_.find(fields, (o) => o.field_id === k)?.field_id}`),
        value: data?.farm_cost_data_aggregated?.[k],
      })),
      "value",
      "desc"
    ),
    table: {
      rows: _.orderBy(
        fixedCostsFields.map((k) => ({
          label: getText(`fields.${_.find(fields, (o) => o.field_id === k)?.field_id}`),
          value: data?.farm_cost_data_aggregated?.[k],
          unitcost: data?.farm_cost_data_aggregated_by_unit?.[k],
        })),
        "value",
        "desc"
      ).map((r) => ({
        ...r,
        value: (
          <Typography fontWeight={"bold"}>
            <NumberFormat value={r.value} decimalScale={0} displayType="text" prefix="$" thousandSeparator="," />
          </Typography>
        ),
        unitcost: (
          <Typography fontWeight={"bold"}>
            <NumberFormat value={r.unitcost} decimalScale={2} displayType="text" prefix="$" thousandSeparator="," suffix="/kg" />
          </Typography>
        ),
      })),
      columns: [
        {
          key: "label",
          display: <Text>interface.general.item</Text>,
        },
        {
          key: "value",
          display: <Text>interface.general.amount</Text>,
        },
        {
          key: "unitcost",
          display: <Text>interface.general.unit-cost</Text>,
        },
      ],
    },
  };

  return (
    <WidgetCard>
      <Stack spacing={2}>
        <Stack
          direction={"row"}
          spacing={2}
          sx={{
            overflowX: "auto",
          }}
        >
          <Digit4x4Card
            title={<Text>interface.general.total-cost</Text>}
            value={<NumberFormat displayType="text" prefix="$" value={data?.farm_costs_total} thousandSeparator="," decimalScale={0} />}
            subValue={<NumberFormat displayType="text" prefix="$" value={data?.farm_costs_by_unit} thousandSeparator="," suffix="/kg" decimalScale={0} />}
          />
          <Digit4x4Card
            title={<Text>interface.general.total-fixed-costs</Text>}
            value={<NumberFormat displayType="text" prefix="$" value={data?.farm_fixed_costs_total} thousandSeparator="," decimalScale={0} />}
            subValue={<NumberFormat displayType="text" prefix="$" value={data?.farm_fixed_costs_by_unit} thousandSeparator="," suffix="/kg" decimalScale={0} />}
          />
          <Digit4x4Card
            title={<Text>interface.general.total-variable-costs</Text>}
            value={<NumberFormat displayType="text" prefix="$" value={data?.farm_var_costs_total} thousandSeparator="," decimalScale={0} />}
            subValue={<NumberFormat displayType="text" prefix="$" value={data?.farm_var_costs_by_unit} thousandSeparator="," suffix="/kg" decimalScale={0} />}
          />
        </Stack>
        <PieAndTableCard title={<Text>interface.general.variable-costs-breakdown</Text>} data={dataForVariableCostsCard} />
        <PieAndTableCard title={<Text>interface.general.fixed-costs-breakdown</Text>} data={dataForFixedCostsCard} />
      </Stack>
    </WidgetCard>
  );
};

const FarmFinancePanel = ({ farm, ponds, farmDashboardData, onSwitchToDataViewField, actions }) => {
  const pondManagementStore = AppSelectors.pondManagementStore();

  const fields = pondManagementStore.fields;

  const { toggleAlertPonds } = actions;

  useEffect(() => {
    return () => {
      toggleAlertPonds({
        isCancel: true,
      });
    };
  }, []);

  const alertResult = getAlertsFromAggregatedData(_.omit(farmDashboardData?.aggregated_pond_data, ["_aggregated"]), defaultAlertRules);

  return (
    <Box>
      <Box>
        <CurrentCostWidget data={farmDashboardData?.costs} fields={fields} />
      </Box>
    </Box>
  );
};

export default FarmFinancePanel;
