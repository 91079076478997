// =============================================================================
// #WATERREPORT
// =============================================================================

import { ApiDelete, ApiGet, ApiPost, ApiPut, AxiosGet } from "api/apiUtils";
import { URL } from "../config";
import { AxiosPost } from "./apiUtils";
import { GOOGLE_CAPTCHA_SECRET_KEY } from "secrets";

export const api_water_monthly_report_index = async (markettype) => {
  const endpoint = `${URL}/analytics/waterreport?request=index&type=${markettype}`;
  return AxiosGet(endpoint).then((res) => res.data);
};

export const api_water_monthly_report_data = async (markettype, zone) => {
  const endpoint = `${URL}/analytics/waterreport?request=data&type=${markettype}&zone=${zone}`;
  return AxiosGet(endpoint).then((res) => res.data);
};

// =============================================================================
// #DATA
// =============================================================================

export function api_data(paras) {
  const { ticker, fields, schema, from, to } = paras;
  let API = `${URL}/api/datacenter?ticker=${ticker}&fields=${fields}&from=${from}&schema=${schema}`;
  return AxiosGet(API);
}

export function api_bom_water_datacenter(ts_id, start) {
  const endpoint = `${URL}/api/water?src=bom&ts_id=${ts_id}&from=${start}&`;
  return AxiosGet(endpoint);
}

export function api_ndvi(id) {
  const endpoint = `${URL}/data/demand?src=ndvi&id=${id}&`;
  return AxiosGet(endpoint);
}

// *** BOM WATER ONLINE
export function api_bomonline_station_index() {
  const API = `${URL}/api/bomonline?request_type=station_index`;
  return AxiosGet(API);
}

export function api_bomonline_station_meta(stationname) {
  const API = `${URL}/api/bomonline?request_type=station_meta&station_name=${stationname}`;
  return AxiosGet(API);
}

export function api_bomonline_data(tsid) {
  const API = `${URL}/api/bomonline?request_type=data&ts_id=${tsid}`;
  return AxiosGet(API);
}

// *** BOM Weather Data
export function api_bomweather_station_index() {
  const API = `${URL}/couchdbaccess?database=bom_weather_index&docid=bom_ftp_index`;
  return AxiosGet(API);
}

// *** Water Market :: Entitlement Auctions
export const api_water_auctions = async () => {
  const API = `${URL}/api/water/auctions`;
  return AxiosGet(API).then((res) => res.data);
};

// =============================================================================
// #DATABASE
// =============================================================================

export function api_couchdb(database, docid) {
  const endpoint = `${URL}/couchdbaccess?database=${database}&docid=${docid}`;
  return AxiosGet(endpoint);
}

// :: Authentication ::
export const AuthenticationApis = {
  signup: (data) => ApiPost("/signup", data).then((res) => res.data).catch((e) => e.response.data),
  verifyCaptcha: (token) => AxiosPost("https://www.google.com/recaptcha/api/siteverify", { secret: GOOGLE_CAPTCHA_SECRET_KEY, response: token  })
}


export const AdminManager = {
  getFields: () => ApiGet("/admin/aqua/fields").then((res) => res.data.list),
  createOrUpdateField: (payload) => ApiPut("/admin/aqua/fields", payload).then((res) => res.data),
  enableField: (fieldId) => ApiPut("/admin/aqua/fields", { field_id: fieldId, is_active: true }).then((res) => res.data),
  disableField: (fieldId) => ApiPut("/admin/aqua/fields", { field_id: fieldId, is_active: false }).then((res) => res.data),
  enableFieldUpload: (fieldId) => ApiPut("/admin/aqua/fields", { field_id: fieldId, upload_enabled: true }).then((res) => res.data),
  disableFieldUpload: (fieldId) => ApiPut("/admin/aqua/fields", { field_id: fieldId, upload_enabled: false }).then((res) => res.data),
};

