import { Box, Grid, MenuItem, Select, Stack, Typography, colors } from "@mui/material";
import HighchartsWrapper from "components/Highcharts/HighchartsWrapper";
import Text, { getText } from "components/text/Text";
import Formatters from "helpers/Formatters";
import _ from "lodash";
import React, { useState } from "react";
import NumberFormat from "react-number-format";
import MiniLabelAndValueCard from "ui/Card/MiniLabelAndValueCard";
import MuiTabs, { MuiTabsThemes } from "ui/tabs/MuiTabs";

const viewModes = {
  all: "all",
  main: "main",
  costs: "costs",
  unitCost: "unitCost",
  feed: "feed",
  fcr: "fcr",
};

const showView = (currentView, targetView) => {
  if (currentView === viewModes.all) return true;
  return currentView === targetView;
};

const FarmResultCharts = ({ forecastResult, financialResults, dynamicFormValues }) => {
  const [viewMode, setViewMode] = useState(viewModes.main);

  const aggForecastResult = forecastResult["_agg"];
  const statsToShow = {
    summary: [
      {
        label: "Optimal Harvest Day",
        value: financialResults?.stats?.optimalHarvestDoc,
      },
      {
        label: "OH. Biomass",
        value: Formatters.formatBiomassInTons({ value: financialResults?.stats?.optimalHarvestBiomass }),
      },
      {
        label: "OH. Profit",
        value: Formatters.formatDollar({ value: financialResults?.stats?.optimalHarvestProfit }),
      },
      {
        label: "OH. Gross Income",
        value: Formatters.formatDollar({ value: financialResults?.stats?.optimalHarvestGrossIncome }),
      },
      {
        label: "OH. Total Costs",
        value: Formatters.formatDollar({ value: financialResults?.stats?.optimalHarvestTotalCost }),
      },
      {
        label: "OH. Average Unit Cost",
        value: Formatters.formatDollar({ value: financialResults?.stats?.optimalHarvestAverageUnitCost }) + "/kg",
      },
    ],
    costs: [
      {
        label: "Total Labour Costs",
        value: Formatters.formatDollar({ value: financialResults?.stats?.optimalHarvestLabourFixedCost }),
      },
      {
        label: "Total Admin Costs",
        value: Formatters.formatDollar({ value: financialResults?.stats?.optimalHarvestAdminFixedCost }),
      },
      {
        label: "Total Other Costs",
        value: Formatters.formatDollar({ value: financialResults?.stats?.optimalHarvestOtherFixedCost }),
      },
      {
        label: "Total Energy Costs",
        value: Formatters.formatDollar({ value: financialResults?.stats?.optimalHarvestEnergyVarCost }),
      },
      {
        label: "Total Feed Costs",
        value: Formatters.formatDollar({ value: financialResults?.stats?.optimalHarvestFeedVarCost }),
      },
      {
        label: "Total Seeding Costs",
        value: Formatters.formatDollar({ value: financialResults?.stats?.optimalHarvestSeedVarCost }),
      },
    ],
    feed: [
      {
        label: "Feed Usage at OH",
        value: <NumberFormat value={financialResults?.stats?.optimalHarvestTotalFeed / 1000} suffix=" t" decimalScale={1} displayType="text" thousandSeparator="," />,
      },
      {
        label: "Remaing Feed at OH",
        value: <NumberFormat value={financialResults?.stats?.optimalHarvestRemainingFeed / 1000} suffix=" t" decimalScale={1} displayType="text" thousandSeparator="," />,
      },
    ],
    fcr: [
      {
        label: "FCR at OH",
        value: <NumberFormat value={aggForecastResult?.data[financialResults?.stats?.optimalHarvestDoc]?.fcr} decimalScale={1} displayType="text" />,
      },
    ],
    avgUnitCost: [
      {
        label: "OH. Average Unit Cost",
        value: Formatters.formatDollar({ value: financialResults?.stats?.optimalHarvestAverageUnitCost }) + "/kg",
      },
    ],
  };

  const chartConfigs = {
    [viewModes.main]: {
      chart: {
        type: "line",
        height: "350px",
      },
      title: {
        text: "SUMMARY",
        align: "left",
      },
      xAxis: {
        title: {
          text: "DOC",
        },
        gridLineWidth: 0,
        lineWidth: 1,
        plotLines: [
          {
            color: colors.green[800], // Red
            width: 2,
            dashStyle: "Dash",
            value: financialResults?.stats?.optimalHarvestDoc,
          },
        ],
        crosshair: true,
      },
      yAxis: {
        title: {
          text: "",
        },
      },
      tooltip: {
        shared: true,
      },
      plotOptions: {
        bar: {
          borderRadius: "50%",
          dataLabels: {
            enabled: true,
          },
          groupPadding: 0.1,
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: "Gross Income",
          type: "line",
          color: colors.orange[200],
          data: financialResults?.data?.map((item) => ({
            x: item.doc,
            y: item.sellingValue,
          })),
        },
        {
          name: "Total Costs",
          type: "area",
          color: colors.red[200],
          data: financialResults?.data?.map((item) => ({
            x: item.doc,
            y: item.totalCost * -1,
          })),
        },
        {
          name: "Net Profit",
          type: "line",
          color: colors.green[800],
          data: financialResults?.data?.map((item) => ({
            x: item.doc,
            y: item.netProfit,
          })),
        },
      ],
    },
    [viewModes.costs]: {
      chart: {
        type: "line",
        height: "350px",
      },
      title: {
        text: "COST BREAKDOWN",
        align: "left",
      },
      xAxis: {
        title: {
          text: "DOC",
        },
        gridLineWidth: 0,
        lineWidth: 1,
        plotLines: [
          {
            color: colors.green[800], // Red
            width: 2,
            dashStyle: "Dash",
            value: financialResults?.stats?.optimalHarvestDoc,
          },
        ],
        crosshair: true,
      },
      yAxis: {
        title: {
          text: "",
        },
      },
      plotOptions: {
        column: {
          stacking: "normal",
          borderWidth: 0,
        },
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: true,
      },
      tooltip: {
        shared: true,
      },
      series: [
        {
          name: "Labour",
          type: "column",
          color: colors.blue[500],
          data: financialResults?.data?.map((item) => ({
            x: item.doc,
            y: item.labourFixedCost,
          })),
        },
        {
          name: "Admin",
          type: "column",
          color: colors.red[500],
          data: financialResults?.data?.map((item) => ({
            x: item.doc,
            y: item.adminFixedCost,
          })),
        },
        {
          name: "Other",
          type: "column",
          color: colors.orange[500],
          data: financialResults?.data?.map((item) => ({
            x: item.doc,
            y: item.otherFixedCost,
          })),
        },
        {
          name: "Feed",
          type: "column",
          color: colors.green[500],
          data: financialResults?.data?.map((item) => ({
            x: item.doc,
            y: item.feedVarCost,
          })),
        },
        {
          name: "Energy",
          type: "column",
          color: colors.amber[500],
          data: financialResults?.data?.map((item) => ({
            x: item.doc,
            y: item.energyVarCost,
          })),
        },
        {
          name: "Seed",
          type: "column",
          color: colors.cyan[500],
          data: financialResults?.data?.map((item) => ({
            x: item.doc,
            y: item.seedVarCost,
          })),
        },
      ],
    },
    [viewModes.unitCost]: {
      chart: {
        type: "line",
        height: "350px",
      },
      title: {
        text: "AVG UNIT COST ($/kg)",
        align: "left",
      },
      xAxis: {
        title: {
          text: "DOC",
        },
        gridLineWidth: 0,
        lineWidth: 1,
        plotLines: [
          {
            color: colors.green[800], // Red
            width: 2,
            dashStyle: "Dash",
            value: financialResults?.stats?.optimalHarvestDoc,
          },
        ],
        crosshair: true,
      },
      yAxis: {
        title: {
          text: "",
        },
      },
      plotOptions: {
        column: {
          stacking: "normal",
          borderWidth: 0,
        },
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: true,
      },
      tooltip: {
        shared: true,
      },
      series: [
        {
          name: "Avg Unit Cost ($/kg)",
          type: "line",
          color: colors.red[500],
          data: financialResults?.data?.map((item) => ({
            x: item.doc,
            y: item.averageUnitCost,
          })),
        },
      ],
    },
    [viewModes.feed]: {
      chart: {
        type: "line",
        height: "350px",
      },
      title: {
        text: "FEED",
        align: "left",
      },
      xAxis: {
        title: {
          text: "DOC",
        },
        gridLineWidth: 0,
        lineWidth: 1,
        plotLines: [
          {
            color: colors.green[800],
            width: 2,
            dashStyle: "Dash",
            value: financialResults?.stats?.optimalHarvestDoc,
          },
        ],
        crosshair: true,
      },
      yAxis: {
        title: {
          text: "",
        },
      },
      plotOptions: {
        bar: {
          borderRadius: "50%",
          dataLabels: {
            enabled: true,
          },
          groupPadding: 0.1,
        },
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: true,
      },
      tooltip: {
        shared: true,
      },
      series: [
        {
          name: "Feed Stock",
          type: "column",
          color: colors.green[200],
          data: aggForecastResult?.data?.map((item) => ({
            x: item.doc,
            y: dynamicFormValues?.totalFeedStock * 1000 - item.cumulative_feed,
            color: dynamicFormValues?.totalFeedStock * 1000 - item.cumulative_feed > 0 ? colors.green[500] : colors.red[500],
          })),
        },
        {
          name: "Feed Comsumption",
          type: "line",
          color: colors.blue[500],
          data: aggForecastResult?.data?.map((item) => ({
            x: item.doc,
            y: item.cumulative_feed,
          })),
        },
      ],
    },
    [viewModes.fcr]: {
      chart: {
        type: "line",
        height: "350px",
      },
      title: {
        text: "FCR",
        align: "left",
      },
      xAxis: {
        title: {
          text: "DOC",
        },
        gridLineWidth: 0,
        lineWidth: 1,
        plotLines: [
          {
            color: colors.green[800], // Red
            width: 2,
            dashStyle: "Dash",
            value: financialResults?.stats?.optimalHarvestDoc,
          },
        ],
        crosshair: true,
      },
      yAxis: {
        title: {
          text: "",
        },
      },
      plotOptions: {
        column: {
          stacking: "normal",
          borderWidth: 0,
        },
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: true,
      },
      tooltip: {
        shared: true,
      },
      series: [
        {
          name: "FCR",
          type: "line",
          color: colors.red[500],
          data: aggForecastResult?.data?.map((item) => ({
            x: item.doc,
            y: item.fcr,
          })),
        },
      ],
    },
  };

  return (
    <Box p={2}>
      <Box my={1} border="1px solid #EEE" p={1} borderRadius={1}>
        <Typography variant="cardTitle">
          <Text>interface.general.summary</Text>
        </Typography>
        <Stack direction={"row"} mt={1}>
          {statsToShow.summary.map((ele) => {
            return <MiniLabelAndValueCard label={ele.label} value={ele.value} />;
          })}
        </Stack>
        <Stack direction={"row"} spacing={1}></Stack>
      </Box>

      <Box>
        <MuiTabs
          options={[
            { label: getText("interface.actions.show-all"), value: viewModes.all },
            { label: getText("interface.general.summary"), value: viewModes.main },
            { label: getText("interface.general.average-unit-cost"), value: viewModes.unitCost },
            { label: getText("interface.general.cost-breakdown"), value: viewModes.costs },
            { label: getText("interface.general.feed"), value: viewModes.feed },
            { label: "FCR", value: viewModes.fcr },
          ]}
          value={viewMode}
          onNewValue={setViewMode}
          theme={MuiTabsThemes.chip}
        />
      </Box>
      <Box>
        {showView(viewMode, viewModes.main) && (
          <>
            <Grid container spacing={1} columns={statsToShow.summary.length}>
              {statsToShow.summary.map((ele, idx) => {
                return (
                  <Grid item xs={1} key={idx}>
                    <MiniLabelAndValueCard {...ele} />
                  </Grid>
                );
              })}
            </Grid>
            <Box my={1}>
              <HighchartsWrapper options={chartConfigs.main} />
            </Box>
          </>
        )}

        {showView(viewMode, viewModes.unitCost) && (
          <>
            <Grid container spacing={1} columns={statsToShow.avgUnitCost.length}>
              {statsToShow.avgUnitCost.map((ele, idx) => {
                return (
                  <Grid item xs={1} key={idx}>
                    <MiniLabelAndValueCard {...ele} />
                  </Grid>
                );
              })}
            </Grid>
            <Box my={1}>
              <HighchartsWrapper options={chartConfigs.unitCost} />
            </Box>
          </>
        )}

        {showView(viewMode, viewModes.costs) && (
          <>
            <Grid container spacing={1} columns={statsToShow.costs.length}>
              {statsToShow.costs.map((ele, idx) => {
                return (
                  <Grid item xs={1} key={idx}>
                    <MiniLabelAndValueCard {...ele} />
                  </Grid>
                );
              })}
            </Grid>
            <Box my={1}>
              <HighchartsWrapper options={chartConfigs.costs} />
            </Box>
          </>
        )}

        {showView(viewMode, viewModes.feed) && (
          <>
            <Grid container spacing={1} columns={statsToShow.feed.length}>
              {statsToShow.feed.map((ele, idx) => {
                return (
                  <Grid item xs={1} key={idx}>
                    <MiniLabelAndValueCard {...ele} />
                  </Grid>
                );
              })}
            </Grid>
            <Box my={1}>
              <HighchartsWrapper options={chartConfigs.feed} />
            </Box>
          </>
        )}
        {showView(viewMode, viewModes.fcr) && (
          <>
            <Grid container spacing={1} columns={statsToShow.fcr.length}>
              {statsToShow.fcr.map((ele, idx) => {
                return (
                  <Grid item xs={1} key={idx}>
                    <MiniLabelAndValueCard {...ele} />
                  </Grid>
                );
              })}
            </Grid>
            <Box my={1}>
              <HighchartsWrapper options={chartConfigs.fcr} />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default FarmResultCharts;
