import { Box, Button, Card, colors, Container, Grid, Stack, TextField, Typography, FormControl, InputLabel, Select, MenuItem, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { UserServies } from "api/userServices";
import Fade from "react-reveal/Fade";
import { Form, Formik } from "formik";
import { signout } from "auth/UserAuth";
import { useSelector, useDispatch } from "react-redux";
import { userReducerTypes } from "redux/user";
import { dialogReducerActions } from "redux/dialog";
import { alertsActions } from "redux/alerts";
import { ShrimplIconOnlySvg } from "components/Images";

import i18next from "i18next";
import { withTranslation, useTranslation } from "react-i18next";
import Text from "components/text/Text";
import PlatformLanguages from "constants/PlatformLanguages";
import Timezones from "constants/Timezones";

const UserProfile = ({ i18n }) => {
  const { t } = useTranslation();

  const userP = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [profile, setProfle] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  useEffect(() => {
    setProfle(userP);
  }, [userP]);

  const updateProfile = async (payload) => {
    const rsp = await UserServies.updateProfile(payload);
    if (rsp.status === "ok") {
      dispatch({ type: userReducerTypes.UPDATE_USER_EMAIL, payload });
      alertsActions.addInfo(dispatch, {
        content: "Your profile has been updated!",
      });
      setProfle(payload);
    } else {
      dialogReducerActions.openDialog(dispatch, {
        title: "Update Profile",
        content: "Something Wrong!",
        level: "error",
      });
      alertsActions.addError(dispatch, {
        content: "Something Wrong!",
      });
    }
  };

  if (!profile) return "";

  const formikConfig = {
    initialValues: profile,
    enableReinitialize: true,
  };

  const Actions = {
    signout: () => {
      signout();
    },
  };

  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.target.value);
  };

  const handleLanguageConfirmButton = () => {
    i18next.changeLanguage(selectedLanguage);
  };

  return (
    <Box>
      <Container
        maxWidth="xl"
        sx={{
          p: {
            xs: 0,
          },
        }}
      >
        <Card
          sx={{
            p: {
              xs: 2,
              sm: 4,
            },
            m: {
              xs: 0,
              sm: 2,
            },
            my: {
              xs: 0,
              sm: 2,
            },
            boxShadow: {
              xs: "none",
              sm: 1,
            },
            borderRadius: {
              xs: 0,
              sm: 2,
            },
          }}
        >
          <Fade bottom distance="1rem" duration={500}>
            <Stack alignItems={"center"}>
              <Box p={2}>
                <img src={ShrimplIconOnlySvg} alt="" width="60" />
              </Box>
              <Typography fontSize={16} sx={{ color: colors.grey[700] }}>
                <strong>
                  {<Text>interface.general.welcome</Text>}, {profile.firstname}
                </strong>
              </Typography>
            </Stack>
          </Fade>

          <Stack spacing={2}>
            <Typography variant="pageTitle">
              <Text>interface.general.profile</Text>
            </Typography>
            <Formik {...formikConfig}>
              {({ values, handleChange, setFieldValue }) => (
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    updateProfile(values);
                  }}
                >
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          id="email"
                          type="text"
                          onChange={handleChange}
                          value={values.email}
                          label={t("interface.account.email")}
                          variant="outlined"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <TextField
                          id="firstname"
                          type="text"
                          onChange={handleChange}
                          value={values.firstname}
                          label={t("interface.account.first-name")}
                          variant="outlined"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <TextField
                          id="lastname"
                          type="text"
                          onChange={handleChange}
                          value={values.lastname}
                          label={t("interface.account.last-name")}
                          variant="outlined"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <TextField
                          id="company"
                          type="text"
                          onChange={handleChange}
                          value={values.company}
                          label={t("interface.account.company")}
                          variant="outlined"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <TextField
                          id="title"
                          type="text"
                          onChange={handleChange}
                          value={values.title}
                          label={t("interface.account.title")}
                          variant="outlined"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <TextField
                          id="mobilenumber"
                          type="text"
                          onChange={handleChange}
                          value={values.mobilenumber}
                          label={t("interface.account.mobilenumber")}
                          variant="outlined"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel id="timezone-label">
                            <Text>interface.general.timezone</Text>
                          </InputLabel>
                          <Select labelId="timezone-label" id="timezone" name="timezone" value={values.timezone} onChange={handleChange} label={<Text>interface.general.timezone</Text>}>
                            {Timezones.map(({ zone, name, gmt }, i) => (
                              <MenuItem value={zone} key={i}>
                                {`${gmt} | ${name}`}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                  <Stack direction="row" spacing={1} mt={2}>
                    <Button type="submit" variant="contained" size="large">
                      <Text>interface.actions.update</Text>
                    </Button>
                    <Button onClick={Actions.signout} variant="contained" size="large" color="error">
                      <Text>interface.account.sign-out</Text>
                    </Button>
                  </Stack>
                </Form>
              )}
            </Formik>

            <Box>
              <Typography variant="pageTitle">
                <Text>interface.general.language</Text>
              </Typography>
              <Box my={2}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="language-label">
                    <Text>interface.general.language</Text>
                  </InputLabel>
                  <Select labelId="language-label" id="language" value={selectedLanguage} onChange={handleLanguageChange} label={<Text>interface.general.language</Text>}>
                    {PlatformLanguages.map(({ name, code, nativeName }, i) => (
                      <MenuItem value={code} key={i}>
                        {`${nativeName}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <Button onClick={handleLanguageConfirmButton} variant="contained" size="large" color="primary">
                  <Text>interface.actions.confirm</Text>
                </Button>
              </Box>
            </Box>
          </Stack>
        </Card>
      </Container>
    </Box>
  );
};

export default withTranslation()(UserProfile);
