import { Box, Stack, Typography, colors } from "@mui/material";
import React from "react";
import { FileUploader } from "react-drag-drop-files";
import { BiSolidFile, BiUpload } from "react-icons/bi";
import styled from "styled-components";

const Layout = styled.div``;

const DndFileInput = (props) => {
  const { value } = props;
  return (
    <Layout>
      <FileUploader {...props}>
        <Box
          p={2}
          sx={{
            borderRadius: 2,
            border: "3px dashed #eee",
            height: 100,
            color: colors.grey[400],
            transition: "all .3s",
            "&:hover": {
              border: "3px solid #ddd",
              background: "#eee",
              cursor: "pointer",
              color: colors.grey[800],
            },
          }}
        >
          {value ? (
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              height={"100%"}
              gap={1}
            >
              <Box
                sx={{
                  svg: {
                    color: colors.grey[700],
                    fontSize: 70,
                  },
                }}
              >
                <BiSolidFile />
              </Box>
              <Typography
                color={colors.grey[700]}
                fontWeight={"bold"}
                fontSize={14}
              >
                {value.name}
              </Typography>
            </Stack>
          ) : (
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              height={"100%"}
              gap={1}
            >
              <Box
                sx={{
                  svg: {
                    fontSize: 70,
                  },
                }}
              >
                <BiUpload />
              </Box>
              <Typography fontSize={14}>Upload or drag a file here</Typography>
            </Stack>
          )}
        </Box>
      </FileUploader>
    </Layout>
  );
};

export default DndFileInput;
