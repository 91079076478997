import { PeopleAlt } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { GroupsIcon } from "components/Icons/MaterialIcons";
import { getText } from "components/text/Text";
import React, { useState } from "react";
import OrganisationAdminGroupList from "screens/Aquaculture/Organisations/OrganisationAdminGroupList";
import OrganisationAdminMemberList from "screens/Aquaculture/Organisations/OrganisationAdminMemberList";
import MuiTabs from "ui/tabs/MuiTabs";

const OrganisationAdminMemberPage = ({ org }) => {
  const [tab, setTab] = useState(0);

  return (
    <Box px={2}>
      <Box my={2}>
        <Typography variant="h6" textTransform={"uppercase"}>
          {getText("interface.general.members")}
        </Typography>
      </Box>
      <Box>
        <MuiTabs
          value={tab}
          onNewValue={setTab}
          options={[
            {
              label: getText("interface.general.members"),
              value: 0,
              icon: <GroupsIcon />,
            },
            {
              label: getText("interface.general.groups"),
              value: 1,
              icon: <PeopleAlt />,
            },
          ]}
          withBorder
        />
      </Box>

      <Box>
        {tab === 0 && (
          <Box>
            <OrganisationAdminMemberList org={org} />
          </Box>
        )}
        {tab === 1 && (
          <Box>
            <OrganisationAdminGroupList org={org} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default OrganisationAdminMemberPage;
