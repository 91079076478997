const { useEffect, useRef } = require("react");

/**
 *
 * Usage
 * =====
 * ```jsx
 * const Component = ({ prop }) => {
 *  const prevProp = usePrevious(prop);
 *  useEffect(() => {
 *    const propChanged = JSON.stringify(prevProp) !== JSON.stringify(prop);
 *    ...
 *  }, [])
 *  ...
 * }
 * ```
 *
 */
const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export default usePrevious;
