import { Box, Grid, Stack, Typography } from "@mui/material";
import NumericFormatters from "helpers/NumericFormatters";
import React, { useEffect, useState } from "react";
import { GOOGLE_MAP_KEY } from "secrets";
import GoogleMapReact from "google-map-react";
import { commonInitMaps } from "components/map/googlemapHelper";
import MuiTabs, { MuiTabsThemes } from "ui/tabs/MuiTabs";
import { MuiBorderIconButton } from "components/buttons/MuiButtonVariants";
import { CloseIcon } from "components/Icons/MaterialIcons";
import NDWIView from "screens/Aquaculture/AquaExplorer/components/NDWIView";
import { AppSelectors } from "redux/AppReducers";
import FeatureEdit from "screens/Aquaculture/AquaExplorer/components/FeatureEdit";
import FeatureProfile from "screens/Aquaculture/AquaExplorer/components/FeatureProfile";

const createMapOptions = (maps) => {
  return {
    fullscreenControl: false,
    zoomControl: false,
    mapTypeId: maps.MapTypeId.SATELLITE,
    minZoom: 5,
  };
};

const shapeStyles = {
  normal: {
    fillColor: "#dc7654",
    fillOpacity: 0.5,
    strokeColor: "#ffffff",
    strokeWeight: 1,
  },
  selected: {
    fillColor: "#ffffff",
    fillOpacity: 0.1,
    strokeColor: "#ffffff",
    strokeWeight: 5,
  },
};

const FeatureCard = ({ feature, actions }) => {
  const [mapCenter, setMapCenter] = useState([feature?.properties?.lat, feature?.properties?.lng]);
  const [mapZoom, setMapZoom] = useState(10);
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  const [pageIndex, setPageIndex] = useState(2);

  const userStore = AppSelectors.userStore();
  const visibility = {
    edit: userStore.isInternal,
  };

  const pageHeights = () => {
    if (pageIndex === 1) return "80vh";
    return 400;
  };

  const handleApiLoaded = async (map, maps) => {
    commonInitMaps(maps);
    map.data.addGeoJson(feature, {
      idPropertyName: "jsonData",
    });
    map.data.setStyle(shapeStyles.selected);
    // fitbound
    const bounds = new maps.LatLngBounds();
    map.data.forEach(function (feature) {
      feature.getGeometry().forEachLatLng(function (latlng) {
        bounds.extend(latlng);
      });
    });
    map.fitBounds(bounds);

    setMap(map);
    setMaps(maps);
  };

  useEffect(() => {
    if (feature && map && maps) {
      map.data.forEach(function (feature) {
        map.data.remove(feature);
      });
      map.data.addGeoJson(feature, {
        idPropertyName: "jsonData",
      });
      map.data.setStyle(shapeStyles.selected);
      // fitbound
      const bounds = new maps.LatLngBounds();
      map.data.forEach(function (feature) {
        feature.getGeometry().forEachLatLng(function (latlng) {
          bounds.extend(latlng);
        });
      });
      map.fitBounds(bounds);
    }
  }, [JSON.stringify(feature)]);

  return (
    <Stack
      sx={{
        bgcolor: "#FFF",
        borderRadius: 3,
        border: "2px solid #e0e0e0",
        animation: "textFloat 0.4s",
        boxShadow: 1,
        overflow: "hidden",
        height: pageHeights(),
        transition: "height 0.3s",
      }}
    >
      <Grid container sx={{ height: "100%", position: "relative" }}>
        <Grid item width={"300px"} sx={{ borderRight: "1px solid #CCC" }}>
          <Stack
            sx={{
              position: "relative",
              height: "100%",
            }}
          >
            <Box
              sx={{
                overflow: "hidden",
                width: "100%",
                height: "100%",
              }}
            >
              <GoogleMapReact
                bootstrapURLKeys={{ key: GOOGLE_MAP_KEY }}
                center={mapCenter}
                defaultZoom={mapZoom}
                options={createMapOptions}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                // onChange={Actions.handleMapChange}
              ></GoogleMapReact>
            </Box>
            <Box
              p={1}
              sx={{
                boxSizing: "border-box",
                position: "absolute",
                width: "100%",
                height: "30%",
                left: 0,
                top: 0,
                background: "linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5676645658263305) 46%, rgba(0,0,0,0.9037990196078431) 100%)",
              }}
            >
              <Typography color="white" fontSize={12} fontWeight={800}>
                {feature?.properties?.label}
              </Typography>
              <Typography color="white" fontSize={12} fontWeight={800}>
                {NumericFormatters.formatAreaInHa({ value: feature?.properties?.area })}
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid
          item
          xs
          sx={{
            height: "100%",
            overflow: "auto",
          }}
        >
          <Box px={1}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
              <MuiTabs
                value={pageIndex}
                theme={MuiTabsThemes.chip}
                options={[{ label: "Profile", value: 2 }, { label: "NDWI", value: 0 }, ...(visibility.edit ? [{ label: "Edit", value: 1 }] : [])]}
                onNewValue={setPageIndex}
              />
              <MuiBorderIconButton icon={<CloseIcon />} onClick={actions.unselectFeature} />
            </Stack>
          </Box>
          <Box
            p={1}
            sx={{
              overflow: "auto",
            }}
          >
            {pageIndex === 0 && (
              <Box>
                <NDWIView feature={feature} />
              </Box>
            )}
            {visibility?.edit && pageIndex === 1 && (
              <Box>
                <FeatureEdit
                  key={feature?.properties?.id}
                  feature={feature}
                  postEditAction={() => {
                    actions.loadData();
                    actions.unselectFeature();
                  }}
                />
              </Box>
            )}
            {pageIndex === 2 && (
              <Box>
                <FeatureProfile feature={feature} />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default FeatureCard;
