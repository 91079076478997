// ClimateChartData.js

import Highcharts from "highcharts";
import moment from "moment";
import palette from "themes/palette";

const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const todayIndex = new Date().getDay();

const labels = Array.from({ length: 6 }, (_, i) => {
  const dayIndex = (todayIndex + i) % 7;
  return i === 0 ? "Today" : daysOfWeek[dayIndex];
});

const hourlyLabels = [
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
  "24:00",
  "1:00",
  "2:00",
  "3:00",
  "4:00",
  "5:00",
  "6:00",
  "7:00",
  "8:00",
  "9:00",
  "10:00",
  "11:00",
];

const currentIsoTime = new Date().toISOString();

const sampleStartingUTCTime = "2023-10-25T18:00:00Z"; // 2023-10-04T04:00:00Z  2023-10-25T06:00:00Z
const localHour = moment(sampleStartingUTCTime).format("HH");

function generateHourArray(startHour) {
  const hours = [];
  for (let i = 0; i < 24; i++) {
    const hour = (startHour + i) % 24;
    hours.push(hour === 0 ? 24 + ":00" : hour + ":00");
  }
  return hours;
}
// const hourlyLabels = generateHourArray(Number(localHour));

const getUniqueValue = (climateData, climateType) => {
  return climateData?.data?.map((val) => val.values[climateType]);
};

const generateTemperatureData = (getUniqueValue) => {
  return {
    labels,
    datasets: [
      {
        label: "Temperature",
        data: getUniqueValue("temperatureAvg"),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
};

const generateRainFallData = (getUniqueValue) => {
  return {
    labels,
    datasets: [
      {
        label: "Rain Fall Chance",
        data: getUniqueValue("rainAccumulationAvg"),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
};

const generateWindSpeedData = (getUniqueValue) => {
  return {
    labels,
    datasets: [
      {
        label: "Wind Speed",
        data: getUniqueValue("windSpeedAvg"),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
};

const generateCloudBaseData = (getUniqueValue) => {
  return {
    labels,
    datasets: [
      {
        label: "Cloud Base",
        data: getUniqueValue("cloudBaseAvg"),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
};

const generateCloudCeilingData = (getUniqueValue) => {
  return {
    labels,
    datasets: [
      {
        label: "Cloud Ceiling",
        data: getUniqueValue("cloudCeilingAvg"),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
};

const generateCloudCoverData = (getUniqueValue) => {
  return {
    labels,
    datasets: [
      {
        label: "Cloud Cover",
        data: getUniqueValue("cloudCoverAvg"),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
};

const generateDewPointData = (getUniqueValue) => {
  return {
    labels,
    datasets: [
      {
        label: "Dew Point",
        data: getUniqueValue("dewPointAvg"),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
};

const generateEvapotranspirationData = (getUniqueValue) => {
  return {
    labels,
    datasets: [
      {
        label: "Evapotranspiration",
        data: getUniqueValue("evapotranspirationAvg"),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
};

const generateFreezingRainIntensityData = (getUniqueValue) => {
  return {
    labels,
    datasets: [
      {
        label: "Freezing Rain Intensity",
        data: getUniqueValue("freezingRainIntensityAvg"),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
};

const generateHumidityData = (getUniqueValue) => {
  return {
    labels,
    datasets: [
      {
        label: "Humidity",
        data: getUniqueValue("humidityAvg"),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
};

const generateIceAccumulationData = (getUniqueValue) => {
  return {
    labels,
    datasets: [
      {
        label: "Ice Accumulation",
        data: getUniqueValue("iceAccumulationAvg"),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
};

const generateIceAccumulationLweData = (getUniqueValue) => {
  return {
    labels,
    datasets: [
      {
        label: "Ice Accumulation LWE",
        data: getUniqueValue("iceAccumulationLweAvg"),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
};

const generatePrecipitationProbabilityData = (getUniqueValue) => {
  return {
    labels,
    datasets: [
      {
        label: "Precipitation Probability",
        data: getUniqueValue("precipitationProbabilityAvg"),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
};

const generatePressureSurfaceLevelData = (getUniqueValue) => {
  return {
    labels,
    datasets: [
      {
        label: "Pressure Surface Level",
        data: getUniqueValue("pressureSurfaceLevelAvg"),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
};

const generateRainIntensityData = (getUniqueValue) => {
  return {
    labels,
    datasets: [
      {
        label: "Rain Intensity",
        data: getUniqueValue("rainIntensityAvg"),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
};

const generateSleetAccumulationData = (getUniqueValue) => {
  return {
    labels,
    datasets: [
      {
        label: "Sleet Accumulation",
        data: getUniqueValue("sleetAccumulationAvg"),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
};

const generateSleetAccumulationLweData = (getUniqueValue) => {
  return {
    labels,
    datasets: [
      {
        label: "Sleet Accumulation LWE",
        data: getUniqueValue("sleetAccumulationLweAvg"),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
};

const generateSleetIntensityData = (getUniqueValue) => {
  return {
    labels,
    datasets: [
      {
        label: "Sleet Intensity",
        data: getUniqueValue("sleetIntensityAvg"),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
};

const generateSnowAccumulationData = (getUniqueValue) => {
  return {
    labels,
    datasets: [
      {
        label: "Snow Accumulation",
        data: getUniqueValue("snowAccumulationAvg"),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
};

const generateSnowAccumulationLweData = (getUniqueValue) => {
  return {
    labels,
    datasets: [
      {
        label: "Snow Accumulation LWE",
        data: getUniqueValue("snowAccumulationLweAvg"),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
};

const generateSnowDepthData = (getUniqueValue) => {
  return {
    labels,
    datasets: [
      {
        label: "Snow Depth",
        data: getUniqueValue("snowDepthAvg"),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
};

const generateSnowIntensityData = (getUniqueValue) => {
  return {
    labels,
    datasets: [
      {
        label: "Snow Intensity",
        data: getUniqueValue("snowIntensityAvg"),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
};

const generateUVHealthConcernData = (getUniqueValue) => ({
  labels,
  datasets: [
    {
      label: "UV Health Concern",
      data: getUniqueValue("uvHealthConcernAvg"),
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
});

const generateUVIndexData = (getUniqueValue) => ({
  labels,
  datasets: [
    {
      label: "UV Index",
      data: getUniqueValue("uvIndexAvg"),
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
});

const generateVisibilityData = (getUniqueValue) => ({
  labels,
  datasets: [
    {
      label: "Visibility",
      data: getUniqueValue("visibilityAvg"),
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
});

const generateWindDirectionData = (getUniqueValue) => ({
  labels,
  datasets: [
    {
      label: "Wind Direction",
      data: getUniqueValue("windDirectionAvg"),
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
});

const generateWindGustData = (getUniqueValue) => ({
  labels,
  datasets: [
    {
      label: "Wind Gust",
      data: getUniqueValue("windGustAvg"),
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
});

const generateTemperatureAndCloudCoverData = (getUniqueValue) => {
  return {
    chart: {
      type: "column",
    },
    title: {
      text: "Monthly Average Rainfall",
    },
    subtitle: {
      text: "Source: WorldClimate.com",
    },
    xAxis: {
      categories: labels,
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "Rainfall (mm)",
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: "Temperature",
        data: getUniqueValue("temperatureAvg"),
      },
      {
        name: "Cloud Cover",
        data: getUniqueValue("cloudCoverAvg"),
      },
    ],
  };
};

const generatePrecipitationProbabilityDataHightChart = (dataType, getUniqueHourlyValue, getUniqueValue) => {
  const isDailyData = dataType === "dailyData";

  return {
    chart: {
      zoomType: "xy",
    },
    title: {
      text: "",
    },
    // subtitle: {
    //   text: "Source: WorldClimate.com",
    //   align: "left",
    // },
    xAxis: [
      {
        categories: isDailyData ? labels : hourlyLabels,
        crosshair: true,
      },
    ],
    yAxis: [
      {
        // Primary yAxis
        labels: {
          format: "{value}°C",
          style: {
            color: Highcharts.getOptions().colors[2],
          },
        },
        title: {
          text: "Temperature",
          style: {
            color: Highcharts.getOptions().colors[2],
          },
        },
        opposite: true,
      },
      {
        // Secondary yAxis
        gridLineWidth: 0,
        title: {
          text: "Rainfall",
          style: {
            color: Highcharts.getOptions().colors[0],
          },
        },
        labels: {
          format: "{value} mm",
          style: {
            color: Highcharts.getOptions().colors[0],
          },
        },
      },
      {
        // Tertiary yAxis
        gridLineWidth: 0,
        title: {
          text: "Sea-Level Pressure",
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        labels: {
          format: "{value} mb",
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
    },
    legend: {
      layout: "vertical",
      align: "left",
      x: 80,
      verticalAlign: "top",
      y: 55,
      floating: true,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || // theme
        "rgba(255,255,255,0.25)",
    },
    series: [
      {
        //name: / Precipitation Probability',
        name: "Humidity",
        type: "column",
        yAxis: 1,
        // data: getUniqueHourlyValue('2023-10-04T04:00:00Z', 'precipitationProbability'),
        data: isDailyData ? getUniqueValue("humidityAvg") : getUniqueHourlyValue("2023-10-04T04:00:00Z", "humidity"),
        tooltip: {
          valueSuffix: " mm",
        },
      },
      {
        name: "Temperature",
        type: "spline",
        yAxis: 2,
        data: isDailyData ? getUniqueValue("temperatureAvg") : getUniqueHourlyValue("2023-10-04T04:00:00Z", "temperature"),
        marker: {
          enabled: false,
        },
        dashStyle: "shortdot",
        tooltip: {
          valueSuffix: " °C",
        },
      },
      {
        name: "Cloud Cover",
        type: "spline",
        data: isDailyData ? getUniqueValue("cloudCoverAvg") : getUniqueHourlyValue("2023-10-04T04:00:00Z", "cloudCover"),
        tooltip: {
          valueSuffix: " %",
        },
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 400,
          },
          chartOptions: {
            legend: {
              floating: false,
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
              x: 0,
              y: 0,
            },
            yAxis: [
              {
                labels: {
                  align: "right",
                  x: 0,
                  y: -6,
                },
                showLastLabel: false,
              },
              {
                labels: {
                  align: "left",
                  x: 0,
                  y: -6,
                },
                showLastLabel: false,
              },
              {
                visible: false,
              },
            ],
          },
        },
      ],
    },
  };
};

const generateHighChartData = (getUniqueHourlyValue, getUniqueDailyValue, selectedClimateName, dataType, gotDailyDates, generateHourlyLabel) => {
  return {
    chart: {
      type: "line",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: dataType === "hourly" ? generateHourlyLabel() : gotDailyDates(),
    },
    yAxis: {
      title: {
        text: selectedClimateName,
      },
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true,
        },
        enableMouseTracking: false,
      },
    },
    series: [
      {
        name: selectedClimateName,
        color: palette.secondary.main,
        data: dataType === "hourly" ? getUniqueHourlyValue(currentIsoTime) : getUniqueDailyValue(), // currentIsoTime "2023-10-04T04:00:00Z"getUniqueDailyValue
      },
    ],
  };
};

const generateHighChartBarData = (getUniqueHourlyValue, getUniqueDailyValue, selectedClimateName, dataType, gotDailyDates, generateHourlyLabel) => {
  return {
    chart: {
      type: "column",
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: dataType === "hourly" ? generateHourlyLabel() : gotDailyDates(),
    },
    plotOptions: {
      series: {
        colorByPoint: false,
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: [
          {
            enabled: true,
            distance: 20,
          },
          {
            enabled: true,
            distance: -40,
            format: "{point.percentage:.1f}%",
            style: {
              fontSize: "1.2em",
              textOutline: "none",
              opacity: 0.7,
            },
            filter: {
              operator: ">",
              property: "percentage",
              value: 10,
            },
          },
        ],
      },
    },
    series: [
      {
        name: selectedClimateName,
        color: palette.secondary.main,
        data: dataType === "hourly" ? getUniqueHourlyValue(currentIsoTime) : getUniqueDailyValue(),
      },
    ],
  };
};

export {
  generateTemperatureData,
  generateRainFallData,
  generateWindSpeedData,
  generateCloudBaseData,
  generateCloudCeilingData,
  generateCloudCoverData,
  generateDewPointData,
  generateEvapotranspirationData,
  generateFreezingRainIntensityData,
  generateHumidityData,
  generateIceAccumulationData,
  generateIceAccumulationLweData,
  generatePrecipitationProbabilityData,
  generatePressureSurfaceLevelData,
  generateRainIntensityData,
  generateSleetAccumulationData,
  generateSleetAccumulationLweData,
  generateSleetIntensityData,
  generateSnowAccumulationData,
  generateSnowAccumulationLweData,
  generateSnowDepthData,
  generateSnowIntensityData,
  generateUVHealthConcernData,
  generateUVIndexData,
  generateVisibilityData,
  generateWindDirectionData,
  generateWindGustData,
  generateTemperatureAndCloudCoverData,
  generatePrecipitationProbabilityDataHightChart,
  generateHighChartData,
  generateHighChartBarData,
};
