import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { layoutConfig } from "config";

import { theme } from "App";
import { Box, Stack, Tooltip, Typography, colors } from "@mui/material";
import palette from "themes/palette";
import { isMobile } from "themes/helpers";
import _ from "lodash";

const mobileMode = isMobile();

// App -> Sidebar -> SidebarApp
const SideBarApp = ({ data }) => {
  return (
    <Box
      m={mobileMode ? 0 : 1}
      sx={
        mobileMode
          ? {
              m: 0,
              "& .sidebarBtn": {
                transition: "all .2s",
              },
              "& .sidebarBtn:active": {
                transform: "scale(0.95)",
              },
            }
          : {
              m: 1,
              "& .sidebarBtn": {
                borderRadius: "16px",
                transition: "all .2s",
              },
              "& .sidebarBtn:hover": {
                background: "#ddd",
              },
              "& .sidebarBtn:active": {
                transform: "scale(0.95)",
              },
            }
      }
    >
      <Stack spacing={mobileMode ? 0 : 1}>
        {data.apps.map((ele, idx) => (
          <Tooltip
            key={idx}
            placement="right"
            title={
              <Box>
                <Typography fontWeight={"bold"}>{ele.name}</Typography>
              </Box>
            }
          >
            <Box
              sx={{
                "&:hover svg": {
                  color: palette.primary.main,
                },
                "& .active svg": {
                  color: palette.primary.main,
                },
                "& svg": {
                  color: "#aaa",
                },
                "& .active .sidebarBtn": {
                  background: theme.palette.primary.light,
                  color: theme.palette.primary.main,
                },
              }}
            >
              <NavLink to={ele.path}>
                <Stack justifyContent={"center"} alignItems={"center"} className="sidebarBtn" height="30px" width="30px" p={1}>
                  <Box>
                    <Box
                      textAlign={"center"}
                      sx={{
                        svg: {
                          fontSize: "20px",
                          transform: "translateY(1px)",
                        },
                      }}
                    >
                      {ele.icon}
                    </Box>
                  </Box>
                </Stack>
              </NavLink>
            </Box>
          </Tooltip>
        ))}
      </Stack>
    </Box>
  );
};

const SideBar = ({ data }) => {
  if (mobileMode) {
    return (
      <Stack
        direction={"row"}
        justifyContent={"space-evenly"}
        sx={{
          bgcolor: "#FFF",
          borderTop: "1px solid #CCC",
          gridArea: "sidebar",
          position: "fiexed",
        }}
      >
        {_.first(data)?.apps?.map((ele, idx) => {
          return (
            <Box
              key={idx}
              sx={{
                a: {
                  display: "block",
                  height: "100%",
                  width: "100%",
                },
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "& .active svg": {
                  color: theme.palette.primary.main,
                },
                "& svg": {
                  transition: "all .5s",
                  color: colors.grey[400],
                },
                "& .active .sidebarBtnText": {
                  color: colors.grey[400],
                },
                "& .active .sidebarBtn": {
                  color: theme.palette.primary.main,
                },
                "& .active .icon-wrapper": {
                  bgcolor: colors.grey[100],
                },
              }}
            >
              <NavLink to={ele.path}>
                <Stack justifyContent={"center"} alignItems={"center"} width={"100%"} height={"100%"}>
                  <Stack
                    className="icon-wrapper"
                    justifyContent={"center"}
                    alignItems={"center"}
                    width={50}
                    height={50}
                    borderRadius={"50%"}
                    sx={{
                      transition: "all .5s",
                      svg: {
                        fontSize: 25,
                      },
                    }}
                  >
                    {ele.icon}
                  </Stack>
                </Stack>
              </NavLink>
            </Box>
          );
        })}
      </Stack>
    );
  }

  return (
    <Box height="100svh" borderRight="1px solid #ddd" bgcolor={"#fff"}>
      <Box height={layoutConfig.navbarHeight} px={1}>
        <Stack justifyContent={"center"} alignItems={"center"} height="100%">
          <NavLink to="/">
            <img src={theme.logo.alt1} alt="" width="20" />
          </NavLink>
        </Stack>
      </Box>
      <Box>
        {data.map((element, idx) => {
          return <SideBarApp key={idx} data={element} />;
        })}
      </Box>
    </Box>
  );
};

export default SideBar;
