import { Box } from "@mui/material";
import React from "react";

const BorderCard = ({ children, ...props }) => {
  return (
    <Box
      sx={{
        borderRadius: 1,
        border: "1px solid #ccc",
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default BorderCard;
