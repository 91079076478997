import { Alert, AlertTitle, Box, Button, Stack, Typography, colors } from "@mui/material";
import { DoNotDisturbOnIcon, ErrorOutlineIcon, HelpCenterOutlinedIcon, HelpOutlineIcon, HideImageIcon, LockIcon } from "components/Icons/MaterialIcons";
import Text, { getText } from "components/text/Text";
import { useDispatch } from "react-redux";
import { Fade } from "react-reveal";
import { cycleManagementActions } from "redux/pondManagement/cycleManagement";

export const NoCycleAlertBox = ({ pondId }) => {
  const dispatch = useDispatch();
  return (
    <AlertBox
      icon={<HelpOutlineIcon />}
      action={() => {
        cycleManagementActions.onChangeToCurrentCycle(dispatch, pondId);
      }}
      actionName={getText("interface.general.current-cycle")}
    >
      <Text>interface.alert.please-select-a-cycle</Text>!
    </AlertBox>
  );
};

export const NoPondAlertBox = () => {
  return (
    <AlertBox icon={<HelpOutlineIcon />}>
      <Text>interface.alert.please-select-a-pond</Text>!
    </AlertBox>
  );
};

export const ErrorAlertBox = (props) => {
  return <AlertBox {...props} icon={<HelpOutlineIcon />} />;
};

export const AlertBoxThemes = {
  banner: "banner",
  vertical: "vertical",
};

export const AlertBoxLevels = {
  info: {
    key: "info",
    color: colors.grey[600],
    colorIcon: colors.grey[300],
    colorLight: colors.grey[50],
  },
  warning: {
    key: "warning",
    color: colors.orange[600],
    colorIcon: colors.orange[300],
    colorLight: colors.orange[50],
  },
  error: {
    key: "error",
    color: colors.red[600],
    colorIcon: colors.red[300],
    colorLight: colors.red[50],
  },
};

const AlertBox = ({ title = "", icon, action, actionName, theme = AlertBoxThemes.banner, level = AlertBoxLevels.info.key, children }) => {
  const defaultError = "Please Wait";

  const levelDetails = AlertBoxLevels[level || AlertBoxLevels.info.key];

  if (theme === AlertBoxThemes.banner) {
    return (
      <Fade left duration={300} distance={"0"}>
        <Alert
          sx={{
            p: 1,
            px: 2,
            fontSize: 18,
            fontWeight: 600,
            bgcolor: levelDetails.colorLight,
            color: levelDetails.color,
            borderWidth: 0,
            alignItems: "center",
            svg: {
              color: levelDetails.color,
              fontSize: 30,
            },
            boxShadow: "none",
            borderRadius: 3,
          }}
          icon={icon || <ErrorOutlineIcon />}
          action={
            action && (
              <Button variant="outlined" size="small" onClick={action}>
                {actionName}
              </Button>
            )
          }
        >
          {title && <AlertTitle sx={{ mb: 0, fontWeight: "bold" }}>{title || defaultError}</AlertTitle>}
          {children}
        </Alert>
      </Fade>
    );
  }
  if (theme === AlertBoxThemes.vertical) {
    return (
      <Box>
        <Stack alignItems={"center"}>
          <Typography sx={{ svg: { fontSize: 180 }, lineHeight: 0 }} color={levelDetails?.colorIcon}>
            {icon || <ErrorOutlineIcon />}
          </Typography>
          <Typography color={levelDetails?.color} fontSize={20} fontWeight={600}>
            {title}
          </Typography>
          {action && (
            <Box mt={2}>
              <Button variant="outlined" size="small" onClick={action}>
                {actionName}
              </Button>
            </Box>
          )}
        </Stack>
      </Box>
    );
  }
};

export const VerticalAlertBox = (props) => <AlertBox theme={AlertBoxThemes.vertical} {...props} />;

export const VerticalAlertBoxCollections = {
  NoPhoto: () => <VerticalAlertBox icon={<HideImageIcon />} title="No Photo to Show" />,
  NoCycle: () => <VerticalAlertBox icon={<HelpOutlineIcon />} title={getText("interface.alert.no-cycle-set-up")} />,
  NoCycleSelected: ({ pondId }) => {
    const dispatch = useDispatch();
    return (
      <VerticalAlertBox
        icon={<HelpOutlineIcon />}
        title={getText("interface.alert.no-cycle-selected")}
        action={
          pondId
            ? () => {
                cycleManagementActions.onChangeToCurrentCycle(dispatch, pondId);
              }
            : null
        }
        actionName={getText("interface.general.current-cycle")}
      />
    );
  },
  NoData: () => <VerticalAlertBox icon={<HelpOutlineIcon />} title={getText("interface.alert.no-data")} />,
  NoPondSelected: () => <VerticalAlertBox icon={<HelpOutlineIcon />} title={getText("interface.alert.please-select-a-pond")} />,
  NoResult: () => <VerticalAlertBox icon={<HelpOutlineIcon />} title={getText("interface.alert.no-result")} />,
  NoResultFor: ({ word }) => <VerticalAlertBox icon={<HelpOutlineIcon />} title={`${getText("interface.alert.no-result-for")} '${word}'`} />,
  NothingShow: () => <VerticalAlertBox icon={<HelpOutlineIcon />} title={getText("interface.alert.nothing-to-show")} />,
  NoAccess: () => <VerticalAlertBox icon={<LockIcon />} title={getText("interface.alert.no-permission")} />,
};

export default AlertBox;
