import { Box, Button, Divider, LinearProgress, Stack, Typography } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import AdminServices from "api/adminServices";
import { PersonIcon, RefreshIcon } from "components/Icons/MaterialIcons";
import { MuiBorderIconButton } from "components/buttons/MuiButtonVariants";
import Countries from "constants/Countries";
import Formatters from "helpers/Formatters";
import moment from "moment";
import React, { useEffect, useState } from "react";
import WidgetCard from "ui/Card/WidgetCard";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    Actions.getAllUsers();
  }, []);

  const Actions = {
    getAllUsers: async () => {
      setLoading(true);
      const resultUsers = await AdminServices.getAllUsers();
      setUsers(resultUsers.payload);
      setLoading(false);
    },
  };

  const tableConfigs = {
    rowHeight: 30,
    headerHeight: 30,
    groupHeaderHeight: 20,
    rowData: users,
    // enables pagination in the grid
    pagination: true,
    // sets 10 rows per page (default is 100)
    paginationPageSize: 50,
    // allows the user to select the page size from a predefined list of page sizes
    paginationPageSizeSelector: [10, 20, 50, 100],
    animateRows: false,
    columnDefs: [
      {
        headerName: "EMAIL",
        field: "email",
        width: 300,
        pinned: "left",
      },
      {
        headerName: "Account Status",
        field: "account_status",
        editable: true,
        cellEditor: "agSelectCellEditor",
        cellEditorParams: {
          values: ["PENDING", "ACTIVE", "SUSPENDED", "DELETED"],
        },
      },
      {
        headerName: "FIRSTNAME",
        field: "firstname",
        editable: true,
      },
      {
        headerName: "LASTNAME",
        field: "lastname",
        editable: true,
      },
      {
        headerName: "MOBILE",
        field: "mobilenumber",
        editable: true,
      },
      {
        headerName: "TITLE",
        field: "title",
        editable: true,
      },
      {
        headerName: "COMPANY",
        field: "company",
        editable: true,
      },
      {
        headerName: "COUNTRY",
        field: "country",
        editable: true,
        cellEditor: "agSelectCellEditor",
        cellEditorParams: {
          values: Countries.map((c) => c.label),
        },
      },
      {
        headerName: "language",
        field: "language",
      },
      {
        headerName: "Timezone",
        field: "timezone",
      },
      {
        headerName: "CREATED",
        field: "created_at",
        cellRenderer: ({ value }) => Formatters.toLocalDateTimeString(value),
      },
      {
        headerName: "LAST LOGIN",
        field: "last_login",
        cellRenderer: ({ value }) => {
          if (!value) return "NEVER";
          const dayGap = moment().diff(moment(value), "days");
          if (dayGap > 365) {
            return Formatters.toLocalDateTimeString(value);
          } else {
            return moment(value).fromNow();
          }
        },
      },
      {
        headerName: "ROLE",
        field: "role",
      },
      {
        headerName: "UserID",
        field: "id",
      },
      {
        headerName: "Email Verified",
        field: "email_verified",
      },
    ],
  };

  return (
    <Box p={2}>
      <WidgetCard title="Shrimpl User" icon={<PersonIcon />} loading={loading} showLoading>
        <Box>
          <Stack direction={"row"} spacing={1}>
            <Button variant="outlined" onClick={Actions.getAllUsers} startIcon={<RefreshIcon />}>
              Refresh
            </Button>
          </Stack>
          <Box mt={2} width="100%" height="calc(100vh - 230px)" className="ag-theme-excel">
            <AgGridReact {...tableConfigs}></AgGridReact>
          </Box>
        </Box>
      </WidgetCard>
    </Box>
  );
};

export default Users;
