import React, { Component } from "react";
import { api_bomonline_station_index, api_bomweather_station_index } from "../../api/api";
import ReactMapboxGl, { Layer, Source, Popup, images } from "react-mapbox-gl";
import styled from "styled-components";
import BomOnlineCard from "./components/BomOnlineCard";
import BomWeatherCard from "./components/BomWeaterCard";
import { MAPBOX_TOKEN } from "../../secrets";
import { Box, Typography } from "@mui/material";

const WEATHERSTATIONCANDIDATES = [
  "090180",
  "087113",
  "085279",
  "089002",
  "081123",
  "087184",
  "090184",
  "090015",
  "090182",
  "086361",
  "080128",
  "090035",
  "086383",
  "086320",
  "084143",
  "085301",
  "090194",
  "083055",
  "085314",
  "079099",
  "088164",
  "086038",
  "083084",
  "086266",
  "086388",
  "084016",
  "084142",
  "079103",
  "090173",
  "077010",
  "079100",
  "082139",
  "079097",
  "078018",
  "080091",
  "087031",
  "079028",
  "084084",
  "088109",
  "086338",
  "086282",
  "076031",
  "086077",
  "090176",
  "085280",
  "085291",
  "083024",
  "083085",
  "085296",
  "084144",
  "078015",
  "083090",
  "084145",
  "090175",
  "090171",
  "085099",
  "088167",
  "088166",
  "079101",
  "088051",
  "086373",
  "082039",
  "086104",
  "087168",
  "081125",
  "079105",
  "077094",
  "081049",
  "086068",
  "088162",
  "076064",
  "082138",
  "090186",
  "089112",
  "085096",
  "085151",
  "081124",
];

const WATERSTATIONCANDIDATES = [
  "Laanecoorie Reservoir",
  "Lake Buffalo",
  "Yarrawonga Weir",
  "Lake William Hovell",
  "DARTMOUTH DAM",
  "DARTMOUTH DAM",
  "DARTMOUTH DAM",
  "TOONUMBAR DAM STO",
  "TOONUMBAR DAM STO",
  "TOONUMBAR DAM STO",
  "TOONUMBAR DAM STO",
  "LOSTOCK DAM",
  "LOSTOCK DAM",
  "BROGO DAM",
  "BROGO DAM",
  "BROGO DAM",
  "BROGO DAM",
  "HUME DAM STORAGE",
  "PINDARI DAM",
  "PINDARI DAM",
  "PINDARI DAM",
  "PINDARI DAM",
  "COPETON DAM",
  "COPETON DAM",
  "COPETON DAM",
  "COPETON DAM",
  "KEEPIT DAM",
  "KEEPIT DAM",
  "KEEPIT DAM",
  "KEEPIT DAM",
  "CHAFFEY DAM",
  "CHAFFEY DAM",
  "CHAFFEY DAM",
  "CHAFFEY DAM",
  "SPLIT ROCK DAM",
  "SPLIT ROCK DAM",
  "SPLIT ROCK DAM",
  "SPLIT ROCK DAM",
  "WINDAMERE DAM",
  "WINDAMERE DAM",
  "WINDAMERE DAM",
  "WINDAMERE DAM",
  "LAKE MENINDEE",
  "LAKE MENINDEE",
  "LAKE MENINDEE",
  "LAKE MENINDEE",
  "GLENBAWN DAM",
  "GLENBAWN DAM",
  "GLENBAWN DAM",
  "GLENBAWN DAM",
  "LOSTOCK DAM",
  "LOSTOCK DAM",
  "GLENNIES CREEK DAM",
  "GLENNIES CREEK DAM",
  "GLENNIES CREEK DAM",
  "GLENNIES CREEK DAM",
  "BLOWERING DAM",
  "BLOWERING DAM",
  "BLOWERING DAM",
  "BLOWERING DAM",
  "BURRINJUCK DAM",
  "BURRINJUCK DAM",
  "BURRINJUCK DAM",
  "BURRINJUCK DAM",
  "WYANGALA STORAGE",
  "WYANGALA STORAGE",
  "WYANGALA STORAGE",
  "WYANGALA STORAGE",
  "CARCOAR DAM",
  "CARCOAR DAM",
  "CARCOAR DAM",
  "CARCOAR DAM",
  "BURRENDONG DAM",
  "BURRENDONG DAM",
  "BURRENDONG DAM",
  "BURRENDONG DAM",
  "OBERON DAM",
  "OBERON DAM",
  "OBERON DAM",
  "OBERON DAM",
  "TOONUMBAR DAM STO",
  "TOONUMBAR DAM STO",
  "GLENNIES CREEK DAM",
  "GLENNIES CREEK DAM",
  "HUME DAM STORAGE",
  "HUME DAM STORAGE",
  "BLOWERING DAM",
  "BLOWERING DAM",
  "CARCOAR DAM",
  "CARCOAR DAM",
  "PINDARI DAM",
  "PINDARI DAM",
  "CHAFFEY DAM",
  "CHAFFEY DAM",
  "SPLIT ROCK DAM",
  "SPLIT ROCK DAM",
  "WINDAMERE DAM",
  "WINDAMERE DAM",
  "TOONUMBAR DAM STO",
  "TOONUMBAR DAM STO",
  "GLENBAWN DAM",
  "GLENBAWN DAM",
  "GLENBAWN DAM",
  "GLENBAWN DAM",
  "LOSTOCK DAM",
  "LOSTOCK DAM",
  "LOSTOCK DAM",
  "LOSTOCK DAM",
  "GLENNIES CREEK DAM",
  "GLENNIES CREEK DAM",
  "BROGO DAM",
  "BROGO DAM",
  "BROGO DAM",
  "BROGO DAM",
  "HUME DAM STORAGE",
  "HUME DAM STORAGE",
  "BLOWERING DAM",
  "BLOWERING DAM",
  "BURRINJUCK DAM",
  "BURRINJUCK DAM",
  "BURRINJUCK DAM",
  "BURRINJUCK DAM",
  "WYANGALA STORAGE",
  "WYANGALA STORAGE",
  "WYANGALA STORAGE",
  "WYANGALA STORAGE",
  "PINDARI DAM",
  "PINDARI DAM",
  "COPETON DAM",
  "COPETON DAM",
  "COPETON DAM",
  "COPETON DAM",
  "KEEPIT DAM",
  "KEEPIT DAM",
  "KEEPIT DAM",
  "KEEPIT DAM",
  "BURRENDONG DAM",
  "BURRENDONG DAM",
  "BURRENDONG DAM",
  "BURRENDONG DAM",
  "OBERON DAM",
  "OBERON DAM",
  "LAKE MENINDEE",
  "LAKE MENINDEE",
  "LAKE MENINDEE",
  "LAKE MENINDEE",
];

const Map = ReactMapboxGl({
  accessToken: MAPBOX_TOKEN,
});

const Layout = styled.div`
  font-family: var(--main-font);
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  height: 100%;
  background: #fff;

  .rhs {
    padding: 20px;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    box-shadow: var(--boxshadow-strong);
  }
`;

export default class BomOnline extends Component {
  constructor(p) {
    super(p);
    this.state = {
      stationindex: [],
      weatherStationIndex: [],
      popupCoord: [],
      zoom: [4],
      center: [146.118482, -31.604802],
      selectedType: 0, // 0:water 1:weather
    };
  }

  componentDidMount() {
    api_bomonline_station_index().then((res) => {
      const data = res.data.message;
      this.setState({
        stationindex: data,
      });
    });

    // api_bomweather_station_index().then((res) => {
    //   const data = res.data.index;
    //   this.setState({
    //     weatherStationIndex: data,
    //   });
    // });
  }

  onChangePopupCord(event, type) {
    this.setState({
      selectedType: type,
      popupCoord: [event.lngLat.lng, event.lngLat.lat],
      selectedStation: event.features[0].properties,
    });
  }

  render() {
    const stationindex = this.state.stationindex.filter((ele) => WATERSTATIONCANDIDATES.includes(ele.station_name));
    const weatherStationIndex = this.state.weatherStationIndex.filter((ele) => WEATHERSTATIONCANDIDATES.includes(ele.id));
    const popupCoord = this.state.popupCoord;
    const zoom = this.state.zoom;
    const center = this.state.center;
    const selectedStation = this.state.selectedStation;

    console.log(selectedStation);

    const generatePopup = () => {
      if (this.state.selectedType === 0) {
        return (
          <Box p={2}>
            <Typography fontWeight={"800"}>
              <i className="fas fa-water"></i> {selectedStation.station_name}
            </Typography>
            <Typography fontSize={12}>DPs: {selectedStation.dp_count}</Typography>
          </Box>
        );
      } else {
        return (
          <div>
            <h3>
              <i className="fas fa-cloud-sun"></i> {selectedStation.station_name} / {selectedStation.state}
            </h3>
            <div>WEATHER STATION</div>
          </div>
        );
      }
    };

    if (stationindex.length > 0) {
      return (
        <Layout>
          <Map
            id="Mainmap"
            {...{
              style: "mapbox://styles/mapbox/outdoors-v9",
              zoom: zoom,
              center: center,
              containerStyle: {
                height: "100%",
                width: "100%",
              },
            }}
          >
            {/* =========== Layer: WATER STATIONS ===========  */}
            <Source
              id="source_id"
              geoJsonSource={{
                type: "geojson",
                data: {
                  type: "FeatureCollection",
                  features: stationindex.map((ele) => ({
                    type: "Feature",
                    geometry: {
                      type: "Point",
                      coordinates: [ele.lon, ele.lat],
                    },
                    properties: ele,
                  })),
                },
              }}
            />

            <Layer
              {...{
                id: "population",
                sourceId: "source_id",
                type: "symbol",
                layout: { "icon-image": "embassy-15" },
                paint: { "icon-color": "#222222" },
                onClick: (e) => {
                  this.onChangePopupCord(e, 0);
                },
                onMouseEnter: (e) => {},
              }}
            />

            {/* =========== Layer: WEATHER STATIONS ===========  */}

            <Source
              id="source_weather_station"
              geoJsonSource={{
                type: "geojson",
                data: {
                  type: "FeatureCollection",
                  features: weatherStationIndex.map((ele) => ({
                    type: "Feature",
                    geometry: {
                      type: "Point",
                      coordinates: [ele.coor[1], ele.coor[0]],
                    },
                    properties: ele,
                  })),
                },
              }}
            />

            <Layer
              {...{
                id: "layer_weather_station",
                sourceId: "source_weather_station",
                type: "symbol",
                layout: { "icon-image": "marker-15" },
                onClick: (e) => {
                  this.onChangePopupCord(e, 1);
                },
                onMouseEnter: (e) => {},
              }}
            />
            {selectedStation ? <Popup coordinates={popupCoord}>{generatePopup()}</Popup> : ""}
          </Map>
          <div className="rhs">
            {this.state.selectedType === 0 ? (
              <BomOnlineCard key={(selectedStation || {}).station_name} payload={selectedStation} />
            ) : (
              <BomWeatherCard key={(selectedStation || {}).station_name} payload={selectedStation} />
            )}
          </div>
        </Layout>
      );
    } else {
      return (
        <Layout>
          <div style={{ padding: "30px" }} className="LoadingText">
            Initialising...
          </div>
        </Layout>
      );
    }
  }
}
