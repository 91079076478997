import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { PondManagerServices } from "api/pondManagerServices";
import { EditIcon } from "components/Icons/MaterialIcons";
import Text from "components/text/Text";
import { Form, Formik } from "formik";
import React, { useState } from "react";

/**
 *
 * A simple pond details batch update form
 * Currently used in map view with multi-mode
 *
 * @returns
 */
const PondManagerBatchUpdateForm = ({
  pondIds,
  callback,
  selectedFarm,
  farms,
}) => {
  const [edit, setEdit] = useState(false);

  const onUpdate = async (values) => {
    const result = await PondManagerServices.updatePondBatch({
      pond_ids: pondIds,
      farm_id: values?.farm_id,
      group: values?.group,
      category: values?.category,
      sub_category: values?.sub_category,
    });

    if (result.status && result.status === "ok") {
      setEdit(false);
      if (callback) {
        callback();
      }
    }
  };

  return (
    <Box mt={2}>
      {edit ? (
        <Formik
          initialValues={{
            farm_id: selectedFarm.farm_id,
            group: null,
            category: null,
            sub_category: null,
          }}
          onSubmit={(values) => {
            onUpdate(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => {
            console.log(values);
            return (
              <Form>
                <Stack spacing={1}>
                  <FormControl fullWidth size="small">
                    <Select
                      labelId="farmId"
                      id="farm_id"
                      name="farm_id"
                      value={values.farm_id}
                      onChange={handleChange}
                    >
                      {farms.map((f, fSeq) => {
                        return (
                          <MenuItem key={fSeq} value={f.farm_id}>
                            {f.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>

                  <TextField
                    size="small"
                    name="group"
                    label="New Group Name"
                    value={values.group}
                    onChange={handleChange}
                  />
                  <TextField
                    size="small"
                    name="category"
                    label="New Category"
                    value={values.category}
                    onChange={handleChange}
                  />
                  <TextField
                    size="small"
                    name="sub_category"
                    label="New Sub Category"
                    value={values.sub_category}
                    onChange={handleChange}
                  />
                  <ButtonGroup fullWidth size="small" variant="contained">
                    <Button color="info" type="submit">
                      <Text>interface.actions.update</Text>
                    </Button>
                    <Button color="error" onClick={() => setEdit(false)}>
                      <Text>interface.actions.cancel</Text>
                    </Button>
                  </ButtonGroup>
                </Stack>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Button
          size="small"
          fullWidth
          variant="outlined"
          startIcon={<EditIcon />}
          onClick={() => setEdit(true)}
        >
          <Text>interface.actions.edit</Text>
        </Button>
      )}
    </Box>
  );
};

export default PondManagerBatchUpdateForm;
