import { Autocomplete, Button, Chip, Stack, TextField, Typography } from "@mui/material";
import Text from "components/text/Text";
import { Form, useFormik } from "formik";
import React from "react";

const MAX_TAGS_LENTH = 3;

const ImageUpdateForm = ({ image, onSubmit, postSubmit, postCancel }) => {
  const formik = useFormik({
    initialValues: {
      label: image?.label,
      tags: image?.tags?.split(",").filter((ele) => ele),
      note: image?.note,
      pond_id: image?.pond_id,
    },
    onSubmit: (values) => {
      onSubmit({
        form: values,
        image: image,
      });
      postSubmit();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={1}>
        <Typography variant="inputLabel">Label</Typography>
        <TextField onChange={formik.handleChange} name="label" value={formik.values.label}></TextField>
        <Typography variant="inputLabel">Tags</Typography>
        <Autocomplete
          multiple
          name="tags"
          options={["pond", "equipment", "water quality", "weather", "shrimp", "harvest"]}
          freeSolo
          value={formik.values.tags}
          onChange={(e, newVal) => {
            if (newVal.length <= MAX_TAGS_LENTH) {
              formik.setFieldValue("tags", newVal);
            }
          }}
          renderTags={(value, getTagProps) => value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({ index })} />)}
          renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Tags" />}
        />
        <Typography variant="inputLabel">Note</Typography>
        <TextField onChange={formik.handleChange} name="note" value={formik.values.note} multiline placeholder="Notes..." minRows={5}></TextField>
        <Button type="submit" variant="contained" color="primary">
          <Text>interface.actions.confirm</Text>
        </Button>
        <Button variant="outlined" color="primary" onClick={postCancel}>
          <Text>interface.actions.cancel</Text>
        </Button>
      </Stack>
    </form>
  );
};

export default ImageUpdateForm;
