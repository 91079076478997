import { Box, SvgIcon } from "@mui/material";
import { ReactComponent as AssesementLogo_ } from "./svg/assesement.svg";
import { ReactComponent as ClimateChangeLogo_ } from "./svg/climate-change.svg";
import { ReactComponent as GeneticLogo_ } from "./svg/genetic.svg";
import { ReactComponent as OperationalRiskLogo_ } from "./svg/operational-risk.svg";
import { ReactComponent as PreventLogo_ } from "./svg/prevent.svg";
import { ReactComponent as RiskLogo_ } from "./svg/risk.svg";
import { ReactComponent as RiskLogo2_ } from "./svg/risks (2).svg";
import { ReactComponent as Risks_ } from "./svg/risks.svg";
import { ReactComponent as Solution_ } from "./svg/solution.svg";
import { ReactComponent as SupplyChainLogo_ } from "./svg/supply-chain.svg";
import { ReactComponent as WarningLogo_ } from "./svg/warning.svg";
import { ReactComponent as WaterQualityLogo_ } from "./svg/water-quality.svg";
import { ReactComponent as WaterSystemLogo_ } from "./svg/water-system.svg";
import { ReactComponent as RegulatoryLogo_ } from "./svg/regulatory.svg";
import { ReactComponent as ExternalLogo_ } from "./svg/external.svg";
import { ReactComponent as SocialLogo_ } from "./svg/social-care.svg";
import { ReactComponent as MarketLogo_ } from "./svg/growth.svg";

const SvgIconWrapper = ({ color, size, children }) => {
  return (
    <Box
      width={`${size || 36}px`}
      height={`${size || 36}px`}
      sx={{
        svg: {
          fill: `${color}!important`,
          path: {
            fill: `${color}!important`,
          },
          width: "100%",
          height: "100%"
        }
      }}
    >
      {children}
    </Box>
  );
};

export const AssesementLogo = (props) => (<SvgIconWrapper {...props}><AssesementLogo_ /></SvgIconWrapper>);
export const ClimateChangeLogo = (props) => (<SvgIconWrapper {...props}><ClimateChangeLogo_ /></SvgIconWrapper>);
export const GeneticLogo = (props) => (<SvgIconWrapper {...props}><GeneticLogo_ /></SvgIconWrapper>);
export const OperationalRiskLogo = (props) => (<SvgIconWrapper {...props}><OperationalRiskLogo_ /></SvgIconWrapper>);
export const PreventLogo = (props) => (<SvgIconWrapper {...props}><PreventLogo_ /></SvgIconWrapper>);
export const RiskLogo = (props) => (<SvgIconWrapper {...props}><RiskLogo_ /></SvgIconWrapper>);
export const RiskLogo2 = (props) => (<SvgIconWrapper {...props}><RiskLogo2_ /></SvgIconWrapper>);
export const RisksLogo = (props) => (<SvgIconWrapper {...props}><Risks_ /></SvgIconWrapper>);
export const SolutionLogo = (props) => (<SvgIconWrapper {...props}><Solution_ /></SvgIconWrapper>);
export const SupplyChainLogo = (props) => (<SvgIconWrapper {...props}><SupplyChainLogo_ /></SvgIconWrapper>);
export const WarningLogo = (props) => (<SvgIconWrapper {...props}><WarningLogo_ /></SvgIconWrapper>);
export const WaterQualityLogo = (props) => (<SvgIconWrapper {...props}><WaterQualityLogo_ /></SvgIconWrapper>);
export const WaterSystemLogo = (props) => (<SvgIconWrapper {...props}><WaterSystemLogo_ /></SvgIconWrapper>);
export const RegulatoryLogo = (props) => (<SvgIconWrapper {...props}><RegulatoryLogo_ /></SvgIconWrapper>);
export const ExternalLogo = (props) => (<SvgIconWrapper {...props}><ExternalLogo_ /></SvgIconWrapper>);
export const SocialLogo = (props) => (<SvgIconWrapper {...props}><SocialLogo_ /></SvgIconWrapper>);
export const MarketLogo = (props) => (<SvgIconWrapper {...props}><MarketLogo_ /></SvgIconWrapper>);