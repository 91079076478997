import { Box, Button, Grid, Stack, Typography, colors } from "@mui/material";
import { theme } from "App";
import { PondManagerServices } from "api/pondManagerServices";
import AlertBox, { NoCycleAlertBox } from "components/Alerts/AlertBox";
import MuiDatetimePicker from "components/DatePicker/MuiDatetimePicker";
import MuiBigTextField from "components/input/MuiBigTextField";
import { HelpOutlineIcon } from "components/Icons/MaterialIcons";
import Text, { getText } from "components/text/Text";
import { Form, Formik } from "formik";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { AppSelectors } from "redux/AppReducers";
import DataLogs from "screens/Aquaculture/components/DataManager/components/DataLogs";
import HighchartsWrapper from "components/Highcharts/HighchartsWrapper";
import Formatters from "helpers/Formatters";
import NumericFormatters from "helpers/NumericFormatters";

const CommonChart = ({ rows, cycle, color, name, type, title }) => {
  const chartConfigs = {
    chart: {
      height: 150,
      animation: {
        duration: 0,
      },
    },
    title: {
      text: "",
    },
    xAxis: {
      type: "datetime",
      min: Formatters.toLocalDatetimeValue(cycle?.start_date),
      max: Formatters.toLocalDatetimeValue(cycle?.end_date),
    },
    yAxis: {
      title: {
        text: "",
      },
      tickAmount: 4,
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        name: name,
        type: type || "line",
        color: color || colors.orange[500],
        data: rows.map((row) => ({
          x: Formatters.toLocalDatetimeValue(row.datetime),
          y: row.value,
        })),
      },
    ],
  };

  return (
    <Box>
      <Box mb={1}>
        <Typography fontSize={12} fontWeight={800}>
          {title}
        </Typography>
      </Box>
      <HighchartsWrapper options={chartConfigs} />
    </Box>
  );
};

const CombinedChart = ({ list, cycle }) => {
  const listSize = list.length;
  const singleChartHeight = _.floor(100 / listSize);

  const chartConfigs = {
    chart: {
      height: 800,
      animation: {
        duration: 0,
      },
    },
    title: {
      text: "",
    },
    xAxis: {
      type: "datetime",
      min: Formatters.toLocalDatetimeValue(cycle?.start_date),
      max: Formatters.toLocalDatetimeValue(cycle?.end_date),
    },
    yAxis: list.map((ele, idx) => ({
      top: idx === 0 ? "0%" : singleChartHeight * idx + "%",
      height: singleChartHeight - 10 + "%",
      title: {
        text: "",
      },
      labels: {
        padding: 0,
      },
      offset: 0,
    })),
    tooltip: {
      shared: true,
    },
    legend: {
      enabled: false,
    },
    series: list.map((ele, idx) => ({
      name: ele.name,
      type: ele.type || "line",
      color: ele.color || colors.orange[500],
      data: ele.rows.map((row) => ({
        x: Formatters.toLocalDatetimeValue(row.datetime),
        y: row.value,
      })),
      yAxis: idx,
    })),
    // [
    //   {
    //     name: name,
    //     type: type || "line",
    //     color: color || colors.orange[500],
    //     data: rows.map((row) => ({
    //       x: Formatters.toLocalDatetimeValue(row.datetime),
    //       y: row.value,
    //     })),
    //   },
    // ],
  };

  console.log(chartConfigs);

  return (
    <Box>
      <HighchartsWrapper options={chartConfigs} />
    </Box>
  );
};

const BiologicalUploader = ({ farm, pond }) => {
  const [data, setdata] = useState({
    raw: [],
  });

  const cycleManagementStore = AppSelectors.cycleStore();

  const selectedCycle = cycleManagementStore?.selectedCycle;
  const isCycleSelected = _.isEmpty(selectedCycle);

  useEffect(() => {
    if (pond && !_.isEmpty(selectedCycle)) {
      if (pond.id === selectedCycle?.pond_id) {
        Actions.onLoadData();
      }
    }
  }, [pond, selectedCycle]);

  const Actions = {
    onLoadData: async () => {
      const response = await PondManagerServices.getTimeseriesData(
        pond?.id,
        ["weight", "current_density", "weight_diff", "biomass", "biomass_relative", "organisms", "survival_rate", "seeded_density", "seeded_pl"],
        selectedCycle?.start_date,
        selectedCycle?.end_date
      );
      setdata(response);
    },
    submitData: async (requestData) => {
      if (requestData.length > 0) {
        const response = await PondManagerServices.putData({
          rows: requestData,
        });
        Actions.onLoadData();
      }
    },
  };

  if (!pond) {
    return (
      <Box p={2}>
        <AlertBox icon={<HelpOutlineIcon />}>
          <Text>interface.alert.please-select-a-pond</Text>!
        </AlertBox>
      </Box>
    );
  }

  if (isCycleSelected)
    return (
      <Box p={2}>
        <NoCycleAlertBox pondId={pond?.id} />
      </Box>
    );

  const stats = {
    currentWeight: _.last(data?.raw?.filter((ele) => ele.value && ele.field_id === "weight"))?.value,
    currentDensity: _.last(data?.raw?.filter((ele) => ele.value && ele.field_id === "current_density"))?.value,
    currentBiomass: _.last(data?.raw?.filter((ele) => ele.value && ele.field_id === "biomass"))?.value,
    currentOrganisms: _.last(data?.raw?.filter((ele) => ele.value && ele.field_id === "organisms"))?.value,
    currentSurvivalRate: _.last(data?.raw?.filter((ele) => ele.value && ele.field_id === "survival_rate"))?.value,
  };

  return (
    <Box p={2}>
      <Box p={2} bgcolor={theme.palette.primary.light} borderRadius={2}>
        <Typography variant="cardTitle" color="primary">
          <Text>interface.actions.record-new-data</Text>
        </Typography>
        <Box mt={2}>
          <Formik
            initialValues={{
              created: moment(),
            }}
            onSubmit={(values) => {
              if (values.weight || values.density) {
                const requestData = ["weight", "current_density"]
                  .filter((f) => values[f])
                  .map((f) => ({
                    datetime: values.created.format("YYYY-MM-DD hh:mm:ss"),
                    value: values[f],
                    pond_id: pond.id,
                    field_id: f,
                  }));

                Actions.submitData(requestData);
              }
            }}
          >
            {({ values, handleChange, setFieldValue }) => {
              return (
                <Form>
                  <Stack spacing={1}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} lg={4}>
                        <MuiDatetimePicker
                          value={values.created}
                          onAccept={(newValue) => {
                            setFieldValue("created", newValue);
                          }}
                          onChange={(newValue) => {
                            setFieldValue("created", newValue);
                          }}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={4}>
                        <MuiBigTextField name="weight" onChange={handleChange} label={getText("fields.weight") + " (g)"} fullWidth />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MuiBigTextField name="current_density" onChange={handleChange} label={getText("fields.current_density") + " (shrimps/m2)"} fullWidth />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <Button variant="contained" type="submit" fullWidth>
                          <Text>interface.actions.submit</Text>
                        </Button>
                      </Grid>
                    </Grid>
                  </Stack>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>

      <Box mt={2} p={2} bgcolor={colors.grey[100]} borderRadius={2}>
        <Typography variant="cardTitle" color="primary">
          <Text>interface.general.current-data</Text>
        </Typography>
        <Box mt={2}>
          {/* <CombinedChart
            list={[
              {
                type: "line",
                color: colors.orange[400],
                rows: data?.raw?.filter((ele) => ele.value && ele.field_id === "weight"),
                name: getText("fields.weight"),
                title: (
                  <span>
                    {getText("fields.weight")}: {NumericFormatters.formatWeightInG({ value: stats.currentWeight })}
                  </span>
                ),
              },
              {
                type: "column",
                color: colors.orange[400],
                rows: data?.raw?.filter((ele) => ele.value && ele.field_id === "current_density"),
                name: getText("fields.current_density"),
                title: (
                  <span>
                    {getText("fields.current_density")}: {NumericFormatters.format({ value: stats.currentDensity, suffix: " / m2" })}
                  </span>
                ),
              },
              {
                type: "line",
                color: colors.blue[400],
                rows: data?.raw?.filter((ele) => ele.value && ele.field_id === "biomass"),
                name: getText("fields.biomass"),
                title: (
                  <span>
                    {getText("fields.biomass")}: {NumericFormatters.formatWeightInKg({ value: stats.currentBiomass })}
                  </span>
                ),
              },
              {
                type: "column",
                color: colors.blue[400],
                rows: data?.raw?.filter((ele) => ele.value && ele.field_id === "organisms"),
                name: getText("fields.organisms"),
                title: (
                  <span>
                    {getText("fields.organisms")}: {NumericFormatters.format({ value: stats.currentOrganisms, decimalScale: 0 })}
                  </span>
                ),
              },
              {
                type: "column",
                color: colors.blue[400],
                rows: data?.raw?.filter((ele) => ele.value && ele.field_id === "survival_rate"),
                name: getText("fields.survival_rate"),
                title: (
                  <span>
                    {getText("fields.survival_rate")}: {NumericFormatters.format({ value: stats.currentSurvivalRate, decimalScale: 0, suffix: "%" })}
                  </span>
                ),
              },
            ]}
            cycle={selectedCycle}
          /> */}
          <CommonChart
            type="line"
            cycle={selectedCycle}
            rows={data?.raw?.filter((ele) => ele.value && ele.field_id === "weight")}
            color={colors.orange[400]}
            name={getText("fields.weight")}
            title={
              <span>
                {getText("fields.weight")}: {NumericFormatters.formatWeightInG({ value: stats.currentWeight })}
              </span>
            }
          />
          <CommonChart
            type="column"
            cycle={selectedCycle}
            rows={data?.raw?.filter((ele) => ele.value && ele.field_id === "current_density")}
            color={colors.orange[400]}
            name="Density"
            title={
              <span>
                {getText("fields.current_density")}: {NumericFormatters.format({ value: stats.currentDensity, suffix: " / m2" })}
              </span>
            }
          />
          <CommonChart
            type="line"
            cycle={selectedCycle}
            rows={data?.raw?.filter((ele) => ele.value && ele.field_id === "biomass")}
            color={colors.blue[400]}
            name="Biomass"
            title={
              <span>
                {getText("fields.biomass")}: {NumericFormatters.formatWeightInKg({ value: stats.currentBiomass })}
              </span>
            }
          />
          <CommonChart
            type="column"
            cycle={selectedCycle}
            rows={data?.raw?.filter((ele) => ele.value && ele.field_id === "organisms")}
            color={colors.blue[400]}
            name="Population"
            title={
              <span>
                {getText("fields.organisms")}: {NumericFormatters.format({ value: stats.currentOrganisms, decimalScale: 0 })}
              </span>
            }
          />
          <CommonChart
            type="column"
            cycle={selectedCycle}
            rows={data?.raw?.filter((ele) => ele.value && ele.field_id === "survival_rate")}
            color={colors.blue[400]}
            name="Survival Rate"
            title={
              <span>
                {getText("fields.survival_rate")}: {NumericFormatters.format({ value: stats.currentSurvivalRate, decimalScale: 0, suffix: "%" })}
              </span>
            }
          />
        </Box>
      </Box>

      <Box mt={2} p={2} bgcolor={colors.grey[100]} borderRadius={2}>
        <Typography variant="cardTitle" color="primary">
          <Text>interface.general.data-logs</Text>
        </Typography>
        <Box mt={2}>
          <DataLogs
            rows={_.orderBy(
              data.raw.filter((ele) => ele.field_id === "weight" || ele.field_id === "current_density"),
              "datetime",
              "desc"
            )}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default BiologicalUploader;
