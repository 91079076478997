import { Box, Button, Grid, Stack, TextField, Typography, colors } from "@mui/material";
import DndFileInput from "components/input/DndFileInput";
import Text, { getText } from "components/text/Text";
import { Form, Formik } from "formik";
import _ from "lodash";
import { useEffect } from "react";
import { useState } from "react";
import { BiSolidFile } from "react-icons/bi";
import palette from "themes/palette";
import { DocumentViewer } from "../Farm/DocumentViewer";
import { useMediaQuery } from "@mui/material";

const DocumentForm = ({ docId, document, documentTypes, onSubmit, downloadDocumentUrl }) => {
  const isBigScreen = useMediaQuery("(min-width:1500px)");

  const [documentPrevUrl, setDocumentPrevUrl] = useState();

  const getDocumentUrl = async () => {
    const res = await downloadDocumentUrl(docId);
    setDocumentPrevUrl(res);
  };

  useEffect(() => {
    downloadDocumentUrl && getDocumentUrl();
  }, [docId]);

  const isCreate = docId == null;
  const formTitlte = isCreate ? getText("interface.general.new-document") : getText("interface.actions.edit");
  const initialFormValues = isCreate
    ? {
        type: documentTypes[0]?.id,
        tag: "",
        note: "",
      }
    : {
        docid: docId,
        label: document?.label,
        type: document?.type,
        tag: document?.tag,
        note: document?.note,
      };

  return (
    <Box>
      <Box>
        <Formik
          initialValues={initialFormValues}
          onSubmit={(values) => {
            onSubmit(values);
          }}
        >
          {({ values, handleChange, setFieldValue }) => {
            return (
              <Form>
                <Stack spacing={2}>
                  <Typography variant="pageTitle">{formTitlte}</Typography>
                  <Typography>Select a document type:</Typography>
                  <Grid spacing={1} container width={"100%"}>
                    {documentTypes.map((t) => {
                      return (
                        <Grid item xs={3} key={t?.id} onClick={() => setFieldValue("type", t?.id)}>
                          <Box
                            sx={{
                              p: 1,
                              transition: "all .2s",
                              bgcolor: values?.type === t?.id ? palette.secondary.light : colors.grey[50],
                              borderRadius: 1,
                              border: `2px solid ${values?.type === t?.id ? palette.secondary.main : colors.grey[400]}`,
                              color: values?.type === t?.id ? palette.secondary.main : colors.grey[400],
                              "&:hover": {
                                cursor: "pointer",
                              },
                            }}
                          >
                            <Stack gap={0.5} direction={isBigScreen ? "row" : "column"} alignItems={"center"}>
                              <Box
                                sx={{
                                  svg: {
                                    fontSize: 20,
                                  },
                                }}
                              >
                                <BiSolidFile />
                              </Box>
                              <Typography fontSize={12} fontWeight={"bold"}>
                                {t?.name}
                              </Typography>
                            </Stack>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>

                  <Box>
                    {isCreate && (
                      <DndFileInput
                        name="file"
                        value={values.file}
                        handleChange={(file) => {
                          setFieldValue("file", file);
                          setFieldValue("filename", file.name);
                          setFieldValue("file_extension", _.last(file.name.split(".")));
                          if (!values?.label) {
                            setFieldValue("label", file.name);
                          }
                        }}
                        required
                      />
                    )}
                  </Box>

                  <Box>
                    <Typography>
                      <Text>interface.general.label</Text>:
                    </Typography>
                    <TextField value={values.label} onChange={handleChange} name="label" fullWidth required></TextField>
                  </Box>
                  <Box>
                    <Typography>
                      {/* <Text>interface.general.tag</Text> */}
                      Tag:
                    </Typography>
                    <TextField value={values.tag} onChange={handleChange} name="tag" fullWidth></TextField>
                  </Box>
                  <Box>
                    <Typography>
                      {/* <Text>interface.general.note</Text> */}
                      Note:
                    </Typography>
                    <TextField multiline={true} name="note" onChange={handleChange} fullWidth></TextField>
                  </Box>
                  <Box>
                    <Stack direction={"row"} gap={1}>
                      <Button variant="contained" type="submit">
                        <Text>interface.actions.submit</Text>
                      </Button>
                    </Stack>
                  </Box>

                  {documentPrevUrl && (
                    <Box my={2}>
                      <DocumentViewer key={documentPrevUrl} docUrl={documentPrevUrl} />
                    </Box>
                  )}
                </Stack>
              </Form>
            );
          }}
        </Formik>
      </Box>

      {/* <Box>
        <Typography>Share With</Typography>
        <TextField
          multiline
          name="note"
          // onChange={handleChange}
          fullWidth
          placeholder="Email address"
        />
      </Box>
      <Box>
        <Typography>Expiration Date</Typography>
        <Select
          value={72} // set the default value to 72
          // onChange={handleChange}
          fullWidth
        >
          <MenuItem value={1}>1H</MenuItem>
          <MenuItem value={2}>2H</MenuItem>
          <MenuItem value={72}>72H</MenuItem>
          <MenuItem value={24}>1D</MenuItem>
          <MenuItem value={48}>2D</MenuItem>
          <MenuItem value={720}>30D</MenuItem>
          <MenuItem value={"anytime"}>Anytime</MenuItem>
        </Select>
      </Box>

      <Box pt={2}>
        <Stack direction={"row"} gap={1}>
          <Button variant="contained" type="submit">
            <Text>Share</Text>
          </Button>
        </Stack>
      </Box> */}
    </Box>
  );
};

export default DocumentForm;
