import { Box, Button, Chip, Grid, Stack, Typography } from "@mui/material";
import CommonDialog, { CommonDialogIds } from "components/Dialogs/CommonDialog";
import { DeleteIcon, EditIcon } from "components/Icons/MaterialIcons";
import Text, { getText } from "components/text/Text";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { dialogReducerActions } from "redux/dialog";
import ImageUpdateForm from "screens/Aquaculture/components/Files/components/ImageUpdateForm";

const MobilePhotoLogsImageViewer = ({ image, onSubmit, onDelete }) => {
  const [editMode, setEditMode] = useState(false);
  const dispatch = useDispatch();
  const tagList = image?.tags?.split(",");

  const Actions = {
    onChangeToEdit: () => {
      setEditMode(true);
    },
    onConfirmDeleteConfirm: () => {
      dialogReducerActions.openCommonDialog(dispatch, {
        id: CommonDialogIds.IMAGE_MODEL_DELETE_CONFIRM,
      });
    },
    postImageUpdateCanceled: () => {
      setEditMode(false);
    },
    postImageUpdateSubmitted: () => {
      setEditMode(false);
    },
  };

  return (
    <Box pb={4}>
      <CommonDialog id={CommonDialogIds.IMAGE_MODEL_DELETE_CONFIRM} title={getText("interface.alert.alert-are-you-sure")} action={() => onDelete(image)} />
      <Stack>
        <Box
          sx={{
            img: {
              width: "100%",
            },
          }}
        >
          <img src={image.url} />
        </Box>
      </Stack>
      <Box>
        {editMode ? (
          <ImageUpdateForm image={image} onSubmit={onSubmit} postSubmit={Actions.postImageUpdateSubmitted} postCancel={Actions.postImageUpdateCanceled} />
        ) : (
          <Stack spacing={3}>
            <Box>
              <Typography fontWeight={800}>{image?.label}</Typography>
              <Typography fontSize={12}>
                {image?.datetime} • {image?.file_name}
              </Typography>
              <Typography fontSize={12}>
                by{" "}
                {image?.user_id && (
                  <strong>
                    {image?.firstname} {image?.lastname} ({image.email})
                  </strong>
                )}
              </Typography>
            </Box>
            <Stack direction={"row"} spacing={1}>
              {tagList.map((tag) => {
                return <Chip label={`#${tag}`} color="secondary" size="small" />;
              })}
            </Stack>
            <Box>
              <Typography fontSize={12} fontWeight={800}>
                NOTE:
              </Typography>
              <Typography
                fontSize={12}
                sx={{
                  whiteSpace: "pre-line",
                }}
              >
                {image?.note}
              </Typography>
            </Box>
            <Box>
              <Stack spacing={1}>
                <Button variant="outlined" startIcon={<EditIcon />} onClick={() => setEditMode(true)}>
                  <Text>interface.actions.edit</Text>
                </Button>
                <Button variant="contained" color="error" startIcon={<DeleteIcon />} onClick={Actions.onConfirmDeleteConfirm}>
                  <Text>interface.actions.delete</Text>
                </Button>
              </Stack>
            </Box>
          </Stack>
        )}
      </Box>
    </Box>
  );
};

export default MobilePhotoLogsImageViewer;
