/**
 * Return Group Multiple Timeseries Arrays to one single Timeseries Array
 * 
 * input: timeseries1: [ {date1, value1}, {date2, value2}, {date3, value3} ... ]
    timeseries2: [ {date1, value1}, {date2, value2}, {date3, value3} ... ]
    timeseries3: [ {date1, value1}, {date2, value2}, {date3, value3} ... ]
 * 
 * return: timeseries: [ {date1, [ts1:v1, ts2:v1, ts3:v1]}, {date2, [ts1:v2, ts2:v2, ts3:v2, ...]}, {date3, [ts1:v3, ts2:v3, ts3:v3, ...]} ... ]
 * 
 * calculate Total and Average of TimeSeries Data
 * calculate maxmum, minimum & standard deviation 
 * 
 */

import moment from "moment";

export default function getGroupTimeseriesAggregation(array, selectedField, interval) {
  let arrayWithInterval = array;
  if (interval === "daily") {
    arrayWithInterval = array.map((arr) =>
      arr.map((ele) => ({
        ...ele,
        datetime: moment(ele.datetime).format("YYYY-MM-DD 00:00:00"),
      }))
    );
  } else if (interval === "weekly") {
    arrayWithInterval = array.map((arr) =>
      arr.map((ele) => ({
        ...ele,
        datetime: moment(ele.datetime).startOf("week").format("YYYY-MM-DD 00:00:00"),
      }))
    );
  }

  let flattenArray = _.flatten(arrayWithInterval);

  flattenArray.sort(function compare(a, b) {
    let dateA = new Date(a.datetime);
    let dateB = new Date(b.datetime);
    return dateA - dateB;
  });

  let result = _(flattenArray)
    .groupBy("datetime")
    .map(function (items, datetime) {
      return {
        value: _.map(items, `${selectedField}`),
        datetime: datetime,
      };
    })
    .value();

  let total = result.map((ele) => {
    return {
      value: ele.value.reduce((a, b) => a + b, 0),
      datetime: ele.datetime,
    };
  });

  let average = result.map((ele) => {
    return {
      value: ele.value.reduce((a, b) => a + b, 0) / ele.value.length || 0,
      datetime: ele.datetime,
    };
  });

  let min = result.map((ele) => {
    return {
      value: _.min(ele.value),
      datetime: ele.datetime,
    };
  });

  let max = result.map((ele) => {
    return {
      value: _.max(ele.value),
      datetime: ele.datetime,
    };
  });

  let standardDeviation = result.map((ele) => {
    const mean = ele.value.reduce((a, b) => a + b, 0) / ele.value.length || 0;
    const variance = ele.value.reduce((a, b) => a + (b - mean) ** 2, 0) / (ele.value.length - 1) || 0;

    return {
      value: Math.sqrt(variance),
      datetime: ele.datetime,
    };
  });

  return { total, average, min, max, standardDeviation };
}
