import React, { useState } from "react";
import DiseaseUploader from "screens/Aquaculture/components/DataManager/DiseaseUploader";
import PondDataBulkUploader from "screens/Aquaculture/components/DataManager/PondDataBulkUploader";
import SideMenuLayout from "screens/Aquaculture/components/Layout/SideMenuLayout";
import { GiChemicalDrop, GiMedicalDrip, GiMedicinePills, GiMoneyStack, GiPlatform, GiShrimp, GiSwapBag, GiTestTubes, GiWaterSplash, GiWebSpit } from "react-icons/gi";
import WaterQualityUploader from "screens/Aquaculture/components/DataManager/WaterQualityUploader";
import HarvestUploader from "screens/Aquaculture/components/DataManager/HarvestUploader";
import FeedUploader from "screens/Aquaculture/components/DataManager/FeedUploader";
import BiologicalUploader from "screens/Aquaculture/components/DataManager/BiologicalUploader";
import FinanceUploader from "screens/Aquaculture/components/DataManager/FinanceUploader";
import Text from "components/text/Text";
import PondList from "screens/Aquaculture/components/DataManager/PondList";
import { ListIcon } from "components/Icons/MaterialIcons";
import AdditiveUploader from "screens/Aquaculture/components/DataManager/AdditiveUploader";
import PondTreatmentUploader from "screens/Aquaculture/components/DataManager/PondTreatmentUploader";
import PondManagerAccessWrapper from "components/Wrapper/PondManagerAccessWrapper";
import { ResourcePolicies } from "screens/Aquaculture/components/AccessControl/ResourcePolicies";

/**
 *
 * Pond Management / Data Manager Home
 *
 * TODO:
 *  move farm and pond to redux
 *
 * @param {} param0
 * @returns
 */
const DataManagerHome = ({ farm, pond, ponds }) => {
  const MENULIST = [
    {
      label: <Text>interface.actions.bulk-upload</Text>,
      description: "",
      value: 0,
      icon: <GiPlatform />,
      component: (
        <PondManagerAccessWrapper policies={[ResourcePolicies["DATA:BULKUPLOAD"]]}>
          <PondDataBulkUploader farm={farm} pond={pond} />
        </PondManagerAccessWrapper>
      ),
    },
    {
      label: <Text>interface.general.water-quality</Text>,
      description: "",
      value: 1,
      icon: <GiWaterSplash />,
      component: (
        <PondManagerAccessWrapper policies={[ResourcePolicies["DATA:WATER_QUALITY:WRITE"]]}>
          <WaterQualityUploader farm={farm} pond={pond} />
        </PondManagerAccessWrapper>
      ),
    },
    {
      label: <Text>interface.general.feed</Text>,
      description: "",
      value: 2,
      icon: <GiSwapBag />,
      component: (
        <PondManagerAccessWrapper policies={[ResourcePolicies["DATA:FARMING_PRODUCT:WRITE"]]}>
          <FeedUploader farm={farm} pond={pond} />
        </PondManagerAccessWrapper>
      ),
    },
    {
      label: <Text>interface.general.additive</Text>,
      description: "",
      value: 2.1,
      icon: <GiMedicinePills />,
      component: (
        <PondManagerAccessWrapper policies={[ResourcePolicies["DATA:FARMING_PRODUCT:WRITE"]]}>
          <AdditiveUploader farm={farm} pond={pond} />
        </PondManagerAccessWrapper>
      ),
    },
    {
      label: <Text>interface.general.pond-treatment</Text>,
      description: "",
      value: 2.2,
      icon: <GiChemicalDrop />,
      component: (
        <PondManagerAccessWrapper policies={[ResourcePolicies["DATA:FARMING_PRODUCT:WRITE"]]}>
          <PondTreatmentUploader farm={farm} pond={pond} />
        </PondManagerAccessWrapper>
      ),
    },
    {
      label: <Text>interface.general.biological</Text>,
      description: "",
      value: 3,
      icon: <GiShrimp />,
      component: (
        <PondManagerAccessWrapper policies={[ResourcePolicies["DATA:BIOLOGICAL:WRITE"]]}>
          <BiologicalUploader farm={farm} pond={pond} />
        </PondManagerAccessWrapper>
      ),
    },
    {
      label: <Text>interface.general.disease</Text>,
      description: "",
      value: 4,
      icon: <GiTestTubes />,
      component: (
        <PondManagerAccessWrapper policies={[ResourcePolicies["DATA:DISEASES:WRITE"]]}>
          <DiseaseUploader farm={farm} pond={pond} />
        </PondManagerAccessWrapper>
      ),
    },
    {
      label: <Text>interface.general.harvest</Text>,
      description: "",
      value: 5,
      icon: <GiWebSpit />,
      component: (
        <PondManagerAccessWrapper policies={[ResourcePolicies["DATA:HARVEST:WRITE"]]}>
          <HarvestUploader farm={farm} pond={pond} />
        </PondManagerAccessWrapper>
      ),
    },
    {
      label: <Text>interface.general.finance</Text>,
      description: "",
      value: 6,
      icon: <GiMoneyStack />,
      component: (
        <PondManagerAccessWrapper policies={[ResourcePolicies["DATA:FINANCE:WRITE"]]}>
          <FinanceUploader farm={farm} pond={pond} />
        </PondManagerAccessWrapper>
      ),
    },
    {
      label: <Text>interface.general.ponds</Text>,
      description: "",
      value: 7,
      icon: <ListIcon />,
      component: (
        <PondManagerAccessWrapper policies={[ResourcePolicies.SETTINGS]}>
          <PondList ponds={ponds} />
        </PondManagerAccessWrapper>
      ),
    },
  ];

  return <SideMenuLayout menulist={MENULIST} title={<Text>interface.general.data-manager</Text>} defaultViewMode={0} />;
};

export default DataManagerHome;
