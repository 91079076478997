import ChatBoard from "components/ChatBoard/ChatBoard";
import { chatMockApis } from "mock/chatMock";
import React, { useEffect, useState } from "react";

const PrivateChatBoardWrapper = ({
  farmId,
  chatId,
  isFloatingMessage,
  isMenuCollapsed,
}) => {
  const [conversations, setConversations] = useState([]);

  useEffect(() => {
    if (chatId) {
      const result = chatMockApis.getChatHistory(farmId, chatId);
      setConversations(result);
    }
  }, [chatId]);

  return (
    <ChatBoard
      isFloatingMessage={isFloatingMessage}
      isMenuCollapsed={isMenuCollapsed}
      messages={conversations}
    />
  );
};

export default PrivateChatBoardWrapper;
