import { Box, Button, Card, Grid, IconButton, Stack, Typography, colors } from "@mui/material";
import _ from "lodash";
import React from "react";
import Text from "components/text/Text";
import { ShrimplImagePlaceholerImg } from "components/Images";
import { Link } from "react-router-dom";
import { ROUTE_ORGANISATION_ADMIN, ROUTE_ORGANISATION_EDIT } from "routes/paths";
import { AdminPanelSettingsIcon, LanguageIcon } from "components/Icons/MaterialIcons";

const Styles = {
  farmCardLayout: {
    border: "2px solid #edf1f2",
    "&:hover": {
      cursor: "pointer",
      border: "2px solid #ddd",
    },
    borderRadius: 3,
    "&:hover .flagWrapper": {
      top: "50px",
      opacity: 1,
    },
  },
};

const OrgCard = ({ org }) => {
  return (
    <Card sx={Styles.farmCardLayout}>
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box
          height={"200px"}
          sx={{
            backgroundImage: `url("${org?.banner_url || ShrimplImagePlaceholerImg}")`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundColor: "#CCC",
            backgroundRepeat: "no-repeat",
            transition: "all .2s",
          }}
        >
          <Stack width={"100%"} height={"100%"} alignItems={"center"} justifyContent={"center"}>
            <Box
              sx={{
                width: 120,
                height: 120,
                borderRadius: "50%",
                border: "3px solid #EEE",
                backgroundImage: `url(${org?.logo_url})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundColor: "#FFF",
              }}
            ></Box>
          </Stack>
        </Box>
        <Box
          p={1}
          textAlign={"center"}
          sx={{
            bgcolor: colors.blueGrey[50],
          }}
        >
          <Typography fontWeight={"bold"} textOverflow={"ellipsis"} whiteSpace={"nowrap"} fontSize={18} color={colors.blueGrey[700]}>
            {org.name}
          </Typography>
          <Typography color={colors.blueGrey[700]} fontSize={12}>
            <Box>{org.org_type}</Box>
          </Typography>
        </Box>
      </Box>
      <Box borderTop={"1px solid #edf1f2"}>
        <Grid container>
          <Grid item xs={6} p={1}>
            <Button fullWidth component={Link} to={`${ROUTE_ORGANISATION_ADMIN}/${org.id}`} startIcon={<AdminPanelSettingsIcon />}>
              <Text>interface.access-control.admin</Text>
            </Button>
          </Grid>
          <Grid item xs={6} p={1}>
            <Stack direction={"row"} justifyContent={"space-around"}>
              <IconButton
                color="primary"
                onClick={() => {
                  org?.link && window.open(org?.link);
                }}
              >
                <LanguageIcon />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default OrgCard;
