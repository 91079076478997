export const ROUTE_HOME = "/";
export const ROUTE_LOGIN = "/login";
export const ROUTE_MICROSOFT_SIGNED_IN = "/microsoft-signed-in";
export const ROUTE_GOOGLE_SIGNED_IN = "/google-signed-in";
export const ROUTE_SIGNUP = "/signup";
export const ROUTE_SIGNUP_FOR_SHRIMPL = "/signup";
export const ROUTE_FORGOT_PASSWORD = "/forgot-password";
export const ROUTE_RESET_PASSWORD = "/reset-password";
export const ROUTE_SIGNUP_FOR_INTERNAL = "/admin-signup";
export const ROUTE_EMAIL_NOT_VERIFIED = "/email-not-verified";
export const ROUTE_CONFIRM_EMAIL = "/verify-email";
export const ROUTE_POND_MANAGER_HOME_PAGE = "/aqua/pond-manager";
export const ROUTE_POND_MANAGER_FARM_CREATE = "/aqua/pond-manager/create";
export const ROUTE_POND_MANAGER_FARM_EDIT = "/aqua/pond-manager/edit";
export const ROUTE_USER_PROFILE = "/settings/profile";
export const ROUTE_CLIMATE = "/aqua/climate";

export const ROUTE_ORGANISATION_HOME = "/aqua/orgs";
export const ROUTE_ORGANISATION_CREATE = "/aqua/orgs/create";
export const ROUTE_ORGANISATION_EDIT = "/aqua/orgs/edit";
export const ROUTE_ORGANISATION_ADMIN = "/aqua/orgs/admin";

export const ROUTE_CLIENT_MANAGER = "/aqua/client-manager";

// ~~~ admin ~~~
export const ROUTE_ADMIN = "/admin";
export const ROUTE_ADMIN_FARMING_PRODUCTS = "/admin/farming-products";

// -- extra --
export const ROUTE_DESIGN_GUIDE = "design-guide";
