import React, { Component } from "react";
import { api_bomonline_station_meta, api_bomonline_data } from "../../../api/api";
import styled from "styled-components";
import { generateSeasonal } from "helpers/ChartFunctions";
import { hasItem } from "../../../helpers/Utils";
import moment from "moment";
import MultiViewChart from "../../../components/chart/MultiViewChart";
import { Box, Grid, MenuItem, Select, Stack, Typography } from "@mui/material";
import LoadingBox from "components/Loading/LoadingBox";

const Layout = styled.div`
  box-sizing: border-box;
`;

export default class BomOnlineCard extends Component {
  constructor(p) {
    super(p);
    this.state = {
      meta: [],
      data: [],
      seasonalStart: 1,
      isLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.payload) {
      this.getData();
    }
  }

  getData() {
    const station_name = this.props.payload.station_name;
    api_bomonline_station_meta(station_name)
      .then((res) => {
        this.setState({
          meta: res.data.message,
        });
      })
      .catch((err) => {
        this.setState({ isLoading: true });
      });
  }

  getTableData(ts_id) {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        api_bomonline_data(ts_id).then((res) => {
          let data = res.data.message;
          data.data = data.data.filter((ele) => ele[1]).map((ele) => [moment(ele[0]).format("YYYY-MM-DD"), ele[1]]);

          this.setState({
            ...data,
            isLoading: false,
          });
        });
      }
    );
  }

  onChangeOption(ts_id) {
    this.getTableData(ts_id);
  }

  onChangeSeasonalStart(newStart) {
    this.setState({
      seasonalStart: newStart,
    });
  }

  render() {
    const payload = this.props.payload;

    // ==== state ====
    let meta = this.state.meta;
    const timeseries = this.state.data;
    const ts_id = this.state.ts_id;
    const seasonalStart = this.state.seasonalStart;
    const isLoading = this.state.isLoading;

    // ==== Other ====
    const hasData = timeseries.length > 0;
    const seasonalStartOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((ele, idx) => ({
      label: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][idx],
      value: ele,
    }));

    if (!payload) {
      return <div>SELECT A STATION</div>;
    } else {
      meta = meta.filter((ele) => hasItem("DailyMean", ele.ts_name));

      const selected = meta.filter((ele) => ele.ts_id === ts_id)[0] || {};
      const itemCode = selected.itemCode;
      let calCum = false;
      if (hasItem(itemCode, ["SNOW", "SNWD", "PRCP"])) {
        calCum = true;
      }
      const chartData = {
        line: [
          {
            name: ts_id,
            data: timeseries.map((ele) => [Date.parse(ele[0]), ele[1]]),
          },
        ],
        seasonal: generateSeasonal(timeseries, false, seasonalStart, calCum),
      };

      const infoBox = () => {
        return (
          <Stack spacing={0} my={2}>
            {[
              ["ts_name", "NAME"],
              ["ts_id", "ID"],
              ["ts_unitname", "Unit"],
            ].map((ele, key) => (
              <Stack direction={"row"} spacing={1}>
                <Typography fontWeight={800}>{ele[1]}: </Typography>
                <Typography>{this.state[ele[0]]}</Typography>
              </Stack>
            ))}
          </Stack>
        );
      };

      return (
        <Layout>
          <Box mt={2}>
            <Typography fontSize={20}>
              <i className="fas fa-water"></i> {payload.station_name}
            </Typography>
          </Box>

          <Grid spacing={2} container mt={1}>
            <Grid item xs={6}>
              <Typography>Data Point</Typography>
              <Select fullWidth onChange={(e) => this.onChangeOption(e.target.value)}>
                {meta.map((ele) => {
                  return <MenuItem value={ele.ts_id}>{ele.parametertype_name}</MenuItem>;
                })}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <Typography>Season Starts</Typography>
              <Select fullWidth onChange={(e) => this.onChangeSeasonalStart(e.target.value)}>
                {seasonalStartOptions.map((ele) => {
                  return <MenuItem value={ele.value}>{ele.label}</MenuItem>;
                })}
              </Select>
            </Grid>
          </Grid>

          <Box my={2}>
            {isLoading ? (
              <LoadingBox />
            ) : hasData ? (
              <div>
                {infoBox()}
                <MultiViewChart chartid={this.state.seasonalStart} height={500} data={chartData} />
              </div>
            ) : (
              "No Data Available"
            )}
          </Box>
        </Layout>
      );
    }
  }
}
