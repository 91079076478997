import { useEffect, useState } from "react";

import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { userActions } from "redux/user";
import { useDispatch } from "react-redux";
import { Alert, Box, Button, Stack, Typography } from "@mui/material";
import { KeyboardArrowRight } from "@mui/icons-material";
import { AppSelectors } from "redux/AppReducers";

const MicrosoftSignedIn = () => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();

  const [isMultiAccounts, setMultiAccounts] = useState(false);
  const [accountNotFound, setAccountNotFound] = useState(false);

  const userStore = AppSelectors.userStore();

  const activeAccount = instance.getActiveAccount();
  const allAccounts = instance.getAllAccounts();

  const Actions = {
    signin: async () => {
      if (activeAccount) {
        const account = activeAccount;
        const tokenRequest = {
          account: account,
          scopes: ["User.Read"],
        };
        const msRsp = await instance.acquireTokenSilent(tokenRequest);
        const idToken = msRsp.accessToken;
        await userActions.login(dispatch, {
          microsoftIdToken: idToken,
          oauthStep: 0,
        });
      }
    },
    signup: async () => {
      const account = activeAccount;
      if (activeAccount) {
        const tokenRequest = {
          account: account,
          scopes: ["User.Read"],
        };
        const msRsp = await instance.acquireTokenSilent(tokenRequest);
        const idToken = msRsp.accessToken;
        await userActions.login(dispatch, {
          microsoftIdToken: idToken,
          oauthStep: 1,
        });
      }
    },
  };

  useEffect(() => {
    if (userStore?.loginError !== "") {
      setAccountNotFound(true);
    }
  }, [userStore]);

  useEffect(() => {
    isAuthenticated && Actions.signin();
  }, [isAuthenticated]);

  return (
    <Box width="100vw" height="100vh" backgroundColor={"#0a2f3d"}>
      <Stack
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        {accountNotFound ? (
          <Box>
            <Alert severity="error">
              There's no account's email associated
            </Alert>
            <Box mt={5}>
              <Button
                endIcon={<KeyboardArrowRight />}
                color="secondary"
                variant="contained"
                onClick={Actions.signup}
              >
                Create an Shrimpl account for me!
              </Button>
            </Box>
          </Box>
        ) : (
          <Typography color="secondary">
            {accounts.length === 0
              ? "Loading..."
              : "Microsoft Signed In Successfully! Redirecting...."}
          </Typography>
        )}
      </Stack>
    </Box>
  );
};

export default MicrosoftSignedIn;
