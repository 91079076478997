import { Construction, ConstructionOutlined } from "@mui/icons-material";
import { Box, IconButton, Stack, Tooltip, Typography, colors } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import NumericFormatters from "helpers/NumericFormatters";
import TextFormatters from "helpers/TextFormatters";
import React, { useState } from "react";

const WqiDebugPopup = ({ data }) => {
  const tableConfigs = {
    rowData: data?.data,
    rowHeight: 20,
    headerHeight: 20,
    defaultColDef: {
      flex: 1,
    },
    columnDefs: [
      {
        headerName: "G",
        field: "group",
      },
      {
        headerName: "F",
        field: "field_id",
      },
      {
        headerName: "std",
        field: "std",
        cellRenderer: NumericFormatters.format,
      },
      {
        headerName: "lb",
        field: "lb",
        cellRenderer: NumericFormatters.format,
      },
      {
        headerName: "la",
        field: "la",
        cellRenderer: NumericFormatters.format,
      },
      {
        headerName: "Observed",
        field: "m",
        cellRenderer: NumericFormatters.format,
      },
      {
        headerName: "ta",
        field: "ta",
        cellRenderer: NumericFormatters.format,
      },
      {
        headerName: "tb",
        field: "tb",
        cellRenderer: NumericFormatters.format,
      },
      {
        headerName: "e",
        field: "e",
        cellRenderer: NumericFormatters.format,
      },
      {
        headerName: "P",
        field: "pv",
        cellRenderer: NumericFormatters.format,
      },
      {
        headerName: "W",
        field: "W",
        cellRenderer: NumericFormatters.format,
      },
    ],
  };

  return (
    <Box p={1}>
      <Box className="ag-theme-clean" height={500}>
        <AgGridReact {...tableConfigs} />
      </Box>
    </Box>
  );
};

const PondWaterQualitySummary = ({ data, fields }) => {
  const [displayDetails, setDisplayDetails] = useState(false);

  return (
    <Box>
      <Stack spacing={1} direction={"row"} alignItems={"center"}>
        <IconButton
          size="small"
          onClick={() => setDisplayDetails(!displayDetails)}
          sx={{
            bgcolor: displayDetails && colors.grey[200],
            svg: {
              fontSize: 14,
            },
          }}
        >
          <ConstructionOutlined />
        </IconButton>
        <Box>{TextFormatters.verticalLabelValue({ label: "WQI", value: NumericFormatters.format({ value: data?.result?.wqi, decimalScale: 2 }), labelFontSize: 14, valueFontSize: 30 })}</Box>
        <Stack direction={"row"}></Stack>
        {/* TODO: add a chart */}
      </Stack>
      <Box>{displayDetails && <WqiDebugPopup data={data} />}</Box>
    </Box>
  );
};

export default PondWaterQualitySummary;
