import { Avatar, Box, Button, ButtonGroup, Stack, Typography } from "@mui/material";
import AdminServices from "api/adminServices";
import { VerticalAlertBox } from "components/Alerts/AlertBox";
import { ArrowBackIcon, BusinessIcon, ListIcon, RefreshIcon } from "components/Icons/MaterialIcons";
import CommonModal from "components/Modal/CommonModal";
import FarmingProductTypes from "constants/FarmingProductTypes";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { BiPlusCircle } from "react-icons/bi";
import { GiSwapBag } from "react-icons/gi";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { alertsActions } from "redux/alerts";
import { modalIds, modalReducersActions } from "redux/modal";
import { ROUTE_ADMIN_FARMING_PRODUCTS } from "routes/paths";
import ProductForm from "screens/Admin/FarmingProduct/ProductForm";
import ProductTable from "screens/Admin/FarmingProduct/ProductTable";
import palette from "themes/palette";
import MuiTabs, { MuiTabsThemes } from "ui/tabs/MuiTabs";

const ProductHome = () => {
  const [error, setError] = useState(null);
  const [producer, setProducer] = useState(null);
  const [producers, setProducers] = useState([]);
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState(null);
  const [productType, setProductType] = useState(null);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const params = useParams();

  useEffect(() => {
    Actions.fetchProducts();
  }, [params, productType]);

  const Actions = {
    fetchProducts: async () => {
      const producerid = params.producerid;
      const producerDetails = await AdminServices.getProducerDetails(producerid);
      const producers = await AdminServices.fetchProducers();

      if (!producerDetails) {
        setError("Producer Is Not Found");
      } else {
        const productsResult = await AdminServices.fetchProducts({ producerid, limit: 999, productType });
        setProducer(producerDetails);
        setProducts(productsResult?.rows);
        setProducers(producers?.rows);
      }
    },
    onEditProduct: async ({ product }) => {
      setProduct(product);
      modalReducersActions.openModal(dispatch, { id: modalIds.ADMIN_PRODUCT_FORM });
    },
    onCreateProduct: async () => {
      setProduct(null);
      modalReducersActions.openModal(dispatch, { id: modalIds.ADMIN_PRODUCT_FORM });
    },
    onSubmitProduct: async (values) => {
      if (!product) {
        await AdminServices.createProduct({ formData: { producer_id: params.producerid, ...values } });
        modalReducersActions.closeModal(dispatch, { id: modalIds.ADMIN_PRODUCT_FORM });
        alertsActions.addInfo(dispatch, { content: "Product is created!" });
        Actions.fetchProducts();
      } else {
        await AdminServices.updateProduct({ formData: { producer_id: params.producerid, ...values } });
        modalReducersActions.closeModal(dispatch, { id: modalIds.ADMIN_PRODUCT_FORM });
        alertsActions.addInfo(dispatch, { content: "Product is updated!" });
        Actions.fetchProducts();
      }
    },
    onDirectEditProduct: async (product) => {
      await AdminServices.updateProduct({ formData: product });
      alertsActions.addInfo(dispatch, { content: "Product is updated!" });
      Actions.fetchProducts();
    },
    onDeleteProduct: async (product) => {
      await AdminServices.deleteProduct({ productId: product.id });
      alertsActions.addInfo(dispatch, { content: "Product is deleted!" });
      Actions.fetchProducts();
    },
    onReplicateProduct: async ({ product }) => {
      const productToReplicate = _.omit(product, ["id"]);
      await AdminServices.createProduct({ formData: productToReplicate });
      Actions.fetchProducts();
      modalReducersActions.closeModal(dispatch, { id: modalIds.ADMIN_PRODUCT_FORM });
      alertsActions.addInfo(dispatch, { content: "Product is replicated!" });
    },
  };

  if (error)
    return (
      <Box my={5}>
        <VerticalAlertBox title={error} actionName="Return" action={() => navigate(ROUTE_ADMIN_FARMING_PRODUCTS)} />
      </Box>
    );

  return (
    <Box p={2}>
      <CommonModal id={modalIds.ADMIN_PRODUCT_FORM}>
        <ProductForm producers={producers} producerId={producer?.id} product={product} onSubmit={Actions.onSubmitProduct} />
      </CommonModal>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Box>
          <Box mb={1}>
            <Typography color="grey" sx={{ a: { color: "grey" } }} fontWeight={800}>
              <Link to={ROUTE_ADMIN_FARMING_PRODUCTS}>Producers</Link>
              {" / "}
              <span style={{ color: palette.primary.main }}>{producer?.name}</span>
            </Typography>
          </Box>
          <Stack direction={"row"} spacing={1}>
            <ButtonGroup size="small" variant="outlined">
              <Button variant="outlined" startIcon={<BiPlusCircle />} onClick={Actions.onCreateProduct}>
                Create
              </Button>
              <Button variant="outlined" startIcon={<RefreshIcon />} onClick={Actions.fetchProducts}>
                Reload
              </Button>
            </ButtonGroup>
          </Stack>
          <Box mt={1}>
            <MuiTabs
              theme={MuiTabsThemes.compact}
              options={[
                {
                  value: null,
                  label: "All",
                  icon: <ListIcon />,
                },
                ..._.values(FarmingProductTypes),
              ]}
              value={productType}
              onNewValue={setProductType}
              withBorder
            />
          </Box>
        </Box>
        <Stack alignItems={"flex-end"} spacing={1}>
          <Typography fontWeight={800} fontSize={20}>
            {producer?.name}
          </Typography>
          <Avatar
            src={producer?.logo_url}
            sx={{
              width: 50,
              height: 50,
              border: "2px solid #CCC",
            }}
          >
            <BusinessIcon />
          </Avatar>
        </Stack>
      </Stack>

      <Box mt={2}>
        <ProductTable rows={products} actions={Actions} />
      </Box>
    </Box>
  );
};

export default ProductHome;
