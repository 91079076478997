import { Alert, Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { PondManagerServices } from "api/pondManagerServices";
import { CheckIcon, CloseIcon } from "components/Icons/MaterialIcons";
import TimeFormatters from "helpers/TimeFormatters";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { organisationManagementActions } from "redux/pondManagement/organisationManagement";
import BorderCard from "ui/Card/BorderCard";

const InviteBubble = ({ payload, postAction }) => {
  const [isAccepted, setAccepted] = useState(false);
  const [isRejected, setRejected] = useState(false);

  const dispatch = useDispatch();

  const Actions = {
    accept: async () => {
      await PondManagerServices.acceptOrgInvite(payload?.["org.id"]);
      organisationManagementActions.loadUserOrgs(dispatch);
      setAccepted(true);
      setTimeout(() => postAction(), 3000);
    },
    reject: async () => {
      await PondManagerServices.rejectOrgInvite(payload?.["org.id"]);
      organisationManagementActions.loadUserOrgs(dispatch);
      setRejected(true);
      postAction();
      setTimeout(() => postAction(), 3000);
    },
  };

  if (isAccepted) {
    return (
      <Alert
        severity="success"
        sx={{
          alignItems: "center",
        }}
      >
        <Typography fontSize={14}>
          <strong>{payload?.["org.name"]}</strong> Invited you as a Memeber.
        </Typography>
        <Typography fontSize={10}>{TimeFormatters.formatUtcDatetime(payload?.["member.created_at"])}</Typography>
      </Alert>
    );
  }

  if (isRejected) {
    return (
      <Alert
        severity="error"
        sx={{
          alignItems: "center",
        }}
      >
        <Typography fontSize={14}>
          <strong>{payload?.["org.name"]}</strong> Invited you as a Memeber.
        </Typography>
        <Typography fontSize={10}>{TimeFormatters.formatUtcDatetime(payload?.["member.created_at"])}</Typography>
      </Alert>
    );
  }

  return (
    <Alert
      severity="info"
      action={
        <Stack direction={"row"}>
          <IconButton color="inherit" onClick={Actions.accept}>
            <CheckIcon />
          </IconButton>
          <IconButton color="error" onClick={Actions.reject}>
            <CloseIcon />
          </IconButton>
        </Stack>
      }
      sx={{
        alignItems: "center",
      }}
    >
      <Typography fontSize={14}>
        <strong>{payload?.["org.name"]}</strong> Invited you as a Memeber.
      </Typography>
      <Typography fontSize={10}>{TimeFormatters.formatUtcDatetime(payload?.["member.created_at"])}</Typography>
    </Alert>
  );
};

const OrganisationInviteTable = () => {
  const [inviteList, setInviteList] = useState([]);

  useEffect(() => {
    Actions.load();
  }, []);

  const Actions = {
    load: async () => {
      const orgMembers = await PondManagerServices.getUserOrgs();
      setInviteList(
        _.orderBy(
          orgMembers?.member?.filter((ele) => !ele?.["member.activated"]),
          "member.created_at",
          "desc"
        )
      );
    },
  };

  if (inviteList.length === 0) return "";

  console.log(inviteList);

  return (
    <Box
      my={2}
      sx={{
        animation: "fadeIn .4s",
      }}
    >
      <BorderCard>
        <Box p={2}>
          <Typography fontWeight={"bold"} textTransform={"uppercase"} letterSpacing={1} sx={{ mb: 1 }}>
            Invitations
          </Typography>
          <Stack spacing={1}>
            {inviteList.map((ele, idx) => {
              return <InviteBubble key={ele?.["org.id"]} payload={ele} postAction={Actions.load} />;
            })}
          </Stack>
        </Box>
      </BorderCard>
    </Box>
  );
};

export default OrganisationInviteTable;
