import { Autocomplete, Box, Button, Grid, TextField, Typography } from "@mui/material";
import { PondManagerServices } from "api/pondManagerServices";
import ImageCropper from "components/Files/ImageCropper";
import Text, { getText } from "components/text/Text";
import Countries from "constants/Countries";
import { Form, Formik } from "formik";
import _ from "lodash";
import React from "react";
import OrgTypes from "screens/Aquaculture/Organisations/data/OrgTypes";

const getInitialFormValues = (org) => {
  if (org) {
    return org;
  } else {
    return {
      name: "",
      contact_number: "",
      mobile_number: "",
      country: Countries[0].label,
      orgType: OrgTypes.FEED_PRODUCER,
    };
  }
};

const OrganisationForm = React.memo(({ org, postSubmitAction }) => {
  const Actions = {
    onSubmit: async (form) => {
      const rsp = await PondManagerServices.createOrg(form);
      if (rsp.payload.status === "ok") {
        postSubmitAction && postSubmitAction();
      }
    },
  };

  return (
    <Box>
      <Formik
        initialValues={getInitialFormValues(org)}
        onSubmit={(values) => {
          Actions.onSubmit(values);
        }}
      >
        {({ values, handleChange, setFieldValue }) => {
          return (
            <Form>
              <Grid container spcaing={2}>
                <Grid item xs={12} md={8} container spacing={2}>
                  <Grid item xs={12}>
                    <TextField fullWidth label={getText("interface.general.name")} name="name" value={values.name} onChange={handleChange} required></TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      disablePortal
                      id="orgType"
                      value={values.org_type}
                      inputValue={values.org_type}
                      onChange={handleChange}
                      options={_.values(OrgTypes)}
                      onInputChange={(_, val) => setFieldValue("org_type", val)}
                      renderInput={(params) => <TextField {...params} label={getText("interface.general.organisation-type")} required />}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth label={getText("interface.general.contact-number")} name="contact_number" value={values.contact_number} onChange={handleChange}></TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth label={getText("interface.general.mobile-number", "Mobile Number")} name="mobile_number" value={values.mobile_number} onChange={handleChange}></TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      multiline
                      fullWidth
                      label={getText("interface.general.description")}
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      inputProps={{
                        style: {
                          minHeight: 200,
                        },
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField fullWidth label={<Text>interface.general.street</Text>} name="street" value={values.street} onChange={handleChange}></TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField fullWidth label={<Text>interface.general.state-or-region</Text>} name="region" value={values.region} onChange={handleChange}></TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <Autocomplete
                      disablePortal
                      id="country"
                      value={values.country}
                      inputValue={values.country}
                      onChange={handleChange}
                      options={Countries.map((ele) => ele.label)}
                      onInputChange={(_, val) => setFieldValue("country", val)}
                      renderInput={(params) => <TextField {...params} label={<Text>interface.account.country</Text>} />}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth label={getText("interface.general.link")} name="link" value={values.link} onChange={handleChange} placeholder="https://example.com"></TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <Button type="submit" variant="contained">
                      <Text>interface.actions.submit</Text>
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={4} pl={2} container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h5" textAlign={"center"}>
                      Logo
                    </Typography>
                    <Box mt={2}>
                      <ImageCropper
                        onFile64Change={(file64) => {
                          if (file64) {
                            setFieldValue("logoImage", file64);
                          } else {
                            setFieldValue("logo_url", null);
                          }
                        }}
                        initImage={org?.logo_url}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5" textAlign={"center"}>
                      Banner
                    </Typography>
                    <Box mt={2}>
                      <ImageCropper
                        onFile64Change={(file64) => {
                          if (file64) {
                            setFieldValue("bannerImage", file64);
                          } else {
                            setFieldValue("banner_url", null);
                          }
                        }}
                        initImage={org?.banner_url}
                        type={"banner"}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
});

export default OrganisationForm;
