import { Box, Stack, Typography, colors } from "@mui/material";
import { VerticalAlertBoxCollections } from "components/Alerts/AlertBox";
import HighchartsWrapper from "components/Highcharts/HighchartsWrapper";
import NumericFormatters from "helpers/NumericFormatters";
import _ from "lodash";
import { fetchEmissionData } from "mock/emissionData";
import React, { useEffect, useState } from "react";

const generateChartConfigs = ({ data }) => {
  return {
    chart: {
      type: "",
      height: 200,
    },
    title: {
      text: "",
    },
    colors: [colors.orange[500], colors.blue[500], colors.brown[500], colors.brown[500]],
    tooltip: {
      pointFormat: "{series.name}{point.percentage:.0f}%",
    },
    series: [
      {
        type: "pie",
        name: "",
        size: "100%",
        innerSize: "60%",
        data: [
          {
            name: "Feed",
            y: data?.feed,
          },
          {
            name: "Energy",
            y: data?.energy,
          },
          {
            name: "Seed",
            y: data?.seed,
          },
          {
            name: "Land use and pond activity",
            y: data?.lupa,
          },
        ],
      },
    ],
  };
};

const FarmEmissionWidget = ({ farm }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    Actions.onLoad();
  }, [farm]);

  const Actions = {
    onLoad: () => {
      if (farm) {
        const rsp = fetchEmissionData({ farmId: farm.farm_id });
        const data = {
          co2: _.sumBy(rsp, "co2"),
          intensity: _.meanBy(rsp, "intensity"),
          feed: _.meanBy(rsp, "feed"),
          energy: _.meanBy(rsp, "energy"),
          seed: _.meanBy(rsp, "seed"),
          lupa: _.meanBy(rsp, "lupa"),
          ponds: rsp,
        };
        setData(data);
      }
    },
  };

  if (!data) {
    return <VerticalAlertBoxCollections.NoData />;
  }

  // return "";

  return (
    <Box>
      <Stack direction={"row"} spacing={2}>
        <Box>
          <Typography>
            <span style={{ color: "#777" }}>
              CO<sub>2</sub>{" "}
              <small>
                (kgCO<sub>2</sub>)
              </small>
            </span>
            <strong> {NumericFormatters.format({ value: data?.co2 })}</strong>
          </Typography>
        </Box>
        <Box>
          <Typography>
            <span style={{ color: "#777" }}>
              Emission Intensity{" "}
              <small>
                (kg CO<sub>2</sub> per kg shrimp)
              </small>
            </span>
            <strong> {NumericFormatters.format({ value: data?.intensity })}</strong>
          </Typography>
        </Box>
      </Stack>
      <Box>
        <HighchartsWrapper options={generateChartConfigs({ data })} />
      </Box>
    </Box>
  );
};

export default FarmEmissionWidget;
