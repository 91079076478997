import React from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

export const DocumentViewer = React.memo(({ docUrl }) => {
  const docs = [
    { uri: docUrl }, // Remote file
  ];

  const config = {
    header: {
      disableFileName: true,
      disableHeader: true,
    },
  };

  return <DocViewer key={docUrl} documents={docs} config={config} prefetchMethod="GET" pluginRenderers={DocViewerRenderers} />;
});
