import React from "react";
import "./realtime.component.css";
import { Temp } from "./temp.component";
// import { WeatherIcon } from "./weather-icon.component";
import WeatherIcon from "../WeatherIcon";
import { prettyPrintWeatherCode } from "../utilities";
import { Typography } from "@mui/material";

function Realtime({ realtime }) {
  return (
    <div className="realtime">
      <Typography fontSize={40} fontWeight={"bold"}>
        <Temp value={realtime.intervals[0].values.temperature} />
        <Typography
          sx={{
            display: "inline-block",
            transform: "translateY(-30px)",
          }}
        >
          °C
        </Typography>
      </Typography>

      <div>
        <Typography>{prettyPrintWeatherCode(realtime.intervals[0].values.weatherCode)}</Typography>
        <Typography>
          Feels Like{" "}
          <strong>
            <Temp value={realtime.intervals[0].values.temperatureApparent} />°
          </strong>
        </Typography>
      </div>
      <div className="realtime-icon">
        <WeatherIcon value={realtime.intervals[0].values.weatherCode} size={50} />
      </div>
    </div>
  );
}

export { Realtime };
