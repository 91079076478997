import { Box, Stack, TextField, Tooltip } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import CommonDialog, { CommonDialogIds } from "components/Dialogs/CommonDialog";
import CountryFlagSvgIcon from "components/Icons/CountryFlagSvgIcon";
import { ArrowForwardIcon, DeleteIcon, EditIcon } from "components/Icons/MaterialIcons";
import { MuiBorderIconButton } from "components/buttons/MuiButtonVariants";
import { getText } from "components/text/Text";
import Formatters from "helpers/Formatters";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { alertsActions } from "redux/alerts";
import { dialogReducerActions } from "redux/dialog";

const ProducerTable = ({ rows, actions }) => {
  const [searchTerm, setSearchTerm] = useState(null);
  const [producerToDelete, setProducerToDelete] = useState(null);

  const dispatch = useDispatch();

  const ref = useRef();

  useEffect(() => {
    ref?.current?.api?.setGridOption("quickFilterText", searchTerm);
  }, [searchTerm]);

  const [colDef, setColDef] = useState([
    {
      headerName: "",
      cellRenderer: ({ data }) => (
        <Stack direction={"row"} spacing={0.3}>
          <MuiBorderIconButton size={"sm"} onClick={() => actions.onEditProducer({ producer: data })} icon={<EditIcon />} />
          <MuiBorderIconButton size={"sm"} onClick={() => Actions.onDeleteProducerConfirm(data)} icon={<DeleteIcon />} />
          <MuiBorderIconButton size={"sm"} onClick={() => actions.onViewProduct(data.id)} icon={<ArrowForwardIcon />} />
        </Stack>
      ),
      pinned: "left",
      width: 90,
      cellStyle: { display: "flex", alignItems: "center", justifyContent: "center" },
    },
    {
      field: "id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "name",
      headerName: "Name",
      pinned: "left",
      filter: "agTextColumnFilter",
    },
    {
      field: "country_code",
      headerName: "Country",
      cellRenderer: ({ value }) => (
        <Stack direction={"row"} spacing={1}>
          <CountryFlagSvgIcon countryCode={value} withName />
        </Stack>
      ),
    },
    {
      field: "product_count",
      headerName: "Products",
    },
    {
      field: "link",
      headerName: "Link",
      cellRenderer: ({ value }) => (
        <a href={value} target="_blank">
          {value}
        </a>
      ),
    },
    // {
    //   field: "contact_number",
    //   headerName: "Contact Number",
    // },
    // {
    //   field: "mobile_number",
    //   headerName: "Mobile Number",
    // },
    {
      field: "logo_url",
      headerName: "Logo URL",
      cellRenderer: ({ value }) =>
        value ? (
          <Tooltip title={<img src={value} width="100%" />} placement="top">
            <img src={value} height={"20px"} />
          </Tooltip>
        ) : (
          "-"
        ),
    },
    {
      field: "created_at",
      headerName: "Time Created",
      cellRenderer: ({ value }) => Formatters.toLocalDateTimeString(value),
    },
  ]);

  const Actions = {
    onCellEditingStopped: (props) => {
      console.log(props);
    },
    onDeleteProducerConfirm: (producer) => {
      if (producer.product_count > 0) {
        alertsActions.addError(dispatch, { content: "You can't remove producer if there's products linked" });
      } else {
        setProducerToDelete(producer);
        dialogReducerActions.openCommonDialog(dispatch, {
          id: CommonDialogIds.ADMIN_PRODUCER_DELETE_CONFIRM,
        });
      }
    },
  };

  return (
    <Box
      className="ag-theme-shrimpl"
      sx={{
        width: "100%",
        height: "calc(100vh - 200px)",
      }}
    >
      <CommonDialog
        id={CommonDialogIds.ADMIN_PRODUCER_DELETE_CONFIRM}
        content={getText("interface.alert.alert-are-you-sure")}
        action={() => {
          actions.onDeleteProducer(producerToDelete);
        }}
      />
      <Box mb={1}>
        <TextField variant="standard" size="small" label="Quick Filter:" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}></TextField>
      </Box>
      <AgGridReact
        defaultColDef={{
          cellStyle: { display: "flex", alignItems: "center" },
          flex: 1,
        }}
        ref={ref}
        rowData={rows}
        columnDefs={colDef}
        rowHeight={35}
        onCellEditingStopped={Actions.onCellEditingStopped}
        animateRows={false}
        pagination={true}
        paginationPageSize={30}
        paginationPageSizeSelector={[10, 20, 50, 100]}
      />
    </Box>
  );
};

export default ProducerTable;
