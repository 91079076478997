import React, { useState, useRef, useEffect } from "react";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import FarmAccessLevelBadge from "../common/FarmAccessLevelBadge";
import NumberFormat from "react-number-format";
import { GOOGLE_MAP_KEY } from "secrets";
import GoogleMapReact from "google-map-react";
import { ShrimplIconOnlySvg } from "components/Images";
import palette from "themes/palette";
import CountryFlagIcon from "components/Icons/CountryFlagIcon";
import FarmCard from "screens/Aquaculture/components/common/FarmCard";
import { AppSelectors } from "redux/AppReducers";

const createMapOptions = (maps) => {
  return {
    mapTypeId: maps.MapTypeId.HYBRID,
    minZoom: 3,
    fullscreenControlOptions: {
      position: maps.ControlPosition.BOTTOM_RIGHT,
    },
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_BOTTOM,
    },
  };
};

const MarkerTooltip = ({ data }) => {
  return (
    <Box p={1}>
      <Stack direction={"row"} spacing={1}>
        <Box>
          <CountryFlagIcon countryCode={data.country_code} />
        </Box>
        <Box>
          <Typography fontWeight={"bold"}>{data.label}</Typography>
          <Typography fontSize={8}>
            {data.region} / {data.country}
          </Typography>
          <Typography fontSize={10}>
            {data.total_ponds} ponds,
            <NumberFormat
              value={data.total_area}
              decimalScale={2}
              displayType="text"
            />{" "}
            ha.
          </Typography>
        </Box>
        <Box>
          <FarmAccessLevelBadge accessLevelId={data.access_level_id} />
        </Box>
      </Stack>
    </Box>
  );
};

const Marker = ({ data, onClickFarm, isSelected }) => {
  return (
    <Tooltip title={<MarkerTooltip data={data} />} placement={"top"}>
      <Box
        sx={{
          color: palette.secondary.main,
          transform: "translate(-50%, -50%)",
          borderRadius: "50%",
          width: "50px",
          height: "50px",
          bgcolor: isSelected ? palette.primary.main : palette.secondary.light,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          opacity: isSelected ? 1 : 0.7,
          transition: "all .3s",
          "&:hover": {
            opacity: 1,
            cursor: "pointer",
          },
        }}
        onClick={onClickFarm}
      >
        <img src={ShrimplIconOnlySvg} alt="" width={25} />
      </Box>
    </Tooltip>
  );
};

const PondManagerHomePageMapView = ({ farms }) => {
  const mapRef = useRef(null);
  const [center, setCenter] = useState([0, 0]);
  const [selectedFarm, setFarm] = useState(null);

  const organisationManagementStore = AppSelectors.orgStore();
  const viewAsOrg = organisationManagementStore.viewAsOrg;

  useEffect(() => {
    if (mapRef.current.maps_) {
      if (selectedFarm) {
        const panToPoint = new mapRef.current.maps_.LatLng(
          selectedFarm.lat,
          selectedFarm.lon
        );

        mapRef?.current?.map_?.getZoom() < 10 &&
          mapRef?.current?.map_?.setZoom(11);
        mapRef?.current?.map_?.panTo(panToPoint);
      } else {
        const panToPoint = new mapRef.current.maps_.LatLng(0, 0);
        mapRef?.current?.map_?.setZoom(3);
        mapRef?.current?.map_?.panTo(panToPoint);
      }
    }
  }, [selectedFarm]);

  const handleApiLoaded = (map, maps) => {};

  const Actions = {
    onClickMarker: (farm) => {
      if (!selectedFarm || selectedFarm?.farm_id !== farm?.farm_id) {
        setFarm(farm);
      } else {
        setFarm(null);
      }
    },
  };

  return (
    <Box>
      <Box
        height={"calc(100vh - 150px)"}
        width={"100%"}
        borderRadius={3}
        overflow={"hidden"}
        position="relative"
      >
        {selectedFarm && (
          <Box
            width={400}
            position={"absolute"}
            top={20}
            right={20}
            zIndex={99}
          >
            <FarmCard farm={selectedFarm} org={viewAsOrg} />
          </Box>
        )}
        <GoogleMapReact
          ref={mapRef}
          bootstrapURLKeys={{ key: GOOGLE_MAP_KEY }}
          defaultCenter={center}
          defaultZoom={3}
          options={createMapOptions}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        >
          {farms.map((f) => (
            <Marker
              lat={f.lat}
              lng={f.lon}
              data={f}
              onClickFarm={() => Actions.onClickMarker(f)}
              isSelected={selectedFarm?.farm_id === f?.farm_id}
            />
          ))}
        </GoogleMapReact>
      </Box>
    </Box>
  );
};

export default PondManagerHomePageMapView;
