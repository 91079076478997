import { Box, Button, Stack, Typography } from "@mui/material";
import { ShrimplLogoSvg } from "components/Images";
import React from "react";
import { ROUTE_POND_MANAGER_HOME_PAGE, ROUTE_USER_PROFILE } from "routes/paths";
import { Link as RouterLink } from "react-router-dom";
import { ArrowForward } from "@mui/icons-material";
import { Fade } from "react-reveal";
import Text from "components/text/Text";

const ShrimplHomePage = () => {
  const featuredLinks = [
    {
      label: <Text>interface.general.my-farms</Text>,
      link: ROUTE_POND_MANAGER_HOME_PAGE,
    },
    {
      label: <Text>interface.general.profile</Text>,
      link: ROUTE_USER_PROFILE,
    },
  ];

  return (
    <Box mt={10}>
      <Stack hustifyContent={"center"} alignItems={"center"}>
        <Fade bottom distance={"0"} cascade duration={500}>
          <img src={ShrimplLogoSvg} alt="" width="300" />
        </Fade>
        <Box mt={4}>
          <Stack direction={"column"} spacing={2} my={2}>
            <Fade left distance={"5px"} duration={500}>
              {featuredLinks.map((ele) => (
                <Box key={ele.link}>
                  <Button
                    size="large"
                    sx={{
                      width: "100%",
                    }}
                    to={ele.link}
                    component={RouterLink}
                    endIcon={<ArrowForward />}
                    variant="contained"
                  >
                    {ele.label}
                  </Button>
                </Box>
              ))}
            </Fade>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default ShrimplHomePage;
