import { Autocomplete, Box, Button, Chip, IconButton, Stack, TextField, colors } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { CameraAltIcon, CloseIcon, FileUploadIcon } from "components/Icons/MaterialIcons";
import Text from "components/text/Text";
import { Form, Formik } from "formik";
import moment from "moment";
import { useState } from "react";

const MAX_TAGS_LENTH = 3;

const ImageUploadForm = ({ onSubmit }) => {
  return (
    <Formik
      initialValues={{
        file: null,
        label: null,
        note: null,
        tags: [],
        datetime: moment(),
      }}
      onSubmit={(values, { resetForm }) => {
        onSubmit(values);
        resetForm();
      }}
    >
      {({ values, setFieldValue, handleChange, resetForm }) => {
        return (
          <Form>
            <Stack spacing={2}>
              <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={1}>
                <Button size="large" variant="contained" color="secondary" startIcon={<CameraAltIcon />} component="label" fullWidth>
                  <Text>interface.actions.add-a-photo</Text>
                  <input
                    type="file"
                    id="file"
                    name="file"
                    onChange={(e) => {
                      const file = e.currentTarget.files[0];
                      if (file && file.type.indexOf("image") >= 0) {
                        setFieldValue("file", e.currentTarget.files[0]);
                        if (values.label === null) {
                          setFieldValue("label", file.name);
                        }
                      }
                    }}
                    hidden
                  />
                </Button>
              </Stack>
              {values.file && (
                <>
                  <Box
                    width={"100%"}
                    position={"relative"}
                    sx={{
                      boxSizing: "border-box",
                      img: {
                        width: "100%",
                        transform: "scale(1.1)",
                      },
                      borderRadius: 3,
                      overflow: "hidden",
                    }}
                  >
                    <img src={URL.createObjectURL(values.file)} alt="" />
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: 10,
                        right: 10,
                        opacity: 0.5,
                        background: colors.grey[500],
                        "&:hover": {
                          background: colors.grey[400],
                          opacity: 1,
                        },
                      }}
                      onClick={() => {
                        resetForm();
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateTimePicker
                      name={"datetime"}
                      value={values.datetime}
                      onChange={(newVal) => setFieldValue("datetime", newVal)}
                      slotProps={{ textField: { size: "normal" } }}
                      renderInput={(params) => <TextField {...params} variant="outlined" fullWidth />}
                      maxDate={moment()}
                      sx={{ marginBottom: 2 }}
                    />
                  </LocalizationProvider>
                  <TextField name="label" onChange={handleChange} value={values.label} placeholder="Label..."></TextField>
                  <TextField name="note" onChange={handleChange} value={values.note} multiline placeholder="Notes..." minRows={5}></TextField>
                  <Autocomplete
                    multiple
                    name="tags"
                    options={["pond", "equipment", "water quality", "weather", "shrimp", "harvest"]}
                    defaultValue={[]}
                    freeSolo
                    value={values.tags}
                    onChange={(e, newVal) => {
                      if (newVal.length <= MAX_TAGS_LENTH) {
                        setFieldValue("tags", newVal);
                      }
                    }}
                    renderTags={(value, getTagProps) => value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({ index })} />)}
                    renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Tags" />}
                  />
                  <Button size="large" type="submit" startIcon={<FileUploadIcon />} variant="contained" fullWidth>
                    <Text>interface.actions.upload</Text>
                  </Button>
                </>
              )}
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ImageUploadForm;
