import { Box } from "@mui/material";
import { VerticalAlertBoxCollections } from "components/Alerts/AlertBox";
import _ from "lodash";
import React from "react";
import { AppSelectors } from "redux/AppReducers";
import { ResourcePolicies } from "screens/Aquaculture/components/AccessControl/ResourcePolicies";

const PondManagerAccessWrapper = ({ policies = [], noAccessRenderer, always, children }) => {
  const pondManagementStore = AppSelectors.pondManagementStore();
  const { selectedFarm } = pondManagementStore;

  const userPolicies = selectedFarm?.policies || [];
  const isAdmin = _.includes(
    userPolicies.map((ele) => ele.code),
    ResourcePolicies.ADMIN.code
  );
  const isOwner = selectedFarm?.access_level_id === 4;
  const isUserAllowed = _.some(policies, (item) =>
    _.includes(
      userPolicies.map((ele) => ele.code),
      item?.code
    )
  );

  if (always) {
    return children;
  }

  // if selected farm is not ready, render nothing
  if (!selectedFarm) {
    return "";
  }

  // if owner, always render
  if (isOwner) {
    return children;
  }

  // if admin, always render
  if (isAdmin) {
    return children;
  }

  // if not admin but has policy assigned
  if (!isAdmin && isUserAllowed) {
    return children;
  }

  if (!noAccessRenderer) {
    return (
      <Box p={5}>
        <VerticalAlertBoxCollections.NoAccess />
      </Box>
    );
  } else {
    return noAccessRenderer;
  }
};

export default PondManagerAccessWrapper;
