import { Box, Stack, Typography, colors, Avatar } from "@mui/material";
import { BusinessIcon } from "components/Icons/MaterialIcons";
import palette from "themes/palette";

const OrgBadge = ({ org }) => {
  // Logo image URL for the organisation
  const orgLogoUrl = org?.logo_url || org?.["org.logo_url"];

  // Organisation Name
  const orgName = org?.name || org?.["org.name"] || "ORG";

  // Organisation Type
  const orgType = org?.org_type || org?.["org.org_type"] || "ORG";

  const utils = {
    getFontSize: (text) => {
      return 14;
    },
  };

  return (
    <Box>
      <Box
        sx={{
          py: 0.2,
          pr: 2,
          pl: 3,
          bgcolor: palette.org.light,
          position: "relative",
          overflow: "hidden",
          borderRadius: 5,
          border: "1px solid #ccc",
        }}
      >
        <Typography fontSize={10} fontWeight={800} textTransform={"uppercase"} color={palette.org.main}>
          <Stack
            direction="row"
            alignItems={"center"}
            spacing={1}
            sx={{
              minHeight: 30,
            }}
          >
            {orgLogoUrl ? (
              <Avatar
                src={orgLogoUrl}
                sx={{
                  opacity: 1,
                  width: 25,
                  height: 25,
                  position: "absolute",
                  left: 3,
                }}
              />
            ) : (
              <BusinessIcon />
            )}
            <Box sx={{ zIndex: 10 }}>
              <Typography fontWeight={800} fontSize={utils.getFontSize(orgName)} letterSpacing={0}>
                {orgName}
              </Typography>
              <Typography
                fontSize={9}
                fontWeight={"bold"}
                color={"primary"}
                sx={{
                  mt: -0.7,
                }}
              >
                {orgType}
              </Typography>
            </Box>
          </Stack>
        </Typography>
      </Box>
    </Box>
  );
};

export default OrgBadge;
