import { Box, Chip, Divider, ImageList, ImageListItem, Stack, Typography, colors } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";

import { modalIds, modalReducersActions } from "redux/modal";
import CommonModal from "components/Modal/CommonModal";
import { useDispatch } from "react-redux";
import ImageModel from "screens/Aquaculture/components/Files/components/ImageModel";
import { HideImageIcon } from "components/Icons/MaterialIcons";
import { VerticalAlertBoxCollections } from "components/Alerts/AlertBox";

const ImageTimeline = ({ images, actions }) => {
  const [previewImage, setPreviewImage] = useState(null);

  const dispatch = useDispatch();

  const groupedImages = _.groupBy(images, (e) => moment(e.datetime).format("YYYY-MM-DD"));
  const groupedImagesFlattened = _.orderBy(_.keys(groupedImages))
    .reverse()
    .map((key) => {
      return {
        dateStr: key,
        date: moment(key),
        list: _.orderBy(groupedImages[key], "datetime"),
      };
    });

  const Actions = {
    previewImage: async (image) => {
      setPreviewImage(image);
      modalReducersActions.openModal(dispatch, { id: modalIds.PHOTO_LOGS_MODAL });
    },
  };

  // trigger when "images" is updated
  useEffect(() => {
    if (previewImage) {
      const previewImageId = previewImage.id;
      const updatedPreviewImage = _.find(images, { id: previewImageId });
      updatedPreviewImage && setPreviewImage(updatedPreviewImage);
    }
  }, [images]);

  return (
    <Box>
      <CommonModal id={modalIds.PHOTO_LOGS_MODAL} size="large">
        {previewImage && (
          <ImageModel
            image={previewImage}
            onSubmit={actions.onImageUpdateFormSubmit}
            onDelete={async () => {
              const pondId = previewImage.pond_id;
              const imageId = previewImage.id;
              await actions.onImageDelete({
                pondId,
                imageId,
              });
              modalReducersActions.closeModal(dispatch, { id: modalIds.PHOTO_LOGS_MODAL });
            }}
          />
        )}
      </CommonModal>
      <Stack p={2}>
        {groupedImagesFlattened.length === 0 && <VerticalAlertBoxCollections.NoPhoto />}
        {groupedImagesFlattened.map((ele) => {
          return (
            <Box>
              <Box pb={1}>
                <Typography fontSize={11} color={colors.grey[600]} lineHeight={1}>
                  {ele.date.format("YYYY")}
                </Typography>
                <Typography fontSize={25} fontWeight={800} lineHeight={1.2}>
                  {ele.date.format("DD")}
                  <Typography
                    fontSize={14}
                    lineHeight={1}
                    sx={{
                      display: "inline",
                    }}
                  >
                    {ele.date.format("MMM")}
                  </Typography>
                </Typography>
              </Box>
              <Divider />

              <ImageList variant="standard" cols={4} gap={8} rowHeight={200}>
                {ele.list.map((item) => {
                  return (
                    <ImageListItem
                      key={item.id}
                      sx={{
                        borderRadius: 2,
                        overflow: "hidden",
                        border: "1px solid #DDD",
                        img: {
                          transition: "all .2s",
                        },
                        ":hover": {
                          cursor: "pointer",
                          "img:hover": {
                            filter: "brightness(1.1)",
                            transform: "scale(1.3)",
                          },
                        },
                      }}
                      onClick={() => Actions.previewImage(item)}
                    >
                      <img src={item.compressed_url} alt="" />
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: 10,
                          right: 10,
                        }}
                      >
                        {item.tags && <Chip label={item.tags} variant="filled" size="small" color="secondary" />}
                      </Box>
                    </ImageListItem>
                  );
                })}
              </ImageList>
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
};

export default ImageTimeline;
