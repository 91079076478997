import { Box, Grid, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography, colors } from "@mui/material";
import Text from "components/text/Text";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppSelectors } from "redux/AppReducers";
import demoData from "json/investmentImpactDemoData.json";
import NumberFormat from "react-number-format";
import HighchartsWrapper from "components/Highcharts/HighchartsWrapper";
import palette from "themes/palette";
import { VerticalAlertBoxCollections } from "components/Alerts/AlertBox";

const InvestmentImpactReport = () => {
  const params = useParams();
  const farmId = params?.farmid;

  const [data, setData] = useState(null);

  const pondManagerStore = AppSelectors.pondManagementStore();
  const farmFinancialFields = _.orderBy(
    pondManagerStore.fields.filter((ele) => ele.field_type === "farm" && !ele.is_derived && ele.field_unit === "$"),
    ["field_group", "field_sub_group", "field_label", "position"]
  );

  useEffect(() => {
    Actions.loadData();
  }, [farmId]);

  // const

  const Actions = {
    loadData: async () => {
      // const result = await PondManagerServices.getFarmTimeseriesData(
      //   farmId,
      //   farmFinancialFields.map((el) => el.field_id),
      //   "2000-01-01",
      //   moment().endOf("year").format("YYYY-MM-DD")
      // );
      const result = demoData[farmId];
      if (result) {
        setData(result.data);
      }
    },
  };

  const renderChart = (data) => {
    const rowKeys = ["production", "avg_unit_prod_cost", "total_energy_cost", "total_feed_used", "avg_fcr", "ebitda", "efr"];
    const getChartConfigs = (rowKey) => {
      return {
        chart: {
          type: "bar",
          height: "70px",
        },
        title: {
          text: "",
          align: "left",
        },
        xAxis: {
          categories: [data[rowKey].label],
          title: {
            text: "",
          },
          gridLineWidth: 0,
          lineWidth: 1,
          labels: {
            enabled: false,
          },
        },
        yAxis: {
          min: 0,
          labels: {
            enabled: false,
          },
          title: {
            text: "",
          },
          gridLineWidth: 0,
        },
        tooltip: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            borderRadius: "50%",
            dataLabels: {
              enabled: true,
            },
            groupPadding: 0.1,
          },
        },
        legend: {
          layout: "vertical",
          align: "right",
          verticalAlign: "top",
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            name: "Current",
            data: [data[rowKey].current],
            color: colors.blue[200],
          },
          {
            name: "Target Improvement",
            data: [data[rowKey].after],
            color: colors.blue[600],
          },
        ],
      };
    };
    return (
      <Box>
        <Grid container columnGap={1} alignItems={"center"}>
          {rowKeys.map((k, idx) => {
            return (
              <>
                <Grid item xs={4}>
                  <Typography textAlign={"right"} fontSize={13} fontWeight={"bold"}>
                    {data[k].label}
                  </Typography>
                  <Typography textAlign={"right"} fontSize={10}>
                    {data[k].unit}
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <HighchartsWrapper options={getChartConfigs(k)} />
                </Grid>
              </>
            );
          })}
        </Grid>
      </Box>
    );
  };

  const renderTable = (data) => {
    const rowKeys = [
      "production",
      "avg_unit_prod_cost",
      "total_prod_cost",
      "total_energy_cost",
      "total_feed_used",
      "total_feed_cost",
      "avg_fcr",
      "selling_price",
      "auto_impl_cost",
      "elec_grid_conn_investment",
      "op_cost_capital_needs",
      "total_capital_need",
      "financial_cost",
      "revenue",
      "ebitda",
      "profit_improvement",
      "srs",
      "efr",
      "efr_diff",
      "efr_perc",
    ];
    const styles = {
      ".MuiTableHead-root .MuiTableCell-root": {
        backgroundColor: palette.primary.main,
        color: palette.primary.light,
        fontWeight: 800,
      },
      ".MuiTableCell-body": {
        borderBottom: "1px solid #eee",
      },
      ".MuiTableRow-highlight .MuiTableCell-body": {
        borderBottom: "2px solid #333",
      },
    };
    return (
      <Table size="small" sx={styles}>
        <TableHead>
          <TableCell></TableCell>
          <TableCell>Units</TableCell>
          <TableCell>Current (2022)</TableCell>
          <TableCell>Target Improvement</TableCell>
        </TableHead>
        <TableBody>
          {rowKeys.map((r, idx) => (
            <TableRow className={data[r].isHighlight && "MuiTableRow-highlight"} key={idx}>
              <TableCell>{data[r].label}</TableCell>
              <TableCell>{data[r].unit}</TableCell>
              <TableCell>
                {data[r].isCurrency ? (
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <Box>$</Box>
                    <Box>
                      <NumberFormat displayType="text" value={data[r].current} thousandSeparator="," />
                    </Box>
                  </Stack>
                ) : (
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <Box></Box>
                    <Box>
                      <NumberFormat displayType="text" value={data[r].current} thousandSeparator="," />
                    </Box>
                  </Stack>
                )}
              </TableCell>
              <TableCell>
                {data[r].isCurrency ? (
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <Box>$</Box>
                    <Box>
                      <NumberFormat displayType="text" value={data[r].after} thousandSeparator="," />
                    </Box>
                  </Stack>
                ) : (
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <Box></Box>
                    <Box>
                      <NumberFormat displayType="text" value={data[r].after} thousandSeparator="," />
                    </Box>
                  </Stack>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  return (
    <Box p={2}>
      <Typography variant="h5">Investment Impact Report</Typography>
      <Typography variant="caption">
        <Text>interface.general.farm</Text>#: {farmId}
      </Typography>
      {data ? (
        <>
          <Box>{renderChart(data)}</Box>
          <Box mt={2}>{renderTable(data)}</Box>
        </>
      ) : (
        <VerticalAlertBoxCollections.NoData />
      )}
    </Box>
  );
};

export default InvestmentImpactReport;
