import { Box, Chip, Typography, colors } from "@mui/material";
import ChartjsContainer from "components/chart/ChartjsV4Wrapper";
import moment from "moment";
import React, { useState } from "react";

const DataExplorerMultiTypeChart = ({ data, title, minDate, maxDate }) => {
  const [charttype, setcharttype] = useState("line");

  const chartProps = {
    type: "line",
    data: {
      labels: data.map((r) => moment(r.datetime)),
      datasets: [
        {
          // label: data.map((r) => r.field),
          data: data.map((r) => r.value),
          borderColor: colors.orange[900],
          borderWidth: 1,
          pointRadius: 5,
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      animation: {
        duration: 0,
      },
      spanGaps: true,
      plugins: {
        legend: {
          display: false,
        },
        // zoom: {
        //   pan: {
        //     enabled: true,
        //     mode: "x",
        //     pinch: {
        //       enabled: true,
        //     },
        //   },
        //   zoom: {
        //     wheel: {
        //       enabled: true,
        //     },
        //     pinch: {
        //       enabled: true,
        //     },
        //     drag: true,
        //     mode: "x",
        //   },
        // },
      },
      scales: {
        x: {
          type: "time",
          time: {
            unit: "week",
            displayFormats: {
              day: "YYYY-MM-DD",
              week: "YYYY-MM-DD",
              month: "YYYY-MM",
              quarter: "[Q]Q - YYYY",
              year: "YYYY",
            },
          },
          min: minDate?.datetime,
          max: maxDate?.datetime,
          // title: {
          //   display: true,
          //   text: interval === INTERVALS.weekly ? "Week" : "Day",
          // },
          // ticks: {},
        },
        y: {
          position: "right",
          ticks: {
            padding: 0,
          },
          // title: {
          //   display: true,
          //   text: field_id,
          // },
        },
      },
    },
  };

  return (
    <Box>
      <Box my={0.5}>
        <Chip
          color="primary"
          size="small"
          label={
            <Typography fontWeight={"bold"} fontSize={12}>
              {title}
            </Typography>
          }
        />
      </Box>
      <Box height={200}>
        <ChartjsContainer {...chartProps} />
      </Box>
    </Box>
  );
};

export default DataExplorerMultiTypeChart;
