import React, { useState } from "react";
import SideMenuLayout from "screens/Aquaculture/components/Layout/SideMenuLayout";

import Text, { getText } from "components/text/Text";
import { PhotoIcon, TextSnippetIcon } from "components/Icons/MaterialIcons";
import FarmDataRoom from "screens/Aquaculture/components/Farm/FarmDataRoom";
import PhotoLogs from "screens/Aquaculture/components/Files/PhotoLogs";
import PondManagerAccessWrapper from "components/Wrapper/PondManagerAccessWrapper";
import { ResourcePolicies } from "screens/Aquaculture/components/AccessControl/ResourcePolicies";

const FilesHome = ({ farm, pond }) => {
  const MENULIST = [
    {
      label: <Text>interface.general.farm-records</Text>,
      description: "",
      value: 0,
      icon: <TextSnippetIcon />,
      component: (
        <PondManagerAccessWrapper policies={[ResourcePolicies["DOCUMENT:READ"], ResourcePolicies["DOCUMENT:WRITE"]]}>
          <FarmDataRoom />
        </PondManagerAccessWrapper>
      ),
    },
    {
      label: <Text>interface.general.photo-logs</Text>,
      description: "",
      value: 1,
      icon: <PhotoIcon />,
      component: (
        <PondManagerAccessWrapper policies={[ResourcePolicies["PHOTO-LOGS:READ"], ResourcePolicies["PHOTO-LOGS:WRITE"]]}>
          <PhotoLogs pond={pond} farm={farm} />
        </PondManagerAccessWrapper>
      ),
    },
  ];

  return <SideMenuLayout menulist={MENULIST} title={getText("interface.general.files")} defaultViewMode={0} />;
};

export default FilesHome;
