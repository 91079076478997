import { Box, Typography, colors } from "@mui/material";
import React from "react";

const MiniLabelAndValueCard = ({ label, value, textAlign = "left", flex = 1 }) => {
  return (
    <Box
      key={label}
      sx={{
        minWidth: 90,
        textAlign,
        flex,
      }}
    >
      <Typography textAlign={textAlign} fontSize={10} color="grey" textTransform={"capitalize"}>
        {label}
      </Typography>
      <Typography textAlign={textAlign} fontWeight={800}>
        {value}
      </Typography>
    </Box>
  );
};

export default MiniLabelAndValueCard;
