import React, { Component, Fragment } from "react";
import ReactMapboxGl, { Layer, Source, Popup } from "react-mapbox-gl";
import RcSelect from "../../components/input/RcSelect";
import RcTable from "../../components/table/RcTable";
import styled from "styled-components";
import allocation_meta from "../../json/allocation_meta";
import entitlement_meta from "../../json/entitlement_meta";
import WaterMarketChart from "./components/WaterMarketChart";
import { LoadingBar } from "../../helpers/SimpleComponents";
import AuDslGeojson from "../../json/SDL_AU.json";
import { MAPBOX_TOKEN } from "../../secrets";
import { Box, Grid, Stack, Typography } from "@mui/material";
import WidgetCard from "ui/Card/WidgetCard";

const Map = ReactMapboxGl({
  accessToken: MAPBOX_TOKEN,
});

const Layout = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  width: 100%;
  height: 100%;

  font-family: var(--main-font);

  .loadingWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .table_section {
    margin: 20px 0;
  }

  /* RHS */
  .rhs {
    height: 100%;
    border-left: solid 1px #aaa;
    display: grid;
    grid-template-rows: 133px calc(100% - 133px);
  }

  .rhs-top {
    display: grid;
    grid-template-rows: 1fr 1fr;
  }

  .rhs .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #fff;
    border-bottom: 2px solid #ccc;
  }

  .rhs .header .header_title {
    margin-bottom: -4px;
    font-size: 1.2rem;
    letter-spacing: 2px;
  }
  .rhs .header .state {
    font-size: 1.3rem;
    margin-bottom: -4px;
  }
  .rhs .header .sdl {
    font-size: 2rem;
  }
  .rhs .header .sdl_code {
    font-size: 2.5rem;
    text-align: right;
  }

  .rhs .main {
    overflow: auto;
    padding: 30px;
  }

  .rhs .main .block {
    margin: 10px 0;
  }

  .rhs .main .block .title {
    font-size: 1.6rem;
  }

  .map-infobar {
    position: absolute;
    padding: 20px;
    background: #3339;
    color: white;
    font-family: var(--main-font);
    font-size: 1.5rem;
    .state {
      font-size: 1rem;
      color: #333;
      background: #eee;
      border-radius: 10px;
      padding: 4px 10px;
      margin-right: 10px;
    }
  }
`;

// =========================================================================================================
// =========================================================================================================
// =========================================================================================================
// =========================================================================================================
// =========================================================================================================
// =========================================================================================================
// =========================================================================================================
// =========================================================================================================

class SideScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      zoneIndex: [],
      selectedZoneId: null,
      selectedZone: {},
      callBackData: {},
    };
    this.onDataCallBack = this.onDataCallBack.bind(this);
  }

  componentDidMount() {
    // const system = "SS6"
    if (this.props.meta) {
      const system = this.props.meta.swsdlid;
      const type = this.props.selectedType;
      const zoneIndex =
        type === "allc"
          ? allocation_meta.filter((ele) => ele.system === system)
          : entitlement_meta
              .filter((ele) => ele.system === system)
              .map((ele) => ({
                ...ele,
                name: ele.zone + ", " + ele.class,
              }));
      this.setState(
        {
          zoneIndex,
        },
        () => {
          if (zoneIndex.length > 0) {
            this.setState({
              selectedZone: {
                label: zoneIndex[0].name,
                value: zoneIndex[0].id,
              },
            });
          }
        }
      );
    }
  }

  onDataCallBack(data) {
    this.setState({
      callBackData: data,
    });
  }

  render() {
    // props:
    const meta = this.props.meta;
    const onChangeType = this.props.onChangeType;
    const selectedType = this.props.selectedType;

    // states:
    const isLoading = this.state.isLoading;
    const zoneIndex = this.state.zoneIndex;
    const selectedZone = this.state.selectedZone;
    const callBackData = this.state.callBackData;
    const data = this.state.data;

    // console.log(selectedZone);

    // converted
    let sellOrdersData = callBackData.sellOrders ? callBackData.sellOrders : [];
    let buyOrdersData = callBackData.buyOrders ? callBackData.buyOrders : [];
    let transactionData = (callBackData.transactions || []).slice(0, 100);
    let monthly = callBackData.monthly;

    // :: Re-order
    if (selectedType === "allc") {
      sellOrdersData = sellOrdersData.filter((ele) => ele.zone.toString() === selectedZone.value).sort((a, b) => b.orderNo - a.orderNo);
      buyOrdersData = buyOrdersData.filter((ele) => ele.zone.toString() === selectedZone.value).sort((a, b) => b.orderNo - a.orderNo);
    } else {
      sellOrdersData = sellOrdersData.sort((a, b) => Date.parse(b.date_create) - Date.parse(a.date_create));
      buyOrdersData = buyOrdersData.sort((a, b) => Date.parse(b.date_create) - Date.parse(a.date_create));
    }
    const tableExtraConfig =
      selectedType === "allc"
        ? {
            sorted: ["orderNo", "date", "broker_individual", "price", "volume"],
            onSorted: true,
          }
        : {
            sorted: ["auction_id", "megs", "date_create", "date_end", "start_price", "current_price", "BIN_price", "reserve_price"],
            onSorted: true,
          };

    if (meta) {
      return (
        <Box>
          <Stack
            p={1}
            direction="row"
            justifyContent={"space-between"}
            alignItems={"center"}
            width="100%"
            sx={{
              boxSizing: "border-box",
              backgroundColor: selectedType === "allc" ? "var(--color-blue)" : "var(--color-green)",
              color: "#FFF",
            }}
          >
            <Box>
              <Typography>{meta.stateid}</Typography>
              <Typography fontWeight={800}>{meta.swsdlname}</Typography>
            </Box>
            <Box>
              <Typography textAlign={"right"} variant="body1">
                {selectedType === "allc" ? "Temporary Allocation" : "Permanent Entitlement"}
              </Typography>
              <Typography textAlign={"right"} fontWeight={800}>
                {meta.swsdlid}
              </Typography>
            </Box>
          </Stack>
          <div className="rhs-top">
            <div className="tab_wrapper">
              <div
                className={"tab" + (selectedType === "allc" ? " tab-selected" : "")}
                onClick={() => {
                  onChangeType("allc");
                }}
              >
                <i className="fal fa-water"></i>
                <Typography>Allocation</Typography>
              </div>
              <div
                className={"tab" + (selectedType === "ent" ? " tab-selected" : "")}
                onClick={() => {
                  onChangeType("ent");
                }}
              >
                <i className="fal fa-file-contract"></i>
                <Typography>Entitlement</Typography>
              </div>
            </div>
          </div>

          <Stack spacing={2} p={2}>
            <RcSelect
              options={zoneIndex.map((ele) => ({
                label: ele.name,
                value: ele.id,
              }))}
              value={selectedZone}
              onChange={(e) => {
                this.setState({ selectedZone: e });
              }}
            />
            {selectedZone.label && (
              <>
                <WidgetCard title="Historical">
                  <WaterMarketChart key={"water-price-" + selectedZone.value} zoneName={selectedZone.label} zoneId={selectedZone.value} marketType={selectedType} callBack={this.onDataCallBack} />
                </WidgetCard>

                <WidgetCard title="Transactions">
                  <RcTable
                    key={callBackData.zoneName}
                    config={{
                      data: transactionData,
                      sorted: {
                        allc: ["sort", "id", "price", "volume"],
                        ent: ["sortdate", "id", "price", "volume"],
                      }[selectedType],
                      onSorted: true,
                      height: 400,
                    }}
                  />
                </WidgetCard>

                <WidgetCard title="Buy Orders">
                  <RcTable
                    key={callBackData.zoneName}
                    config={{
                      data: buyOrdersData,
                      height: 400,
                      ...tableExtraConfig,
                    }}
                  />
                </WidgetCard>

                <WidgetCard title="Sell Orders">
                  <RcTable
                    key={callBackData.zoneName}
                    config={{
                      data: sellOrdersData,
                      height: 400,
                      ...tableExtraConfig,
                    }}
                  />
                </WidgetCard>
              </>
            )}
          </Stack>
        </Box>
      );
    } else {
      return (
        <Box p={2}>
          <Typography variant="h3" color="grey">
            Select a Zone
          </Typography>
        </Box>
      );
    }
  }
}

// =========================================================================================================
// =========================================================================================================
// =========================================================================================================
// =========================================================================================================
// =========================================================================================================
// =========================================================================================================
// =========================================================================================================
// =========================================================================================================

class MapScreen extends Component {
  state = {
    isLoading: false,
    zoneData: null,
    selectedSDL: null,
    hoveredSDL: null,
    selectedType: "allc",
    stationindex: [],
    popupCoord: [],
    zoom: [5],
    center: [146.118482, -31.604802],
  };

  componentDidMount() {
    const sourceData = {
      ...AuDslGeojson,
      features: AuDslGeojson.features.map((ele, idx) => ({
        ...ele,
        id: idx + 1,
      })),
    };
    this.setState({ zoneData: sourceData });
  }

  onChangePopupCord = (event) => {
    // console.log(event.lngLat)
    this.setState({
      popupCoord: [event.lngLat.lng, event.lngLat.lat],
      selectedProperty: event.features[0].properties,
    });
  };

  renderBoarderLayer = () => {
    const { zoneData } = this.state;
    if (zoneData) {
      return (
        <Fragment>
          <Source
            id="zoneborder_src"
            geoJsonSource={{
              type: "geojson",
              data: zoneData,
            }}
          />
          <Layer
            {...{
              id: "zoneborder_layer",
              sourceId: "zoneborder_src",
              type: "fill",
              paint: {
                "fill-outline-color": "#333",
                "fill-color": "rgb(107, 144, 214)",
                "fill-opacity": ["case", ["boolean", ["feature-state", "hover"], false], 0.6, 0.1],
              },
              onClick: (e) => {
                const prop = e.features[0].properties;
                this.props.onSelectSDL(prop);
              },
            }}
          />
          <Layer
            {...{
              id: "zoneborder_bd_layer",
              sourceId: "zoneborder_src",
              type: "line",
              paint: {
                "line-color": "#e8fffd",
                "line-width": 2,
              },
              onClick: (e) => {},
            }}
          />
        </Fragment>
      );
    } else {
      return "";
    }
  };

  selectLayer = (e) => {
    this.setState({ selectedLayer: e });
  };

  onMapLoaded = (map, setSelectedProperty) => {
    const layer_id = "zoneborder_layer";
    const source_id = "zoneborder_src";
    const selectLayer = this.selectLayer;

    this.map = map;

    // EFFECT: HOVER HIGHLIGHT
    var hoveredStateId = null;
    var clickedStateId = null;

    const onRegisterHover = (e) => {
      if ((this.state.hoveredSDL || {})["id"] !== e.features[0].id) {
        this.setState({
          hoveredSDL: {
            ...e.features[0].properties,
            id: e.features[0].id,
          },
        });
      }
    };

    const onDeregisterHover = () => {
      this.setState({
        hoveredSDL: null,
      });
    };

    // When the user moves their mouse over the state-fill layer, we'll update the
    // feature state for the feature under the mouse.
    map.on("mousemove", layer_id, function (e) {
      if (e.features.length > 0) {
        onRegisterHover(e);
        if (hoveredStateId) {
          if (hoveredStateId !== clickedStateId) {
            map.setFeatureState({ source: source_id, id: hoveredStateId }, { hover: false });
          }
        }
        hoveredStateId = e.features[0].id;
        map.setFeatureState({ source: source_id, id: hoveredStateId }, { hover: true });
      }
    });

    map.on("mouseout", layer_id, function (e) {
      onDeregisterHover();
    });

    map.on("click", layer_id, function (e) {
      if (clickedStateId) {
        map.setFeatureState({ source: source_id, id: clickedStateId }, { hover: false });
      }
      clickedStateId = e.features[0].id;
      map.setFeatureState({ source: source_id, id: clickedStateId }, { hover: true });
      // console.log(e);
      map.flyTo({
        // These options control the ending camera position: centered at
        // the target, at zoom level 9, and north up.
        center: e.lngLat,
        // zoom: 8,
        pitch: 30,

        // These options control the flight curve, making it move
        // slowly and zoom out almost completely before starting
        // to pan.
        speed: 0.5, // make the flying slow
        curve: 1.5, // change the speed at which it zooms out

        // This can be any easing function: it takes a number between
        // 0 and 1 and returns another number between 0 and 1.
        easing: function (t) {
          return t;
        },
      });
    });

    // When the mouse leaves the state-fill layer, update the feature state of the
    // previously hovered feature.

    map.on("mouseleave", layer_id, function () {
      if (hoveredStateId) {
        map.setFeatureState({ source: source_id, id: hoveredStateId }, { hover: false });
      }
      hoveredStateId = null;

      if (clickedStateId) {
        map.setFeatureState({ source: source_id, id: clickedStateId }, { hover: true });
      }
    });
  };

  render() {
    const zoom = this.state.zoom;
    const center = this.state.center;
    const isLoading = this.state.isLoading;

    if (isLoading) {
      return <div className="loadingWrapper">{LoadingBar()}</div>;
    } else {
      return (
        <Map
          {...{
            id: "Mainmap",
            style: "mapbox://styles/mapbox/satellite-streets-v9",
            // "mapbox://styles/mapbox/dark-v10",
            zoom: zoom,
            center: center,
            containerStyle: {
              height: "100%",
              width: "100%",
            },
            onStyleLoad: this.onMapLoaded,
          }}
        >
          {this.state.hoveredSDL ? (
            <div className="map-infobar">
              <span className="state">{this.state.hoveredSDL.stateid}</span>
              {this.state.hoveredSDL.swsdlname} ({this.state.hoveredSDL.swsdlid})
            </div>
          ) : (
            ""
          )}
          {this.renderBoarderLayer()}
        </Map>
      );
    }
  }
}

// =========================================================================================================
// =========================================================================================================
// =========================================================================================================
// =========================================================================================================
// =========================================================================================================
// =========================================================================================================
// =========================================================================================================
// =========================================================================================================

export default class WaterOverview extends Component {
  state = {
    selectedSDL: null,
    selectedType: "allc",
  };

  componentDidMount() {}

  onSelectSDL = (sdl) => {
    this.setState({
      selectedSDL: sdl,
    });
  };

  render() {
    const selectedSDL = this.state.selectedSDL;
    const selectedType = this.state.selectedType;

    return (
      <Box height="100%">
        <Grid container height="100%">
          <Grid xs={6} item>
            <MapScreen onSelectSDL={this.onSelectSDL} />
          </Grid>
          <Grid xs={6} item sx={{ height: "100%", overflow: "auto" }}>
            <SideScreen
              key={"rhs-" + (selectedSDL || {}).swsdlid + "-" + selectedType}
              meta={selectedSDL}
              onChangeType={(typ) => {
                this.setState({ selectedType: typ });
              }}
              selectedType={selectedType}
            />
          </Grid>
        </Grid>
      </Box>
    );
  }
}
