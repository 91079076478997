import { Box, Typography } from "@mui/material";
import CommonForm, { FormFieldTypes } from "components/Form/CommonForm";
import Text, { getText } from "components/text/Text";

const ScenarioForm = ({ scenario, onSubmit }) => {
  const contentDisplay = {
    title: () => {
      return scenario ? <Text>interface.actions.edit</Text> : <Text>interface.actions.create</Text>;
    },
    subtitle: () => {
      return scenario ? `ID: ${scenario.id}` : "";
    },
  };

  const intialValues = {
    label: "",
    tags: "",
    note: "",
  };

  return (
    <Box minWidth={500}>
      <Box mb={2}>
        <Typography variant="cardTitle">{contentDisplay.title()}</Typography>
        <Typography fontSize={10} color="grey">
          {contentDisplay.subtitle()}
        </Typography>
      </Box>
      <CommonForm
        columns={2}
        initialValues={scenario || intialValues}
        fields={[
          {
            label: getText("interface.general.label"),
            name: "label",
            type: FormFieldTypes.text,
            placeholder: getText("interface.general.label"),
            options: {
              required: true,
            },
          },
          {
            label: getText("interface.general.tags"),
            name: "tags",
            type: FormFieldTypes.tags,
            placeholder: getText("interface.general.tags"),
            options: {
              options: [],
            },
          },
          {
            span: 2,
            label: getText("interface.general.note"),
            name: "note",
            type: FormFieldTypes.text,
            placeholder: getText("interface.general.note"),
            options: {
              multiline: true,
              rows: 4,
            },
          },
        ]}
        onSubmit={onSubmit}
      />
    </Box>
  );
};

export default ScenarioForm;
