import { Box, Grid } from "@mui/material";
import { AppSelectors } from "redux/AppReducers";
import OrganisationInviteTable from "screens/Aquaculture/Organisations/OrganisationInviteTable";
import OrgCard from "screens/Aquaculture/Organisations/components/OrgCard";

const OrganisationHome = () => {
  const orgs_ = AppSelectors.orgStore()["orgs"];

  return (
    <Box p={2}>
      <OrganisationInviteTable />
      {/* <Stack direction="row" alignItems={"center"}>
        <Box>
          <Button variant="contained" startIcon={<AddCircleOutlineIcon />} component={Link} to={ROUTE_ORGANISATION_CREATE}>
            Organisation
          </Button>
        </Box>
      </Stack> */}
      <Grid container mt={2} spacing={2}>
        {orgs_.map((ele) => {
          return (
            <Grid item xs={12} md={3}>
              <OrgCard key={ele.name} org={ele} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default OrganisationHome;
