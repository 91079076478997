import { get, set, del } from "idb-keyval";
import { QueryClient } from "@tanstack/react-query";

// Initialize IndexedDB persister
export function createIDBPersister(idbValidKey = "reactQuery") {
  return {
    persistClient: async (client) => {
      await set(idbValidKey, client);
    },
    restoreClient: async () => {
      return await get(idbValidKey);
    },
    removeClient: async () => {
      await del(idbValidKey);
    },
  };
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 60 * 24, // Stale Time
      gcTime: 1000 * 60 * 60 * 24 * 5, // Garbage Collection Time (Max Limit to 24 days)
    },
  },
});

export const persister = createIDBPersister();
