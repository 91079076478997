import { CheckOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { BiClipboard } from "react-icons/bi";

const ClipboardButton = ({ text, buttonText = "Copy to Clipboard" }) => {
  const [copied, setCopied] = useState(false);

  return (
    <CopyToClipboard
      text={text || ""}
      onCopy={() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      }}
    >
      <Button size="small" startIcon={copied ? <CheckOutlined /> : <BiClipboard />}>
        {copied ? "Copied" : buttonText}
      </Button>
    </CopyToClipboard>
  );
};

export default ClipboardButton;
