import { Box, Button, Card, colors, Grid, Stack, Typography } from "@mui/material";
import { ArrowForwardIcon, SettingsIcon } from "components/Icons/MaterialIcons";
import NumberFormat from "react-number-format";
import { Link, useNavigate } from "react-router-dom";
import { ROUTE_POND_MANAGER_FARM_EDIT } from "routes/paths";
import Text from "components/text/Text";
import { ShrimplImagePlaceholerImg } from "components/Images";
import CountryFlagSvgIcon from "components/Icons/CountryFlagSvgIcon";
import FarmAccessLevelBadge from "screens/Aquaculture/components/common/FarmAccessLevelBadge";
import palette from "themes/palette";

const Styles = {
  farmCardLayout: {
    border: "2px solid #edf1f2",
    "&:hover": {
      cursor: "pointer",
      border: "2px solid #ddd",
    },
    borderRadius: 3,
    "&:hover .flagWrapper": {
      top: "50px",
      opacity: 1,
    },
  },
};

const MobileFarmCard = ({ variant = "farm", farm }) => {
  const navigate = useNavigate();

  const variableContent = {
    farm: {
      getViewLink: () => `${location.pathname}/${farm.farm_id}`,
      accessBadge: () => (
        <Box>
          <FarmAccessLevelBadge accessLevelId={farm.access_level_id} />
        </Box>
      ),
      buttons: () => [
        <Button startIcon={<SettingsIcon />} fullWidth component={Link} to={`${ROUTE_POND_MANAGER_FARM_EDIT}/${farm.farm_id}`}>
          <Text>interface.actions.edit</Text>
        </Button>,
        <Button fullWidth to={viewLink} endIcon={<ArrowForwardIcon />} variant="contained" component={Link}>
          <Text>interface.actions.view</Text>
        </Button>,
      ],
    },
    org: {
      getViewLink: () => `${location.pathname}/${farm.farm_id}`,
      accessBadge: () => "",
      buttons: () => [
        <Button fullWidth to={viewLink} endIcon={<ArrowForwardIcon />} variant="contained" color="org" component={Link}>
          <Text>interface.actions.view</Text>
        </Button>,
      ],
    },
  };

  const viewLink = variableContent[variant].getViewLink();

  // get farm images
  const farmImages = farm?.images || [];

  // get the featured farm image
  const farmDisplayImage = farmImages[0]?.compressed_url || ShrimplImagePlaceholerImg;

  return (
    <Box
      sx={{
        bgcolor: palette.primary.light,
        border: "1px solid #CCC",
        backgroundImage: `url("${farmDisplayImage}")`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundColor: "#FFF",
        backgroundRepeat: "no-repeat",
        transition: "all .2s",
        position: "relative",
        overflow: "hidden",
        borderRadius: 3,
      }}
      onClick={() => {
        navigate(viewLink);
      }}
    >
      <Box
        sx={{
          p: 1,
          zIndex: 2,
          background: "linear-gradient(180deg, rgba(255,255,255,0.4) 0%, rgba(255,255,255,0.61194415266106448) 35%, rgba(255,255,255,0.886453956582633) 100%)",
          background: "-webkit-linear-gradient(180deg, rgba(255,255,255,0.4) 0%, rgba(255,255,255,0.61194415266106448) 35%, rgba(255,255,255,0.886453956582633) 100%)",
          transition: "all .3s",
          ":active": {
            bgcolor: "#ffffff",
          },
        }}
      >
        <Typography fontWeight={800} textOverflow={"ellipsis"} whiteSpace={"nowrap"} fontSize={14} color="primary">
          {farm.label}
        </Typography>
        <Box>
          <Grid container>
            <Grid item xs={12}>
              <Typography fontSize={9} fontWeight={600}>
                <NumberFormat value={farm.total_area} displayType="text" thousandSeparator={true} decimalScale={2} /> ha.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize={9} fontWeight={"bold"}>
                {farm.total_ponds} Pd.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize={9} fontWeight={"bold"}>
                {farm?.settings?.currency || (
                  <span
                    style={{
                      color: "#AAA",
                    }}
                  >
                    -
                  </span>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box mt={2}>
          <Typography variant="body2">
            <Stack direction={"row"} spacing={0.3} justifyContent={"space-between"}>
              <Box>{variableContent[variant].accessBadge()}</Box>
              <CountryFlagSvgIcon countryCode={farm.country_code.toUpperCase()} />
            </Stack>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default MobileFarmCard;
