import { Box, Stack } from "@mui/material";
import { CalendarIcon } from "@mui/x-date-pickers";
import { TableRowsIcon } from "components/Icons/MaterialIcons";
import { getText } from "components/text/Text";
import _ from "lodash";
import { useState } from "react";
import { AppSelectors } from "redux/AppReducers";
import CycleTable from "screens/Aquaculture/components/CycleManager/components/CycleTable";
import CycleTimeline from "screens/Aquaculture/components/CycleManager/components/CycleTimeline";
import { PondManagerLayoutStyles } from "screens/Aquaculture/components/PondManagerMapView";
import MuiTabs, { MuiTabsThemes } from "ui/tabs/MuiTabs";

const MultiPondsCycleManager = ({ ponds, farm, disableEdit }) => {
  const [displayMode, setDisplayMode] = useState("table-current"); // table; calendar

  const pondManagementStore = AppSelectors.pondManagementStore();
  const group = pondManagementStore.selectedGroup;

  const cycleManagementStore = AppSelectors.cycleStore();
  const cycles = cycleManagementStore.cycles;
  const cyclesFlatten = _.chain(cycles).values().flatten().orderBy(["start_date", "pond_label"], ["desc", "asc"]).value();
  const latestCycles = cycleManagementStore.latestCycles;

  const cyclesCurrent = ponds
    .filter((ele) => (group ? ele.group === group : true))
    .map(
      (ele) =>
        latestCycles[ele.id] || {
          pond_label: ele.label,
        }
    );

  return (
    <Box sx={PondManagerLayoutStyles.rightPanel}>
      <Stack spacing={1}>
        <MuiTabs
          theme={MuiTabsThemes.chip}
          value={displayMode}
          onNewValue={setDisplayMode}
          options={[
            { label: getText("interface.general.current-cycle"), value: "table-current", icon: <TableRowsIcon /> },
            { label: getText("interface.general.all"), value: "table", icon: <TableRowsIcon /> },
            { label: getText("interface.general.timeline"), value: "calendar", icon: <CalendarIcon /> },
          ]}
        />
        {displayMode === "table-current" && <CycleTable cycles={cyclesCurrent} showall />}
        {displayMode === "table" && <CycleTable cycles={cyclesFlatten} />}
        {displayMode === "calendar" && <CycleTimeline {...{ ponds, farm, disableEdit }} />}
      </Stack>
    </Box>
  );
};

export default MultiPondsCycleManager;
