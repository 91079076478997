import { Box, Divider, Grid, List, ListItem, ListItemIcon, Stack, Typography, colors } from "@mui/material";
import { theme } from "App";
import {
  AssesementLogo,
  ClimateChangeLogo,
  ExternalLogo,
  GeneticLogo,
  MarketLogo,
  OperationalRiskLogo,
  PreventLogo,
  RegulatoryLogo,
  RiskLogo,
  SocialLogo,
  SolutionLogo,
  SupplyChainLogo,
  WarningLogo,
  WaterQualityLogo,
  WaterSystemLogo,
} from "components/Icons/CustomSVGIcons";
import LoadingBox from "components/Loading/LoadingBox";
import ChartjsContainer from "components/chart/ChartjsV4Wrapper";
import { AddCircleIcon, AddCircleOutlineIcon, CheckCircleOutlineIcon } from "components/Icons/MaterialIcons";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import GaugeChart from "react-gauge-chart";
import NumberFormat from "react-number-format";
import { useParams } from "react-router-dom";

import mitigationActions from "json/srsMitigationActions.json";
import Text, { getText } from "components/text/Text";
import HighchartsWrapper from "components/Highcharts/HighchartsWrapper";
import { typography } from "themes/typography";
import { VerticalAlertBoxCollections } from "components/Alerts/AlertBox";

export const demoSrsData = {
  // 11 DE DICIEMBRE - MX
  "e57fa60b-bde8-4d54-8f23-74fde88255b4": {
    scores: [
      {
        color: colors.amber[300],
        key: "disease",
        label: <Text>SRS.disease</Text>,
        description: <Text>SRS.disease-description</Text>,
        icon: <PreventLogo color={"#FFF"} size={35} />,
        srs: 1.54,
        sab: -12.32,
        score: 10.56,
        baseline: 22.88,
        weight: 20.5,
        r: ["Disease Risk"],
        m: ["SPF PL", "PL Testing", "Biosecurity SOP's"],
        s: ["Genetics Review", "Disease surveillance", "Biosecurity Audit"],
        mitigated: ["ds-spfpl", "ds-pldt", "ds-biosop", "ds-genrev", "ds-ds", "ds-bioaud", "ds-pts", "ds-wit", "ds-afs"],
      },
      {
        color: colors.blue[300],
        key: "meteorological",
        label: <Text>SRS.meteorological</Text>,
        description: <Text>SRS.meteorological-description</Text>,
        icon: <ClimateChangeLogo color={"#FFF"} size={35} />,
        srs: 1.45,
        sab: -7.52,
        score: 9.2,
        baseline: 16.72,
        weight: 15.0,
        r: ["Meteorological Risk"],
        m: ["Weather monitoring systems", "Remote response"],
        s: [],
        mitigated: ["mt-wm", "mt-rr", "mt-eb"],
      },
      {
        color: colors.green[300],
        key: "water-quality",
        label: <Text>SRS.water-quality</Text>,
        description: <Text>SRS.water-quality-description</Text>,
        icon: <WaterQualityLogo color={"#FFF"} size={35} />,
        srs: 1.87,
        sab: -17.28,
        score: 2.48,
        baseline: 19.76,
        weight: 17.7,
        r: ["Water Quality"],
        m: ["Lead scoring", "Quality of leads"],
        s: ["Automated water testing", "Automated aeration", "automated water exchange"],
        mitigated: ["wq-ls", "wq-ql"],
      },
      {
        color: colors.red[300],
        key: "management",
        label: <Text>SRS.management</Text>,
        description: <Text>SRS.management-description</Text>,
        icon: <OperationalRiskLogo color={"#FFF"} size={35} />,
        srs: 1.36,
        sab: -3.8,
        score: 6.64,
        baseline: 10.44,
        weight: 9.3,
        r: ["Management"],
        m: ["Data reporting systems", "Capacity monitoring"],
        s: ["Automated feeding", "Increase density"],
        mitigated: ["mn-drc", "mn-id"],
      },
      {
        color: colors.orange[300],
        key: "market",
        label: <Text>SRS.market</Text>,
        description: <Text>SRS.market-description</Text>,
        icon: <MarketLogo color={"#FFF"} size={35} />,
        srs: 1,
        sab: 0,
        score: 10.24,
        baseline: 10.24,
        weight: 9.2,
        r: ["Market"],
        m: ["3 key suppliers", "Partial harvests"],
        s: ["Further reduce COP"],
        mitigated: ["mk-m3s", "mk-ph"],
      },
      {
        color: colors.teal[300],
        key: "regulatory",
        label: <Text>SRS.regulatory</Text>,
        description: <Text>SRS.regulatory-description</Text>,
        icon: <RegulatoryLogo color={"#FFF"} size={35} />,
        srs: 1.16,
        sab: -0.8,
        score: 4.32,
        baseline: 5.12,
        weight: 4.6,
        r: ["Regulatory"],
        m: ["ASC Certified", "BAP Certified"],
        s: ["HACCP Certification"],
        mitigated: ["rg-ascc", "rg-bapc"],
      },
      {
        color: colors.deepPurple[300],
        key: "logistics",
        label: <Text>SRS.logistics</Text>,
        description: <Text>SRS.logistics-description</Text>,
        icon: <SupplyChainLogo color={"#FFF"} size={35} />,
        srs: 1.11,
        sab: -0.56,
        score: 4.64,
        baseline: 5.2,
        weight: 4.7,
        r: ["Logistics"],
        m: ["Storage Agreements"],
        s: ["Feed Storage", "Cold Storage", "Processing capability"],
        mitigated: ["lg-sa"],
      },
      {
        color: colors.indigo[300],
        key: "infrastructure",
        label: <Text>SRS.infrastructure</Text>,
        description: <Text>SRS.infrastructure-description</Text>,
        icon: <WaterSystemLogo color={"#FFF"} size={35} />,
        srs: 1.0,
        sab: 0,
        score: 9.08,
        baseline: 9.08,
        weight: 8.1,
        r: ["Infrustructure"],
        m: ["Connection to Grid", "Auto Feeders", "Regular updates to SLA"],
        s: ["Auto Water quality"],
        mitigated: ["if-ctg", "if-af", "if-rusla"],
      },
      {
        color: colors.brown[300],
        key: "genetics",
        label: <Text>SRS.genetics</Text>,
        description: <Text>SRS.genetics-description</Text>,
        icon: <GeneticLogo color={"#FFF"} size={35} />,
        srs: 1.54,
        sab: -2.24,
        score: 1.92,
        baseline: 4.16,
        weight: 3.7,
        r: ["Genetics"],
        m: ["Certified Genetics"],
        s: [],
        mitigated: ["gn-cergen"],
      },
      {
        color: colors.cyan[300],
        key: "social",
        label: <Text>SRS.social</Text>,
        description: <Text>SRS.social-description</Text>,
        icon: <SocialLogo color={"#FFF"} size={35} />,
        srs: 1.62,
        sab: -4.16,
        score: 2.56,
        baseline: 6.72,
        weight: 6.0,
        r: [],
        m: [],
        s: [],
        mitigated: [],
      },
      {
        color: colors.lime[300],
        key: "external",
        label: <Text>SRS.external</Text>,
        description: <Text>SRS.external-description</Text>,
        icon: <ExternalLogo color={"#FFF"} size={35} />,
        srs: 1.14,
        sab: -0.2,
        score: 1.2,
        baseline: 1.4,
        weight: 1.3,
        r: [],
        m: [],
        s: [],
        mitigated: [],
      },
    ],
  },
  // Shrimpl Demo
  "ad05bfdf-b37d-4b20-99b6-c8e93995ac13": {
    scores: [
      {
        color: colors.amber[300],
        key: "disease",
        label: <Text>SRS.disease</Text>,
        description: <Text>SRS.disease-description</Text>,
        icon: <PreventLogo color={"#FFF"} size={35} />,
        srs: 1.54,
        sab: -12.32,
        score: 10.56,
        baseline: 22.88,
        weight: 20.5,
        r: ["Disease Risk"],
        m: ["SPF PL", "PL Testing", "Biosecurity SOP's"],
        s: ["Genetics Review", "Disease surveillance", "Biosecurity Audit"],
        mitigated: ["ds-spfpl", "ds-pldt", "ds-biosop", "ds-genrev", "ds-ds", "ds-bioaud", "ds-pts", "ds-wit", "ds-afs"],
      },
      {
        color: colors.blue[300],
        key: "meteorological",
        label: <Text>SRS.meteorological</Text>,
        description: <Text>SRS.meteorological-description</Text>,
        icon: <ClimateChangeLogo color={"#FFF"} size={35} />,
        srs: 1.45,
        sab: -7.52,
        score: 9.2,
        baseline: 16.72,
        weight: 15.0,
        r: ["Meteorological Risk"],
        m: ["Weather monitoring systems", "Remote response"],
        s: [],
        mitigated: ["mt-wm", "mt-rr", "mt-eb"],
      },
      {
        color: colors.green[300],
        key: "water-quality",
        label: <Text>SRS.water-quality</Text>,
        description: <Text>SRS.water-quality-description</Text>,
        icon: <WaterQualityLogo color={"#FFF"} size={35} />,
        srs: 1.87,
        sab: -17.28,
        score: 2.48,
        baseline: 19.76,
        weight: 17.7,
        r: ["Water Quality"],
        m: ["Lead scoring", "Quality of leads"],
        s: ["Automated water testing", "Automated aeration", "automated water exchange"],
        mitigated: ["wq-ls", "wq-ql"],
      },
      {
        color: colors.red[300],
        key: "management",
        label: <Text>SRS.management</Text>,
        description: <Text>SRS.management-description</Text>,
        icon: <OperationalRiskLogo color={"#FFF"} size={35} />,
        srs: 1.36,
        sab: -3.8,
        score: 6.64,
        baseline: 10.44,
        weight: 9.3,
        r: ["Management"],
        m: ["Data reporting systems", "Capacity monitoring"],
        s: ["Automated feeding", "Increase density"],
        mitigated: ["mn-drc", "mn-id"],
      },
      {
        color: colors.orange[300],
        key: "market",
        label: <Text>SRS.market</Text>,
        description: <Text>SRS.market-description</Text>,
        icon: <MarketLogo color={"#FFF"} size={35} />,
        srs: 1,
        sab: 0,
        score: 10.24,
        baseline: 10.24,
        weight: 9.2,
        r: ["Market"],
        m: ["3 key suppliers", "Partial harvests"],
        s: ["Further reduce COP"],
        mitigated: ["mk-m3s", "mk-ph"],
      },
      {
        color: colors.teal[300],
        key: "regulatory",
        label: <Text>SRS.regulatory</Text>,
        description: <Text>SRS.regulatory-description</Text>,
        icon: <RegulatoryLogo color={"#FFF"} size={35} />,
        srs: 1.16,
        sab: -0.8,
        score: 4.32,
        baseline: 5.12,
        weight: 4.6,
        r: ["Regulatory"],
        m: ["ASC Certified", "BAP Certified"],
        s: ["HACCP Certification"],
        mitigated: ["rg-ascc", "rg-bapc"],
      },
      {
        color: colors.deepPurple[300],
        key: "logistics",
        label: <Text>SRS.logistics</Text>,
        description: <Text>SRS.logistics-description</Text>,
        icon: <SupplyChainLogo color={"#FFF"} size={35} />,
        srs: 1.11,
        sab: -0.56,
        score: 4.64,
        baseline: 5.2,
        weight: 4.7,
        r: ["Logistics"],
        m: ["Storage Agreements"],
        s: ["Feed Storage", "Cold Storage", "Processing capability"],
        mitigated: ["lg-sa"],
      },
      {
        color: colors.indigo[300],
        key: "infrastructure",
        label: <Text>SRS.infrastructure</Text>,
        description: <Text>SRS.infrastructure-description</Text>,
        icon: <WaterSystemLogo color={"#FFF"} size={35} />,
        srs: 1.0,
        sab: 0,
        score: 9.08,
        baseline: 9.08,
        weight: 8.1,
        r: ["Infrustructure"],
        m: ["Connection to Grid", "Auto Feeders", "Regular updates to SLA"],
        s: ["Auto Water quality"],
        mitigated: ["if-ctg", "if-af", "if-rusla"],
      },
      {
        color: colors.brown[300],
        key: "genetics",
        label: <Text>SRS.genetics</Text>,
        description: <Text>SRS.genetics-description</Text>,
        icon: <GeneticLogo color={"#FFF"} size={35} />,
        srs: 1.54,
        sab: -2.24,
        score: 1.92,
        baseline: 4.16,
        weight: 3.7,
        r: ["Genetics"],
        m: ["Certified Genetics"],
        s: [],
        mitigated: ["gn-cergen"],
      },
      {
        color: colors.cyan[300],
        key: "social",
        label: <Text>SRS.social</Text>,
        description: <Text>SRS.social-description</Text>,
        icon: <SocialLogo color={"#FFF"} size={35} />,
        srs: 1.62,
        sab: -4.16,
        score: 2.56,
        baseline: 6.72,
        weight: 6.0,
        r: [],
        m: [],
        s: [],
        mitigated: [],
      },
      {
        color: colors.lime[300],
        key: "external",
        label: <Text>SRS.external</Text>,
        description: <Text>SRS.external-description</Text>,
        icon: <ExternalLogo color={"#FFF"} size={35} />,
        srs: 1.14,
        sab: -0.2,
        score: 1.2,
        baseline: 1.4,
        weight: 1.3,
        r: [],
        m: [],
        s: [],
        mitigated: [],
      },
    ],
  },
  // El Caracol - MX
  "683d4bf7-9d8a-4747-9d07-2fbf0f560f6a": {
    scores: [
      {
        color: colors.amber[300],
        key: "disease",
        label: <Text>SRS.disease</Text>,
        description: <Text>SRS.disease-description</Text>,
        icon: <PreventLogo color={"#FFF"} size={35} />,
        srs: 1.58,
        score: 9.56,
        baseline: 22.88,
        weight: 20.5,
        r: ["Disease Risk"],
        m: ["SPF PL", "PL Testing", "Biosecurity SOP's"],
        s: ["Genetics Review", "Disease surveillance", "Biosecurity Audit"],
        mitigated: ["ds-spfpl", "ds-pldt", "ds-biosop", "ds-genrev", "ds-ds", "ds-bioaud", "ds-pts", "ds-wit", "ds-afs"],
      },
      {
        color: colors.blue[300],
        key: "meteorological",
        label: <Text>SRS.meteorological</Text>,
        description: <Text>SRS.meteorological-description</Text>,
        icon: <ClimateChangeLogo color={"#FFF"} size={35} />,
        srs: 1.45,
        score: 9.2,
        baseline: 16.72,
        weight: 15.0,
        r: ["Meteorological Risk"],
        m: ["Weather monitoring systems", "Remote response"],
        s: [],
        mitigated: ["mt-wm", "mt-rr", "mt-eb"],
      },
      {
        color: colors.green[300],
        key: "water-quality",
        label: <Text>SRS.water-quality</Text>,
        description: <Text>SRS.water-quality-description</Text>,
        icon: <WaterQualityLogo color={"#FFF"} size={35} />,
        srs: 1.87,
        score: 2.48,
        baseline: 19.76,
        weight: 17.7,
        r: ["Water Quality"],
        m: ["Lead scoring", "Quality of leads"],
        s: ["Automated water testing", "Automated aeration", "automated water exchange"],
        mitigated: ["wq-ls", "wq-ql"],
      },
      {
        color: colors.red[300],
        key: "management",
        label: <Text>SRS.management</Text>,
        description: <Text>SRS.management-description</Text>,
        icon: <OperationalRiskLogo color={"#FFF"} size={35} />,
        srs: 1.42,
        score: 6.04,
        baseline: 10.44,
        weight: 9.3,
        r: ["Management"],
        m: ["Data reporting systems", "Capacity monitoring"],
        s: ["Automated feeding", "Increase density"],
        mitigated: ["mn-drc", "mn-id"],
      },
      {
        color: colors.orange[300],
        key: "market",
        label: <Text>SRS.market</Text>,
        description: <Text>SRS.market-description</Text>,
        icon: <MarketLogo color={"#FFF"} size={35} />,
        srs: 1.16,
        score: 8.64,
        baseline: 10.24,
        weight: 9.2,
        r: ["Market"],
        m: ["3 key suppliers", "Partial harvests"],
        s: ["Further reduce COP"],
        mitigated: ["mk-m3s", "mk-ph"],
      },
      {
        color: colors.teal[300],
        key: "regulatory",
        label: <Text>SRS.regulatory</Text>,
        description: <Text>SRS.regulatory-description</Text>,
        icon: <RegulatoryLogo color={"#FFF"} size={35} />,
        srs: 1.23,
        score: 3.92,
        baseline: 5.12,
        weight: 4.6,
        r: ["Regulatory"],
        m: ["ASC Certified", "BAP Certified"],
        s: ["HACCP Certification"],
        mitigated: ["rg-ascc", "rg-bapc"],
      },
      {
        color: colors.deepPurple[300],
        key: "logistics",
        label: <Text>SRS.logistics</Text>,
        description: <Text>SRS.logistics-description</Text>,
        icon: <SupplyChainLogo color={"#FFF"} size={35} />,
        srs: 1.52,
        score: 2.48,
        baseline: 5.2,
        weight: 4.7,
        r: ["Logistics"],
        m: ["Storage Agreements"],
        s: ["Feed Storage", "Cold Storage", "Processing capability"],
        mitigated: ["lg-sa"],
      },
      {
        color: colors.indigo[300],
        key: "infrastructure",
        label: <Text>SRS.infrastructure</Text>,
        description: <Text>SRS.infrastructure-description</Text>,
        icon: <WaterSystemLogo color={"#FFF"} size={35} />,
        srs: 1.23,
        score: 7,
        baseline: 9.08,
        weight: 8.1,
        r: ["Infrustructure"],
        m: ["Connection to Grid", "Auto Feeders", "Regular updates to SLA"],
        s: ["Auto Water quality"],
        mitigated: ["if-ctg", "if-af", "if-rusla"],
      },
      {
        color: colors.brown[300],
        key: "genetics",
        label: <Text>SRS.genetics</Text>,
        description: <Text>SRS.genetics-description</Text>,
        icon: <GeneticLogo color={"#FFF"} size={35} />,
        srs: 1.69,
        score: 1.28,
        baseline: 4.16,
        weight: 3.7,
        r: ["Genetics"],
        m: ["Certified Genetics"],
        s: [],
        mitigated: ["gn-cergen"],
      },
      {
        color: colors.cyan[300],
        key: "social",
        label: <Text>SRS.social</Text>,
        description: <Text>SRS.social-description</Text>,
        icon: <SocialLogo color={"#FFF"} size={35} />,
        srs: 1.62,
        score: 2.56,
        baseline: 6.72,
        weight: 6.0,
        r: [],
        m: [],
        s: [],
        mitigated: [],
      },
      {
        color: colors.lime[300],
        key: "external",
        label: <Text>SRS.external</Text>,
        description: <Text>SRS.external-description</Text>,
        icon: <ExternalLogo color={"#FFF"} size={35} />,
        srs: 1.14,
        score: 1.2,
        baseline: 1.4,
        weight: 1.3,
        r: [],
        m: [],
        s: [],
        mitigated: [],
      },
    ],
  },
  // DEMO 2024 Aug
  "5dfbf4ea-289d-41e6-8e87-200e23fbfc17": {
    scores: [
      {
        color: colors.amber[300],
        key: "disease",
        label: <Text>SRS.disease</Text>,
        description: <Text>SRS.disease-description</Text>,
        icon: <PreventLogo color={"#FFF"} size={35} />,
        srs: 1.58,
        score: 9.56,
        baseline: 22.88,
        weight: 20.5,
        r: ["Disease Risk"],
        m: ["SPF PL", "PL Testing", "Biosecurity SOP's"],
        s: ["Genetics Review", "Disease surveillance", "Biosecurity Audit"],
        mitigated: ["ds-spfpl", "ds-pldt", "ds-biosop", "ds-genrev", "ds-ds", "ds-bioaud", "ds-pts", "ds-wit", "ds-afs"],
      },
      {
        color: colors.blue[300],
        key: "meteorological",
        label: <Text>SRS.meteorological</Text>,
        description: <Text>SRS.meteorological-description</Text>,
        icon: <ClimateChangeLogo color={"#FFF"} size={35} />,
        srs: 1.45,
        score: 9.2,
        baseline: 16.72,
        weight: 15.0,
        r: ["Meteorological Risk"],
        m: ["Weather monitoring systems", "Remote response"],
        s: [],
        mitigated: ["mt-wm", "mt-rr", "mt-eb"],
      },
      {
        color: colors.green[300],
        key: "water-quality",
        label: <Text>SRS.water-quality</Text>,
        description: <Text>SRS.water-quality-description</Text>,
        icon: <WaterQualityLogo color={"#FFF"} size={35} />,
        srs: 1.87,
        score: 2.48,
        baseline: 19.76,
        weight: 17.7,
        r: ["Water Quality"],
        m: ["Lead scoring", "Quality of leads"],
        s: ["Automated water testing", "Automated aeration", "automated water exchange"],
        mitigated: ["wq-ls", "wq-ql"],
      },
      {
        color: colors.red[300],
        key: "management",
        label: <Text>SRS.management</Text>,
        description: <Text>SRS.management-description</Text>,
        icon: <OperationalRiskLogo color={"#FFF"} size={35} />,
        srs: 1.42,
        score: 6.04,
        baseline: 10.44,
        weight: 9.3,
        r: ["Management"],
        m: ["Data reporting systems", "Capacity monitoring"],
        s: ["Automated feeding", "Increase density"],
        mitigated: ["mn-drc", "mn-id"],
      },
      {
        color: colors.orange[300],
        key: "market",
        label: <Text>SRS.market</Text>,
        description: <Text>SRS.market-description</Text>,
        icon: <MarketLogo color={"#FFF"} size={35} />,
        srs: 1.16,
        score: 8.64,
        baseline: 10.24,
        weight: 9.2,
        r: ["Market"],
        m: ["3 key suppliers", "Partial harvests"],
        s: ["Further reduce COP"],
        mitigated: ["mk-m3s", "mk-ph"],
      },
      {
        color: colors.teal[300],
        key: "regulatory",
        label: <Text>SRS.regulatory</Text>,
        description: <Text>SRS.regulatory-description</Text>,
        icon: <RegulatoryLogo color={"#FFF"} size={35} />,
        srs: 1.23,
        score: 3.92,
        baseline: 5.12,
        weight: 4.6,
        r: ["Regulatory"],
        m: ["ASC Certified", "BAP Certified"],
        s: ["HACCP Certification"],
        mitigated: ["rg-ascc", "rg-bapc"],
      },
      {
        color: colors.deepPurple[300],
        key: "logistics",
        label: <Text>SRS.logistics</Text>,
        description: <Text>SRS.logistics-description</Text>,
        icon: <SupplyChainLogo color={"#FFF"} size={35} />,
        srs: 1.52,
        score: 2.48,
        baseline: 5.2,
        weight: 4.7,
        r: ["Logistics"],
        m: ["Storage Agreements"],
        s: ["Feed Storage", "Cold Storage", "Processing capability"],
        mitigated: ["lg-sa"],
      },
      {
        color: colors.indigo[300],
        key: "infrastructure",
        label: <Text>SRS.infrastructure</Text>,
        description: <Text>SRS.infrastructure-description</Text>,
        icon: <WaterSystemLogo color={"#FFF"} size={35} />,
        srs: 1.23,
        score: 7,
        baseline: 9.08,
        weight: 8.1,
        r: ["Infrustructure"],
        m: ["Connection to Grid", "Auto Feeders", "Regular updates to SLA"],
        s: ["Auto Water quality"],
        mitigated: ["if-ctg", "if-af", "if-rusla"],
      },
      {
        color: colors.brown[300],
        key: "genetics",
        label: <Text>SRS.genetics</Text>,
        description: <Text>SRS.genetics-description</Text>,
        icon: <GeneticLogo color={"#FFF"} size={35} />,
        srs: 1.69,
        score: 1.28,
        baseline: 4.16,
        weight: 3.7,
        r: ["Genetics"],
        m: ["Certified Genetics"],
        s: [],
        mitigated: ["gn-cergen"],
      },
      {
        color: colors.cyan[300],
        key: "social",
        label: <Text>SRS.social</Text>,
        description: <Text>SRS.social-description</Text>,
        icon: <SocialLogo color={"#FFF"} size={35} />,
        srs: 1.62,
        score: 2.56,
        baseline: 6.72,
        weight: 6.0,
        r: [],
        m: [],
        s: [],
        mitigated: [],
      },
      {
        color: colors.lime[300],
        key: "external",
        label: <Text>SRS.external</Text>,
        description: <Text>SRS.external-description</Text>,
        icon: <ExternalLogo color={"#FFF"} size={35} />,
        srs: 1.14,
        score: 1.2,
        baseline: 1.4,
        weight: 1.3,
        r: [],
        m: [],
        s: [],
        mitigated: [],
      },
    ],
  },
  // Kopaggio DEMO 2024 Aug
  "d2c7bf74-1edb-4e45-a65b-e10c72a93b15": {
    scores: [
      {
        color: colors.amber[300],
        key: "disease",
        label: <Text>SRS.disease</Text>,
        description: <Text>SRS.disease-description</Text>,
        icon: <PreventLogo color={"#FFF"} size={35} />,
        srs: 1.58,
        score: 9.56,
        baseline: 22.88,
        weight: 20.5,
        r: ["Disease Risk"],
        m: ["SPF PL", "PL Testing", "Biosecurity SOP's"],
        s: ["Genetics Review", "Disease surveillance", "Biosecurity Audit"],
        mitigated: ["ds-spfpl", "ds-pldt", "ds-biosop", "ds-genrev", "ds-ds", "ds-bioaud", "ds-pts", "ds-wit", "ds-afs"],
      },
      {
        color: colors.blue[300],
        key: "meteorological",
        label: <Text>SRS.meteorological</Text>,
        description: <Text>SRS.meteorological-description</Text>,
        icon: <ClimateChangeLogo color={"#FFF"} size={35} />,
        srs: 1.45,
        score: 9.2,
        baseline: 16.72,
        weight: 15.0,
        r: ["Meteorological Risk"],
        m: ["Weather monitoring systems", "Remote response"],
        s: [],
        mitigated: ["mt-wm", "mt-rr", "mt-eb"],
      },
      {
        color: colors.green[300],
        key: "water-quality",
        label: <Text>SRS.water-quality</Text>,
        description: <Text>SRS.water-quality-description</Text>,
        icon: <WaterQualityLogo color={"#FFF"} size={35} />,
        srs: 1.87,
        score: 2.48,
        baseline: 19.76,
        weight: 17.7,
        r: ["Water Quality"],
        m: ["Lead scoring", "Quality of leads"],
        s: ["Automated water testing", "Automated aeration", "automated water exchange"],
        mitigated: ["wq-ls", "wq-ql"],
      },
      {
        color: colors.red[300],
        key: "management",
        label: <Text>SRS.management</Text>,
        description: <Text>SRS.management-description</Text>,
        icon: <OperationalRiskLogo color={"#FFF"} size={35} />,
        srs: 1.42,
        score: 6.04,
        baseline: 10.44,
        weight: 9.3,
        r: ["Management"],
        m: ["Data reporting systems", "Capacity monitoring"],
        s: ["Automated feeding", "Increase density"],
        mitigated: ["mn-drc", "mn-id"],
      },
      {
        color: colors.orange[300],
        key: "market",
        label: <Text>SRS.market</Text>,
        description: <Text>SRS.market-description</Text>,
        icon: <MarketLogo color={"#FFF"} size={35} />,
        srs: 1.16,
        score: 8.64,
        baseline: 10.24,
        weight: 9.2,
        r: ["Market"],
        m: ["3 key suppliers", "Partial harvests"],
        s: ["Further reduce COP"],
        mitigated: ["mk-m3s", "mk-ph"],
      },
      {
        color: colors.teal[300],
        key: "regulatory",
        label: <Text>SRS.regulatory</Text>,
        description: <Text>SRS.regulatory-description</Text>,
        icon: <RegulatoryLogo color={"#FFF"} size={35} />,
        srs: 1.23,
        score: 3.92,
        baseline: 5.12,
        weight: 4.6,
        r: ["Regulatory"],
        m: ["ASC Certified", "BAP Certified"],
        s: ["HACCP Certification"],
        mitigated: ["rg-ascc", "rg-bapc"],
      },
      {
        color: colors.deepPurple[300],
        key: "logistics",
        label: <Text>SRS.logistics</Text>,
        description: <Text>SRS.logistics-description</Text>,
        icon: <SupplyChainLogo color={"#FFF"} size={35} />,
        srs: 1.52,
        score: 2.48,
        baseline: 5.2,
        weight: 4.7,
        r: ["Logistics"],
        m: ["Storage Agreements"],
        s: ["Feed Storage", "Cold Storage", "Processing capability"],
        mitigated: ["lg-sa"],
      },
      {
        color: colors.indigo[300],
        key: "infrastructure",
        label: <Text>SRS.infrastructure</Text>,
        description: <Text>SRS.infrastructure-description</Text>,
        icon: <WaterSystemLogo color={"#FFF"} size={35} />,
        srs: 1.23,
        score: 7,
        baseline: 9.08,
        weight: 8.1,
        r: ["Infrustructure"],
        m: ["Connection to Grid", "Auto Feeders", "Regular updates to SLA"],
        s: ["Auto Water quality"],
        mitigated: ["if-ctg", "if-af", "if-rusla"],
      },
      {
        color: colors.brown[300],
        key: "genetics",
        label: <Text>SRS.genetics</Text>,
        description: <Text>SRS.genetics-description</Text>,
        icon: <GeneticLogo color={"#FFF"} size={35} />,
        srs: 1.69,
        score: 1.28,
        baseline: 4.16,
        weight: 3.7,
        r: ["Genetics"],
        m: ["Certified Genetics"],
        s: [],
        mitigated: ["gn-cergen"],
      },
      {
        color: colors.cyan[300],
        key: "social",
        label: <Text>SRS.social</Text>,
        description: <Text>SRS.social-description</Text>,
        icon: <SocialLogo color={"#FFF"} size={35} />,
        srs: 1.62,
        score: 2.56,
        baseline: 6.72,
        weight: 6.0,
        r: [],
        m: [],
        s: [],
        mitigated: [],
      },
      {
        color: colors.lime[300],
        key: "external",
        label: <Text>SRS.external</Text>,
        description: <Text>SRS.external-description</Text>,
        icon: <ExternalLogo color={"#FFF"} size={35} />,
        srs: 1.14,
        score: 1.2,
        baseline: 1.4,
        weight: 1.3,
        r: [],
        m: [],
        s: [],
        mitigated: [],
      },
    ],
  },
  "bb09d3e7-1a12-4cdc-be15-cc4e434f50a0": {
    scores: [
      {
        color: colors.amber[300],
        key: "disease",
        label: <Text>SRS.disease</Text>,
        description: <Text>SRS.disease-description</Text>,
        icon: <PreventLogo color={"#FFF"} size={35} />,
        srs: 1.58,
        score: 9.56,
        baseline: 22.88,
        weight: 20.5,
        r: ["Disease Risk"],
        m: ["SPF PL", "PL Testing", "Biosecurity SOP's"],
        s: ["Genetics Review", "Disease surveillance", "Biosecurity Audit"],
        mitigated: ["ds-spfpl", "ds-pldt", "ds-biosop", "ds-genrev", "ds-ds", "ds-bioaud", "ds-pts", "ds-wit", "ds-afs"],
      },
      {
        color: colors.blue[300],
        key: "meteorological",
        label: <Text>SRS.meteorological</Text>,
        description: <Text>SRS.meteorological-description</Text>,
        icon: <ClimateChangeLogo color={"#FFF"} size={35} />,
        srs: 1.45,
        score: 9.2,
        baseline: 16.72,
        weight: 15.0,
        r: ["Meteorological Risk"],
        m: ["Weather monitoring systems", "Remote response"],
        s: [],
        mitigated: ["mt-wm", "mt-rr", "mt-eb"],
      },
      {
        color: colors.green[300],
        key: "water-quality",
        label: <Text>SRS.water-quality</Text>,
        description: <Text>SRS.water-quality-description</Text>,
        icon: <WaterQualityLogo color={"#FFF"} size={35} />,
        srs: 1.87,
        score: 2.48,
        baseline: 19.76,
        weight: 17.7,
        r: ["Water Quality"],
        m: ["Lead scoring", "Quality of leads"],
        s: ["Automated water testing", "Automated aeration", "automated water exchange"],
        mitigated: ["wq-ls", "wq-ql"],
      },
      {
        color: colors.red[300],
        key: "management",
        label: <Text>SRS.management</Text>,
        description: <Text>SRS.management-description</Text>,
        icon: <OperationalRiskLogo color={"#FFF"} size={35} />,
        srs: 1.42,
        score: 6.04,
        baseline: 10.44,
        weight: 9.3,
        r: ["Management"],
        m: ["Data reporting systems", "Capacity monitoring"],
        s: ["Automated feeding", "Increase density"],
        mitigated: ["mn-drc", "mn-id"],
      },
      {
        color: colors.orange[300],
        key: "market",
        label: <Text>SRS.market</Text>,
        description: <Text>SRS.market-description</Text>,
        icon: <MarketLogo color={"#FFF"} size={35} />,
        srs: 1.16,
        score: 8.64,
        baseline: 10.24,
        weight: 9.2,
        r: ["Market"],
        m: ["3 key suppliers", "Partial harvests"],
        s: ["Further reduce COP"],
        mitigated: ["mk-m3s", "mk-ph"],
      },
      {
        color: colors.teal[300],
        key: "regulatory",
        label: <Text>SRS.regulatory</Text>,
        description: <Text>SRS.regulatory-description</Text>,
        icon: <RegulatoryLogo color={"#FFF"} size={35} />,
        srs: 1.23,
        score: 3.92,
        baseline: 5.12,
        weight: 4.6,
        r: ["Regulatory"],
        m: ["ASC Certified", "BAP Certified"],
        s: ["HACCP Certification"],
        mitigated: ["rg-ascc", "rg-bapc"],
      },
      {
        color: colors.deepPurple[300],
        key: "logistics",
        label: <Text>SRS.logistics</Text>,
        description: <Text>SRS.logistics-description</Text>,
        icon: <SupplyChainLogo color={"#FFF"} size={35} />,
        srs: 1.52,
        score: 2.48,
        baseline: 5.2,
        weight: 4.7,
        r: ["Logistics"],
        m: ["Storage Agreements"],
        s: ["Feed Storage", "Cold Storage", "Processing capability"],
        mitigated: ["lg-sa"],
      },
      {
        color: colors.indigo[300],
        key: "infrastructure",
        label: <Text>SRS.infrastructure</Text>,
        description: <Text>SRS.infrastructure-description</Text>,
        icon: <WaterSystemLogo color={"#FFF"} size={35} />,
        srs: 1.23,
        score: 7,
        baseline: 9.08,
        weight: 8.1,
        r: ["Infrustructure"],
        m: ["Connection to Grid", "Auto Feeders", "Regular updates to SLA"],
        s: ["Auto Water quality"],
        mitigated: ["if-ctg", "if-af", "if-rusla"],
      },
      {
        color: colors.brown[300],
        key: "genetics",
        label: <Text>SRS.genetics</Text>,
        description: <Text>SRS.genetics-description</Text>,
        icon: <GeneticLogo color={"#FFF"} size={35} />,
        srs: 1.69,
        score: 1.28,
        baseline: 4.16,
        weight: 3.7,
        r: ["Genetics"],
        m: ["Certified Genetics"],
        s: [],
        mitigated: ["gn-cergen"],
      },
      {
        color: colors.cyan[300],
        key: "social",
        label: <Text>SRS.social</Text>,
        description: <Text>SRS.social-description</Text>,
        icon: <SocialLogo color={"#FFF"} size={35} />,
        srs: 1.62,
        score: 2.56,
        baseline: 6.72,
        weight: 6.0,
        r: [],
        m: [],
        s: [],
        mitigated: [],
      },
      {
        color: colors.lime[300],
        key: "external",
        label: <Text>SRS.external</Text>,
        description: <Text>SRS.external-description</Text>,
        icon: <ExternalLogo color={"#FFF"} size={35} />,
        srs: 1.14,
        score: 1.2,
        baseline: 1.4,
        weight: 1.3,
        r: [],
        m: [],
        s: [],
        mitigated: [],
      },
    ],
  },
};

const renderCharts = {
  scoreBarChart: (data) => {
    let delayed;
    const chartConfigs = {
      chart: {
        type: "column",
        height: "500px",
      },
      title: {
        text: "",
      },
      plotOptions: {
        series: {
          grouping: false,
          borderWidth: 0,
        },
      },
      legend: {
        enabled: true,
      },
      xAxis: {
        type: "category",
        labels: {
          useHTML: true,
          animate: true,
          format: "{value}",
          style: {
            textAlign: "center",
          },
        },
      },
      yAxis: [
        {
          title: {
            text: "",
          },
          showFirstLabel: false,
        },
      ],
      series: [
        {
          name: "baseline",
          data: data.map((ele) => [getText(`SRS.${ele.key}`), ele.baseline]),
          color: colors.grey[200],
          pointPlacement: -0.2,
          linkedTo: "main",
          dataLabels: [
            {
              enabled: true,
              inside: false,
              style: {
                fontSize: "12px",
              },
            },
          ],
        },
        {
          name: "score",
          id: "main",
          data: data.map((ele) => [getText(`SRS.${ele.key}`), ele.score]),
          color: colors.blue[500],
          dataLabels: [
            {
              enabled: true,
              inside: true,
              style: {
                fontSize: "12px",
              },
            },
          ],
        },
      ],
    };
    return (
      <Box height={"500px"}>
        <HighchartsWrapper options={chartConfigs} />
      </Box>
    );
  },
  weightPolarAreaChart: (data) => {
    const configs = {
      type: "polarArea",
      data: {
        labels: data.map((ele) => getText(`SRS.${ele.key}`)),
        datasets: [
          {
            label: "Weight",
            data: data.map((ele) => ele.weight),
            backgroundColor: data.map((ele) => ele.color),
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          r: {
            pointLabels: {
              display: true,
              centerPointLabels: true,
              font: {
                size: 18,
              },
            },
          },
        },
      },
    };
    return (
      <Box height={"500px"}>
        <ChartjsContainer {...configs} />
      </Box>
    );
  },
  srstable: (data) => {
    const ContentBox = ({ color, title, subtitle, icon, content }) => (
      <Stack
        direction={"column"}
        p={2}
        bgcolor={color}
        height={"100%"}
        boxSizing={"border-box"}
        sx={{
          transition: "filter .3s",
          "&:hover": {
            filter: "brightness(1.1)",
          },
        }}
        minHeight={150}
      >
        {content ? (
          content
        ) : (
          <>
            {" "}
            <Box mb={1}>{icon}</Box>
            <Box>
              <Typography color="white" fontWeight={"bold"} fontSize={15}>
                {title}
              </Typography>
              <Typography color="white" fontSize={10}>
                {subtitle}
              </Typography>
            </Box>
          </>
        )}
      </Stack>
    );

    return (
      <Grid container spacing={0.5} columns={7}>
        <Grid item xs={1}></Grid>
        <Grid item xs={2}>
          <ContentBox title={<Text>SRS.srs-score</Text>} icon={<AssesementLogo color={"#FFF"} size={50} />} color={colors.purple[400]} />
        </Grid>
        <Grid item xs={2}>
          <ContentBox title={<Text>SRS.mitigants</Text>} icon={<WarningLogo color={"#FFF"} size={50} />} color={colors.teal[400]} />
        </Grid>
        <Grid item xs={2}>
          <ContentBox title={<Text>SRS.future-solutions</Text>} icon={<SolutionLogo color={"#FFF"} size={50} />} color={colors.orange[400]} />
        </Grid>
        {data.map((ele, idx) => (
          <>
            <Grid item xs={1}>
              <ContentBox title={ele.label} subtitle={ele.description} icon={ele.icon} color={colors.grey[600]} />
            </Grid>
            <Grid item xs={2}>
              <ContentBox color={colors.purple[50]} content={renderCharts.srsGauge(ele.srs)} />
            </Grid>
            {/* <Grid item xs={2}>
              <ContentBox
                color={colors.red[50]}
                content={
                  <Box>
                    {ele.r.map((r) => (
                      <Typography fontWeight={"bold"}>{r}</Typography>
                    ))}
                  </Box>
                }
              />
            </Grid> */}
            <Grid item xs={2}>
              <ContentBox
                color={colors.teal[50]}
                content={
                  <Box>
                    <Typography>
                      <strong>
                        <Text>SRS.actions-taken</Text>
                      </strong>
                    </Typography>
                    <List>
                      {ele.mitigated.map((m) => (
                        <ListItem sx={{ height: 25, p: 0 }}>
                          <ListItemIcon sx={{ minWidth: 20 }}>
                            <CheckCircleOutlineIcon fontSize={"small"} />
                          </ListItemIcon>
                          <Typography fontSize={10}>
                            <Text>{`SRS.${m}`}</Text>
                            {/* {_.find(mitigationActions.list, { key: m })?.label} */}
                          </Typography>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                }
              />
            </Grid>
            <Grid item xs={2}>
              <ContentBox
                color={colors.orange[50]}
                content={
                  <Box>
                    <Typography>
                      <strong>
                        {" "}
                        <Text>SRS.potential-improvements</Text>
                      </strong>
                    </Typography>
                    <List>
                      {mitigationActions.list
                        .filter((e) => e.group === ele.key)
                        .filter((e) => !_.includes(ele.mitigated, e.key))
                        .map((s) => (
                          <ListItem sx={{ height: 25, p: 0 }}>
                            <ListItemIcon sx={{ minWidth: 25 }}>
                              <AddCircleOutlineIcon fontSize={"small"} />
                            </ListItemIcon>
                            <Typography fontSize={10}>
                              {" "}
                              <Text>{`SRS.${s.key}`}</Text>
                            </Typography>
                          </ListItem>
                        ))}
                    </List>
                  </Box>
                }
              />
            </Grid>
          </>
        ))}
      </Grid>
    );
  },
  srsGauge: (score, options) => {
    const srsScore = score;
    const srsMin = 0;
    const srsMax = 2;
    const gaugePerc = srsScore / srsMax;

    const chartConfigs = {
      chart: {
        type: "gauge",
        backgroundColor: "transparent",
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
        height: 300,
      },
      title: {
        text: "",
      },
      pane: {
        startAngle: -90,
        endAngle: 89.9,
        background: null,
        center: ["50%", "75%"],
        size: "90%",
      },
      tooltip: {
        enabled: false,
      },
      // the value axis
      yAxis: {
        min: 0,
        max: 2,
        tickPixelInterval: 72,
        tickPosition: "inside",
        tickColor: "#FFFFFF",
        tickLength: 20,
        tickWidth: 2,
        minorTickInterval: null,
        labels: {
          distance: 15,
          inside: true,
          style: {
            fontSize: "13px",
            fontFamily: typography.fontFamily,
            fontWeight: 800,
          },
        },
        lineWidth: 0,
        plotBands: [
          {
            from: 0,
            to: 0.66,
            color: colors.red[400],
            thickness: 20,
          },
          {
            from: 0.66,
            to: 1.33,
            color: colors.yellow[400],
            thickness: 20,
          },
          {
            from: 1.33,
            to: 2,
            color: colors.green[400],
            thickness: 20,
          },
        ],
      },
      series: [
        {
          name: "SRS",
          data: [srsScore],
          dataLabels: {
            format: "{y:,.2f}",
            borderWidth: 0,
            color: "#333333",
            style: {
              fontSize: "30px",
            },
          },
          dial: {
            radius: "80%",
            backgroundColor: colors.grey[800],
            baseWidth: 12,
            baseLength: "0%",
            rearLength: "0%",
          },
          pivot: {
            backgroundColor: colors.grey[800],
            radius: 6,
          },
        },
      ],
    };

    return (
      <Stack alignItems={"center"}>
        <Box sx={{ position: "relative" }} width={options?.width || "100%"}>
          <HighchartsWrapper options={chartConfigs} />
        </Box>
      </Stack>
    );
  },
  srsSummary: (data) => {
    const generateBulletChartConfigs = ({ label, value }) => {
      return {
        chart: {
          height: 11,
          type: "bullet",
          inverted: true,
          padding: 0,
          margin: 0,
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: [``],
        },
        legend: {
          enabled: false,
        },
        yAxis: {
          gridLineWidth: 0,
          labels: { enabled: false },
          softMax: 2,
          plotBands: [
            {
              from: 0,
              to: 0.66,
              color: colors.red[300],
            },
            {
              from: 0.66,
              to: 1.33,
              color: colors.yellow[300],
            },
            {
              from: 1.33,
              to: 2,
              color: colors.green[300],
            },
          ],
          title: null,
        },
        series: [
          {
            data: [
              {
                y: 0,
                color: "#444",
                target: value || 0,
                name: label,
                targetOptions: {
                  width: "200%",
                  height: 6, // The height of the target
                  borderWidth: 0, // The border width of the target
                  borderColor: "#444", // The border color of the target
                  color: "#444", // The color of the target
                },
              },
            ],
          },
        ],
        tooltip: {
          enabled: false,
        },
      };
    };

    return (
      <Grid spacing={1} container>
        {data.map((ele) => (
          <Grid item xs={6}>
            <Stack justifyContent={"space-between"} alignItems={"center"} direction={"row"}>
              <Typography fontSize={12}>{ele.label}</Typography>
              <Typography fontSize={12} fontWeight={800}>
                <NumberFormat value={ele.srs} displayType="text" decimalScale={2} />
              </Typography>
            </Stack>
            <HighchartsWrapper
              options={generateBulletChartConfigs({
                label: ele.label,
                value: ele.srs,
              })}
            />
          </Grid>
        ))}
      </Grid>
    );
  },
};

const SRSReport = () => {
  const params = useParams();
  const { farmid } = params;

  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      demoSrsData[farmid] && setReportData(demoSrsData[farmid]);
      setLoading(false);
    }, 500);
  }, [farmid]);

  const renderPartial = {
    renderGridItem: ({ title, component }) => {
      return (
        <Box>
          <Box my={1}>
            <Typography variant="cardTitle" gutterBottom fontWeight={"bold"} sx={{ my: 2 }}>
              {title}
            </Typography>
          </Box>
          <Divider />
          <Box my={4}>{component}</Box>
        </Box>
      );
    },
  };

  return (
    <Box p={2}>
      <Typography variant="h5">
        <Text>interface.general.srs-report</Text>
      </Typography>
      <Typography variant="caption">
        {" "}
        <Text>interface.general.farm</Text>#: {farmid}
      </Typography>
      {loading && <LoadingBox />}
      {!loading && reportData && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <renderPartial.renderGridItem
              title={<Text>SRS.score-against-baseline</Text>}
              component={renderCharts.srsGauge(_.sumBy(reportData?.scores, "baseline") > 0 ? 2 - _.sumBy(reportData?.scores, "score") / _.sumBy(reportData?.scores, "baseline") : 1, {
                width: "400px",
              })}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <renderPartial.renderGridItem title={<Text>interface.general.summary</Text>} component={renderCharts.srsSummary(reportData?.scores)} />
          </Grid>
          <Grid item xs={12} md={6}>
            <renderPartial.renderGridItem title={<Text>SRS.score-against-baseline</Text>} component={renderCharts.scoreBarChart(reportData?.scores)} />
          </Grid>

          <Grid item xs={12} md={6} alignItems={"center"}>
            <renderPartial.renderGridItem title={<Text>SRS.srs-weights</Text>} component={renderCharts.weightPolarAreaChart(reportData?.scores)} />
          </Grid>
          <Grid item xs={12} alignItems={"center"}>
            <renderPartial.renderGridItem title={<Text>SRS.srs-table</Text>} component={renderCharts.srstable(reportData?.scores)} />
          </Grid>
        </Grid>
      )}
      {!loading && !reportData && <VerticalAlertBoxCollections.NoData />}
    </Box>
  );
};

export default SRSReport;
