import React, { useState } from "react";
import { Box, Button, Card, CardContent, Grid, Modal, TextField, Typography, colors, FormControl, InputLabel, MenuItem, Select, Alert, AlertTitle, Autocomplete } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { SaveIcon } from "components/Icons/MaterialIcons";
import { CancelIcon } from "components/Icons/MaterialIcons";
import { Form, Formik } from "formik";
import { AdminManager } from "api/api";

const style = {
  width: "100%",
  height: "100%",
  bgcolor: alpha(colors.grey[900], 0.9),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const FieldEditModal = ({ open, handleClose, fieldData, fieldGroups, fieldUnits }) => {
  const [error, setError] = useState(null);

  if (!fieldData) {
    return "";
  }

  const isNew = !fieldData.field_id;
  const initialFormValue = fieldData;
  const isSubmittable = (values) => values.field_id && values.field_name && values.field_group && values.field_unit;

  if (fieldData) {
    return (
      <Modal open={open} onClose={() => handleClose(false)} sx={style}>
        <Card
          sx={{
            maxWidth: "800px",
            width: {
              xs: "100%",
              md: "90%",
            },
            height: {
              xs: "100%",
              md: "auto",
            },
            animation: "fadeIn 0.2s",
            overflow: "auto",
            p: {
              xs: 1,
              md: 2,
            },
            borderRadius: {
              xs: 0,
              sm: 1,
            },
          }}
        >
          <CardContent>
            <Typography variant="h4">{fieldData.field_id ? <span>Update</span> : "Create a New Field"}</Typography>
            {isNew && (
              <Alert sx={{ my: 2 }} severity="info">
                <AlertTitle>Notes</AlertTitle>
                1. "field_id" has to be all lower case alphabets without space (use _ instead). <strong>E.g. "feed_in_dollar_per_kg"</strong>
                <br />
                2. once field is created, the field_id is immutable
              </Alert>
            )}
            <Formik
              initialValues={initialFormValue}
              onSubmit={(values) => {
                setError(null);
                AdminManager.createOrUpdateField(values).then((res) => {
                  const error = res.payload.error;
                  if (error) {
                    setError(error);
                  }
                  handleClose(true);
                });
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => {
                return (
                  <Form>
                    <Grid container my={2} spacing={2}>
                      <Grid xs={12} md={12} item>
                        <TextField fullWidth id="field_id" label="Field ID *" value={values.field_id} onChange={handleChange} disabled={!isNew} />
                      </Grid>
                      <Grid xs={12} md={6} item>
                        <TextField fullWidth id="position" label="Display Position *" value={values.position} onChange={handleChange} />
                      </Grid>
                      <Grid xs={12} md={6} item>
                        <TextField fullWidth id="field_name" label="Label *" value={values.field_name} onChange={handleChange} />
                      </Grid>

                      <Grid xs={12} md={6} item>
                        <Autocomplete
                          disablePortal
                          id="field_group"
                          value={values.field_group}
                          onChange={handleChange}
                          options={fieldGroups.map((ele) => ({ label: ele }))}
                          inputValue={values.field_group}
                          onInputChange={(_, newValue) => {
                            setFieldValue("field_group", newValue);
                          }}
                          renderInput={(params) => <TextField {...params} label="Group *" variant="outlined" />}
                        />
                      </Grid>
                      <Grid xs={12} md={6} item>
                        <TextField fullWidth id="field_sub_group" label="Sub Group" value={values.field_sub_group} onChange={handleChange} />
                      </Grid>
                      <Grid xs={12} md={6} item>
                        <Autocomplete
                          disablePortal
                          id="field_unit"
                          value={values.field_unit}
                          onChange={handleChange}
                          options={fieldUnits.map((ele) => ({ label: ele }))}
                          inputValue={values.field_unit}
                          onInputChange={(_, newValue) => {
                            setFieldValue("field_unit", newValue);
                          }}
                          renderInput={(params) => <TextField {...params} label="Unit *" />}
                        />
                      </Grid>
                      <Grid xs={6} item>
                        <FormControl fullWidth>
                          <InputLabel id="field_type">Field Type</InputLabel>
                          <Select labelId="field_type" label="Field Type" name="field_type" value={values.field_type} onChange={handleChange}>
                            <MenuItem value={"pond"}>Pond</MenuItem>
                            <MenuItem value={"farm"}>Farm</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid xs={6} item>
                        <FormControl fullWidth>
                          <InputLabel id="is_derived">Is Calculated?</InputLabel>
                          <Select labelId="is_derived" label="Is Calculated" name="is_derived" value={values.is_derived} onChange={handleChange} defaultValue={false}>
                            <MenuItem value={true}>YES</MenuItem>
                            <MenuItem value={false}>NO</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid xs={12} item>
                        <TextField fullWidth id="description" label="Description" value={values.description} onChange={handleChange} />
                      </Grid>
                      <Grid xs={6} item>
                        <Button variant="outlined" fullWidth size="large" startIcon={<SaveIcon />} onClick={handleSubmit} disabled={!isSubmittable(values)}>
                          Save
                        </Button>
                      </Grid>
                      <Grid xs={6} item>
                        <Button variant="outlined" fullWidth size="large" color="error" startIcon={<CancelIcon />} onClick={() => handleClose(false)}>
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </CardContent>
        </Card>
      </Modal>
    );
  }
  return "";
};

export default FieldEditModal;
