import { Box, colors } from "@mui/material";
import React from "react";

/**
 *
 * @param {*} param0.progress
 * @returns
 */
const ProgressBar = ({ progress, color = colors.green[500], width = "100%" }) => {
  return (
    <Box
      sx={{
        height: 8,
        width: width,
        position: "relative",
        background: "#EEE",
        borderRadius: 3,
        overflow: "hidden",
        border: `1px solid ${colors.grey[500]}`,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          left: 0,
          top: 0,
          height: "100%",
          width: `${progress}%`,
          background: color || colors.green[500],
        }}
      ></Box>
    </Box>
  );
};

export default ProgressBar;
