import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, Grid, TextField, Typography, colors, InputAdornment, FormControl, MenuItem, Select, Stack, Tooltip, IconButton } from "@mui/material";
import { AddIcon, ArrowBackIcon, InfoIcon, SaveIcon } from "components/Icons/MaterialIcons";
import { Form, Formik } from "formik";
import moment from "moment";
import MapShapeSelector from "components/map/MapShapeSelector";
import { AppSettings } from "helpers/LocalStorage";
import { PondManagerServices } from "api/pondManagerServices";
import * as Yup from "yup";
import { dialogReducerActions } from "redux/dialog";
import { useDispatch } from "react-redux";
import Text from "components/text/Text";
import PondSubCategories from "constants/PondSubCategories";
import PondBulkUpload from "screens/Aquaculture/components/PondBulkUpload";

const formValidationSchema = Yup.object().shape({
  label: Yup.string().required("Required"),
  farm_id: Yup.string().required("Required"),
  group: Yup.string().required("Required"),
  category: Yup.string().required("Required"),
  sub_category: Yup.string().nullable(),
  lat: Yup.number().required("Required"),
  lon: Yup.number().required("Required"),
  area: Yup.number().required("Required"),
  boundary_geojson: Yup.mixed().required("You haven't drawn the Boundary"),
});

const PondEdit = ({ farm, farms, ponds, handleClose, pondData, pondsGeoJson, style }) => {
  const dispatch = useDispatch();

  const isFirstPond = ponds && ponds.length === 0;
  const isCreatePond = !("id" in pondData);

  const handleSubmit = async (values) => {
    values.last_update = moment().format("YYYY-MM-DD hh:mm:ss");
    if (typeof values.boundary_geojson !== "string") {
      values.boundary_geojson = JSON.stringify(values.boundary_geojson);
    }
    const formData = {
      id: values.id,
      boundary_geojson: values.boundary_geojson,
      farm_id: values.farm_id,
      group: values.group,
      label: values.label,
      last_update: values.last_update,
      lat: values.lat,
      lon: values.lon,
      area: values.area,
      category: values.category,
      sub_category: values.sub_category,
    };
    const result = await PondManagerServices.savePond(formData);
    if (result.error) {
      dialogReducerActions.openDialog(dispatch, {
        title: "Ouch!!",
        content: result.error,
        level: "error",
      });
      return;
    }

    // save last geo
    const lastGeoLocation = [values.lon, values.lat];
    AppSettings.Aqua.lastGeoLocation.save(lastGeoLocation);
    dialogReducerActions.openDialog(dispatch, {
      title: pondData.id ? "Pond Details Updated" : "Pond Has Been Created", // #TR
      content: pondData.id ? "The pond details have been updated" : "", // #TR
    });
    handleClose();
  };

  const RenderLabel = ({ label, description, error }) => (
    <Box>
      <Stack direction="row" alignItems={"center"} spacing={0.5}>
        <Typography>{label}</Typography>
        {description && (
          <Tooltip title={description} placement="right-start">
            <IconButton size="small">
              <InfoIcon sx={{ fontSize: "1.4rem" }} />
            </IconButton>
          </Tooltip>
        )}
        {error && <Typography color={colors.red[600]}>* {error}</Typography>}
      </Stack>
    </Box>
  );

  if (pondData) {
    return (
      <Box
        sx={{
          animation: "fadeIn 0.2s",
          overflow: "auto",
          borderRadius: {
            xs: 0,
            sm: 1,
          },
        }}
      >
        {isFirstPond ? (
          <>
            <Typography variant="h5" fontWeight={"bold"}>
              <Text>interface.general.claim-your-first-pond</Text>
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h5" fontWeight={"bold"}>
              {pondData.id ? <Text>interface.actions.update</Text> : <Text>interface.actions.claim-a-new-pond</Text>}
            </Typography>
            <Typography variant="caption">
              {pondData.last_update && (
                <span>
                  <Text>interface.general.last-updated</Text>: {pondData.last_update}
                </span>
              )}
            </Typography>
          </>
        )}

        <Formik initialValues={pondData} onSubmit={handleSubmit} validationSchema={formValidationSchema}>
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => {
            const hasValidCoordinates = values.lon && values.lat;
            const lastGeoCoordinates = AppSettings.Aqua.lastGeoLocation.get();
            const initCoordinate = hasValidCoordinates ? [values.lon, values.lat] : lastGeoCoordinates;
            const onMapSelectCoordinates = (cordObj) => {
              setFieldValue("lon", cordObj.lng);
              setFieldValue("lat", cordObj.lat);
            };
            const onMapAddedPolygon = ({ features, area }) => {
              setFieldValue("boundary_geojson", features ? JSON.stringify(features) : null);
              isCreatePond && setFieldValue("area", area);
            };
            return (
              <Form style={{ margin: "20px 0" }}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Grid container spacing={2}>
                      <Grid xs={12} item>
                        <RenderLabel label={<Text suffix={"*"}>interface.general.label</Text>} description="Give a name to your pond." error={errors.label} />
                        <TextField variant="standard" size="small" fullWidth id="label" value={values.label} onChange={handleChange} />
                      </Grid>
                      <Grid xs={12} item>
                        <RenderLabel label={<Text suffix={"*"}>interface.general.farm</Text>} description="The farm" error={errors.farm_id} />
                        <FormControl fullWidth size="small" variant="standard">
                          <Select labelId="farmId" id="farm_id" name="farm_id" value={values.farm_id} onChange={handleChange}>
                            {farms.map((f, fSeq) => {
                              return (
                                <MenuItem key={fSeq} value={f.farm_id}>
                                  {f.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid xs={12} item>
                        <RenderLabel label={<Text suffix={"*"}>interface.general.group</Text>} description="Specify a group this pond is designated." error={errors.group} />
                        <TextField variant="standard" size="small" fullWidth id="group" value={values.group} onChange={handleChange} placeholder={`e.g. "Group #1"`} required />
                      </Grid>
                      <Grid xs={12} item>
                        <RenderLabel label={<Text suffix={"*"}>interface.general.category</Text>} description="Specify a category" error={errors.category} />
                        <FormControl fullWidth size="small" variant="standard">
                          <Select labelId="category" id="category" name="category" value={values.category} onChange={handleChange} defaultValue={"grow-out-pond"} required>
                            <MenuItem value="grow-out-pond">
                              <Text>interface.general.grow-out-pond</Text>
                            </MenuItem>
                            <MenuItem value="hatchery-pond">
                              <Text>interface.general.hatchery-pond</Text>
                            </MenuItem>
                            <MenuItem value="nursery-pond">
                              <Text>interface.general.nursery-pond</Text>
                            </MenuItem>
                            <MenuItem value="treatment-pond">
                              <Text>interface.general.treatment-pond</Text>
                            </MenuItem>
                            <MenuItem value="holding-pond">
                              <Text>interface.general.holding-pond</Text>
                            </MenuItem>
                            <MenuItem value="quarantine-pond">
                              <Text>interface.general.quarantine-pond</Text>
                            </MenuItem>
                            <MenuItem value="inspection-pond">
                              <Text>interface.general.inspection-pond</Text>
                            </MenuItem>
                            <MenuItem value="feed-pond">
                              <Text>interface.general.feed-pond</Text>
                            </MenuItem>
                            <MenuItem value="purification-pond">
                              <Text>interface.general.purification-pond</Text>
                            </MenuItem>
                            <MenuItem value="sedimentation-pond">
                              <Text>interface.general.sedimentation-pond</Text>
                            </MenuItem>
                            <MenuItem value="reservoir-pond">
                              <Text>interface.general.reservoir-pond</Text>
                            </MenuItem>
                            <MenuItem value="isolation-pond">
                              <Text>interface.general.isolation-pond</Text>
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid xs={12} item>
                        <RenderLabel label={<Text>interface.general.sub-category</Text>} description="Specify a sub-category" error={errors.sub_category} />
                        <FormControl fullWidth size="small" variant="standard">
                          <Select id="sub_category" name="sub_category" value={values.sub_category} onChange={handleChange} defaultValue={PondSubCategories[0]["value"]}>
                            <MenuItem value={null}>-</MenuItem>
                            {PondSubCategories.map((ele) => {
                              return (
                                <MenuItem value={ele.value}>
                                  <Typography>
                                    <strong>
                                      <Text>{`interface.species.${ele.value}`}</Text>
                                    </strong>{" "}
                                    <small>
                                      <i>({ele.value})</i>
                                    </small>
                                  </Typography>
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid xs={12} md={6} item>
                        <RenderLabel label={<Text>interface.general.longitude</Text>} error={errors.lon} description="This will be automatically calculated once the boundary is drawn" />
                        <TextField variant="standard" size="small" fullWidth id="lon" value={values.lon} onChange={handleChange} type="number" InputLabelProps={{ shrink: true }} />
                      </Grid>
                      <Grid xs={12} md={6} item>
                        <RenderLabel label={<Text>interface.general.latitude</Text>} error={errors.lat} description="This will be automatically calculated once the boundary is drawn" />
                        <TextField variant="standard" size="small" fullWidth id="lat" value={values.lat} onChange={handleChange} type="number" InputLabelProps={{ shrink: true }} />
                      </Grid>
                      <Grid xs={12} md={6} item>
                        <RenderLabel label={<Text>interface.general.pond-area</Text>} description="This will be automatically calculated once the boundary is drawn" error={errors.area} />
                        <TextField
                          variant="standard"
                          size="small"
                          fullWidth
                          id="area"
                          value={values.area}
                          onChange={handleChange}
                          type="number"
                          InputProps={{
                            endAdornment: <InputAdornment position="end">ha</InputAdornment>,
                          }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid xs={12} md={6} item>
                        <RenderLabel label="Polygon Drawn" description="We require you to draw the boundary of your pond." error={errors.boundary_geojson} />
                        <TextField variant="standard" size="small" fullWidth id="area" value={values.boundary_geojson ? "✅ Provided" : "❔ Not Provided"} onChange={handleChange} disabled />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={8}>
                    <Grid xs={12} item>
                      <Box
                        mt={2}
                        sx={{
                          borderRadius: 1,
                          overflow: "hidden",
                          height: "calc(100vh - 700px)",
                          minHeight: "500px",
                        }}
                      >
                        <MapShapeSelector
                          center={initCoordinate}
                          initPolygon={values.boundary_geojson}
                          handlePointAdded={onMapSelectCoordinates}
                          handlePolygonAdded={onMapAddedPolygon}
                          coverLayer={pondsGeoJson}
                          coverLayerStyle={style}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid xs={12} item>
                    <Button variant="outlined" fullWidth size="large" startIcon={<SaveIcon />} onClick={handleSubmit}>
                      <Text>interface.actions.save</Text>
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Box>
    );
  }
  return "";
};

export default PondEdit;
