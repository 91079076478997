import _ from "lodash";

const initState = {
  pondsToHighlight: [],
};

const cycleManagementTypes = {
  HIGHLIGHT_POND: "HIGHLIGHT_POND",
  HIGHLIGHT_MULTIPLE_PONDS: "HIGHLIGHT_MULTIPLE_PONDS",
};

const googleMapManagementReducers = (state = initState, action) => {
  switch (action.type) {
    case cycleManagementTypes.HIGHLIGHT_POND:
      return {
        ...state,
        pondsToHighlight: [action.payload],
      };
    case cycleManagementTypes.HIGHLIGHT_MULTIPLE_PONDS:
      return {
        ...state,
        pondsToHighlight: action.payload,
      };
    default:
      return state;
  }
};

export const googleMapManagementActions = {
  highlightPond: async (dispatch, pond) => {
    dispatch({
      type: cycleManagementTypes.HIGHLIGHT_POND,
      payload: pond,
    });
  },
  highlightMultiPonds: async (dispatch, ponds) => {
    dispatch({
      type: cycleManagementTypes.HIGHLIGHT_MULTIPLE_PONDS,
      payload: ponds,
    });
  },
};

export default googleMapManagementReducers;
