import { Box, Container } from "@mui/material";
import FarmProfileForm from "screens/Aquaculture/components/Farm/FarmProfileForm";
import { AppSelectors } from "redux/AppReducers";
import PondManagerAccessWrapper from "components/Wrapper/PondManagerAccessWrapper";
import { ResourcePolicies } from "screens/Aquaculture/components/AccessControl/ResourcePolicies";

const FarmMainSettings = () => {
  const pondManagementStore = AppSelectors.pondManagementStore();
  const { selectedFarm } = pondManagementStore;
  const ready = !!selectedFarm;

  if (!ready) return null;

  return (
    <PondManagerAccessWrapper policies={[ResourcePolicies.SETTINGS]}>
      <Box>
        <Container maxWidth="lg">
          <Box p={2}>
            <FarmProfileForm farm={selectedFarm} />
          </Box>
        </Container>
      </Box>
    </PondManagerAccessWrapper>
  );
};

export default FarmMainSettings;
