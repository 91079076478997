import { Box, Button, Divider, Grid, InputAdornment, Stack, TextField, Typography, colors } from "@mui/material";
import { theme } from "App";
import { PondManagerServices } from "api/pondManagerServices";
import MuiDatetimePicker from "components/DatePicker/MuiDatetimePicker";
import LoadingBox from "components/Loading/LoadingBox";
import ChartjsContainer from "components/chart/ChartjsV4Wrapper";
import Text, { getText } from "components/text/Text";
import { Form, Formik } from "formik";
import Formatters from "helpers/Formatters";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { Fade } from "react-reveal";
import { AppSelectors } from "redux/AppReducers";
import DataLogs from "screens/Aquaculture/components/DataManager/components/DataLogs";
import palette from "themes/palette";

const FinanceUploader = ({ farm, pond }) => {
  const [isloading, setloading] = useState(false);
  const [data, setdata] = useState({});

  const pondManagementStore = AppSelectors.pondManagementStore();
  const financeFields = pondManagementStore.fields.filter((f) => f.field_group === "Cashflow Expense" && f.field_type === "farm");

  const farmCurrency = farm?.settings?.currency || "USD";

  useEffect(() => {
    if (financeFields && farm) {
      Actions.loadTimeserieses();
    }
  }, [farm]);

  const Actions = {
    loadTimeserieses: async () => {
      setloading(true);
      const response = await PondManagerServices.getFarmTimeseriesData(
        farm.farm_id,
        financeFields.map((f) => f.field_id)
      );
      setdata(response);
      setloading(false);
    },
    submitData: async (requestData) => {
      if (requestData.length > 0) {
        setloading(true);
        const response = await PondManagerServices.putFarmData({
          rows: requestData,
        });
        Actions.loadTimeserieses();
      }
    },
  };

  const Renders = {
    renderGraph: ({ data, fieldId }) => {
      const stats = {
        currentValue: _.last(data.raw.filter((ele) => ele.value).filter((ele) => ele.field_id === fieldId)),
        timeseries: _.takeRight(
          data.raw.filter((ele) => ele.value).filter((ele) => ele.field_id === fieldId),
          30
        ),
      };

      const chartConfigs = {
        data: {
          labels: stats.timeseries.map((r) => r.datetime),
          datasets: [
            {
              label: fieldId,
              data: stats.timeseries.map((ele) => ele.value),
              borderColor: theme.palette.primary.main,
              pointRadius: 0,
              borderWidth: 2,
            },
          ],
        },
        type: "line",
        options: {
          responsive: true,
          maintainAspectRatio: false,
          animation: {
            duration: 0,
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          scales: {
            x: {
              display: false,
            },
            y: {
              display: false,
            },
          },
        },
      };

      return (
        <Stack direction={"row"} spacing={1} px={2}>
          <Fade>
            <Box height={"30px"} width={"120px"} borderRadius={1} py={0.5} bgcolor={theme.palette.primary.light}>
              <ChartjsContainer {...chartConfigs} />
            </Box>
          </Fade>

          <Box>
            <Typography fontWeight={600} fontSize={18}>
              {stats.currentValue ? <NumberFormat displayType="text" value={stats.currentValue.value} decimalScale={2} /> : "-"}
            </Typography>
            <Typography fontSize={11} color={"grey"} sx={{ mt: -0.6 }}>
              {stats.currentValue?.datetime}
            </Typography>
          </Box>
        </Stack>
      );
    },
  };

  return (
    <Box p={2}>
      {isloading ? (
        <LoadingBox />
      ) : (
        <Stack spacing={2}>
          <Box p={2} bgcolor={palette.primary.light} borderRadius={2}>
            <Typography variant="cardTitle" color="primary">
              <Text>interface.actions.record-new-data</Text>
            </Typography>
            <Box my={2}>
              <Formik
                initialValues={{
                  created: moment(),
                }}
                onSubmit={(values) => {
                  console.log(values);
                  const requestData = financeFields
                    .filter((f) => values[f.field_id])
                    .map((f) => ({
                      datetime: values.created.format("YYYY-MM-DD hh:mm:ss"),
                      value: values[f.field_id],
                      farm_id: farm.farm_id,
                      field_id: f.field_id,
                    }));
                  Actions.submitData(requestData);
                }}
              >
                {({ values, handleChange, setFieldValue }) => {
                  return (
                    <Form>
                      <Box>
                        <Grid container>
                          <Grid item xs={4}>
                            <Typography fontSize={13} fontWeight={600}>
                              <Text>interface.time.time</Text>
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <MuiDatetimePicker
                              value={values.created}
                              onAccept={(newValue) => {
                                setFieldValue("created", newValue);
                              }}
                              size="small"
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </Box>

                      {["Fixed", "Variable", "Other", "Income"].map((sg) => (
                        <Box key={sg}>
                          <Box>
                            <Typography fontWeight={"bold"} color="secondary">
                              {getText(`fields.subgroup.${sg}`)}
                            </Typography>
                            <Divider></Divider>
                          </Box>
                          <Box my={2}>
                            <Grid container spacing={1}>
                              {financeFields
                                .filter((ele) => ele.field_sub_group === sg)
                                .map((f) => (
                                  <>
                                    <Grid item xs={4}>
                                      <Typography fontSize={13} fontWeight={600}>
                                        {getText(`fields.${f.field_id}`)}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                      <Box>
                                        <TextField
                                          name={f.field_id}
                                          onChange={handleChange}
                                          value={values[f.field_id]}
                                          type={"number"}
                                          size="small"
                                          placeholder={getText(`fields.${f.field_id}`)}
                                          fullWidth
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">{f.field_unit === "$" ? Formatters.Text.formatCurrencySymbol({ code: farmCurrency }) : f.field_unit}</InputAdornment>
                                            ),
                                          }}
                                        ></TextField>
                                      </Box>
                                    </Grid>
                                  </>
                                ))}
                            </Grid>
                          </Box>
                        </Box>
                      ))}

                      <Box mt={3}>
                        <Button size="large" variant="contained" type="submit">
                          <Text>interface.actions.submit</Text>
                        </Button>
                      </Box>
                    </Form>
                  );
                }}
              </Formik>
            </Box>
          </Box>
          <Box mt={2} p={2} bgcolor={colors.grey[100]} borderRadius={2}>
            <Typography variant="cardTitle" color="primary">
              <Text>interface.general.data-logs</Text>
            </Typography>
            <Box mt={2}>
              <DataLogs rows={_.orderBy(data.raw, "datetime", "desc")} />
            </Box>
          </Box>
        </Stack>
      )}
    </Box>
  );
};

export default FinanceUploader;
