import { Chip, colors } from "@mui/material";

const OrgTypes = {
  FEED_PRODUCER: "Feed Producer",
  BANK: "Bank",
  INSURANCE_COMPANY: "Insurance Company",
  UNIVERSITY: "University",
  RESEARCH: "Research",
  OTHER: "Other",
};

export const OrgTypeBadge = ({ orgType }) => {
  const chipStyle = {
    height: 20,
    ".MuiChip-label": {
      px: 1,
      color: "white",
      fontWeight: 500,
    },
  };
  if (orgType === OrgTypes.FEED_PRODUCER) return <Chip label={OrgTypes.FEED_PRODUCER} sx={{ bgcolor: colors.orange[600], ...chipStyle }} />;
  if (orgType === OrgTypes.BANK) return <Chip label={OrgTypes.FEED_PRODUCER} sx={{ bgcolor: colors.blue[600], ...chipStyle }} />;
  if (orgType === OrgTypes.INSURANCE_COMPANY) return <Chip label={OrgTypes.INSURANCE_COMPANY} sx={{ bgcolor: colors.green[600], ...chipStyle }} />;
  if (orgType === OrgTypes.UNIVERSITY) return <Chip label={OrgTypes.UNIVERSITY} sx={{ bgcolor: colors.orange[600], ...chipStyle }} />;
  if (orgType === OrgTypes.RESEARCH) return <Chip label={OrgTypes.RESEARCH} sx={{ bgcolor: colors.red[600], ...chipStyle }} />;
  if (orgType === OrgTypes.OTHER) return <Chip label={OrgTypes.OTHER} sx={{ bgcolor: colors.grey[600], ...chipStyle }} />;
};

export default OrgTypes;
