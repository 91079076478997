import { Alert, colors } from "@mui/material";

export const AlertColorOptions = [
  { value: colors.red[500] },
  { value: colors.green[500] },
  { value: colors.blue[500] },
  { value: colors.orange[500] },
  { value: colors.red[200] },
  { value: colors.green[200] },
  { value: colors.blue[200] },
  { value: colors.orange[200] },
];

export const AlertColorDefault = {
  lowColor: colors.blue[500],
  midColor: colors.green[500],
  highColor: colors.red[500],
  normalWhen: 0,
};

export const defaultAlertRules = {
  seeded_density: {
    min: 20,
    max: 30,
    normalWhen: 0,
  },
  water_temp: {
    max: 35,
    min: 21,
    normalWhen: 0,
  },
  salinity: {
    max: 32,
    min: 12,
    normalWhen: 0,
  },
  dissolved_oxygen: {
    max: 10,
    min: 4,
    normalWhen: 0,
  },
  nitrite: {
    max: 2,
    min: 0,
    normalWhen: 0,
  },
  nitrate: {
    max: 7,
    min: 0,
    normalWhen: 0,
  },
  ammonia: {
    max: 0.5,
    min: 0,
    normalWhen: 0,
  },
  ph: {
    max: 10,
    min: 7,
    normalWhen: 0,
  },
  average_depth: {
    min: 0.5,
    normalWhen: 0,
  },
  alkalinity: {
    max: 150,
    min: 20,
    normalWhen: 0,
  },
  turbidity: {
    max: 60,
    min: 20,
    normalWhen: 0,
  },
  survival_rate: {
    max: 70,
    min: 50,
    highColor: colors.green[500],
    midColor: colors.orange[500],
    lowColor: colors.red[500],
    normalWhen: 1,
  },
};

export const AlertLevels = {
  high: 1,
  mid: 0,
  low: -1,
  missing: -99,
};

export const getAlertResult = ({ field_id, value, rules }) => {
  const alertRules = rules || defaultAlertRules;
  const rulesForField = alertRules?.[field_id];

  if (rulesForField) {
    if (!value) {
      return {
        isAlert: false,
        alertLevel: AlertLevels.missing,
        alertLevelText: "N/V",
        alertColor: colors.grey[600],
        field_id,
        value,
      };
    }
    if (value > rulesForField.max) {
      return {
        isAlert: rulesForField.normalWhen === 1 ? false : true,
        alertLevel: AlertLevels.high,
        alertLevelText: "high",
        alertColor: rulesForField?.highColor || AlertColorDefault.highColor,
        field_id,
        value,
      };
    } else if (value < rulesForField.min) {
      return {
        isAlert: rulesForField.normalWhen === -1 ? false : true,
        alertLevel: AlertLevels.low,
        alertLevelText: "low",
        alertColor: rulesForField?.lowColor || AlertColorDefault.lowColor,
        field_id,
        value,
      };
    } else {
      return {
        isAlert: rulesForField.normalWhen === 0 ? false : true,
        alertLevel: AlertLevels.mid,
        alertLevelText: "mid",
        alertColor: rulesForField?.midColor || AlertColorDefault.midColor,
        field_id,
        value,
      };
    }
  } else {
    return {
      isAlert: false,
      alertLevel: AlertLevels.missing,
      alertLevelText: "N/A",
      alertColor: colors.grey[600],
      field_id,
      value,
    };
  }
};
