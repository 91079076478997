import { Box, Button, Card, Stack, Typography, colors } from "@mui/material";
import HighchartsWrapper from "components/Highcharts/HighchartsWrapper";
import OutlinedTag from "components/Tags/OutlinedTag";
import { getText } from "components/text/Text";
import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";

const FarmCycleWidget = ({ farm, farmDashboardData }) => {
  const cycles = _.values(farmDashboardData?.aggregated_pond_data)
    ?.map((ele) => ele._cycle)
    .filter((ele) => ele);
  const activeCycles = cycles?.filter((ele) => ele.ended === false)?.length;
  const inactiveCycles = cycles?.filter((ele) => ele.ended === true)?.length;

  const generateChartConfigs = () => {
    return {
      chart: {
        type: "",
        height: 200,
        animation: false,
      },
      title: {
        text: "",
      },
      colors: [colors.green[600], colors.grey[300]],
      plotOptions: {
        series: {
          dataLabels: {
            enabled: false,
          },
        },
        pie: {
          animation: false,
        },
      },
      series: [
        {
          type: "pie",
          name: "",
          size: "100%",
          innerSize: "60%",
          data: [
            {
              name: getText("interface.general.active"),
              y: activeCycles,
            },
            {
              name: getText("interface.general.ended"),
              y: inactiveCycles,
            },
          ],
        },
      ],
    };
  };

  return (
    <Box>
      <Stack direction="row" spacing={1}>
        <OutlinedTag text={`🟢 ${getText("interface.general.active")}: ${activeCycles} `} />
        <OutlinedTag text={`⚪ ${getText("interface.general.ended")}: ${inactiveCycles} `} />
      </Stack>
      <HighchartsWrapper options={generateChartConfigs()} />
    </Box>
  );
};

export default FarmCycleWidget;
