import { PondManagerServices } from "api/pondManagerServices";
import { getColorsBasedOnListOfText } from "helpers/ColorHelpers";
import AppLocalStorage from "helpers/LocalStorage";
import _ from "lodash";

const defaultPrice = 144;

const initState = {
  selectedFarm: null,
  selectedGroup: null,
  farms: [],
  fields: [],
  fieldsMap: [],
  ponds: [],
  pondsGeoJson: null,
  pondid: null,
  pondids: [],
  groups: [],
  groupColors: {},
  isMulti: false,
  timeseries: [],
  field: null,
  loading: false,
  shrimpUnitPrices: {
    "91-110": defaultPrice,
    "71-90": defaultPrice,
    "61-70": defaultPrice,
    "51/60": defaultPrice,
    "41/50": defaultPrice,
    "36/40": defaultPrice,
    "31/35": defaultPrice,
    "26/30": defaultPrice,
    "21/25": defaultPrice,
    "16/20": defaultPrice,
    U15: defaultPrice,
    U12: defaultPrice,
    U10: defaultPrice,
  },
  registeredProducts: [],
};

const pondManagementTypes = {
  LOAD_ALL_FARMS: "LOAD_ALL_FARMS",
  LOAD_ALL_PONDS: "LOAD_ALL_PONDS",
  LOAD_ALL_FIELDS: "LOAD_ALL_FIELDS",
  SELECT_POND: "SELECT_POND",
  SELECT_FARM: "SELECT_FARM",
  SELECT_GROUP: "SELECT_GROUP",
  START_LOADING: "START_LOADING",
  END_LOADING: "END_LOADING",
  APPLY_FILTER: "APPLY_FILTER",
  LOAD_SHRIMP_UNIT_PRICES: "LOAD_SHRIMP_UNIT_PRICES",
  FETCH_REGISTERED_PRODUCTS: "FETCH_REGISTERED_PRODUCTS",
};

const pondManagementReducers = (state = initState, action) => {
  switch (action.type) {
    case pondManagementTypes.LOAD_ALL_FARMS:
      return {
        ...state,
        farms: action.payload.farms,
        fields: action.payload.fields,
        fieldsMap: action.payload.fieldsMap,
      };
    case pondManagementTypes.LOAD_ALL_PONDS:
      const { ponds, pondsGeoJson } = action.payload;
      const groups = _.unionBy(ponds, "group")
        .map((e) => e.group)
        .filter((e) => e)
        .sort((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" }));
      const groupColors = getColorsBasedOnListOfText(groups);
      return {
        ...state,
        ponds: _.orderBy(ponds, "label"),
        pondsGeoJson,
        groups,
        groupColors,
      };
    case pondManagementTypes.SELECT_FARM: {
      const local = new AppLocalStorage();
      const { farms } = state;
      const { farmId } = action.payload;
      const selectedFarm = _.find(farms, { farm_id: farmId });
      if (selectedFarm) {
        local.addRecentViewedFarmId(farmId);
        return {
          ...state,
          selectedFarm: selectedFarm,
          selectedGroup: null, // reset group whenever this happens
          pondid: null, // reset pondid whenever this happens
        };
      }
    }
    case pondManagementTypes.SELECT_POND:
      return {
        ...state,
        pondid: action.payload.pondid,
      };
    case pondManagementTypes.SELECT_GROUP:
      return {
        ...state,
        selectedGroup: action.payload.group,
      };
    case pondManagementTypes.START_LOADING:
      if (!state.loading) {
        return {
          ...state,
          loading: true,
        };
      }
    case pondManagementTypes.END_LOADING:
      if (state.loading) {
        return {
          ...state,
          loading: false,
        };
      }
    case pondManagementTypes.LOAD_SHRIMP_UNIT_PRICES:
      return {
        ...state,
        shrimpUnitPrices: action?.payload?.shrimpUnitPrices,
      };
    case pondManagementTypes.SELECT_FARM:
      return {
        ...state,
        selectedFarm: action?.payload?.farm,
      };
    case pondManagementTypes.FETCH_REGISTERED_PRODUCTS:
      return {
        ...state,
        registeredProducts: action?.payload?.registeredProducts,
      };
    default:
      return state;
  }
};

export const pondManagementActions = {
  /**
   * Initial Loading at the top level
   *
   * @param {*} dispatch
   */
  loadAllFarms: async (dispatch, farmid) => {
    dispatch({ type: pondManagementTypes.START_LOADING });
    const farms = await PondManagerServices.getFarms();
    const fields = await PondManagerServices.getFields();
    dispatch({
      type: pondManagementTypes.LOAD_ALL_FARMS,
      payload: {
        farms,
        fields,
        fieldsMap: _.reduce(fields, (d, n) => ({ ...d, [n["field_id"]]: n }), {}),
      },
    });
    // if farmid is defined (action taken when farm id selected)
    if (farmid) {
      const selectedFarm = _.find(farms, { farm_id: farmid });
      if (selectedFarm) {
        dispatch({
          type: pondManagementTypes.SELECT_FARM,
          payload: {
            farm: selectedFarm,
          },
        });
      }
    }
    dispatch({ type: pondManagementTypes.END_LOADING });
  },
  /**
   *
   * @param {*} dispatch
   */
  loadAllPonds: async (dispatch, farmId) => {
    dispatch({ type: pondManagementTypes.START_LOADING });
    const ponds = await PondManagerServices.listPonds(farmId);
    const pondsGeoJson = await PondManagerServices.getPondsGeoJson(farmId);
    dispatch({
      type: pondManagementTypes.LOAD_ALL_PONDS,
      payload: { ponds, pondsGeoJson },
    });
    dispatch({ type: pondManagementTypes.END_LOADING });
  },
  /**
   * select a farm
   *
   * @param {*} dispatch
   * @param {Object} { farmId }
   */
  selectFarm: async (dispatch, params) => {
    const { farmId, farm } = params;
    dispatch({
      type: pondManagementTypes.SELECT_FARM,
      payload: {
        farmId,
        farm,
      },
    });
  },
  /**
   * select a pond
   *
   * @param {*} dispatch
   * @param {*} params
   */
  selectPond: (dispatch, params) => {
    const { pondid } = params;
    dispatch({
      type: pondManagementTypes.SELECT_POND,
      payload: {
        pondid,
      },
    });
  },
  /**
   * select a group
   *
   * @param {*} dispatch
   * @param {*} params
   */
  selectGroup: (dispatch, params) => {
    const { group } = params;
    dispatch({
      type: pondManagementTypes.SELECT_GROUP,
      payload: {
        group,
      },
    });
  },
  /**
   * fetch shrimp unit prices (linked to farm and submitted by users)
   *
   * @param {*} dispatch
   * @param {*} farmId
   */
  loadShrmipUnitPrices: async (dispatch, farmId) => {
    const result = await PondManagerServices.getFarmShrimpUnitPrices(farmId);
    if (!_.isEmpty(result)) {
      const prices = {
        "91-110": result["U91_110"],
        "71-90": result["U71_90"],
        "61-70": result["U61_70"],
        "51/60": result["U51_60"],
        "41/50": result["U41_50"],
        "36/40": result["U36_40"],
        "31/35": result["U31_35"],
        "26/30": result["U26_30"],
        "21/25": result["U21_25"],
        "16/20": result["U16_20"],
        U15: result["U15"],
        U12: result["U12"],
        U10: result["U10"],
        _meta: {
          created: result.created,
        },
      };
      dispatch({
        type: pondManagementTypes.LOAD_SHRIMP_UNIT_PRICES,
        payload: { shrimpUnitPrices: prices },
      });
    }
  },
  /**
   * Save shrimp unit prices
   */
  saveShrimpUnitPrices: async (dispatch, farmId, prices) => {
    await PondManagerServices.addFarmShrimpUnitPrices(farmId, prices);
    pondManagementActions.loadShrmipUnitPrices(dispatch, farmId);
  },
  /**
   * fetch farm registered products
   *
   * @param {*} dispatch
   * @param {*} farmid
   */
  fetchRegistgeredProducts: async (dispatch, farmid) => {
    const result = await PondManagerServices.fetchRegisteredProducts({ farmid });
    const products = _.orderBy(result.rows, ["product_type", "id"]);
    dispatch({
      type: pondManagementTypes.FETCH_REGISTERED_PRODUCTS,
      payload: { registeredProducts: products },
    });
  },
};

export default pondManagementReducers;
