import { Box, Divider, Stack, Typography } from "@mui/material";
import Text from "components/text/Text";
import Formatters from "helpers/Formatters";

const ScenarioDetails = ({ scenario, snapshots, actions }) => {
  return (
    <Box>
      <Typography variant="inputLabel" color="secondary">
        <Text>interface.general.label</Text>
      </Typography>
      <Typography>{scenario.label}</Typography>
      <Typography variant="inputLabel" color="secondary">
        <Text>interface.general.tags</Text>
      </Typography>
      {scenario.tags ? Formatters.formatTags(scenario.tags) : "-"}
      <Typography variant="inputLabel" color="secondary">
        <Text>interface.general.note</Text>
      </Typography>
      <Typography sx={{ whiteSpace: "pre-line" }}>{scenario.note}</Typography>
      <Divider />
      <Box mt={2}>
        <Stack maxHeight={300} overflow={"auto"}>
          <Typography variant="inputLabel" color="secondary">
            <Text fallback={"Snapshots"}>interface.general.snapshots</Text>
          </Typography>
          {snapshots.map((ss) => {
            return (
              <Stack key={ss.id} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <Typography fontSize={10}>{Formatters.toLocalDateTimeString(ss.created)}</Typography>
                <Box>
                  <Typography
                    fontSize={10}
                    color="error"
                    sx={{
                      ":hover": {
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => {
                      actions.onDeleteSceanrioSnapshot(ss.id);
                    }}
                  >
                    <Text>interface.actions.delete</Text>
                  </Typography>
                </Box>
              </Stack>
            );
          })}
        </Stack>
      </Box>
    </Box>
  );
};

export default ScenarioDetails;
