import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import { Box, FormControl, Grid, IconButton, InputLabel, MenuItem, Popover, Select, Stack, Switch, TextField, Tooltip, Typography, colors } from "@mui/material";
import { theme } from "App";
import { MenuIcon } from "components/Icons/MaterialIcons";
import Text, { getText } from "components/text/Text";
import _ from "lodash";
import React, { useState } from "react";
import { AppSelectors } from "redux/AppReducers";
import palette from "themes/palette";

export const FieldTypes = {
  pond: "pond",
  farm: "farm",
};

/**
 * A Sidebar style field selector for Data Explorer
 */
const DataExplorerSideBar = ({ fields, selectedField, onSelectField, defaultFieldType, referenceFields, setReferenceFields, referenceFieldsMode, setReferenceFieldsMode, isMultiMode }) => {
  const cycleStore = AppSelectors.cycleStore();
  const selectedCycle = cycleStore.selectedCycle;

  const [anchorEl, setAnchorEl] = useState(null);
  const [fieldType, setFieldType] = useState(defaultFieldType || FieldTypes.pond);
  const [query, setQuery] = useState(null);
  const [filterGroup, setFilterGroup] = useState(null);

  const groupedFields = _.groupBy(fields, "field_type");
  const filteredFields = groupedFields[fieldType];
  const availableFieldGroups = _.unionBy(filteredFields, "field_group")
    .map((e) => e.field_group)
    .sort();

  const queryResult = (q, w) => {
    if (!q) return true;
    if (!w) return false;
    if (q && _.includes(w.toLowerCase(), q.toLowerCase())) return true;
    return false;
  };

  const Actions = {
    onSelectFieldType: (t) => {
      setFieldType(t);
      setFilterGroup(null);
    },
    onSelectFilterGroup: (g) => {
      setFilterGroup(g);
    },
    onChangeReferenceFields: (newFieldId) => {
      if (_.includes(referenceFields, newFieldId)) {
        setReferenceFields(referenceFields.filter((f) => f !== newFieldId));
      } else {
        setReferenceFields([...referenceFields, newFieldId]);
      }
    },
    handleMenuClick: (event) => {
      setAnchorEl(event.currentTarget);
    },
    handleMenuClose: () => {
      setAnchorEl(null);
    },
  };

  const menuOpen = Boolean(anchorEl);

  const menuId = open ? "menu" : undefined;

  return (
    <Box width="100%" height="100%">
      <Grid container height="100%">
        <Grid item height={"70px"} width={"100%"}>
          <Stack p={1} pt={2} justifyContent={"space-between"} alignItems="center" direction={"row"} spacing={1}>
            <TextField
              label={
                <span>
                  <i className="fa fa-search"></i> <Text>interface.actions.search</Text>
                </span>
              }
              fullWidth
              value={query}
              onChange={(e) => (e.target.value ? setQuery(e.target.value) : setQuery(null))}
              size="small"
            />
            <IconButton size="small" onClick={Actions.handleMenuClick}>
              <MenuIcon />
            </IconButton>

            <Popover
              id={menuId}
              open={menuOpen}
              anchorEl={anchorEl}
              onClose={Actions.handleMenuClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <Box width={"250px"} borderRadius={2} p={2}>
                <Stack spacing={1}>
                  <Typography fontSize={13} fontWeight={800} gutterBottom>
                    <Text>interface.actions.filters</Text>
                  </Typography>
                  <FormControl size="small" fullWidth>
                    <InputLabel id="group-select-label">
                      <Text>interface.general.group</Text>
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="group-select-label"
                      label={<Text>interface.general.group</Text>}
                      onChange={(e) => {
                        Actions.onSelectFilterGroup(e.target.value);
                      }}
                      value={filterGroup}
                    >
                      <MenuItem value={null}>ALL</MenuItem>
                      {availableFieldGroups.map((e, idx) => {
                        return (
                          <MenuItem key={idx} value={e}>
                            {getText(`fields.group.${e}`)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  {!isMultiMode && !_.isEmpty(selectedCycle) && (
                    <>
                      <Typography fontSize={13} fontWeight={800} gutterBottom>
                        Reference Data (beta)
                      </Typography>
                      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography>Add Reference Data</Typography>
                        <Switch size="small" color="primary" checked={referenceFieldsMode} onChange={(e) => setReferenceFieldsMode(e.target.checked)}></Switch>
                      </Stack>

                      {referenceFieldsMode && (
                        <Box>
                          {_.unionBy(filteredFields, "field_group")
                            .map((e) => e.field_group)
                            .sort()
                            .map((g, idx) => {
                              const groupedFields = filteredFields.filter((ele) => ele.field_group === g);
                              return (
                                <Box key={"desb-group-" + idx}>
                                  <Box
                                    px={2}
                                    py={0.5}
                                    sx={{
                                      background: theme.palette.primary.light,
                                      color: theme.palette.primary.main,
                                      borderBottomColor: theme.palette.primary.main,
                                      borderWidth: "1px",
                                      borderBottomStyle: "solid",
                                    }}
                                  >
                                    <Typography textTransform="uppercase" fontWeight="800" letterSpacing={"1px"} fontSize={12}>
                                      <Text>{`fields.group.${g}`}</Text>
                                    </Typography>
                                  </Box>
                                  {[
                                    null,
                                    ..._.unionBy(groupedFields, "field_sub_group")
                                      .map((e) => e.field_sub_group)
                                      .filter((e) => e)
                                      .sort(),
                                  ].map((subG, subIdx) => {
                                    if (groupedFields.filter((e) => e.field_sub_group === subG).filter((e) => (!query ? true : queryResult(query, e.field_name))).length < 1) return "";
                                    return (
                                      <Box px={1} key={`${idx}-${subIdx}`}>
                                        <Box my={1}>
                                          <Box px={1} my={1}>
                                            <Typography textTransform="uppercase" color={"secondary"} fontWeight="bold" letterSpacing={"1px"} fontSize={10}>
                                              {subG}
                                            </Typography>
                                          </Box>
                                          {_.orderBy(groupedFields, ["position", "field_name"])
                                            .filter((ele) => ele.field_sub_group === subG)
                                            .map((f, idx_) => {
                                              const isFieldIncluded = _.includes(referenceFields, f.field_id);
                                              return (
                                                <Tooltip
                                                  arrow
                                                  key={idx_}
                                                  title={
                                                    <Box>
                                                      <Box>{f.is_derived ? "*" + getText("interface.general.derived-field") : ""}</Box>
                                                      <Typography variant="h6">{getText(`fields.${f.field_id}`)}</Typography>
                                                      <Typography variant="body2">
                                                        {getText(`fields.group.${g}`)} {subG && `/ ${getText(`fields.subgroup.${subG}`)}`}
                                                      </Typography>
                                                      <Typography variant="body2">
                                                        {getText(`interface.general.unit`)}: {f.field_unit}
                                                      </Typography>
                                                      <Typography variant="body2">{f.description || "-"}</Typography>
                                                      <Typography variant="body2"># {f.field_id}</Typography>
                                                    </Box>
                                                  }
                                                  placement="right-start"
                                                >
                                                  <Box onClick={() => Actions.onChangeReferenceFields(f.field_id)}>
                                                    <Box
                                                      px={1}
                                                      py={0.5}
                                                      my={0.5}
                                                      sx={{
                                                        borderRadius: "4px",
                                                        "&:hover": {
                                                          backgroundColor: !f.is_derived ? theme.palette.primary.light : theme.palette.secondary.light,
                                                          color: !f.is_derived ? theme.palette.primary.main : theme.palette.secondary.main,
                                                          cursor: "pointer",
                                                        },
                                                        "&:active": {
                                                          transform: "translateY(1px)",
                                                        },
                                                        ...(isFieldIncluded && {
                                                          fontWeight: 600,
                                                          backgroundColor: !f.is_derived ? theme.palette.primary.light : theme.palette.secondary.light,
                                                          color: !f.is_derived ? theme.palette.primary.main : theme.palette.secondary.main,
                                                        }),
                                                      }}
                                                    >
                                                      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                                        <Typography
                                                          color={isFieldIncluded ? (!f.is_derived ? theme.palette.primary.main : theme.palette.secondary.main) : colors.grey[600]}
                                                          fontWeight={600}
                                                        >
                                                          {f.is_derived && "*"}
                                                          <Text>{`fields.${f.field_id}`}</Text>
                                                        </Typography>
                                                        {isFieldIncluded && <CheckCircleOutline />}
                                                      </Stack>
                                                    </Box>
                                                  </Box>
                                                </Tooltip>
                                              );
                                            })}
                                        </Box>
                                      </Box>
                                    );
                                  })}
                                </Box>
                              );
                            })}
                        </Box>
                      )}
                    </>
                  )}
                </Stack>
              </Box>
            </Popover>
          </Stack>
        </Grid>

        <Grid
          item
          xs={12}
          height="calc(100% - 70px)"
          sx={{
            overflow: "auto",
          }}
        >
          {_.unionBy(filteredFields, "field_group")
            .map((e) => e.field_group)
            .filter((g) => (filterGroup ? g === filterGroup : true))
            .filter((g) => g)
            .sort()
            .map((g, idx) => {
              const groupedFields = filteredFields.filter((ele) => ele.field_group === g);
              return (
                <Box key={idx}>
                  <Box
                    px={2}
                    py={0.5}
                    sx={{
                      background: theme.palette.primary.light,
                      color: theme.palette.primary.main,
                      borderBottomColor: theme.palette.primary.main,
                      borderWidth: "1px",
                      borderBottomStyle: "solid",
                    }}
                  >
                    <Typography textTransform="uppercase" fontWeight="800" letterSpacing={"1px"} fontSize={12}>
                      {getText(`fields.group.${g}`)}
                    </Typography>
                  </Box>
                  {[
                    null,
                    ..._.unionBy(groupedFields, "field_sub_group")
                      .map((e) => e.field_sub_group)
                      .filter((e) => e)
                      .sort(),
                  ].map((subG, subIdx) => {
                    if (groupedFields.filter((e) => e.field_sub_group === subG).filter((e) => (!query ? true : queryResult(query, e.field_name))).length < 1) return "";
                    return (
                      <Box px={1}>
                        <Box my={1}>
                          <Box px={1} my={1}>
                            <Typography textTransform="uppercase" color={"secondary"} fontWeight="bold" letterSpacing={"1px"} fontSize={10}>
                              {subG && getText(`fields.subgroup.${subG}`)}
                            </Typography>
                          </Box>
                          {_.orderBy(groupedFields, ["position", "field_name"])
                            .filter((ele) => ele.field_sub_group === subG)
                            .filter((e) => (!query ? true : queryResult(query, e.field_name)))
                            .map((f, idx_) => {
                              return (
                                <Tooltip
                                  arrow
                                  key={idx_}
                                  title={
                                    <Box>
                                      <Stack direction={"row"} justifyContent={"space-between"}>
                                        <Typography variant="h6" fontWeight={800}>
                                          {getText(`fields.${f.field_id}`, f.field_name)}
                                        </Typography>
                                        <Box>{f.is_derived ? "*" + getText("interface.general.derived-field") : ""}</Box>
                                      </Stack>
                                      <Typography variant="body2">
                                        {getText(`interface.general.group`)}:{" "}
                                        <strong>
                                          {getText(`fields.group.${g}`, f.field_group)} {subG && `/ ${getText(`fields.subgroup.${subG}`, f.field_sub_group)}`}
                                        </strong>
                                      </Typography>
                                      <Typography variant="body2">
                                        {getText(`interface.general.unit`)}: <strong>{f.field_unit}</strong>
                                      </Typography>
                                      <Typography variant="body2">
                                        <br />
                                        {f.description || "-"}
                                        <br />
                                        <br />
                                      </Typography>
                                      <Typography fontSize={10} textAlign={"right"}>
                                        # {f.field_id}
                                      </Typography>
                                    </Box>
                                  }
                                  placement="right-start"
                                  componentsProps={{
                                    arrow: {
                                      sx: {
                                        color: colors.grey[200],
                                        // color: f.is_derived ? palette.secondary.light : palette.primary.light,
                                      },
                                    },
                                    tooltip: {
                                      sx: {
                                        p: 1,
                                        px: 2,
                                        width: 250,
                                        borderRadius: 1,
                                        bgcolor: colors.grey[200],
                                        // bgcolor: f.is_derived ? palette.secondary.light : palette.primary.light,
                                        color: f.is_derived ? palette.secondary.main : palette.primary.main,
                                      },
                                    },
                                  }}
                                >
                                  <Box onClick={() => onSelectField(f)}>
                                    <Box
                                      px={1}
                                      py={0.5}
                                      sx={{
                                        borderRadius: "4px",
                                        "&:hover": {
                                          backgroundColor: !f.is_derived ? theme.palette.primary.light : theme.palette.secondary.light,
                                          color: !f.is_derived ? theme.palette.primary.main : theme.palette.secondary.main,
                                          cursor: "pointer",
                                        },
                                        "&:active": {
                                          transform: "translateY(1px)",
                                        },
                                        ...(selectedField.field_id === f.field_id && {
                                          fontWeight: 600,
                                          backgroundColor: !f.is_derived ? theme.palette.primary.light : theme.palette.secondary.light,
                                          color: !f.is_derived ? theme.palette.primary.main : theme.palette.secondary.main,
                                        }),
                                      }}
                                    >
                                      <Typography
                                        color={selectedField.field_id === f.field_id ? (!f.is_derived ? theme.palette.primary.main : theme.palette.secondary.main) : colors.grey[600]}
                                        fontWeight={600}
                                      >
                                        {f.is_derived && "*"}
                                        <Text fallback={f.field_name}>{`fields.${f.field_id}`}</Text>
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Tooltip>
                              );
                            })}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              );
            })}
        </Grid>
      </Grid>
    </Box>
  );
};

export default DataExplorerSideBar;
