import { Box, Stack, TextField, Typography } from "@mui/material";
import { theme } from "App";
import PrimaryButton from "components/buttons/PrimaryButton";
import { ShrimplLogoSvg } from "components/Images";
import Text from "components/text/Text";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { userActions } from "redux/user";
import LoginPageLayout from "screens/Login/styles/LoginPageLayout";

const ForgotPassword = () => {
  const [requested, setRequested] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const run = async () => {};
    run();
  }, []);

  const Actions = {
    sendPasswordResetLink: (email) => {
      userActions.forgotPassword(dispatch, { email });
      setRequested(true);
    },
  };

  return (
    <LoginPageLayout
      title={<Text>interface.account.forget-your-password</Text>}
    >
      {requested ? (
        <Box my={2}>
          <Typography>
            If you have registered with this email, check your inbox for a
            password reset email.
          </Typography>
        </Box>
      ) : (
        <Box mt={2}>
          <Formik
            initialValues={{ email: "" }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                values.email && Actions.sendPasswordResetLink(values.email);
              }, 400);
            }}
          >
            {({ isSubmitting, handleChange }) => (
              <Form>
                <Stack width={"100%"} spacing={4}>
                  <TextField
                    type="email"
                    name="email"
                    onChange={handleChange}
                    label={<Text>interface.account.email</Text>}
                    variant="standard"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  ></TextField>
                  <PrimaryButton type="submit">
                    <Text>interface.account.send-me-the-reset-link</Text>
                  </PrimaryButton>
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      )}
    </LoginPageLayout>
  );
};

export default ForgotPassword;
