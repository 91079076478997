const FarmDocumentTypes = {
  COMPANYREG: {
    id: "COMPANYREG",
    name: "Registration",
  },
  FINANCIAL: {
    id: "FINANCIAL",
    name: "Financial",
  },
  CERTIFICATE: {
    id: "CERTIFICATE",
    name: "Certificates",
  },
  OTHER: {
    id: "OTHER",
    name: "Other",
  },
};

export default FarmDocumentTypes;
