import { colors, Grid, Typography, Button, Stack, Switch, FormGroup, FormControlLabel, Chip, Box, Container, FormControl, MenuItem, InputLabel, Select } from "@mui/material";
import { useEffect, useState } from "react";
import * as turf from "@turf/turf";
import {
  DashboardIcon,
  InsertChartIcon,
  SelectAllIcon,
  DeselectIcon,
  HelpOutlineIcon,
  UpdateIcon,
  NewspaperIcon,
  ViewTimelineIcon,
  ArrowForwardIcon,
  StorageIcon,
  MultilineChartIcon,
  BusinessIcon,
  SatelliteAltIcon,
  BuildCircleIcon,
  FolderIcon,
} from "components/Icons/MaterialIcons";
import { RiLayout2Fill, RiLayoutLeftFill, RiLayoutRightFill } from "react-icons/ri";

import _ from "lodash";
import Formatters from "helpers/Formatters";

import { AppSelectors } from "redux/AppReducers";
import { useDispatch } from "react-redux";
import { cycleManagementActions } from "redux/pondManagement/cycleManagement";
import PondCycleManager from "screens/Aquaculture/components/CycleManager/PondCycleManager";
import MultiPondsCycleManager from "screens/Aquaculture/components/CycleManager/MultiPondsCycleManager";
import MuiDatePicker from "components/DatePicker/MuiDatePicker";
import moment from "moment";
import MuiTabs, { MuiTabsThemes } from "ui/tabs/MuiTabs";
import { useParams } from "react-router-dom";
import ReportHome from "screens/Aquaculture/components/Report/ReportHome";
import DataManagerHome from "screens/Aquaculture/components/DataManager/DataManagerHome";
import ForecastHome from "screens/Aquaculture/components/Forecast/ForecastHome";
import DataExplorer from "./DataExplorer/DataExplorer";
import { FieldTypes } from "screens/Aquaculture/components/DataExplorer/DataExplorerSideBar";
import Text, { getText } from "components/text/Text";
import DashboardHome from "screens/Aquaculture/components/Dashboard/DashboardHome";
import PondManagerMap from "screens/Aquaculture/components/PondManagerMap";
import MuiToggleButtonGroup from "components/buttons/MuiToggleButtonGroup";
import { pondManagementActions } from "redux/pondManagement/pondManagement";
import OrganisationHome from "screens/Aquaculture/components/Organisation/OrganisationHome";

import ScenarioBuilderHome from "screens/Aquaculture/components/ScenarioBuilder/ScenarioBuilderHome";
import FilesHome from "screens/Aquaculture/components/Files/FilesHome";
import SatEyeHome from "screens/Aquaculture/components/SatEye/SatEyeHome";
import { getCollabAccessDetails } from "constants/FarmAccessLevels";
import AccessWrapper from "components/Wrapper/AccessWrapper";
import PlatformAccessPolices from "constants/PlatformAccessPolicies";
import PondManagerAccessWrapper from "components/Wrapper/PondManagerAccessWrapper";
import { ResourcePolicies } from "screens/Aquaculture/components/AccessControl/ResourcePolicies";

export const PondManagerLayoutStyles = {
  leftPanel: {
    width: "100%",
    height: "calc(100vh - 161px)",
  },
  rightPanel: {
    position: "relative",
    backgroundColor: "#fff",
    height: "calc(100vh - 161px)",
    p: 2,
    boxSizing: "border-box",
    overflow: "auto",
  },
  rightPanelInner: {
    height: "calc(100vh - 161px)",
    overflow: "auto",
    p: 2,
    boxSizing: "border-box",
    borderRight: "1px solid #ccc",
  },
};

const subviewModes = {
  DASHBOARDVIEW: {
    value: 1,
    path: "dashboard",
  },
  DATAVIEW: {
    value: 2,
    path: "data-explorer",
  },
  FORECAST: {
    value: 3,
    path: "forecast",
  },
  CYCLES: {
    value: 4,
    path: "cycles",
  },
  DATAMANAGER: {
    value: 5,
    path: "data-manager",
  },
  REPORT: {
    value: 6,
    path: "report",
  },
  ORGANISATION: {
    value: "ORGANISATION",
    path: "org",
  },
  SCENARIOBUILDER: {
    value: "SCENARIOBUILDER",
    path: "scenario-builder",
  },
  CHAT: {
    value: "CHAT",
    path: "chat",
  },
  FILES: {
    value: "FILES",
    path: "files",
  },
  LAB: {
    value: "LAB",
    path: "lab",
  },
  SATEYE: {
    value: "SATEYE",
    path: "sateye",
  },
};

const ViewLayouts = {
  LEFT: "LEFT",
  RIGHT: "RIGHT",
  COMBINED: "COMBINED",
};

const ViewLayoutGridConfigs = {
  [ViewLayouts.COMBINED]: {
    left: {
      width: {
        xs: "200px",
        lg: "400px",
        xl: "500px",
      },
    },
    right: {
      width: {
        xs: "calc(100% - 200px)",
        lg: "calc(100% - 400px)",
        xl: "calc(100% - 500px)",
      },
    },
  },
  [ViewLayouts.LEFT]: {
    left: { xs: 12 },
    right: { sx: { display: "none" } },
  },
  [ViewLayouts.RIGHT]: {
    left: { sx: { display: "none" } },
    right: { xs: 12 },
  },
};

const PondManagerMapView = ({ fields, pondsGeoJson, ponds, handleEditAction, handleDeleteAction, handleCloneAction, handleSelectGroupAction, selectedFarm, selectedGroup, getPonds, style }) => {
  const params = useParams();
  const orgid = params["orgid"];

  const contentDisplayConfigs = {
    tabs: orgid
      ? [
          {
            label: "Organisation",
            path: "org",
            value: subviewModes.ORGANISATION.value,
            icon: <BusinessIcon />,
          },
        ]
      : [
          {
            label: <Text>interface.general.dashboard</Text>,
            path: "dashboard",
            value: subviewModes.DASHBOARDVIEW.value,
            icon: <DashboardIcon />,
          },
          {
            label: <Text>interface.general.cycles</Text>,
            path: "cycles",
            value: subviewModes.CYCLES.value,
            icon: <ViewTimelineIcon />,
          },
          {
            label: <Text>interface.general.data-explorer</Text>,
            path: "data-explorer",
            value: subviewModes.DATAVIEW.value,
            icon: <InsertChartIcon />,
          },
          {
            label: <Text>interface.general.data-manager</Text>,
            path: "data-manager",
            value: subviewModes.DATAMANAGER.value,
            icon: <StorageIcon />,
          },
          {
            label: <Text>interface.general.scenario-builder</Text>,
            path: "scenario-builder",
            value: subviewModes.SCENARIOBUILDER.value,
            icon: <BuildCircleIcon />,
          },
          {
            label: <Text>interface.general.forecast</Text>,
            path: "forecast",
            value: subviewModes.FORECAST.value,
            icon: <MultilineChartIcon />,
          },
          {
            label: <Text>interface.general.report</Text>,
            path: "report",
            value: subviewModes.REPORT.value,
            icon: <NewspaperIcon />,
          },
          {
            label: <Text>interface.general.files</Text>,
            path: subviewModes.FILES.path,
            value: subviewModes.FILES.value,
            icon: <FolderIcon />,
          },
          {
            label: "SATEYE",
            path: subviewModes.SATEYE.path,
            value: subviewModes.SATEYE.value,
            icon: <SatelliteAltIcon />,
          },
          // {
          //   label: "Chat",
          //   path: "chat",
          //   value: subviewModes.CHAT.value,
          //   icon: <QuestionAnswerIcon />,
          // },
        ],
  };

  const urlParams = useParams();

  /** redux store */
  const dispatch = useDispatch();

  const pondManagementStore = AppSelectors.pondManagementStore();
  const cycleStore = AppSelectors.cycleStore();
  const allPondCycles = cycleStore.cycles;
  const selectedCycle = cycleStore.selectedCycle;
  const selectedDate = cycleStore.selectedDate;
  const farms = pondManagementStore.farms;

  if (!pondsGeoJson || pondsGeoJson.features.length === 0) {
    return (
      <Container>
        <Stack alignItems={"center"} p={4}>
          <HelpOutlineIcon sx={{ fontSize: "10rem", color: colors.grey[300] }} />
          <Typography sx={{ fontSize: "3rem", color: colors.grey[300] }}>There's no pond in this farm</Typography>
        </Stack>
      </Container>
    );
  }

  // insert some extra info
  // - generate ID
  // - is active
  pondsGeoJson = {
    ...pondsGeoJson,
    features: pondsGeoJson.features.map((ele, idx) => ({
      ...ele,
      id: Formatters.uuidToNumber(ele.properties.id),
      properties: {
        ...ele.properties,
        isActive: _.maxBy(allPondCycles[ele.properties.id], "start_date")?.ended === false,
      },
    })),
  };

  // --+-- local states --+--
  const [selectedPond, setPond] = useState(null);
  const [selectedPonds, setPonds] = useState([]);
  const [multiMode, setMultiMode] = useState(false);
  const [viewLayout, setViewLayout] = useState(ViewLayouts.COMBINED);
  const [livecenter, setCenter] = useState(turf.centroid(pondsGeoJson).geometry.coordinates);
  const [subviewMode, setSubviewMode] = useState(
    (urlParams.page && _.find(_.values(subviewModes), { path: urlParams.page })?.value) || (orgid ? subviewModes.ORGANISATION.value : subviewModes.DASHBOARDVIEW.value)
  );
  const [defaultField, setDefaultField] = useState(null);
  const [alertPondIds, setAlertPondIds] = useState([]);
  // const [showSpatialDataMap, setShowSpatialDataMap] = useState(false);

  const cycles = _.orderBy(allPondCycles[selectedPond?.id] || [], "start_date", "desc");

  const onHandleSelectPondAction = (pond) => {
    setPond(pond);
  };

  useEffect(() => {
    setSubviewMode((urlParams.page && _.find(_.values(subviewModes), { path: urlParams.page })?.value) || (orgid ? subviewModes.ORGANISATION.value : subviewModes.DASHBOARDVIEW.value));
  }, [urlParams.page]);

  useEffect(() => {
    if (selectedCycle.id) {
      if (cycles.length < 1) {
        cycleManagementActions.onChangeCycle(dispatch, {});
      } else {
        const latestCycle = _.first(cycles);
        const closestCycle = _.minBy(cycles, (o) => Math.abs(moment.duration(moment(selectedCycle.start_date).diff(moment(o.start_date))).asDays()));
        cycleManagementActions.onChangeCycle(dispatch, closestCycle);
      }
    } else {
      // alway set cycle to null if new pond is selected
      cycleManagementActions.onChangeCycle(dispatch, {});
      // // alway set cycle to latest cycle when a new pond is selected
      // cycleManagementActions.onChangeToCurrentCycle(dispatch, selectedPond?.id);
    }
  }, [selectedPond]); // trigger when pond is changed

  useEffect(() => {
    setPond(null);
  }, [selectedGroup]);

  useEffect(() => {
    pondManagementActions.loadAllPonds(dispatch, selectedFarm.farm_id);
  }, [selectedFarm]);

  const onHandleSelectMultiPonds = (pond) => {
    setPonds((prevSelectedPonds) => {
      if (
        _.includes(
          prevSelectedPonds.map((p) => p.id),
          pond.id
        )
      ) {
        return prevSelectedPonds.filter((p) => p.id !== pond.id);
      } else {
        return [...prevSelectedPonds, pond];
      }
    });
  };

  const onHandleSelectedAllPondsInSelectedGroup = () => {
    if (selectedPonds.length !== ponds.length) {
      let pondsData = ponds.map((pond) => ({
        area: pond.area,
        category: pond.category,
        country: pond.country,
        country_code: pond.country_code,
        farm: pond.farm,
        group: pond.group,
        id: pond.id,
        lat: pond.lat,
        lng: pond.lng,
        name: pond.label,
        place: pond.place,
        postcode: pond.postcode,
        region: pond.region,
        sub_category: pond.sub_category,
      }));
      setPonds(() => [...pondsData]);
    } else {
      setPonds(() => []);
    }
  };

  const Actions = {
    /** enable sub component to switch to data explorer view */
    onSwitchToDataViewField: (field, pond) => {
      field && setDefaultField(field);
      pond && onHandleSelectPondAction(pond);
      setSubviewMode(subviewModes.DATAVIEW.value);
    },
    /** switch between single-pond mode and multi-pon mode */
    onSwitchMultiMode: () => {
      if (multiMode) {
        // forget pond selections as temp solution to address mapbox update issue
        setPonds([]);
        setMultiMode(false);
      } else {
        setMultiMode(true);
        if (subviewMode === subviewModes.DASHBOARDVIEW.value) {
          setSubviewMode(subviewModes.DATAVIEW.value);
        }
      }
    },
    /** unselect pond */
    unselectPond: () => {
      setPond(null);
    },
    toggleAlertPonds: ({ pondIds, isCancel }) => {
      isCancel ? setAlertPondIds([]) : setAlertPondIds(pondIds);
    },
  };

  const collabAccessDetails = getCollabAccessDetails(selectedFarm?.access_level_id);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={2} py={1} px={1}>
              <MuiToggleButtonGroup
                onChange={setViewLayout}
                value={viewLayout}
                options={[
                  {
                    label: "Map Only",
                    value: ViewLayouts.LEFT,
                    icon: <RiLayoutRightFill />,
                  },
                  {
                    label: "Combined",
                    value: ViewLayouts.COMBINED,
                    icon: <RiLayout2Fill />,
                  },
                  {
                    label: "Chart Only",
                    value: ViewLayouts.RIGHT,
                    icon: <RiLayoutLeftFill />,
                  },
                ]}
              />

              {/* CONTROL :: Spatial Data Map Switch */}

              {/* <IconButton
                size="small"
                onClick={() => {
                  setShowSpatialDataMap(!showSpatialDataMap);
                }}
                sx={{
                  background: showSpatialDataMap && palette.purple.main,
                  color: showSpatialDataMap && "#FFF",
                  "&:hover": {
                    color: palette.purple.main,
                    background: palette.purple.light,
                  },
                }}
              >
                <SatelliteAltIcon />
              </IconButton> */}

              {/* CONTROL :: Multi Pond Mode */}
              <FormGroup sx={{ transform: "translateY(-4px)", p: 0 }}>
                <FormControlLabel
                  control={<Switch size="small" checked={multiMode} color="info" />}
                  label={
                    <Typography fontSize={11} fontWeight={"bold"}>
                      <Text>interface.general.multi-pond</Text>
                    </Typography>
                  }
                  onChange={Actions.onSwitchMultiMode}
                  labelPlacement="top"
                  sx={{ m: 0 }}
                />
              </FormGroup>

              {/* CONTROL :: only when MultiMode + chosen group + chosen farm */}
              {multiMode && selectedFarm && (
                <Button
                  variant="contained"
                  onClick={onHandleSelectedAllPondsInSelectedGroup}
                  size="small"
                  color="info"
                  startIcon={selectedPonds.length !== ponds.length ? <SelectAllIcon /> : <DeselectIcon />}
                >
                  {selectedPonds.length !== ponds.length ? getText("interface.actions.select-all") : getText("interface.actions.cancel")}
                </Button>
              )}

              {!multiMode && (
                <FormGroup sx={{ transform: "translateY(-4px)" }}>
                  <FormControlLabel
                    sx={{ m: 0 }}
                    control={
                      <Switch
                        size="small"
                        checked={selectedPond}
                        onClick={() => {
                          selectedPond && Actions.unselectPond();
                        }}
                      />
                    }
                    label={
                      <Typography fontSize={11} fontWeight={"bold"}>
                        <Text>interface.general.farm-or-pond</Text>
                      </Typography>
                    }
                    labelPlacement="top"
                  />
                </FormGroup>
              )}
              <Box>
                {(!selectedPond || multiMode) && (
                  <Stack direction={"row"} spacing={1}>
                    <MuiDatePicker
                      value={moment(selectedDate, "YYYY-MM-DD")}
                      onAccept={(newValue) => {
                        cycleManagementActions.selectDate(dispatch, newValue ? newValue.format("YYYY-MM-DD") : null);
                      }}
                      label="Pivot Date"
                      size="small"
                      width={"150px"}
                    />
                  </Stack>
                )}
              </Box>
              <Box>
                {!multiMode &&
                  selectedPond &&
                  (cycles.length > 0 ? (
                    <FormControl key={selectedPond?.id}>
                      <InputLabel id="cycle-select-label">
                        <Text>interface.general.cycles</Text>
                      </InputLabel>
                      <Select
                        labelId="cycle-select-label"
                        id="cycle-select"
                        value={selectedCycle.id || "_all"}
                        label={<Text>interface.general.cycles</Text>}
                        size="small"
                        onChange={(e) => {
                          if (e.target.value === "_all") {
                            cycleManagementActions.onChangeCycle(dispatch, {});
                          } else {
                            cycleManagementActions.onChangeCycle(
                              dispatch,
                              _.find(cycles, (el) => el.id === e.target.value)
                            );
                          }
                        }}
                      >
                        <MenuItem value={"_all"}>
                          <Typography>
                            <Text>interface.actions.select-all</Text>
                          </Typography>
                        </MenuItem>
                        {cycles.map((ele, idx) => (
                          <MenuItem key={idx} value={ele.id} dense>
                            <Stack
                              direction="row"
                              alignItems={"center"}
                              spacing={1}
                              sx={{
                                bgcolor: !ele.ended && colors.green[50],
                                color: !ele.ended && colors.green[900],
                                fontWeight: !ele.ended && 800,
                                px: 0.4,
                                borderRadius: 1,
                              }}
                            >
                              <Typography fontSize={11}>
                                <strong>"{ele.label}"</strong>
                              </Typography>
                              <Typography fontSize={11}>{Formatters.toDateString(ele.start_date)}</Typography>
                              <Typography
                                sx={{
                                  svg: {
                                    fontSize: 10,
                                  },
                                }}
                              >
                                <ArrowForwardIcon />
                              </Typography>
                              <Typography fontSize={11}>{ele?.ended ? Formatters.toDateString(ele.end_date) : "ACTIVE"}</Typography>
                            </Stack>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <Chip icon={<UpdateIcon />} size="small" color={"error"} label={getText("interface.alert.no-cycle-is-set")} />
                  ))}
              </Box>
            </Stack>
            {/* Top controlling buttons */}
            <Stack px={1} spacing={1} direction="row" alignItems="center">
              <MuiTabs theme={MuiTabsThemes.icon} options={contentDisplayConfigs.tabs} value={subviewMode} onNewValue={setSubviewMode} />
            </Stack>
          </Stack>
        </Grid>
        {/* 
        
        ~~~~~~~~~~~~~~~~~~~
        LEFT PANEL :: M A P 
        ~~~~~~~~~~~~~~~~~~~
        
        */}
        <Grid item {...ViewLayoutGridConfigs[viewLayout].left}>
          <Box key={`map-key--${multiMode}`} sx={PondManagerLayoutStyles.leftPanel}>
            <PondManagerMap
              defaultCenter={[livecenter[1], livecenter[0]]}
              pondsGeoJson={pondsGeoJson}
              pondGroupColorMapping={style.colorMapping}
              states={{
                selectedFarm,
                selectedGroup,
                selectedPond,
                selectedPonds,
                ponds,
                farms,
                multiMode,
              }}
              actions={{
                onHandleSelectPondAction,
                onHandleSelectMultiPonds,
                unselectPond: Actions.unselectPond,
                handleEditAction,
                handleDeleteAction,
                handleCloneAction,
                handleSelectGroupAction,
                getPonds,
              }}
            />
          </Box>
        </Grid>
        {/* 
        
        ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        RIGHT PANEL :: V I S U A L I Z A T I O N S 
        ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        
        */}

        <Grid item {...ViewLayoutGridConfigs[viewLayout].right}>
          {/*  ---- DASHBOARD ---- */}
          {subviewMode === subviewModes.DASHBOARDVIEW.value && (
            <DashboardHome
              farm={selectedFarm}
              pond={selectedPond}
              ponds={ponds}
              group={selectedGroup}
              pondGroupColorMapping={style.colorMapping}
              actions={{
                onSwitchToDataViewField: Actions.onSwitchToDataViewField,
                toggleAlertPonds: Actions.toggleAlertPonds,
              }}
            />
          )}
          {/* NOTE: when selectedPond is not defined, it means there's no pond selected and we should show data at FARM level */}
          {/*  ---- Data Explorer ---- */}

          {subviewMode === subviewModes.DATAVIEW.value && (
            <>
              {!multiMode && !selectedPond && <DataExplorer farm={selectedFarm} defaultFieldType={FieldTypes.farm} />}

              {!multiMode && selectedPond && (
                <DataExplorer
                  farm={selectedFarm}
                  defaultField={defaultField}
                  onSwitchToDashboardView={() => setSubviewMode(subviewModes.DASHBOARDVIEW.value)}
                  pond={selectedPond}
                  isMultiMode={multiMode}
                />
              )}

              {multiMode && selectedPonds.length > 0 && (
                <DataExplorer
                  farm={selectedFarm}
                  fields={fields}
                  defaultField={defaultField}
                  onSwitchToDashboardView={() => setSubviewMode(subviewModes.DASHBOARDVIEW.value)}
                  ponds={selectedPonds}
                  isMultiMode={multiMode}
                />
              )}
            </>
          )}

          {subviewMode === subviewModes.DATAMANAGER.value && <DataManagerHome farm={selectedFarm} pond={selectedPond} ponds={ponds} />}

          {subviewMode === subviewModes.FORECAST.value && <ForecastHome farm={selectedFarm} pond={selectedPond} group={selectedGroup} />}

          {/* TODO: clean this up */}
          {subviewMode === subviewModes.CYCLES.value && (
            <PondManagerAccessWrapper policies={[ResourcePolicies["CYCLE:READ"], ResourcePolicies["CYCLE:WRITE"]]}>
              {selectedPond ? <PondCycleManager pond={selectedPond} farm={selectedFarm} /> : <MultiPondsCycleManager ponds={ponds} farm={selectedFarm} />}
            </PondManagerAccessWrapper>
          )}

          {subviewMode === subviewModes.REPORT.value && <ReportHome pond={selectedPond} farm={selectedFarm} />}

          {subviewMode === subviewModes.SCENARIOBUILDER.value && <ScenarioBuilderHome farm={selectedFarm} />}

          {subviewMode === subviewModes.FILES.value && <FilesHome pond={selectedPond} farm={selectedFarm} />}

          {subviewMode === subviewModes.SATEYE.value && <SatEyeHome pond={selectedPond} farm={selectedFarm} />}

          {subviewMode === subviewModes.ORGANISATION.value && (
            <OrganisationHome
              ponds={ponds}
              farm={selectedFarm}
              pond={selectedPond}
              actions={{
                onSwitchToDataViewField: Actions.onSwitchToDataViewField,
                toggleAlertPonds: Actions.toggleAlertPonds,
              }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default PondManagerMapView;
