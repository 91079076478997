import { Component } from "react";
import { api_water_monthly_report_data, api_water_monthly_report_index } from "api/api";
import RcSelect from "../../components/input/RcSelect";
import styled from "styled-components";
import { toUnique } from "../../helpers/Utils";
import { highchartBasicConfig } from "../../config";
import ReactHighcharts from "react-highcharts";
import ChartjsContainer from "components/chart/ChartjsV4Wrapper";
import _ from "lodash";

const MONTHLIST = "jan-feb-mar-apr-may-jun-jul-aug-sep-oct-nov-dec".split("-").map((ele) => ele.toUpperCase());

const Layout = styled.div`
  font-family: var(--main-font);

  .topbar {
    display: flex;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #ddd;
    background: white;
  }

  .topbar .title {
    font-family: var(--main-font);
    margin-right: 40px;
    color: #555;
  }

  .topbar > div {
    margin-right: 10px;
  }

  .main {
    padding: 30px;
  }

  .chartWrapper {
    height: 300px;
  }
`;

const THEMECOLOR = {
  allocation: "#1976d2",
  entitlement: "#1b5e20",
};

const generateHeatmapConfig = (data, datatype, color) => {
  const year_list = toUnique(data.map((ele) => ele.year)).sort();
  let hcData = data.map((ele) => [ele.month - 1, year_list.indexOf(ele.year), ele[datatype]]);
  let avg = [];
  for (let y of year_list) {
    avg.push([12, year_list.indexOf(y), _.mean(data.filter((ele) => ele.year === y).map((ele) => ele[datatype]))]);
  }
  hcData = [...hcData, ...avg];

  const xaxis_categories = [...MONTHLIST, "Average"];
  const yaxis_categories = year_list;

  return {
    chart: {
      ...highchartBasicConfig.chart,
      type: "heatmap",
      plotBorderWidth: 1,
      backgroundColor: "transparent",
    },
    lang: {
      thousandsSep: ",",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: xaxis_categories,
    },
    yAxis: {
      categories: yaxis_categories,
      title: null,
      reversed: true,
    },
    colorAxis: {
      min: 0,
      minColor: "#FFFFFF",
      maxColor: color,
    },
    credits: highchartBasicConfig.credits,
    tooltip: {
      formatter: function () {
        return xaxis_categories[this.point.x] + " " + yaxis_categories[this.point.y] + ":<b>" + this.point.value + "</b>";
      },
    },
    legend: {
      align: "right",
      layout: "vertical",
      margin: 0,
      verticalAlign: "top",
      y: 25,
      symbolHeight: 280,
    },
    series: [
      {
        name: datatype,
        borderWidth: 1,
        data: hcData,
        borderColor: "#ccc",
        dataLabels: {
          enabled: true,
          format: "{point.value:,.2f}",
          align: "right",
          padding: 10,
          style: {
            textOutline: "none",
          },
        },
      },
    ],
  };
};

const generateHistorical = (data, datatype, color, zone) => {
  return {
    datasets: [
      {
        label: zone,
        barPercentage: 0.5,
        barThickness: 6,
        maxBarThickness: 8,
        minBarLength: 2,
        backgroundColor: color,
        data: data.map((ele) => ({ x: ele.date, y: ele[datatype] })),
      },
    ],
  };
};

const generateSeasonal = (data, datatype, color) => {
  const year_list = toUnique(data.map((ele) => ele.year)).sort();
  let datasets = [];
  for (let year of year_list) {
    let data_of_year = data.filter((ele) => ele.year === year);
    let isLastYear = year_list[year_list.length - 1];
    datasets.push({
      type: year === isLastYear ? "line" : "scatter",
      label: year,
      fill: false,
      borderColor: year === isLastYear ? color : "#ccc",
      pointBorderWidth: isLastYear ? 3 : 1,
      data: data_of_year.map((ele) => ({ x: ele.month, y: ele[datatype] })),
      // pointRadius: (year_list.indexOf(year) + 1) * 0.1 + 1
    });
  }
  // console.log(datasets);
  datasets = datasets.reverse();
  return {
    labels: MONTHLIST,
    datasets: datasets,
  };
};

export default class WaterReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMarket: "entitlement",
      selectedState: "Victoria",
      selectedZone: "",
      selectedClass: "",
      selectedDataType: "net_price", // net_price or quantity_traded
      indextable: [],
      data: [],
      classes: [],
    };
  }

  componentDidMount() {
    this.getIndexData(this.state.selectedMarket);
  }

  getIndexData = async (type) => {
    const indextable = await api_water_monthly_report_index(type);
    this.setState({ indextable });
  };

  onChangeMarket = async (markettype) => {
    const indextable = await api_water_monthly_report_index(markettype);
    this.setState({
      indextable,
      selectedMarket: markettype,
      selectedZone: "",
    });
  };

  onSelectZone = async (zone) => {
    let data = await api_water_monthly_report_data(this.state.selectedMarket, zone);
    data = data.filter((ele) => ele.net_price > 0 && ele.quantity_traded > 0);
    data = data.map((ele) => ({
      ...ele,
      vwap: ele.net_price / ele.quantity_traded,
    }));
    let classes = [];
    let selectedClass = "";
    if (this.state.selectedMarket === "entitlement") {
      classes = toUnique(data.map((ele) => ele.reliability)).sort();
      selectedClass = classes[0];
    }
    this.setState({
      data: data,
      selectedZone: zone,
      classes,
      selectedClass,
    });
  };

  render() {
    const themeColor = THEMECOLOR[this.state.selectedMarket];
    let selectedZoneInfo = this.state.indextable.filter((ele) => ele.zone === this.state.selectedZone)[0] || {};

    // -- Make Select Options
    const marketOptions = [
      { label: "entitlement", value: "entitlement" },
      { label: "allocation", value: "allocation" },
    ];
    const stateOptions = toUnique(this.state.indextable.map((ele) => ele.state)).map((ele) => ({
      label: ele,
      value: ele,
    }));
    const zoneOptions = this.state.indextable
      .filter((ele) => ele.state === this.state.selectedState)
      .map((ele) => ({
        label: ele.zone,
        value: ele.zone,
      }));
    const classOptions = this.state.classes.map((ele) => ({
      label: ele,
      value: ele,
    }));
    const datatypeOptions = [
      { label: "Trade Value", value: "net_price" },
      { label: "Trade Volume", value: "quantity_traded" },
      { label: "Vwap Price", value: "vwap" },
    ];

    let data = this.state.data;
    if (this.state.selectedMarket === "entitlement") {
      data = data.filter((ele) => ele.reliability === this.state.selectedClass);
    }

    let statsInfo = "";
    if (data.length > 0) {
      let timeInfo = `${MONTHLIST[data[0].month - 1]} ${data[0].year} ~ ${MONTHLIST[data[data.length - 1].month - 1]}  ${data[data.length - 1].year}`;
      statsInfo = `${timeInfo} | ${selectedZoneInfo.drainage_division} / ${selectedZoneInfo.murray_darling_basin_region} | Last Updated: ${selectedZoneInfo.record_to}`;
    }

    // -- heatmap data
    const hmConfig = generateHeatmapConfig(data, this.state.selectedDataType, themeColor);
    const barDatasets = generateHistorical(data, this.state.selectedDataType, themeColor, this.state.selectedZone);
    const seasonalDatasets = generateSeasonal(data, this.state.selectedDataType, themeColor);

    return (
      <Layout>
        <div>
          <div className="topbar">
            <div className="title">Australian Water Market Report (beta)</div>
            <RcSelect
              label="market type"
              options={marketOptions}
              value={{
                label: this.state.selectedMarket,
                value: this.state.selectedMarket,
              }}
              onChange={(ele) => this.onChangeMarket(ele.value)}
              width="150px"
            />
            <RcSelect
              key={this.state.selectedMarket}
              label="state"
              options={stateOptions}
              value={{
                label: this.state.selectedState,
                value: this.state.selectedState,
              }}
              onChange={(ele) => this.setState({ selectedState: ele.value })}
              width="150px"
            />
            <RcSelect
              label="trading zone"
              key={this.state.selectedMarket + this.state.selectedState}
              options={zoneOptions}
              onChange={(ele) => this.onSelectZone(ele.value)}
              placeholder="Trading Zone..."
              width="300px"
            />
          </div>
        </div>
        {this.state.selectedZone !== "" ? (
          <div className="main">
            <div className="flexBox flexBox_between">
              <div>
                <h3>{this.state.selectedZone}</h3>
                <div style={{ fontSize: "1.2rem" }}>{statsInfo}</div>
              </div>
              <div className="RcSelectWrapper">
                <RcSelect
                  label="class"
                  options={classOptions}
                  onChange={(ele) => this.setState({ selectedClass: ele.value })}
                  width="100px"
                  value={{
                    label: this.state.selectedClass,
                    value: this.state.selectedClass,
                  }}
                />
                <RcSelect
                  label="data"
                  options={datatypeOptions}
                  onChange={(ele) => this.setState({ selectedDataType: ele.value })}
                  width="150px"
                  value={{
                    label: {
                      net_price: "Trade Value",
                      quantity_traded: "Trade Volume",
                      vwap: "Vwap Price",
                    }[this.state.selectedDataType],
                    value: this.state.selectedDataType,
                  }}
                />
              </div>
            </div>

            <div className="GridLayout GridLayout2">
              <div>
                <h3>Historical</h3>
                <div className="chartWrapper">
                  <ChartjsContainer
                    type="bar"
                    data={barDatasets}
                    height={200}
                    options={{
                      scales: {
                        xAxis: {
                          type: "time",
                        },

                        yAxis: {
                          ticks: {
                            callback: (value) => {
                              return value.toLocaleString("en-US", {});
                            },
                          },
                        },
                      },
                      maintainAspectRatio: false,
                    }}
                  />
                </div>
              </div>
              <div>
                <h3>Seasonal Trend</h3>
                <div style={{ height: "300px" }}>
                  <ChartjsContainer
                    type="line"
                    data={seasonalDatasets}
                    options={{
                      legend: { display: false },
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              callback: (value) => {
                                return value.toLocaleString("en-US", {});
                              },
                            },
                          },
                        ],
                      },
                      maintainAspectRatio: false,
                    }}
                  />
                </div>
              </div>
            </div>

            <h3>Seasonal Comparison</h3>
            <ReactHighcharts config={hmConfig} />
          </div>
        ) : (
          ""
        )}
      </Layout>
    );
  }
}
