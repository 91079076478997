import React from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";

/* Mui based Primary Button
 */
const PrimaryButton = (props) => {
  const { children, ...props_ } = props;
  return (
    <Button
      sx={{
        p: 2,
        fontSize: 18,
        letterSpacing: 2,
      }}
      variant="contained"
      {...props_}
    >
      {children}
    </Button>
  );
};

PrimaryButton.propTypes = {};

export default PrimaryButton;
