import { Box, Stack, TextField, Tooltip } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import CommonDialog, { CommonDialogIds } from "components/Dialogs/CommonDialog";
import { DeleteIcon, EditIcon, FileCopyIcon } from "components/Icons/MaterialIcons";
import { MuiBorderIconButton } from "components/buttons/MuiButtonVariants";
import { getText } from "components/text/Text";
import Formatters from "helpers/Formatters";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { dialogReducerActions } from "redux/dialog";

const ProductTable = ({ rows, actions }) => {
  const [productToDelete, setProductToDelete] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const dispatch = useDispatch();

  const ref = useRef();

  useEffect(() => {
    ref?.current?.api?.setGridOption("quickFilterText", searchTerm);
  }, [searchTerm]);

  const [colDef, setColDef] = useState([
    {
      headerName: "",
      cellRenderer: ({ data }) => (
        <Stack direction={"row"} spacing={0.3}>
          <MuiBorderIconButton size={"sm"} onClick={() => actions.onEditProduct({ product: data })} icon={<EditIcon />} />
          <MuiBorderIconButton size={"sm"} onClick={() => actions.onReplicateProduct({ product: data })} icon={<FileCopyIcon />} />
          <MuiBorderIconButton size={"sm"} onClick={() => Actions.onDeleteProductConfirm(data)} icon={<DeleteIcon />} />
        </Stack>
      ),
      pinned: "left",
      width: 100,
      cellStyle: { display: "flex", alignItems: "center", justifyContent: "center" },
    },
    {
      field: "name",
      headerName: "Name",
      pinned: "left",
      filter: "agTextColumnFilter",
      editable: true,
    },
    {
      field: "product_type",
      headerName: "Product Type",
      cellRenderer: Formatters.Special.formatFarmingProductType,
    },
    {
      field: "category",
      headerName: "Category",
      editable: true,
    },
    {
      field: "sub_category",
      headerName: "Sub Category",
      editable: true,
    },
    {
      field: "net_weight",
      headerName: "Net Weight/Volume",
      editable: true,
    },
    {
      field: "net_weight_unit",
      headerName: "Net Weight/Volume Unit",
      editable: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: [null, "g", "kg", "oz", "lb", "l", "ml"],
      },
    },
    {
      field: "image_url",
      headerName: "Image URL",
      editable: true,
      cellRenderer: ({ value }) =>
        value ? (
          <Tooltip title={<img src={value} width="100%" />} placement="right">
            <img src={value} height={"30"} />
          </Tooltip>
        ) : (
          ""
        ),
      editable: true,
    },
    {
      field: "created_at",
      headerName: "Time Created",
      cellRenderer: ({ value }) => Formatters.toLocalDateTimeString(value),
    },
    {
      field: "link",
      headerName: "Link",
      editable: true,
    },
    {
      field: "id",
      headerName: "ID",
    },
  ]);

  const Actions = {
    onCellEditingStopped: (props) => {
      if (!props.valueChanged) return;
      const editColumn = props.column.colId;
      const productAfterEdit = props.data;
      if (_.includes(["name", "category", "sub_category", "image_url", "link", "net_weight", "net_weight_unit"], editColumn)) {
        actions.onDirectEditProduct(productAfterEdit);
      }
    },
    onDeleteProductConfirm: (product) => {
      setProductToDelete(product);
      dialogReducerActions.openCommonDialog(dispatch, {
        id: CommonDialogIds.ADMIN_PRODUCT_DELETE_CONFIRM,
      });
    },
  };

  return (
    <Box
      className="ag-theme-excel"
      sx={{
        width: "100%",
        height: "calc(100vh - 260px)",
      }}
    >
      <CommonDialog
        id={CommonDialogIds.ADMIN_PRODUCT_DELETE_CONFIRM}
        content={getText("interface.alert.alert-are-you-sure")}
        action={() => {
          actions.onDeleteProduct(productToDelete);
        }}
      />
      <Box mb={1}>
        <TextField variant="standard" size="small" label="Quick Filter:" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}></TextField>
      </Box>
      <AgGridReact
        defaultColDef={{
          cellStyle: { display: "flex", alignItems: "center" },
          flex: 1,
        }}
        ref={ref}
        rowData={rows}
        columnDefs={colDef}
        rowHeight={35}
        onCellEditingStopped={Actions.onCellEditingStopped}
        animateRows={false}
        pagination={true}
        paginationPageSize={100}
        paginationPageSizeSelector={[10, 20, 50, 100]}
      />
    </Box>
  );
};

export default ProductTable;
