import { Box, Stack, colors } from "@mui/material";
import CalendarMonitorChart from "components/chart/CalendarMonitorChart";
import _ from "lodash";
import { DateTime } from "luxon";
import React from "react";

const DataCalendarMonitor = ({ data }) => {
  const dataCount = _.chain(data.map((ele) => ({ ...ele, date: ele.datetime.split(" ")[0] })))
    .groupBy("date")
    .mapValues((ele) => 1)
    .value();

  const startYear = Number.parseInt(_.maxBy(data, "datetime")?.datetime?.split("-")[0]) || DateTime.local().year;

  const configs = {
    1: { level: "Has Data", color: colors.green[600] },
    default: { level: "No Data", color: colors.grey[300] },
  };
  return (
    <Stack alignItems={"center"}>
      <CalendarMonitorChart data={dataCount} defaultStartYear={startYear} configs={configs} />
    </Stack>
  );
};

export default DataCalendarMonitor;
