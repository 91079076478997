import { Box, Stack, Typography, colors } from "@mui/material";
import { NotificationImportantIcon } from "components/Icons/MaterialIcons";
import { AlertLevels } from "screens/Aquaculture/components/Dashboard/utils/alertHelpers";

export const AlertBarLevels = {
  low: "low",
  mid: "mid",
  high: "high",
};

/**
 *
 * level= low, mid, high
 *
 */
const AlertBar = (alertDetails) => {
  const { alertColor, alertLevelText, alertLevel, isAlert } = alertDetails;

  return (
    <Box>
      <Stack direction={"row"} alignItems={"center"}>
        <Box width={"50px"}>
          <Typography textTransform={"uppercase"} variant="caption" fontWeight={"bold"} sx={{ color: alertColor }}>
            {isAlert && (
              <NotificationImportantIcon
                sx={{
                  fontSize: 14,
                }}
              />
            )}{" "}
            {alertLevelText}
          </Typography>
        </Box>
        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} spacing={"2px"}>
          <Box
            sx={{
              height: "8px",
              width: "30px",
              backgroundColor: alertLevel === AlertLevels.low ? alertColor : colors.grey[400],
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
            }}
          ></Box>
          <Box
            sx={{
              height: "8px",
              width: "30px",
              backgroundColor: alertLevel === AlertLevels.mid ? alertColor : colors.grey[400],
            }}
          ></Box>
          <Box
            sx={{
              height: "8px",
              width: "30px",
              backgroundColor: alertLevel === AlertLevels.high ? alertColor : colors.grey[400],
              borderTopRightRadius: 10,
              borderBottomRightRadius: 10,
            }}
          ></Box>
        </Stack>
      </Stack>
    </Box>
  );
};

export default AlertBar;
