import { Box, Stack, Typography } from "@mui/material";
import { PondManagerServices } from "api/pondManagerServices";
import { VerticalAlertBoxCollections } from "components/Alerts/AlertBox";
import CommonForm, { FormFieldTypes } from "components/Form/CommonForm";
import { SearchIcon } from "components/Icons/MaterialIcons";
import LoadingBox from "components/Loading/LoadingBox";
import Text, { getText } from "components/text/Text";
import FarmingProductTypes from "constants/FarmingProductTypes";
import _ from "lodash";
import { useState } from "react";
import ProductCard from "screens/Aquaculture/components/FarmingProduct/components/ProductCard";

const ProductFinder = ({ registeredProducts = [], actions }) => {
  const [query, setQuery] = useState("");
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const isProductRegistered = (product) => {
    return _.includes(
      registeredProducts.map((ele) => ele.id),
      product.id
    );
  };

  const formFields = [
    {
      name: "query",
      label: getText("interface.general.keyword"),
      type: FormFieldTypes.text,
      span: 2,
      options: {
        required: true,
      },
    },
    {
      name: "productType",
      label: getText("interface.general.product-type"),
      type: FormFieldTypes.select,
      span: 1,
      options: {
        required: true,
        options: [
          {
            label: "All",
            value: "__all__",
          },
          ..._.values(FarmingProductTypes),
        ],
      },
    },
  ];

  const Actions = {
    onSubmit: async (values) => {
      setLoading(true);
      const { query, productType } = values;
      const result = await PondManagerServices.findFarmingProducts({ query, productType: productType === "__all__" ? null : productType });
      setProducts(result.rows);
      setLoading(false);
    },
  };

  return (
    <Box>
      <Stack spacing={1}>
        <Box>
          <CommonForm
            initialValues={{
              query: query,
              productType: "__all__",
            }}
            fields={formFields}
            columns={3}
            onSubmit={Actions.onSubmit}
            buttonIcon={<SearchIcon />}
            buttonLabel={<Text>interface.actions.search</Text>}
          />
        </Box>
        {loading ? (
          <LoadingBox />
        ) : (
          <Stack
            spacing={1}
            sx={{
              pr: 2,
              maxHeight: 800,
              overflow: "auto",
            }}
          >
            <Typography fontSize={10} color="grey">
              Showing {products.length} results:
            </Typography>
            {products.length === 0 && <VerticalAlertBoxCollections.NothingShow />}
            {products.map((item, idx) => {
              return <ProductCard key={idx} product={item} onRegister={actions.onRegisterProduct} isRegistered={isProductRegistered(item)} />;
            })}
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export default ProductFinder;
