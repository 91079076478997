import { InfoOutlined } from "@mui/icons-material";
import { Box, colors, Stack, Typography } from "@mui/material";
import AlertBox from "components/Alerts/AlertBox";
import MuiToggleButtonGroup from "components/buttons/MuiToggleButtonGroup";
import HighchartsWrapper from "components/Highcharts/HighchartsWrapper";
import { BarChartIcon, HelpOutlineIcon, TableChartIcon, TimelineIcon } from "components/Icons/MaterialIcons";
import Text, { getText } from "components/text/Text";
import { ChartColorsRoundRobinGetter, ChartColorsRoundRobinLight } from "helpers/constants";
import TimeFormatters from "helpers/TimeFormatters";
import _ from "lodash";
import { DateTime } from "luxon";
import moment from "moment";
import { useState } from "react";
import NumberFormat from "react-number-format";
import DataCalendarMonitor from "screens/Aquaculture/components/DataExplorer/components/DataCalendarMonitor";
import PondReferenceDataCharts from "screens/Aquaculture/components/DataExplorer/components/PondReferenceDataCharts";
import TableChart from "screens/Aquaculture/components/DataExplorer/components/TableChart";
import { CHART_TYPES } from "screens/Aquaculture/components/DataExplorer/DataExplorer.jsx";
import styled from "styled-components";
import palette from "themes/palette";
import WidgetCard from "ui/Card/WidgetCard";

/**
 *
 * TODO: replace ChartJS with Highchart
 *
 *
 */

const Layout = styled.div`
  height: calc(100vh - 340px);
  width: 100%;
  position: relative;
`;

const INTERVALS = {
  intraday: "intraday",
  daily: "daily",
  weekly: "weekly",
};

const AGGFUNCS = {
  average: "Average",
  ampm: "Avg + AM/PM",
};

const CHART_HEIGHT = "500px";
const SINGLE_LINE_COLOR = palette.secondary.main;

/**
 *
 * Make Configs for Highcharts
 *

 * */
const makeChartConfig = ({ chartType, interval, data, field, cycle, cycles }, config) => {
  const { field_id, field_unit, field_name } = field;
  // 1. reprocess data
  let groupedData = data.filter((e) => e[field_id] !== null);
  if (interval === INTERVALS.daily) {
    groupedData = _.groupBy(groupedData, (e) => DateTime.fromFormat(e.datetime, "yyyy-MM-dd HH:mm:ss").startOf("day").toFormat("yyyy-MM-dd HH:mm:ss"));
    groupedData = _.keys(groupedData).map((g) => ({
      datetime: g,
      [field_id]: _.meanBy(groupedData[g], field_id),
      _n: groupedData[g].length,
    }));
  } else if (interval === INTERVALS.weekly) {
    groupedData = _.groupBy(groupedData, (e) => DateTime.fromFormat(e.datetime, "yyyy-MM-dd HH:mm:ss").startOf("week").toFormat("yyyy-MM-dd HH:mm:ss"));
    groupedData = _.keys(groupedData).map((g) => ({
      datetime: g,
      [field_id]: _.meanBy(groupedData[g], field_id),
      _n: groupedData[g].length,
    }));
  }

  if (chartType === CHART_TYPES.LINE) {
    // Multi Cycle Chart
    if (config?.showMultiCycle) {
      const grouped = cycles
        .map((c) => {
          let dataSegment = groupedData.filter((e) => e.datetime >= c.start_date && e.datetime <= c.end_date);
          dataSegment = _.orderBy(dataSegment, "datetime");
          dataSegment = dataSegment.map((e) => {
            if (interval === INTERVALS.weekly) {
              return [e.datetime, parseInt(moment(e.datetime).diff(moment(dataSegment[0].datetime), "weeks")), e[field_id]];
            }
            return [e.datetime, parseInt(moment(e.datetime).diff(moment(dataSegment[0].datetime), "days")), e[field_id]];
          });
          return {
            cycle: c.label,
            data: dataSegment,
          };
        })
        .filter((g) => g.data.length > 0);

      return {
        chart: {
          type: "line",
          height: CHART_HEIGHT,
          animation: {
            duration: 0,
          },
        },
        xAxis: {
          gridLineWidth: 1,
          type: "category",
          crosshair: true,
          title: {
            text: "Days",
          },
        },
        yAxis: {
          gridLineWidth: 1,
          title: {
            text: `${getText(`fields.${field_id}`, field_name)} (${field_unit})`,
          },
          crosshair: true,
        },
        plotOptions: {
          series: {
            animation: {
              duration: 0,
            },
          },
        },
        series: _.reverse(
          grouped.map((ele, idx) => ({
            type: idx === 0 ? "areaspline" : "line",
            name: ele.cycle,
            lineWidth: idx === 0 ? 3 : 1,
            color: idx > 0 ? ChartColorsRoundRobinGetter(ChartColorsRoundRobinLight, idx - 1) : SINGLE_LINE_COLOR,
            data: ele.data.map((item) => ({
              x: item[1],
              y: item[2],
            })),
            fillOpacity: 0.1,
            zIndex: idx,
          }))
        ),
      };
    }
    // Single Cycle Chart
    else {
      return {
        chart: {
          type: "line",
          height: CHART_HEIGHT,
          animation: {
            duration: 0,
          },
        },
        time: {
          timezone: "Australia/Melbourne",
        },
        xAxis: {
          gridLineWidth: 1,
          type: "datetime",
          crosshair: true,
        },
        yAxis: {
          gridLineWidth: 1,
          title: {
            text: `${getText(`fields.${field_id}`, field_name)} (${field_unit})`,
          },
          crosshair: true,
        },
        plotOptions: {
          series: {
            animation: {
              duration: 0,
            },
          },
        },
        series: [
          {
            name: getText(`fields.${field_id}`, field_name),
            color: SINGLE_LINE_COLOR,
            data: groupedData.map((ele) => ({
              x: TimeFormatters.convertUtcDatetimeStringToValue(ele.datetime),
              y: ele[field_id],
            })),
          },
        ],
      };
    }
  }

  if (chartType === CHART_TYPES.BAR) {
    // Multi Cycle Chart
    if (config?.showMultiCycle) {
      return null;
    }
    // Single Cycle Chart
    else {
      return {
        chart: {
          type: "column",
          height: CHART_HEIGHT,
          animation: {
            duration: 0,
          },
        },
        xAxis: {
          type: "datetime",
          crosshair: true,
        },
        yAxis: {
          title: {
            text: `${getText(`fields.${field_id}`, field_name)} (${field_unit})`,
          },
          crosshair: true,
        },
        plotOptions: {
          series: {
            animation: {
              duration: 0,
            },
          },
        },
        series: [
          {
            name: getText(`fields.${field_id}`, field_name),
            color: SINGLE_LINE_COLOR,
            data: groupedData.map((ele) => ({
              x: TimeFormatters.convertUtcDatetimeStringToValue(ele.datetime),
              y: ele[field_id],
            })),
          },
        ],
      };
    }
  }
};

/**
 *
 * Data Explorer View
 * ------------------
 *
 * @param {*} param0
 * @returns
 */
const DataExplorerView = ({ cycle, cycles, farm, pond, pondData, pondReferenceData, referenceFields, referenceFieldsMode, selectedField }) => {
  const [dataInterval, setDataInterval] = useState(INTERVALS.intraday);
  const [aggFunc, setAggFunc] = useState(AGGFUNCS.average);
  const [chartType, setChartType] = useState(CHART_TYPES.LINE);

  const chartConfigs = makeChartConfig(
    {
      chartType: chartType,
      interval: dataInterval,
      aggFunc: aggFunc,
      data: pondData,
      field: selectedField,
      cycles: cycles,
    },
    {
      showMultiCycle: _.isEmpty(cycle) && cycles.length > 0,
    }
  );

  const isFieldValid = !_.isEmpty(selectedField);

  // No Data Placeholder
  if (!pondData || pondData.length < 1) {
    return (
      <Layout>
        <Stack spacing={1}>
          <AlertBox
            icon={<HelpOutlineIcon />}
            title={isFieldValid ? `${getText("interface.alert.no-data-for")} "${getText("fields." + selectedField.field_id)}"` : <Text>description.select-data-from-menu</Text>}
          ></AlertBox>
          {selectedField.is_derived && (
            <AlertBox icon={<InfoOutlined />} title={getText("interface.alert.this-is-derived-field") + "."}>
              <Box>
                <Typography color={colors.grey[500]}>
                  <Text>description.drived-data-description</Text>
                </Typography>
              </Box>
            </AlertBox>
          )}
        </Stack>
      </Layout>
    );
  }

  // If the data lenght is 1, display figure only
  if (pondData.length === 1) {
    return (
      <Layout>
        <Box p={2}>
          <Stack alignItems={"center"} spacing={2}>
            <Typography>{TimeFormatters.formatDatetime(pondData[0]["datetime"], "DD")}</Typography>
            <Typography variant="h3" fontWeight="bold">
              <NumberFormat displayType="text" decimalScale={2} thousandSeparator={","} value={pondData[0][selectedField?.field_id]} />
            </Typography>
            <Typography variant="h5">
              <Text>{`fields.${selectedField?.field_id}`}</Text>
            </Typography>
          </Stack>
        </Box>
      </Layout>
    );
  }

  return (
    <Layout>
      <WidgetCard>
        <Stack direction="row" alignItems="center" spacing={2} my={1}>
          <MuiToggleButtonGroup
            value={chartType}
            onChange={setChartType}
            options={[
              { value: CHART_TYPES.LINE, icon: <TimelineIcon /> },
              { value: CHART_TYPES.BAR, icon: <BarChartIcon /> },
              { value: CHART_TYPES.TABLE, icon: <TableChartIcon /> },
            ]}
          />
          <MuiToggleButtonGroup
            value={dataInterval}
            onChange={setDataInterval}
            options={[
              { label: getText("interface.general.original"), value: INTERVALS.intraday },
              { label: getText("interface.time.daily"), value: INTERVALS.daily },
              { label: getText("interface.time.weekly"), value: INTERVALS.weekly },
            ]}
          />
        </Stack>

        {/* --------- Reference Data  --------- */}
        {referenceFieldsMode && (
          <Box p={2}>
            <PondReferenceDataCharts
              key={`${selectedField?.field_id}-${JSON.stringify(cycle)} - ${chartType}`}
              pondReferenceData={pondReferenceData}
              referenceFields={referenceFields}
              primaryPondData={pondData}
            />
          </Box>
        )}

        {(chartType === CHART_TYPES.LINE || chartType === CHART_TYPES.BAR) && <HighchartsWrapper key={`${JSON.stringify(cycle)} - ${chartType}`} options={chartConfigs} />}

        {chartType === CHART_TYPES.TABLE && <TableChart data={pondData} selectedField={selectedField} />}

        {chartType === CHART_TYPES.CALENDAR && <DataCalendarMonitor data={pondData} />}
      </WidgetCard>
    </Layout>
  );
};

export default DataExplorerView;
