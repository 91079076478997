import { Box, Typography } from "@mui/material";
import OrgRow from "screens/Aquaculture/components/OrgConnect/components/OrgRow";
import { CheckIcon, CloseIcon } from "components/Icons/MaterialIcons";
import { VerticalAlertBoxCollections } from "components/Alerts/AlertBox";
import { PondManagerServices } from "api/pondManagerServices";
import { alertsActions } from "redux/alerts";
import { useDispatch } from "react-redux";

const PendingReceivedOrgsList = ({ farm, list = [], actions }) => {
  const dispatch = useDispatch();

  const Actions = {
    onAcceptRequest: async ({ orgid }) => {
      await PondManagerServices.acceptConnectionToOrg({ farmid: farm.farm_id, orgid: orgid });
      alertsActions.addInfo(dispatch, {
        content: "You have accepted the request!",
      });
      actions.onLoadConnectedOrgs();
    },
    onDeleteConfirm: async ({ orgid }) => {
      await PondManagerServices.removeConnectionToOrg({ farmid: farm.farm_id, orgid: orgid });
      alertsActions.addInfo(dispatch, {
        content: "You have rejected the request!",
      });
      actions.onLoadConnectedOrgs();
    },
  };

  return (
    <Box>
      <Box mb={3}>
        <Typography variant="cardTitle">Connect Request Received</Typography>
      </Box>
      <Box>
        {list.length === 0 && <VerticalAlertBoxCollections.NothingShow />}
        {list.map((ele) => {
          return (
            <OrgRow
              data={ele}
              actions={[
                {
                  action: () => {
                    Actions.onDeleteConfirm({ orgid: ele.org_id });
                  },
                  icon: <CheckIcon color="success" />,
                },
                {
                  action: () => {
                    Actions.onDeleteConfirm({ orgid: ele.org_id });
                  },
                  icon: <CloseIcon color="error" />,
                },
              ]}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default PendingReceivedOrgsList;
