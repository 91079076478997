import { NewspaperIcon } from "components/Icons/MaterialIcons";
import Text from "components/text/Text";

import InvestmentImpactReport from "screens/Aquaculture/components/Report/InvestmentImpactReport";
import ProfitLossReport from "screens/Aquaculture/components/Report/ProfitLossReport";
import SRSReport from "screens/Aquaculture/components/Report/SRSReport";
import SideMenuLayout from "screens/Aquaculture/components/Layout/SideMenuLayout";
import PondFinancialReport from "screens/Aquaculture/components/Report/PondFinancialReport";
import { VerticalAlertBoxCollections } from "components/Alerts/AlertBox";
import FarmFinancialReport from "screens/Aquaculture/components/Report/FarmFinancialReport";
import PondManagerAccessWrapper from "components/Wrapper/PondManagerAccessWrapper";
import { ResourcePolicies } from "screens/Aquaculture/components/AccessControl/ResourcePolicies";

const ReportHome = ({ pond, farm }) => {
  const MENULIST = [
    {
      label: (
        <span>
          <Text>interface.general.srs-report</Text> (beta)
        </span>
      ),
      description: "",
      value: 0,
      icon: <NewspaperIcon />,
      component: (
        <PondManagerAccessWrapper policies={[ResourcePolicies["REPORT:SRS"]]}>
          <SRSReport />
        </PondManagerAccessWrapper>
      ),
    },
    {
      label: <Text>interface.general.profit-and-loss</Text>,
      description: "",
      value: 2,
      icon: <NewspaperIcon />,
      component: (
        <PondManagerAccessWrapper policies={[ResourcePolicies["REPORT:FINANCE"]]}>
          <ProfitLossReport />
        </PondManagerAccessWrapper>
      ),
    },
    {
      label: <Text>interface.general.investment-impact</Text>,
      description: "",
      value: 3,
      icon: <NewspaperIcon />,
      component: (
        <PondManagerAccessWrapper policies={[ResourcePolicies["REPORT:FINANCE"]]}>
          <InvestmentImpactReport />
        </PondManagerAccessWrapper>
      ),
    },
    {
      label: <Text>interface.general.pond-financial</Text>,
      description: "",
      value: 4,
      icon: <NewspaperIcon />,
      component: (
        <PondManagerAccessWrapper policies={[ResourcePolicies["REPORT:FINANCE"]]}>
          {pond ? <PondFinancialReport pond={pond} /> : <VerticalAlertBoxCollections.NoPondSelected />}
        </PondManagerAccessWrapper>
      ),
    },
    {
      label: <Text>interface.general.farm-financial</Text>,
      description: "",
      value: 5,
      icon: <NewspaperIcon />,
      component: (
        <PondManagerAccessWrapper policies={[ResourcePolicies["REPORT:FINANCE"]]}>
          <FarmFinancialReport farm={farm} />
        </PondManagerAccessWrapper>
      ),
    },
  ];

  return <SideMenuLayout menulist={MENULIST} title={<Text>interface.general.report</Text>} defaultViewMode={0} />;
};

export default ReportHome;
