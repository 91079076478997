import React from "react";
import "./widget.component.css";
import { Realtime } from "./realtime.component";
import { Hourly } from "./hourly.component";
import PinIcon from "../icons/pin.svg";
import { addHours } from "../utilities";
import useWeatherTimelines from "../hooks/useWeatherTimelines";
import { Box, Typography } from "@mui/material";
import { Daily } from "screens/Aquaculture/components/Climate/ClimateDashboard/widgets/components/daily.component";

const now = new Date();
const startTime = now.toISOString();
const endTime = addHours({ date: now, hours: 6 }).toISOString();

function Loading() {
  return (
    <Box minWidth={200}>
      <Typography>Loading...</Typography>
    </Box>
  );
}

function Error() {
  return <div>Oops! Something went wrong :(</div>;
}

function Widget({ lat, lon, location }) {
  const { weatherData: timelineResponse, loading: timelineLoading, error: timelineHasError } = useWeatherTimelines(lat, lon, startTime, endTime);

  if (timelineLoading) {
    return <Loading />;
  }

  if (timelineHasError) {
    return <Error />;
  }

  const hourlyResponse = timelineResponse.data.timelines[0];
  const realtimeResponse = timelineResponse.data?.timelines[1];

  return (
    <div className="app-root">
      <Typography>{now.toDateString()}</Typography>
      <Box>
        <Typography>
          <img className="icon location-icon" src={PinIcon} alt={location} title={location} />
          {location}
        </Typography>
      </Box>
      <Box mb={2}>
        <Realtime realtime={realtimeResponse} />
      </Box>
      <Hourly hourly={hourlyResponse} />
    </div>
  );
}

const StaticForecastWidget = ({ data, location }) => {
  if (!data) return "";

  const tsResponse = data?.timelines?.[0];
  const realtimeResponse = data?.timelines?.[1];

  return (
    <div className="app-root">
      <Typography>{now.toDateString()}</Typography>
      <Box>
        <Typography fontSize={10}>
          <img className="icon location-icon" src={PinIcon} alt={location} title={location} />
          {location}
        </Typography>
      </Box>
      <Box mb={2}>
        <Realtime realtime={realtimeResponse} />
      </Box>
      <Daily daily={tsResponse} />
    </div>
  );
};

export { Widget, StaticForecastWidget };
