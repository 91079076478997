import React from "react";

import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { unregister } from "./registerServiceWorker";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import * as ReactDOMClient from "react-dom/client";

import "./css/index.css";
import "./css/fonts.css";
import "./css/external.css";
import AppReducers from "redux/AppReducers";

// INIT :: MSAL
import { msalConfig } from "configs/msalConfigs";
import { MsalProvider } from "@azure/msal-react";
import { EventType, PublicClientApplication } from "@azure/msal-browser";
// INIT :: Google Oauth
import { GoogleOAuthProvider } from "@react-oauth/google";
// INIT :: Ag Grid
import { LicenseManager } from "ag-grid-enterprise";
import { AG_GRID_LICENSE_KEY, GOOGLE_OAUTH_CLIENT_ID } from "secrets";
LicenseManager.setLicenseKey(AG_GRID_LICENSE_KEY);
// INIT :: i18next
import "./i18next";

const store = configureStore({
  reducer: AppReducers,
  // persistedState,
});

const pca = new PublicClientApplication(msalConfig);

pca.addEventCallback((event) => {
  if (event.eventType == EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    pca.setActiveAccount(account);
  }
  if (event.eventType == EventType.LOGIN_FAILURE) {
    if (window.location.pathname === "/microsoft-signed-in") {
      window.location.replace("/login");
    }
  }
});

const root = ReactDOMClient.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <MsalProvider instance={pca}>
      <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
        <Provider store={store}>
          <App />
        </Provider>
      </GoogleOAuthProvider>
    </MsalProvider>
  </BrowserRouter>
);

unregister();
