import { Box, Button, Stack, Typography } from "@mui/material";
import AuthServices from "api/authServices";
import { theme } from "App";
import { ShrimplLogoSvg } from "components/Images";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { userActions } from "redux/user";
import { ROUTE_LOGIN } from "routes/paths";

const ConfirmEmail = () => {
  const [verified, setVerified] = useState(false);

  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");

  useEffect(() => {
    const run = async () => {
      if (token) {
        const rsp = await AuthServices.verifyEmail(token);
        if (rsp?.payload?.status === "ok") {
          setVerified(true);
          setTimeout(() => {
            window.location.replace("/");
          }, 1000);
        }
      }
    };
    run();
  }, []);

  const Actions = {
    resendConfirmationEmail: () => {
      userActions.resendConfirmationEmail(dispatch, { requestId: userId });
      setEmailSent(true);
    },
  };

  if (!token)
    return (
      <Box backgroundColor={theme.palette.primary.main}>
        <Stack height="100vh" width="100vw" direction="row" justifyContent="center" alignItems="center"></Stack>
      </Box>
    );
  return (
    <Box backgroundColor={theme.palette.primary.main}>
      <Stack height="100vh" width="100vw" direction="row" justifyContent="center" alignItems="center">
        {verified ? (
          <Box p={5} backgroundColor="white" borderRadius={1} maxWidth="400px">
            <img src={ShrimplLogoSvg} alt="" width="200px" />
            <Box my={2}>
              <Typography variant="h5">You email has been verified</Typography>
            </Box>
          </Box>
        ) : (
          <Box p={5} backgroundColor="white" borderRadius={1} maxWidth="400px">
            <img src={ShrimplLogoSvg} alt="" width="200px" />
            <Box my={2}>
              <Typography variant="h5">Confirming your email...</Typography>
            </Box>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default ConfirmEmail;
