import _ from "lodash";

export const chatMockApis = {
  getChats: (farmId) => {
    if (farmId === "e57fa60b-bde8-4d54-8f23-74fde88255b4" || farmId === "ba726143-c1ca-4fdf-ad9e-b52c82dc90c3") {
      return chatApiMock
    }
  },
  getChatHistory: (farmId, chatId, headChatId) => {
    if (farmId === "e57fa60b-bde8-4d54-8f23-74fde88255b4" ||farmId === "ba726143-c1ca-4fdf-ad9e-b52c82dc90c3" ) {
      const result = _.chain(chatApiMock).find({ chatId }).value()?.conversation;
      return result.map(ele => ({
        ...ele,
        body: `<p>${ele.body}</p>`,
        isMine: ele.type === "sent",
      }))
    }
  }
}

export const chatApiMock = [
    {
        chatId: 1,
        sender: 'Claude Chen',
        senderType: 'user',
        timestamp: '2024-01-30T09:00:00',
        conversation: [
            { id: "1",  sender: "Claude C", body: 'Hi!', timestamp: '2024-01-30T09:00:05', type: 'sent', seen: 'seen' },
            { id: "2",  sender: "Claude C", body: 'Hello!', timestamp: '2024-01-30T09:00:10', type: 'received', seen: 'notseen' },
            { id: "3",  sender: "Claude C", body: 'How are you?', timestamp: '2024-01-30T09:00:15', type: 'sent', seen: 'seen' },
            { id: "4",  sender: "Claude C", body: "I'm good, thanks!", timestamp: '2024-01-30T09:00:20', type: 'received', seen: 'notseen' },
            { id: "5",  sender: "Claude C", body: 'What have you been up to?', timestamp: '2024-01-30T09:00:25', type: 'sent', seen: 'seen' },
            { id: "6",  sender: "Claude C", body: 'Just working on some projects.', timestamp: '2024-01-30T09:00:30', type: 'sent', seen: 'notseen' },
            { id: "7",  sender: "Claude C", body: 'That sounds interesting!', timestamp: '2024-01-30T09:00:35', type: 'sent', seen: 'seen' },
            { id: "8",  sender: "Claude C", body: "Yes, it's been quite busy lately.", timestamp: '2024-01-30T09:00:40', type: 'received', seen: 'notseen' },
            { id: "9",  sender: "Claude C", body: 'Any plans for the weekend?', timestamp: '2024-01-30T09:00:45', type: 'sent', seen: 'seen' },
            { id: "10", sender: "Claude C", body: 'Not sure yet, maybe some hiking.', timestamp: '2024-01-30T09:00:50', type: 'received', seen: 'notseen' },
            { id: "11", sender: "Claude C", body: 'Yes, I love being in nature and getting some fresh air.', timestamp: '2024-01-30T09:00:55', type: 'received', seen: 'seen' },
            { id: "12", sender: "Claude C", body: 'Me too! Nature can be so refreshing and rejuvenating.', timestamp: '2024-01-30T09:01:00', type: 'sent', seen: 'notseen' },
            { id: "13", sender: "Claude C", body: 'Absolutely. Do you have any favorite hiking spots?', timestamp: '2024-01-30T09:01:05', type: 'received', seen: 'seen' },
            { id: "14", sender: "Claude C", body: "There's a beautiful trail in the nearby forest that I enjoy. How about you?", timestamp: '2024-01-30T09:01:10', type: 'sent', seen: 'notseen' },
            { id: "15", sender: "Claude C", body: "I like exploring new places, so I'm always on the lookout for new hiking trails.", timestamp: '2024-01-30T09:01:15', type: 'received', seen: 'seen' },
            { id: "16", sender: "Claude C", body: 'That\'s adventurous! Discovering new trails can be so much fun.', timestamp: '2024-01-30T09:01:20', type: 'sent', seen: 'notseen' },
            { id: "17", sender: "Claude C", body: "Indeed, it's like a mini adventure every time.", timestamp: '2024-01-30T09:01:25', type: 'received', seen: 'seen' },
            { id: "18", sender: "Claude C", body: 'By the way, have you tried any new restaurants in town recently?', timestamp: '2024-01-30T09:01:30', type: 'sent', seen: 'notseen' },
            { id: "19", sender: "Claude C", body: "Not lately, but I've heard about this new Italian place that's supposed to be fantastic.", timestamp: '2024-01-30T09:01:35', type: 'received', seen: 'seen' }
        ]
    },
    {
        chatId: 2,
        sender: 'Ciaron',
        senderType: 'admin',
        timestamp: '2024-01-30T09:15:00',
        conversation: [
            { id: "1",  sender: "Ciaron M", body: 'Hey there!', timestamp: '2024-01-30T09:15:05', type: 'sent', seen: 'notseen' },
            { id: "2",  sender: "Ciaron M", body: 'Hello Ciaron!', timestamp: '2024-01-30T09:15:10', type: 'received', seen: 'seen' },
            { id: "3",  sender: "Ciaron M", body: 'What\'s up?', timestamp: '2024-01-30T09:15:15', type: 'sent', seen: 'notseen' },
            { id: "4",  sender: "Ciaron M", body: 'Not much, just working.', timestamp: '2024-01-30T09:15:20', type: 'received', seen: 'seen' },
            { id: "5",  sender: "Ciaron M", body: 'How\'s your day going?', timestamp: '2024-01-30T09:15:25', type: 'sent', seen: 'notseen' },
            { id: "6",  sender: "Ciaron M", body: 'Pretty good, thanks!', timestamp: '2024-01-30T09:15:30', type: 'received', seen: 'seen' },
            { id: "7",  sender: "Ciaron M", body: 'Got any exciting plans for the evening?', timestamp: '2024-01-30T09:15:35', type: 'sent', seen: 'notseen' },
            { id: "8",  sender: "Ciaron M", body: 'Just relaxing at home.', timestamp: '2024-01-30T09:15:40', type: 'received', seen: 'seen' },
            { id: "9",  sender: "Ciaron M", body: 'Sounds nice!', timestamp: '2024-01-30T09:15:45', type: 'sent', seen: 'notseen' },
            { id: "10", sender: "Ciaron M", body: 'Indeed it is!', timestamp: '2024-01-30T09:15:50', type: 'received', seen: 'seen' }
        ]
    },
    {
        chatId: 3,
        sender: 'Rizlan',
        senderType: 'admin',
        timestamp: '2024-01-30T09:30:00',
        conversation: [
            { id: "1", sender: "Demo", body: 'Hi!', timestamp: '2024-01-30T09:30:05', type: 'sent', seen: 'seen' },
            { id: "2", sender: "Demo", body: 'Hello Rizlan!', timestamp: '2024-01-30T09:30:10', type: 'received', seen: 'notseen' },
            { id: "3", sender: "Demo", body: 'What are you up to?', timestamp: '2024-01-30T09:30:15', type: 'sent', seen: 'seen' },
            { id: "4", sender: "Demo", body: 'Just relaxing at home.', timestamp: '2024-01-30T09:30:20', type: 'received', seen: 'notseen' },
            { id: "5", sender: "Demo", body: 'Any plans for the day?', timestamp: '2024-01-30T09:30:25', type: 'sent', seen: 'seen' },
            { id: "6", sender: "Demo", body: 'Not much, just taking it easy.', timestamp: '2024-01-30T09:30:30', type: 'received', seen: 'notseen' }
        ]
    },
    // {
    //     chatId: 4,
    //     sender: 'Ella',
    //     senderType: 'user',
    //     timestamp: '2024-01-30T09:45:00',
    //     conversation: [
    //         { id: "1", body: 'Hey!', timestamp: '2024-01-30T09:45:05', type: 'sent', seen: 'seen' },
    //         { id: "2", body: 'Hello Ella!', timestamp: '2024-01-30T09:45:10', type: 'received', seen: 'notseen' },
    //         { id: "3", body: 'How\'s your day going?', timestamp: '2024-01-30T09:45:15', type: 'sent', seen: 'seen' },
    //         { id: "4", body: 'It\'s been good, thanks!', timestamp: '2024-01-30T09:45:20', type: 'received', seen: 'notseen' },
    //         { id: "5", body: 'Any plans for the evening?', timestamp: '2024-01-30T09:45:25', type: 'sent', seen: 'seen' },
    //         { id: "6", body: 'Thinking of going out for dinner.', timestamp: '2024-01-30T09:45:30', type: 'received', seen: 'notseen' }
    //     ]
    // },
    // {
    //     chatId: 5,
    //     sender: 'Sophie',
    //     senderType: 'user',
    //     timestamp: '2024-01-30T10:00:00',
    //     conversation: [
    //         { id: "1", body: 'Hi there!', timestamp: '2024-01-30T10:00:05', type: 'sent', seen: 'notseen' },
    //         { id: "2", body: 'Hello Sophie!', timestamp: '2024-01-30T10:00:10', type: 'received', seen: 'seen' },
    //         { id: "3", body: 'What\'s new?', timestamp: '2024-01-30T10:00:15', type: 'sent', seen: 'notseen' },
    //         { id: "4", body: 'Not much, just catching up on some reading.', timestamp: '2024-01-30T10:00:20', type: 'received', seen: 'seen' },
    //         { id: "5", body: 'Any good books you\'d recommend?', timestamp: '2024-01-30T10:00:25', type: 'sent', seen: 'notseen' },
    //         { id: "6", body: 'I can suggest a few, what genres do you like?', timestamp: '2024-01-30T10:00:30', type: 'received', seen: 'seen' }
    //     ]
    // },
    // {
    //     chatId: 6,
    //     sender: 'Liam',
    //     senderType: 'user',
    //     timestamp: '2024-01-30T10:15:00',
    //     conversation: [
    //         { id:"1", body: 'Hello!', timestamp: '2024-01-30T10:15:05', type: 'sent', seen: 'seen' },
    //         { id:"2", body: 'Hi Liam!', timestamp: '2024-01-30T10:15:10', type: 'received', seen: 'notseen' },
    //         { id:"3", body: 'How\'s work going?', timestamp: '2024-01-30T10:15:15', type: 'sent', seen: 'seen' },
    //         { id:"4", body: 'It\'s busy as usual.', timestamp: '2024-01-30T10:15:20', type: 'received', seen: 'notseen' },
    //         { id:"5", body: 'Looking forward to the weekend?', timestamp: '2024-01-30T10:15:25', type: 'sent', seen: 'seen' },
    //         { id:"6", body: 'Definitely! Need a break.', timestamp: '2024-01-30T10:15:30', type: 'received', seen: 'notseen' }
    //     ]
    // },
    // {
    //     chatId: 7,
    //     sender: 'Ava',
    //     senderType: 'admin',
    //     timestamp: '2024-01-30T10:30:00',
    //     conversation: [
    //         { id:"1", body: 'Hi Ava!', timestamp: '2024-01-30T10:30:05', type: 'sent', seen: 'notseen' },
    //         { id:"2", body: 'Hey!', timestamp: '2024-01-30T10:30:10', type: 'received', seen: 'seen' },
    //         { id:"3", body: 'How\'s the weather there?', timestamp: '2024-01-30T10:30:15', type: 'sent', seen: 'notseen' },
    //         { id:"4", body: 'It\'s sunny and warm!', timestamp: '2024-01-30T10:30:20', type: 'received', seen: 'seen' },
    //         { id:"5", body: 'Wish I could be there!', timestamp: '2024-01-30T10:30:25', type: 'sent', seen: 'notseen' },
    //         { id:"6", body: 'You should visit sometime!', timestamp: '2024-01-30T10:30:30', type: 'received', seen: 'seen' }
    //     ]
    // }
];
