import {
  Box,
  colors,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useState } from "react";

const styles = {
  tableHeaderCell: {
    "&:hover": {
      backgroundColor: colors.grey[100],
      cursor: "pointer",
    },
    "& i.fas": {
      marginLeft: "5px",
    },
  },
};

export const XSmallTableCell = ({ children, ...props }) => (
  <TableCell
    sx={{
      padding: "2px 5px",

      ...props.sx,
    }}
    {...props}
  >
    {children}
  </TableCell>
);

const SmartTable = ({ rows, columns, pageSize, withSearch, xSmall }) => {
  const [query, setQuery] = useState(null);
  const [page, setPage] = useState(0);
  const [{ sortBy, ascending }, setSort] = useState({
    sortBy: null,
    ascending: true,
  });

  if (!rows || rows.length < 1) {
    return "";
  }

  const cols =
    columns ||
    _.keys(_.first(rows)).map((ele) => ({
      label: ele,
      accessor: ele,
    }));

  let rows_after_filter = rows;

  if (sortBy) {
    rows_after_filter = _.orderBy(
      rows_after_filter,
      sortBy,
      ascending ? "asc" : "desc"
    );
  }

  if (query) {
    const re = new RegExp(":(.+)=(.+)", "g");
    const match = re.exec(query);
    if (match) {
      const qK = match[1];
      const qV = match[2];
      rows_after_filter = rows_after_filter.filter(
        (r) =>
          r[qK] &&
          typeof r[qK] === "string" &&
          r[qK].toLowerCase() === qV.toLowerCase()
      );
    } else {
      rows_after_filter = rows.filter((r) =>
        _.includes(
          _.values(r).map((v) =>
            typeof v === "string"
              ? _.includes(v.toLowerCase(), query.toLowerCase())
              : false
          ),
          true
        )
      );
    }
  }

  const rows_to_display =
    rows_after_filter.length > 0
      ? pageSize
        ? _.chunk(rows_after_filter, pageSize)[page]
        : rows_after_filter
      : [];
  const handlePageChange = (_, newP) => {
    setPage(newP);
  };

  const handleSortAction = ({ accessor, sortable }) => {
    if (sortable) {
      if (sortBy && ascending) {
        setSort({
          sortBy: accessor,
          ascending: false,
        });
      } else if (sortBy && !ascending) {
        setSort({
          sortBy: null,
          ascending: true,
        });
      } else {
        setSort({
          sortBy: accessor,
          ascending: true,
        });
      }
    }
  };

  const renderSortableIcon = ({ accessor, sortable }) => {
    if (sortable) {
      if (!sortBy || sortBy !== accessor) {
        return <i className="fas fa-sort"></i>;
      }
      if (sortBy && sortBy === accessor && ascending) {
        return <i className="fas fa-sort-up"></i>;
      }
      if (sortBy && sortBy === accessor && !ascending) {
        return <i className="fas fa-sort-down"></i>;
      }
    }
  };

  return (
    <>
      {withSearch && (
        <Box my={2}>
          <TextField
            size="small"
            label="Search (Press Enter)"
            onChange={(e) => !e.target.value && setQuery(null)}
            onKeyPress={(e) => e.key === "Enter" && setQuery(e.target.value)}
            variant="standard"
            fullWidth
          ></TextField>
        </Box>
      )}
      <Table size="small">
        <TableHead>
          <TableRow>
            {cols.map((c, cIdx) =>
              xSmall ? (
                <XSmallTableCell
                  key={cIdx}
                  sx={styles.tableHeaderCell}
                  onClick={() => handleSortAction(c)}
                >
                  {c.label} {renderSortableIcon(c)}
                </XSmallTableCell>
              ) : (
                <TableCell
                  key={cIdx}
                  sx={styles.tableHeaderCell}
                  onClick={() => handleSortAction(c)}
                >
                  {c.label} {renderSortableIcon(c)}
                </TableCell>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows_to_display.map((r, rIdx) => (
            <TableRow
              sx={{
                "&:hover": {
                  backgroundColor: colors.grey[100],
                },
              }}
              key={rIdx}
            >
              {cols.map((c, cIdx) => {
                const cellValue = c.wrapper
                  ? c.accessor === "*"
                    ? c.wrapper(r)
                    : c.wrapper(r[c.accessor])
                  : r[c.accessor];
                if (xSmall) {
                  return <XSmallTableCell>{cellValue}</XSmallTableCell>;
                }

                return <TableCell>{cellValue}</TableCell>;
              })}
            </TableRow>
          ))}
        </TableBody>
        {pageSize && (
          <TablePagination
            count={rows_after_filter.length}
            onPageChange={handlePageChange}
            page={page}
            rowsPerPage={pageSize}
          />
        )}
      </Table>
      {rows_to_display.length < 1 && (
        <Typography
          variant="h1"
          p={2}
          sx={{
            color: "#ddd",
            textAlign: "center",
          }}
        >
          Empty
        </Typography>
      )}
    </>
  );
};

export default SmartTable;
