import _ from "lodash"

export const emissionData = [

  // ====== Sri Srinivasa Farms (INDIA)
  { farm_id: "147659c6-6e96-4e6b-a8c0-99f1a57d089a", pond_id: "4528196f-8b1b-41e4-82f8-c2750ed3441f", pond_label:"A1", "co2": 9615.380993, "intensity": 7.229609769, feed: 36, energy: 4, seed:	0.03, lupa: 60},
  { farm_id: "147659c6-6e96-4e6b-a8c0-99f1a57d089a", pond_id: "73ab6b89-4a6c-4519-acff-5cc5d12ef8f1", pond_label:"A2", "co2": 8758.530466, "intensity": 5.713327114, feed: 38, energy: 4, seed:	0.03, lupa: 57},
  // { pond_label:"A3",},
  { farm_id: "147659c6-6e96-4e6b-a8c0-99f1a57d089a", pond_id: "316974dd-0ab0-4bff-8360-8319a761deaa", pond_label:"A4", "co2": 7324.178342, "intensity": 7.373581337, feed: 23, energy: 4, seed:	0.04, lupa: 73},
  { farm_id: "147659c6-6e96-4e6b-a8c0-99f1a57d089a", pond_id: "88ca109e-97f5-4580-9252-3460ccc11172", pond_label:"A5", "co2": 7142.626082, "intensity": 4.939575437, feed: 34, energy: 3, seed:	0.01, lupa: 63},
  // { pond_label:"A6",},
  { farm_id: "147659c6-6e96-4e6b-a8c0-99f1a57d089a", pond_id: "7f217d2a-503b-4d47-bb60-cc1de0c9cb71", pond_label:"B1", "co2": 8948.730145, "intensity": 5.436322304, feed: 40, energy: 4, seed:	0.02, lupa: 56},
  { farm_id: "147659c6-6e96-4e6b-a8c0-99f1a57d089a", pond_id: "14e86413-2fcb-4e32-a6ad-44467374cdb4", pond_label:"B2", "co2": 8814.593178, "intensity": 4.94285492, feed: 39, energy: 4, seed:	0.03, lupa: 57},
  { farm_id: "147659c6-6e96-4e6b-a8c0-99f1a57d089a", pond_id: "4e54a5f9-6ce6-4d6d-b14e-8fe2d888ea29", pond_label:"B3", "co2": 8422.399214, "intensity": 11.10255631, feed: 32, energy: 4, seed:	0.02, lupa: 64},
  { farm_id: "147659c6-6e96-4e6b-a8c0-99f1a57d089a", pond_id: "9d00a12c-4051-48d2-a984-f362eec2221d", pond_label:"B4", "co2": 8936.96864, "intensity": 5.952819983, feed: 30, energy: 3, seed:	0.01, lupa: 67},
  { farm_id: "147659c6-6e96-4e6b-a8c0-99f1a57d089a", pond_id: "526eb79f-86db-4098-9cd7-61f6ad746810", pond_label:"B5", "co2": 5391.325728, "intensity": 7.164552463, feed: 24, energy: 4, seed:	0.04, lupa: 72},
  { farm_id: "147659c6-6e96-4e6b-a8c0-99f1a57d089a", pond_id: "2becedf3-9f55-477f-9acc-6573edfd017a", pond_label:"C1", "co2": 8901.276356, "intensity": 6.030266483, feed: 26, energy: 4, seed:	0.01, lupa: 71},
  { farm_id: "147659c6-6e96-4e6b-a8c0-99f1a57d089a", pond_id: "f52c7c66-e354-4e18-9f99-d1f284563e2e", pond_label:"C2", "co2": 9979.533459, "intensity": 6.403293846, feed: 25, energy: 3, seed:	0.03, lupa: 71},
 
  // ===== El Caracol
  { farm_id: "683d4bf7-9d8a-4747-9d07-2fbf0f560f6a", pond_id: "44e9d079-3d9f-4539-b29d-de9feae91ff8", pond_label:"1",  "co2": 14045, "intensity": 3.08, feed: 66, energy: 25, seed:	1, lupa: 9},
  { farm_id: "683d4bf7-9d8a-4747-9d07-2fbf0f560f6a", pond_id: "d553cd5f-b7bf-4e8c-9f5d-0f58391d8db3", pond_label:"2",  "co2": 16584, "intensity": 3.12, feed: 71, energy: 21, seed:	1, lupa: 7},
  { farm_id: "683d4bf7-9d8a-4747-9d07-2fbf0f560f6a", pond_id: "c3023070-b060-4494-9c51-468e11754f08", pond_label:"3",  "co2": 17540, "intensity": 2.78, feed: 73, energy: 20, seed:	1, lupa: 7},
  { farm_id: "683d4bf7-9d8a-4747-9d07-2fbf0f560f6a", pond_id: "fb9e4d88-37fc-4912-a05d-42bc4ae69e9c", pond_label:"4",  "co2": 17445, "intensity": 3.09, feed: 75, energy: 18, seed:	1, lupa: 7},
  { farm_id: "683d4bf7-9d8a-4747-9d07-2fbf0f560f6a", pond_id: "1d33e57b-09f9-4bdc-95c9-560b9ef1ed70", pond_label:"5",  "co2": 16521, "intensity": 2.63, feed: 78, energy: 16, seed:	1, lupa: 6},
  { farm_id: "683d4bf7-9d8a-4747-9d07-2fbf0f560f6a", pond_id: "a84719d2-89bb-4780-a1fa-acda0901aac5", pond_label:"6",  "co2": 17015, "intensity": 2.92, feed: 73, energy: 20, seed:	1, lupa: 7},
  { farm_id: "683d4bf7-9d8a-4747-9d07-2fbf0f560f6a", pond_id: "c5e2d5ca-f90e-4e33-8003-41f8cbe3f9fb", pond_label:"7",  "co2": 21896, "intensity": 2.93, feed: 74, energy: 25, seed:	1, lupa: 9},
  { farm_id: "683d4bf7-9d8a-4747-9d07-2fbf0f560f6a", pond_id: "351c721a-c634-4401-b943-0c8980b501e4", pond_label:"8",  "co2": 11271, "intensity": 2.94, feed: 74, energy: 12, seed:	0, lupa: 5},
  { farm_id: "683d4bf7-9d8a-4747-9d07-2fbf0f560f6a", pond_id: "b9dd8435-4732-4931-a47a-f28cc1b111ff", pond_label:"9",  "co2": 11154, "intensity": 2.97, feed: 75, energy: 12, seed:	0, lupa: 5},
  { farm_id: "683d4bf7-9d8a-4747-9d07-2fbf0f560f6a", pond_id: "2fd1db5b-7ce8-4818-b708-32ee2ab02f6a", pond_label:"10", "co2": 12433, "intensity": 3.21, feed: 76, energy: 12, seed:	0, lupa: 5},
  { farm_id: "683d4bf7-9d8a-4747-9d07-2fbf0f560f6a", pond_id: "36b7f824-a3d2-41c3-b82e-20857a3da8eb", pond_label:"11", "co2": 12163, "intensity": 3.07, feed: 76, energy: 13, seed:	0, lupa: 5},
  { farm_id: "683d4bf7-9d8a-4747-9d07-2fbf0f560f6a", pond_id: "ee655cbf-daf7-4f72-ab49-8b7f47e7c13b", pond_label:"12", "co2": 12324, "intensity": 2.77, feed: 76, energy: 12, seed:	0, lupa: 5},
  { farm_id: "683d4bf7-9d8a-4747-9d07-2fbf0f560f6a", pond_id: "657df271-cd31-4714-88bb-972e0299967e", pond_label:"13", "co2": 11401, "intensity": 2.57, feed: 74, energy: 12, seed:	0, lupa: 5},
  { farm_id: "683d4bf7-9d8a-4747-9d07-2fbf0f560f6a", pond_id: "15fc9c8b-5386-42ad-a735-8d97df9b91c5", pond_label:"14", "co2": 23037, "intensity": 2.68, feed: 73, energy: 27, seed:	1, lupa: 9},
  { farm_id: "683d4bf7-9d8a-4747-9d07-2fbf0f560f6a", pond_id: "77fc21c4-4e11-4148-8cb6-de83cca708f5", pond_label:"15", "co2": 11415, "intensity": 3.42, feed: 76, energy: 12, seed:	1, lupa: 5},
  { farm_id: "683d4bf7-9d8a-4747-9d07-2fbf0f560f6a", pond_id: "d50c1cd3-e348-4e1a-a22e-a81a9454bfad", pond_label:"16", "co2": 12839, "intensity": 2.88, feed: 77, energy: 13, seed:	1, lupa: 5},
  { farm_id: "683d4bf7-9d8a-4747-9d07-2fbf0f560f6a", pond_id: "fb558b37-b4b2-41d4-a8a3-643e93ff1aa9", pond_label:"17", "co2": 12796, "intensity": 2.71, feed: 75, energy: 14, seed:	1, lupa: 5},
  { farm_id: "683d4bf7-9d8a-4747-9d07-2fbf0f560f6a", pond_id: "f6776a8a-e5bf-4d36-9b16-6dc7895facf3", pond_label:"18", "co2": 12993, "intensity": 2.80, feed: 76, energy: 13, seed:	1, lupa: 5},
  { farm_id: "683d4bf7-9d8a-4747-9d07-2fbf0f560f6a", pond_id: "1f23a149-3b11-4bac-be37-a88d4055f866", pond_label:"19", "co2": 14159, "intensity": 3.00, feed: 74, energy: 16, seed:	1, lupa: 6},
  { farm_id: "683d4bf7-9d8a-4747-9d07-2fbf0f560f6a", pond_id: "37289a82-e585-4aa4-8c04-20681ef20f2c", pond_label:"20", "co2": 14066, "intensity": 3.05, feed: 75, energy: 15, seed:	1, lupa: 6},
  { farm_id: "683d4bf7-9d8a-4747-9d07-2fbf0f560f6a", pond_id: "5d4d3879-065a-4de6-9851-c23f5b4ed8d4", pond_label:"21", "co2": 17651, "intensity": 3.05, feed: 74, energy: 20, seed:	1, lupa: 7},
  { farm_id: "683d4bf7-9d8a-4747-9d07-2fbf0f560f6a", pond_id: "10ef054d-d170-4420-baf1-2f8aa3615e76", pond_label:"22", "co2": 18297, "intensity": 3.25, feed: 76, energy: 19, seed:	1, lupa: 7},
  { farm_id: "683d4bf7-9d8a-4747-9d07-2fbf0f560f6a", pond_id: "0bbccf0b-ebbd-4129-b8f8-33b592de5b13", pond_label:"23", "co2": 20652, "intensity": 3.34, feed: 69, energy: 28, seed:	1, lupa: 10},
  { farm_id: "683d4bf7-9d8a-4747-9d07-2fbf0f560f6a", pond_id: "1a578a46-5b1d-4730-a95b-7f91c0e41214", pond_label:"24", "co2": 13289, "intensity": 3.08, feed: 60, energy: 23, seed:	1, lupa: 8},
  { farm_id: "683d4bf7-9d8a-4747-9d07-2fbf0f560f6a", pond_id: "7031c4be-8b7b-4ff4-99ad-aab9778f2044", pond_label:"25", "co2": 15226, "intensity": 3.07, feed: 74, energy: 17, seed:	1, lupa: 6},
  { farm_id: "683d4bf7-9d8a-4747-9d07-2fbf0f560f6a", pond_id: "285456a3-0780-4ff6-95a8-6c790ab74a09", pond_label:"26", "co2": 14769, "intensity": 3.90, feed: 70, energy: 20, seed:	1, lupa: 7},
  { farm_id: "683d4bf7-9d8a-4747-9d07-2fbf0f560f6a", pond_id: "dfacba70-e2d1-4d66-b23e-09e29137acf3", pond_label:"27", "co2": 24599, "intensity": 3.62, feed: 70, energy: 33, seed:	1, lupa: 11},
  { farm_id: "683d4bf7-9d8a-4747-9d07-2fbf0f560f6a", pond_id: "4d05f52c-2e04-4203-92f2-47675d1c2f9f", pond_label:"28", "co2": 18063, "intensity": 3.18, feed: 58, energy: 35, seed:	1, lupa: 10},
  { farm_id: "683d4bf7-9d8a-4747-9d07-2fbf0f560f6a", pond_id: "ab3a0ca5-828f-4597-b2cf-59c493caf48c", pond_label:"29", "co2": 23678, "intensity": 3.23, feed: 65, energy: 37, seed:	1, lupa: 12},
  { farm_id: "683d4bf7-9d8a-4747-9d07-2fbf0f560f6a", pond_id: "b166c774-fe41-4a1f-a44a-8ee29f15e4fc", pond_label:"30", "co2": 23816, "intensity": 3.29, feed: 65, energy: 37, seed:	1, lupa: 12},
  { farm_id: "683d4bf7-9d8a-4747-9d07-2fbf0f560f6a", pond_id: "a42123d6-671d-46a1-9807-9b961e242e38", pond_label:"31", "co2": 24272, "intensity": 3.51, feed: 67, energy: 36, seed:	1, lupa: 12},
  { farm_id: "683d4bf7-9d8a-4747-9d07-2fbf0f560f6a", pond_id: "4c7d690b-cd45-43bd-a2c2-52d0211646bc", pond_label:"32", "co2": 23512, "intensity": 3.65, feed: 66, energy: 36, seed:	1, lupa: 12},
  { farm_id: "683d4bf7-9d8a-4747-9d07-2fbf0f560f6a", pond_id: "85f1e73d-0ec3-457e-a922-9bc5029e5820", pond_label:"33", "co2": 24334, "intensity": 3.44, feed: 67, energy: 36, seed:	1, lupa: 12},
  { farm_id: "683d4bf7-9d8a-4747-9d07-2fbf0f560f6a", pond_id: "778a2e45-9d6a-4b89-8935-0726a9d4ae70", pond_label:"34", "co2": 17969, "intensity": 4.40, feed: 56, energy: 37, seed:	1, lupa: 10},
  { farm_id: "683d4bf7-9d8a-4747-9d07-2fbf0f560f6a", pond_id: "cbf67ff3-2a7f-485e-9125-9d53d3df3c3a", pond_label:"35", "co2": 17922, "intensity": 3.97, feed: 56, energy: 37, seed:	1, lupa: 10},


// ===== Demo 2024 Aug
{ farm_id: "5dfbf4ea-289d-41e6-8e87-200e23fbfc17", pond_id: "d71dab44-77b8-485b-954c-ad46814e14e9", pond_label:"1",  "co2": 14045, "intensity": 3.08, feed: 66, energy: 25, seed:	1, lupa: 9},
{ farm_id: "5dfbf4ea-289d-41e6-8e87-200e23fbfc17", pond_id: "946c51e0-0d5d-4cac-b891-ec2c4291886d", pond_label:"2",  "co2": 16584, "intensity": 3.12, feed: 71, energy: 21, seed:	1, lupa: 7},
{ farm_id: "5dfbf4ea-289d-41e6-8e87-200e23fbfc17", pond_id: "470c8203-d877-47f0-9621-7464cc88a4bd", pond_label:"3",  "co2": 17540, "intensity": 2.78, feed: 73, energy: 20, seed:	1, lupa: 7},
{ farm_id: "5dfbf4ea-289d-41e6-8e87-200e23fbfc17", pond_id: "9154b46d-d115-4230-83a0-0a2a33776089", pond_label:"4",  "co2": 17445, "intensity": 3.09, feed: 75, energy: 18, seed:	1, lupa: 7},
{ farm_id: "5dfbf4ea-289d-41e6-8e87-200e23fbfc17", pond_id: "24617c07-1df8-42a3-bbe0-51b2e4c3636c", pond_label:"5",  "co2": 16521, "intensity": 2.63, feed: 78, energy: 16, seed:	1, lupa: 6},
{ farm_id: "5dfbf4ea-289d-41e6-8e87-200e23fbfc17", pond_id: "f3a67f71-6aa0-409c-bea6-d56c247ee66f", pond_label:"6",  "co2": 17015, "intensity": 2.92, feed: 73, energy: 20, seed:	1, lupa: 7},
{ farm_id: "5dfbf4ea-289d-41e6-8e87-200e23fbfc17", pond_id: "9e8580b3-d628-4003-a673-4e44cbf4fd41", pond_label:"7",  "co2": 21896, "intensity": 2.93, feed: 74, energy: 25, seed:	1, lupa: 9},
{ farm_id: "5dfbf4ea-289d-41e6-8e87-200e23fbfc17", pond_id: "67359682-3d47-43b8-a7c6-ae6a3629f7fa", pond_label:"8",  "co2": 11271, "intensity": 2.94, feed: 74, energy: 12, seed:	0, lupa: 5},
{ farm_id: "5dfbf4ea-289d-41e6-8e87-200e23fbfc17", pond_id: "2ee5a438-bf63-46f7-b42f-a6e8e4f4e691", pond_label:"9",  "co2": 11154, "intensity": 2.97, feed: 75, energy: 12, seed:	0, lupa: 5},
{ farm_id: "5dfbf4ea-289d-41e6-8e87-200e23fbfc17", pond_id: "3f6feb73-0b1c-47b1-8b90-5ecf7782ecb0", pond_label:"10", "co2": 12433, "intensity": 3.21, feed: 76, energy: 12, seed:	0, lupa: 5},
{ farm_id: "5dfbf4ea-289d-41e6-8e87-200e23fbfc17", pond_id: "a72b5cbc-05c4-4d3f-ae3e-73f41a11f405", pond_label:"11", "co2": 12163, "intensity": 3.07, feed: 76, energy: 13, seed:	0, lupa: 5},
{ farm_id: "5dfbf4ea-289d-41e6-8e87-200e23fbfc17", pond_id: "9236000c-a8db-4bbc-a673-387c7bbe4432", pond_label:"12", "co2": 12324, "intensity": 2.77, feed: 76, energy: 12, seed:	0, lupa: 5},
{ farm_id: "5dfbf4ea-289d-41e6-8e87-200e23fbfc17", pond_id: "d8cfe8f5-8c9d-4128-baf8-e49755dd54ed", pond_label:"13", "co2": 11401, "intensity": 2.57, feed: 74, energy: 12, seed:	0, lupa: 5},
{ farm_id: "5dfbf4ea-289d-41e6-8e87-200e23fbfc17", pond_id: "41937932-429a-447f-a9e8-3a3e377e8e4a", pond_label:"14", "co2": 23037, "intensity": 2.68, feed: 73, energy: 27, seed:	1, lupa: 9},
{ farm_id: "5dfbf4ea-289d-41e6-8e87-200e23fbfc17", pond_id: "a5ca5622-43ec-409f-90dd-41026101ab21", pond_label:"15", "co2": 11415, "intensity": 3.42, feed: 76, energy: 12, seed:	1, lupa: 5},
{ farm_id: "5dfbf4ea-289d-41e6-8e87-200e23fbfc17", pond_id: "57b6fccd-8440-4978-b9ce-f3341cc58b29", pond_label:"16", "co2": 12839, "intensity": 2.88, feed: 77, energy: 13, seed:	1, lupa: 5},
{ farm_id: "5dfbf4ea-289d-41e6-8e87-200e23fbfc17", pond_id: "7b556419-27ac-48bf-983a-1547ffe4e1cf", pond_label:"17", "co2": 12796, "intensity": 2.71, feed: 75, energy: 14, seed:	1, lupa: 5},
{ farm_id: "5dfbf4ea-289d-41e6-8e87-200e23fbfc17", pond_id: "5bd140f9-5a8c-4951-90b1-82b6a588aca1", pond_label:"18", "co2": 12993, "intensity": 2.80, feed: 76, energy: 13, seed:	1, lupa: 5},
{ farm_id: "5dfbf4ea-289d-41e6-8e87-200e23fbfc17", pond_id: "6ac6a9f3-f37a-4d8d-8775-170e4a1e86bc", pond_label:"19", "co2": 14159, "intensity": 3.00, feed: 74, energy: 16, seed:	1, lupa: 6},
{ farm_id: "5dfbf4ea-289d-41e6-8e87-200e23fbfc17", pond_id: "de569929-0929-48a6-83fe-507653e1fb38", pond_label:"20", "co2": 14066, "intensity": 3.05, feed: 75, energy: 15, seed:	1, lupa: 6},
{ farm_id: "5dfbf4ea-289d-41e6-8e87-200e23fbfc17", pond_id: "d2abb834-1d0b-46a2-bc2d-a60f32f976b4", pond_label:"21", "co2": 17651, "intensity": 3.05, feed: 74, energy: 20, seed:	1, lupa: 7},
{ farm_id: "5dfbf4ea-289d-41e6-8e87-200e23fbfc17", pond_id: "e3850f4b-831a-4670-9423-b0c5027eaa28", pond_label:"22", "co2": 18297, "intensity": 3.25, feed: 76, energy: 19, seed:	1, lupa: 7},
{ farm_id: "5dfbf4ea-289d-41e6-8e87-200e23fbfc17", pond_id: "72225542-427d-4317-aff4-f855f85c220a", pond_label:"23", "co2": 20652, "intensity": 3.34, feed: 69, energy: 28, seed:	1, lupa: 10},
{ farm_id: "5dfbf4ea-289d-41e6-8e87-200e23fbfc17", pond_id: "0ba2d235-4a24-4fa1-9220-ef5407992f13", pond_label:"24", "co2": 13289, "intensity": 3.08, feed: 60, energy: 23, seed:	1, lupa: 8},
{ farm_id: "5dfbf4ea-289d-41e6-8e87-200e23fbfc17", pond_id: "b901bb5f-412e-40ca-bce8-640742d71950", pond_label:"25", "co2": 15226, "intensity": 3.07, feed: 74, energy: 17, seed:	1, lupa: 6},
{ farm_id: "5dfbf4ea-289d-41e6-8e87-200e23fbfc17", pond_id: "65089dae-d467-431e-85f3-0d037b3caa8c", pond_label:"26", "co2": 14769, "intensity": 3.90, feed: 70, energy: 20, seed:	1, lupa: 7},
{ farm_id: "5dfbf4ea-289d-41e6-8e87-200e23fbfc17", pond_id: "cfd5750d-5b1c-4f25-98c4-30082b3957fd", pond_label:"27", "co2": 24599, "intensity": 3.62, feed: 70, energy: 33, seed:	1, lupa: 11},
{ farm_id: "5dfbf4ea-289d-41e6-8e87-200e23fbfc17", pond_id: "154a88e3-cadc-40e6-9d97-260231c6dfbe", pond_label:"28", "co2": 18063, "intensity": 3.18, feed: 58, energy: 35, seed:	1, lupa: 10},
{ farm_id: "5dfbf4ea-289d-41e6-8e87-200e23fbfc17", pond_id: "916a29fd-ff2b-498b-9854-ecc76a341c6f", pond_label:"29", "co2": 23678, "intensity": 3.23, feed: 65, energy: 37, seed:	1, lupa: 12},
{ farm_id: "5dfbf4ea-289d-41e6-8e87-200e23fbfc17", pond_id: "77bbe819-e81b-4504-abe7-6353c12d3941", pond_label:"30", "co2": 23816, "intensity": 3.29, feed: 65, energy: 37, seed:	1, lupa: 12},
{ farm_id: "5dfbf4ea-289d-41e6-8e87-200e23fbfc17", pond_id: "05056976-2f41-4b81-9309-a2165c27e3d7", pond_label:"31", "co2": 24272, "intensity": 3.51, feed: 67, energy: 36, seed:	1, lupa: 12},
{ farm_id: "5dfbf4ea-289d-41e6-8e87-200e23fbfc17", pond_id: "7036733a-fd75-4306-a04e-8450aa8b07c9", pond_label:"32", "co2": 23512, "intensity": 3.65, feed: 66, energy: 36, seed:	1, lupa: 12},
{ farm_id: "5dfbf4ea-289d-41e6-8e87-200e23fbfc17", pond_id: "2d2efd0e-d983-4550-9bf3-c72ae7ff7489", pond_label:"33", "co2": 24334, "intensity": 3.44, feed: 67, energy: 36, seed:	1, lupa: 12},
{ farm_id: "5dfbf4ea-289d-41e6-8e87-200e23fbfc17", pond_id: "9a09c28c-56b4-404a-8311-b5d4d87c9d7f", pond_label:"34", "co2": 17969, "intensity": 4.40, feed: 56, energy: 37, seed:	1, lupa: 10},
{ farm_id: "5dfbf4ea-289d-41e6-8e87-200e23fbfc17", pond_id: "ef25a081-4ebd-4970-a817-0ae0cf7735af", pond_label:"35", "co2": 17922, "intensity": 3.97, feed: 56, energy: 37, seed:	1, lupa: 10},
{ farm_id: "5dfbf4ea-289d-41e6-8e87-200e23fbfc17", pond_id: "7dd4e36d-e42f-43b7-9ff1-046db104b45b", pond_label:"36", "co2": 12993, "intensity": 2.80, feed: 76, energy: 13, seed:	1, lupa: 5},
{ farm_id: "5dfbf4ea-289d-41e6-8e87-200e23fbfc17", pond_id: "f603507e-c8e3-4db6-8ac5-051ab5ef4a40", pond_label:"37", "co2": 14769, "intensity": 3.90, feed: 70, energy: 20, seed:	1, lupa: 7},

// ===== Kopaggio Demo 2024 Aug
{ farm_id: "d2c7bf74-1edb-4e45-a65b-e10c72a93b15", pond_id: "f0c6f548-43d8-43ce-a59c-7bfde8bf1fdc", pond_label:"1",  "co2": 14045, "intensity": 3.08, feed: 66, energy: 25, seed:	1, lupa: 9},
{ farm_id: "d2c7bf74-1edb-4e45-a65b-e10c72a93b15", pond_id: "8e3d6d81-e9c8-483f-b3d2-4e10f4e7c76f", pond_label:"2",  "co2": 16584, "intensity": 3.12, feed: 71, energy: 21, seed:	1, lupa: 7},
{ farm_id: "d2c7bf74-1edb-4e45-a65b-e10c72a93b15", pond_id: "4cb91070-9392-4785-a68d-2f3145a9a43c", pond_label:"3",  "co2": 17540, "intensity": 2.78, feed: 73, energy: 20, seed:	1, lupa: 7},
{ farm_id: "d2c7bf74-1edb-4e45-a65b-e10c72a93b15", pond_id: "c6fc221b-1041-47cf-9599-de0ecb35b422", pond_label:"4",  "co2": 17445, "intensity": 3.09, feed: 75, energy: 18, seed:	1, lupa: 7},
{ farm_id: "d2c7bf74-1edb-4e45-a65b-e10c72a93b15", pond_id: "974389d9-fc15-4c48-9fc5-4bfe5df621f4", pond_label:"5",  "co2": 16521, "intensity": 2.63, feed: 78, energy: 16, seed:	1, lupa: 6},
{ farm_id: "d2c7bf74-1edb-4e45-a65b-e10c72a93b15", pond_id: "5eb7f835-af85-48d0-b7a8-df38bf4f937d", pond_label:"6",  "co2": 17015, "intensity": 2.92, feed: 73, energy: 20, seed:	1, lupa: 7},
{ farm_id: "d2c7bf74-1edb-4e45-a65b-e10c72a93b15", pond_id: "ef0a310f-0f8e-49bb-835f-5fc777ae73ad", pond_label:"7",  "co2": 21896, "intensity": 2.93, feed: 74, energy: 25, seed:	1, lupa: 9},
{ farm_id: "d2c7bf74-1edb-4e45-a65b-e10c72a93b15", pond_id: "33dd8459-3a57-4613-88a9-77586ca570be", pond_label:"8",  "co2": 11271, "intensity": 2.94, feed: 74, energy: 12, seed:	0, lupa: 5},
{ farm_id: "d2c7bf74-1edb-4e45-a65b-e10c72a93b15", pond_id: "59da7701-0536-4032-ba9d-a8674c515038", pond_label:"9",  "co2": 11154, "intensity": 2.97, feed: 75, energy: 12, seed:	0, lupa: 5},
{ farm_id: "d2c7bf74-1edb-4e45-a65b-e10c72a93b15", pond_id: "234fe5f6-b21a-4625-9c01-88a914d9c96b", pond_label:"10", "co2": 12433, "intensity": 3.21, feed: 76, energy: 12, seed:	0, lupa: 5},
{ farm_id: "d2c7bf74-1edb-4e45-a65b-e10c72a93b15", pond_id: "f6f892dc-80d7-4d84-8530-a5584fedd669", pond_label:"11", "co2": 12163, "intensity": 3.07, feed: 76, energy: 13, seed:	0, lupa: 5},
{ farm_id: "d2c7bf74-1edb-4e45-a65b-e10c72a93b15", pond_id: "eed8a3ff-4768-486e-a230-0e2a9d40e9d4", pond_label:"12", "co2": 12324, "intensity": 2.77, feed: 76, energy: 12, seed:	0, lupa: 5},
{ farm_id: "d2c7bf74-1edb-4e45-a65b-e10c72a93b15", pond_id: "6fb4f97f-81f3-4f5f-a96b-f5d831483b1f", pond_label:"13", "co2": 11401, "intensity": 2.57, feed: 74, energy: 12, seed:	0, lupa: 5},
{ farm_id: "d2c7bf74-1edb-4e45-a65b-e10c72a93b15", pond_id: "7ae88aa1-2625-4742-ae35-759ebb1f603e", pond_label:"14", "co2": 23037, "intensity": 2.68, feed: 73, energy: 27, seed:	1, lupa: 9},
{ farm_id: "d2c7bf74-1edb-4e45-a65b-e10c72a93b15", pond_id: "ba2c73a4-f36e-4723-b5f2-9f037bf00be2", pond_label:"15", "co2": 11415, "intensity": 3.42, feed: 76, energy: 12, seed:	1, lupa: 5},
{ farm_id: "d2c7bf74-1edb-4e45-a65b-e10c72a93b15", pond_id: "40a30e2d-1305-492c-9375-6fecc7823c56", pond_label:"16", "co2": 12839, "intensity": 2.88, feed: 77, energy: 13, seed:	1, lupa: 5},
{ farm_id: "d2c7bf74-1edb-4e45-a65b-e10c72a93b15", pond_id: "8506a183-6e42-4c71-a369-8951c97d54b1", pond_label:"17", "co2": 12796, "intensity": 2.71, feed: 75, energy: 14, seed:	1, lupa: 5},
{ farm_id: "d2c7bf74-1edb-4e45-a65b-e10c72a93b15", pond_id: "0a3bbdab-f978-473d-b0d8-1b21d1a2bb90", pond_label:"18", "co2": 12993, "intensity": 2.80, feed: 76, energy: 13, seed:	1, lupa: 5},
{ farm_id: "d2c7bf74-1edb-4e45-a65b-e10c72a93b15", pond_id: "de5033ff-40ab-4661-bde5-198f75c6e776", pond_label:"19", "co2": 14159, "intensity": 3.00, feed: 74, energy: 16, seed:	1, lupa: 6},
{ farm_id: "d2c7bf74-1edb-4e45-a65b-e10c72a93b15", pond_id: "345dcac9-33e8-49c6-87da-2bdd6d9f7360", pond_label:"20", "co2": 14066, "intensity": 3.05, feed: 75, energy: 15, seed:	1, lupa: 6},
{ farm_id: "d2c7bf74-1edb-4e45-a65b-e10c72a93b15", pond_id: "364b225e-ab3a-45ee-9adc-fc858340e148", pond_label:"21", "co2": 17651, "intensity": 3.05, feed: 74, energy: 20, seed:	1, lupa: 7},
{ farm_id: "d2c7bf74-1edb-4e45-a65b-e10c72a93b15", pond_id: "3bc139cb-c403-4b2f-9e20-4362a4d57150", pond_label:"22", "co2": 18297, "intensity": 3.25, feed: 76, energy: 19, seed:	1, lupa: 7},
{ farm_id: "d2c7bf74-1edb-4e45-a65b-e10c72a93b15", pond_id: "6c21d56b-29ed-48bd-a3fa-029d3a7b0d39", pond_label:"23", "co2": 20652, "intensity": 3.34, feed: 69, energy: 28, seed:	1, lupa: 10},
{ farm_id: "d2c7bf74-1edb-4e45-a65b-e10c72a93b15", pond_id: "fb2c15c9-c795-434d-a1d6-ef782a1581c7", pond_label:"24", "co2": 13289, "intensity": 3.08, feed: 60, energy: 23, seed:	1, lupa: 8},
{ farm_id: "d2c7bf74-1edb-4e45-a65b-e10c72a93b15", pond_id: "177681e6-a882-4a60-93cb-b8e429f3cafa", pond_label:"25", "co2": 15226, "intensity": 3.07, feed: 74, energy: 17, seed:	1, lupa: 6},
{ farm_id: "d2c7bf74-1edb-4e45-a65b-e10c72a93b15", pond_id: "660e5f85-4118-4d27-9150-e09498000bb4", pond_label:"26", "co2": 14769, "intensity": 3.90, feed: 70, energy: 20, seed:	1, lupa: 7},
{ farm_id: "d2c7bf74-1edb-4e45-a65b-e10c72a93b15", pond_id: "6c31269a-8ede-402e-b596-02a0e1ad3f60", pond_label:"27", "co2": 24599, "intensity": 3.62, feed: 70, energy: 33, seed:	1, lupa: 11},
{ farm_id: "d2c7bf74-1edb-4e45-a65b-e10c72a93b15", pond_id: "d7e48789-b14c-4a3c-bcc8-23f18b138778", pond_label:"28", "co2": 18063, "intensity": 3.18, feed: 58, energy: 35, seed:	1, lupa: 10},
{ farm_id: "d2c7bf74-1edb-4e45-a65b-e10c72a93b15", pond_id: "2da86195-f2c1-40ec-b560-4b3925bfd67f", pond_label:"29", "co2": 23678, "intensity": 3.23, feed: 65, energy: 37, seed:	1, lupa: 12},
{ farm_id: "d2c7bf74-1edb-4e45-a65b-e10c72a93b15", pond_id: "dd78f9a1-1ca6-4bdb-aa05-2699a15f8352", pond_label:"30", "co2": 23816, "intensity": 3.29, feed: 65, energy: 37, seed:	1, lupa: 12},
{ farm_id: "d2c7bf74-1edb-4e45-a65b-e10c72a93b15", pond_id: "3e5945ad-5e4b-4058-8a0e-90c51635c31c", pond_label:"31", "co2": 24272, "intensity": 3.51, feed: 67, energy: 36, seed:	1, lupa: 12},
{ farm_id: "d2c7bf74-1edb-4e45-a65b-e10c72a93b15", pond_id: "11e715a3-a698-4cbf-8261-54989c93db3d", pond_label:"32", "co2": 23512, "intensity": 3.65, feed: 66, energy: 36, seed:	1, lupa: 12},
{ farm_id: "d2c7bf74-1edb-4e45-a65b-e10c72a93b15", pond_id: "34928b3f-e19d-415c-a465-bb213c59ecee", pond_label:"33", "co2": 24334, "intensity": 3.44, feed: 67, energy: 36, seed:	1, lupa: 12},
{ farm_id: "d2c7bf74-1edb-4e45-a65b-e10c72a93b15", pond_id: "10646323-1156-4070-aa67-e52986016632", pond_label:"34", "co2": 17969, "intensity": 4.40, feed: 56, energy: 37, seed:	1, lupa: 10},
{ farm_id: "d2c7bf74-1edb-4e45-a65b-e10c72a93b15", pond_id: "aee18ae9-4eb0-4d09-a9fd-3671ef032753", pond_label:"35", "co2": 17922, "intensity": 3.97, feed: 56, energy: 37, seed:	1, lupa: 10},
{ farm_id: "d2c7bf74-1edb-4e45-a65b-e10c72a93b15", pond_id: "ef67a3b3-1014-4aa8-a76d-59cb00d17c30", pond_label:"36", "co2": 12993, "intensity": 2.80, feed: 76, energy: 13, seed:	1, lupa: 5},
{ farm_id: "d2c7bf74-1edb-4e45-a65b-e10c72a93b15", pond_id: "1044e024-e7b0-4c57-a36c-02cbb8a9b82d", pond_label:"37", "co2": 14769, "intensity": 3.90, feed: 70, energy: 20, seed:	1, lupa: 7},

// ===== Kopaggio Demo DO 2024 Sept
{ farm_id: "bb09d3e7-1a12-4cdc-be15-cc4e434f50a0", pond_id: "079d65b6-293c-4f0d-97d3-1d53b0ad89d3", pond_label:"1",  "co2": 14045, "intensity": 3.08, feed: 66, energy: 25, seed:	1, lupa: 9},
{ farm_id: "bb09d3e7-1a12-4cdc-be15-cc4e434f50a0", pond_id: "8753cce5-a91a-4183-8f47-168ce21fedfa", pond_label:"2",  "co2": 16584, "intensity": 3.12, feed: 71, energy: 21, seed:	1, lupa: 7},
{ farm_id: "bb09d3e7-1a12-4cdc-be15-cc4e434f50a0", pond_id: "a18a6a64-46f2-4eb7-8e38-279347cecabf", pond_label:"3",  "co2": 17540, "intensity": 2.78, feed: 73, energy: 20, seed:	1, lupa: 7},
{ farm_id: "bb09d3e7-1a12-4cdc-be15-cc4e434f50a0", pond_id: "c8207066-b4c6-406d-9484-672f89c8b783", pond_label:"4",  "co2": 17445, "intensity": 3.09, feed: 75, energy: 18, seed:	1, lupa: 7},
{ farm_id: "bb09d3e7-1a12-4cdc-be15-cc4e434f50a0", pond_id: "846b900e-e333-44bd-af7c-2b2409f9e7f0", pond_label:"5",  "co2": 16521, "intensity": 2.63, feed: 78, energy: 16, seed:	1, lupa: 6},
{ farm_id: "bb09d3e7-1a12-4cdc-be15-cc4e434f50a0", pond_id: "a9453332-c604-43c4-97f2-f9f5f33dd569", pond_label:"6",  "co2": 17015, "intensity": 2.92, feed: 73, energy: 20, seed:	1, lupa: 7},
{ farm_id: "bb09d3e7-1a12-4cdc-be15-cc4e434f50a0", pond_id: "f90b2138-3e95-4b86-9f6c-c1afabcb9251", pond_label:"7",  "co2": 21896, "intensity": 2.93, feed: 74, energy: 25, seed:	1, lupa: 9},
{ farm_id: "bb09d3e7-1a12-4cdc-be15-cc4e434f50a0", pond_id: "a988201b-962e-42d0-910c-b09750a6e289", pond_label:"8",  "co2": 11271, "intensity": 2.94, feed: 74, energy: 12, seed:	0, lupa: 5},
{ farm_id: "bb09d3e7-1a12-4cdc-be15-cc4e434f50a0", pond_id: "6688c7be-3d24-485a-982b-04b0f090da7a", pond_label:"9",  "co2": 11154, "intensity": 2.97, feed: 75, energy: 12, seed:	0, lupa: 5},
{ farm_id: "bb09d3e7-1a12-4cdc-be15-cc4e434f50a0", pond_id: "fb0c1e39-8dd3-4ebb-ac0a-8ef6e7f237d0", pond_label:"10", "co2": 12433, "intensity": 3.21, feed: 76, energy: 12, seed:	0, lupa: 5},
{ farm_id: "bb09d3e7-1a12-4cdc-be15-cc4e434f50a0", pond_id: "4523ddb5-047d-4409-af3f-c637da76bcd4", pond_label:"11", "co2": 12163, "intensity": 3.07, feed: 76, energy: 13, seed:	0, lupa: 5},
{ farm_id: "bb09d3e7-1a12-4cdc-be15-cc4e434f50a0", pond_id: "49686668-61b5-42f7-920e-b32be327fca8", pond_label:"12", "co2": 12324, "intensity": 2.77, feed: 76, energy: 12, seed:	0, lupa: 5},
{ farm_id: "bb09d3e7-1a12-4cdc-be15-cc4e434f50a0", pond_id: "aceffb6c-f940-4a89-96dd-1c30ff92bf49", pond_label:"13", "co2": 11401, "intensity": 2.57, feed: 74, energy: 12, seed:	0, lupa: 5},
{ farm_id: "bb09d3e7-1a12-4cdc-be15-cc4e434f50a0", pond_id: "bc1c689a-b8d8-41ca-9af9-597214da681d", pond_label:"14", "co2": 23037, "intensity": 2.68, feed: 73, energy: 27, seed:	1, lupa: 9},
{ farm_id: "bb09d3e7-1a12-4cdc-be15-cc4e434f50a0", pond_id: "14b0d95f-0d07-430c-98b5-d45b4feb7f44", pond_label:"15", "co2": 11415, "intensity": 3.42, feed: 76, energy: 12, seed:	1, lupa: 5},
{ farm_id: "bb09d3e7-1a12-4cdc-be15-cc4e434f50a0", pond_id: "d5123b57-930e-4532-8da4-ac65d245ce7b", pond_label:"16", "co2": 12839, "intensity": 2.88, feed: 77, energy: 13, seed:	1, lupa: 5},
{ farm_id: "bb09d3e7-1a12-4cdc-be15-cc4e434f50a0", pond_id: "d2dbe9a0-b92e-4bd4-9080-324f25913513", pond_label:"17", "co2": 12796, "intensity": 2.71, feed: 75, energy: 14, seed:	1, lupa: 5},
{ farm_id: "bb09d3e7-1a12-4cdc-be15-cc4e434f50a0", pond_id: "c0d34239-ba2a-4b90-ab65-968024ce1662", pond_label:"18", "co2": 12993, "intensity": 2.80, feed: 76, energy: 13, seed:	1, lupa: 5},
{ farm_id: "bb09d3e7-1a12-4cdc-be15-cc4e434f50a0", pond_id: "23346aaa-9f78-4c8d-b8e5-46a5dba8ec5d", pond_label:"19", "co2": 14159, "intensity": 3.00, feed: 74, energy: 16, seed:	1, lupa: 6},
{ farm_id: "bb09d3e7-1a12-4cdc-be15-cc4e434f50a0", pond_id: "7683e2a6-4864-4a96-9197-8e4f3f848c04", pond_label:"20", "co2": 14066, "intensity": 3.05, feed: 75, energy: 15, seed:	1, lupa: 6},
{ farm_id: "bb09d3e7-1a12-4cdc-be15-cc4e434f50a0", pond_id: "e19c5072-9d87-4a36-a604-e7cef05ce0eb", pond_label:"21", "co2": 17651, "intensity": 3.05, feed: 74, energy: 20, seed:	1, lupa: 7},
{ farm_id: "bb09d3e7-1a12-4cdc-be15-cc4e434f50a0", pond_id: "ee9292b7-9052-41aa-8777-c2f27db267ec", pond_label:"22", "co2": 18297, "intensity": 3.25, feed: 76, energy: 19, seed:	1, lupa: 7},
{ farm_id: "bb09d3e7-1a12-4cdc-be15-cc4e434f50a0", pond_id: "d44818ad-7d59-4aa4-adfe-07782200b034", pond_label:"23", "co2": 20652, "intensity": 3.34, feed: 69, energy: 28, seed:	1, lupa: 10},
{ farm_id: "bb09d3e7-1a12-4cdc-be15-cc4e434f50a0", pond_id: "a256a9ca-761d-4622-8a6f-1fef31e94dd9", pond_label:"24", "co2": 13289, "intensity": 3.08, feed: 60, energy: 23, seed:	1, lupa: 8},
{ farm_id: "bb09d3e7-1a12-4cdc-be15-cc4e434f50a0", pond_id: "28408b23-4d55-4986-8f32-457e7a22de08", pond_label:"25", "co2": 15226, "intensity": 3.07, feed: 74, energy: 17, seed:	1, lupa: 6},
{ farm_id: "bb09d3e7-1a12-4cdc-be15-cc4e434f50a0", pond_id: "74373419-0b9e-4695-a399-94371c95fb18", pond_label:"26", "co2": 14769, "intensity": 3.90, feed: 70, energy: 20, seed:	1, lupa: 7},
{ farm_id: "bb09d3e7-1a12-4cdc-be15-cc4e434f50a0", pond_id: "5ef92104-48c4-4c7a-b4af-850adc00c87e", pond_label:"27", "co2": 24599, "intensity": 3.62, feed: 70, energy: 33, seed:	1, lupa: 11},
{ farm_id: "bb09d3e7-1a12-4cdc-be15-cc4e434f50a0", pond_id: "7f0c0322-1d89-43ed-bd0d-8cf04dcaf368", pond_label:"28", "co2": 18063, "intensity": 3.18, feed: 58, energy: 35, seed:	1, lupa: 10},
{ farm_id: "bb09d3e7-1a12-4cdc-be15-cc4e434f50a0", pond_id: "65ba3b52-10da-4347-9d6e-f9fc6aacb79f", pond_label:"29", "co2": 23678, "intensity": 3.23, feed: 65, energy: 37, seed:	1, lupa: 12},
{ farm_id: "bb09d3e7-1a12-4cdc-be15-cc4e434f50a0", pond_id: "8f4e4012-db8b-43db-8b42-e2b3024b6e74", pond_label:"30", "co2": 23816, "intensity": 3.29, feed: 65, energy: 37, seed:	1, lupa: 12},
{ farm_id: "bb09d3e7-1a12-4cdc-be15-cc4e434f50a0", pond_id: "fbc51a09-1aec-4061-9259-65cea7874c44", pond_label:"31", "co2": 24272, "intensity": 3.51, feed: 67, energy: 36, seed:	1, lupa: 12},
{ farm_id: "bb09d3e7-1a12-4cdc-be15-cc4e434f50a0", pond_id: "0ef5120b-2564-4bb5-a379-3568861efe22", pond_label:"32", "co2": 23512, "intensity": 3.65, feed: 66, energy: 36, seed:	1, lupa: 12},
{ farm_id: "bb09d3e7-1a12-4cdc-be15-cc4e434f50a0", pond_id: "c8e327af-c7d5-40b9-b846-778200553fc5", pond_label:"33", "co2": 24334, "intensity": 3.44, feed: 67, energy: 36, seed:	1, lupa: 12},
{ farm_id: "bb09d3e7-1a12-4cdc-be15-cc4e434f50a0", pond_id: "96e0b430-739e-47bb-a566-e3e23673207d", pond_label:"34", "co2": 17969, "intensity": 4.40, feed: 56, energy: 37, seed:	1, lupa: 10},
{ farm_id: "bb09d3e7-1a12-4cdc-be15-cc4e434f50a0", pond_id: "963a745b-95c6-4dea-bba4-00e84228b9be", pond_label:"35", "co2": 17922, "intensity": 3.97, feed: 56, energy: 37, seed:	1, lupa: 10},
{ farm_id: "bb09d3e7-1a12-4cdc-be15-cc4e434f50a0", pond_id: "09267da5-7e77-42c9-a0fa-d9d9121254c2", pond_label:"36", "co2": 12993, "intensity": 2.80, feed: 76, energy: 13, seed:	1, lupa: 5},
{ farm_id: "bb09d3e7-1a12-4cdc-be15-cc4e434f50a0", pond_id: "eac4477a-1f1d-45ff-bb78-866639c47414", pond_label:"37", "co2": 14769, "intensity": 3.90, feed: 70, energy: 20, seed:	1, lupa: 7},

]

export const fetchEmissionData = ({ pondId, farmId }) => {
  if (farmId) {
    return _.filter(emissionData, { farm_id: farmId })
  }
  if (pondId) {
    return _.find(emissionData, { pond_id: pondId })
  }
  return null
}