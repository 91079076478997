// -- Bom Data Center Index |  DamDashboard.js
const BOMINDEX = [
  {
    id: 367087010,
    stationName: "Dartmouth Storage Volume",
    capacity: 3856232,
  },
  {
    id: 184821010,
    stationName: "Blowering Storage Volume",
    capacity: 1609859,
  },
  {
    id: 185039010,
    stationName: "Burrinjuck Storage Volume",
    capacity: 1023036,
  },
  {
    id: 367561010,
    stationName: "Cairn Curran Storage Volume",
    capacity: 146830,
  },
  { id: 368068010, stationName: "Eildon Storage Volume", capacity: 3249914 },
  {
    id: 324992010,
    stationName: "Eucumbene Storage Volume",
    capacity: 4366900,
  },
  { id: 325408010, stationName: "Geehi Storage Volume", capacity: 13040 },
  { id: 145368010, stationName: "Hume Storage Volume", capacity: 3036393 },
  {
    id: 325068010,
    stationName: "Jindabyne Storage Volume",
    capacity: 388990,
  },
  {
    id: 316848010,
    stationName: "Lake Victoria Storage Volume",
    capacity: 576579,
  },
  { id: 170392010, stationName: "Menindee Storage Volume", capacity: 568628 },
  { id: 325822010, stationName: "Talbingo Storage Volume", capacity: 160280 },
  {
    id: 325746010,
    stationName: "Tantangara Storage Volume",
    capacity: 238768,
  },
  { id: 289303010, stationName: "Thomson Storage Volume", capacity: 1068000 },
  {
    id: 170311010,
    stationName: "Wetherell Storage Volume",
    capacity: 192656,
  },
  {
    id: 185362010,
    stationName: "Wyangala Storage Volume",
    capacity: 1217035,
  },
  { id: 367255010, stationName: "Yarrawonga Storage Volume", capacity: 95174 },
];

// -- Euroco Water Price Index
const WATERMARKETINDEX = [
  {
    id: 1028,
    type: "allc",
    subType: "allc_vic",
    geo: "VIC",
    name: "VIC Murray (Below Choke)_Zone 7",
  },
  {
    id: 1027,
    type: "allc",
    subType: "allc_vic",
    geo: "VIC",
    name: "VIC Murray (Above Choke)_Zone 6",
  },
  {
    id: 1029,
    type: "allc",
    subType: "allc_vic",
    geo: "VIC",
    name: "VIC Goulburn_Zone 1A",
  },
  {
    id: 1030,
    type: "allc",
    subType: "allc_vic",
    geo: "VIC",
    name: "VIC Goulburn_Zone 1B",
  },
  {
    id: 1007,
    type: "allc",
    subType: "allc_nsw",
    geo: "NSW",
    name: "NSW Murrumbidgee_Zone 13 - Private Diverter",
  },
  {
    id: 1009,
    type: "allc",
    subType: "allc_nsw",
    geo: "NSW",
    name: "NSW Murrumbidgee_Zone 13 - Murrumbidgee Irrigation Area",
  },
  {
    id: 1010,
    type: "allc",
    subType: "allc_nsw",
    geo: "NSW",
    name: "NSW Murrumbidgee_Zone 13 - Coleambally Irrigation",
  },
  {
    id: 1012,
    type: "allc",
    subType: "allc_nsw",
    geo: "NSW",
    name: "NSW Murray (Below Choke)_Zone 11 - Private Diverter",
  },
  {
    id: 1014,
    type: "allc",
    subType: "allc_nsw",
    geo: "NSW",
    name: "NSW Murray (Below Choke)_Zone 11 - Western Murray Irrigation",
  },
  {
    id: 1020,
    type: "allc",
    subType: "allc_nsw",
    geo: "NSW",
    name: "NSW Murray (Above Choke)_Zone 10 - Private Diverter",
  },
  {
    id: 1021,
    type: "allc",
    subType: "allc_nsw",
    geo: "NSW",
    name: "NSW Murray (Above Choke)_Zone 10 - Murray Irrigation Limited",
  },
  {
    id: 1024,
    type: "allc",
    subType: "allc_sa",
    geo: "SA",
    name: "SA River Murray_Zone 12 - Private Diverter",
  },
  {
    id: 1025,
    type: "allc",
    subType: "allc_sa",
    geo: "SA",
    name: "SA River Murray_Zone 12 - Central Irrigation Trust",
  },
  {
    id: 3168,
    type: "ent",
    subType: "ent_h",
    geo: "VIC",
    name: "VIC Murray (Below Choke) - Zone 7 - High Reliability",
  },
  {
    id: 3169,
    type: "ent",
    subType: "ent_l",
    geo: "VIC",
    name: "VIC Murray (Below Choke) - Zone 7 - Low Reliability",
  },
  {
    id: 3166,
    type: "ent",
    subType: "ent_h",
    geo: "VIC",
    name: "VIC Murray (Above Choke) - Zone 6 - High Reliability",
  },
  {
    id: 3167,
    type: "ent",
    subType: "ent_l",
    geo: "VIC",
    name: "VIC Murray (Above Choke) - Zone 6 - Low Reliability",
  },
  {
    id: 3156,
    type: "ent",
    subType: "ent_h",
    geo: "VIC",
    name: "VIC Goulburn - Zone 1A - High Reliability",
  },
  {
    id: 3157,
    type: "ent",
    subType: "ent_l",
    geo: "VIC",
    name: "VIC Goulburn - Zone 1A - Low Reliability",
  },
  {
    id: 3101,
    type: "ent",
    subType: "ent_h",
    geo: "NSW",
    name: "NSW Murray (Below Choke) - Zone 11 - High Reliability",
  },
  {
    id: 3100,
    type: "ent",
    subType: "ent_l",
    geo: "NSW",
    name: "NSW Murray (Below Choke) - Zone 11 - General Reliability",
  },
  {
    id: 3144,
    type: "ent",
    subType: "ent_h",
    geo: "SA",
    name: "SA River Murray - Zone 12 - Class 3A",
  },
  {
    id: 3143,
    type: "ent",
    subType: "ent_l",
    geo: "SA",
    name: "SA River Murray - Zone 12 - Class 1",
  },
  {
    id: 3104,
    type: "ent",
    subType: "ent_h",
    geo: "NSW",
    name: "NSW Murrumbidgee - Zone 13 - High Reliability",
  },
  {
    id: 3103,
    type: "ent",
    subType: "ent_l",
    geo: "NSW",
    name: "NSW Murrumbidgee - Zone 13 - General Reliability",
  },
];

const DATAINDEX = {
  bom: BOMINDEX,
  water: WATERMARKETINDEX,
};

export { DATAINDEX };
