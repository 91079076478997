import { PondManagerServices } from "api/pondManagerServices";
import AppLocalStorage from "helpers/LocalStorage";
import _ from "lodash";

const appStorage = new AppLocalStorage();

const initState = {
  orgs: [],
  userOrgs: [],
  viewAsOrg: {
    id: null,
  },
  viewFarms: [],
};

const organisationManagementTypes = {
  LOAD_ORGS: "LOAD_ORGS",
  LOAD_USER_ORGS: "LOAD_USER_ORGS",
  SELECT_VIEW_ORG: "SELECT_VIEW_ORG",
};

const organisationManagementReducers = (state = initState, action) => {
  switch (action.type) {
    case organisationManagementTypes.LOAD_ORGS:
      return {
        ...state,
        orgs: action.payload.orgs,
      };
    case organisationManagementTypes.LOAD_USER_ORGS:
      return {
        ...state,
        userOrgs: action.payload.userOrgs,
      };
    case organisationManagementTypes.SELECT_VIEW_ORG:
      return {
        ...state,
        viewAsOrg: action.payload.viewAsOrg,
        viewFarms: action.payload.viewFarms,
      };
    default:
      return {
        ...state,
      };
  }
};

export const organisationManagementActions = {
  loadOrgs: async (dispatch) => {
    const orgs = await PondManagerServices.getOrgs();
    dispatch({
      type: organisationManagementTypes.LOAD_ORGS,
      payload: {
        orgs: orgs,
      },
    });
  },
  loadUserOrgs: async (dispatch) => {
    let userOrgs = await PondManagerServices.getUserOrgs();
    dispatch({
      type: organisationManagementTypes.LOAD_USER_ORGS,
      payload: {
        userOrgs: [...userOrgs.owned, ...userOrgs.member],
      },
    });
  },
  onSelectViewAsOrg: async (dispatch, orgId) => {
    if (orgId) {
      appStorage.saveOrgId(orgId);
      const farms = await PondManagerServices.getUserOrgFarms(orgId);
      dispatch({
        type: organisationManagementTypes.SELECT_VIEW_ORG,
        payload: {
          viewAsOrg: {
            id: orgId,
          },
          viewFarms: farms,
        },
      });
    } else {
      appStorage.saveOrgId(null);
      dispatch({
        type: organisationManagementTypes.SELECT_VIEW_ORG,
        payload: {
          viewAsOrg: { id: null },
          viewFarms: [],
        },
      });
    }
  },
};

export default organisationManagementReducers;
