import SideMenuLayout from "screens/Aquaculture/components/Layout/SideMenuLayout";
import { DashboardIcon } from "components/Icons/MaterialIcons";
import Text from "components/text/Text";
import PondForecastMk2 from "screens/Aquaculture/components/Forecast/PondForecastMk2";
import AlertBox from "components/Alerts/AlertBox";
import { Box } from "@mui/material";
import PondManagerAccessWrapper from "components/Wrapper/PondManagerAccessWrapper";
import { ResourcePolicies } from "screens/Aquaculture/components/AccessControl/ResourcePolicies";

const ForecastHome = ({ pond, farm, group }) => {
  const MENULIST = [
    {
      label: <Text>interface.general.overview</Text>,
      description: "",
      value: 0,
      icon: <DashboardIcon />,
      component: (
        <PondManagerAccessWrapper policies={[ResourcePolicies.FORECAST]}>
          {pond ? (
            <PondForecastMk2 pond={pond} farm={farm} />
          ) : (
            <Box p={2}>
              <AlertBox title={"Farm Level is currently not supported"}></AlertBox>
            </Box>
          )}
        </PondManagerAccessWrapper>
      ),
    },
  ];

  return <SideMenuLayout menulist={MENULIST} title={<Text>interface.general.forecast</Text>} defaultViewMode={0} />;
};

export default ForecastHome;
