import { Box, Stack, Tooltip, Typography, colors } from "@mui/material";
import { _ } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import CommonDialog, { CommonDialogIds } from "components/Dialogs/CommonDialog";
import { DeleteIcon, EditNoteIcon, PreviewIcon, ViewComfyIcon } from "components/Icons/MaterialIcons";
import CommonModal from "components/Modal/CommonModal";
import { MuiBorderIconButton } from "components/buttons/MuiButtonVariants";
import Text, { getText } from "components/text/Text";
import Formatters from "helpers/Formatters";
import NumericFormatters from "helpers/NumericFormatters";
import { upperCase } from "lodash";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { dialogReducerActions } from "redux/dialog";
import { modalIds, modalReducersActions } from "redux/modal";
import FarmingProductPriceView from "screens/Aquaculture/components/Farm/components/FarmingProductPriceView";
import ProductCard from "screens/Aquaculture/components/FarmingProduct/components/ProductCard";
import FarmingProductPreview from "screens/Aquaculture/components/FarmingProduct/components/ProductPreview";

const FarmingProductRegistryTable = ({ farm, products, actions }) => {
  const [productToDelete, setProductToDelete] = useState(null);
  const [productToViewPrice, setProductToViewPrice] = useState(null);
  const [productPreview, setProductPreview] = useState(null);

  const dispatch = useDispatch();

  const Actions = {
    // on confirm to deregister a product
    onDeleteConfirm: (product) => {
      setProductToDelete(product);
      dialogReducerActions.openCommonDialog(dispatch, { id: CommonDialogIds.PRODUCT_DELETE_CONFIRM });
    },
    // execute product deregitration
    onDelete: () => {
      actions?.onDeregisterProduct(productToDelete);
    },
    onViewProductPrice: (product) => {
      setProductToViewPrice(product);
      modalReducersActions.openModal(dispatch, { id: modalIds.FARMING_PRODUCT_PRICE_VIEW });
    },
    onPreviewProduct: (product) => {
      setProductPreview(product);
      modalReducersActions.openModal(dispatch, { id: modalIds.FARMING_PRODUCT_PREVIEW });
    },
  };

  const [colsDef, setColsDef] = useState([
    {
      field: "id",
      headerName: "ID",
      width: 60,
      valueFormatter: ({ value }) => `#${value}`,
      cellStyle: {
        color: colors.grey[500],
      },
    },
    {
      flex: 1,
      field: "name",
      headerName: getText("interface.general.product"),
      cellRenderer: ({ value, data }) => {
        return (
          <Tooltip title={<ProductCard product={data} />} placement="top">
            {value}
          </Tooltip>
        );
      },
    },
    {
      flex: 1,
      field: "product_type",
      headerName: getText("interface.general.product-type"),
      cellRenderer: Formatters.Special.formatFarmingProductType,
      width: 100,
    },
    {
      flex: 1,
      field: "producer_name",
      headerName: getText("interface.general.producer"),
    },
    {
      flex: 1,
      field: "latest_price",
      headerName: getText("interface.general.unit-price"),
      cellRenderer: ({ data, value }) => {
        return (
          <Stack direction={"row"} spacing={0.5} alignItems={"center"}>
            <MuiBorderIconButton size="sm" icon={<EditNoteIcon />} onClick={() => Actions.onViewProductPrice(data)} />
            <Typography fontSize={11}>
              {NumericFormatters.formatDollar({ value, symbol: Formatters.Text.formatCurrencySymbol({ code: farm?.settings?.currency }), decimalScale: 2 })} / {upperCase(data?.net_weight_unit)}
            </Typography>
          </Stack>
        );
      },
    },
    {
      flex: 1,
      field: "net_Weight",
      headerName: getText("interface.general.net-weight") + "/" + getText("interface.general.volume"),
      cellRenderer: ({ data }) => {
        return `${data?.net_weight || "-"} ${data?.net_weight_unit || "-"}`;
      },
    },
    {
      flex: 1,
      headerName: "",
      cellRenderer: ({ data }) => {
        return (
          <Stack direction={"row"} spacing={0.5}>
            <MuiBorderIconButton
              size="sm"
              onClick={() => {
                Actions.onDeleteConfirm(data);
              }}
              icon={<DeleteIcon />}
            />
            <MuiBorderIconButton
              size="sm"
              onClick={() => {
                Actions.onPreviewProduct(data);
              }}
              icon={<PreviewIcon />}
            />
          </Stack>
        );
      },
      width: 80,
    },
  ]);

  const tableRows = products;

  return (
    <Box className="ag-theme-shrimpl" height={800}>
      <CommonModal id={modalIds.FARMING_PRODUCT_PRICE_VIEW} size="large" title={"Record product price"}>
        {productToViewPrice && <FarmingProductPriceView product={productToViewPrice} />}
      </CommonModal>
      <CommonModal id={modalIds.FARMING_PRODUCT_PREVIEW} size="large">
        {productPreview && <FarmingProductPreview product={productPreview} />}
      </CommonModal>
      <CommonDialog id={CommonDialogIds.PRODUCT_DELETE_CONFIRM} title={<Text>interface.alert.alert-are-you-sure</Text>} action={Actions.onDelete} />
      <AgGridReact
        defaultColDef={{
          cellStyle: { display: "flex", alignItems: "center" },
        }}
        rowData={tableRows}
        columnDefs={colsDef}
        rowHeight={35}
        pagination={true}
        paginationPageSize={20}
        paginationPageSizeSelector={[10, 20, 50, 100]}
      />
    </Box>
  );
};

export default FarmingProductRegistryTable;
