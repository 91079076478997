import { ArrowBack } from "@mui/icons-material";
import { Box, Button, Card, CardContent } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { ROUTE_POND_MANAGER_HOME_PAGE } from "routes/paths";
import _ from "lodash";
import Text from "components/text/Text";
import FarmProfileForm from "screens/Aquaculture/components/Farm/FarmProfileForm";

const PondManagerFarmEditPage = () => {
  return (
    <Box p={2}>
      <Box mb={2}>
        <Button startIcon={<ArrowBack />} component={Link} to={ROUTE_POND_MANAGER_HOME_PAGE}>
          <Text>interface.actions.return</Text>
        </Button>
      </Box>
      <Card>
        <CardContent>
          <FarmProfileForm />
        </CardContent>
      </Card>
    </Box>
  );
};

export default PondManagerFarmEditPage;
