import { Box, Stack, Typography } from "@mui/material";
import { theme } from "App";
import { ShrimplLogoSvg } from "components/Images";
import React from "react";
import { Fade } from "react-reveal";

const LoginPageLayout = ({ title, children }) => {
  return (
    <Box backgroundColor={theme.palette.primary.main}>
      <Stack
        height="100vh"
        width="100vw"
        justifyContent="center"
        alignItems="center"
      >
        <Box p={5} backgroundColor="white" borderRadius={1} width="400px">
          <Stack alignItems={"center"} spacing={1}>
            <Box>
              <img src={ShrimplLogoSvg} alt="" width="200px" />
            </Box>
          </Stack>
          <Box my={4}>
            <Fade left distance="1rem" duration={500}>
              <Typography variant="h5">{title}</Typography>
            </Fade>
          </Box>
          {children}
        </Box>
      </Stack>
    </Box>
  );
};

export default LoginPageLayout;
