import { Box, Stack, Typography } from "@mui/material";
import NumericFormatters from "helpers/NumericFormatters";
import _ from "lodash";
import React from "react";

const MapStatsBar = ({ data }) => {
  // number of features
  const numberOfFeatures = data?.features?.length;
  // total area of all features
  const totalArea = _.sumBy(data?.features || [], "properties.area");
  // average area of all features
  const avgArea = _.meanBy(data?.features || [], "properties.area");
  // polygon with water_clf = 1 ratio
  const activePercentage = data?.features?.filter((ele) => ele.properties.dt_water_clf === 1).length / data?.features?.length;

  return (
    <Box
      sx={{
        bgcolor: "#0000007b",
        px: 1,
        color: "#FFF",
      }}
    >
      <Stack direction={"row"} spacing={1} divider={<span style={{ fontSize: 13 }}>|</span>}>
        <Typography color="white" fontWeight={800} fontSize={10}>
          Qty: {numberOfFeatures}
        </Typography>
        <Typography color="white" fontWeight={800} fontSize={10}>
          Sum Area: {NumericFormatters.formatAreaInHa({ value: totalArea })}
        </Typography>
        <Typography color="white" fontWeight={800} fontSize={10}>
          Avg Area: {NumericFormatters.formatAreaInHa({ value: avgArea })}
        </Typography>
        <Typography color="white" fontWeight={800} fontSize={10}>
          Active%: {NumericFormatters.format({ value: activePercentage * 100, suffix: "%" })}
        </Typography>
      </Stack>
    </Box>
  );
};

export default MapStatsBar;
