import { Box, Button, Stack } from "@mui/material";

import { PondManagerServices } from "api/pondManagerServices";
import { VerticalAlertBoxCollections } from "components/Alerts/AlertBox";
import CommonDialog, { CommonDialogIds } from "components/Dialogs/CommonDialog";
import { AddCircleOutlineIcon } from "components/Icons/MaterialIcons";
import Text, { getText } from "components/text/Text";

import _ from "lodash";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppSelectors } from "redux/AppReducers";
import { alertsActions } from "redux/alerts";
import { dialogReducerActions } from "redux/dialog";
import { cycleManagementActions } from "redux/pondManagement/cycleManagement";
import { ResourcePolicies } from "screens/Aquaculture/components/AccessControl/ResourcePolicies";
import CycleManagementEditPanel from "screens/Aquaculture/components/CycleManager/CycleEditPanel";
import CycleTable from "screens/Aquaculture/components/CycleManager/components/CycleTable";
import { PondManagerLayoutStyles } from "screens/Aquaculture/components/PondManagerMapView";

const PondCycleManager = ({ pond, farm }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [cycle, setCycle] = useState(null);
  const [cycleToDelete, setCycleToDelete] = useState(null);

  useEffect(() => {
    if (cycle) {
      setShowEdit(true);
    } else {
      setShowEdit(false);
    }
  }, [cycle]);

  const dispatch = useDispatch();

  const cycleManagementStore = AppSelectors.cycleStore();
  const cycles = _.orderBy(cycleManagementStore.cycles[pond.id] || [], "start_date", "desc");

  const Actions = {
    onLoadCycles: () => {
      cycleManagementActions.loadAllCyclesByFarmId(dispatch, farm?.farm_id);
    },
    onEnterEdit: (cycle) => {
      // check if there's any active cycles
      const activeCycles = cycles.filter((ele) => !ele.ended);
      if (_.isEmpty(cycle) && activeCycles.length > 0) {
        alertsActions.addError(dispatch, {
          content: "You must end current Active cycle before you can create a new one",
        });
        return;
      }
      setCycle(cycle);
    },
    onExitEdit: () => {
      setShowEdit(false);
      setCycle(null);
    },
    onDeleteCycleConfirm: (cycle) => {
      dialogReducerActions.openCommonDialog(dispatch, { id: CommonDialogIds.CYCLE_DELETE_CONFIRM });
      setCycleToDelete(cycle);
    },
    onDeleteCycle: async () => {
      if (cycleToDelete) {
        const cycle = cycleToDelete;
        await PondManagerServices.deleteCycle(pond.id, cycle.id);
        alertsActions.addInfo(dispatch, { content: "Cycle is removed" });
        await cycleManagementActions.loadAllCyclesByFarmId(dispatch, farm.farm_id);
      }
    },
  };

  const editable = farm?.policies?.map((ele) => _.includes([ResourcePolicies["CYCLE:WRITE"].code, ResourcePolicies["ADMIN"].code], ele?.code));

  return (
    <Box sx={PondManagerLayoutStyles.rightPanel}>
      <CommonDialog id={CommonDialogIds.CYCLE_DELETE_CONFIRM} title={getText("interface.alert.alert-are-you-sure")} action={Actions.onDeleteCycle} />
      <Stack direction="row" justifyContent="space-between">
        <Box>
          {!showEdit && farm?.access_level_write && (
            <Button variant="contained" color="primary" startIcon={<AddCircleOutlineIcon />} onClick={() => Actions.onEnterEdit({})}>
              <Text>interface.actions.create</Text>
            </Button>
          )}
        </Box>
      </Stack>

      {showEdit && <CycleManagementEditPanel {...{ cycle, pond, farm, onExit: Actions.onExitEdit }} />}

      {!showEdit &&
        (cycles.length === 0 ? (
          <Box my={2}>
            <VerticalAlertBoxCollections.NoCycle />
          </Box>
        ) : (
          <Box>
            <Box mt={1}>
              <CycleTable cycles={cycles} onEdit={Actions.onEnterEdit} onDelete={Actions.onDeleteCycleConfirm} editable={editable} />
            </Box>
          </Box>
        ))}
    </Box>
  );
};

export default PondCycleManager;
