import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import { theme } from "App";
import { PondManagerServices } from "api/pondManagerServices";
import AlertBox, { NoCycleAlertBox } from "components/Alerts/AlertBox";
import MuiDatetimePicker from "components/DatePicker/MuiDatetimePicker";
import LoadingBox from "components/Loading/LoadingBox";
import ChartjsContainer from "components/chart/ChartjsV4Wrapper";
import { HelpOutlineIcon } from "components/Icons/MaterialIcons";
import Text, { getText } from "components/text/Text";
import { Form, Formik } from "formik";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { Fade } from "react-reveal";
import { AppSelectors } from "redux/AppReducers";
import palette from "themes/palette";
import Formatters from "helpers/Formatters";

const WaterQualityUploader = ({ farm, pond }) => {
  const [isloading, setloading] = useState(true);
  const [data, setdata] = useState({});

  const cycleManagementStore = AppSelectors.cycleStore();
  const selectedCycle = cycleManagementStore?.selectedCycle;
  const isCycleSelected = !_.isEmpty(selectedCycle);

  const pondManagementStore = AppSelectors.pondManagementStore();
  const wqFields = pondManagementStore.fields.filter((f) => f.field_group === "Water Quality");
  const fieldsMap = pondManagementStore.fieldsMap;

  useEffect(() => {
    if (wqFields && pond && isCycleSelected && selectedCycle?.pond_id === pond.id) {
      Actions.loadTimeserieses();
    }
  }, [pond, selectedCycle]);

  const Actions = {
    loadTimeserieses: async () => {
      setloading(true);
      const response = await PondManagerServices.getTimeseriesData(
        pond.id,
        wqFields.map((f) => f.field_id),
        selectedCycle.start_date,
        selectedCycle.end_date
      );
      setdata(response);
      setloading(false);
    },
    submitData: async (requestData) => {
      if (requestData.length > 0) {
        setloading(true);
        const response = await PondManagerServices.putData({
          rows: requestData,
        });
        Actions.loadTimeserieses();
      }
    },
  };

  const Renders = {
    renderGraph: ({ data, fieldId }) => {
      const stats = {
        currentValue: _.last(data.raw.filter((ele) => ele.value).filter((ele) => ele.field_id === fieldId)),
        timeseries: _.takeRight(
          data.raw.filter((ele) => ele.value).filter((ele) => ele.field_id === fieldId),
          30
        ),
      };

      const chartConfigs = {
        data: {
          labels: stats.timeseries.map((r) => r.datetime),
          datasets: [
            {
              label: fieldId,
              data: stats.timeseries.map((ele) => ele.value),
              backgroundColor: palette.secondary.main,
              borderRadius: 0,
              borderWidth: 0,
            },
          ],
        },
        type: "bar",
        options: {
          responsive: true,
          maintainAspectRatio: true,
          animation: {
            duration: 0,
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          scales: {
            x: {
              display: false,
            },
            y: {
              display: false,
            },
          },
        },
      };

      return (
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <Fade>
            <Box height={"30px"} width={"120px"} borderRadius={"6px 6px 0 0"} overflow={"hidden"} pt={1} bgcolor={palette.secondary.light}>
              <ChartjsContainer {...chartConfigs} />
            </Box>
          </Fade>
          <Box>
            <Typography fontWeight={600} fontSize={16}>
              {stats.currentValue ? <NumberFormat displayType="text" value={stats.currentValue.value} decimalScale={2} /> : "-"}
            </Typography>
            <Typography fontSize={11} color={"grey"} sx={{ mt: -0.6 }}>
              {Formatters.Time.toLocalDateTimeString(stats.currentValue?.datetime)}
            </Typography>
          </Box>
        </Stack>
      );
    },
  };

  if (!pond) {
    return (
      <Box p={2}>
        <AlertBox icon={<HelpOutlineIcon />}>
          <Text>interface.alert.please-select-a-pond</Text>!
        </AlertBox>
      </Box>
    );
  }

  if (!isCycleSelected) {
    return (
      <Box p={2}>
        <NoCycleAlertBox pondId={pond?.id} />
      </Box>
    );
  }

  return (
    <Box p={2}>
      {isloading ? (
        <LoadingBox />
      ) : (
        <Stack spacing={2}>
          <Box p={2} bgcolor={theme.palette.primary.light} borderRadius={1}>
            <Box mb={2}>
              <Typography variant="cardTitle" color="primary">
                <Text>interface.actions.record-new-data</Text>
              </Typography>
            </Box>
            <Formik
              initialValues={{
                created: moment(),
              }}
              onSubmit={(values) => {
                const requestData = wqFields
                  .filter((f) => values[f.field_id])
                  .map((f) => ({
                    datetime: values.created.format("YYYY-MM-DD hh:mm:ss"),
                    value: values[f.field_id],
                    pond_id: pond.id,
                    field_id: f.field_id,
                  }));
                Actions.submitData(requestData);
              }}
            >
              {({ values, handleChange, setFieldValue }) => {
                return (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={4}>
                            <Typography fontSize={12} fontWeight={800} textAlign={"right"}>
                              <Text>interface.time.time</Text>
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <MuiDatetimePicker
                              value={values.created}
                              onAccept={(newValue) => {
                                setFieldValue("created", newValue);
                              }}
                              size="small"
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      {wqFields.map((f) => (
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              <Typography fontSize={12} fontWeight={800} textAlign={"right"}>
                                <Text fallback={f.field_name}>{`fields.${f.field_id}`}</Text>
                              </Typography>
                              <Typography fontSize={11} color="grey" textAlign={"right"}>
                                {f.field_unit}
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <TextField
                                name={f.field_id}
                                onChange={handleChange}
                                value={values[f.field_id]}
                                type={"number"}
                                size="small"
                                placeholder={getText(`fields.${f.field_id}`, f.field_name)}
                                fullWidth
                              ></TextField>
                            </Grid>
                            <Grid item xs={4}>
                              {Renders.renderGraph({
                                data,
                                fieldId: f.field_id,
                              })}
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                    <Box mt={3}>
                      <Button size="large" variant="contained" type="submit">
                        <Text>interface.actions.submit</Text>
                      </Button>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Stack>
      )}
    </Box>
  );
};

export default WaterQualityUploader;
