/**
 * /config.js
 * Global configuration for the web-app
 * Claude Chen | claude.chen
 */

// import { WEB_SERVER_HOST } from "config.live";
import jwt_decode from "jwt-decode";

/**
 * URL used for connection to the webserver
 */

export const URL = "https://api.intelligon.com";
// export const URL = "http://localhost:8900";

/**
 * HOST is the current user's host address
 **/
export const HOST = window.location.origin;

export const EMAIL = localStorage.getItem("_email") || "";
export const FNAME = localStorage.getItem("_fname") || "";
export const USERID = localStorage.getItem("_userid") || "";
export const TOKENEMAIL = localStorage.getItem("_token") ? jwt_decode(localStorage.getItem("_token")).sub : null;

/**
 * App global layout configuration
 */
export const layoutConfig = {
  sidebarWidth: 250, // Full width of sidebar
  sidebarWidthCollapsed: 40, // Width of sidebar when it is collapsed
  innerSidebarWidth: 220, //
  navbarHeight: 45, // Height of Nav bar
};

export const COLORMAP = {
  main: "rgb(85, 80, 64)",
  minor: "rgb(122, 114, 86)",
  minor2: "rgb(50, 68, 103)",
  darkgold: "rgb(153, 122, 78)",
  gold: "rgb(247, 208, 153)",
  blue: "rgb(47, 101, 145)",
  grey: "#777",
  orange: "rgb(232, 123, 6)",
  health: "#06c32b",
  red: "#DD2C00",
  green: "#1B5E20",

  green_bg: "rgb(75, 198, 81)",
  red_o: "rgba(213, 0, 0, 0.7)",
  green_o: "rgba(46, 125, 50, 0.7)",
  red_oo: "rgba(213, 0, 0, 0.1)",
  red_bg: "rgb(232, 55, 55)",
  green_oo: "rgba(46, 125, 50, 0.1)",
  gold_oo: "rgb(153, 122, 78, 0.1)",

  bgGreen: "#C8E6C9",
  bgGrey: "#F5F5F5",
  bgRed: "#FFCCBC",
};

/**
 * OLD STUFF, to be deprecated
 *
 */
export const highchartBasicConfig = {
  chart: {
    style: {
      fontFamily: "Arial",
    },
    backgroundColor: "rgba(0,0,0,0)",
    animation: false,
  },
  title: { text: "" },
  credits: { enabled: false },
  navigator: {
    // maskFill: "rgba(37, 122, 100, 0.1)",
    maskFill: "rgba(78, 78, 78, 0.15)",
    height: 35,
    animation: false,
  },
  scrollbar: {
    height: 0,
    buttonArrowColor: "rgba(0,0,0,0)",
  },
  rangeSelector: {
    buttons: [
      { type: "all", text: "ALL" },
      { type: "year", count: 3, text: "3Y" },
      { type: "year", count: 1, text: "1Y" },
      { type: "ytd", text: "YTD" },
      { type: "month", count: 6, text: "6M" },
      { type: "month", count: 3, text: "3M" },
      { type: "month", count: 1, text: "1M" },
    ],
    buttonTheme: {
      padding: 0,
      height: 18,
      width: 20,
      fill: "#00000000",
      style: {
        fontSize: "1rem",
        fontFamily: "var(--condensed-font)",
        color: "#aaa",
      },
      states: {
        hover: {
          fill: "#00000000",
          style: { color: "#888" },
        },
        select: {
          fill: "#00000000",
          style: { fontSize: "1.3rem", color: "#000", fontWeight: 800 },
        },
      },
    },
    labelStyle: { display: "none" },
    inputEnabled: false,
  },
  tooltip: { animation: false, outside: true },
  xAxis: { type: "datetime", crosshair: true },
  yAxis: { gridLineColor: "#eee" },
  plotOptions: {
    series: {
      color: COLORMAP.minor,
      dataGrouping: { approximation: "close" },
      animation: false,
    },
  },
};

/*
 * Platform Roles
 */
export const Roles = {
  INTELLIGON: "INTELLIGON",
  USER: "USER",
  INTERNAL: "INTERNAL",
  SHRIMPSTAR_USER: "SHRIMPSTAR_USER",
  SHRIMPSTAR_AGENT: "SHRIMPSTAR_AGENT",
};
