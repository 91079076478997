import React, { Component, Fragment } from "react";
import { COLORMAP } from "../../config";
import moment from "moment";
import styled from "styled-components";
import { toUnique, getMin, getMargin } from "../../helpers/Utils";
import { Box, Card, Grid, Typography } from "@mui/material";
import HighchartsWrapper from "components/Highcharts/HighchartsWrapper";

const data = [
  1.810965041, 1.877204135, 1.747263203, 1.900876394, 1.562281913, 1.709660246, 1.845898309, 1.787312179, 1.595524914, 1.51149866, 1.593153447, 1.506629987, 1.51036084, 1.497393627, 1.73848842,
  1.668109774, 1.456804914, 1.476368204, 1.344969589, 1.343517601, 1.291389802, 1.163322577, 1.129380775, 1.090656831, 1.100150046, 1.06155639, 1.051917665, 1.060069784, 1.036156328, 1.012049562,
  1.002112947, 0.955578389, 0.936829858, 0.931165316,
].reverse();

const startmonth = moment("2017-07-01");

const performance = data.map((item, idx) => {
  let newYM = "";

  if (idx < startmonth.month()) {
    newYM = `${startmonth.year()}-${startmonth.month() + 1 + idx}-1`;
  } else {
    const offsetYear = Math.floor((idx - startmonth.month()) / 12) + 1;
    const offsetMonth = (idx - startmonth.month()) % 12;
    newYM = `${startmonth.year() + offsetYear}-${1 + offsetMonth}-1`;
  }
  return {
    date: newYM,
    nav: item,
    netNav: idx > 0 ? item - data[idx - 1] : item - 1,
  };
});

function timeseriesAggToQuarterly(timeseries) {
  let temp_dict = {};
  for (let item of timeseries) {
    const converted = moment(item.date);
    const y = converted.year();
    const m = converted.month();
    const q = Math.floor(m / 3) + 1;
    const key = `${y}-Q${q}`;
    if (!temp_dict[key]) {
      temp_dict[key] = [];
    }
    temp_dict[key].push(item);
  }
  let result = Object.keys(temp_dict).map((k) => [k, temp_dict[k]]);
  return result;
}

function timeseriesAggToAnnually(timeseries) {
  let temp_dict = {};
  for (let item of timeseries) {
    const converted = moment(item.date);
    const y = converted.year();
    const key = `${y}`;
    if (!temp_dict[key]) {
      temp_dict[key] = [];
    }
    temp_dict[key].push(item);
  }
  let result = Object.keys(temp_dict).map((k) => [k, temp_dict[k]]);
  return result;
}

const quarterlyPerformance = timeseriesAggToQuarterly(performance).map((ele) => [ele[0], ele[1].map((item) => item.netNav).reduce((a, b) => a + b)]);

let annuallyPerformance = timeseriesAggToAnnually(performance).map((ele) => [ele[0], ele[1].map((item) => item.netNav).reduce((a, b) => a + b)]);

const composition = [
  {
    name: "Entitlement - 1A Greater Goulburn",
    color: "rgb(203, 175, 135)",
    y: 7.1817163,
  },
  {
    name: "Entitlement - VIC Murray 7",
    color: COLORMAP.main,
    y: 75.7956595,
  },
  {
    name: "Allocation - VIC Murray 7",
    color: COLORMAP.minor,
    y: 16.357442,
  },
  {
    name: "Cash",
    color: "rgb(73,127,108)",
    y: 0.6651822,
  },
];

const config = {
  line: {
    title: { text: "" },
    xAxis: { type: "datetime" },
    yAxis: [
      {
        gridLineWidth: 1,
        // min: -5,
        // max: max_performance + 1,
        startOnTick: false,
        opposite: true,
        title: { text: "Monthly Performance" },
        labels: { format: "{value}%" },
      },
      {
        gridLineWidth: 1,
        min: 0,
        // max: max_performance + 1,
        startOnTick: false,
        title: { text: "NAV" },
        // labels: { format: "{value}%" },
      },
    ],
    tooltip: { shared: true },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
          crop: false,
          overflow: "none",
          format: "{y:.2f}%",
        },
      },
      area: {
        dataLabels: {
          enabled: true,
          format: "{y:.4f}",
        },
      },
    },
    series: [
      {
        name: "Net NAV",
        type: "column",
        data: performance.map((ele) => [Date.parse(ele.date), ele.netNav * 100]),
        // color: "rgb(196, 165, 124)",
        yAxis: 0,
        color: COLORMAP.green_o,
        negativeColor: COLORMAP.red_o,
      },
      {
        name: "NAV",
        type: "area",
        color: COLORMAP.main,
        fillOpacity: 0.1,
        data: performance.map((ele) => [Date.parse(ele.date), ele.nav]),
        yAxis: 1,
      },
    ],
  },
  pie: {
    chart: {
      type: "pie",
    },
    title: {
      text: "",
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
        },
      },
    },
    series: [
      {
        name: "Portofolio Composition",
        colorByPoint: true,
        data: composition,
      },
    ],
  },

  monthlyHeatmap: {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      style: { fontFamily: "var(--main-font)" },
      animation: true,
      backgroundColor: "rgba(0,0,0,0)",
    },
    title: { text: "" },
    tooltip: { enabled: false },
    xAxis: {
      categories: "jan-feb-mar-apr-may-jun-jul-aug-sep-oct-nov-dec".split("-"),
    },
    yAxis: {
      categories: toUnique(performance.map((item) => item.date.split("-")[0])).reverse(),
    },
    colorAxis: {
      min: getMargin(performance.map((item) => item.netNav)) * -1,
      max: getMargin(performance.map((item) => item.netNav)),
      stops: [
        [0, COLORMAP.red],
        [0.3, COLORMAP.red_o],
        [0.5, "#fff"],
        [0.7, COLORMAP.green_o],
        [1, COLORMAP.green],
      ],
    },
    series: [
      {
        name: "return",
        type: "heatmap",
        data: performance.map((ele) => [
          parseInt(ele.date.split("-")[1]) - 1,
          toUnique(performance.map((item) => item.date.split("-")[0]))
            .reverse()
            .indexOf(ele.date.split("-")[0]),
          ele.netNav,
        ]),
        dataLabels: {
          enabled: true,
          formatter: function () {
            return (this.point.value * 100).toFixed(2) + "%";
          },
        },
      },
    ],
  },

  quarterlyHeatmap: {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      style: { fontFamily: "var(--main-font)" },
      animation: true,
      backgroundColor: "rgba(0,0,0,0)",
    },
    title: { text: "" },
    tooltip: { enabled: false },
    xAxis: {
      categories: ["Q1", "Q2", "Q3", "Q4"],
    },
    yAxis: {
      categories: toUnique(quarterlyPerformance.map((item) => item[0].split("-")[0])).reverse(),
    },
    colorAxis: {
      min: getMargin(quarterlyPerformance.map((item) => item[1])) * -1,
      max: getMargin(quarterlyPerformance.map((item) => item[1])),
      stops: [
        [0, COLORMAP.red],
        [0.3, COLORMAP.red_o],
        [0.5, "#fff"],
        [0.7, COLORMAP.green_o],
        [1, COLORMAP.green],
      ],
    },
    series: [
      {
        name: "return",
        type: "heatmap",
        data: quarterlyPerformance.map((ele) => [
          parseInt(ele[0].split("Q")[1]) - 1,
          toUnique(quarterlyPerformance.map((item) => item[0].split("-")[0]))
            .reverse()
            .indexOf(ele[0].split("-")[0]),
          ele[1],
        ]),
        dataLabels: {
          enabled: true,
          formatter: function () {
            return (this.point.value * 100).toFixed(2) + "%";
          },
        },
      },
    ],
  },

  annuallyHeatmap: {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      style: { fontFamily: "var(--main-font)" },
      animation: true,
      backgroundColor: "rgba(0,0,0,0)",
    },
    title: { text: "" },
    tooltip: { enabled: false },
    xAxis: {
      categories: ["Annual"],
    },
    yAxis: {
      categories: annuallyPerformance.map((item) => item[0]).reverse(),
    },
    colorAxis: {
      min: getMargin(annuallyPerformance.map((item) => item[1])) * -1,
      max: getMargin(annuallyPerformance.map((item) => item[1])),
      stops: [
        [0, COLORMAP.red],
        [0.3, COLORMAP.red_o],
        [0.5, "#fff"],
        [0.7, COLORMAP.green_o],
        [1, COLORMAP.green],
      ],
    },
    series: [
      {
        name: "return",
        type: "heatmap",
        data: annuallyPerformance.reverse().map((ele, idx) => [0, idx, ele[1]]),
        dataLabels: {
          enabled: true,
          formatter: function () {
            return (this.point.value * 100).toFixed(2) + "%";
          },
        },
      },
    ],
  },

  waterfall: {
    chart: {
      type: "waterfall",
      plotBackgroundColor: null,
      plotBorderWidth: null,
      style: { fontFamily: "var(--main-font)" },
      animation: true,
      backgroundColor: "rgba(0,0,0,0)",
    },
    title: { text: "" },
    xAxis: {
      type: "category",
    },
    yAxis: {
      title: {
        text: "Return",
      },
      labels: {
        formatter: function () {
          return this.value * 100 + "%";
        },
      },
    },

    legend: {
      enabled: false,
    },

    tooltip: {
      pointFormatter: function () {
        return (this.y * 100).toFixed(2) + "%";
      },
    },

    series: [
      {
        data: [
          ...quarterlyPerformance.map((ele) => ({
            name: ele[0],
            y: ele[1],
            color: ele[1] > 0 ? COLORMAP.green_o : COLORMAP.red_o,
          })),
          {
            name: "TOTAL",
            y: quarterlyPerformance.reduce((a, b) => a + b[1], 0),
            isIntermediateSum: true,
            color: quarterlyPerformance.reduce((a, b) => a + b[1], 0) > 0 ? COLORMAP.green : COLORMAP.red,
          },
        ],
        dataLabels: {
          enabled: true,
          formatter: function () {
            return (this.y * 100).toFixed(2) + "%";
          },
        },
        pointPadding: 0,
      },
    ],
  },
};

class WaterFundPerformance extends Component {
  render() {
    return (
      <Box p={2}>
        {/* Header */}
        <div style={{ animation: "textFloat 1s" }}>
          <Typography variant="h4" gutterBottom>
            Water Trust Performance
          </Typography>

          <Typography variant="body1">
            The AIP Management Water Trust Monthly Performance represents live trading performance generated from investing and trading Australian water entitlements.
          </Typography>

          <Typography variant="body1">
            The trust features 3 key strategies: <br />
            - Buy and hold long term ownership of high reliability water entitlements where long term agricultural demand is predicted to be robust. <br />- The ownership of these entitlements means
            that each year Imbue receives allocation water which will then decide when to sell to optimise returns. This is done with the Imbue AI Yield Optimiser. <br />- Thirdly, the Imbue
            Allocation AI Strategy learns from a wide range of alternative datasets to actively trade the allocation water market. This active strategy adds alpha over current existing managers in the
            market that deploy passive strategies.
          </Typography>
        </div>
        {/* Chart */}

        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} lg={8}>
            <Card sx={{ p: 2 }}>
              <Box mb={2}>
                <Typography variant="h5">Monthly Returns</Typography>
              </Box>
              <HighchartsWrapper options={config.line} />
            </Card>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Card sx={{ p: 2 }}>
              <Box mb={2}>
                <Typography variant="h5">Quarterly Returns</Typography>
              </Box>
              <HighchartsWrapper options={config.waterfall} />
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card sx={{ p: 2 }}>
              <Box mb={2}>
                <Typography variant="h5"></Typography>
              </Box>
              <HighchartsWrapper options={config.monthlyHeatmap} />
            </Card>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Card sx={{ p: 2 }}>
              <Box mb={2}>
                <Typography variant="h5"></Typography>
              </Box>
              <HighchartsWrapper options={config.quarterlyHeatmap} />
            </Card>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Card sx={{ p: 2 }}>
              <Box mb={2}>
                <Typography variant="h5"></Typography>
              </Box>
              <HighchartsWrapper options={config.annuallyHeatmap} />
            </Card>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Card sx={{ p: 2 }}>
              <Box mb={2}>
                <Typography variant="h5">Portfolio Composition</Typography>
              </Box>
              <HighchartsWrapper options={config.pie} />
            </Card>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default WaterFundPerformance;
