import { Email, EmailOutlined, Person, PersonOutline } from "@mui/icons-material";

const MyIcons = {
  mui: {
    User: (p) => <Person {...p} />,
    UserO: (p) => <PersonOutline {...p} />,
    Email: (p) => <Email {...p} />,
    EmailOutlined: (p) => <EmailOutlined {...p} />,
  },
};

export default MyIcons;
