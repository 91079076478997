import { Box, Button, Chip, Stack, Tooltip, Typography, colors } from "@mui/material";
import Text from "components/text/Text";
import { AddCircleOutlineIcon, DeleteIcon, KeyboardArrowLeftIcon, SettingsIcon } from "components/Icons/MaterialIcons";
import { dialogReducerActions } from "redux/dialog";
import { useDispatch } from "react-redux";
import CommonDialog, { CommonDialogIds } from "components/Dialogs/CommonDialog";
import { PrettifyPondSubCategory } from "constants/PondSubCategories";
import NumericFormatters from "helpers/NumericFormatters";

const PondInfoBanner = ({ pond, selectedGroup, writable, actions }) => {
  const { onReturn, handleEditAction, handleCloneAction, handleDeleteAction } = actions;

  const dispatch = useDispatch();

  const Actions = {
    onDeletePondConfirm: async () => {
      dialogReducerActions.openCommonDialog(dispatch, { id: CommonDialogIds.POND_DELETE_CONFIRM });
    },
  };

  return (
    <Box
      p={1}
      pb={5}
      zIndex={99}
      sx={{
        background: "linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5676645658263305) 46%, rgba(0,0,0,0.9037990196078431) 100%)",
        position: "absolute",
        width: "100%",
        boxSizing: "border-box",
        animation: "fadeIn 0.4s",
      }}
    >
      <CommonDialog
        id={CommonDialogIds.POND_DELETE_CONFIRM}
        title="Are you Sure?"
        content={"Are you sure you want to remove this pond? (You will lose all your data)"}
        action={() => handleDeleteAction(pond.id)}
      />
      <Box>
        <Button
          variant="text"
          size="small"
          startIcon={<KeyboardArrowLeftIcon />}
          onClick={onReturn}
          sx={{
            color: "primary.light",
          }}
        >
          {selectedGroup ? <Text>interface.actions.switch-to-group</Text> : <Text>interface.actions.switch-to-farm</Text>}
        </Button>
      </Box>

      <Stack alignItems={"center"}>
        <Typography color="primary.light" variant="caption" sx={{ mb: -0.8 }}>
          <Text>interface.general.pond</Text>
        </Typography>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="h5" fontWeight="bold" color="primary.light" sx={{ mb: 1 }}>
            {pond.name} {pond.name === "New Pond" && <small>({pond.id.split("-")[0]})</small>}
          </Typography>
        </Stack>
        <Stack mt={0} direction="row" spacing={1} alignItems="center">
          <Tooltip title={<Text>interface.general.pond-area</Text>} placement="top">
            <Chip
              size="small"
              color="primary"
              label={<span>A: {NumericFormatters.formatAreaInHa({ value: pond.area })}</span>}
              sx={{
                backgroundColor: colors.red[800],
              }}
            />
          </Tooltip>
          <Tooltip title={<Text>interface.general.group</Text>} placement="top">
            <Chip
              size="small"
              label={`G: ${pond.group}`}
              color="primary"
              sx={{
                backgroundColor: colors.blue[800],
              }}
            />
          </Tooltip>
          <Tooltip title={<Text>interface.general.category</Text>} placement="top">
            <Chip
              size="small"
              color="primary"
              label={`C: ${pond.category}`}
              sx={{
                backgroundColor: colors.green[800],
              }}
            />
          </Tooltip>
          <Tooltip title="Sub Category" placement="top">
            <Chip
              size="small"
              color="primary"
              sx={{
                backgroundColor: colors.purple[800],
              }}
              label={`SC: ${PrettifyPondSubCategory(pond.sub_category)}`}
            />
          </Tooltip>
        </Stack>
      </Stack>

      {writable && (
        <Box mt={1}>
          <Stack direction={"row"} spacing={1}>
            <Button
              variant="text"
              fullWidth
              onClick={() => handleEditAction(pond.id)}
              startIcon={<SettingsIcon />}
              sx={{
                color: "primary.light",
              }}
            >
              <Text>interface.actions.edit</Text>
            </Button>
            <Button
              variant="text"
              fullWidth
              onClick={() => handleCloneAction(pond.id)}
              startIcon={<AddCircleOutlineIcon />}
              sx={{
                color: "primary.light",
              }}
            >
              <Text>interface.actions.clone</Text>
            </Button>
            <Button
              variant="text"
              fullWidth
              onClick={Actions.onDeletePondConfirm}
              startIcon={<DeleteIcon />}
              sx={{
                color: "primary.light",
              }}
            >
              <Text>interface.actions.delete</Text>
            </Button>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default PondInfoBanner;
