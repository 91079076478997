import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Slider,
  Stack,
  Switch,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  colors,
} from "@mui/material";
import { theme } from "App";
import MyIcons from "components/Icons/Icons";
import { Grid3x3Icon, Grid4x4Icon, SettingsIcon } from "components/Icons/MaterialIcons";
import React from "react";
import { isMobile } from "themes/helpers";
import palette from "themes/palette";
import WidgetCard from "ui/Card/WidgetCard";

const mobileMode = isMobile();

const TheTitle = ({ title }) => {
  return (
    <Box m={2}>
      <Box my={5} py={2}>
        <Typography fontWeight={800} fontSize={mobileMode ? 40 : 140} textTransform={"capitalize"} letterSpacing={0} color={colors.grey[400]}>
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

const TheColors = ({}) => {
  return (
    <Box m={2}>
      <Grid
        container
        spacing={1}
        columns={4}
        sx={{
          ".MuiPaper-root": {
            height: 50,
          },
        }}
      >
        <Grid item xs={1}>
          <Paper
            sx={{
              bgcolor: theme.palette.primary.main,
            }}
          ></Paper>
          <Typography>Primary / Main: {theme.palette.primary.main}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Paper
            sx={{
              bgcolor: theme.palette.primary.light,
            }}
          ></Paper>
          <Typography>Primary / Light: {theme.palette.primary.light}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Paper
            sx={{
              bgcolor: theme.palette.primary.dark,
            }}
          ></Paper>
          <Typography>Primary / Dark: {theme.palette.primary.dark}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Paper
            sx={{
              bgcolor: theme.palette.primary.contrastText,
            }}
          ></Paper>
          <Typography>Primary / ContrastText: {theme.palette.primary.contrastText}</Typography>
        </Grid>

        <Grid item xs={1}>
          <Paper
            sx={{
              bgcolor: theme.palette.secondary.main,
            }}
          ></Paper>
          <Typography>Secondary / Main</Typography>
        </Grid>
        <Grid item xs={1}>
          <Paper sx={{ bgcolor: theme.palette.secondary.light }}></Paper>
          <Typography>Secondary / Light</Typography>
        </Grid>
        <Grid item xs={1}>
          <Paper
            sx={{
              bgcolor: theme.palette.secondary.dark,
            }}
          ></Paper>
          <Typography>Secondary / Dark</Typography>
        </Grid>
        <Grid item xs={1}>
          <Paper
            sx={{
              bgcolor: theme.palette.secondary.contrastText,
            }}
          ></Paper>
          <Typography>Secondary / ContrastText</Typography>
        </Grid>

        <Grid item xs={1}>
          <Paper
            sx={{
              bgcolor: theme.palette.info.main,
            }}
          ></Paper>
          <Typography>info / Main</Typography>
        </Grid>
        <Grid item xs={1}>
          <Paper
            sx={{
              bgcolor: theme.palette.info.light,
            }}
          ></Paper>
          <Typography>info / Light</Typography>
        </Grid>
        <Grid item xs={1}>
          <Paper
            sx={{
              bgcolor: theme.palette.info.dark,
            }}
          ></Paper>
          <Typography>info / Dark</Typography>
        </Grid>
        <Grid item xs={1}>
          <Paper
            sx={{
              bgcolor: theme.palette.info.contrastText,
            }}
          ></Paper>
          <Typography>info / ContrastText</Typography>
        </Grid>

        <Grid item xs={1}>
          <Paper
            sx={{
              bgcolor: theme.palette.warning.main,
            }}
          ></Paper>
          <Typography>warning / Main</Typography>
        </Grid>
        <Grid item xs={1}>
          <Paper
            sx={{
              bgcolor: theme.palette.warning.light,
            }}
          ></Paper>
          <Typography>warning / Light</Typography>
        </Grid>
        <Grid item xs={1}>
          <Paper
            sx={{
              bgcolor: theme.palette.warning.dark,
            }}
          ></Paper>
          <Typography>warning / Dark</Typography>
        </Grid>
        <Grid item xs={1}>
          <Paper
            sx={{
              bgcolor: theme.palette.warning.contrastText,
            }}
          ></Paper>
          <Typography>warning / ContrastText</Typography>
        </Grid>

        <Grid item xs={1}>
          <Paper
            sx={{
              bgcolor: theme.palette.success.main,
            }}
          ></Paper>
          <Typography>success / Main</Typography>
        </Grid>
        <Grid item xs={1}>
          <Paper
            sx={{
              bgcolor: theme.palette.success.light,
            }}
          ></Paper>
          <Typography>success / Light</Typography>
        </Grid>
        <Grid item xs={1}>
          <Paper
            sx={{
              bgcolor: theme.palette.success.dark,
            }}
          ></Paper>
          <Typography>success / Dark</Typography>
        </Grid>
        <Grid item xs={1}>
          <Paper
            sx={{
              bgcolor: theme.palette.success.contrastText,
            }}
          ></Paper>
          <Typography>success / ContrastText</Typography>
        </Grid>

        <Grid item xs={1}>
          <Paper
            sx={{
              bgcolor: theme.palette.error.main,
            }}
          ></Paper>
          <Typography>error / Main</Typography>
        </Grid>
        <Grid item xs={1}>
          <Paper
            sx={{
              bgcolor: theme.palette.error.light,
            }}
          ></Paper>
          <Typography>error / Light</Typography>
        </Grid>
        <Grid item xs={1}>
          <Paper
            sx={{
              bgcolor: theme.palette.error.dark,
            }}
          ></Paper>
          <Typography>error / Dark</Typography>
        </Grid>
        <Grid item xs={1}>
          <Paper
            sx={{
              bgcolor: theme.palette.error.contrastText,
            }}
          ></Paper>
          <Typography>error / ContrastText</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const TheTheme = () => {
  return (
    <Paper sx={{ m: 2, height: 500, overflow: "auto", fontSize: 10 }}>
      <Box p={2}>
        <pre>{JSON.stringify(theme, undefined, 4)}</pre>
      </Box>
    </Paper>
  );
};

const DesignGuide = () => {
  return (
    <Box>
      <Container>
        <Box p={2}>
          <Typography variant="h4" gutterBottom>
            Shrimpl Design Guide
          </Typography>
        </Box>

        <TheTitle title="System" />

        <Stack>
          <Paper sx={{ overflow: "auto", fontSize: 10 }}>
            <Box p={2}>
              <pre>
                <strong>userAgent</strong>
              </pre>
              <pre>{navigator.userAgent}</pre>
            </Box>
          </Paper>
        </Stack>

        <TheTitle title="Color" />
        <TheColors />

        <TheTitle title="typography" />
        <Box p={2}>
          <Typography variant="h1" gutterBottom>
            h1. Heading
          </Typography>
          <Typography variant="h2" gutterBottom>
            h2. Heading
          </Typography>
          <Typography variant="h3" gutterBottom>
            h3. Heading
          </Typography>
          <Typography variant="h4" gutterBottom>
            h4. Heading
          </Typography>
          <Typography variant="h5" gutterBottom>
            h5. Heading
          </Typography>
          <Typography variant="h6" gutterBottom>
            h6. Heading
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
          </Typography>
          <Typography variant="body1" gutterBottom>
            body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam
            dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
          </Typography>
          <Typography variant="body2" gutterBottom>
            body2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam
            dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
          </Typography>
          <Typography variant="button" display="block" gutterBottom>
            button text
          </Typography>
          <Typography variant="caption" display="block" gutterBottom>
            caption text
          </Typography>
          <Typography variant="overline" display="block" gutterBottom>
            overline text
          </Typography>
          <Typography fontSize="1rem">1rem</Typography>
          <Typography fontSize="2rem">2rem</Typography>
          <Typography fontSize="3rem">3rem</Typography>
          <Typography fontSize="4rem">4rem</Typography>
          <Typography variant="h3" gutterBottom>
            Number <br /> 0 1 2 3 4 5 6 7 8 9
            <br /> 123,456,789.000
            <br /> $123,456,789
            <br /> 123 kg
          </Typography>
        </Box>

        <TheTitle title="Shadows" />
        <Grid container spacing={4}>
          {[...Array(16)].map((ele, idx) => (
            <Grid xs={4} item>
              <Paper key={idx} elevation={idx}>
                <Box p={2}>{idx}</Box>
              </Paper>
            </Grid>
          ))}
        </Grid>

        <TheTitle title="Buttons" />

        <Stack spacing={2} p={1}>
          <Grid container px={1} alignItems={"center"}>
            <Grid item xs={4}>
              <Button size="small">Button (text, small)</Button>
            </Grid>
            <Grid item xs={4}>
              <Button size="medium">Button (text, medium)</Button>
            </Grid>
            <Grid item xs={4}>
              <Button size="large">Button (text, large)</Button>
            </Grid>
          </Grid>

          <Grid container px={1} alignItems={"center"}>
            <Grid item xs={4}>
              <Button variant="contained" size="small">
                Button (Medium)
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button variant="contained" size="medium">
                Button (Medium)
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button variant="contained" size="large">
                Button (Medium)
              </Button>
            </Grid>
          </Grid>

          <Grid container px={1} alignItems={"center"}>
            <Grid item xs={4}>
              <Button variant="outlined" size="small">
                Button (Medium)
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button variant="outlined" size="medium">
                Button (Medium)
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button variant="outlined" size="large">
                Button (Medium)
              </Button>
            </Grid>
          </Grid>
        </Stack>

        <Stack spacing={2} width={300} m={2}>
          <ButtonGroup variant="text" size="medium">
            <Button>Button1</Button>
            <Button>Button2</Button>
            <Button>Button3</Button>
          </ButtonGroup>
          <ButtonGroup variant="contained" size="medium">
            <Button>Button1</Button>
            <Button>Button2</Button>
            <Button>Button3</Button>
          </ButtonGroup>
          <ButtonGroup variant="outlined" size="medium">
            <Button>Button1</Button>
            <Button>Button2</Button>
            <Button>Button3</Button>
          </ButtonGroup>
        </Stack>
        <Box>
          <ToggleButtonGroup value={2} size="small">
            <ToggleButton value={1}>
              <SettingsIcon />
            </ToggleButton>
            <ToggleButton value={2}>
              <Grid3x3Icon />
            </ToggleButton>
            <ToggleButton value={3}>
              <Grid4x4Icon />
            </ToggleButton>
            <ToggleButton value={3}>Chart View</ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <TheTitle title="Tabs" />
        <Box m={2}>
          <Tabs value="0">
            <Tab value="0" label="tab1"></Tab>
            <Tab value="1" label="tab2"></Tab>
            <Tab value="2" label="tab3"></Tab>
          </Tabs>
        </Box>

        <TheTitle title="Switch" />
        <Box m={2}>
          <Box>
            <Switch size="medium"></Switch>
            <Switch size="small"></Switch>
          </Box>
          <Box>
            <Switch defaultChecked color="default"></Switch>
            <Switch defaultChecked color="primary"></Switch>
            <Switch defaultChecked color="error"></Switch>
            <Switch defaultChecked color="secondary"></Switch>
            <Switch defaultChecked color="info"></Switch>
            <Switch defaultChecked color="success"></Switch>
            <Switch defaultChecked color="warning"></Switch>
          </Box>
          <Box>
            <FormGroup>
              <FormControlLabel control={<Switch />} label="Switch" />
            </FormGroup>
          </Box>
        </Box>

        <TheTitle title="Card" />
        <Stack spacing={2} p={2}>
          <Card>
            <CardHeader title="Default Card" />
            <CardContent>
              <Typography>Card Content</Typography>
            </CardContent>
          </Card>
          <WidgetCard title="widget card" icon={<MyIcons.mui.UserO />}>
            <Box>
              <Typography>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including
                versions of Lorem Ipsum.
              </Typography>
            </Box>
          </WidgetCard>
        </Stack>

        <TheTitle title="Toggle" />

        <TheTitle title="Slider" />

        <Box p={4}>
          <Slider />
          <Slider color="secondary" />
          <Slider color="org" />
        </Box>

        <TheTitle title="Theme in JSON" />
        <TheTheme />
      </Container>
    </Box>
  );
};

export default DesignGuide;
