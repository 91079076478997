import { isDevMode } from "helpers/Utils";

const isDevmode_ = isDevMode();

export const MAPBOX_TOKEN = "pk.eyJ1IjoiY2xhdWRlY2hlbiIsImEiOiJjbGljeW95N2swMzNjM2xwazFqcHJwNWhnIn0.yLGCTvyKHueidePhRuStDw";

export const MAPBOX_STYLE = "mapbox://styles/claudechen/clin27asq008f01r840lq5n3t";

export const GOOGLE_CAPTCHA_SITE_KEY = "6Lecc-cgAAAAAKaIeG-CIuOOJNXsG9lH2ncVjQdN";

export const GOOGLE_CAPTCHA_SECRET_KEY = "6Lecc-cgAAAAADXP-7iEdJehAFwoH_yiYv6Z2X9a";

export const GOOGLE_MAP_KEY = isDevmode_ ? "AIzaSyCPOKOdqCCsg-rENEls-SgD5falZFGcJLI" : "AIzaSyByD5QXIzu6Acz8zI7E4G3Y7WhuCDvBlK0";

export const SPREADJS_LICENSE_KEY =
  "*.shrimpl.com,187221919895192#B0u1nQ5JVUkZ5KRNTZ6IEVCFDSSdkWXN4bJJVeqhGdrUVNy3UV7wWRvF7SQF5Yu3ieGdzcZxmamd6b0x4Yws4ZQNXZ9tmaiRFNyVXQ8dlanZUTKF6QtZzdQx6ctx4c0hWVCtUdnd6Lo9Ua6RGRwQEZP94b6wGVxpUNuRzZNNWcxIVNsVnT8M5LO3yU42UWvh5Qu54d8lzQCdHatRDWzBVNPBXNOp4bYxEMu3CeJZ5ZylUUNdlWUhzVvNFeQZGbV54LXJjZ4IUOWhHMPllaaBnRsdTdHFmUTNDMQlzbvATWC3GTSp5RUdFNzZkT6tWeRNnexkmI0IyUiwiI8kTQDRTNGdjI0ICSiwiN6kzMyczNyEjM0IicfJye#4Xfd5nIMFUUTJiOiMkIsISNx8idgMlSgQWYlJHcTJiOi8kI1tlOiQmcQJCLiYDMyUjMwACNyATMyIDMyIiOiQncDJCLi46bj9Cbw5Wayh6cuoiI0IyctRkIsIiLkRHTgUGdQBichR7Uw5Wayh6UiojIh94QiwiIykTM5kDO9ETOxIjM7gTMiojIklkIs4XXbpjInxmZiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34TQWRnbZt6ZNV6N5wEcG3CVrc6KBJ6NrJmdFBzNVRkcadkSTN5TSdmQUhldlR6NGdncXRXTDZGSX3iWL9WREFnQDp6S5IkTzVkd7ZUOWFFdygVa4ZVZYRURkZWUOlVTiNES2JqQ";

export const AG_GRID_LICENSE_KEY =
  "Using_this_AG_Grid_Enterprise_key_( AG-047508 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Shrimpl Pte Ltd )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Shrimpl )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( Shrimpl )_need_to_be_licensed___( Shrimpl )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 10 September 2024 )____[v2]_MTcyNTkyMjgwMDAwMA==7fda18f438799e95ab0e3613a060808f";

export const GOOGLE_OAUTH_CLIENT_ID = "844427901388-ttcnab16tesssv40pq25gdskbs1avr2p.apps.googleusercontent.com";
