import { useQuery } from "@tanstack/react-query";
import { PondManagerServices } from "api/pondManagerServices";
import _ from "lodash";
import { DateTime } from "luxon";
import moment from "moment";

const initState = {
  cycles: {},
  latestCycles: {},
  selectedCycle: {},
  selectedDate: moment().format("YYYY-MM-DD"),
};

const cycleManagementTypes = {
  LOAD_ALL_CYCLES: "LOAD_ALL_CYCLES",
  ON_CHANGE_CYCLE: "ON_CHANGE_CYCLE",
  ON_CHANGE_TO_CURRENT_CYCLE: "ON_CHANGE_TO_CURRENT_CYCLE",
  SELECT_DATE: "SELECT_DATE",
};

const cycleManagementReducers = (state = initState, action) => {
  switch (action.type) {
    case cycleManagementTypes.LOAD_ALL_CYCLES:
      return {
        ...state,
        cycles: action.payload.cycles,
        latestCycles: action.payload.latestCycles,
        selectedCycle: {}, // reset selectedCycle
      };
    case cycleManagementTypes.ON_CHANGE_CYCLE:
      return {
        ...state,
        selectedCycle: action.payload.cycle,
      };
    case cycleManagementTypes.ON_CHANGE_TO_CURRENT_CYCLE:
      const { pondId } = action.payload;
      const cyclesOfCurrentPond = state.cycles[pondId];
      if (cyclesOfCurrentPond && cyclesOfCurrentPond.length > 0) {
        const currentCycle = _.maxBy(cyclesOfCurrentPond, "start_date");
        return {
          ...state,
          selectedCycle: currentCycle,
        };
      }
      return state;
    case cycleManagementTypes.SELECT_DATE:
      return {
        ...state,
        selectedDate: action.payload.date,
      };
    default:
      return state;
  }
};

export const cycleManagementActions = {
  selectDate: async (dispatch, date) => {
    dispatch({
      type: cycleManagementTypes.SELECT_DATE,
      payload: { date },
    });
  },
  loadAllCyclesByFarmId: async (dispatch, farmId) => {
    const rsp = await PondManagerServices.loadAllCyclesByFarmId(farmId);
    const allCycles = rsp.rows
      .map((r) => ({
        ...r,
        active: !r.ended,
        start_date: DateTime.fromFormat(r.start_date, "yyyy-MM-dd HH:mm:ss").toFormat("yyyy-MM-dd"),
        end_date: DateTime.fromFormat(r.end_date, "yyyy-MM-dd HH:mm:ss").toFormat("yyyy-MM-dd"),
        total_days: DateTime.fromFormat(r.end_date, "yyyy-MM-dd HH:mm:ss").diff(DateTime.fromFormat(r.start_date, "yyyy-MM-dd HH:mm:ss")).as("days"),
        days_to_today: DateTime.now().diff(DateTime.fromFormat(r.start_date, "yyyy-MM-dd HH:mm:ss")).as("days"),
      }))
      .map((r) => ({
        ...r,
        progress: r.days_to_today >= r.total_days ? 1 : r.days_to_today / r.total_days,
      }));
    const groupedCycles = _.mapValues(_.groupBy(allCycles, "pond_id"), (list) => _.orderBy(list, "start_date"));
    const groupedLatestCycles = _.mapValues(_.groupBy(allCycles, "pond_id"), (list) => _.last(_.orderBy(list, "start_date")));
    dispatch({
      type: cycleManagementTypes.LOAD_ALL_CYCLES,
      payload: { cycles: groupedCycles, latestCycles: groupedLatestCycles },
    });
  },
  onChangeCycle: (dispatch, cycle) => {
    dispatch({
      type: cycleManagementTypes.ON_CHANGE_CYCLE,
      payload: { cycle },
    });
  },
  onChangeToCurrentCycle: (dispatch, pondId) => {
    dispatch({
      type: cycleManagementTypes.ON_CHANGE_TO_CURRENT_CYCLE,
      payload: { pondId },
    });
  },
};

export const cycleManagementHelpers = {
  getCurrentCycle: ({ pondId, cycle, cycles }) => {
    if (_.isEmpty(cycle)) {
      const cyclesOfCurrentPond = cycles?.[pondId] || [];
      const currentCycle = _.maxBy(cyclesOfCurrentPond, "start_date") || {};
      return currentCycle;
    } else {
      return cycle;
    }
  },
};

export default cycleManagementReducers;
