import { Box, Grid, LinearProgress, Typography } from "@mui/material";
import { PondManagerServices } from "api/pondManagerServices";
import Text, { getText } from "components/text/Text";
import NumericFormatters from "helpers/NumericFormatters";
import TimeFormatters from "helpers/TimeFormatters";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";

const DataExplorerSummary = ({ pond, cycle }) => {
  const [data, setData] = useState({});
  const isPond = pond;

  useEffect(() => {
    pond && cycle && Actions.loadData();
  }, [pond, cycle]);

  const Actions = {
    loadData: async () => {
      const result = await PondManagerServices.getDataexploreSummaryData({
        pond: pond,
        cycle: cycle,
      });
      setData(result);
    },
  };

  const pondSummaryItems = [
    {
      label: <Text>interface.general.label</Text>,
      value: pond?.name || pond?.label,
      c: 0,
    },
    {
      label: <Text>interface.general.pond-area</Text>,
      value: pond?.area,
      formatter: (val) => NumericFormatters.formatAreaInHa({ value: val }),
      c: 0,
    },
    {
      label: <Text>interface.general.cycle</Text>,
      value: cycle?.label,
      c: 0,
    },
    {
      label: "",
      value: TimeFormatters.formatDatetime(cycle?.start_date, "DD", "yyyy-MM-dd") + " ~ " + TimeFormatters.formatDatetime(cycle?.end_date, "DD", "yyyy-MM-dd"),
      c: 0,
    },
    {
      label: "",
      value: cycle?.ended ? getText("interface.general.ended") : getText("interface.general.active"),
      c: 0,
    },
    {
      label: <Text>interface.general.seeded-date</Text>,
      value: data?.data?.seeded_weight?.datetime,
      formatter: (val) => TimeFormatters.formatDatetime(val, "DD"),
      c: 1,
    },
    {
      label: <Text>interface.general.seeded-density</Text>,
      value: data?.data?.seeded_density?.value,
      formatter: (val) => <NumberFormat value={val} decimalScale={1} displayType="text" suffix=" /m2" />,
      c: 1,
    },
    {
      label: <Text>interface.general.current-density</Text>,
      value: data?.data?.current_density?.value,
      formatter: (val) => <NumberFormat value={val} decimalScale={1} displayType="text" suffix=" /m2" />,
      c: 1,
    },
    {
      label: <Text>interface.general.survival-rate</Text>,
      value: data?.data?.survival_rate?.value,
      formatter: (val) => <NumberFormat value={val} decimalScale={1} displayType="text" suffix="%" />,
      c: 1,
    },
    {
      label: <Text>interface.general.last-biomass-recorded</Text>,
      value: data?.data?.biomass?.datetime,
      formatter: (val) => TimeFormatters.formatDatetime(val, "DD"),
      c: 2,
    },
    {
      label: <Text>interface.general.current-biomass</Text>,
      value: data?.data?.biomass?.value,
      formatter: (val) => <NumberFormat value={val / 1000} decimalScale={1} displayType="text" suffix=" t" />,
      c: 2,
    },
    {
      label: <Text>interface.general.total-harvested</Text>,
      value: data?.data?.harvest_biomass_accumulated?.value,
      formatter: (val) => <NumberFormat value={val / 1000} decimalScale={1} displayType="text" suffix=" t" />,
      c: 2,
    },
    {
      label: <Text>interface.general.total-feed</Text>,
      value: data?.data?.feed_accumulated?.value,
      formatter: (val) => <NumberFormat value={val / 1000} decimalScale={1} displayType="text" suffix=" t" />,
      c: 2,
    },
    {
      label: <Text>interface.general.fcr-to-date</Text>,
      value: data?.data?.fcr_full_cycle?.value,
      formatter: (val) => <NumberFormat value={val} decimalScale={1} displayType="text" />,
      c: 2,
    },
  ];

  return (
    <Box>
      <Grid container>
        {[0, 1, 2].map((c, idx) => (
          <Grid item xs={12} md={4} key={"des-grid-c-" + idx}>
            <Grid container columnSpacing={1} alignItems={"center"}>
              {pondSummaryItems
                .filter((item) => item.c === c)
                .map((item, idx_) => (
                  <>
                    <Grid item xs={4}>
                      {!item.isHidden && (
                        <Box>
                          <Typography textAlign={"right"} fontSize={11} color="grey ">
                            {item.label}
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={8}>
                      {!item.isHidden && (
                        <Box>
                          <Typography fontWeight={"bold"} fontSize={11}>
                            {item.value ? (item.formatter ? item.formatter(item.value) : item.value) : "--"}
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                  </>
                ))}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default DataExplorerSummary;
