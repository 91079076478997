import { Box, LinearProgress, Stack, colors } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { PondManagerServices } from "api/pondManagerServices";
import { VerticalAlertBoxCollections } from "components/Alerts/AlertBox";
import HighchartsWrapper from "components/Highcharts/HighchartsWrapper";
import { SatEyeLogoBlack } from "components/Images";
import { getText } from "components/text/Text";
import Formatters from "helpers/Formatters";
import _ from "lodash";
import { spatialData } from "mock/spatialData";
import moment from "moment";
import { useEffect, useState } from "react";
import { AppSelectors } from "redux/AppReducers";
import { cycleManagementHelpers } from "redux/pondManagement/cycleManagement";
import palette from "themes/palette";
import WidgetCard from "ui/Card/WidgetCard";

const generateTableConfigs = {
  biomass: ({ biomassSeries, spatialBiomassSeries, biomassForecastTimeseries }) => {
    const biomassSeriesStartDate = _.minBy(biomassSeries, "datetime")?.datetime;
    const biomassSeriesEndDate = _.maxBy(biomassSeries, "datetime")?.datetime;
    const spatialBiomassSeriesCut =
      biomassSeriesStartDate && biomassSeriesEndDate
        ? spatialBiomassSeries?.filter((ele) => moment(ele.date) >= moment(biomassSeriesStartDate) && moment(ele.date) <= moment(biomassSeriesEndDate))
        : spatialBiomassSeries;

    const merged = _.orderBy(_.values(_.merge(_.keyBy(biomassSeries, "datetime"), _.keyBy(spatialBiomassSeriesCut, "datetime"))), "datetime");

    return {
      colDefs: [
        {
          field: "datetime",
          headerName: "Datetime",
          flex: 1,
        },
        {
          field: "biomass",
          headerName: "Biomass",
          flex: 1,
          cellRenderer: ({ value }) => Formatters.formatBiomass({ value }),
        },
        {
          field: "value",
          headerName: "Satellite Estimated",
          flex: 1,
          cellRenderer: ({ value }) => Formatters.formatBiomass({ value }),
          cellStyle: {
            color: colors.purple[600],
            fontWeight: 800,
          },
        },
        {
          field: "harvest_biomass",
          headerName: "Harvest Biomass",
          flex: 1,
          cellRenderer: ({ value }) => Formatters.formatBiomass({ value }),
        },
      ],
      data: merged,
    };
  },
};

const generateChartConfigs = {
  biomass: ({ biomassSeries, spatialBiomassSeries, biomassForecastTimeseries }) => {
    const biomassSeriesStartDate = _.minBy(biomassSeries, "datetime")?.datetime;
    const biomassSeriesEndDate = _.maxBy(biomassSeries, "datetime")?.datetime;

    const spatialBiomassSeriesCut =
      biomassSeriesStartDate && biomassSeriesEndDate
        ? spatialBiomassSeries?.filter((ele) => moment(ele.date) >= moment(biomassSeriesStartDate) && moment(ele.date) <= moment(biomassSeriesEndDate))
        : spatialBiomassSeries;

    const configs = {
      chart: {
        animation: {
          duration: 0,
        },
        height: 400,
      },
      title: {
        text: "",
      },
      xAxis: {
        type: "datetime",
        crosshair: true,
      },
      yAxis: {
        crosshair: true,
      },
      legend: {
        enabled: true,
      },
      plotOptions: {
        series: {
          animation: {
            duration: 0,
          },
        },
      },
      series: [
        {
          name: "observed biomass",
          type: "line",
          data: biomassSeries.map((ele) => ({
            x: moment(ele.datetime).valueOf(),
            y: ele.biomass,
          })),
          color: colors.orange[500],
          connectNulls: true,
          visible: biomassSeries.length > 0,
        },
        {
          name: "harvest biomass",
          type: "column",
          color: colors.red[500],
          pointWidth: 10,
          data: biomassSeries.map((ele) => ({
            x: moment(ele.datetime).valueOf(),
            y: ele.harvest_biomass,
          })),
          visible: biomassSeries.length > 0,
        },
        {
          name: "forecasted",
          type: "line",
          color: colors.blue[500],
          pointWidth: 2,
          lineWidth: 1,
          data: biomassForecastTimeseries.map((ele) => ({
            x: moment(ele.date).valueOf(),
            y: ele.value,
          })),
          dashStyle: "ShortDash",
          visible: biomassForecastTimeseries.length > 0,
        },
        {
          name: "spatial estimated",
          type: "line",
          color: palette.gis.main,
          marker: {
            enabled: true,
            radius: 4,
            symbol: "cross",
          },
          dashStyle: "ShortDash",
          lineWidth: 1,
          data: spatialBiomassSeriesCut.map((ele) => ({
            x: moment(ele.date).valueOf(),
            y: ele.value,
          })),
        },
      ],
      tooltip: {
        enabled: true,
      },
    };

    return configs;
  },
  do: ({ spatialSeries }) => {
    if (spatialSeries.length === 0) return null;

    const spatialSeriesCut = spatialSeries;

    const configs = {
      chart: {
        animation: {
          duration: 0,
        },
        height: 400,
      },
      title: {
        text: "",
      },
      xAxis: {
        type: "datetime",
        crosshair: true,
      },
      yAxis: {
        crosshair: true,
      },
      legend: {
        enabled: true,
      },
      plotOptions: {
        series: {
          animation: {
            duration: 0,
          },
        },
      },
      series: [
        {
          name: "spatial estimated",
          type: "line",
          color: palette.gis.main,
          marker: {
            enabled: true,
            radius: 4,
            symbol: "circle",
          },
          dashStyle: "ShortDash",
          lineWidth: 1,
          data: spatialSeriesCut.map((ele) => ({
            x: moment(ele.datetime).valueOf(),
            y: ele.value,
          })),
        },
      ],
      tooltip: {
        enabled: true,
      },
    };

    return configs;
  },
  waterTemp: ({ spatialSeries }) => {
    if (spatialSeries.length === 0) return null;
    const spatialSeriesCut = spatialSeries;

    const configs = {
      chart: {
        animation: {
          duration: 0,
        },
        height: 400,
      },
      title: {
        text: "",
      },
      xAxis: {
        type: "datetime",
        crosshair: true,
      },
      yAxis: {
        crosshair: true,
      },
      legend: {
        enabled: true,
      },
      plotOptions: {
        series: {
          animation: {
            duration: 0,
          },
        },
      },
      series: [
        {
          name: "spatial estimated",
          type: "line",
          color: palette.gis.main,
          marker: {
            enabled: true,
            radius: 4,
            symbol: "circle",
          },
          dashStyle: "ShortDash",
          lineWidth: 1,
          data: spatialSeriesCut.map((ele) => ({
            x: moment(ele.datetime).valueOf(),
            y: ele.value,
          })),
        },
      ],
      tooltip: {
        enabled: true,
      },
    };
    return configs;
  },
};

/**
 *
 * an interface visualising bionmass, water tempearture and dissolved oxygen data from Shrimpl GIS projects
 *
 * TODO:
 *  - table views
 *
 * @param {*} param0
 * @returns
 */
const GisLab = ({ farm, pond }) => {
  const [biomassChartConfigs, setBiomassChartConfigs] = useState(null);
  const [biomassTableConfigs, setBiomassTableConfigs] = useState(null);
  const [doChartConfigs, setDoChartConfigs] = useState(null);
  const [waterTempChartConfigs, setWaterTempChartConfigs] = useState(null);

  const [loading, setLoading] = useState(false);

  const cycleStore = AppSelectors.cycleStore();
  const selectedCycle = cycleManagementHelpers.getCurrentCycle({ pondId: pond?.id, cycle: cycleStore.selectedCycle, cycles: cycleStore.cycles });

  const farmSpatialData = spatialData?.[farm.farm_id]?.aquosmic;

  const pondSpatialDataBiomass = farmSpatialData?.biomass?.data?.filter((ele) => ele.pond_id === pond?.id).map((row) => ({ ...row, datetime: row.date + " 00:00:00" })) || [];

  const Actions = {
    fetchData: async ({ pond, cycle }) => {
      setLoading(true);

      console.log(pond, cycle);

      // const dbGisData = (await PondManagerServices.getTimeseriesData(pond.id, ["gis_biomass", "gis_do", "gis_water_temp"], "", ""))?.rows;

      // // generate chart configs
      // const spatialBiomass = dbGisData.map((ele) => ({ ...ele, value: ele.gis_biomass, date: ele.datetime }));
      // const spatialDo = dbGisData.map((ele) => ({ ...ele, value: ele.gis_do, date: ele.datetime }));
      // const spatialWaterTemp = dbGisData.map((ele) => ({ ...ele, value: ele.gis_water_temp, date: ele.datetime }));

      // const biomassChartConfigs = generateChartConfigs.biomass({
      //   biomassSeries: [],
      //   spatialBiomassSeries: dbGisData.map((ele) => ({ ...ele, value: ele.gis_biomass, date: ele.datetime })),
      //   biomassForecastTimeseries: [],
      // });
      // setBiomassChartConfigs(biomassChartConfigs);

      // // generate table configs
      // const biomassTableConfigs = generateTableConfigs.biomass({
      //   biomassSeries: [],
      //   spatialBiomassSeries: dbGisData.map((ele) => ({ ...ele, value: ele.gis_biomass, date: ele.datetime })),
      //   biomassForecastTimeseries: [],
      // });
      // setBiomassTableConfigs(biomassTableConfigs);

      // // generate DO configs
      // const doChartConfigs = generateChartConfigs.do({
      //   spatialSeries: dbGisData.map((ele) => ({ datetime: ele.datetime, value: ele?.gis_do })),
      // });
      // setDoChartConfigs(doChartConfigs);

      // // generate DO configs
      // const waterTempChartConfigs = generateChartConfigs.waterTemp({
      //   spatialSeries: dbGisData.map((ele) => ({ datetime: ele.datetime, value: ele?.gis_water_temp })),
      // });
      // setWaterTempChartConfigs(waterTempChartConfigs);

      setLoading(false);
    },
  };

  useEffect(() => {
    if (farm && pond) {
      Actions.fetchData({ pond, cycle: selectedCycle });
    }
  }, [farm, pond]);

  // useEffect(() => {
  //   if (farm && pond && selectedCycle) {
  //     Actions.fetchData({ pond, cycle: selectedCycle });
  //   }
  // }, [selectedCycle]);

  // return alert if no pond selected
  if (!pond) return <VerticalAlertBoxCollections.NoPondSelected />;

  // return alert if no cycle selected
  // if (_.isEmpty(selectedCycle)) return <VerticalAlertBoxCollections.NoCycleSelected pondId={pond?.id} />;

  return (
    <Box p={2}>
      <Box>
        <img src={SatEyeLogoBlack} alt="" width={150} />
      </Box>
      <Box height={2} my={1}>
        {loading && <LinearProgress />}
      </Box>
      <Stack spacing={2}>
        {biomassChartConfigs && (
          <WidgetCard title={getText("interface.general.biomass")}>
            <HighchartsWrapper options={biomassChartConfigs} />
          </WidgetCard>
        )}
        {doChartConfigs && (
          <WidgetCard title={getText("interface.general.DO")}>
            <HighchartsWrapper options={doChartConfigs} />
          </WidgetCard>
        )}
        {waterTempChartConfigs && (
          <WidgetCard title={getText("fields.water-temp")}>
            <HighchartsWrapper options={waterTempChartConfigs} />
          </WidgetCard>
        )}
        {biomassTableConfigs && (
          <WidgetCard>
            <Box height={600} className="ag-theme-clean">
              <AgGridReact rowHeight={25} columnDefs={biomassTableConfigs.colDefs} rowData={biomassTableConfigs.data} overlayNoRowsTemplate={"NO DATA"} />
            </Box>
          </WidgetCard>
        )}
      </Stack>
    </Box>
  );
};

export default GisLab;
