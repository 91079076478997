import React, { useState } from "react";
import { DateTime } from "luxon";
import _ from "lodash";
import { Box, Stack, Tooltip, Typography, colors } from "@mui/material";
import { MuiBorderIconButton } from "components/buttons/MuiButtonVariants";
import { ArrowBackIcon, ArrowForwardIcon } from "components/Icons/MaterialIcons";

const getContributionLevel = (count, configs) => {
  if (configs) {
    if (configs[count]) {
      return configs[count];
    } else {
      return (
        configs["default"] || {
          level: "",
          color: colors.grey[400],
        }
      );
    }
  } else {
    switch (count) {
      case -3:
        return { level: "very bad", color: "#B71C1C" };
      case -2:
        return { level: "bad", color: "#EF5350" };
      case -1:
        return { level: "moderately bad", color: "#FFCDD2" };
      case 0:
        return { level: "normal", color: "#A5D6A7" };
      default:
        return { level: "", color: "#ccc" };
    }
  }
};

const generateCalendar = ({ data, configs, startYear = 2024 }) => {
  const weeks = [];
  const yearStart = DateTime.local(startYear, 1, 1);
  const firstMonday = yearStart.plus({ days: 7 - yearStart.weekday });
  const startDate = firstMonday.startOf("week");
  for (let week = 0; week < 53; week++) {
    const days = [];
    for (let day = 0; day < 7; day++) {
      const currentDate = startDate.plus({ weeks: week, days: day });
      const dateString = currentDate.toISODate();
      const contributionCount = data[dateString] || 0;
      const { level, color } = getContributionLevel(contributionCount, configs);
      days.push({
        date: dateString,
        dt: currentDate,
        isToday: dateString === DateTime.local().toISODate(),
        level,
        color,
      });
    }

    const daylist = days.map((d) => d.dt.day);
    const hasFirstDay = _.includes(daylist, 1);
    const month = hasFirstDay ? _.last(days.map((d) => d.dt))?.toFormat("MMM") : "";
    weeks.push({
      hasFirstDay,
      month,
      days,
    });
  }
  return weeks;
};

/**
 *
 * exmaple configs
 *
 * {
 *  1: { color: "red", label: "Has Data" },
 *  default: { color: "grey", label: "No Data" }
 * }
 *
 * @param {*} param0
 * @returns
 */
const CalendarMonitorChart = ({ data = {}, configs, defaultStartYear = 2024, maxYear, minYear }) => {
  const [startYear, setStartYear] = useState(defaultStartYear || DateTime.local().year);

  const weeks = generateCalendar({ data, configs, startYear });

  const gap = "2px";
  const size = "12px";

  const Actions = {
    onNextYear: () => {
      if (maxYear) {
        if (startYear >= maxYear) return;
      }
      setStartYear(startYear + 1);
    },
    onPrevYear: () => {
      if (minYear) {
        if (startYear <= minYear) return;
      }
      setStartYear(startYear - 1);
    },
  };

  return (
    <Box>
      <div style={{ display: "flex" }}>
        <Box sx={{ display: "flex", flexDirection: "column", mt: "3px" }}>
          {["", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((week) => (
            <div
              key={week}
              style={{
                width: "30px",
                height: size,
                margin: gap,
                fontSize: "9px",
              }}
            >
              <Typography fontSize={9} fontWeight={800} textAlign={"right"}>
                {week}
              </Typography>
            </div>
          ))}
        </Box>
        {weeks.map((week, i) => {
          return (
            <Box key={i} sx={{ display: "flex", flexDirection: "column" }}>
              {/* 
              ///////////// Header
              */}
              <Box
                sx={{
                  width: size,
                  height: "15px",
                  margin: gap,
                }}
              >
                <Typography fontSize={9} fontWeight={800} textAlign={"left"} lineHeight={1}>
                  {week.hasFirstDay ? (
                    <span>
                      {week.month}
                      <br />|
                    </span>
                  ) : (
                    ""
                  )}
                </Typography>
              </Box>
              {week.days.map((day) => (
                <Tooltip
                  placement="top-start"
                  title={
                    <Box>
                      <Typography fontSize={12}>
                        <span style={{ color: "grey" }}>{`W${day.dt.toFormat("W")}`}</span> <strong>{`${day.dt.toFormat("DDD")}`}</strong>
                      </Typography>
                      <Stack direction={"row"} spacing={0.5} alignItems={"center"}>
                        <Box
                          sx={{
                            width: 2,
                            height: 10,
                            bgcolor: day.color,
                            border: "1px solid #999",
                          }}
                        ></Box>
                        <Typography fontSize={15} fontWeight={800}>{`${day.level}`}</Typography>
                      </Stack>
                    </Box>
                  }
                >
                  <Box
                    key={day.date}
                    className="dot"
                    sx={{
                      width: size,
                      height: size,
                      margin: gap,
                      backgroundColor: day.color,
                      borderRadius: "2px",
                      animation: day.isToday && "flashing 1s infinite",
                      transition: "all .3s",
                      "&:hover": {
                        transform: "scale(1.3)",
                        cursor: "pointer",
                      },
                    }}
                  ></Box>
                </Tooltip>
              ))}
            </Box>
          );
        })}
      </div>
      <Stack direction={"row"} alignItems={"center"} spacing={1} mt={1} ml={4}>
        <MuiBorderIconButton size="sm" icon={<ArrowBackIcon />} onClick={Actions.onPrevYear} />
        <Box width={50} textAlign={"center"}>
          <Typography fontWeight={800}>{startYear}</Typography>
        </Box>
        <MuiBorderIconButton size="sm" icon={<ArrowForwardIcon />} onClick={Actions.onNextYear} />
      </Stack>
    </Box>
  );
};

export default CalendarMonitorChart;
