import { Box, Button, FormControl, FormLabel, MenuItem, Select, Stack, TextField, Typography, colors } from "@mui/material";
import Text from "components/text/Text";
import CollabMemberRoles from "constants/CollabMemberRoles";
import React, { useState } from "react";
import FarmAccessLevelBadge from "screens/Aquaculture/components/common/FarmAccessLevelBadge";

export const CollabInviteForm = ({ farmId, onSubmit, onCancel }) => {
  const [email, setEmail] = useState("");
  const [role, setRole] = useState(CollabMemberRoles[0].value);

  return (
    <Box py={2}>
      <Stack spacing={2} direction={"row"} alignItems={"center"}>
        <Box>
          <FormControl>
            <FormLabel>
              <Typography variant="inputLabel">
                <Text>interface.account.email</Text>
              </Typography>
            </FormLabel>
            <TextField
              size="small"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant="outlined"
              sx={{
                minWidth: 300,
              }}
            />
          </FormControl>
        </Box>

        <Box>
          <FormControl size="small">
            <FormLabel>
              <Typography variant="inputLabel">
                <Text>interface.general.role</Text>
              </Typography>
            </FormLabel>
            <Select
              value={role}
              onChange={(e) => setRole(Number.parseInt(e.target.value))}
              sx={{
                minWidth: 300,
              }}
            >
              {CollabMemberRoles.map((ele, idx) => (
                <MenuItem key={idx} value={ele.value}>
                  <Box>
                    <FarmAccessLevelBadge accessLevelId={ele.value} />
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box>
          <Button
            variant="contained"
            onClick={() => {
              const form = {
                userId: null,
                email,
                accessLevelId: role,
              };
              onSubmit(form);
            }}
            disabled={!email}
          >
            <Text>interface.actions.confirm</Text>
          </Button>
        </Box>

        <Box>
          <Button variant="error" onClick={onCancel}>
            <Text>interface.actions.cancel</Text>
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};
