import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Box, Button, Checkbox, Container, Divider, FormControlLabel, Stack, Typography, colors } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { PondManagerServices } from "api/pondManagerServices";
import AlertBox from "components/Alerts/AlertBox";
import CommonDialog, { CommonDialogIds } from "components/Dialogs/CommonDialog";
import { AddIcon, CircleIcon, DeleteIcon } from "components/Icons/MaterialIcons";
import StyledTag from "components/Tags/StyledTag";
import PondManagerAccessWrapper from "components/Wrapper/PondManagerAccessWrapper";
import { MuiBorderIconButton } from "components/buttons/MuiButtonVariants";
import Text, { getText } from "components/text/Text";
import { CollabAccessLevels } from "constants/FarmAccessLevels";
import Formatters from "helpers/Formatters";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppSelectors } from "redux/AppReducers";
import { alertsActions } from "redux/alerts";
import { dialogReducerActions } from "redux/dialog";
import { CollabInviteForm } from "screens/Aquaculture/components/AccessControl/CollabInviteForm";
import { ResourcePolicies, displayedPolices } from "screens/Aquaculture/components/AccessControl/ResourcePolicies";
import FarmAccessLevelBadge from "screens/Aquaculture/components/common/FarmAccessLevelBadge";
import WidgetCard from "ui/Card/WidgetCard";

const hiddenAccounts = ["709f2907-cf70-4b2c-9c26-c0b5cc0f3602"];

const MemberTable = ({ data, actions }) => {
  const { onSelectItem, onDeleteMemberConfirm, onUpdateMemberRole } = actions;

  let rowData = [...data.filter((ele) => ele.is_me), ...data.filter((ele) => !ele.is_me)];
  rowData = rowData.filter((ele) => (ele.is_me ? true : !_.includes(hiddenAccounts, ele.user_id)));

  const [coldefs, setcoldefs] = useState([
    {
      field: "member",
      headerName: getText("interface.general.members"),
      cellRenderer: ({ data }) => {
        return (
          <Box>
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <Box>
                <Typography fontSize={13} fontWeight={800}>
                  {[data.firstname, data.lastname].join(" ")}
                </Typography>
                <Typography fontSize={10} color="grey">
                  {data.email}
                </Typography>
              </Box>
              <Box>{data.is_me && <StyledTag label={"ME"} color={colors.red[600]} />}</Box>
            </Stack>
          </Box>
        );
      },
    },
    {
      field: "company",
      headerName: getText("interface.account.company"),
    },
    {
      field: "access_level_id",
      headerName: getText("interface.account.role"),
      editable: (params) => {
        const data = params.node.data;
        const isOwner = data.access_level_id === CollabAccessLevels.OWNER;
        return isOwner ? false : true;
      },
      cellEditor: "agRichSelectCellEditor",
      cellRenderer: ({ value }) => <FarmAccessLevelBadge accessLevelId={value} sx={{ height: 20 }} />,
      cellEditorParams: {
        cellRenderer: (params) => {
          return <FarmAccessLevelBadge accessLevelId={params.value} sx={{ height: 20 }} />;
        },
        values: [CollabAccessLevels.ADMIN, CollabAccessLevels.MANAGER, CollabAccessLevels.TECHNICIAN, CollabAccessLevels.VIEWER, CollabAccessLevels.ADVISOR],
      },
    },
    {
      field: "id",
      headerName: getText("interface.general.policies"),
      cellRenderer: ({ data }) => {
        const policiesLength = data?.policies?.length || 0;
        return policiesLength;
      },
    },
    {
      field: "id",
      headerName: "",
      cellRenderer: ({ data }) => {
        const isMe = data.is_me;
        const isOwner = data.access_level_id === CollabAccessLevels.OWNER;

        let enableDelete = true;
        let enableSelect = true;

        // other member but it's owner
        if (!isMe && isOwner) {
          enableDelete = false;
          enableSelect = false;
        }

        return (
          <Stack direction={"row"} spacing={1}>
            <MuiBorderIconButton
              icon={<DeleteIcon />}
              size={"sm"}
              onClick={(e) => {
                onDeleteMemberConfirm(data);
              }}
              disabled={!enableDelete}
            />
            <MuiBorderIconButton
              icon={<ArrowForward />}
              size={"sm"}
              onClick={() => {
                onSelectItem(data);
              }}
              disabled={!enableSelect}
            />
          </Stack>
        );
      },
    },
  ]);

  return (
    <Box className="ag-theme-clean" height={800}>
      <AgGridReact
        columnDefs={coldefs}
        rowData={rowData}
        rowHeight={60}
        defaultColDef={{
          flex: 1,
          cellStyle: {
            display: "flex",
            alignItems: "center",
            height: "100%",
          },
        }}
        getRowStyle={(params) => {
          const isMe = params?.node?.data?.is_me;
          if (isMe) {
            return {
              background: colors.red[50],
            };
          }
        }}
        onCellEditingStopped={(e) => {
          if (e.valueChanged) {
            const accessLevelId = e.newValue;
            const userId = e.data.user_id;
            const farmId = e.data.farm_id;
            onUpdateMemberRole({
              accessLevelId,
              userId,
              farmId,
            });
          }
        }}
      />
    </Box>
  );
};

/**
 *
 * Member Details
 *
 * @param {*} param0
 * @returns
 */
const MemberDetails = ({ data, actions }) => {
  const farmId = data.farm_id;

  const defaultPolicies = data?.["policies"] || [];
  const [currentPolices, setCurrentPolices] = useState(data?.["policies"] || []);

  const dispatch = useDispatch();

  const Actions = {
    onChangePolicy: ({ code, checked }) => {
      if (checked) {
        setCurrentPolices([...currentPolices, code]);
      } else {
        setCurrentPolices(currentPolices.filter((ele) => ele !== code));
      }
    },
    onReset: () => {
      setCurrentPolices(defaultPolicies);
    },
    onSubmit: async () => {
      const formData = {
        user_id: data?.user_id,
        policies: currentPolices,
      };
      await PondManagerServices.assignFarmAdminAccess(farmId, formData);
      // alert
      alertsActions.addInfo(dispatch, {
        content: getText("interface.alert.alert-success"),
      });
      // post actions
      actions.loadData();
    },
  };

  const renderOption = ({ code, label, description }) => {
    const isChecked = _.includes(currentPolices, code);
    return (
      <Stack
        direction={"row"}
        spacing={1}
        alignItems={"center"}
        px={1}
        sx={{
          transition: "all .3s",
          borderRadius: 4,
          "&:hover": {
            background: "#FFF",
          },
        }}
      >
        <CircleIcon
          sx={{
            fontSize: 10,
            color: isChecked ? "green" : colors.grey[400],
          }}
        />
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <FormControlLabel
            control={<Checkbox checked={isChecked} onChange={(e, checked) => Actions.onChangePolicy({ code: code, checked: checked })} />}
            label={
              <Box p={1}>
                <Typography fontSize={14} fontWeight={800} color={isChecked ? "primary" : colors.grey[700]}>
                  <Text>{`policy.${code}`}</Text>
                </Typography>
                <Typography fontSize={12} color={colors.grey[600]} lineHeight={1}>
                  <Text>{`policy.description.${code}`}</Text>
                </Typography>
              </Box>
            }
          />
        </Stack>
      </Stack>
    );
  };

  const noPoliciesWarning = currentPolices.length === 0;

  return (
    <Stack>
      <Box py={2}>
        <FarmAccessLevelBadge accessLevelId={data?.access_level_id} />
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Typography fontSize={20} fontWeight={800}>
            {[data.firstname, data.lastname].join(" ")}
          </Typography>
          {data.is_me && (
            <Box>
              <StyledTag label="ME" color={colors.red[600]} />
            </Box>
          )}
        </Stack>

        <Typography fontSize={10}>{data?.email}</Typography>
        <Typography fontSize={10}>{data?.company}</Typography>
      </Box>

      <Divider />

      <Container maxWidth="lg" sx={{ p: 4 }}>
        {noPoliciesWarning && (
          <Box pb={2}>
            <AlertBox level="warning" title={"No Policies Assigned"} />
          </Box>
        )}

        {displayedPolices.map((displayGroup, idx) => {
          const { group, groupK, description, descriptionK, policies } = displayGroup;
          return (
            <Box py={1} key={`g-${idx}`}>
              <Typography variant="pageSubTitle">
                <Text>{`policy.group.${groupK}`}</Text>
              </Typography>
              <Typography fontSize={10} color="grey">
                <Text>{`policy.group.${descriptionK}`}</Text>
              </Typography>
              <Divider></Divider>
              <Stack>
                {policies.map((p) => {
                  const { code, description, label } = p;
                  return renderOption({ code, label, description });
                })}
              </Stack>
            </Box>
          );
        })}

        <Stack direction={"row"} spacing={2} mt={2}>
          <Button variant="contained" size="large" onClick={Actions.onSubmit}>
            <Text>interface.actions.confirm</Text>
          </Button>
          <Button variant="contained" size="large" onClick={Actions.onReset}>
            <Text>interface.actions.reset</Text>
          </Button>
        </Stack>
      </Container>
    </Stack>
  );
};

const CollabManager = () => {
  const [memberToRemove, setMemberToRemove] = useState(null);
  const [showInviteForm, setShowInviteForm] = useState(false);

  const pondManagementStore = AppSelectors.pondManagementStore();
  const { selectedFarm } = pondManagementStore;

  const [data, setData] = useState([]);
  const [selectedMemeber, setMember] = useState(null);

  useEffect(() => {
    selectedFarm && Actions.loadData();
  }, [selectedFarm]);

  const dispatch = useDispatch();

  const Actions = {
    loadData: async () => {
      const farm = selectedFarm;
      const result = await PondManagerServices.getFarmAdminAccessMembers(farm.farm_id);
      setData(result);
      return result;
    },
    onSelectMember: (item) => {
      setMember(item);
    },
    onInviteMember: async (form) => {
      const email = Formatters.cleanEmail(form.email);
      const farmId = selectedFarm.farm_id;
      const accessLevelId = form.accessLevelId;
      const rsp = await PondManagerServices.createNewUserFarmAccess(email, farmId, accessLevelId);
      if (rsp.error) {
        alertsActions.addError(dispatch, { content: rsp.error });
      } else {
        const newMembers = await Actions.loadData();
        setShowInviteForm(false);
        alertsActions.addInfo(dispatch, { content: "New member is added!" });
        // select the newly added member
        const newMember = _.find(newMembers, { email });
        if (newMember) {
          setMember(newMember);
        }
      }
    },
    onDeleteMemberConfirm: (item) => {
      setMemberToRemove(item);
      dialogReducerActions.openCommonDialog(dispatch, { id: CommonDialogIds.FARM_ACCESS_CONTROL_CONFIRM });
    },
    onDeleteMember: async () => {
      if (memberToRemove) {
        await PondManagerServices.deleteUserFarmAccess(memberToRemove.user_id, selectedFarm.farm_id);
        Actions.loadData();
        alertsActions.addInfo(dispatch, {
          content: getText("interface.alert.alert-success"),
        });
        // clean up
        setMemberToRemove(null);
      }
    },
    onUpdateMemberRole: async ({ accessLevelId, userId, farmId }) => {
      await PondManagerServices.updateUserFarmAccess(userId, farmId, accessLevelId);
      Actions.loadData();
      alertsActions.addInfo(dispatch, {
        content: getText("interface.alert.alert-success"),
      });
    },
  };

  if (selectedMemeber) {
    return (
      <Box p={2}>
        <Box mb={2}>
          <Button startIcon={<ArrowBack />} onClick={() => Actions.onSelectMember(null)}>
            <Text>interface.actions.return</Text>
          </Button>
        </Box>
        <MemberDetails data={selectedMemeber} actions={Actions} />
      </Box>
    );
  }

  return (
    <PondManagerAccessWrapper policies={[ResourcePolicies.ACCESSCONTROL]}>
      <Box p={2}>
        <CommonDialog
          id={CommonDialogIds.FARM_ACCESS_CONTROL_CONFIRM}
          title={<Text>interface.alert.alert-are-you-sure</Text>}
          content={`You are removing ${memberToRemove?.email}`}
          action={() => Actions.onDeleteMember()}
        />
        <Stack direction={"row"} spacing={3} alignItems={"center"}>
          <Typography variant="pageTitle">
            <Text>interface.access-control.access-control</Text>
          </Typography>
          <Box>
            <Button startIcon={<AddIcon />} variant="outlined" onClick={() => setShowInviteForm(true)}>
              <Text>interface.general.invite</Text>
            </Button>
          </Box>
        </Stack>
        {showInviteForm && (
          <Box my={2}>
            <WidgetCard title={<Text>interface.actions.share-with</Text>}>
              <CollabInviteForm onCancel={() => setShowInviteForm(false)} onSubmit={Actions.onInviteMember} />
            </WidgetCard>
          </Box>
        )}
        <Box mt={2}>
          <WidgetCard>
            <MemberTable
              data={data}
              actions={{
                onSelectItem: Actions.onSelectMember,
                onDeleteMemberConfirm: Actions.onDeleteMemberConfirm,
                onUpdateMemberRole: Actions.onUpdateMemberRole,
              }}
            />
          </WidgetCard>
        </Box>
      </Box>
    </PondManagerAccessWrapper>
  );
};

export default CollabManager;
