import { isDevMode } from "helpers/Utils";

const isDevMode_ = isDevMode();

export const msalConfig = {
  auth: {
    clientId: "aa95690e-0a4f-4dff-bbd0-c30ab8eff537",
    authority: "https://login.microsoftonline.com/common", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: isDevMode_
      ? "http://localhost:3000/microsoft-signed-in"
      : "https://app.shrimpl.com/microsoft-signed-in",
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  protectedResourceMap: null,
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
