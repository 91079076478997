import { Avatar, Box, Card, Container, Typography, colors } from "@mui/material";
import { PondManagerServices } from "api/pondManagerServices";
import { ShrimplIconOnlySvg, ShrimplLogoSvg } from "components/Images";
import React, { useEffect, useState } from "react";
import { Fade } from "react-reveal";
import { useParams } from "react-router-dom";

const OrganisationLandingPage = () => {
  const { orgId } = useParams();

  const [profile, setProflie] = useState(null);

  useEffect(() => {
    orgId && Actions.getOrgProfile();
  }, []);

  const Actions = {
    getOrgProfile: async () => {
      const result = await PondManagerServices.getPublicOrgProfile(orgId);
      setProflie(result);
    },
  };

  if (!profile) return "";

  return (
    <Box>
      <Container>
        <Card sx={{ mt: 10, overflow: "hidden" }}>
          <Box
            sx={{
              position: "relative",
              overflow: "visible",
            }}
          >
            <Box
              sx={{
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${profile.banner_url})`,
                backgroundPosition: "center",
                height: 300,
              }}
            ></Box>
            <Fade bottom duration={300} distance={"10px"}>
              <Avatar
                src={profile.logo_url}
                sx={{
                  position: "absolute",
                  width: 200,
                  height: 200,
                  bottom: 50,
                  left: "calc(50% - 100px)",
                  zIndex: 999,
                  boxShadow: 1,
                }}
              ></Avatar>
            </Fade>
            <Box p={4} my={10} textAlign={"center"}>
              <Typography fontWeight={"bold"} fontSize={40}>
                {profile.name}
              </Typography>
              <Typography fontSize={20} color={colors.grey[500]} fontStyle={"italic"}>
                {profile.org_type}
              </Typography>
              <Box mt={2}>
                <a src={profile.link}>
                  <Typography>{profile.link}</Typography>
                </a>
              </Box>
              <Box my={5}>
                <Typography variant="body1">{profile.description}</Typography>
              </Box>
              <Box textAlign={"center"}>
                <img src={ShrimplLogoSvg} alt="" width={150} />
              </Box>
            </Box>
          </Box>
        </Card>
      </Container>
    </Box>
  );
};

export default OrganisationLandingPage;
