import { ReportProblem } from "@mui/icons-material";
import { Box, Grid, Stack, Typography, colors } from "@mui/material";
import { VerticalAlertBoxCollections } from "components/Alerts/AlertBox";
import HighchartsWrapper from "components/Highcharts/HighchartsWrapper";
import Text from "components/text/Text";
import Formatters from "helpers/Formatters";
import NumericFormatters from "helpers/NumericFormatters";
import TimeFormatters from "helpers/TimeFormatters";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { getAlertResult } from "screens/Aquaculture/components/Dashboard/utils/alertHelpers";
import palette from "themes/palette";

const SidebarItem = ({ selected, item, actions }) => {
  const isValueChanged = item?.change;
  const valueChangeColor = item?.change?.diff > 0 ? colors.green[700] : item?.change?.diff < 0 ? colors.red[700] : colors.grey[800];

  const alertResult = getAlertResult({ field_id: item?.field?.field_id, value: item?.current?.value, rules: { [item?.field?.field_id]: item?.alertRules } });

  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      sx={{
        p: 1,
        borderRadius: 1,
        bgcolor: selected && palette.primary.light,
        border: selected ? "solid 1px #CCC" : "solid 1px transparent",
        ":hover": {
          cursor: "pointer",
          bgcolor: colors.grey[100],
        },
        ":active": {
          transform: "translateY(1px)",
        },
      }}
      onClick={() => actions.onSelectField(item?.field?.field_id)}
    >
      <Box>
        <Typography color="grey" fontSize={11}>
          <Text fallback={item?.field?.field_name}>{`fields.${item?.field?.field_id}`}</Text> ({item?.field?.field_unit})
        </Typography>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Typography fontSize={20} fontWeight={"800"}>
            {item?.current?.value ? NumericFormatters.format({ value: item?.current?.value }) : "-"}
          </Typography>
          <Typography fontSize={12} color={valueChangeColor}>
            {item?.change?.diff_perc ? NumericFormatters.format({ value: item?.change?.diff_perc * 100, suffix: "%", decimalScale: 0 }) : "-"}
          </Typography>
        </Stack>
        <Typography color="grey" fontSize={11}>
          {item?.current?.value ? TimeFormatters.formatDatetime(item?.current?.datetime, "DD") : "-"}
        </Typography>
      </Box>
      <Stack direction={"row"}>{alertResult?.isAlert && <ReportProblem sx={{ color: "red" }} />}</Stack>
    </Stack>
  );
};

const TheChart = ({ item }) => {
  const hasData = item?.size > 0;

  const isValueChanged = item?.change;
  const valueChangeColor = item?.change?.diff > 0 ? colors.green[700] : item?.change?.diff < 0 ? colors.red[700] : colors.grey[800];

  const chartConfigs = {
    chart: {
      height: "500px",
      animation: {
        duration: 0,
      },
    },
    xAxis: {
      type: "datetime",
      crosshair: true,
      // min: Formatters.toLocalDatetimeValue(cycle?.start_date),
      // max: Formatters.toLocalDatetimeValue(cycle?.end_date),
    },
    yAxis: {
      crosshair: true,
      title: {
        text: "",
      },
      plotBands: item?.alertRules
        ? [
            {
              color: colors.green[50],
              from: item?.alertRules?.min || 0,
              to: item?.alertRules?.max || 0,
              label: {
                text: "MID",
              },
            },
            {
              color: colors.red[50],
              from: item?.alertRules?.max || 0,
              to: 999999999,
              label: {
                text: "HIGH",
              },
            },
            {
              color: colors.blue[50],
              from: -999999999,
              to: item?.alertRules?.min || 0,
              label: {
                text: "LOW",
              },
            },
          ]
        : [],
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        stacking: "overlap",
      },
      column: {
        maxPointWidth: 30,
        grouping: true,
      },
    },
    series: [
      {
        name: item?.field?.field_name,
        type: "line",
        data: item?.timeseries?.map((ele) => ({
          x: Formatters.Time.toLocalDatetimeValue(ele.datetime),
          y: ele.value,
        })),
      },
    ],
  };

  return (
    <Box>
      <Box mb={1}>
        <Typography fontSize={14} fontWeight={800}>
          <Text fallback={item?.field?.field_name}>{`fields.${item?.field?.field_id}`}</Text> <span style={{ color: colors.grey[500] }}> {item?.field?.field_unit}</span>
        </Typography>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Typography fontSize={20} fontWeight={"800"}>
            {item?.current?.value ? NumericFormatters.format({ value: item?.current?.value }) : "-"}
          </Typography>
          <Typography fontSize={12} color={valueChangeColor}>
            {item?.change?.diff_perc ? NumericFormatters.format({ value: item?.change?.diff_perc * 100, suffix: "%", decimalScale: 0 }) : "-"}
          </Typography>
        </Stack>
      </Box>
      {hasData ? <HighchartsWrapper options={chartConfigs} /> : <VerticalAlertBoxCollections.NoData />}
    </Box>
  );
};

const PondWaterQualityTimeseriesChart = ({ data, fields }) => {
  const [viewFieldId, setViewFieldId] = useState(null);

  useEffect(() => {
    setViewFieldId(_.first(fields)?.field_id);
  }, [fields]);

  const Actions = {
    onSelectField: (newFieldId) => {
      setViewFieldId(newFieldId);
    },
  };

  const reorderedFields = [...fields.filter((ele) => data?.[ele.field_id]?.size > 0), ...fields.filter((ele) => data?.[ele.field_id]?.size === 0)];

  return (
    <Grid container>
      <Grid item minWidth={200} xs={3}>
        <Stack
          sx={{
            maxHeight: "calc(100vh - 400px)",
            overflow: "auto",
          }}
          pr={1}
          spacing={0.3}
        >
          {reorderedFields.map((ele) => (
            <SidebarItem key={ele.field_id} selected={ele?.field_id === viewFieldId} item={data?.[ele.field_id]} actions={Actions} />
          ))}
        </Stack>
      </Grid>
      <Grid item xs={9}>
        <Box position={"relative"} p={1} width={"100%"}>
          {data?.[viewFieldId] && <TheChart item={data?.[viewFieldId]} />}
        </Box>
      </Grid>
    </Grid>
  );
};

export default PondWaterQualityTimeseriesChart;
