import React, { useEffect, useState } from "react";
import { groupBy } from "../../helpers/Utils";
import { api_water_auctions } from "api/api";
import { Box, Card, CardContent, CardHeader, Divider, IconButton, Stack, Tooltip } from "@mui/material";
import SmartTable from "components/mui/SmartTable";
import { InfoIcon } from "components/Icons/MaterialIcons";
import LoadingBox from "components/Loading/LoadingBox";
import WidgetCard from "ui/Card/WidgetCard";
import { AgGridReact } from "ag-grid-react";

function renderZone(data) {
  data.list = data.list.map((ele, idx) => ({
    ...ele,
    bids:
      ele.bids.length > 0 ? (
        <span key={idx}>
          <Tooltip
            title={
              <Box sx={{ backgroudColor: "white" }}>
                {ele.bids.map((ele) => (
                  <Box>{`${ele.date}: ${ele.current_bid_price}`}</Box>
                ))}
              </Box>
            }
          >
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </span>
      ) : (
        ""
      ),
  }));

  const columns = [
    "auction_id",
    "megs",
    "date_create",
    "date_end",
    "days_left",
    "hours_left",
    "start_price",
    "current_price",
    "BIN_price",
    "reserve_price",
    "winner",
    "winner_email",
    "licence",
    "customer_id",
    "bids",
  ].map((ele) => ({ label: ele, accessor: ele, headerName: ele, field: ele }));

  const tableConfigs = {
    rowData: data.list,
    columnDefs: columns,
    defaultColDef: {
      flex: 1,
    },
    rowHeight: 20,
  };

  return (
    <WidgetCard title={data.group}>
      <Box className="ag-theme-clean" height={200}>
        <AgGridReact {...tableConfigs} />
      </Box>
    </WidgetCard>
  );
}

const EntitlementOrders = () => {
  const [grouped, setGrouped] = useState([]);

  const getData = async () => {
    const data = await api_water_auctions();
    const grouped = groupBy(data.content, "entitlement_type");
    setGrouped(grouped);
  };

  useEffect(() => {
    getData();
  }, []);

  if (grouped.length < 1) {
    return <LoadingBox />;
  }

  return (
    <Box p={3}>
      <Stack spacing={2}>
        {grouped.map((ele) => {
          return renderZone(ele);
        })}
      </Stack>
    </Box>
  );
};

export default EntitlementOrders;
