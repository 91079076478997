import { Box, Button, Grid, Stack, Typography, colors } from "@mui/material";
import { theme } from "App";
import { PondManagerServices } from "api/pondManagerServices";
import AlertBox, { NoCycleAlertBox } from "components/Alerts/AlertBox";
import MuiDatetimePicker from "components/DatePicker/MuiDatetimePicker";
import MuiBigTextField from "components/input/MuiBigTextField";
import { HelpOutlineIcon } from "components/Icons/MaterialIcons";
import Text, { getText } from "components/text/Text";
import { Form, Formik } from "formik";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { AppSelectors } from "redux/AppReducers";
import DataLogs from "screens/Aquaculture/components/DataManager/components/DataLogs";
import StatsBar from "screens/Aquaculture/components/common/StatsBar";
import Formatters from "helpers/Formatters";
import HighchartsWrapper from "components/Highcharts/HighchartsWrapper";
import NumericFormatters from "helpers/NumericFormatters";

const generateChartConfigs = ({ rows }) => {
  const configs = {
    data: {
      datasets: [
        {
          label: getText("interface.general.current-biomass"),
          data: rows
            .filter((ele) => ele.field_id === "biomass")
            .map((ele) => ({
              x: moment(ele.datetime),
              y: ele.value,
            })),
          borderColor: theme.palette.secondary.main,
          backgroundColor: theme.palette.secondary.main,
          pointRadius: 2,
          borderWidth: 2,
          yAxisID: "y",
        },
        {
          label: getText("interface.general.harvest"),
          type: "bar",
          data: rows
            .filter((ele) => ele.field_id === "harvest_biomass")
            .map((ele) => ({
              x: moment(ele.datetime),
              y: ele.value,
            })),
          borderColor: colors.blue[600],
          backgroundColor: colors.blue[600],
          pointRadius: 2,
          borderWidth: 2,
          yAxisID: "y1",
        },
      ],
    },
    type: "line",
    options: {
      // responsive: true,
      maintainAspectRatio: false,
      animation: {
        duration: 0,
      },
      plugins: {},
      scales: {
        x: {
          display: true,
          type: "time",
          time: {
            unit: "week",
            displayFormats: {
              day: "YYYY-MM-DD",
              week: "YYYY-MM-DD",
              month: "YYYY-MM",
              quarter: "[Q]Q - YYYY",
              year: "YYYY",
            },
          },
        },
        y: {
          title: {
            display: true,
            align: "center",
            text: `${getText("interface.general.current-biomass")} kg`,
          },
          grid: {
            drawOnChartArea: false, // only want the grid lines for one axis to show up
          },
        },
        y1: {
          position: "right",
          title: {
            display: true,
            align: "center",
            text: `${getText("interface.general.harvest")} kg`,
          },
          grid: {
            drawOnChartArea: false, // only want the grid lines for one axis to show up
          },
        },
      },
    },
  };
  return configs;
};

/**
 * ************************************************
 *
 *
 * Charts
 *
 *
 * ************************************************
 */

const BiomassAndHarvestChart = ({ rows, cycle }) => {
  const chartConfigs = {
    chart: {
      height: "300px",
      animation: {
        duration: 0,
      },
    },
    xAxis: {
      type: "datetime",
      min: Formatters.toLocalDatetimeValue(cycle?.start_date),
      max: Formatters.toLocalDatetimeValue(cycle?.end_date),
    },
    series: [
      {
        name: getText("interface.general.biomass"),
        type: "area",
        fillOpacity: 0.1,
        color: colors.orange[500],
        data: rows
          .filter((ele) => ele.field_id === "biomass")
          .map((ele) => ({
            x: Formatters.toLocalDatetimeValue(ele.datetime),
            y: ele.value,
          })),
      },
      {
        name: getText("interface.general.harvest"),
        type: "column",
        fillOpacity: 0.1,
        color: colors.red[500],
        data: rows
          .filter((ele) => ele.field_id === "harvest_biomass")
          .map((ele) => ({
            x: Formatters.toLocalDatetimeValue(ele.datetime),
            y: ele.value,
          })),
      },
    ],
  };

  return <HighchartsWrapper options={chartConfigs} />;
};

/**
 * ************************************************
 *
 *
 * Main Component
 *
 *
 * ************************************************
 */

const HarvestUploader = ({ farm, pond }) => {
  const [data, setdata] = useState({
    raw: [],
  });

  const cycleManagementStore = AppSelectors.cycleStore();

  const selectedCycle = cycleManagementStore?.selectedCycle;
  const isCycleSelected = _.isEmpty(selectedCycle);

  useEffect(() => {
    if (pond && !_.isEmpty(selectedCycle)) {
      Actions.onLoadData();
    }
  }, [pond, selectedCycle]);

  const Actions = {
    onLoadData: async () => {
      const response = await PondManagerServices.getTimeseriesData(pond?.id, ["biomass", "harvest_biomass"], selectedCycle?.start_date, selectedCycle?.end_date);
      setdata(response);
    },
    submitData: async (requestData) => {
      if (requestData.length > 0) {
        const response = await PondManagerServices.putData({
          rows: requestData,
        });
        Actions.onLoadData();
      }
    },
  };

  if (!pond) {
    return (
      <Box p={2}>
        <AlertBox icon={<HelpOutlineIcon />}>
          <Text>interface.alert.please-select-a-pond</Text>!
        </AlertBox>
      </Box>
    );
  }

  if (isCycleSelected)
    return (
      <Box p={2}>
        <NoCycleAlertBox pondId={pond?.id} />
      </Box>
    );

  const stats = {
    total_harvest_biomass: _.sumBy(data?.rows?.filter((ele) => ele["harvest_biomass"]) || [], "harvest_biomass"),
    current_biommass: _.last(data?.rows?.filter((ele) => ele["biomass"]) || [])?.biomass,
    total_biomass_produced: _.sumBy(data?.rows?.filter((ele) => ele["harvest_biomass"]) || [], "harvest_biomass") + _.last(data?.rows?.filter((ele) => ele["biomass"]) || [])?.biomass,
  };

  // Data Logs Configs

  return (
    <Box p={2}>
      <Box p={2} bgcolor={theme.palette.primary.light} borderRadius={2}>
        <Typography variant="cardTitle" color="primary">
          <Text>interface.actions.record-new-data</Text>
        </Typography>
        <Box mt={2}>
          <Formik
            initialValues={{
              created: moment(),
            }}
            onSubmit={(values) => {
              if (values.harvest_biomass) {
                const requestData = ["harvest_biomass"]
                  .filter((f) => values[f])
                  .map((f) => ({
                    datetime: values.created.format("YYYY-MM-DD hh:mm:ss"),
                    value: values[f],
                    pond_id: pond.id,
                    field_id: f,
                  }));
                Actions.submitData(requestData);
              }
            }}
          >
            {({ values, handleChange, setFieldValue }) => {
              return (
                <Form>
                  <Stack spacing={1}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} lg={4}>
                        <MuiDatetimePicker
                          value={values.created}
                          onAccept={(newValue) => {
                            setFieldValue("created", newValue);
                          }}
                          onChange={(newValue) => {
                            setFieldValue("created", newValue);
                          }}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={4}>
                        <MuiBigTextField name="harvest_biomass" onChange={handleChange} label={`${getText("fields.harvest_biomass")} (kg)`} fullWidth></MuiBigTextField>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <Button variant="contained" type="submit" fullWidth>
                          <Text>interface.actions.submit</Text>
                        </Button>
                      </Grid>
                    </Grid>
                  </Stack>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>

      <Box mt={2} p={2} bgcolor={colors.grey[100]} borderRadius={2}>
        <Typography variant="cardTitle" color="primary">
          <Text>interface.general.current-data</Text>
        </Typography>
        <Box mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <StatsBar
                list={[
                  {
                    label: <Text>interface.general.current-biomass</Text>,
                    value: NumericFormatters.formatWeightInKg({ value: stats.current_biommass }),
                  },
                  {
                    label: <Text>interface.general.total-harvested-biomass</Text>,
                    value: NumericFormatters.formatWeightInKg({ value: stats.total_harvest_biomass }),
                  },
                  {
                    label: <Text>interface.general.total-biomass-produced</Text>,
                    value: NumericFormatters.formatWeightInKg({ value: stats.total_biomass_produced }),
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <BiomassAndHarvestChart rows={data?.raw} cycle={selectedCycle} />
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box mt={2} p={2} bgcolor={colors.grey[100]} borderRadius={2}>
        <Typography variant="cardTitle" color="primary">
          <Text>interface.general.data-logs</Text>
        </Typography>
        <Box mt={2}>
          <DataLogs
            rows={_.orderBy(
              data.raw.filter((ele) => ele.field_id === "harvest_biomass"),
              "datetime",
              "desc"
            )}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default HarvestUploader;
