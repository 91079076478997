import { colors } from "@mui/material";

/**
 *
 * Shrimpl Color Palette
 *
 * NAVY: #142E3B
 * CORAL: #FF7A6B
 * SEA: #A3C0A6
 * SALMON: #F1D2C4
 * MIST: #E5E1DE
 * WHITE: #FFFFFF
 *
 */

const palette = {
  primary: {
    main: "#142e3b",
    // main: "#40866f",
    light: "#f1f7fa",
    // light: "#F3FBFA",
  },
  secondary: {
    main: "#ea6e5b",
    light: "#fef8f7",
  },
  info: {
    main: "#2097c4",
    light: "#e8f6fb",
  },
  purple: {
    main: colors.purple[700],
    light: colors.purple[50],
    contrastText: colors.purple[700],
    dark: colors.purple[900],
  },
  org: {
    main: colors.blue[700],
    light: colors.blue[50],
    contrastText: colors.blue[50],
    dark: colors.blue[900],
  },
  "btn:grey": {
    main: colors.grey[400],
    light: colors.grey[700],
    contrastText: colors.grey[900],
    dark: colors.grey[100],
  },
  gis: {
    main: colors.purple[500],
    light: colors.purple[200],
    contrastText: "#FFF",
    dark: colors.purple[800],
  },
  orange: {
    main: colors.orange[600],
    light: colors.orange[50],
    dark: colors.orange[900],
  },
};

export default palette;
