import { AppSettings } from "helpers/LocalStorage";
import { HomeIcon, PersonIcon } from "components/Icons/MaterialIcons";
import { FaHome, FaWater } from "react-icons/fa";
import { FaGear, FaHandHoldingDroplet, FaUserGear } from "react-icons/fa6";
import { isMobile } from "themes/helpers";
import { GiShrimp } from "react-icons/gi";
import { Person } from "@mui/icons-material";
import { Roles } from "configs/appConfigs";

const mobileMode = isMobile();

const commonComponents = [
  {
    name: "Settings",
    path: "/settings",
    icon: <FaGear />,
  },
];

const getAppComponents = () => {
  const role = AppSettings.Auth.getRole();

  if (!role) {
    return [];
  }

  // if it's mobile mode
  if (mobileMode) {
    return [
      {
        apps: [
          { name: "Home", path: "/", icon: <FaHome />, exact: true },
          { name: "Aqua", path: "/aqua", icon: <GiShrimp /> },
          { name: "Profile", path: "/settings", icon: <PersonIcon /> },
        ],
      },
    ];
  }

  if (role === Roles.INTERNAL) {
    return [
      {
        apps: [
          {
            name: "Home",
            path: "/",
            icon: <FaHome />,
            exact: true,
          },
          {
            name: "Aqua",
            path: "/aqua",
            icon: <FaWater />,
          },
          {
            name: "Water Market",
            path: "/au-water",
            icon: <FaHandHoldingDroplet />,
          },
          {
            name: "Shrimpl Admin",
            path: "/admin",
            icon: <FaUserGear />,
          },
          ...commonComponents,
        ],
      },
    ];
  }
  if (role === Roles.SHRIMPSTAR_USER || role === Roles.SHRIMPL_USER) {
    return mobileMode
      ? [
          {
            section: "Shrimpl",
            apps: [
              {
                name: "Aqua",
                path: "/aqua",
                icon: <HomeIcon />,
              },
              {
                name: "Settings",
                path: "/settings",
                icon: <Person />,
              },
            ],
          },
        ]
      : [
          {
            section: "Shrimpl",
            apps: [
              {
                name: "Aqua",
                path: "/aqua",
                icon: <FaWater />,
              },
              ...commonComponents,
            ],
          },
        ];
  }
  return [
    {
      apps: [
        {
          name: "Home",
          path: "/",
          icon: <FaHome />,
          exact: true,
        },
      ],
    },
  ];
};

export const getHomepagePath = (role) => {
  const defaultPath = "/";
  return defaultPath;
};

const appComponents = getAppComponents();

export { appComponents };
