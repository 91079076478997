import { Box, Button, Container, FormControl, InputLabel, LinearProgress, MenuItem, Select, Stack, Typography, colors } from "@mui/material";
import { AppsIcon, HelpOutlineIcon } from "components/Icons/MaterialIcons";
import AppLocalStorage, { KEYMAPPING } from "helpers/LocalStorage";
import _ from "lodash";
import { useEffect, useState } from "react";
import PondManagerMapView from "screens/Aquaculture/components/PondManagerMapView";

import { PondManagerServices } from "api/pondManagerServices";
import Text, { getText } from "components/text/Text";
import { getColorsBasedOnListOfText } from "helpers/ColorHelpers";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AppSelectors } from "redux/AppReducers";
import { alertsActions } from "redux/alerts";
import { modalIds, modalReducersActions } from "redux/modal";
import { cycleManagementActions } from "redux/pondManagement/cycleManagement";
import { organisationManagementActions } from "redux/pondManagement/organisationManagement";
import { pathologyManagementActions } from "redux/pondManagement/pathologyManagement";
import { pondManagementActions } from "redux/pondManagement/pondManagement";
import { ROUTE_CLIENT_MANAGER } from "routes/paths";
import OrgBadge from "screens/Aquaculture/Organisations/components/OrgBadge";
import ClimateWidget from "screens/Aquaculture/components/Climate/ClimateWidget";

const appStorage = new AppLocalStorage();

/**
 *
 * Ponds Management System Main
 *
 * @returns
 */

const ClientManager = () => {
  // hook
  const navigate = useNavigate();
  // hook
  const dispatch = useDispatch();

  // ~~~ Redux ~~~
  const pondManagementStore = AppSelectors.pondManagementStore();
  const fields = pondManagementStore.fields;

  const orgManagementStore = AppSelectors.orgStore();

  const farms = orgManagementStore.viewFarms;

  // fetch farmid and other parameters from the URL
  const params = useParams();
  const farmid = params["farmid"];
  const orgId = params["orgid"];

  const contentDisplayConfigs = {
    org: _.find(orgManagementStore.userOrgs, { "org.id": orgId }),
  };

  const [selectedFarm, setSelectedFarm] = useState(null);
  const [selectedGroup, setGroup] = useState(null);
  const [selectedPondData, setPondData] = useState(null);
  const [ponds, setPonds] = useState([]);
  const [pondsGeoJson, setPondsGeoJson] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const run = async () => {
      if (orgId) {
        await organisationManagementActions.onSelectViewAsOrg(dispatch, orgId);
      }
    };
    run();
  }, [orgId]);

  // tigger for redux
  useEffect(() => {
    const run = async () => {
      if (farmid) {
        // init org
        await organisationManagementActions.loadUserOrgs(dispatch);
        await organisationManagementActions.onSelectViewAsOrg(dispatch, orgId);

        // select a farm to view
        await pondManagementActions.selectFarm(dispatch, { farmId: farmid });

        // init unit prices data
        await pondManagementActions.loadShrmipUnitPrices(dispatch, farmid);

        // init Pathology data
        await pathologyManagementActions.initData(dispatch);
      }
    };

    run();
  }, [dispatch]);

  useEffect(() => {
    // validate farm ownership
    if (farms.length === 0) return;
    if (farmid) {
      const farm = _.find(farms, { farm_id: farmid });
      if (farm && farms) {
        pondManagementActions.selectFarm(dispatch, { farm });
        setSelectedFarm(farm);
        return;
      } else {
        navigate(ROUTE_CLIENT_MANAGER);
      }
    } else {
      navigate(ROUTE_CLIENT_MANAGER);
    }
  }, [farms]);

  /** Call when new farm is selected */
  useEffect(() => {
    const run = async () => {
      await getPonds(selectedFarm);
      await cycleManagementActions.loadAllCyclesByFarmId(dispatch, selectedFarm.farm_id);
    };
    selectedFarm && run();
  }, [selectedFarm]);

  /** Trigger when pondData is update */
  useEffect(() => {
    selectedPondData && modalReducersActions.openModal(dispatch, { id: modalIds.POND_EDIT_MODAL });
  }, [selectedPondData]);

  const getPonds = async (farm) => {
    const farmId = farm ? farm?.farm_id : selectedFarm.farm_id;
    if (!farmId) return;
    setLoading(true);
    // if farm is selected
    // TODO: update below to redux
    const pondsResult = await PondManagerServices.listPonds(farmId);
    const geojsonResult = await PondManagerServices.getPondsGeoJson(farmId);
    setPonds(
      pondsResult.sort((a, b) =>
        a?.label.localeCompare(b?.label, undefined, {
          numeric: true,
        })
      )
    );
    setPondsGeoJson(geojsonResult);
    // if saved group is not exist, update it to ALL
    const isSelectedGroupValid = _.includes(
      pondsResult.map((p) => p.group),
      selectedGroup
    );
    if (!isSelectedGroupValid) setGroup(null);
    // automatically open pond creation page when there's no ponds created
    if (pondsResult && pondsResult.length < 1 && farm.access_level_write) {
      Actions.pond.onCreatePond();
    }
    setLoading(false);
  };

  const filteredPonds = ponds.filter((e) => (selectedGroup ? e.group === selectedGroup : true));

  const filteredPondsAsGeoJson = pondsGeoJson && {
    ...pondsGeoJson,
    features: pondsGeoJson.features.filter((e) => (selectedGroup ? e.properties.group === selectedGroup : true)),
  };

  const groupSelections = _.unionBy(ponds, "group")
    .map((e) => e.group)
    .filter((e) => e)
    .sort();

  const Actions = {
    pond: {
      onCreatePond: async () => {
        setPondData({
          label: "New Pond",
          category: "Shrimp",
          farm_id: selectedFarm?.farm_id,
          lat: selectedFarm?.lat,
          lon: selectedFarm?.lon,
        });
      },
      onEditPond: async (pondId) => {
        const data = await PondManagerServices.getPond(pondId);
        setPondData(data);
      },
      onClonePond: async (pondId) => {
        const data = await PondManagerServices.getPond(pondId);
        const { id, ...dataWithoutId } = data;
        setPondData(dataWithoutId);
      },

      onDeletePond: async (pondId) => {
        await PondManagerServices.deletePond(pondId);
        await getPonds(selectedFarm);
        alertsActions.addInfo(dispatch, {
          content: "Pond has been removed",
        });
      },
    },
    group: {
      onSelectGroup: async (group) => {
        setGroup(group);
        appStorage.save(KEYMAPPING.SETTINGS.SELECTED_GROUP, group);
      },
    },
  };

  // ============
  // START RENDER
  // ============

  /** The summary data in the header */
  const HeaderPondSummary = () => {
    const stats = {
      count: filteredPonds.length,
      total_area: (_.sum(filteredPonds.map((ele) => ele.area)) || 0).toFixed(2),
      total_area_ac: ((_.sum(filteredPonds.map((ele) => ele.area)) || 0) * 2.47105).toFixed(2),
    };

    const statsToDisplayList = [
      {
        label: getText("interface.general.farm"),
        value: selectedFarm ? selectedFarm.label : "All",
      },
      {
        label: selectedFarm?.country,
        value: selectedFarm?.region,
      },
    ];

    return (
      <Stack direction="row">
        {statsToDisplayList.map((item, idx) => (
          <Box
            key={idx}
            px={1}
            sx={{
              borderRight: idx !== statsToDisplayList.length - 1 && "1px solid #eee",
            }}
          >
            <Typography fontSize={10}>{item.label}</Typography>
            <Typography fontWeight="bold">{item.value}</Typography>
          </Box>
        ))}
      </Stack>
    );
  };

  const pondGroupColorMapping = getColorsBasedOnListOfText(ponds.filter((ele) => ele.group).map((ele) => ele.group));

  return (
    <Box>
      <Box sx={{ height: "3px" }}>{pondManagementStore.loading && <LinearProgress sx={{ height: "100%" }} />}</Box>
      <Stack
        direction={"row"}
        spacing={1}
        justifyContent={"space-between"}
        alignItems={"center"}
        p={1}
        sx={{
          bgcolor: "#fff",
        }}
      >
        {/* Top Bar */}
        <Stack direction="row" alignItems="center" spacing={1} height="100%">
          {/* Top Bar > Filters */}
          {selectedFarm && (
            <>
              <Stack direction="row" alignItems={"center"} spacing={1}>
                <Button variant="text" startIcon={<AppsIcon />} component={Link} to={ROUTE_CLIENT_MANAGER}>
                  <Text>interface.actions.return</Text>
                </Button>
                <OrgBadge org={contentDisplayConfigs.org} />
                <FormControl sx={{ minWidth: "130px" }} size="small">
                  <InputLabel id="selectedGroupLabel">
                    {" "}
                    <Text>interface.general.group</Text>
                  </InputLabel>
                  <Select
                    label={<Text>interface.general.group</Text>}
                    labelId="selectedGroupLabel"
                    id="selectedGroup"
                    value={selectedGroup}
                    onChange={(e) => Actions.group.onSelectGroup(e.target.value)}
                  >
                    {[{ label: "- All -", value: null }, ...groupSelections.map((f) => ({ label: f, value: f }))].map((item, idx) => (
                      <MenuItem key={idx} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </>
          )}
        </Stack>
        {/* TopBar > Summary */}
        <Stack direction="row" spacing={1}>
          <ClimateWidget farm={selectedFarm} />
          <HeaderPondSummary />
        </Stack>
      </Stack>
      <Box sx={{ height: "1px" }}>{loading && <LinearProgress color="secondary" sx={{ height: "100%" }} />}</Box>
      {filteredPondsAsGeoJson &&
        (filteredPondsAsGeoJson.features.length > 0 ? (
          <PondManagerMapView
            fields={fields}
            ponds={filteredPonds}
            pondsGeoJson={filteredPondsAsGeoJson}
            selectedFarm={selectedFarm}
            selectedGroup={selectedGroup}
            style={{
              colorMapping: pondGroupColorMapping,
            }}
            handleEditAction={Actions.pond.onEditPond}
            handleDeleteAction={Actions.pond.onDeletePond}
            handleCloneAction={Actions.pond.onClonePond}
            handleSelectGroupAction={Actions.group.onSelectGroup}
            getPonds={() => {
              getPonds();
              pondManagementActions.loadAllFarms(dispatch);
            }}
          />
        ) : (
          <Container>
            <Stack alignItems={"center"} p={4}>
              <HelpOutlineIcon sx={{ fontSize: "10rem", color: colors.grey[300] }} />
              <Typography sx={{ fontSize: "3rem", color: colors.grey[300] }}>
                <Text>interface.general.no-pond-warning</Text>
              </Typography>
            </Stack>
          </Container>
        ))}
    </Box>
  );
};

export default ClientManager;
