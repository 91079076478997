import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { CloseIcon } from "components/Icons/MaterialIcons";
import { saveObjectAsJsonFile } from "helpers/FileHelpers";
import TimeFormatters from "helpers/TimeFormatters";
import React from "react";
import WidgetCard from "ui/Card/WidgetCard";

const AoiCard = ({ feature, actions }) => {
  const status = feature.properties.status;
  return (
    <WidgetCard>
      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
        <Box>
          <Typography variant="cardTitle">AOI</Typography>
        </Box>
        <Box>
          <IconButton onClick={actions.unselectAoi}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Stack>
      <Stack>
        <Typography fontSize={10} color="grey">
          {feature.properties.id}
        </Typography>
        <Typography fontSize={20}>{feature.properties.label}</Typography>
        <Typography>
          Status: <strong>{feature.properties.status}</strong>
        </Typography>
        <Typography>
          Grid Size: <strong>{feature.properties.size} Km</strong>
        </Typography>
        <Typography>
          Created: <strong>{TimeFormatters.formatDatetime(feature.properties.created)}</strong> UTC
        </Typography>
      </Stack>
      <Stack mt={1} direction={"row"} spacing={1}>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            actions.onDeleteAoi(feature);
          }}
          disabled={status !== "PENDING"}
        >
          Delete
        </Button>
        <Button
          variant="contained"
          color="info"
          onClick={() => {
            saveObjectAsJsonFile({
              data: feature,
              filename: `${feature.properties.label}.geojson`,
            });
          }}
        >
          Download GeoJSON
        </Button>
      </Stack>
    </WidgetCard>
  );
};

export default AoiCard;
