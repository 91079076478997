import { Avatar, Box, Grid, Stack, Typography } from "@mui/material";
import CommonForm, { FormFieldTypes } from "components/Form/CommonForm";
import CountryFlagSvgIcon from "components/Icons/CountryFlagSvgIcon";
import { BusinessIcon } from "components/Icons/MaterialIcons";
import FarmingProductTypes from "constants/FarmingProductTypes";
import _ from "lodash";
import React from "react";

const ProductForm = ({ product, producerId, producers, onSubmit }) => {
  const formFields = [
    {
      name: "name",
      type: FormFieldTypes.text,
      label: "Name",
      span: 2,
      options: {
        required: true,
      },
    },
    {
      name: "name_key",
      type: FormFieldTypes.text,
      label: "Name Key",
      span: 2,
    },
    {
      name: "product_type",
      type: FormFieldTypes.select,
      label: "Product Type",
      span: 2,
      options: {
        required: true,
        options: _.values(FarmingProductTypes),
      },
    },
    {
      name: "producer_id",
      type: FormFieldTypes.select,
      label: "Producer",
      span: 2,
      options: {
        required: true,
        options: _.orderBy(producers, ["country_code", "name"]).map((ele) => ({
          label: (
            <Stack direction="row" justifyContent={"space-between"} sx={{ width: "100%" }} key={ele.id}>
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <Box>
                  <Avatar
                    src={ele.logo_url}
                    sx={{
                      width: 20,
                      height: 20,
                      border: "1px solid #CCC",
                    }}
                  >
                    <BusinessIcon />
                  </Avatar>
                </Box>
                <Box>
                  <Typography fontSize={13} fontWeight={800}>
                    {ele.name}
                  </Typography>
                </Box>
              </Stack>
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <Box>
                  <CountryFlagSvgIcon countryCode={ele.country_code} />
                </Box>
              </Stack>
            </Stack>
          ),
          value: ele.id,
        })),
      },
    },
    {
      name: "category",
      type: FormFieldTypes.text,
      label: "Category",
      span: 1,
    },
    {
      name: "sub_category",
      type: FormFieldTypes.text,
      label: "Sub Category",
      span: 1,
    },
    {
      name: "net_weight",
      type: FormFieldTypes.numeric,
      label: "Net Weight / Volume Unit",
      span: 1,
    },
    {
      name: "net_weight_unit",
      type: FormFieldTypes.select,
      label: "Net Weight / Volume Unit",
      span: 1,
      options: {
        options: [
          {
            label: "Net weight",
            children: [
              {
                label: "Grams (g)",
                value: "g",
              },
              {
                label: "Kilograms (kg)",
                value: "kg",
              },
              {
                label: "Ounces (oz)",
                value: "oz",
              },
              {
                label: "Pounds (lb)",
                value: "lb",
              },
            ],
          },
          {
            label: "Volume",
            children: [
              {
                label: "Litre (L)",
                value: "l",
              },
              {
                label: "Millilitre (ML)",
                value: "ml",
              },
            ],
          },
        ],
      },
    },
    {
      name: "description",
      type: FormFieldTypes.text,
      label: "Description",
      span: 2,
      options: {
        multiline: true,
        minRows: 5,
      },
    },
    {
      name: "link",
      type: FormFieldTypes.text,
      label: "Website Link",
      span: 2,
    },
    {
      name: "image_url",
      type: FormFieldTypes.text,
      label: "Image Url",
      span: 2,
      description: product && product?.image_url && <img src={product.image_url} width={500} />,
    },
  ];

  const initialValues = product || {
    name: "",
    product_type: FarmingProductTypes.FEED.value,
    producer_id: producerId,
  };

  const Header = () => {
    if (product) {
      return (
        <Box mb={3}>
          <Typography variant="cardTitle">Edit Product</Typography>
          <Typography fontSize={12} color="grey">
            # {product.id}
          </Typography>
        </Box>
      );
    } else {
      return (
        <Box mb={3}>
          <Typography variant="cardTitle">Create a new Product</Typography>
        </Box>
      );
    }
  };

  return (
    <Box>
      <Header />
      <CommonForm columns={2} initialValues={initialValues} fields={formFields} onSubmit={onSubmit} />
    </Box>
  );
};

export default ProductForm;
