import { Box, Card, colors, Stack, Typography } from "@mui/material";
import React from "react";

const colorThemeMapping = {
  blue: {
    front: colors.blue[900],
    back: colors.blue[50],
  },
  red: {
    front: colors.red[900],
    back: colors.red[50],
  },
  yellow: {
    front: colors.orange[900],
    back: colors.orange[50],
  },
  green: {
    front: colors.green[900],
    back: colors.green[50],
  },
  pink: {
    front: colors.pink[900],
    back: colors.pink[50],
  },
  _default: {
    front: colors.grey[900],
    back: "#ffffff",
  },
};

const MuiIconNumberCard = ({ icon, title, value, color, colorTheme, subValue }) => {
  return (
    <Card
      sx={{
        background: colorTheme ? colorThemeMapping[colorTheme].back : colorThemeMapping._default.back,
        "&:hover": {
          boxShadow: 2,
        },
      }}
    >
      <Stack p={2} direction={"row"} alignItems={"center"}>
        <Box>
          <Typography fontSize={10} fontWeight={800} color={colorTheme ? colorThemeMapping[colorTheme].front : colors.grey[600]} letterSpacing={1} sx={{ textTransform: "uppercase" }}>
            {title}
          </Typography>
          <Typography color={colorTheme ? colorThemeMapping[colorTheme].front : colorThemeMapping._default.front} fontSize={20} fontWeight={800}>
            {value}
          </Typography>
        </Box>
        {icon && (
          <Box
            sx={{
              color: "white",
              background: colors[color || "blue"][500],
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "50px",
              height: "50px",
              borderRadius: "50%",
            }}
          >
            {icon}
          </Box>
        )}
      </Stack>
    </Card>
  );
};

export default MuiIconNumberCard;
