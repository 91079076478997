import { Box, Divider, Stack, colors } from "@mui/material";
import HighchartsWrapper from "components/Highcharts/HighchartsWrapper";
import { getText } from "components/text/Text";
import { formatValueByFieldId } from "helpers/NumericFormatters";
import _ from "lodash";
import React, { useState } from "react";
import MuiTabs, { MuiTabsThemes } from "ui/tabs/MuiTabs";

/** ----- Helpers */
/**
 * Calculate the average, standard deviation, and derived values for each doc.
 * @param {Array<Array<{doc: number, value: number}>>} dataList - List of arrays containing {doc, value} objects.
 * @returns {Array<{doc: number, average: number, std: number, avg_plus_std: number, avg_minus_std: number}>}
 */
function calculateSmoothedStatsWithRegression(dataList) {
  // Create a map to aggregate values by doc
  const docMap = new Map();

  // Aggregate all values by doc
  dataList.forEach((arr) => {
    arr.forEach(({ doc, value }) => {
      if (!docMap.has(doc)) {
        docMap.set(doc, []);
      }
      docMap.get(doc).push(value);
    });
  });

  // Sort docs numerically
  const sortedDocs = Array.from(docMap.keys()).sort((a, b) => a - b);

  // Prepare arrays for linear regression
  const docs = [];
  const values = [];

  sortedDocs.forEach((doc) => {
    const docValues = docMap.get(doc);
    docValues.forEach((value) => {
      docs.push(doc);
      values.push(value);
    });
  });

  // Perform linear regression (finding the best fit line y = mx + b)
  const n = docs.length;
  const sumX = docs.reduce((sum, x) => sum + x, 0);
  const sumY = values.reduce((sum, y) => sum + y, 0);
  const sumXY = docs.reduce((sum, x, i) => sum + x * values[i], 0);
  const sumXX = docs.reduce((sum, x) => sum + x * x, 0);

  const m = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
  const b = (sumY - m * sumX) / n;

  // Calculate the smoothed values
  const smoothedValues = docs.map((x) => m * x + b);

  // Calculate average, standard deviation, and derived values
  const result = [];
  sortedDocs.forEach((doc) => {
    const docIndices = docs.map((x, i) => (x === doc ? i : -1)).filter((i) => i >= 0);
    const smoothedForDoc = docIndices.map((i) => smoothedValues[i]);

    const docCount = smoothedForDoc.length;
    const average = smoothedForDoc.reduce((sum, val) => sum + val, 0) / docCount;

    const variance = smoothedForDoc.reduce((sum, val) => sum + Math.pow(val - average, 2), 0) / docCount;
    const std = Math.sqrt(variance);

    result.push({
      doc: doc,
      average: average,
      std: std,
      avg_plus_std: average + std,
      avg_minus_std: average - std,
    });
  });

  return result;
}

/**
 *
 * @param {*} param0
 * @returns
 */
const PondHistoricalCycleChart = ({ rows = [], cycles = [], fieldId = "", title = "Title", height = 300, viewMode, setViewMode, yearLimit, setYearLimit }) => {
  const viewModes = {
    normal: "normal",
    seasonal: "seasonal",
    noramlHead: "normalHead",
  };

  // some settings
  let latestColor = colors.grey[600];

  if (fieldId === "weight") {
    latestColor = colors.green[600];
  }
  if (fieldId === "weight_diff") {
    latestColor = colors.green[400];
  }
  if (fieldId === "biomass") {
    latestColor = colors.orange[600];
  }
  if (fieldId === "organisms") {
    latestColor = colors.purple[600];
  }
  if (fieldId === "survival_rate") {
    latestColor = colors.blue[600];
  }
  if (fieldId === "seeded_density") {
    latestColor = colors.orange[600];
  }
  if (fieldId === "feed_daily") {
    latestColor = colors.brown[400];
  }
  if (fieldId === "feed_accumulated") {
    latestColor = colors.brown[400];
  }

  const generateChartConfigs = () => {
    if (viewMode === viewModes.normal) {
      let dataRows = rows.filter((ele) => ele?.[fieldId]).filter((ele) => (yearLimit ? Date.now() - ele._cycle_end <= 1000 * 60 * 60 * 24 * 365 * yearLimit : true));
      const dataRowsGrouped = _.groupBy(dataRows, "_cycle_id");

      const rowsByCycles = cycles
        .filter((item) => item.id in dataRowsGrouped)
        .map((item) => ({
          cycle: item,
          rows: dataRowsGrouped[item.id],
        }));

      return {
        chart: {
          height: `${height}px`,
          animation: false,
        },
        title: {
          text: "",
        },
        plotOptions: {
          series: {
            grouping: false,
            borderWidth: 0,
            marker: {
              enabled: false,
            },
            animation: false,
          },
        },
        legend: {
          enabled: true,
        },
        xAxis: {
          type: "datetime",
          title: {
            text: "Timestamp",
          },
        },
        yAxis: [
          {
            title: {
              text: title,
            },
          },
        ],
        series: [
          ...rowsByCycles.map((ele, idx) => ({
            type: "column",
            name: ele.cycle?.label,
            color: idx === rowsByCycles.length - 1 ? latestColor : null,
            opacity: idx === rowsByCycles.length - 1 ? 1 : 0.6,
            data: ele.rows.map((item) => ({
              x: item.datetime,
              y: item?.[fieldId],
            })),
          })),
        ],
      };
    }

    if (viewMode === viewModes.noramlHead) {
      let dataRows = rows.filter((ele) => ele?.[fieldId]).filter((ele) => (yearLimit ? Date.now() - ele._cycle_end <= 1000 * 60 * 60 * 24 * 365 * yearLimit : true));
      const dataRowsGrouped = _.groupBy(dataRows, "_cycle_id");

      const rowsByCycles = cycles
        .filter((item) => item.id in dataRowsGrouped)
        .map((item) => ({
          cycle: item,
          rows: dataRowsGrouped[item.id],
        }));

      return {
        chart: {
          height: `${height}px`,
          animation: false,
        },
        title: {
          text: "",
        },
        plotOptions: {
          series: {
            grouping: false,
            borderWidth: 0,
            marker: {
              enabled: false,
            },
            animation: false,
          },
        },
        legend: {
          enabled: false,
        },
        xAxis: {
          title: {
            text: getText("interface.general.cycle"),
          },
          categories: rowsByCycles.map((ele) => ele?.cycle?.label),
        },
        yAxis: [
          {
            title: {
              text: "",
            },
          },
        ],
        series: [
          {
            type: "column",
            name: title,
            data: rowsByCycles.map((ele, idx) => ({
              y: _.first(ele?.rows)?.[fieldId],
              color: idx === rowsByCycles.length - 1 ? latestColor : colors.grey[300],
            })),
            dataLabels: {
              enabled: true,
              formatter: function () {
                return formatValueByFieldId(this.y, fieldId);
              },
              useHTML: true,
            },
          },
        ],
      };
    }

    if (viewMode === viewModes.seasonal) {
      let dataRows = rows.filter((ele) => ele?.[fieldId]).filter((ele) => (yearLimit ? Date.now() - ele._cycle_end <= 1000 * 60 * 60 * 24 * 365 * yearLimit : true));
      const dataRowsGrouped = _.groupBy(dataRows, "_cycle_id");
      const rowsByCycles = cycles
        .filter((item) => item.id in dataRowsGrouped)
        .filter((item) => dataRowsGrouped?.[item.id]?.length > 0)
        .map((item) => ({
          cycle: item,
          rows: dataRowsGrouped[item.id],
        }));
      const historicalRowsByCycles = _.take(rowsByCycles, rowsByCycles.length - 1);
      const latestCycleRows = _.takeRight(rowsByCycles, 1);

      const historicalStats = calculateSmoothedStatsWithRegression(historicalRowsByCycles.map((item) => item.rows.map((ele) => ({ doc: ele.doc, value: ele?.[fieldId] }))));

      if (fieldId === "feed_accumulated") {
        console.log(rowsByCycles);
      }

      return {
        chart: {
          type: "line",
          height: `${height}px`,
          animation: false,
        },
        title: {
          text: "",
        },
        plotOptions: {
          series: {
            grouping: false,
            borderWidth: 0,
            marker: {
              enabled: false,
            },
            animation: false,
          },
        },
        legend: {
          enabled: true,
          maxHeight: 40,
        },
        xAxis: {
          type: "category",
          title: {
            text: "DoC",
          },
        },
        yAxis: [
          {
            title: {
              text: "",
            },
          },
        ],
        series: [
          ...historicalRowsByCycles.map((ele) => ({
            type: "line",
            name: ele.cycle?.label,
            color: colors.grey[400],
            lineWidth: 1,
            dashStyle: "Dash",
            data: ele.rows.map((item) => ({
              x: item.doc,
              y: item?.[fieldId],
            })),
            dataLabels: [
              {
                enabled: false,
              },
            ],
            showInLegend: false,
          })),
          {
            name: getText("interface.general.historical-average"),
            type: "line",
            color: colors.red[600],
            dashStyle: "Dash",
            data: historicalStats.map((r) => ({
              x: r.doc,
              y: r.average,
            })),
            opacity: 1,
            lineWidth: 2,
            dataLabels: {
              enabled: false,
            },
          },
          // {
          //   name: getText("interface.general.standard-deviation"),
          //   type: "arearange",
          //   color: colors.red[600],
          //   dashStyle: "Dash",
          //   data: historicalStats.map((r) => ({
          //     x: r.doc,
          //     high: r.avg_plus_std,
          //     low: r.avg_minus_std,
          //   })),
          //   fillOpacity: 0.1,
          //   lineWidth: 0,
          //   dataLabels: {
          //     enabled: false,
          //   },
          // },
          ...latestCycleRows.map((ele) => ({
            type: "line",
            name: ele.cycle?.label,
            color: latestColor,
            lineWidth: 3,
            dashStyle: "Solid",
            data: ele.rows.map((item) => ({
              x: item.doc,
              y: item?.[fieldId],
            })),
            dataLabels: [
              {
                enabled: true,
                style: {
                  fontSize: "10px",
                },
                formatter: function () {
                  return formatValueByFieldId(this.y, fieldId);
                },
              },
            ],
          })),
        ],
      };
    }
  };

  const chartConfigs = generateChartConfigs();

  return (
    <Box>
      <Stack spacing={1} direction={"row"}></Stack>
      <Box>{chartConfigs && <HighchartsWrapper key={viewMode} options={chartConfigs} />}</Box>
    </Box>
  );
};

export default PondHistoricalCycleChart;
