import { Box, Typography } from "@mui/material";
import CommonForm, { FormFieldTypes } from "components/Form/CommonForm";
import React from "react";

const ProducerForm = ({ producer, onSubmit }) => {
  const formFields = [
    {
      name: "name",
      type: FormFieldTypes.text,
      label: "Name",
      span: 2,
      options: {
        required: true,
      },
    },
    {
      name: "name_key",
      type: FormFieldTypes.text,
      label: "Name Key",
      span: 2,
    },
    {
      name: "country_code",
      type: FormFieldTypes.country_select,
      label: "Country",
      span: 2,
    },
    {
      name: "link",
      type: FormFieldTypes.text,
      label: "Website Link",
      span: 2,
    },
    {
      name: "logo_url",
      type: FormFieldTypes.text,
      label: "Logo URL",
      span: 2,
    },
    {
      name: "description",
      type: FormFieldTypes.text,
      label: "Description",
      span: 2,
      options: {
        multiline: true,
        minRows: 5,
      },
    },
    {
      name: "contact_number",
      type: FormFieldTypes.text,
      label: "Contact Number",
      span: 1,
    },
    {
      name: "mobile_number",
      type: FormFieldTypes.text,
      label: "Mobile Number",
      span: 1,
    },
    {
      name: "address",
      type: FormFieldTypes.text,
      label: "Address",
      span: 2,
    },
  ];

  const initialValues = producer || {
    name: "",
  };

  const Header = () => {
    if (producer) {
      return (
        <Box mb={3}>
          <Typography variant="cardTitle">Edit Producer</Typography>
          <Typography fontSize={12} color="grey">
            {producer.id}
          </Typography>
        </Box>
      );
    } else {
      return (
        <Box mb={3}>
          <Typography variant="cardTitle">Create a new Producer</Typography>
        </Box>
      );
    }
  };

  return (
    <Box minWidth={600}>
      <Header />
      <CommonForm columns={2} initialValues={initialValues} fields={formFields} onSubmit={onSubmit} />
    </Box>
  );
};

export default ProducerForm;
