import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Tooltip, Typography, colors } from "@mui/material";
import { PondManagerServices } from "api/pondManagerServices";
import AlertBox, { NoCycleAlertBox } from "components/Alerts/AlertBox";
import { HelpOutlineIcon } from "components/Icons/MaterialIcons";
import _ from "lodash";
import { useEffect, useState } from "react";
import { AppSelectors } from "redux/AppReducers";
import { AgGridReact } from "ag-grid-react";
import { Form, Formik } from "formik";
import MuiDatePicker from "components/DatePicker/MuiDatePicker";
import moment from "moment";
import { theme } from "App";
import Text from "components/text/Text";

const DiseaseUploader = ({ farm, pond }) => {
  const [list, setList] = useState([]);

  const cycleManagementStore = AppSelectors.cycleStore();
  const pathologyManagementStore = AppSelectors.pathologyStore();

  const selectedCycle = cycleManagementStore?.selectedCycle;
  const isCycleSelected = _.isEmpty(selectedCycle);

  const diseases = pathologyManagementStore.diseaseList;
  const labs = pathologyManagementStore.labList;

  useEffect(() => {
    if (pond && !_.isEmpty(selectedCycle)) {
      selectedCycle?.pond_id === pond.id && Actions.loadTestSamples();
    }
  }, [pond, selectedCycle]);

  const Actions = {
    loadTestSamples: async () => {
      const response = await PondManagerServices.fetchTestSamples({
        pondId: pond?.id,
        dateFrom: selectedCycle?.start_date,
        dateTo: selectedCycle?.end_date,
      });
      setList(response.list);
    },
    onSubmitData: async (data) => {
      await PondManagerServices.submitTestSample(data);
      Actions.loadTestSamples();
    },
  };

  if (!pond) {
    return (
      <Box p={2}>
        <AlertBox icon={<HelpOutlineIcon />}>
          <Text>interface.alert.please-select-a-pond</Text>!
        </AlertBox>
      </Box>
    );
  }

  if (isCycleSelected)
    return (
      <Box p={2}>
        <NoCycleAlertBox pondId={pond?.id} />
      </Box>
    );

  const tableConfigs = {
    rowHeight: 20,
    headerHeight: 25,
    groupHeaderHeight: 20,
    rowData: _.reverse(list),
    columnDefs: [
      {
        headerName: "SDT",
        field: "sample_created",
        width: 200,
        pinned: "left",
      },
      {
        headerName: "SID",
        field: "sample_id",
        width: 200,
        pinned: "left",
        valueFormatter: (params) => _.last(params.value.split("-")),
      },
      ...diseases.map((ele) => ({
        headerName: ele.id,
        field: "data",
        cellStyle: { "padding-left": 1 },
        valueFormatter: (params) => params.value[ele.id] || "N/A",
      })),
    ],
  };

  return (
    <Box p={2}>
      <Box p={2} bgcolor={theme.palette.primary.light} borderRadius={2}>
        <Typography variant="cardTitle" color="primary">
          <Text>interface.actions.record-new-data</Text>
        </Typography>
        <Box py={2}>
          <Formik
            initialValues={{
              created: moment(),
            }}
            onSubmit={(values) => {
              const requestData = {
                pond_id: pond.id,
                created: values.created.format("YYYY-MM-DD"),
                lab_id: values.lab_id,
                data: diseases
                  .filter((d) => values[d.id])
                  .map((d) => ({
                    disease_id: d.id,
                    value: values[d.id],
                    note: null,
                  })),
              };
              Actions.onSubmitData(requestData);
            }}
          >
            {({ values, handleChange, setFieldValue }) => {
              return (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                      <MuiDatePicker
                        value={values.created}
                        onAccept={(newValue) => {
                          setFieldValue("created", newValue);
                        }}
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormControl fullWidth>
                        <InputLabel id="lab_id_l">Source Laboratory</InputLabel>
                        <Select labelId="lab_id_l" label="Source Laboratory" name="lab_id" value={values.lab_id} onChange={handleChange} defaultValue={false} size="small" required>
                          {labs.map((l) => (
                            <MenuItem key={l.id} value={l.id}>
                              {l.name} ({l.location})
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    {diseases.map((ele) => (
                      <Grid item xs={12} lg={2}>
                        <Stack direction={"row"} justifyContent={"space-between"}>
                          <Typography fontSize={10}>{ele.name}</Typography>
                          <Tooltip
                            title={
                              <Box>
                                <Typography fontSize={10}># {ele.id}</Typography>
                                <Typography fontSize={10}>
                                  <strong>Type:</strong> {ele.type}
                                </Typography>
                                <Typography fontSize={10}>
                                  <strong>Description:</strong> {ele.description}
                                </Typography>
                                <Typography fontSize={10}>
                                  <strong>Note:</strong> {ele.description}
                                </Typography>
                              </Box>
                            }
                            placement="top"
                          >
                            <Typography>
                              <HelpOutlineIcon fontSize="10px" />
                            </Typography>
                          </Tooltip>
                        </Stack>
                        <TextField size="small" name={ele.id} value={values[ele.id]} onChange={handleChange} type="number" fullWidth></TextField>
                      </Grid>
                    ))}
                    <Grid item xs={12}>
                      <Box mt={2}>
                        <Button type="submit" variant="contained">
                          <Text>interface.actions.submit</Text>
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>

      <Box mt={2} bgcolor={colors.grey[100]} borderRadius={2}>
        {list.length === 0 ? (
          <AlertBox icon={<HelpOutlineIcon />}>
            <Text>interface.alert.no-data</Text>
          </AlertBox>
        ) : (
          <Box p={2} mt={2}>
            <Typography variant="cardTitle" color="primary">
              <Text>interface.general.data-logs</Text>
            </Typography>
            <Box mt={2} width="100%" height="calc(100vh - 320px)" className="ag-theme-excel">
              <AgGridReact {...tableConfigs}></AgGridReact>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DiseaseUploader;
