/**
 * API.js
 * All api functions
 * ------------------------------------------
 * Claude Chen | claude.chen@aipmanagement.com
 */

import { ApiGet } from "api/apiUtils";

export function api_water_broker(zoneid, type) {
  return ApiGet(`/api/water?src=broker&zone_id=${zoneid}&type=${type}&`);
}
