import React from "react";

import AccessTime from "@mui/icons-material/AccessTime";
import AccountBalance from "@mui/icons-material/AccountBalance";
import AccountBox from "@mui/icons-material/AccountBox";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AddIcon_ from "@mui/icons-material/Add";
import AddCircle from "@mui/icons-material/AddCircle";
import AddCircleOutlineIcon_ from "@mui/icons-material/AddCircleOutline";
import AddLocationIcon_ from "@mui/icons-material/AddLocation";
import AdminPanelSettings from '@mui/icons-material/AdminPanelSettings';
import Apps from "@mui/icons-material/Apps";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ArrowForward from "@mui/icons-material/ArrowForward";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import AttachMoney from "@mui/icons-material/AttachMoney";
import BarChart from "@mui/icons-material/BarChart";
import BorderAll from "@mui/icons-material/BorderAll";
import Business from "@mui/icons-material/Business";
import CameraAlt from "@mui/icons-material/CameraAlt";
import CancelIcon_ from "@mui/icons-material/Cancel";
import CenterFocusStrong from "@mui/icons-material/CenterFocusStrong";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import Circle from "@mui/icons-material/Circle";
import Close from "@mui/icons-material/Close";
import ContentCopy from "@mui/icons-material/ContentCopy";
import Coronavirus from "@mui/icons-material/Coronavirus";
import Crop from "@mui/icons-material/Crop";
import DashboardIcon_ from "@mui/icons-material/Dashboard";
import DeleteIcon_ from "@mui/icons-material/Delete";
import Deselect from "@mui/icons-material/Deselect";
import DoNotDisturbIcon_ from "@mui/icons-material/DoNotDisturb";
import DoNotDisturbOn from '@mui/icons-material/DoNotDisturbOn';
import Download from "@mui/icons-material/Download";
import EditIcon_ from "@mui/icons-material/Edit";
import EditNote from "@mui/icons-material/EditNote";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import ExitToApp from "@mui/icons-material/ExitToApp";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FileCopyIcon_ from "@mui/icons-material/FileCopy";
import FileUpload from "@mui/icons-material/FileUpload";
import FilterAltOff from "@mui/icons-material/FilterAltOff";
import Grid3x3 from "@mui/icons-material/Grid3x3";
import Grid4x4 from "@mui/icons-material/Grid4x4";
import GridView from "@mui/icons-material/GridView";
import Groups from "@mui/icons-material/Groups";
import Groups2 from "@mui/icons-material/Groups2";
import HelpOutline from "@mui/icons-material/HelpOutline";
import HighlightAltIcon_ from "@mui/icons-material/HighlightAlt";
import Home from "@mui/icons-material/Home";
import Image from "@mui/icons-material/Image";
import InfoIcon_ from "@mui/icons-material/Info";
import InsertChart from "@mui/icons-material/InsertChart";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Language from "@mui/icons-material/Language";
import List from "@mui/icons-material/List";
import LocationOn from "@mui/icons-material/LocationOn";
import MapIcon_ from "@mui/icons-material/Map";
import Menu from "@mui/icons-material/Menu";
import MoreVertIcon_ from "@mui/icons-material/MoreVert";
import MultilineChart from "@mui/icons-material/MultilineChart";
import NavigateNext from "@mui/icons-material/NavigateNext";
import Newspaper from "@mui/icons-material/Newspaper";
import Numbers from "@mui/icons-material/Numbers";
import People from '@mui/icons-material/People';
import Person from "@mui/icons-material/Person";
import Public from "@mui/icons-material/Public";
import PushPin from "@mui/icons-material/PushPin";
import RefreshIcon_ from "@mui/icons-material/Refresh";
import RemoveIcon_ from "@mui/icons-material/Remove";
import Reorder from "@mui/icons-material/Reorder";
import RestartAlt from "@mui/icons-material/RestartAlt";
import SaveIcon_ from "@mui/icons-material/Save";
import Scale from "@mui/icons-material/Scale";
import Search from "@mui/icons-material/Search";
import SelectAll from "@mui/icons-material/SelectAll";
import Settings from "@mui/icons-material/Settings";
import SsidChart from "@mui/icons-material/SsidChart";
import Star from "@mui/icons-material/Star";
import StarBorder from "@mui/icons-material/StarBorder";
import Storage from "@mui/icons-material/Storage";
import TableChart from "@mui/icons-material/TableChart";
import TableRowsIcon_ from "@mui/icons-material/TableRows";
import TableView from "@mui/icons-material/TableView";
import TextSnippet from "@mui/icons-material/TextSnippet";
import Timeline from "@mui/icons-material/Timeline";
import TouchApp from "@mui/icons-material/TouchApp";
import Update from "@mui/icons-material/Update";
import ViewComfyIcon_ from "@mui/icons-material/ViewComfy";
import ViewModule from "@mui/icons-material/ViewModule";
import ViewTimeline from "@mui/icons-material/ViewTimeline";
import Warning from "@mui/icons-material/Warning";
import Water from "@mui/icons-material/Water";
import WaterDrop from "@mui/icons-material/WaterDrop";
import WbSunny from "@mui/icons-material/WbSunny";
import Wysiwyg from "@mui/icons-material/Wysiwyg";
import ZoomIn from "@mui/icons-material/ZoomIn";
import ZoomOut from "@mui/icons-material/ZoomOut";
import AspectRatio from '@mui/icons-material/AspectRatio';
import FitScreen from '@mui/icons-material/FitScreen';
import Check from '@mui/icons-material/Check';
import Fullscreen from '@mui/icons-material/Fullscreen';
import HideImage from '@mui/icons-material/HideImage';
import Logout from '@mui/icons-material/Logout';
import QuestionAnswer from '@mui/icons-material/QuestionAnswer';
import SatelliteAlt from '@mui/icons-material/SatelliteAlt';
import BuildCircle from '@mui/icons-material/BuildCircle';
import Chat from '@mui/icons-material/Chat';
import Tag from '@mui/icons-material/Tag';
import Article from '@mui/icons-material/Article';
import AssignmentReturned from '@mui/icons-material/AssignmentReturned';
import RequestQuote from '@mui/icons-material/RequestQuote';
import Folder from '@mui/icons-material/Folder';
import Photo from '@mui/icons-material/Photo';
import HelpCenterOutlined from "@mui/icons-material/HelpCenterOutlined";
import NotificationImportant from "@mui/icons-material/NotificationImportant";
import Science from '@mui/icons-material/Science';
import CorporateFare from '@mui/icons-material/CorporateFare';
import Send from '@mui/icons-material/Send';
import ScheduleSend from '@mui/icons-material/ScheduleSend';
import LinkOff from '@mui/icons-material/LinkOff';
import AccountTree from '@mui/icons-material/AccountTree';
import PlayArrow from '@mui/icons-material/PlayArrow';
import FirstPage from '@mui/icons-material/FirstPage';
import MenuOpen from "@mui/icons-material/MenuOpen";
import ManageAccounts from '@mui/icons-material/ManageAccounts';
import Lock from '@mui/icons-material/Lock';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Preview from '@mui/icons-material/Preview';

export const DeleteIcon = () => <DeleteIcon_ />;
export const EditIcon = () => <EditIcon_ />;
export const DoNotDisturbIcon = () => <DoNotDisturbIcon_ />;
export const FileCopyIcon = () => <FileCopyIcon_ />;
export const SaveIcon = () => <SaveIcon_ />;
export const CancelIcon = () => <CancelIcon_ />;
export const AddCircleIcon = () => <AddCircle />;
export const AddCircleOutlineIcon = () => <AddCircleOutlineIcon_ />;
export const TableRowsIcon = () => <TableRowsIcon_ />;
export const ViewComfyIcon = () => <ViewComfyIcon_ />;
export const MapIcon = () => <MapIcon_ />;
export const MoreVertIcon = () => <MoreVertIcon_ />;
export const AddIcon = () => <AddIcon_ />;
export const AddLocationIcon = (prop) => <AddLocationIcon_ {...prop} />;
export const HighlightAltIcon = (prop) => <HighlightAltIcon_ {...prop} />;
export const InfoIcon = (prop) => <InfoIcon_ {...prop} />;
export const DashboardIcon = (prop) => <DashboardIcon_ {...prop} />;
export const RemoveIcon = (prop) => <RemoveIcon_ {...prop} />;
export const RefreshIcon = (prop) => <RefreshIcon_ {...prop} />;
export const WysiwygIcon = (prop) => <Wysiwyg {...prop} />;
export const ExitToAppIcon = (prop) => <ExitToApp {...prop} />;
export const CloseIcon = (prop) => <Close {...prop} />;
export const NumbersIcon = (prop) => <Numbers {...prop} />;
export const GridViewIcon = (prop) => <GridView {...prop} />;
export const TimelineIcon = (prop) => <Timeline {...prop} />;
export const BarChartIcon = (prop) => <BarChart {...prop} />;
export const TableChartIcon = (prop) => <TableChart {...prop} />;
export const AccountBoxIcon = (prop) => <AccountBox {...prop} />;
export const AccountCircleIcon = (prop) => <AccountCircle {...prop} />;
export const TouchAppIcon = (prop) => <TouchApp {...prop} />;
export const InsertChartIcon = (prop) => <InsertChart {...prop} />;
export const CropIcon = (prop) => <Crop {...prop} />;
export const LocationOnIcon = (prop) => <LocationOn {...prop} />;
export const SsidChartIcon = (prop) => <SsidChart {...prop} />;
export const AccountBalanceIcon = (prop) => <AccountBalance {...prop} />;
export const DownloadIcon = (prop) => <Download {...prop} />;
export const KeyboardArrowLeftIcon = (prop) => <KeyboardArrowLeft {...prop} />;
export const KeyboardArrowRightIcon = (prop) => <KeyboardArrowRight {...prop} />;
export const ContentCopyIcon = (prop) => <ContentCopy {...prop} />;
export const CenterFocusStrongIcon = (prop) => <CenterFocusStrong {...prop} />;
export const SelectAllIcon = (prop) => <SelectAll {...prop} />;
export const DeselectIcon = (prop) => <Deselect {...prop} />;
export const PublicIcon = (prop) => <Public {...prop} />;
export const HelpOutlineIcon = (prop) => <HelpOutline {...prop} />;
export const ArrowBackIcon = (prop) => <ArrowBack {...prop} />;
export const FileUploadIcon = (prop) => <FileUpload {...prop} />;
export const PushPinIcon = (prop) => <PushPin {...prop} />;
export const HomeIcon = (prop) => <Home {...prop} />;
export const ArrowForwardIcon = (prop) => <ArrowForward {...prop} />;
export const ArrowUpwardIcon = (prop) => <ArrowUpward {...prop} />;
export const ArrowDownwardIcon = (prop) => <ArrowDownward {...prop} />;
export const CheckCircleOutlineIcon = (prop) => <CheckCircleOutline {...prop} />;
export const SettingsIcon = (prop) => <Settings {...prop} />;
export const ErrorOutlineIcon = (prop) => <ErrorOutline {...prop} />;
export const Grid3x3Icon = (prop) => <Grid3x3 {...prop} />;
export const Grid4x4Icon = (prop) => <Grid4x4 {...prop} />;
export const UpdateIcon = (prop) => <Update {...prop} />;
export const StorageIcon = (prop) => <Storage {...prop} />;
export const StarBorderIcon = (prop) => <StarBorder {...prop} />;
export const StarIcon = (prop) => <Star {...prop} />;
export const GroupsIcon = (prop) => <Groups {...prop} />;
export const PersonIcon = (prop) => <Person {...prop} />;
export const BorderAllIcon = (prop) => <BorderAll {...prop} />;
export const AccessTimeIcon = (prop) => <AccessTime {...prop} />;
export const ExpandMoreIcon = (prop) => <ExpandMore {...prop} />;
export const NewspaperIcon = (prop) => <Newspaper {...prop} />;
export const AppsIcon = (prop) => <Apps {...prop} />;
export const ScaleIcon = (prop) => <Scale {...prop} />;
export const AttachMoneyIcon = (prop) => <AttachMoney {...prop} />;
export const WarningIcon = (prop) => <Warning {...prop} />;
export const CircleIcon = (prop) => <Circle {...prop} />;
export const NavigateNextIcon = (prop) => <NavigateNext {...prop} />;
export const TableViewIconIcon = (prop) => <TableView {...prop} />;
export const CoronavirusIcon = (prop) => <Coronavirus {...prop} />;
export const WaterIcon = (prop) => <Water {...prop} />;
export const WaterDropIcon = (prop) => <WaterDrop {...prop} />;
export const ViewTimelineIcon = (prop) => <ViewTimeline {...prop} />;
export const ZoomInIcon = (prop) => <ZoomIn {...prop} />;
export const ZoomOutIcon = (prop) => <ZoomOut {...prop} />;
export const LanguageIcon = (prop) => <Language {...prop} />;
export const WbSunnyIcon = (prop) => <WbSunny {...prop} />;
export const CameraAltIcon = (prop) => <CameraAlt {...prop} />;
export const ImageIcon = (prop) => <Image {...prop} />;
export const TextSnippetIcon = (prop) => <TextSnippet {...prop} />;
export const EditNoteIcon = (prop) => <EditNote {...prop} />;
export const RestartAltIcon = (prop) => <RestartAlt {...prop} />;
export const ReorderIcon = (prop) => <Reorder {...prop} />;
export const ViewModuleIcon = (prop) => <ViewModule {...prop} />;
export const SearchIcon = (prop) => <Search {...prop} />;
export const MultilineChartIcon = (prop) => <MultilineChart {...prop} />;
export const ListIcon = (prop) => <List {...prop} />;
export const FilterAltOffIcon = (prop) => <FilterAltOff {...prop} />;
export const Groups2Icon = (prop) => <Groups2 {...prop} />;
export const BusinessIcon = (prop) => <Business {...prop} />;
export const AdminPanelSettingsIcon = (prop) => <AdminPanelSettings {...prop} />;
export const PeopleIcon = (prop) => <People {...prop} />;
export const MenuIcon = (prop) => <Menu {...prop} />;
export const AspectRatioIcon = (prop) => <AspectRatio {...prop} />;
export const FitScreenIcon = (prop) => <FitScreen {...prop} />;
export const CheckIcon = (prop) => <Check {...prop} />;
export const FullscreenIcon = (prop) => <Fullscreen {...prop} />;
export const HideImageIcon = (prop) => <HideImage {...prop} />;
export const LogoutIcon = (prop) => <Logout {...prop} />;
export const QuestionAnswerIcon = (prop) => <QuestionAnswer {...prop} />;
export const SatelliteAltIcon = (prop) => <SatelliteAlt {...prop} />;
export const BuildCircleIcon = (prop) => <BuildCircle {...prop} />;
export const ChatIcon = (prop) => <Chat {...prop} />;
export const TagIcon = (prop) => <Tag {...prop} />;
export const ArticleIcon = (prop) => <Article {...prop} />;
export const AssignmentReturnedIconIcon = (prop) => <AssignmentReturned {...prop} />;
export const RequestQuoteIcon = (prop) => <RequestQuote {...prop} />;
export const FolderIcon = (prop) => <Folder {...prop} />;
export const PhotoIcon = (prop) => <Photo {...prop} />;
export const HelpCenterOutlinedIcon = (prop) => <HelpCenterOutlined {...prop} />;
export const NotificationImportantIcon = (prop) => <NotificationImportant {...prop} />;
export const ScienceIcon = (prop) => <Science {...prop} />;
export const CorporateFareIcon = (prop) => <CorporateFare {...prop} />;
export const SendIcon = (prop) => <Send {...prop} />;
export const ScheduleSendIcon = (prop) => <ScheduleSend {...prop} />;
export const LinkOffIcon = (prop) => <LinkOff {...prop} />;
export const AccountTreeIcon = (prop) => <AccountTree {...prop} />;
export const DoNotDisturbOnIcon = (prop) => <DoNotDisturbOn {...prop} />;
export const PlayArrowIcon = (prop) => <PlayArrow {...prop} />;
export const FirstPageIcon = (prop) => <FirstPage {...prop} />;
export const MenuOpenIcon = (prop) => <MenuOpen {...prop} />;
export const ManageAccountsIcon = (prop) => <ManageAccounts {...prop} />;
export const LockIcon = (prop) => <Lock {...prop} />;
export const CheckCircleIcon = (prop) => <CheckCircle {...prop} />;
export const PreviewIcon = (prop) => <Preview {...prop} />;
