import { Box, Typography, Stack, IconButton, colors } from "@mui/material";
import OutlinedTag from "components/Tags/OutlinedTag";
import { OrgTypeBadge } from "screens/Aquaculture/Organisations/data/OrgTypes";

const OrgRow = ({ data, action, actions }) => {
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      sx={{
        p: 1,
        ":hover": {
          bgcolor: colors.grey[100],
          borderRadius: 1,
        },
      }}
    >
      <Stack direction={"row"} spacing={1} alignItems={"center"}>
        <Box
          sx={{
            width: 60,
            height: 60,
            overflow: "hidden",
            borderRadius: 1,
            backgroundImage: `url(${data.logo_url})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            border: "1px solid #DDD",
          }}
        ></Box>
        <Box>
          <Typography fontSize={10} color={colors.grey[700]}>
            {data.country}
          </Typography>
          <Typography fontSize={14} fontWeight={600}>
            {data.name}
          </Typography>
          <OrgTypeBadge orgType={data.org_type} />
        </Box>
      </Stack>
      {action && (
        <Box>
          <IconButton size="small" onClick={action.action}>
            {action.icon}
          </IconButton>
        </Box>
      )}
      {actions && (
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          {actions.map((item) => (
            <IconButton size="small" onClick={item.action}>
              {item.icon}
            </IconButton>
          ))}
        </Stack>
      )}
    </Stack>
  );
};

export default OrgRow;
