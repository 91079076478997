import { Avatar, Box, colors, Grid, Stack, Typography } from "@mui/material";
import { theme } from "App";
import { VerticalAlertBoxCollections } from "components/Alerts/AlertBox";
import ChartjsContainer from "components/chart/ChartjsV4Wrapper";
import Text, { getText } from "components/text/Text";
import Digit4x4Card from "components/card/Digit4x4Card";
import PieAndTableCard from "components/ui/Cards/PieAndTableCard";
import { fixedCostsFields, variableCostsFields } from "configs/appConfigs";
import { generateChartConfig } from "helpers/ChartGenerateConfigs";
import { getTrendColor } from "helpers/ColorHelpers";
import _ from "lodash";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import NumberFormat from "react-number-format";
import { AppSelectors } from "redux/AppReducers";
import { PondManagerServices } from "api/pondManagerServices";
import WidgetCard from "ui/Card/WidgetCard";
import { AttachMoneyIcon, ViewTimelineIcon } from "components/Icons/MaterialIcons";
import { typography } from "themes/typography";
import { OutlinedTagCollections } from "components/Tags/OutlinedTag";
import LoadingBox from "components/Loading/LoadingBox";
import PondProfileWidget from "screens/Aquaculture/components/Dashboard/components/PondProfileWidget";
import { cycleManagementHelpers } from "redux/pondManagement/cycleManagement";
import PondEmissionWidget from "screens/Aquaculture/components/Dashboard/components/PondEmissionWidget";
import { BiLeaf } from "react-icons/bi";
import { PondDataSummaryWidget } from "screens/Aquaculture/components/Dashboard/components/PondDataSummaryWidget";
import { useQuery } from "@tanstack/react-query";

const PondCycleWidget = ({ cycle }) => {
  if (!cycle || _.isEmpty(cycle)) {
    return ""; // TODO: content for no cycle selected
  }

  const now = moment();

  const isCycleFinished = moment(cycle.end_date) < now;
  const numberOfDatesInCycle = moment(cycle.end_date).diff(moment(cycle.start_date), "day");
  const daysToCycleEnd = moment(cycle.end_date).diff(now, "day");
  const weeksToCycleEnd = moment(cycle.end_date).diff(now, "week");
  const cycleProgress = isCycleFinished ? 1 : 1 - daysToCycleEnd / numberOfDatesInCycle;

  const tableData = [
    {
      label: <Text>interface.general.active</Text>,
      value: cycle.ended ? <OutlinedTagCollections.CycleInactiveTag /> : <OutlinedTagCollections.CycleActiveTag />,
    },
    {
      label: <Text>interface.general.label</Text>,
      value: cycle.label,
    },
    {
      label: <Text>interface.general.start-date</Text>,
      value: cycle.start_date && moment(cycle.start_date).format("YYYY-MM-DD"),
    },
    {
      label: <Text>interface.general.end-date</Text>,
      value: cycle.end_date && moment(cycle.end_date).format("YYYY-MM-DD"),
    },
    {
      label: <Text>interface.general.farming-duration</Text>,
      value: `${numberOfDatesInCycle} ${getText("interface.time.days")} (${_.round(numberOfDatesInCycle / 7, 0)} ${getText("interface.time.weeks")})`,
    },
    {
      label: <Text>interface.general.remaining</Text>,
      value: isCycleFinished ? "-" : `${daysToCycleEnd} ${getText("interface.time.days")} (${weeksToCycleEnd}  ${getText("interface.time.weeks")} )`,
    },
  ];

  return (
    <WidgetCard title={getText("interface.general.cycle")} subtitle={cycle.label} icon={<ViewTimelineIcon />}>
      <Box>
        <Stack alignItems="center" flexWrap="wrap" mb={1}>
          <Box
            maxWidth="100px"
            sx={{
              ".CircularProgressbar-text": {
                fontFamily: typography.fontFamily,
                fontWeight: 800,
                fontSize: 20,
              },
            }}
          >
            <CircularProgressbar
              value={cycleProgress * 100}
              text={`${_.round(cycleProgress * 100, 0)}%`}
              circleRatio={0.75}
              strokeWidth={10}
              styles={buildStyles({
                rotation: 1 / 2 + 1 / 8,
                strokeLinecap: "round",
                trailColor: colors.grey[300],
                pathColor: cycleProgress === 1 ? colors.green[500] : theme.palette.secondary.main,
                textColor: theme.palette.primary.main,
              })}
            />
          </Box>
        </Stack>
      </Box>
      <Box p={2}>
        <Grid container columns={6} spacing={1}>
          {tableData.map((ele, idx) => (
            <Grid item key={idx} xs={6} md={3}>
              <Stack key={idx} spacing={0}>
                <Typography textAlign={"left"} fontSize={11} color="grey">
                  {ele.label}
                </Typography>
                <Typography textAlign={"left"} fontSize={13} fontWeight={800}>
                  {ele.value}
                </Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Box>
    </WidgetCard>
  );
};

const CardDataWidget = ({ title = "", data, onSwitchToDataViewField }) => {
  const [selectedPrimaryField, setPrimaryField] = useState(null);

  const fieldOverwriter = (item) => {
    if (item.field === "biomass" || item.field === "harvest_biomass_accumulated") {
      return {
        ...item,
        value: item.value / 1000,
        field_meta: {
          ...item.field_meta,
          field_unit: "tons",
        },
      };
    }
    return item;
  };

  useEffect(() => {
    const primaryDataFieldId = selectedPrimaryField || "weight";
    const hasPrimaryField = _.find(data, (ele) => ele.field === primaryDataFieldId);

    if (hasPrimaryField) {
      setPrimaryField(primaryDataFieldId);
    } else {
      if (data.filter((e) => e.field).length > 0) {
        setPrimaryField(data[0].field);
      } else {
        setPrimaryField(null);
      }
    }
  }, [data]);

  if (!selectedPrimaryField)
    return (
      <Typography variant="h4" color={colors.grey[400]}>
        <Text>interface.alert.no-data</Text>
      </Typography>
    );

  const renderPercentage = (pct) => {
    if (!pct) return null;
    return `${pct >= 0 ? "+" : ""}${_.round(pct * 100, 2)} %`;
  };

  let primaryData = _.find(data, (ele) => ele.field === selectedPrimaryField) || {};
  if (!_.isEmpty(primaryData)) {
    primaryData = fieldOverwriter(primaryData);
  }

  return (
    <WidgetCard padding={0} sx={{ height: "100%" }}>
      <Box
        p={1}
        sx={{
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
          }}
          zIndex={1}
        >
          <Box
            sx={{
              position: "relative",
              height: "100%",
              width: "100%",
              background: `linear-gradient(0deg, rgba(255,255,255,1) 0%, ${getTrendColor(primaryData?.change_of_prev_pct).back} 70%);`,
              opacity: 0.3,
            }}
          >
            <ChartjsContainer
              type={"line"}
              {...generateChartConfig.simpleLine({
                rows: (primaryData?.timeseries || []).map((r) => ({
                  date: r.datetime,
                  value: r.value,
                })),
                color: getTrendColor(primaryData?.change_of_prev_pct).front,
                withAnimation: false,
              })}
            />
          </Box>
        </Box>
        <Box
          position="relative"
          zIndex={5}
          p={1}
          onClick={() => onSwitchToDataViewField(primaryData?.field_meta)}
          sx={{
            borderRadius: 1,
            "&:hover": {
              background: "#b3b3b329",
              cursor: "pointer",
            },
          }}
        >
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography fontWeight="bold" color="grey">
                {title}
              </Typography>
              <Typography fontWeight="bold" fontSize={30}>
                {primaryData.value ? <NumberFormat value={primaryData.value} displayType={"text"} thousandSeparator={true} decimalScale={2} /> : primaryData.value === 0 ? "0" : "-"}
              </Typography>
            </Box>
            <Box>
              <Typography color={getTrendColor(primaryData?.change_of_prev_pct).front} textAlign={"right"} fontWeight={800}>
                {renderPercentage(primaryData?.change_of_prev_pct)}
              </Typography>
            </Box>
          </Stack>
          <Box>
            <Typography fontSize={16} fontWeight="bold">
              <Text>{`fields.${primaryData?.field_meta?.field_id}`}</Text>
            </Typography>
            <Typography fontSize={12} fontWeight="bold">
              ({primaryData?.field_meta?.field_unit})
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box p={1}>
        {_.filter(data, (ele) => ele.field && ele.field !== selectedPrimaryField)
          .map((ele) => fieldOverwriter(ele))
          .map((ele, idx) => (
            <Stack
              key={idx}
              direction="row"
              justifyContent="space-between"
              p={0.5}
              px={1}
              my={1}
              sx={{
                borderRadius: 1,
                "&:hover": {
                  cursor: "pointer",
                  backgroundColor: colors.grey[100],
                },
              }}
              onClick={() => ele?.value !== null && setPrimaryField(ele.field)}
            >
              <Stack direction="row" alignItems={"center"} spacing={1}>
                <Box>
                  <Avatar
                    variant="rounded"
                    size="small"
                    sx={{
                      width: 40,
                      height: 40,
                      color: getTrendColor(ele?.change_of_prev_pct).front,
                      bgcolor: getTrendColor(ele?.change_of_prev_pct).back,
                    }}
                  >
                    {getTrendColor(ele?.change_of_prev_pct).icon}
                  </Avatar>
                </Box>
                <Box>
                  <Typography fontWeight={800}>
                    <Text>{`fields.${ele?.field_meta?.field_id}`}</Text>
                  </Typography>
                  <Typography fontSize={11}>{ele?.field_meta?.field_unit}</Typography>
                </Box>
              </Stack>
              <Stack>
                <Typography variant="h6" fontWeight="bold" textAlign="right">
                  {ele.value ? <NumberFormat value={ele?.value} displayType={"text"} thousandSeparator={true} decimalScale={2} /> : ele.value === 0 ? 0 : "-"}
                </Typography>
                <Typography variant="caption" color={getTrendColor(ele?.change_of_prev_pct).front} textAlign="right">
                  {renderPercentage(ele?.change_of_prev_pct)}
                </Typography>
              </Stack>
            </Stack>
          ))}
      </Box>
    </WidgetCard>
  );
};

const CurrentCostWidget = ({ data, fields }) => {
  const dataForVariableCostsCard = {
    pie: _.orderBy(
      variableCostsFields.map((k) => ({
        label: getText(`fields.${_.find(fields, (o) => o.field_id === k)?.field_id || k}`),
        value: data?.pond_cost_data_aggregated?.[k],
      })),
      "value",
      "desc"
    ),
    table: {
      rows: _.orderBy(
        variableCostsFields.map((k) => ({
          label: getText(`fields.${_.find(fields, (o) => o.field_id === k)?.field_id || k}`),
          value: data?.pond_cost_data_aggregated?.[k],
          unitcost: data?.pond_cost_data_by_unit?.[k],
        })),
        "value",
        "desc"
      ).map((r) => ({
        ...r,
        value: (
          <Typography fontWeight={"bold"} fontSize={12}>
            <NumberFormat value={r.value} decimalScale={2} displayType="text" prefix="$" thousandSeparator="," />
          </Typography>
        ),
        unitcost: (
          <Typography fontWeight={"bold"} fontSize={12}>
            <NumberFormat value={r.unitcost} decimalScale={2} displayType="text" prefix="$" thousandSeparator="," suffix="/kg" />
          </Typography>
        ),
      })),
      columns: [
        {
          key: "label",
          display: <Text>interface.general.item</Text>,
        },
        {
          key: "value",
          display: <Text>interface.general.amount</Text>,
        },
        {
          key: "unitcost",
          display: <Text>interface.general.unit-cost</Text>,
        },
      ],
    },
  };

  const dataForFixedCostsCard = {
    pie: _.orderBy(
      fixedCostsFields.map((k) => ({
        label: getText(`fields.${_.find(fields, (o) => o.field_id === k)?.field_id || k}`),
        value: data?.pond_cost_data_aggregated?.[k],
      })),
      "value",
      "desc"
    ),
    table: {
      rows: _.orderBy(
        fixedCostsFields.map((k) => ({
          label: <Text>{`fields.${_.find(fields, (o) => o.field_id === k)?.field_id || k}`}</Text>,
          value: data?.pond_cost_data_aggregated?.[k],
          unitcost: data?.pond_cost_data_by_unit?.[k],
        })),
        "value",
        "desc"
      ).map((r) => ({
        ...r,
        value: (
          <Typography fontWeight={"bold"} fontSize={12}>
            <NumberFormat value={r.value} decimalScale={2} displayType="text" prefix="$" thousandSeparator="," />
          </Typography>
        ),
        unitcost: (
          <Typography fontWeight={"bold"} fontSize={12}>
            <NumberFormat value={r.unitcost} decimalScale={2} displayType="text" prefix="$" thousandSeparator="," suffix="/kg" />
          </Typography>
        ),
      })),
      columns: [
        {
          key: "label",
          display: <Text>interface.general.item</Text>,
        },
        {
          key: "value",
          display: <Text>interface.general.amount</Text>,
        },
        {
          key: "unitcost",
          display: <Text>interface.general.unit-cost</Text>,
        },
      ],
    },
  };

  return (
    <WidgetCard title={<Text>interface.general.cost</Text>} icon={<AttachMoneyIcon />}>
      <Stack spacing={2}>
        <Stack
          direction={"row"}
          spacing={2}
          sx={{
            overflowX: "auto",
          }}
        >
          <Digit4x4Card
            title={<Text>interface.general.total-cost</Text>}
            value={<NumberFormat displayType="text" prefix="$" value={data?.pond_fixed_cost_data_aggregated + data?.pond_var_cost_data_aggregated} thousandSeparator="," decimalScale={0} />}
            subValue={<NumberFormat displayType="text" prefix="$" value={data?.pond_fixed_cost_data_by_unit + data?.pond_var_cost_data_by_unit} thousandSeparator="," suffix="/kg" decimalScale={2} />}
          />
          <Digit4x4Card
            title={<Text>interface.general.total-fixed-costs</Text>}
            value={<NumberFormat displayType="text" prefix="$" value={data?.pond_fixed_cost_data_aggregated} thousandSeparator="," decimalScale={0} />}
            subValue={<NumberFormat displayType="text" prefix="$" value={data?.pond_fixed_cost_data_by_unit} thousandSeparator="," suffix="/kg" decimalScale={2} />}
          />
          <Digit4x4Card
            title={<Text>interface.general.total-variable-costs</Text>}
            value={<NumberFormat displayType="text" prefix="$" value={data?.pond_var_cost_data_aggregated} thousandSeparator="," decimalScale={0} />}
            subValue={<NumberFormat displayType="text" prefix="$" value={data?.pond_var_cost_data_by_unit} thousandSeparator="," suffix="/kg" decimalScale={2} />}
          />
        </Stack>
        <PieAndTableCard title={<Text>interface.general.variable-costs-breakdown</Text>} data={dataForVariableCostsCard} />
        <PieAndTableCard title={<Text>interface.general.fixed-costs-breakdown</Text>} data={dataForFixedCostsCard} />
      </Stack>
    </WidgetCard>
  );
};

const PondSummaryDashboard = ({ farm, pond, onSwitchToDataViewField }) => {
  // ===== REDUX START
  const cycleStore = AppSelectors.cycleStore();
  const cycle = cycleManagementHelpers.getCurrentCycle({ pondId: pond?.id, cycle: cycleStore.selectedCycle, cycles: cycleStore.cycles });

  const pondManagementStore = AppSelectors.pondManagementStore();
  const { fields } = pondManagementStore;
  // ===== REDUX END

  const theQuery = useQuery({
    queryKey: ["data", "pond-dashboard", pond?.id, cycle?.id],
    queryFn: async () => {
      const dashboardDataResult = await PondManagerServices.getDashboardData(pond?.id, cycle.id);
      return dashboardDataResult;
    },
    enabled: !!pond?.id && !!cycle?.id && cycle?.pond_id === pond?.id,
    staleTime: 1000 * 60 * 10, // 10 min
    gcTime: 1000 * 60 * 30, // 30 min
    retry: false,
  });

  const { refetch, error, dataUpdatedAt, isFetching } = theQuery;
  const dashboardData = theQuery.data || {};

  // useEffect(() => {
  //   Actions.loadPondDashboardData();
  // }, [pond, cycle]);

  // const Actions = {
  //   loadPondDashboardData: async () => {
  //     setLoading(true);
  //     const pondid = pond?.id;
  //     if (pond && !_.isEmpty(cycle) && cycle.pond_id === pondid) {
  //       const dashboardDataResult = await PondManagerServices.getDashboardData(pondid, cycle.id);
  //       setDashboardData(dashboardDataResult);
  //     }
  //     setLoading(false);
  //   },
  // };

  if (isFetching) {
    return <LoadingBox />;
  }

  if (_.isEmpty(cycle)) {
    return (
      <Box>
        <VerticalAlertBoxCollections.NoCycleSelected pondId={pond?.id} />
      </Box>
    );
  }

  if (!dashboardData || _.isEmpty(dashboardData)) {
    return (
      <Box p={2}>
        <VerticalAlertBoxCollections.NothingShow />
      </Box>
    );
  }

  console.log(dashboardData);

  const biologicalSummaryCardData = ["weight", "weight_diff", "biomass", "current_density", "organisms", "seeded_density", "seeded_pl", "survival_rate"].map((f) => ({
    ...dashboardData.summary[f],
    field_meta: _.find(fields, (item) => item["field_id"] === f),
  }));

  const waterQualitySummaryCardData = ["water_temp", "ph", "dissolved_oxygen", "salinity", "turbidity", "water_exchange", "average_depth"].map((f) => ({
    ...dashboardData.summary[f],
    field_meta: _.find(fields, (item) => item["field_id"] === f),
  }));

  const productivitySummaryCardData = ["harvest_biomass_accumulated", "fcr_full_cycle", "feed_daily", "feed_provided", "feed_accumulated"].map((f) => ({
    ...dashboardData.summary[f],
    field_meta: _.find(fields, (item) => item["field_id"] === f),
  }));

  return (
    <Box p={2}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} sx={{ height: "100%" }}>
          <WidgetCard title="">
            <PondDataSummaryWidget pond={pond} cycle={cycle} />
          </WidgetCard>
        </Grid>
        <Grid item xs={12} md={6} sx={{ height: "100%" }}>
          <PondCycleWidget cycle={cycle} />
        </Grid>
        <Grid item xs={12} md={6} sx={{ height: "100%" }}>
          <WidgetCard
            title={
              <span>
                CO<sub>2</sub>
              </span>
            }
            icon={<BiLeaf />}
          >
            <PondEmissionWidget pond={pond} />
          </WidgetCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <CardDataWidget title={<Text>interface.general.biological</Text>} data={biologicalSummaryCardData} onSwitchToDataViewField={onSwitchToDataViewField} />
        </Grid>
        <Grid item xs={12} md={4}>
          <CardDataWidget title={<Text>interface.general.water-quality</Text>} data={waterQualitySummaryCardData} onSwitchToDataViewField={onSwitchToDataViewField} />
        </Grid>
        <Grid item xs={12} md={4}>
          <CardDataWidget title={<Text>interface.general.productivity</Text>} data={productivitySummaryCardData} onSwitchToDataViewField={onSwitchToDataViewField} />
        </Grid>
        <Grid item xs={12}>
          <CurrentCostWidget data={dashboardData?.cost} fields={fields} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PondSummaryDashboard;
