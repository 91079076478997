import { useEffect, useState } from "react";
import { Formik } from "formik";

import Fade from "react-reveal/Fade";
import { Link, useNavigate } from "react-router-dom";
import { ROUTE_FORGOT_PASSWORD, ROUTE_GOOGLE_SIGNED_IN, ROUTE_SIGNUP_FOR_SHRIMPL } from "routes/paths";
import { Alert, Box, Button, Checkbox, FormControlLabel, Grid, IconButton, Stack, TextField, Tooltip, Typography } from "@mui/material";
import PrimaryButton from "components/buttons/PrimaryButton";
import { ShrimplLogoSvg, ShrimplLogoWhiteSvg } from "components/Images";

import { theme } from "App";
import { useDispatch } from "react-redux";
import { AppSelectors } from "redux/AppReducers";
import { userActions } from "redux/user";

import { useMsal } from "@azure/msal-react";
import { loginRequest } from "configs/msalConfigs";
import Text from "components/text/Text";
import LanugagesIconSelect from "components/Languages/LanugagesIconSelect";
import { useGoogleLogin } from "@react-oauth/google";

const Login = () => {
  const dispatch = useDispatch();

  const { instance, accounts, inProgress } = useMsal();
  const userStore = AppSelectors.userStore();
  const { loginError, loginSucceed } = userStore;
  const [loading, setLoading] = useState(false);

  const navigateTo = useNavigate();

  const onSubmit = async (params) => {
    const { email, password, longLogin } = params;
    setLoading(true);
    await userActions.login(dispatch, { email, password, longLogin });
    setLoading(false);
  };

  const Actions = {
    // SSO :: microsoft
    onLoginWithMicrosoft: () => {
      instance.loginRedirect(loginRequest).catch((e) => {
        console.log(e);
      });
    },
    onLoginWithGoogle: useGoogleLogin({
      onSuccess: (tokenResponse) => {
        sessionStorage.setItem("_GOOGLE_SSO_SESSION", JSON.stringify(tokenResponse)), window.location.replace(ROUTE_GOOGLE_SIGNED_IN);
      },
    }),
    onRequestProfileData: () => {
      // TO IMPLEMENT
    },
  };

  useEffect(() => {
    // Actions.onRequestProfileData
  }, [instance, inProgress]);

  return (
    <Box backgroundColor={theme.palette.primary.main}>
      <Stack height="100dvh" width="100vw" direction="row" justifyContent="center" alignItems="center">
        {loginSucceed ? (
          <Box sx={{ animation: "scaleUp 1s;" }}>
            <img src={ShrimplLogoWhiteSvg} alt="" width="300" />
          </Box>
        ) : (
          <Box
            backgroundColor={"#FFF"}
            p={{
              xs: 5,
              sm: 3,
            }}
            width={{
              xs: "100%",
              sm: 400,
            }}
            borderRadius={{
              xs: 0,
              sm: 2,
            }}
            height={{
              xs: "100%",
              sm: "auto",
            }}
            sx={{
              boxSizing: {
                xs: "border-box",
                sm: "content-box",
              },
            }}
          >
            <Stack direction={"row"} alignItems={"flex-end"} justifyContent={"flex-end"}>
              <Box>
                <LanugagesIconSelect />
              </Box>
            </Stack>

            <Box textAlign={"center"} my={3}>
              <img src={ShrimplLogoSvg} alt="" width="200" />
            </Box>
            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
              <Fade left distance="1rem" duration={500}>
                <Typography variant="h4">
                  <Text>interface.account.login</Text>
                </Typography>
              </Fade>
            </Stack>
            <Formik
              initialValues={{
                email: "",
                password: "",
                longLogin: false,
              }}
              validate={(values) => {
                const errors = {};
                if (!values.email) {
                  errors.email = "Required";
                } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                  errors.email = "Invalid email address";
                }
                return errors;
              }}
            >
              {({ values, handleChange, setFieldValue, isSubmitting }) => (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (values.email && values.password) {
                      onSubmit(values);
                    }
                  }}
                >
                  <Box my={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          id="email"
                          type="email"
                          onChange={handleChange}
                          value={values.email}
                          label={<Text>interface.account.email</Text>}
                          variant="standard"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="password"
                          type="password"
                          onChange={handleChange}
                          value={values.password}
                          label={<Text>interface.account.password</Text>}
                          variant="standard"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="caption"
                          color="primary"
                          sx={{
                            a: {
                              color: "inherit",
                            },
                          }}
                        >
                          <Link to={ROUTE_FORGOT_PASSWORD}>
                            <Text>interface.account.forget-your-password</Text>
                          </Link>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Stack alignItems={"center"} mb={1}>
                    <Tooltip
                      placement="top"
                      title={
                        <Typography fontSize={10} color="red">
                          <Text>interface.alert.keep-me-signed-in-alert</Text>
                        </Typography>
                      }
                    >
                      <FormControlLabel
                        onChange={() => setFieldValue("longLogin", !values.longLogin)}
                        control={<Checkbox checked={values.longLogin} />}
                        label={<Text>interface.general.keep-me-signed-in</Text>}
                      />
                    </Tooltip>
                  </Stack>
                  <Stack spacing={0}>
                    <Stack direction={"row"} justifyContent="center" alignItems={"center"} spacing={1} mb={1}>
                      <Typography color="primary">
                        <Text>interface.account.or-sign-in-with</Text>
                      </Typography>
                      <Tooltip title="Sign in with Microsoft" placement="top">
                        <IconButton variant="contained" color="primary" onClick={Actions.onLoginWithMicrosoft}>
                          <i className="fab fa-microsoft"></i>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Sign in with Google" placement="top">
                        <IconButton variant="contained" color="primary" onClick={Actions.onLoginWithGoogle}>
                          <i className="fab fa-google"></i>
                        </IconButton>
                      </Tooltip>
                    </Stack>

                    {loginError && (
                      <Box mb={1}>
                        <Alert severity="error">{loginError}</Alert>
                      </Box>
                    )}
                    <PrimaryButton type="submit" disabled={isSubmitting}>
                      {loading ? <Text suffix="...">interface.general.please-wait</Text> : <Text>interface.account.login</Text>}
                    </PrimaryButton>
                    <Box mt={1}>
                      <Button fullWidth onClick={() => navigateTo(ROUTE_SIGNUP_FOR_SHRIMPL)}>
                        <Text>interface.account.signup</Text>
                      </Button>
                    </Box>
                  </Stack>
                </form>
              )}
            </Formik>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default Login;
