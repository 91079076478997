import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Box, Button, Grid, IconButton, Stack, Typography, colors } from "@mui/material";
import { PondManagerServices } from "api/pondManagerServices";
import { VerticalAlertBoxCollections } from "components/Alerts/AlertBox";
import React, { useEffect, useRef, useState } from "react";
import MuiTabs, { MuiTabsThemes } from "ui/tabs/MuiTabs";
import GoogleMapReact from "google-map-react";
import { GOOGLE_MAP_KEY } from "secrets";
import { addGeoJsonGroup, commonInitMaps } from "components/map/googlemapHelper";
import TimeFormatters from "helpers/TimeFormatters";
import CountryFlagSvgIcon from "components/Icons/CountryFlagSvgIcon";
import CountriesAutocomplete from "components/input/CountriesAutocomplete";
import _ from "lodash";
import NumericFormatters from "helpers/NumericFormatters";
import { MuiBorderIconButton } from "components/buttons/MuiButtonVariants";
import { RefreshIcon } from "components/Icons/MaterialIcons";

const createMapOptions = (maps) => {
  return {
    fullscreenControlOptions: {
      position: maps.ControlPosition.BOTTOM_RIGHT,
    },
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_BOTTOM,
    },
    mapTypeId: maps.MapTypeId.HYBRID,
    minZoom: 5,
  };
};

const mapStyles = {
  data: {
    normal: {
      fillColor: colors.blue[500],
      strokeColor: "#FFF",
      strokeWeight: 1,
      fillOpacity: 0.5,
      editable: false,
    },
    selected: {
      fillColor: "#09ff00",
      strokeColor: "#FFF",
      strokeWeight: 2,
      fillOpacity: 0.8,
      editable: false,
    },
  },
  aoi: {
    fillColor: "#ff0000",
    fillOpacity: 0,
    strokeColor: "#ff0000",
    strokeWeight: 3,
    zIndex: 0,
  },
};

const AquaExplorerAdminAoiManagerMapView = ({ aoi, aoiData, actions }) => {
  const [selectedFeatures, setSelectedFeatures] = useState(new Set());
  const [hiddenFeatures, setHiddenFeatures] = useState(new Set());

  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);

  const [mapCenter, setMapCenter] = useState([-2.585671742540449, -80.01776784245965]);
  const [mapZoom, setMapZoom] = useState(13);

  const [mapDataLayer, setMapDataLayer] = useState(null);

  const mapRef = useRef(null);

  const Actions = {
    onSubmit: async () => {
      const removedFeatureIds = Array.from(hiddenFeatures).map((ele) => {
        return ele.getProperty("properties")?.id;
      });
      const aoiId = aoi?.id;
      const payload = {
        aoi_id: aoiId,
        to_remove: removedFeatureIds,
      };
      await PondManagerServices.updateAquaExplorerAdminAoiPonds(payload);
      window.alert("Success");
    },
    onRerun: async () => {
      const confirmed = window.confirm("Are you sure? This action will delete all generated ponds and update AOI to PENDING mode.");
      if (confirmed) {
        await PondManagerServices.rerunAquaExplorerAdminAoi({ aoi_id: aoi.id });
        window.alert("Success");
        actions.onLoad();
      }
    },
    onEdit: async () => {
      const [editTarget] = selectedFeatures;
      Array.from(selectedFeatures).forEach((ft) => {
        map.data.overrideStyle(ft, { editable: true });
      });
      // const editTargetGeojson = {
      //   type: "Feature",
      //   geometry: editTarget.getProperty("geometry"),
      //   properties: editTarget.getProperty("properties")
      // }
      // console.log(editTargetGeojson);
    },
  };

  // console.log(selectedFeatures);

  const handleApiLoaded = async (map, maps) => {
    commonInitMaps(maps);

    /** draw AOI boundary */
    const d = map.data.addGeoJson(aoi.geojson);
    d.forEach((ft) => map.data.overrideStyle(ft, mapStyles.aoi));

    // :: fit bounds ::
    const bounds = new maps.LatLngBounds();
    map.data.forEach(function (feature) {
      feature.getGeometry().forEachLatLng(function (latlng) {
        bounds.extend(latlng);
      });
    });
    map.fitBounds(bounds);

    setMap(map);
    setMaps(maps);
    mapRef.current = map;
  };

  useEffect(() => {
    if (map && aoiData) {
      if (mapDataLayer) {
        mapDataLayer.forEach((ft) => {
          map.data.remove(ft);
        });
      }
      const d = addGeoJsonGroup(map, aoiData, "data");
      d.forEach((ft) =>
        map.data.overrideStyle(ft, {
          ...mapStyles.data.normal,
          zIndex: 999,
        })
      );
      setMapDataLayer(d);

      map.data.addListener("click", (event) => {
        const feature = event.feature;
        const _g = feature.getProperty("_g");
        // only add to "data layer"
        if (_g === "data") {
          setSelectedFeatures((prevSelected) => {
            // multi-select
            const newSelected = new Set(prevSelected);
            // if it's selected
            if (newSelected.has(feature)) {
              newSelected.delete(feature);
              map.data.overrideStyle(feature, mapStyles.data.normal);
            }
            // if it's not selected
            else {
              newSelected.add(feature);
              map.data.overrideStyle(feature, mapStyles.data.selected);
            }
            return newSelected;
          });
        }
      });

      setSelectedFeatures(new Set());
      setHiddenFeatures(new Set());
    }
  }, [aoiData]);

  /** handle hotkey  */
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "x") {
        // Hide selected features
        setSelectedFeatures((prevSelected) => {
          const newHidden = new Set(hiddenFeatures);
          prevSelected.forEach((feature) => {
            mapRef.current.data.overrideStyle(feature, { visible: false });
            newHidden.add(feature);
          });
          setHiddenFeatures(newHidden);
          return new Set(); // Clear selected features
        });
      } else if (event.key === "z") {
        // Show only the last hidden feature
        setHiddenFeatures((prevHidden) => {
          if (prevHidden.size > 0) {
            // Convert set to array to get the last element
            const lastFeature = Array.from(prevHidden).pop();

            // Show the last hidden feature
            mapRef.current.data.overrideStyle(lastFeature, { ...mapStyles.data.normal, visible: true });

            // Remove the last feature from hiddenFeatures
            const newHidden = new Set(prevHidden);
            newHidden.delete(lastFeature);
            return newHidden;
          }
          return prevHidden; // Return unchanged if no hidden features exist
        });
      }
    };

    // Add and clean up event listener
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [hiddenFeatures]);

  const keyMetrics = {
    resultCount: aoiData?.features?.length || 0,
    totalArea: _.sumBy(
      aoiData?.features?.map((ele) => ele.properties),
      "area"
    ),
    removedCount: hiddenFeatures.size,
    selectCount: selectedFeatures.size,
  };

  return (
    <Box
      width={"100%"}
      height={"100%"}
      sx={{
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          bottom: 30,
          left: 8,
          p: 1,
          borderRadius: 2,
          bgcolor: "#000000a7",
          zIndex: 9,
        }}
      >
        <Stack spacing={1}>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Box
              sx={{
                border: "1px solid #FFF",
                borderRadius: 1,
                width: 20,
                height: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography fontSize={10} color="white">
                X
              </Typography>
            </Box>
            <Typography fontSize={10} color="#FFF">
              Remove selected polygons
            </Typography>
          </Stack>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Box
              sx={{
                border: "1px solid #FFF",
                borderRadius: 1,
                width: 20,
                height: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography fontSize={10} color="white">
                Z
              </Typography>
            </Box>
            <Typography fontSize={10} color="#FFF">
              Undo deletion
            </Typography>
          </Stack>
        </Stack>
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          p: 0.5,
          bgcolor: "#000000a7",
          zIndex: 9,
        }}
      >
        <Stack direction={"row"} spacing={1}>
          <Typography color="#FFF" fontSize={10}>
            Result: <strong>{keyMetrics.resultCount}</strong>
          </Typography>
          <Typography color="#FFF" fontSize={10}>
            Total Area: <strong>{NumericFormatters.formatAreaInHa({ value: keyMetrics.totalArea })}</strong>
          </Typography>
          <Typography color="#CCC" fontSize={10}>
            |
          </Typography>
          <Typography color="#ffc6c6" fontSize={10}>
            Removed: <strong>{keyMetrics.removedCount}</strong>
          </Typography>
          <Typography color="#ffc6c6" fontSize={10}>
            Selected: <strong>{keyMetrics.selectCount}</strong>
          </Typography>
          {keyMetrics.selectCount > 0 && (
            <Typography
              color="#ffc6c6"
              fontSize={10}
              sx={{
                textDecoration: "underline",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onClick={() => {
                Array.from(selectedFeatures).forEach((ft) => {
                  map.data.overrideStyle(ft, mapStyles.data.normal);
                });
                setSelectedFeatures(new Set());
              }}
            >
              UNSELECT
            </Typography>
          )}
        </Stack>
      </Box>
      {/* Popup Menu */}
      <Box
        sx={{
          position: "absolute",
          top: 50,
          right: 10,
          p: 1,
          borderRadius: 3,
          bgcolor: "#FFF",
          zIndex: 999,
        }}
      >
        <Stack direction={"column"} spacing={1}>
          <Button onClick={Actions.onRerun}>Rerun</Button>
          <Button onClick={Actions.onEdit}>Edit</Button>
          <Button onClick={Actions.onSubmit}>Save</Button>
        </Stack>
      </Box>
      <GoogleMapReact
        ref={mapRef}
        bootstrapURLKeys={{ key: GOOGLE_MAP_KEY }}
        center={mapCenter}
        defaultZoom={mapZoom}
        options={createMapOptions}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      ></GoogleMapReact>
    </Box>
  );
};

const AquaExplorerAdminAoiManager = () => {
  const [data, setData] = useState([]);
  const [aoiData, setAoiData] = useState(null);
  const [page, setPage] = useState(0);
  const [status, setStatus] = useState("FINISHED");
  const [country, setCountry] = useState(null);
  const [selectedAoi, setAoi] = useState(null);

  const Actions = {
    onLoad: async () => {
      const result = await PondManagerServices.fetchAquaExplorerAdminAois({
        status: status,
        page: page,
        country: country,
      });
      setData(result?.rows);
    },
    onLoadPonds: async () => {
      const result = await PondManagerServices.fetchAquaExplorerAdminAoiPonds({
        aoi_id: selectedAoi?.id,
      });
      const resultGeoJson = {
        type: "FeatureCollection",
        features: result.map((ele) => ({
          ...ele.boundary_geojson[0],
          properties: {
            id: ele.id,
            label: ele.label,
            lat: ele.lat,
            lng: ele.lng,
            group: ele.group,
            country: ele.country,
            country_code: ele.country_code,
            area: ele.area,
          },
        })),
      };
      setAoiData(resultGeoJson);
    },
    onPrevPage: () => {
      if (page > 0) {
        setPage(page - 1);
      }
    },
    onNextPage: () => {
      if (data.length === 100) {
        setPage(page + 1);
      }
    },
  };

  useEffect(() => {
    Actions.onLoad();
  }, [page, country, status]);

  useEffect(() => {
    setPage(0);
  }, [country, status]);

  useEffect(() => {
    selectedAoi && Actions.onLoadPonds();
  }, [selectedAoi]);

  return (
    <Grid container>
      <Grid item width={400} px={1}>
        <MuiTabs
          theme={MuiTabsThemes.chip}
          options={[
            { label: "Pending", value: "PENDING" },
            { label: "Processsing", value: "PROCESSING" },
            { label: "Finished", value: "FINISHED" },
          ]}
          value={status}
          onNewValue={setStatus}
        />
        <Stack>
          <Box>
            <CountriesAutocomplete onNewValue={(val) => setCountry(_.lowerCase(val))} />
          </Box>
        </Stack>
        <Stack direction={"row"} justifyContent={"space-between"} my={1} alignItems={"center"}>
          <Stack direction={"row"} alignItems={"center"}>
            <Box>
              <MuiBorderIconButton icon={<RefreshIcon />} onClick={Actions.onLoad} size={"sm"} />
            </Box>
          </Stack>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <IconButton size="small" onClick={Actions.onPrevPage}>
              <ChevronLeft />
            </IconButton>
            <Typography fontSize={10}>{page + 1}</Typography>
            <IconButton size="small" onClick={Actions.onNextPage}>
              <ChevronRight />
            </IconButton>
          </Stack>
        </Stack>
        <Box>
          <Stack
            spacing={1}
            sx={{
              height: "calc(100vh - 300px)",
              overflow: "auto",
            }}
          >
            {data?.length === 0 && <VerticalAlertBoxCollections.NothingShow />}
            {data?.map((item) => {
              const selected = item?.id === selectedAoi?.id;
              return (
                <Box
                  key={item.id}
                  sx={{
                    p: 1,
                    bgcolor: "#FFF",
                    border: selected ? "2px solid #000" : "2px solid transparent",
                    borderRadius: 1,
                    "&:hover": {
                      cursor: "pointer",
                      border: "2px solid #aeaeae",
                    },
                  }}
                  onClick={() => {
                    setAoiData(null);
                    setAoi(item);
                  }}
                >
                  <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography fontWeight={800}>{item.label}</Typography>
                    <Box>
                      <CountryFlagSvgIcon countryCode={item.country_code} />
                    </Box>
                  </Stack>
                  <Stack mt={2}>
                    <Stack direction={"row"} spacing={1} justifyContent={"space-between"}>
                      <Typography color="grey" fontSize={10}>
                        Created
                      </Typography>
                      <Typography fontWeight={800} fontSize={10}>
                        {TimeFormatters.formatUtcDatetimeString(item.created)}
                      </Typography>
                    </Stack>
                    <Stack direction={"row"} spacing={1} justifyContent={"space-between"}>
                      <Typography color="grey" fontSize={10}>
                        Updated
                      </Typography>
                      <Typography fontWeight={800} fontSize={10}>
                        {TimeFormatters.formatUtcDatetimeString(item.updated)}
                      </Typography>
                    </Stack>
                    <Stack direction={"row"} spacing={1} justifyContent={"space-between"}>
                      <Typography color="grey" fontSize={10}>
                        Location
                      </Typography>
                      <Typography fontWeight={800} fontSize={10}>
                        {[item.place, item.region, item.country].join(" , ")}
                      </Typography>
                    </Stack>
                    <Stack direction={"row"} spacing={1} justifyContent={"space-between"}>
                      <Typography color="grey" fontSize={10}>
                        Status
                      </Typography>
                      <Typography
                        fontWeight={800}
                        fontSize={10}
                        color={
                          {
                            PENDING: colors.orange[600],
                            PROCESSING: colors.blue[600],
                            FINISHED: colors.green[600],
                          }[item.status]
                        }
                      >
                        {item.status}
                      </Typography>
                    </Stack>
                  </Stack>
                </Box>
              );
            })}
          </Stack>
        </Box>
      </Grid>
      <Grid item xs>
        {selectedAoi ? <AquaExplorerAdminAoiManagerMapView aoi={selectedAoi} aoiData={aoiData} key={selectedAoi?.id} actions={Actions} /> : <VerticalAlertBoxCollections.NoResult />}
      </Grid>
    </Grid>
  );
};

export default AquaExplorerAdminAoiManager;
