import React, { useState } from "react";
import { Form, Formik } from "formik";
import Fade from "react-reveal/Fade";
import { useNavigate } from "react-router-dom";
import { ROUTE_LOGIN } from "routes/paths";

import { Alert, Autocomplete, Box, Grid, Stack, TextField, Typography } from "@mui/material";
import { Roles } from "../../config";
import { CentralLogoImg, ShrimplLogoSvg, ShrimplLogoWhiteSvg } from "components/Images";
import { AuthenticationApis } from "api/api";
import PrimaryButton from "components/buttons/PrimaryButton";

import WhereDidYouHearAboutUs from "index/WhereDidYouHearAboutUs";
import ReCAPTCHA from "react-google-recaptcha";
import { GOOGLE_CAPTCHA_SITE_KEY } from "secrets";
import * as Yup from "yup";
import _ from "lodash";
import { theme } from "App";
import Text from "components/text/Text";
import UserRoles from "constants/UserRoles";
import Countries from "constants/Countries";

const formValidationScheme = Yup.object().shape({
  email: Yup.string().required("Required"),
  firstname: Yup.string().required("Required"),
  lastname: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
});

const Signup = ({ source }) => {
  const [captchaToken, setCaptchaToken] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [hasSignedUp, setSignedUp] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const navigateTo = useNavigate();

  const onSubmit = async (params) => {
    // const rsp = await AuthenticationApis.verifyCaptcha(captchaToken);
    setLoading(true);
    setErrorMessage(null);
    const payload = {
      role: source,
      request: source === Roles.INTERNAL ? "new_admin_signup" : "new_user_signup",
      ...params,
    };
    const rsp = await AuthenticationApis.signup(payload);
    if (rsp.error) {
      setErrorMessage(rsp.error);
      setLoading(false);
      return;
    }
    setSignedUp(true);
    // TODO: directly login
    setTimeout(() => {
      navigateTo("/");
    }, 5000);
  };

  const Header = ({ source, ...props }) => {
    return (
      <Box {...props}>
        <Stack spacing={1} alignItems="center" py={4}>
          <img src={ShrimplLogoSvg} alt="" width="200" />
        </Stack>
      </Box>
    );

    // if (source === Roles.SHRIMPSTAR_USER) {
    // return (
    //   <Box {...props}>
    //     <Stack spacing={1} alignItems="center">
    //       <img src={CentralLogoImg} alt="" width="100" />
    //     </Stack>
    //   </Box>
    // );
    // }
    // if (source === Roles.INTERNAL) {
    //   return (
    //     <Box {...props}>
    //       <Stack spacing={1} alignItems="center">
    //         <img src={CentralLogoImg} alt="" width="150" />
    //       </Stack>
    //     </Box>
    //   );
    // }
    // return (
    //   <Box {...props}>
    //     <h1>Signup</h1>
    //   </Box>
    // );
  };

  const Hero = ({ source, ...props }) => {
    if (source === UserRoles.SHRIMPL_USER) {
      return (
        <Box {...props}>
          <Stack spacing={1} alignItems="center">
            <Box>
              <img src={ShrimplLogoWhiteSvg} alt="" width="400" />
            </Box>
            <Box>
              <Typography fontSize="2rem" sx={{ color: "#fff" }}>
                The leading intelligent farm management platform.
              </Typography>
            </Box>
          </Stack>
        </Box>
      );
    }
    if (source === Roles.INTERNAL) {
      return (
        <Box>
          <Stack spacing={1} alignItems="center">
            <img src={CentralLogoImg} alt="" width="400" />
          </Stack>
        </Box>
      );
    }
    return (
      <Box>
        <Fade left distance="1rem" duration={500}>
          <h1>Signup</h1>
        </Fade>
      </Box>
    );
  };

  const onRecaptchaResponse = (verifyToken) => {
    setCaptchaToken(verifyToken);
  };

  return (
    <Box sx={{ height: "100vh" }} backgroundColor={theme.palette.primary.main}>
      <Grid container sx={{ height: "100%" }}>
        <Grid
          item
          xs={12}
          lg={5}
          sx={{ height: "100%" }}
          p={{
            xs: 0,
            sm: 2,
          }}
        >
          <Box
            p={{
              xs: 2,
              lg: 6,
            }}
            pt={{
              xs: 2,
              lg: 12,
            }}
            sx={{
              height: "100%",
              backgroundColor: "#fff",
              overflow: "auto",
            }}
            boxSizing="border-box"
            borderRadius={{
              xs: 0,
              sm: 2,
            }}
          >
            {hasSignedUp ? (
              <Box>
                <Typography textAlign={"center"} variant="h2" gutterBottom>
                  Sign up success!
                </Typography>
                <Typography textAlign={"center"}>A confirmation email has been sent to you. Please check your inbox! (Redirect to login page soon ...)</Typography>
              </Box>
            ) : (
              <>
                <Header
                  sx={{
                    display: {
                      lg: "none",
                    },
                  }}
                  source={source}
                />
                <Formik
                  initialValues={{
                    email: "",
                    firstname: "",
                    lastname: "",
                    password: "",
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.email) {
                      errors.email = "Required";
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                      errors.email = "Invalid email address";
                    }
                    if (!values.firstname) errors.firstname = "Required";
                    if (!values.password) errors.password = "Required";
                    return errors;
                  }}
                  onSubmit={(values) => {
                    onSubmit(values);
                  }}
                  validateOnChange={false}
                  // validateOnBlur={true}
                  validationSchema={formValidationScheme}
                >
                  {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                    <Form>
                      <Box>
                        <Typography variant="h4">
                          <Text>interface.account.signup</Text>
                        </Typography>
                      </Box>
                      <Box my={4}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6}>
                            <TextField
                              id="firstname"
                              onChange={handleChange}
                              value={values.firstname}
                              label={<Text suffix={" *"}>interface.account.first-name</Text>}
                              variant="standard"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                            />
                            {errors.firstname && (
                              <Alert severity="error" sx={{ mt: 1 }}>
                                {errors.firstname}
                              </Alert>
                            )}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              id="lastname"
                              onChange={handleChange}
                              value={values.lastname}
                              label={<Text>interface.account.last-name</Text>}
                              variant="standard"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                            />
                            {errors.lastname && (
                              <Alert severity="error" sx={{ mt: 1 }}>
                                {errors.lastname}
                              </Alert>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id="email"
                              type="email"
                              onChange={handleChange}
                              value={values.email}
                              label={<Text suffix={" *"}>interface.account.email</Text>}
                              variant="standard"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                            />
                            {errors.email && (
                              <Alert severity="error" sx={{ mt: 1 }}>
                                {errors.email}
                              </Alert>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id="password"
                              type="password"
                              onChange={handleChange}
                              value={values.password}
                              label={<Text suffix={" *"}>interface.account.password</Text>}
                              variant="standard"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                            />
                            {errors.password && (
                              <Alert severity="error" sx={{ mt: 1 }}>
                                {errors.password}
                              </Alert>
                            )}
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <TextField
                              id="title"
                              onChange={handleChange}
                              value={values.title}
                              label={<Text>interface.account.title</Text>}
                              variant="standard"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <TextField
                              id="company"
                              onChange={handleChange}
                              value={values.company}
                              label={<Text>interface.account.company</Text>}
                              variant="standard"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Autocomplete
                              disablePortal
                              id="country"
                              options={Countries.map((e) => ({
                                label: e.label,
                                value: e.label,
                              }))}
                              onChange={(e, nVal) => {
                                setFieldValue("country", nVal && nVal.value);
                              }}
                              renderInput={(params) => <TextField {...params} label={<Text>interface.account.country</Text>} variant="standard" InputLabelProps={{ shrink: true }} />}
                            />
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Autocomplete
                              disablePortal
                              id="source"
                              options={WhereDidYouHearAboutUs}
                              onChange={(e, nVal) => {
                                setFieldValue("source", nVal && nVal.value);
                              }}
                              renderInput={(params) => <TextField {...params} label={<Text>interface.general.where-did-you-hear</Text>} variant="standard" InputLabelProps={{ shrink: true }} />}
                            />
                          </Grid>
                          {source === Roles.INTERNAL && (
                            <Grid item xs={12}>
                              <input id="token" type="text" onChange={handleChange} value={values.token} placeholder="Invitation Code" />
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                      {errorMessage && (
                        <Box my={1}>
                          <Alert severity="error">{errorMessage}</Alert>
                        </Box>
                      )}
                      <PrimaryButton type="submit" disabled={isLoading || !captchaToken || !_.isEmpty(errors)} fullWidth>
                        {isLoading ? (
                          <Text>interface.general.please-wait</Text>
                        ) : (
                          <span>
                            <Text>interface.account.signup</Text> <i className="fa fa-chevron-right"></i>
                          </span>
                        )}
                      </PrimaryButton>
                      <Stack justifyContent="center" alignItems="center" m={2}>
                        <Box>
                          <ReCAPTCHA sitekey={GOOGLE_CAPTCHA_SITE_KEY} onChange={onRecaptchaResponse} />
                        </Box>
                      </Stack>
                      <Box mt={1} mb={4}>
                        <div className="linkButton textCenter" onClick={() => navigateTo(ROUTE_LOGIN)}>
                          <Typography>
                            <Text>interface.general.already-have-an-account</Text>
                          </Typography>
                        </div>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </>
            )}
          </Box>
        </Grid>
        <Grid
          item
          lg={7}
          sx={{
            display: {
              xs: "none",
              lg: "block",
            },
          }}
        >
          <Stack justifyContent={"center"} alignItems="center" sx={{ height: "100%" }}>
            <Hero key={"hero"} source={source} />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Signup;
