const initState = {
  open: false,
  level: "info",
  title: "",
  content: "",
  action: null,
  opens: {},
};

const dialogReducerTypes = {
  OPEN_DIALOG: "OPEN_DIALOG",
  CLOSE_DIALOG: "CLOSE_DIALOG",
  OPEN_COMMON_DIALOG: "OPEN_COMMON_DIALOG",
  CLOSE_COMMON_DIALOG: "CLOSE_COMMON_DIALOG",
};

const dialogReducers = (state = initState, action) => {
  switch (action.type) {
    case dialogReducerTypes.OPEN_DIALOG:
      return {
        open: true,
        level: action.payload.level || "info",
        title: action.payload.title,
        content: action.payload.content,
        action: action.payload.action,
      };
    case dialogReducerTypes.CLOSE_DIALOG:
      return {
        ...state,
        open: false,
      };
    case dialogReducerTypes.OPEN_COMMON_DIALOG: {
      const { id } = action.payload;
      const newOpens = {
        ...state.opens,
        [id]: true,
      };
      return {
        ...state,
        opens: newOpens,
      };
    }
    case dialogReducerTypes.CLOSE_COMMON_DIALOG: {
      const { id } = action.payload;
      const newOpens = {
        ...state.opens,
        [id]: false,
      };
      return {
        ...state,
        opens: newOpens,
      };
    }
    default:
      return state;
  }
};

export const dialogReducerActions = {
  /**
   *
   * payload = {
   *  level,
   *  title,
   *  content,
   *  action[optional]
   * }
   *
   * @param {*} dispatch
   * @param {*} payload
   */
  openDialog: (dispatch, payload) => {
    dispatch({
      type: dialogReducerTypes.OPEN_DIALOG,
      payload: payload,
    });
  },
  closeDialog: (dispatch) => {
    dispatch({
      type: dialogReducerTypes.CLOSE_DIALOG,
    });
  },
  openCommonDialog: (dispatch, payload) => {
    dispatch({
      type: dialogReducerTypes.OPEN_COMMON_DIALOG,
      payload,
    });
  },
  closeCommonDialog: (dispatch, payload) => {
    dispatch({
      type: dialogReducerTypes.CLOSE_COMMON_DIALOG,
      payload,
    });
  },
};

export default dialogReducers;
