import { Box, Stack, Typography } from "@mui/material";
import { AspectRatioIcon } from "components/Icons/MaterialIcons";
import StyledTag from "components/Tags/StyledTag";
import Text, { getText } from "components/text/Text";
import NumericFormatters from "helpers/NumericFormatters";
import palette from "themes/palette";

const PondDashboardHero = ({ pond }) => {
  if (!pond) return "";
  return (
    <Box>
      <Stack direction={"row"} spacing={0.5} alignItems={"center"}>
        <Typography color="secondary" fontSize={18} fontWeight={800} sx={{ opacity: 0.5 }}>
          <Text>interface.general.pond</Text>
        </Typography>
        <Typography color="secondary" fontSize={18} fontWeight={800}>
          {pond?.name}
        </Typography>
      </Stack>
      <Stack direction={"row"} spacing={0.5} mt={0.2}>
        <StyledTag icon={<AspectRatioIcon />} label={NumericFormatters.formatAreaInHa({ value: pond?.area })} tooltip={getText("interface.general.pond-area")} color={palette.secondary.main} />
        <StyledTag label={"G: " + pond?.group} tooltip={getText("interface.general.group")} color={palette.secondary.main} />
        <StyledTag label={"C: " + getText("interface.general." + pond?.category)} tooltip={getText("interface.general.category")} color={palette.secondary.main} />
        <StyledTag label={"SC: " + getText("interface.species." + pond?.sub_category)} tooltip={getText("interface.general.sub-category")} color={palette.secondary.main} />
      </Stack>
    </Box>
  );
};

export default PondDashboardHero;
