import { Box, Button, ButtonGroup, Stack, Typography, colors } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { AppSelectors } from "redux/AppReducers";
import { cycleManagementActions } from "redux/pondManagement/cycleManagement";
import CycleManagementEditPanel from "screens/Aquaculture/components/CycleManager/CycleEditPanel";
import { PondManagerLayoutStyles } from "screens/Aquaculture/components/PondManagerMapView";

import Timeline, { CustomMarker, DateHeader, TimelineHeaders, TimelineMarkers } from "react-calendar-timeline";

import "css/ThirdParty/ReactCalenderTimeline.css";

import { CenterFocusStrongIcon, CircleIcon, EditIcon, RefreshIcon, ZoomInIcon, ZoomOutIcon } from "components/Icons/MaterialIcons";
import Text, { getText } from "components/text/Text";
import TimeFormatters from "helpers/TimeFormatters";
import { googleMapManagementActions } from "redux/pondManagement/googleMapManagement";
import palette from "themes/palette";
import OutlinedTag from "components/Tags/OutlinedTag";
import { MdCheckCircle, MdRadioButtonUnchecked } from "react-icons/md";
import { DateTime } from "luxon";
import { PondManagerServices } from "api/pondManagerServices";
import { alertsActions } from "redux/alerts";
import { Lock, ViewAgenda, ViewHeadline } from "@mui/icons-material";
import MuiToggleButtonGroup from "components/buttons/MuiToggleButtonGroup";
import NumericFormatters from "helpers/NumericFormatters";
import { ResourcePolicies } from "screens/Aquaculture/components/AccessControl/ResourcePolicies";

const PageIndices = {
  ganttView: 0,
  editView: 1,
};

const TimelineSizing = {
  compact: "compact",
  detailed: "detailed",
};

const CycleTimeline = ({ ponds, farm }) => {
  const [pageIndex, setPageIndex] = useState(PageIndices.ganttView); // 0: gantt view 1: edit view
  const [dataOnEdit, setDataOnEdit] = useState(null);
  const [selectedCycles, setCycles] = useState([]);
  const [selectedCycleId, setCycleId] = useState(null);
  const [cycleToCreate, setCycleToCreate] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [timelineSizing, setTimelineSizing] = useState(TimelineSizing.compact);

  const dispatch = useDispatch();

  const draggingRef = useRef(null);
  const [isDraggingItem, setDraggingItem] = useState(null);

  // REDUX
  const cycleManagementStore = AppSelectors.cycleStore();
  const cycles = cycleManagementStore.cycles;
  const selectedDate = cycleManagementStore.selectedDate;
  const [initDate, setInitDate] = useState(selectedDate);

  const timelineRef = useRef(null);

  // Permissions
  const disableEdit = !_.find(farm?.policies, { code: ResourcePolicies["CYCLE:WRITE"].code });

  useEffect(() => {
    // Cleanup the timeout when the component unmounts
    if (draggingRef.current) {
      clearTimeout(draggingRef.current);
    }
  }, []);

  useEffect(() => {
    if (selectedDate !== initDate) {
      TimelineActions.showPeriod(selectedDate);
    }
  }, [selectedDate]);

  const Actions = {
    onLoad: async () => {
      await cycleManagementActions.loadAllCyclesByFarmId(dispatch, farm?.farm_id);
    },
    onDoubleClick: useCallback((payload) => {
      setDataOnEdit(payload);
      setPageIndex(PageIndices.editView);
    }),
    onExitEdit: useCallback(() => {
      Actions.onLoad();
      setPageIndex(PageIndices.ganttView);
    }),
    onDeselectCycle: useCallback(() => {
      setCycles([]);
      setCycleId(null);
    }),
    onSelectCycle: useCallback((cid) => {
      let selectedCycles_ = selectedCycles;
      if (_.includes(selectedCycles, cid)) {
        selectedCycles_ = selectedCycles_.filter((ele) => ele !== cid);
        setCycles(selectedCycles_);
      } else {
        setCycles([...selectedCycles_, cid]);
      }
    }),
    onMoveEnd: async ({ itemId, dragTime, newGroupOrder }) => {
      const targetCycle = _.find(_.flatten(_.values(cycles)), { id: itemId });
      const oldStartDate = DateTime.fromFormat(targetCycle?.start_date, "yyyy-MM-dd");
      const oldEndDate = DateTime.fromFormat(targetCycle?.end_date, "yyyy-MM-dd");
      const oldCycleDays = oldEndDate.diff(oldStartDate, "days")?.days;
      const newStartDate = DateTime.fromMillis(dragTime);
      const newEndDate = DateTime.fromMillis(dragTime).plus({ days: oldCycleDays });
      const newStartDateStr = newStartDate.toFormat("yyyy-MM-dd");
      const newEndDateStr = newEndDate.toFormat("yyyy-MM-dd");
      const cycleNew = {
        ...targetCycle,
        start_date: newStartDateStr,
        end_date: newEndDateStr,
      };
      // update cycle
      await PondManagerServices.updateCycle(cycleNew);
      alertsActions.addInfo(dispatch, {
        content: getText("interface.alert.alert-success"),
      });
      await Actions.onLoad();
    },
    onResizeEnd: async ({ itemId, endTimeOrStartTime, edge }) => {
      const targetCycle = _.find(_.flatten(_.values(cycles)), { id: itemId });
      const oldStartDate = DateTime.fromFormat(targetCycle?.start_date, "yyyy-MM-dd");
      const oldEndDate = DateTime.fromFormat(targetCycle?.end_date, "yyyy-MM-dd");
      let cycleNew = null;
      if (edge === "left") {
        const newStartDate = DateTime.fromMillis(endTimeOrStartTime.valueOf());
        const newEndDate = oldEndDate;
        const newStartDateStr = newStartDate.toFormat("yyyy-MM-dd");
        const newEndDateStr = newEndDate.toFormat("yyyy-MM-dd");
        cycleNew = {
          ...targetCycle,
          start_date: newStartDateStr,
          end_date: newEndDateStr,
        };
      } else {
        const newStartDate = oldStartDate;
        const newEndDate = DateTime.fromMillis(endTimeOrStartTime.valueOf());
        const newStartDateStr = newStartDate.toFormat("yyyy-MM-dd");
        const newEndDateStr = newEndDate.toFormat("yyyy-MM-dd");
        cycleNew = {
          ...targetCycle,
          start_date: newStartDateStr,
          end_date: newEndDateStr,
        };
      }
      if (cycleNew.start_date >= cycleNew.end_date) {
        alertsActions.addError(dispatch, {
          content: "Start Date MUST BE earlier than End Date",
        });
        return;
      }
      if (cycleNew) {
        // update cycle
        await PondManagerServices.updateCycle(cycleNew);
        alertsActions.addInfo(dispatch, {
          content: getText("interface.alert.alert-success"),
        });
        await Actions.onLoad();
      }
    },
    onBulkDelete: () => {},
    onCreatePondCycle: async ({ pondId, time }) => {
      if (!editMode) return;
      // constructe new cycle
      const startDate = DateTime.fromMillis(time);
      const endDate = DateTime.fromMillis(time).plus({ months: 6 });
      const startYear = startDate.toFormat("yyyy");
      const newCycle = {
        label: `C#${startYear}`,
        pond_id: pondId,
        start_date: startDate.toFormat("yyyy-MM-dd"),
        end_date: endDate.toFormat("yyyy-MM-dd"),
      };
      // setCycleToCreate(newCycle);
      await PondManagerServices.createCycle(newCycle);
      alertsActions.addInfo(dispatch, {
        content: getText("interface.alert.alert-success"),
      });
      await Actions.onLoad();
    },
    handleTimelineItemKeyDown: async (e) => {
      if (!editMode) return;
      // Del is pressed
      if (e.keyCode === 46) {
        if (selectedCycles.length > 0) {
          const cyclesToDelete = _.flatten(_.values(cycles)).filter((c) => _.includes(selectedCycles, c.id));
          await PondManagerServices.deleteCycles(cyclesToDelete);
          alertsActions.addInfo(dispatch, {
            content: getText("interface.alert.alert-success"),
          });
          await Actions.onLoad();
          setCycles([]);
          return;
        }
        if (selectedCycle) {
          await PondManagerServices.deleteCycle(selectedCycle.pond_id, selectedCycle.id);
          alertsActions.addInfo(dispatch, {
            content: getText("interface.alert.alert-success"),
          });
          setCycleId(null);
          await Actions.onLoad();
        }
      }
    },
  };

  const TimelineActions = {
    onZoomIn: () => {
      if (timelineRef && timelineRef.current) {
        timelineRef.current.changeZoom(0.5);
      }
    },
    onZoomOut: () => {
      if (timelineRef && timelineRef.current) {
        timelineRef.current.changeZoom(1.5);
      }
    },
    showPeriod: (pivotDate) => {
      if (timelineRef && timelineRef.current) {
        timelineRef.current.showPeriod(moment(pivotDate).add(-1.5, "years"), moment(pivotDate).add(2.5, "years"));
      }
    },
  };

  const groups = ponds.map((el) => ({
    id: el.id,
    title: `POND: ${el.label}`,
    properties: {
      pond: el,
      cycles: cycles[el.id] || [],
      hasActive: (cycles[el.id] || []).filter((ele) => !ele.ended).length > 0,
      activeCycle: _.last((cycles[el.id] || []).filter((ele) => !ele.ended)),
    },
  }));

  const items = _.flatten(
    ponds.map((el) => {
      if (!cycles[el.id]) return [];
      return (cycles[el.id] || []).map((c) => ({
        className: c.ended ? "c-ended" : "c-active",
        id: c.id,
        group: el.id,
        title: c.label,
        tip: c.label,
        start_time: moment(c.start_date),
        end_time: moment(c.end_date),
        properties: c,
        canChangeGroup: true,
        itemProps: {
          onDoubleClick: () => {
            !disableEdit &&
              Actions.onDoubleClick({
                cycle: c,
                pond: el,
              });
          },
        },
      }));
    })
  );

  const selectedCycle = _.find(_.flatten(_.values(cycles)), { id: selectedCycleId });

  return (
    <Box tabIndex={0} onKeyDown={Actions.handleTimelineItemKeyDown}>
      {pageIndex === 0 && (
        <Box
          sx={{
            maxHeight: "calc(100vh - 300px)",
            overflow: "auto",
          }}
        >
          <Box my={2}>
            <Stack direction={"row"} alignItems={"center"} spacing={1} justifyContent={"space-between"}>
              <Stack direction={"row"} spacing={1}>
                <ButtonGroup size="small" variant="outlined">
                  <Button onClick={TimelineActions.onZoomIn} startIcon={<ZoomInIcon />}>
                    <Text>interface.actions.zoom</Text>
                  </Button>
                  <Button onClick={TimelineActions.onZoomOut} startIcon={<ZoomOutIcon />}>
                    <Text>interface.actions.zoom</Text>
                  </Button>
                  <Button onClick={() => TimelineActions.showPeriod(selectedDate)} startIcon={<CenterFocusStrongIcon />}>
                    <Text>interface.actions.center</Text>
                  </Button>
                </ButtonGroup>
                <MuiToggleButtonGroup
                  onChange={setTimelineSizing}
                  value={timelineSizing}
                  options={[
                    {
                      icon: <ViewAgenda />,
                      value: TimelineSizing.compact,
                    },
                    {
                      icon: <ViewHeadline />,
                      value: TimelineSizing.detailed,
                    },
                  ]}
                />
                {!disableEdit && (
                  <MuiToggleButtonGroup
                    onChange={setEditMode}
                    value={editMode}
                    options={[
                      {
                        icon: <Lock />,
                        value: false,
                      },
                      {
                        icon: <EditIcon />,
                        value: true,
                      },
                    ]}
                  />
                )}
              </Stack>
              {selectedCycles.length > 0 ? (
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                  <Box>
                    <Typography>{selectedCycles.length} Selected:</Typography>
                  </Box>
                  <ButtonGroup size="small" variant="outlined">
                    {/* <Button startIcon={<DeleteIcon />}>
                      <Text>interface.actions.delete</Text>
                    </Button> */}
                    <Button startIcon={<RefreshIcon />} onClick={Actions.onDeselectCycle}>
                      <Text>interface.actions.cancel</Text>
                    </Button>
                  </ButtonGroup>
                </Stack>
              ) : (
                selectedCycle && (
                  <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
                    <OutlinedTag text={selectedCycle?.label} tooltip={getText("interface.general.label")} />
                    <OutlinedTag text={selectedCycle?.start_date} tooltip={getText("interface.general.start-date")} />
                    <OutlinedTag text={selectedCycle?.end_date} tooltip={getText("interface.general.end-date")} />
                    <OutlinedTag text={!selectedCycle?.ended ? "ACTIVE" : "ENDED"} tooltip={getText("interface.general.status")} />
                    <ButtonGroup size="small" variant="outlined">
                      {/* <Button startIcon={<DeleteIcon />}>
                        <Text>interface.actions.delete</Text>
                      </Button> */}
                    </ButtonGroup>
                  </Stack>
                )
              )}
            </Stack>
          </Box>
          <Box>
            {isDraggingItem && (
              <Stack direction={"row"}>
                <Typography>
                  {isDraggingItem?.start_date} ~ {isDraggingItem?.end_date}
                </Typography>
              </Stack>
            )}
          </Box>
          <Box
            overflow={"hidden"}
            sx={{
              borderRadius: 4,
              "&:focus": {},
            }}
          >
            <Timeline
              ref={timelineRef}
              groups={groups}
              items={items}
              lineHeight={timelineSizing === TimelineSizing.compact ? 35 : 100}
              defaultTimeStart={moment(selectedDate).add(-1.5, "years")}
              defaultTimeEnd={moment(selectedDate).add(2, "years")}
              itemHeightRatio={0.8}
              showCursorLine
              minZoom={60 * 1000 * 60 * 24 * 30}
              maxZoom={60 * 1000 * 60 * 24 * 365 * 6}
              stackItems
              canMove={editMode}
              canResize={editMode ? "both" : false}
              useResizeHandle={editMode}
              groupRenderer={({ group }) => {
                return (
                  <Box>
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      height={"100%"}
                      spacing={1}
                      sx={{
                        "&:hover": {
                          textDecoration: "underline",
                          cursor: "pointer",
                          color: palette.secondary.main,
                        },
                      }}
                      onClick={() => {
                        googleMapManagementActions.highlightPond(dispatch, group.properties.pond);
                      }}
                    >
                      <CircleIcon sx={{ color: group.properties.hasActive ? colors.green.A700 : colors.grey[400], fontSize: 12 }} />
                      <Typography fontSize={11} fontWeight={600}>
                        <Text>interface.general.pond</Text>: {group.properties.pond.label}
                      </Typography>
                    </Stack>
                    {group.properties.activeCycle && (
                      <Typography fontSize={10} lineHeight={1} color="grey">
                        {group.properties.activeCycle.start_date} ~ {group.properties.activeCycle.end_date}
                      </Typography>
                    )}
                  </Box>
                );
              }}
              itemRenderer={({ item, itemContext, getItemProps, getResizeProps }) => {
                const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();

                const selected = _.includes(selectedCycles, item.id);

                const dragColor = palette.orange.main;
                const dragBorderColor = palette.orange.main;

                const backgroundColor = selected ? colors.blue[300] : !item.properties.ended ? colors.green[300] : "rgba(120, 120, 120, 0.2)";
                const borderColor = selected ? colors.blue[800] : !item.properties.ended ? colors.green[300] : colors.grey[300];

                const selectedBackgroundColor = palette.orange.light;
                const selectedBorderColor = palette.orange.dark;

                const backgroundColor_ = itemContext.selected ? (itemContext.dragging ? dragColor : selectedBackgroundColor) : backgroundColor;
                const borderColor_ = itemContext.selected ? (itemContext.dragging ? dragBorderColor : selectedBorderColor) : borderColor;
                const fontColor = palette.primary.main;

                const getPopupText = () => {
                  const targetCycle = item.properties;
                  const oldStartDate = DateTime.fromFormat(targetCycle?.start_date, "yyyy-MM-dd");
                  const oldEndDate = DateTime.fromFormat(targetCycle?.end_date, "yyyy-MM-dd");
                  const oldCycleDays = oldEndDate.diff(oldStartDate, "days")?.days;
                  if (itemContext?.dragging) {
                    const newStartDate = DateTime.fromMillis(itemContext?.dragTime.valueOf()).toFormat("yyyy-MM-dd");
                    const newEndDate = DateTime.fromMillis(itemContext?.dragTime.valueOf()).plus({ days: oldCycleDays }).toFormat("yyyy-MM-dd");
                    return `${newStartDate} ~ ${newEndDate}`;
                  }
                  if (itemContext?.resizing) {
                    if (itemContext?.resizeEdge === "right") {
                      const newStartDate = oldStartDate.toFormat("yyyy-MM-dd");
                      const newEndDate = DateTime.fromMillis(itemContext?.resizeTime.valueOf()).toFormat("yyyy-MM-dd");
                      return `${newStartDate} ~ ${newEndDate}`;
                    } else {
                      const newStartDate = DateTime.fromMillis(itemContext?.resizeTime.valueOf()).toFormat("yyyy-MM-dd");
                      const newEndDate = oldEndDate.toFormat("yyyy-MM-dd");
                      return `${newStartDate} ~ ${newEndDate}`;
                    }
                  }
                  return null;
                };
                const popupText = getPopupText();
                const displayContent =
                  timelineSizing === TimelineSizing.compact ? (
                    <Typography fontSize={10} fontWeight={800} whiteSpace={"nowrap"}>
                      {itemContext.title}
                    </Typography>
                  ) : (
                    <Stack maxWidth={200} textOverflow={"ellipsis"}>
                      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} spacing={1}>
                        <Typography fontSize={10} color="grey" fontWeight={800} whiteSpace={"nowrap"}>
                          {itemContext.title}
                        </Typography>
                      </Stack>
                      <Typography fontSize={10} fontWeight={800}>
                        {item.properties.start_date}
                      </Typography>
                      {item.properties.ended && (
                        <Typography fontSize={10} fontWeight={800}>
                          ~ {item.properties.end_date}
                        </Typography>
                      )}
                      {!item.properties.ended && (
                        <Typography fontSize={10} fontWeight={800}>
                          ~ ACTIVE
                        </Typography>
                      )}
                      <Typography fontSize={10} color="grey" fontWeight={800} whiteSpace={"nowrap"}>
                        {NumericFormatters.format({ value: item.properties.total_days, decimalScale: 0, suffix: " d" })}
                      </Typography>
                    </Stack>
                  );
                return (
                  <div
                    {...getItemProps({
                      ...item.itemProps,
                      style: {
                        backgroundColor: backgroundColor_,
                        borderColor: borderColor_,
                        color: fontColor,
                        borderRadius: 6,
                      },
                    })}
                  >
                    {/* ---------- The Left Handle ------------ */}
                    {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ""} {/* ---------- Content ------------ */}
                    <Stack direction={"row"} alignItems={"center"} flexWrap={"nowrap"} spacing={0.4} height={"100%"} pl={0.4}>
                      <Box
                        onClick={(e) => {
                          e.preventDefault();
                          Actions.onSelectCycle(item.id);
                        }}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          svg: {
                            fontSize: 18,
                            color: colors.grey[600],
                          },
                          "&:hover": {
                            svg: {
                              color: colors.grey[800],
                            },
                          },
                        }}
                      >
                        {selected ? <MdCheckCircle /> : <MdRadioButtonUnchecked />}
                      </Box>
                      {displayContent}
                      {popupText && (
                        <Box
                          sx={{
                            position: "absolute",
                            top: 1,
                            left: -170,
                            width: 150,
                            p: 0,
                            bgcolor: "#000000ad",
                            boxShadow: 1,
                            borderRadius: 2,
                            zIndex: 999999,
                          }}
                        >
                          <Typography color="#FFF" textAlign={"center"} fontSize={10} fontWeight={800}>
                            {popupText}
                          </Typography>
                        </Box>
                      )}
                      {popupText && (
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: 1,
                            right: -170,
                            width: 150,
                            p: 0,
                            bgcolor: "#000000ad",
                            boxShadow: 1,
                            borderRadius: 2,
                            zIndex: 999999,
                          }}
                        >
                          <Typography color="#FFF" textAlign={"center"} fontSize={10} fontWeight={800}>
                            {popupText}
                          </Typography>
                        </Box>
                      )}
                    </Stack>
                    {/* ---------- The Right Handle ------------ */}
                    {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ""}
                  </div>
                );
              }}
              dragSnap={24 * 60 * 60 * 1000}
              onItemMove={(itemId, dragTime, newGroupOrder) => {
                Actions.onMoveEnd({ itemId, dragTime, newGroupOrder });
              }}
              onItemResize={(itemId, endTimeOrStartTime, edge) => {
                Actions.onResizeEnd({ itemId, endTimeOrStartTime, edge });
              }}
              onItemSelect={(itemId) => {
                setCycleId(itemId);
              }}
              onItemDeselect={(itemId) => {
                setCycleId(null);
              }}
              onCanvasDoubleClick={(groupId, time, e) => {
                Actions.onCreatePondCycle({
                  pondId: groupId,
                  time: time,
                });
              }}
            >
              <TimelineHeaders>
                <DateHeader unit="primaryHeader" />
                <DateHeader
                  unit="month"
                  style={{ height: 50 }}
                  labelFormat="YYYY-MM-DD"
                  intervalRenderer={({ getIntervalProps, intervalContext }) => {
                    return (
                      <div {...getIntervalProps()}>
                        <Stack alignItems={"center"} justifyContent={"center"} pt={1}>
                          <Typography fontSize={9} fontWeight={800} textTransform={"uppercase"}>
                            {TimeFormatters.formatDatetime(intervalContext.intervalText, intervalContext.interval.labelWidth > 22 ? "MMM" : "M", "yyyy-MM-dd")}
                          </Typography>
                        </Stack>
                      </div>
                    );
                  }}
                />
                <DateHeader
                  unit="day"
                  style={{ height: "20px" }}
                  labelFormat="YYYY-MM-DD"
                  intervalRenderer={({ getIntervalProps, intervalContext }) => {
                    if (intervalContext.interval.labelWidth < 12) {
                      return "";
                    }
                    return (
                      <div {...getIntervalProps()}>
                        <Stack alignItems={"center"} justifyContent={"center"} pt={1}>
                          <Typography fontSize={9} fontWeight={800} textTransform={"uppercase"}>
                            {TimeFormatters.formatDatetime(intervalContext.intervalText, "d", "yyyy-MM-dd")}
                          </Typography>
                        </Stack>
                      </div>
                    );
                  }}
                />
              </TimelineHeaders>
              <TimelineMarkers>
                <CustomMarker date={moment(selectedDate)}>
                  {({ styles, date }) => {
                    return (
                      <div
                        style={{
                          ...styles,
                          backgroundColor: colors.red[400],
                          zIndex: 99,
                          width: 2,
                        }}
                      />
                    );
                  }}
                </CustomMarker>
              </TimelineMarkers>
            </Timeline>
          </Box>
        </Box>
      )}

      {pageIndex === 1 && (
        <Box>
          <CycleManagementEditPanel
            {...{
              cycle: dataOnEdit.cycle,
              pond: dataOnEdit.pond,
              farm,
              onExit: Actions.onExitEdit,
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default CycleTimeline;
