import { colors } from "@mui/material";
import PlatformAccessPolices from "constants/PlatformAccessPolicies";

export const CollabAccessLevels = {
  OWNER: 4,
  ADMIN: 3,
  MANAGER: 2,
  VIEWER: 1,
  ADVISOR: 5,
  TECHNICIAN: 6,
};

export const getCollabAccessDetails = (levelId) => {
  return FarmAccessLevels?.[levelId] || FarmAccessLevels["_unknown"];
};

const FarmAccessLevels = {
  [CollabAccessLevels.OWNER]: {
    id: 4,
    displayKey: "interface.access-control.owner",
    color: colors.red[700],
    bgColor: colors.red[50],
    policies: [
      PlatformAccessPolices.modules.dashboard.r,
      PlatformAccessPolices.modules.cycle.r,
      PlatformAccessPolices.modules.dataexplorer.r,
      PlatformAccessPolices.modules.datamanager.r,
      PlatformAccessPolices.modules.files.r,
      PlatformAccessPolices.modules.forecast.r,
      PlatformAccessPolices.modules.report.r,
      PlatformAccessPolices.modules.sateye.r,
      PlatformAccessPolices.modules.scenariobuilder.r,
    ],
  },
  [CollabAccessLevels.ADMIN]: {
    id: 3,
    displayKey: "interface.access-control.admin",
    color: colors.orange[500],
    bgColor: colors.orange[50],
    policies: [
      PlatformAccessPolices.modules.dashboard.r,
      PlatformAccessPolices.modules.cycle.r,
      PlatformAccessPolices.modules.dataexplorer.r,
      PlatformAccessPolices.modules.datamanager.r,
      PlatformAccessPolices.modules.files.r,
      PlatformAccessPolices.modules.forecast.r,
      PlatformAccessPolices.modules.report.r,
      PlatformAccessPolices.modules.sateye.r,
      PlatformAccessPolices.modules.scenariobuilder.r,
    ],
  },
  [CollabAccessLevels.MANAGER]: {
    id: 2,
    displayKey: "interface.access-control.manager",
    color: colors.blue[500],
    bgColor: colors.blue[50],
    policies: [
      PlatformAccessPolices.modules.dashboard.r,
      PlatformAccessPolices.modules.cycle.r,
      PlatformAccessPolices.modules.dataexplorer.r,
      PlatformAccessPolices.modules.datamanager.r,
      // PlatformAccessPolices.modules.files.r,
      PlatformAccessPolices.modules.forecast.r,
      PlatformAccessPolices.modules.report.r,
      PlatformAccessPolices.modules.sateye.r,
      PlatformAccessPolices.modules.scenariobuilder.r,
    ],
  },
  [CollabAccessLevels.ADVISOR]: {
    id: 5,
    displayKey: "interface.access-control.advisor",
    color: colors.purple[700],
    bgColor: colors.purple[50],
    policies: [
      PlatformAccessPolices.modules.dashboard.r,
      PlatformAccessPolices.modules.cycle.r,
      PlatformAccessPolices.modules.dataexplorer.r,
      // PlatformAccessPolices.modules.datamanager.r,
      // PlatformAccessPolices.modules.files.r,
      PlatformAccessPolices.modules.forecast.r,
      PlatformAccessPolices.modules.report.r,
      PlatformAccessPolices.modules.sateye.r,
      PlatformAccessPolices.modules.scenariobuilder.r,
    ],
  },
  [CollabAccessLevels.VIEWER]: {
    id: 1,
    displayKey: "interface.access-control.viewer",
    color: colors.green[500],
    bgColor: colors.green[50],
    policies: [
      PlatformAccessPolices.modules.dashboard.r,
      PlatformAccessPolices.modules.cycle.r,
      PlatformAccessPolices.modules.dataexplorer.r,
      // PlatformAccessPolices.modules.datamanager.r,
      // PlatformAccessPolices.modules.files.r,
      PlatformAccessPolices.modules.forecast.r,
      PlatformAccessPolices.modules.report.r,
      PlatformAccessPolices.modules.sateye.r,
      // PlatformAccessPolices.modules.scenariobuilder.r,
    ],
  },
  [CollabAccessLevels.TECHNICIAN]: {
    id: 1,
    displayKey: "interface.access-control.technician",
    color: colors.teal[500],
    bgColor: colors.teal[50],
    policies: [
      // PlatformAccessPolices.modules.dashboard.r,
      // PlatformAccessPolices.modules.cycle.r,
      // PlatformAccessPolices.modules.dataexplorer.r,
      PlatformAccessPolices.modules.datamanager.r,
      // PlatformAccessPolices.modules.files.r,
      // PlatformAccessPolices.modules.forecast.r,
      // PlatformAccessPolices.modules.report.r,
      // PlatformAccessPolices.modules.sateye.r,
      // PlatformAccessPolices.modules.scenariobuilder.r,
    ],
  },
  _unknown: {
    display: "Unknown",
    color: colors.grey[700],
    bgColor: colors.grey[50],
    policies: [],
  },
};

export default FarmAccessLevels;
