import { PondManagerServices } from "api/pondManagerServices";
import { Box, Typography } from "@mui/material";
import OrgRow from "screens/Aquaculture/components/OrgConnect/components/OrgRow";
import { CloseIcon } from "components/Icons/MaterialIcons";
import { VerticalAlertBoxCollections } from "components/Alerts/AlertBox";
import { useDispatch } from "react-redux";
import { alertsActions } from "redux/alerts";

const PendingSentOrgList = ({ farm, list = [], actions }) => {
  const dispatch = useDispatch();

  const Actions = {
    onDeleteConfirm: async ({ orgid }) => {
      await PondManagerServices.removeConnectionToOrg({ farmid: farm.farm_id, orgid: orgid });
      alertsActions.addInfo(dispatch, {
        content: "Request has been removed",
      });
      actions.onLoadConnectedOrgs();
    },
  };

  return (
    <Box>
      <Box mb={3}>
        <Typography variant="cardTitle">Connect Request Sent</Typography>
      </Box>
      <Box>
        {list.length === 0 && <VerticalAlertBoxCollections.NothingShow />}
        {list.map((ele) => {
          return (
            <OrgRow
              data={ele}
              action={{
                action: () => {
                  Actions.onDeleteConfirm({ orgid: ele.org_id });
                },
                icon: <CloseIcon />,
              }}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default PendingSentOrgList;
