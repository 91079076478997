import { Box, colors } from "@mui/material";
import HighchartsWrapper from "components/Highcharts/HighchartsWrapper";
import _ from "lodash";

const median = (values) => {
  if (values.length === 0) {
    return 0;
  }

  values = [...values].sort((a, b) => a - b);

  const half = Math.floor(values.length / 2);

  return values.length % 2
    ? values[half]
    : (values[half - 1] + values[half]) / 2;
};

const quantile = (arr, q) => {
  const sorted = arr.sort();
  const pos = (sorted.length - 1) * q;
  const base = Math.floor(pos);
  const rest = pos - base;
  if (sorted[base + 1] !== undefined) {
    return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
  } else {
    return sorted[base];
  }
};

const calculateBoxChartData = (data) => {
  const min = _.minBy(data, "value")?.value;
  const max = _.maxBy(data, "value")?.value;
  const med = median(data.map((ele) => ele.value));
  const upperQ = quantile(
    data.map((ele) => ele.value),
    0.75
  );
  const lowerQ = quantile(
    data.map((ele) => ele.value),
    0.25
  );
  return [min, lowerQ, med, upperQ, max];
};

const BoxChart = ({ data, ponds }) => {
  // validate data
  if (data.length === 0) return "";

  const data_ = calculateBoxChartData(data);

  const chartConfigs = {
    chart: {
      type: "boxplot",
    },
    title: {
      text: "",
    },
    tooltip: {
      useHTML: true,
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
        },
      },
    },
    yAxis: {
      title: {
        text: "Value",
      },
    },
    xAxis: {
      visible: false,
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        name: "",
        data: [data_],
        color: colors.blue[800],
        fillColor: colors.blue[100],
      },
    ],
  };

  return (
    <Box>
      <HighchartsWrapper options={chartConfigs} />
    </Box>
  );
};

export default BoxChart;
