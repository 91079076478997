import { Box, Typography } from "@mui/material";
import LoadingBox from "components/Loading/LoadingBox";
import Text, { getText } from "components/text/Text";
import _ from "lodash";
import React from "react";
import { useDispatch } from "react-redux";
import { dialogReducerActions } from "redux/dialog";
import { organisationManagementActions } from "redux/pondManagement/organisationManagement";
import OrganisationForm from "screens/Aquaculture/Organisations/OrganisationForm";

const OrganisationEditPage = ({ org }) => {
  const dispatch = useDispatch();

  const Actions = {
    postSubmitAction: () => {
      dialogReducerActions.openDialog(dispatch, {
        title: getText("interface.alert.alert-success"),
      });
      organisationManagementActions.loadOrgs(dispatch);
    },
  };

  return (
    <Box px={2}>
      <Box my={2}>
        <Typography variant="h6" textTransform={"uppercase"}>
          <Text>interface.actions.update</Text>
        </Typography>
      </Box>
      <Box mt={2}>{!org ? <LoadingBox /> : <OrganisationForm postSubmitAction={Actions.postSubmitAction} org={org} />}</Box>
    </Box>
  );
};

export default OrganisationEditPage;
