import { Box } from "@mui/material";
import { VerticalAlertBoxCollections } from "components/Alerts/AlertBox";
import { getText } from "components/text/Text";
import React, { useState } from "react";
import MuiTabs, { MuiTabsThemes } from "ui/tabs/MuiTabs";

const dashboardIndex = {
  // El Caracol
  "683d4bf7-9d8a-4747-9d07-2fbf0f560f6a": {
    biomass: "https://www.arcgis.com/apps/dashboards/d377db77aee64656b88f4cb535f17774",
    dissolved_oxygen: "https://www.arcgis.com/apps/dashboards/636b7f05e87241bfb082b1662bea689a",
    water_temp: "https://www.arcgis.com/apps/dashboards/64eb48260967447bb7b5f6be86fbf446",
    nitrogen: "https://www.arcgis.com/apps/dashboards/1d524b1156854c3ab9076d91f169d8f8",
    suspended_solids: "https://www.arcgis.com/apps/dashboards/937b57b60bb643ffb17df0ce8e2404c1",
    conductivity: "https://www.arcgis.com/apps/dashboards/33d2663b8fbb4b1ca34b843f767f1936",
    turbidity: "https://www.arcgis.com/apps/dashboards/16cbedf9f8d4450983a753867a703901",
    salinity: "https://www.arcgis.com/apps/dashboards/faf4a1d581c74fe1a86a529d516d3d32",
    chlorophyll: "https://www.arcgis.com/apps/dashboards/774b0a81b49347d88e596775ed9de7d4",
    organic_matter: "https://www.arcgis.com/apps/dashboards/1ad956a5a98e4af5a4b6cc114e45dc9f",
  },
  // VN2401 Pilot - GBVN
  "a1468cbc-4ae7-435f-acfd-29d12daa241a": {
    biomass: "https://www.arcgis.com/apps/dashboards/a8ba63868471487b8d5a60fd900d43cb",
    dissolved_oxygen: "https://www.arcgis.com/apps/dashboards/0d316325adf64c1b897856cb71f842b0",
    water_temp: "https://www.arcgis.com/apps/dashboards/1e4ee7e1505d4d3082f40c682b8008f5",
    salinity: "https://www.arcgis.com/apps/dashboards/46c44443022d4fec9d6369905fe2d490",
    turbidity: "https://www.arcgis.com/apps/dashboards/cfc1c3fb077a43f28a480bd16f659b85",
    chlorophyll: "https://www.arcgis.com/apps/dashboards/91fd95081b4e412cb6d336b1b942ea2c",
    suspended_solids: "https://www.arcgis.com/apps/dashboards/2949836e93d643b89ee848861479caf0",
    nitrogen: "https://www.arcgis.com/apps/dashboards/9369cc4d7dd145c1bc93a80e29d1f995",
    organic_matter: "https://www.arcgis.com/apps/dashboards/fc4cb82eb1ec41cc8e7bab7d8c03afb5",
    conductivity: "https://www.arcgis.com/apps/dashboards/f39382fcdc344f6ba062e7a0af196521",
    phosphorus: "https://www.arcgis.com/apps/dashboards/4fa4acda5f9746299f989c2beb3b715b",
  },
  // Oman Farm #1
  "91b6d486-43aa-4eca-8e91-f10631f9ce35": {
    biomass: "https://www.arcgis.com/apps/dashboards/aabb85313e554a4f828396c5e5809c66",
    dissolved_oxygen: "https://www.arcgis.com/apps/dashboards/c18ec1e8cf1f45efb6bc9359afdf5dd0",
    water_temp: "https://www.arcgis.com/apps/dashboards/7e4e2ba31fdc41dbaab14ad10ee02906",
  },
  // Darren Lee
  "f98ccece-d81f-4607-8ceb-f17c4fae64a4": {
    // biomass: "https://www.arcgis.com/apps/dashboards/aabb85313e554a4f828396c5e5809c66",
    dissolved_oxygen: "https://www.arcgis.com/apps/dashboards/8053501a0cf447aa8e737043062fed3c",
    water_temp: "https://www.arcgis.com/apps/dashboards/0d81c085836348ea9701a2604a310a12",
  },
  // Kopaggio Demo
  "d2c7bf74-1edb-4e45-a65b-e10c72a93b15": {
    biomass: "https://www.arcgis.com/apps/dashboards/53d9fcc328614f0aac71cbfeb7c201f1",
    dissolved_oxygen: "https://www.arcgis.com/apps/dashboards/26b029edf38e4bd4b538fb2678a2b960",
    water_temp: "https://www.arcgis.com/apps/dashboards/114838a4dc1a4cf19f3a78402fd79618",
    salinity: "https://www.arcgis.com/apps/dashboards/0ada362e388743a59bf3e6b9d972b97f",
    turbidity: "https://www.arcgis.com/apps/dashboards/52cbf1dc48e04e45a7cf531c84fc0a6f",
    chlorophyll: "https://www.arcgis.com/apps/dashboards/fe43b04a945246febd2f9a332f8905a0",
    suspended_solids: "https://www.arcgis.com/apps/dashboards/b6ec94b5f3354ba88e29613a9aca88b9",
    nitrogen: "https://www.arcgis.com/apps/dashboards/dfc8642fe4664414a08b892af4f68f66",
    organic_matter: "https://www.arcgis.com/apps/dashboards/0b3efdee8e2b479890f90b984ccb8144",
    conductivity: "https://www.arcgis.com/apps/dashboards/a55015ead4a846f392f2caf35263f284",
    phosphorus: "https://www.arcgis.com/apps/dashboards/1b017ab6210b42aa9b60304e34e69c44",
  },
};

const ArcGisDashboard = ({ farm }) => {
  const Views = {
    biomass: { label: getText("fields.biomass"), value: "biomass" },
    dissolved_oxygen: { label: getText("fields.dissolved_oxygen"), value: "dissolved_oxygen" },
    water_temp: { label: getText("fields.water_temp"), value: "water_temp" },
    salinity: { label: getText("fields.salinity"), value: "salinity" },
    turbidity: { label: getText("fields.turbidity"), value: "turbidity" },
    chlorophyll: { label: getText("fields.chlorophyll"), value: "chlorophyll" },
    suspended_solids: { label: getText("fields.suspended_solids"), value: "suspended_solids" },
    nitrogen: { label: getText("fields.nitrogen"), value: "nitrogen" },
    organic_matter: { label: getText("fields.organic_matter"), value: "organic_matter" },
    conductivity: { label: getText("fields.conductivity"), value: "conductivity" },
    phosphorus: { label: getText("fields.phosphorus"), value: "phosphorus" },
  };

  const getViewList = (farmid) => {
    if (!dashboardIndex[farmid]) {
      return [];
    } else {
      const viewlist = [
        Views.biomass,
        Views.dissolved_oxygen,
        Views.water_temp,
        Views.salinity,
        Views.turbidity,
        Views.chlorophyll,
        Views.suspended_solids,
        Views.nitrogen,
        Views.organic_matter,
      ].filter((ele) => dashboardIndex[farmid][ele.value]);
      return viewlist;
    }
  };

  const [view, setView] = useState(Views.biomass);

  const viewlist = getViewList(farm.farm_id);

  if (viewlist.length === 0) {
    return <VerticalAlertBoxCollections.NoData />;
  }

  return (
    <Box>
      <Box px={2}>
        <MuiTabs options={viewlist} value={view?.value} onNewValue={(val) => setView(Views?.[val])} theme={MuiTabsThemes.chip} />
      </Box>
      <Box
        p={0}
        sx={{
          position: "relative",
          iframe: {
            height: "calc(100vh - 240px)",
            width: "100%",
          },
        }}
      >
        <iframe src={dashboardIndex[farm.farm_id][view.value]} frameborder="0"></iframe>
      </Box>
    </Box>
  );
};

export default ArcGisDashboard;
