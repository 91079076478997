import { Helmet } from "react-helmet";

const platform_name = "Shrimpl: Aqua Business Intelligence";

export const CommonHelmet = ({ title }) => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>{`${title && title + " | "}${platform_name}`}</title>
  </Helmet>
);

export const GlobalHelmet = () => <CommonHelmet title="Shrimpl" />;
