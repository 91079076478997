import { Box, Stack } from "@mui/material";
import { PondManagerServices } from "api/pondManagerServices";
import { VerticalAlertBoxCollections } from "components/Alerts/AlertBox";
import LoadingBox from "components/Loading/LoadingBox";
import { getText } from "components/text/Text";
import { useEffect, useState } from "react";
import { AppSelectors } from "redux/AppReducers";
import { cycleManagementHelpers } from "redux/pondManagement/cycleManagement";
import PondWaterQualitySummary from "screens/Aquaculture/components/Dashboard/components/PondWaterQualitySummary";
import PondWaterQualityTimeseriesChart from "screens/Aquaculture/components/Dashboard/components/PondWaterQualityTimeseriesChart";
import { defaultAlertRules } from "screens/Aquaculture/components/Dashboard/utils/alertHelpers";
import WidgetCard from "ui/Card/WidgetCard";

const PondWaterQualityDashboard = ({ farm, pond }) => {
  const [data, setData] = useState();
  const [dashboardData, setDashboardData] = useState({});
  const [loading, setLoading] = useState(false);

  // =========== REDUX STARTdefaultAlertRules
  const pondManagementStore = AppSelectors.pondManagementStore();
  const { fields } = pondManagementStore;
  const wqFields = _.orderBy(
    fields.filter((ele) => ele.field_group === "Water Quality" && ele.field_type === "pond"),
    "position"
  );

  const cycleStore = AppSelectors.cycleStore();
  const selectedCycle = cycleManagementHelpers.getCurrentCycle({ pondId: pond?.id, cycle: cycleStore.selectedCycle, cycles: cycleStore.cycles });
  // =========== REDUX END

  useEffect(() => {
    if (!_.isEmpty(selectedCycle) && selectedCycle.pond_id === pond.id) {
      Actions.fetchData();
    }
  }, [farm, pond, selectedCycle]);

  const Actions = {
    fetchData: async () => {
      setLoading(true);
      const fieldIds = wqFields.map((ele) => ele.field_id);
      if (fieldIds.length > 0) {
        const alertRules = farm?.settings?.alerts || defaultAlertRules;
        const result = await PondManagerServices.getTimeseriesData(pond.id, fieldIds, selectedCycle.start_date, selectedCycle.end_date);
        let reformatted = {};
        for (let field of wqFields) {
          const { field_id } = field;
          reformatted[field_id] = {};
          reformatted[field_id]["field"] = field;
          reformatted[field_id]["timeseries"] = _.orderBy(
            result.raw.filter((ele) => ele.field_id === field_id),
            "datetime"
          );
          reformatted[field_id]["size"] = reformatted[field_id]["timeseries"].length;
          reformatted[field_id]["current"] = _.last(reformatted[field_id]["timeseries"]);
          reformatted[field_id]["prev"] = _.takeRight(reformatted[field_id]["timeseries"], 2)[0];
          if (reformatted[field_id]["current"] && reformatted[field_id]["prev"]) {
            reformatted[field_id]["change"] = {
              diff: reformatted[field_id]["current"].value - reformatted[field_id]["prev"].value,
              diff_perc: reformatted[field_id]["current"].value / (reformatted[field_id]["prev"].value + 1e-30) - 1,
            };
          } else {
            reformatted[field_id]["change"] = {
              diff: 0,
              diff_perc: 0,
            };
          }
          reformatted[field_id]["alertRules"] = alertRules?.[field_id];
        }

        setData(reformatted);
      }

      const wqDashboardData = await PondManagerServices.getPondWaterQualityDashboardData({ pondid: pond.id, cycleid: selectedCycle?.id });

      setDashboardData(wqDashboardData);

      setLoading(false);
    },
  };

  if (_.isEmpty(selectedCycle)) {
    return <VerticalAlertBoxCollections.NoCycleSelected pondId={pond?.id} />;
  }

  if (loading) {
    return <LoadingBox />;
  }

  return (
    <Box p={2}>
      {data && (
        <Stack spacing={2}>
          <WidgetCard title={getText("interface.general.summary")}>
            <PondWaterQualitySummary data={dashboardData} fields={wqFields} />
          </WidgetCard>
          <WidgetCard>
            <PondWaterQualityTimeseriesChart data={data} fields={wqFields} />
          </WidgetCard>
        </Stack>
      )}
    </Box>
  );
};

export default PondWaterQualityDashboard;
