import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import AdminServices from "api/adminServices";
import { ListIcon, RefreshIcon } from "components/Icons/MaterialIcons";
import CommonModal from "components/Modal/CommonModal";
import { useEffect, useState } from "react";
import { BiPlusCircle } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { alertsActions } from "redux/alerts";
import { modalIds, modalReducersActions } from "redux/modal";
import ProducerForm from "screens/Admin/FarmingProduct/ProducerForm";
import ProducerTable from "screens/Admin/FarmingProduct/ProducerTable";
import WidgetCard from "ui/Card/WidgetCard";

const ProducerHome = () => {
  const [producers, setProducers] = useState([]);
  const [producer, setProducer] = useState(null);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    Actions.fetchProducers();
  }, []);

  const Actions = {
    fetchProducers: async () => {
      const result = await AdminServices.fetchProducers();
      setProducers(result.rows);
    },
    onEditProducer: async ({ producer }) => {
      setProducer(producer);
      modalReducersActions.openModal(dispatch, { id: modalIds.ADMIN_PRODUCER_FORM });
    },
    onCreateProducer: async ({ producer }) => {
      setProducer(null);
      modalReducersActions.openModal(dispatch, { id: modalIds.ADMIN_PRODUCER_FORM });
    },
    onSubmitProducer: async (values) => {
      if (!producer) {
        await AdminServices.createProducer({ formData: values });
        modalReducersActions.closeModal(dispatch, { id: modalIds.ADMIN_PRODUCER_FORM });
        alertsActions.addInfo(dispatch, { content: "Producer is created!" });
        Actions.fetchProducers();
      } else {
        await AdminServices.updateProducer({ formData: values });
        modalReducersActions.closeModal(dispatch, { id: modalIds.ADMIN_PRODUCER_FORM });
        alertsActions.addInfo(dispatch, { content: "Producer is updated!" });
        Actions.fetchProducers();
      }
    },
    onViewProduct: (producerId) => {
      navigate(`/admin/farming-products/${producerId}`);
    },
    onDeleteProducer: async (producer) => {
      await AdminServices.deleteProducer({ producerId: producer.id });
      alertsActions.addInfo(dispatch, { content: "Producer is deleted!" });
      Actions.fetchProducers();
    },
  };

  return (
    <Box p={2}>
      <CommonModal id={modalIds.ADMIN_PRODUCER_FORM}>
        <ProducerForm producer={producer} onSubmit={Actions.onSubmitProducer} />
      </CommonModal>
      <WidgetCard title="Manufactures" icon={<ListIcon />}>
        <Box mb={2}>
          <Box mt={1}>
            <ButtonGroup size="small" variant="outlined">
              <Button onClick={Actions.onCreateProducer} startIcon={<BiPlusCircle />}>
                Create
              </Button>
              <Button onClick={Actions.fetchProducers} startIcon={<RefreshIcon />}>
                Reload
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
        <Box>
          <ProducerTable rows={producers} actions={Actions} />
        </Box>
      </WidgetCard>
    </Box>
  );
};

export default ProducerHome;
