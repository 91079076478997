import { Box, Stack, Typography, colors } from "@mui/material";
import { CircleIcon } from "components/Icons/MaterialIcons";
import Text, { getText } from "components/text/Text";
import NumericFormatters from "helpers/NumericFormatters";
import NumberFormat from "react-number-format";
import palette from "themes/palette";

/**
 *
 * Map Tooltip in Pond Manager Map
 *
 * @param {} param0
 * @returns
 */
const PondMapInfoBox = ({ pond }) => {
  return (
    <Box
      sx={{
        minWidth: 200,
        position: "absolute",
        transform: "translate(-50%, -130%)",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: "2px",
          height: 40,
          background: "#ffffff",
          left: "50%",
          top: "97%",
          zIndex: 998,
        }}
      ></Box>
      <Box
        sx={{
          position: "absolute",
          width: "8px",
          height: "8px",
          background: "#ffffff",
          left: "48.5%",
          top: "128%",
          borderRadius: 3,
          zIndex: 998,
        }}
      ></Box>
      <Stack gap={1} overflow={"hidden"} borderRadius={2} bgcolor={"#FFF"}>
        <Box px={1} py={0.2} bgcolor={palette.primary.light}>
          <Typography textAlign={"center"} fontSize={10} fontWeight={800}>
            {pond?.group}
          </Typography>
        </Box>
        <Stack direction="row" justifyContent={"space-between"} alignItems={"center"} px={1}>
          <Box>
            <Typography fontSize={20} fontWeight={"bold"}>
              {pond?.name}
            </Typography>
          </Box>
          <Box>
            <CircleIcon sx={{ fontSize: 10, color: pond.isActive ? colors.green[500] : colors.red[500] }} />
          </Box>
        </Stack>
        <Box p={1}>
          <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <Typography color="grey">
              <Text>interface.general.category</Text>
            </Typography>
            <Typography fontWeight={800}>{pond.category}</Typography>
          </Stack>
          <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <Typography color="grey">
              <Text>interface.general.pond-area</Text>
            </Typography>
            <Typography fontWeight={800}>{NumericFormatters.formatAreaInHa({ value: pond.area })}</Typography>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default PondMapInfoBox;
