import React from "react";
import "./css/App.css";

import "ag-grid-enterprise";

import { createTheme, ThemeProvider } from "@mui/material";
import { THEME } from "themes/theme";
import GlobalDialog from "components/Dialogs/GlobalDialog";
import GlobalAlerts from "components/Alerts/GlobalAlerts";
import AppRoutes from "routes/routes";
import { CommonHelmet } from "components/Helmets/helmets";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { persister, queryClient } from "configs/reactQueryConfigs";

export const theme = createTheme(THEME);

const App = () => {
  return (
    <PersistQueryClientProvider client={queryClient} persistOptions={{ persister: persister, maxAge: 1000 * 60 * 60 * 24 }}>
      <ThemeProvider theme={theme}>
        <CommonHelmet title="" />
        <GlobalDialog />
        <GlobalAlerts />
        <AppRoutes />
      </ThemeProvider>
    </PersistQueryClientProvider>
  );
};

export default App;
