import { Box, Grid } from "@mui/material";
import _ from "lodash";
import { useState } from "react";
import HighchartsWrapper from "components/Highcharts/HighchartsWrapper";
import { AgGridReact } from "ag-grid-react";
import NumericFormatters from "helpers/NumericFormatters";
import { AppSelectors } from "redux/AppReducers";
import { getText } from "components/text/Text";

/**
 *
 * productIdField:
 * - additive_product
 * - feed_product
 * - pond_treatment_product
 *
 * productUsageField
 * - feed_daily
 * - additive_product_usage
 * - pond_treatment_usage
 *
 * @param {*} param0
 * @returns
 */
const FarmingProductUsageChart = ({ data, cycle, hidePieChart }) => {
  // redux
  const pondManagementStore = AppSelectors.pondManagementStore();
  const { selectedFarm } = pondManagementStore;

  const [viewProductId, setViewProductId] = useState(null);
  const [unitFilter, setUnitFilter] = useState(null);
  const chartConfigs = {
    chart: {
      type: "pie",
      height: "300px",
      backgroundColor: "transparent",
    },
    title: {
      text: "",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        depth: 35,
        dataLabels: {
          enabled: true,
          format: "{point.name}",
        },
      },
    },
    series: [
      {
        type: "pie",
        data: data.map((ele) => ({
          name: ele?.product?.name,
          x: ele?.product?.name,
          y: ele?.stats?.total,
          color: ele?.color,
        })),
      },
    ],
  };

  const tableConfigs = {
    rowData: _.orderBy(
      data.map((item) => ({
        name: item?.product?.name,
        total: item?.stats?.total,
        totalCost: item?.stats?.totalCost,
        category: item?.product?.category,
        producer_name: item?.product?.producer_name,
        lastValue: _.last(item?.rows)?.usage,
        unit: item?.product?.net_weight_unit,
      })),
      "total",
      "desc"
    ),
    columnDefs: [
      {
        headerName: getText("interface.general.name"),
        field: "name",
      },
      {
        headerName: getText("interface.general.producer"),
        field: "producer_name",
      },
      {
        headerName: getText("interface.general.category"),
        field: "category",
      },
      {
        headerName: getText("interface.general.total"),
        field: "total",
        cellRenderer: NumericFormatters.format,
      },
      {
        headerName: getText("interface.general.total-cost"),
        field: "totalCost",
        cellRenderer: ({ value }) => NumericFormatters.formatFarmCurrency({ value: value, farm: selectedFarm }),
      },
      {
        headerName: getText("interface.general.unit"),
        field: "unit",
      },
    ],
    rowHeight: 20,
    headerHeight: 30,
    defaultColDef: {
      flex: 1,
    },
  };

  const Actions = {
    handleViewProductChang: (productId) => {
      if (viewProductId && viewProductId === productId) {
        setViewProductId(null);
      } else {
        setViewProductId(productId);
      }
    },
  };

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          {!hidePieChart && <HighchartsWrapper options={chartConfigs} />}
        </Grid>
        <Grid item xs={6}>
          <Box className="ag-theme-clean" height={400}>
            <AgGridReact {...tableConfigs} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FarmingProductUsageChart;
