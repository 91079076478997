import { useSelector } from "react-redux";
import { combineReducers } from "redux";

import userReducers from "redux/user";
import dialogReducers from "redux/dialog";
import alertsReducers from "redux/alerts";
import pondManagementReducers from "redux/pondManagement/pondManagement";
import cycleManagementReducers from "redux/pondManagement/cycleManagement";
import modalReducers from "redux/modal";
import smartSearchReducers from "redux/smartSearch";
import pathologyManagementReducers from "redux/pondManagement/pathologyManagement";
import googleMapManagementReducers from "redux/pondManagement/googleMapManagement";
import organisationManagementReducers from "redux/pondManagement/organisationManagement";
import chatReducer from "redux/chatSlice";

const AppReducers = combineReducers({
  user: userReducers,
  dialog: dialogReducers,
  pond: pondManagementReducers,
  cycles: cycleManagementReducers,
  alerts: alertsReducers,
  modal: modalReducers,
  smartSearch: smartSearchReducers,
  pathology: pathologyManagementReducers,
  map: googleMapManagementReducers,
  orgs: organisationManagementReducers,
  chat: chatReducer,
});

/**
 * App Selectors :: gateway for redux store
 *
 * userStore: return User Store
 *
 * pondManagementStore: return Pond Management Store
 *
 * cycleStore: return Cycle Store
 *
 */
export const AppSelectors = {
  userStore: () => useSelector((state) => state.user),
  cycleStore: () => useSelector((state) => state.cycles),
  pondManagementStore: () => useSelector((state) => state.pond),
  modalStore: () => useSelector((state) => state.modal),
  smartSearchStore: () => useSelector((state) => state.smartSearch),
  pathologyStore: () => useSelector((state) => state.pathology),
  mapStore: () => useSelector((state) => state.map),
  orgStore: () => useSelector((state) => state.orgs),
  chatStore: () => useSelector((state) => state.chat),
  dialogStore: () => useSelector((state) => state.dialog),
};

export default AppReducers;
