import { Box, Card, Grid, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography, colors } from "@mui/material";
import HighchartsWrapper from "components/Highcharts/HighchartsWrapper";
import ChartjsContainer from "components/chart/ChartjsV4Wrapper";
import React from "react";

/**
 *
 * @param {*} param0
 *
 * props = { data, title }
 *
 * data = { pie, table }
 *
 * pie = [{label, value}]
 *
 * @returns
 */
const PieAndTableCard = ({ data, title }) => {
  const { pie, table } = data;
  return (
    <Box sx={{ borderRadius: 1, p: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={7}>
          <Typography fontSize={13} fontWeight={800}>
            {title}
          </Typography>
          <Stack justifyContent={"center"} direction={"row"}>
            {pie && (
              <Box maxWidth={500} position={"relative"}>
                <HighchartsWrapper
                  options={{
                    chart: {
                      type: "pie",
                      options3d: {
                        enabled: true,
                        alpha: 45,
                        beta: 0,
                      },
                      backgroundColor: "transparent",
                    },
                    title: {
                      text: "",
                    },

                    accessibility: {
                      point: {
                        valueSuffix: "%",
                      },
                    },
                    tooltip: {
                      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
                    },
                    plotOptions: {
                      pie: {
                        allowPointSelect: true,
                        cursor: "pointer",
                        depth: 35,
                        dataLabels: {
                          enabled: true,
                          format: "{point.name}",
                        },
                      },
                    },
                    series: [
                      {
                        type: "pie",
                        name: "",
                        data: pie.map((ele) => [ele.label, ele.value]),
                      },
                    ],
                  }}
                />
              </Box>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} md={5}>
          {table && (
            <Box
              sx={{
                background: "#FFF",
                borderRadius: 1,
                height: "100%",
                overflow: "auto",
              }}
            >
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {table?.columns.map((ele, idx) => (
                      <TableCell key={idx} sx={{ fontSize: 11, fontWeight: 800 }}>
                        {ele.display}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {table?.rows.map((ele, idx) => (
                    <TableRow key={idx}>
                      {table?.columns.map((c, idx_) => (
                        <TableCell key={idx_} sx={{ fontSize: 11 }}>
                          {ele?.[c.key]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default PieAndTableCard;
