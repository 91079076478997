import { DashboardIcon, SatelliteAltIcon } from "components/Icons/MaterialIcons";
import PondManagerAccessWrapper from "components/Wrapper/PondManagerAccessWrapper";
import { ResourcePolicies } from "screens/Aquaculture/components/AccessControl/ResourcePolicies";
import SideMenuLayout from "screens/Aquaculture/components/Layout/SideMenuLayout";
import ArcGisDashboard from "screens/Aquaculture/components/SatEye/ArcGisDashboard";
import SatEye from "screens/Aquaculture/components/SatEye/SatEye";

const SatEyeHome = ({ pond, farm }) => {
  const MENULIST = [
    {
      label: "Home",
      description: "",
      value: 0,
      icon: <SatelliteAltIcon />,
      component: (
        <PondManagerAccessWrapper policies={[ResourcePolicies.SATEYE]}>
          <SatEye farm={farm} pond={pond} />
        </PondManagerAccessWrapper>
      ),
    },
    {
      label: "Dashboard",
      description: "",
      value: 1,
      icon: <DashboardIcon />,
      component: (
        <PondManagerAccessWrapper policies={[ResourcePolicies.SATEYE]}>
          <ArcGisDashboard farm={farm} />
        </PondManagerAccessWrapper>
      ),
    },
  ];

  return <SideMenuLayout menulist={MENULIST} title={"SATEYE"} defaultViewMode={0} />;
};

export default SatEyeHome;
