import { Box, Button, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { PondManagerServices } from "api/pondManagerServices";
import MuiDatetimePicker from "components/DatePicker/MuiDatetimePicker";
import HighchartsWrapper from "components/Highcharts/HighchartsWrapper";
import { DeleteIcon, FileUploadIcon } from "components/Icons/MaterialIcons";
import Text, { getText } from "components/text/Text";
import { Form, Formik } from "formik";
import Formatters from "helpers/Formatters";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { alertsActions } from "redux/alerts";
import ProductCard from "screens/Aquaculture/components/FarmingProduct/components/ProductCard";
import palette from "themes/palette";

const generateChartConfigs = (data) => {
  return {
    chart: {
      type: "line",
      height: "300px",
    },
    title: {
      text: "",
    },
    xAxis: {
      type: "datetime",
      title: {
        text: "Time",
      },
    },
    yAxis: {
      title: {
        text: "Unit Price",
      },
    },
    series: [
      {
        type: "line",
        name: "Unit Price",
        data: _.orderBy(data, "datetime").map((ele) => [Formatters.toLocalDatetimeValue(ele.datetime), ele.unit_price]),
        color: palette.secondary.main,
      },
    ],
  };
};

const FarmingProductPriceView = ({ product }) => {
  const [colDef, setColDef] = useState([
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      field: "datetime",
      headerName: getText("interface.general.timestamp"),
      flex: 1,
      cellRenderer: ({ value }) => Formatters.toLocalDateTimeString(value),
    },
    {
      field: "unit_price",
      headerName: getText("interface.general.unit-price"),
      cellRenderer: ({ value }) => Formatters.formatDollar({ value, decimalScale: 2 }),
      flex: 1,
    },
  ]);

  const [price, setPrice] = useState([]);

  const [rowToDelete, setRowToDelete] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    Actions.fetchPrice();
  }, [product]);

  const Actions = {
    fetchPrice: async () => {
      const farmid = product.farm_id;
      const productid = product.id;
      const result = await PondManagerServices.fetchFarmingProductPrice({ farmid, productid });
      setPrice(_.orderBy(result.rows, "datetime", "desc"));
    },
    recordPrice: async (formData) => {
      const farmid = product.farm_id;
      const productid = product.id;
      const datetime = moment.utc(formData.datetime).format("YYYY-MM-DD HH:mm:ss");
      const unitprice = formData.unit_price;
      await PondManagerServices.recordFarmingProductPrice({ farmid, productid, datetime, unitprice });
      Actions.fetchPrice();
      alertsActions.addInfo(dispatch, { content: getText("interface.alert.alert-success") });
    },
    removePrice: async () => {
      if (rowToDelete) {
        const farmid = rowToDelete.farm_id;
        const productid = rowToDelete.product_id;
        const datetime = rowToDelete.datetime;
        await PondManagerServices.removeFarmingProductPrice({ farmid, productid, datetime });
        Actions.fetchPrice();
        alertsActions.addInfo(dispatch, { content: getText("interface.alert.alert-success") });
        setRowToDelete(null);
      }
    },
  };

  return (
    <Box pt={6}>
      <Box>
        <ProductCard product={product} />
      </Box>
      <Box>
        <Formik
          initialValues={{
            datetime: moment(),
            unit_price: null,
          }}
          onSubmit={Actions.recordPrice}
        >
          {({ values, handleChange, setFieldValue }) => {
            return (
              <Form>
                <Stack p={2} direction={"row"} spacing={1} justifyContent={"center"}>
                  <MuiDatetimePicker
                    name="datetime"
                    onAccept={(newValue) => {
                      setFieldValue("datetime", newValue);
                    }}
                    onChange={(newValue) => {
                      setFieldValue("datetime", newValue);
                    }}
                    label="Datetime"
                    value={values.datetime}
                    required
                  />
                  <TextField
                    name="unit_price"
                    onChange={handleChange}
                    value={values.unit_price}
                    type="number"
                    label="unit price"
                    inputProps={{ step: 0.01 }}
                    InputProps={{
                      endAdornment: <InputAdornment position="start">/{(product?.net_weight_unit || "").toUpperCase()}</InputAdornment>,
                    }}
                    required
                  ></TextField>
                  <Button startIcon={<FileUploadIcon />} type="submit">
                    <Text>interface.actions.submit</Text>
                  </Button>
                </Stack>
              </Form>
            );
          }}
        </Formik>
      </Box>
      <Box>
        <HighchartsWrapper options={generateChartConfigs(price)} />
      </Box>
      {rowToDelete && (
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Typography fontSize={10}>Actions: </Typography>
          <Button startIcon={<DeleteIcon />} size="small" variant="contained" color="error" onClick={Actions.removePrice}>
            Remove
          </Button>
        </Stack>
      )}
      <Box className="ag-theme-clean" height={600}>
        <AgGridReact
          columnDefs={colDef}
          rowData={price}
          rowHeight={30}
          onRowSelected={(e) => {
            setRowToDelete(_.first(e.api.getSelectedRows()));
          }}
        />
      </Box>
    </Box>
  );
};

export default FarmingProductPriceView;
