import { Stack } from "@mui/material";
import PrivateChat from "./PrivateChat";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import MessageIcon from "@mui/icons-material/Message";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { Fade } from "react-reveal";

const FloatingMessageBoard = ({ chatId }) => {
  const [isOpenMessage, setIsOpenMessage] = useState(false);

  const handleSpeedDialClick = () => {
    setIsOpenMessage((prev) => !prev);
  };

  return (
    <>
      {isOpenMessage && (
        <Box
          sx={{
            position: "absolute",
            bottom: "20px",
            right: "100px",
            // width: "450px",
            // position: "absolute",
            // right: "100px",
            zIndex: 9999,
            animation: "textFloat 0.2s",
          }}
        >
          <PrivateChat chatId={chatId} isFloatingMessage />
        </Box>
      )}
      <Box sx={{ position: "absolute", bottom: "20px", right: "20px" }}>
        <SpeedDial ariaLabel="SpeedDial openIcon example" icon={!isOpenMessage ? <MessageIcon /> : <CloseIcon />} onClick={handleSpeedDialClick} open={isOpenMessage} sx={{}}></SpeedDial>
      </Box>
    </>
  );
};

export default FloatingMessageBoard;
