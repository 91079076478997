import { Box, Button, Grid, IconButton, Stack, TextField, Typography } from "@mui/material";
import { CloseIcon } from "components/Icons/MaterialIcons";
import MapShapeSelector from "components/map/MapShapeSelector";
import { Form, Formik } from "formik";
import { clipToGrid, getBoundingBox } from "helpers/GISHelpers";
import _ from "lodash";
import React, { useState } from "react";
import MuiTabs, { MuiTabsThemes } from "ui/tabs/MuiTabs";
import { v4 as uuidv4 } from "uuid";

const createDefaultFeature = () => {
  const newId = uuidv4();
  const label = newId.split("-")[0];
  return {
    id: newId,
    label: label,
    group: "USER-INPUT",
    category: "",
    sub_category: "",
    lat: "",
    lng: "",
    area: "",
  };
};

const createDefaultAoi = () => {
  const newId = uuidv4();
  const label = newId.split("-")[0];
  return {
    id: newId,
    label: label,
    group: "",
    lat: "",
    lng: "",
    area: "",
    size: 5,
  };
};

const FeatureAdd = ({ overlayGeojson, mapViewpoint, onExit, onSubmit, onSubmitAoi }) => {
  // 1. "feature": manual adding pond polygon
  // 2. "aoi": add AOI for processing
  const [mode, setMode] = useState("feature");

  const [feature, setFeature] = useState(createDefaultFeature());
  const [aoi, setAoi] = useState(createDefaultAoi());

  const [mapShapeSelectorCoverLayers, setMapShapeSelectorCoverLayers] = useState(null);

  const Actions = {
    onSubmit: (values) => {
      if (mode === "feature") {
        const feature = {
          type: "Feature",
          geometry: values["geometry"],
          properties: {
            id: values.id,
            label: values.label,
            group: values.group,
            category: values.category,
            sub_category: values.sub_category,
            lat: values.lat,
            lng: values.lng,
            area: values.area,
          },
        };
        onSubmit && onSubmit(feature);
      } else if (mode === "aoi") {
        const thePolygon = values.geometry;
        const size = values.size;
        const theFeature = {
          type: "Feature",
          properties: {},
          geometry: thePolygon,
        };
        let gridGeojson = clipToGrid({ feature: theFeature, sizeInKm: size });
        values["grid_geojson"] = gridGeojson;
        values["geojson"] = {
          type: "Feature",
          geometry: values["geometry"],
          properties: {},
        };
        values["bbox_geojson"] = getBoundingBox({ feature: theFeature });
        onSubmitAoi && onSubmitAoi(values);
      }
    },
    aoi: {
      onPreviewGrid: (values) => {
        const thePolygon = values.geometry;
        const size = values.size;
        const theFeature = {
          type: "Feature",
          properties: {},
          geometry: thePolygon,
        };
        let gridGeojson = clipToGrid({ feature: theFeature, sizeInKm: size });

        setMapShapeSelectorCoverLayers([{ geojson: gridGeojson }]);
        // todo: pass to mapshapeselector
      },
    },
  };

  return (
    <Box
      sx={{
        borderRadius: 4,
        p: 2,
        bgcolor: "#FFF",
        height: "100%",
        boxSizing: "border-box",
        overflow: "auto",
      }}
    >
      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          <Typography fontSize={20} fontWeight={800}>
            Create
          </Typography>
          <Box>
            <MuiTabs
              theme={MuiTabsThemes.chip}
              options={[
                { label: "pond", value: "feature" },
                { label: "aoi", value: "aoi" },
              ]}
              onNewValue={setMode}
              value={mode}
            />
          </Box>
        </Stack>
        <Box>
          <IconButton onClick={onExit}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Stack>

      {/**
       *
       *
       *
       * FORM :: feature
       *
       *
       *
       * */}
      {mode === "feature" && (
        <Formik initialValues={feature} enableReinitialize={true} onSubmit={Actions.onSubmit}>
          {({ values, handleChange, setFieldValue }) => {
            return (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box borderRadius={3} overflow={"hidden"} my={2}>
                      <MapShapeSelector
                        center={[mapViewpoint?.center?.[1], mapViewpoint?.center?.[0]]}
                        initPolygon={null}
                        handlePolygonAdded={(props) => {
                          setFieldValue("area", props.area);
                          setFieldValue("lat", props.centroid.lat);
                          setFieldValue("lng", props.centroid.lng);
                          setFieldValue("geometry", props.features[0]["geometry"]);
                        }}
                        coverLayer={overlayGeojson}
                        height={"45vh"}
                        defaultZoom={mapViewpoint?.zoom || 15}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Typography variant="inputLabel">ID</Typography>
                    <TextField size="small" name={"id"} disabled={true} onChange={handleChange} value={values.id} fullWidth></TextField>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Typography variant="inputLabel">Label *</Typography>
                    <TextField size="small" name={"label"} onChange={handleChange} value={values.label} fullWidth required></TextField>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Typography variant="inputLabel">Group</Typography>
                    <TextField size="small" name={"group"} onChange={handleChange} value={values.group} fullWidth></TextField>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Typography variant="inputLabel">Area</Typography>
                    <TextField size="small" name={"area"} onChange={handleChange} value={values.area} fullWidth required></TextField>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Typography variant="inputLabel">Lat</Typography>
                    <TextField size="small" name={"lat"} onChange={handleChange} value={values.lat} fullWidth required></TextField>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Typography variant="inputLabel">Lng</Typography>
                    <TextField size="small" name={"lng"} onChange={handleChange} value={values.lng} fullWidth required></TextField>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Typography variant="inputLabel">Category</Typography>
                    <TextField size="small" name={"category"} onChange={handleChange} value={values.category} fullWidth></TextField>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Typography variant="inputLabel">Sub Category</Typography>
                    <TextField size="small" name={"sub_category"} onChange={handleChange} value={values.sub_category} fullWidth></TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <Button type="submit" variant="contained" size="large">
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      )}

      {/**
       *
       *
       *
       * FORM :: AOI
       *
       *
       *
       * */}
      {mode === "aoi" && (
        <Formik initialValues={aoi} enableReinitialize={true} onSubmit={Actions.onSubmit}>
          {({ values, handleChange, setFieldValue }) => {
            return (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box borderRadius={3} overflow={"hidden"} my={2}>
                      <MapShapeSelector
                        center={[mapViewpoint?.center?.[1], mapViewpoint?.center?.[0]]}
                        initPolygon={null}
                        handlePolygonAdded={(props) => {
                          setFieldValue("area", props.area);
                          setFieldValue("lat", props.centroid.lat);
                          setFieldValue("lng", props.centroid.lng);
                          setFieldValue("geometry", props.features[0]["geometry"]);
                        }}
                        coverLayer={overlayGeojson}
                        coverLayers={mapShapeSelectorCoverLayers}
                        height={"45vh"}
                        defaultZoom={13}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Typography variant="inputLabel">ID</Typography>
                    <TextField size="small" name={"id"} disabled={true} onChange={handleChange} value={values.id} fullWidth></TextField>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Typography variant="inputLabel">Label *</Typography>
                    <TextField size="small" name={"label"} onChange={handleChange} value={values.label} fullWidth required></TextField>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Typography variant="inputLabel">Group</Typography>
                    <TextField size="small" name={"group"} onChange={handleChange} value={values.group} fullWidth></TextField>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Typography variant="inputLabel">Area</Typography>
                    <TextField size="small" name={"area"} onChange={handleChange} value={values.area} fullWidth required></TextField>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Typography variant="inputLabel">Lat</Typography>
                    <TextField size="small" name={"lat"} onChange={handleChange} value={values.lat} fullWidth required></TextField>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Typography variant="inputLabel">Lng</Typography>
                    <TextField size="small" name={"lng"} onChange={handleChange} value={values.lng} fullWidth required></TextField>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Typography variant="inputLabel">Grid Size (Km)</Typography>
                    <TextField required size="small" type="number" name={"size"} onChange={handleChange} value={values.size} fullWidth></TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction={"row"} spacing={1}>
                      <Button variant="outlined" size="large" onClick={() => Actions.aoi.onPreviewGrid(values)}>
                        Preview Grid
                      </Button>
                      <Button type="submit" variant="contained" size="large">
                        Submit
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      )}
    </Box>
  );
};

export default FeatureAdd;
