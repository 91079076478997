import React, { Component, Fragment } from "react";
import ReactHighstock from "react-highcharts/ReactHighstock";
import styled from "styled-components";

import { CSVLink } from "react-csv";
import { highchartBasicConfig, COLORMAP } from "../../config";
import ReactHighcharts from "react-highcharts";
import HighchartsWrapper, {
  HighstockWrapper,
} from "components/Highcharts/HighchartsWrapper";

// -- Overwrite Highchart for highchart-more

const DEFAULTCOLOR = COLORMAP.main;

// -- Styled
const Layout = styled.div`
  background: none;

  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 30px calc(100% - 30px);

  .chart_container {
    display: grid;
    padding: 0 10px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .grid_head {
    font-family: var(--main-font);
    text-align: left;
    font-size: 14px;
    padding-bottom: 8px;
  }

  .chart_button_wrapper {
    display: flex;
    align-items: center;
  }
`;

const ChartButton = styled.button`
  display: ${(props) => (props.isHidden === true ? "none" : "flex")};
  font-family: "Fira Sans Extra Condensed";
  text-transform: uppercase;
  margin-top: 5px;
  margin-right: 5px;
  color: #666;
  font-size: 15px;
  border: none;
  background: none;

  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 3px;

  &:hover {
    background: rgb(220, 220, 220);
  }

  &:active {
    background: rgb(230, 230, 230);
  }

  i {
  }

  .csvlink {
    color: #666;
  }

  .csvlink:hover {
    color: black;
  }
`;

const TableWrapper = styled.div`
  margin: 10px 0;
  font-family: "Roboto";
  color: #444;
  font-size: 11px;
`;

export default class MultiViewChart extends Component {
  state = {
    chartid: "",
    hasValidData: false,
    chartType: "line",
    candleSeries: [],
    lineSeries: [],
    barSeries: [],
    seasonalSeries: [],
    heatmapConfig: {},
    tableView: {},
  };

  componentDidMount() {
    // console.log("MULTIFUNCTIONALCHART RENDERED!");
    this.updateChart(this.props);
  }

  updateChart = (props) => {
    if (props.data === undefined) {
      return null;
    } else {
      const data = props.data;
      const candleSeries = data.candle || [];
      const lineSeries = data.line || [];
      const barSeries = data.bar || [];
      const seasonalSeries = data.seasonal || [];
      const heatmapConfig = data.heatmapConfig || {};
      const tableView = data.table || {};
      // Update Data
      this.setState({
        candleSeries,
        lineSeries,
        barSeries,
        seasonalSeries,
        tableView,
        heatmapConfig,
      });
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.hasValidData && nextProps.data !== undefined) {
      return { hasValidData: true };
    } else if (prevState.chartid !== nextProps.chartid) {
      return { chartid: nextProps.chartid, newProps: nextProps };
    } else {
      return null;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.hasValidData && this.state.hasValidData) {
      this.updateChart(this.props);
    } else if (prevState.chartid !== this.state.chartid) {
      this.updateChart(this.state.newProps);
    }
  }

  // Chart Management
  onChangeChartType = (chartType) => {
    this.setState({
      ...this.state,
      chartType: chartType,
    });
  };

  renderChart = (states, props) => {
    // Highchart Configuration for linechart view and barchart view
    const chartType = states.chartType;
    const tableView = states.tableView;
    const lineSeries = states.lineSeries;
    const barSeries = states.barSeries;
    const seasonalSeries = states.seasonalSeries;
    const heatmapConfig = states.heatmapConfig;
    const candleSeries = states.candleSeries;

    let defaultZoom = props.defaultZoom;
    let height = props.height;

    const commonConfig = {
      chart: {
        backgroundColor: "transparent",
        animation: false,
      },
      rangeSelector: {
        buttons: [
          { type: "month", count: 1, text: "1M" },
          { type: "month", count: 3, text: "3M" },
          { type: "ytd", text: "YTD" },
          { type: "year", count: 3, text: "3Y" },
          { type: "all", text: "ALL" },
        ],
        buttonTheme: {
          padding: 0,
          height: 18,
          width: 20,
          fill: "#00000000",
          style: {
            color: "#aaa",
          },
          states: {
            hover: {
              fill: "#00000000",
              style: { color: "#888" },
            },
            select: {
              fill: "#00000000",
              style: { color: "#000", fontWeight: 800 },
            },
          },
        },
        labelStyle: { display: "none" },
        selected: defaultZoom || 4,
        inputEnabled: false,
      },
      yAxis: [
        {
          labels: { x: 10 },
          gridLineWidth: 1,
        },
        {
          labels: { enabled: false },
          gridLineWidth: 0,
          opposite: true,
        },
      ],
      credits: highchartBasicConfig.credits,
      scrollbar: highchartBasicConfig.scrollbar,
      navigator: highchartBasicConfig.navigator,
      tooltip: {
        animation: true,
        useHTML: true,
        shadow: false,
        borderWidth: 2,
        borderRadius: 4,
        style: {
          fontSize: "12px",
        },
      },
    };

    // --+-- 1. Line View Chart Configuration --+--
    const lineViewconfig = {
      ...commonConfig,
      chart: {
        ...commonConfig.chart,
        type: "area",
      },
      legend: {
        enabled: lineSeries ? (lineSeries.length > 1 ? 1 : 0) : 1,
        itemStyle: { fontSize: 10 },
      },
      plotOptions: {
        series: {
          color: DEFAULTCOLOR,
          lineWidth: 1,
          connectNulls: true,
          fillOpacity: 0.1,
          softThreshold: true,
          dataGrouping: {
            approximation: "close",
          },
          animation: false,
        },
      },
      series: lineSeries || [],
    };

    // --+-- 1.1 Bar View Chart Configuration (e.g. daily changes) --+--
    const barViewconfig = {
      ...commonConfig,
      chart: {
        ...commonConfig.chart,
        type: "column",
      },
      legend: {
        enabled: barSeries ? (barSeries.length > 1 ? 1 : 0) : 1,
        itemStyle: { fontSize: 10 },
      },
      plotOptions: {
        series: {
          color: DEFAULTCOLOR, // Default Line Color
          lineWidth: 2,
          connectNulls: true,
          fillOpacity: 0.1,
          softThreshold: true,
          dataGrouping: {
            approximation: "averages",
          },
          animation: false,
        },
      },
      series: barSeries || [],
    };

    // --+-- 2. Seasonal View Chart Configuration --+--
    const seasonalViewConfig = {
      ...commonConfig,
      chart: {
        ...commonConfig.chart,
        type: "line",
      },
      rangeSelector: {
        enabled: false,
      },
      navigator: {
        ...lineViewconfig.navigator,
        baseSeries: 1,
        xAxis: {
          dateTimeLabelFormats: {
            second: "%Y-%m-%d<br/>%H:%M:%S",
            minute: "%Y-%m-%d<br/>%H:%M",
            hour: "%Y-%m-%d<br/>%H:%M",
            day: "%b %d",
            week: "%b %d",
            month: "%b 01",
            year: "%b",
          },
        },
      },
      plotOptions: {
        series: {
          ...lineViewconfig.plotOptions.series,
          connectNulls: true,
          lineWidth: 1,
        },
      },
      xAxis: {
        dateTimeLabelFormats: {
          second: "%Y-%m-%d<br/>%H:%M:%S",
          minute: "%Y-%m-%d<br/>%H:%M",
          hour: "%Y-%m-%d<br/>%H:%M",
          day: "%b %d",
          week: "%b %d",
          month: "%b",
          year: "%b",
        },
      },
      legend: {
        ...lineViewconfig.legend,
        enabled: !0,
        backgroundColor: "rgba(255,255,255, 0.3)",
        verticalAlign: "top",
        itemDistance: 4,
        symbolHeight: 1,
        symbolPadding: 0,
        padding: 1,
        margin: 4,
        itemMarginBottom: 1,
        maxHeight: 35,
      },
      series: seasonalSeries || [],
    };

    const candleViewConfig = {
      ...commonConfig,
      rangeSelector: {
        ...commonConfig.rangeSelector,
        selected: 1,
      },
      navigator: {
        ...commonConfig.navigator,
        series: [],
      },
      yAxis: [
        {
          labels: { x: 10 },
          gridLineWidth: 1,
        },
      ],
      plotOptions: {
        series: {
          lineWidth: 1,
          animation: false,
        },
      },
      series: candleSeries || [],
    };

    const monthlyHeatmapConfig = heatmapConfig;

    switch (chartType) {
      case "candle":
        return (
          <HighstockWrapper options={candleViewConfig} ref="chart-candle" />
        );
      case "seasonal":
        return (
          <HighstockWrapper options={seasonalViewConfig} ref="chart-season" />
        );
      case "line":
        return <HighstockWrapper options={lineViewconfig} ref="chart" />;
      case "column":
        return <HighstockWrapper options={barViewconfig} ref="chart" />;
      case "monthlyHeatmap":
        return <HighstockWrapper options={monthlyHeatmapConfig} ref="chart" />;
      default:
        return <div></div>;
    }
  };

  // :: Render Buttons
  renderButtons(state, props) {
    // ===== state =====
    let tableView = this.state.tableView;
    const lineSeries = this.state.lineSeries;
    const barSeries = this.state.barSeries;
    const seasonalSeries = this.state.seasonalSeries;
    const candleSeries = this.state.candleSeries;

    const heatmapConfig = this.state.heatmapConfig;

    const chartType = this.state.chartType;

    // ===== valid data =====
    const showCandle = candleSeries.length > 0;
    const showLine = lineSeries.length > 0;
    const showBar = barSeries.length > 0;
    const showSeasonal = seasonalSeries.length;
    const showMonthlyHeatmap = Object.keys(heatmapConfig).length > 0;
    const showTable = tableView.data != undefined && tableView.data.length > 0;

    // helper
    const genButton = (props, idx) => {
      return (
        <ChartButton
          key={idx}
          isHidden={props.isHidden}
          title={props.title}
          style={{
            color: chartType === props.id ? "white" : undefined,
            background:
              chartType === props.id ? "var(--main-color)" : undefined,
          }}
          onClick={() => this.onChangeChartType(props.id)}
        >
          <div>
            <i className={"fas fa-" + props.icon}></i>
          </div>
        </ChartButton>
      );
    };

    return (
      <div className="chart_button_wrapper">
        {[
          {
            isHidden: !showCandle,
            title: "Candlesticks Chart",
            id: "candle",
            label: "Candlestick",
            icon: "chart-bar",
          },
          {
            isHidden: !showLine,
            title: "Line Chart",
            id: "line",
            label: "Line",
            icon: "chart-line",
          },
          {
            isHidden: !showBar,
            title: "Bar Chart",
            id: "column",
            label: "Bar",
            icon: "chart-bar",
          },
          {
            isHidden: !showMonthlyHeatmap,
            title: "Heatmap Chart",
            id: "monthlyHeatmap",
            label: "Monthly",
            icon: "th-large",
          },
          {
            isHidden: !showSeasonal,
            title: "Annual Chart",
            id: "seasonal",
            label: "Seasonal",
            icon: "chart-area",
          },
          {
            isHidden: !showTable,
            title: "Table Chart",
            id: "table",
            label: "Table",
            icon: "table",
          },
        ].map((ele, idx) => genButton(ele, idx))}
        <ChartButton isHidden={!showTable} title="Download as CSV">
          <CSVLink
            className="csvlink"
            data={tableView.data || []}
            filename={(tableView.name || "data") + ".csv"}
          >
            <i className="fas fa-download"></i>
          </CSVLink>
        </ChartButton>
      </div>
    );
  }

  render() {
    return (
      <Layout>
        {/* Buttons Area */}
        {this.renderButtons(this.state, this.props)}
        {/* Chart Area */}
        {this.renderChart(this.state, this.props)}
      </Layout>
    );
  }
}
