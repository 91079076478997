import { getToken } from "auth/UserAuth";
import Axios from "axios";
import { URL } from "config";
import AppLocalStorage from "helpers/LocalStorage";
const appStorage = new AppLocalStorage();

export const plainGet = (endpoint) => Axios.get(`${URL}${endpoint}`);
export const plainPost = (endpoint, payload) => Axios.post(`${URL}${endpoint}`, payload);
export const plainPut = (endpoint, payload) => Axios.put(`${URL}${endpoint}`, payload);

export const AuthAxios = (config) => {
  const token = getToken();
  return Axios.create({
    headers: {
      Authorization: "Bearer " + token,
      "Shrimpl-Org-Id": appStorage.getOrgId()
    },
    ...config
  });
};

export const AuthFormAxios = (config) => {
  const token = getToken();
  return Axios.create({
    headers: {
      Authorization: "Bearer " + token,
      'Content-Type': 'multipart/form-data',
      "Shrimpl-Org-Id": appStorage.getOrgId()
    },
    ...config
  });
};

export const AxiosGet = (url, headers) => AuthAxios(headers).get(url);
export const AxiosPost = (url, data) => AuthAxios().post(url, data);
export const AxiosPostForm = (url, data) => AuthFormAxios().post(url, data);
export const AxiosPut = (url, data) => AuthAxios().put(url, data);
export const AxiosPutForm = (url, data) => AuthFormAxios().put(url, data);
export const AxiosDelete = (url) => AuthAxios().delete(url);

/**
 *
 * @param {string} endpoint // must start with "/"
 * @returns
 */
export const ApiGet = (endpoint, config) => AxiosGet(`${URL}${endpoint}`, config);
export const ApiPost = (endpoint, data) => AxiosPost(`${URL}${endpoint}`, data);
export const ApiPostForm = (endpoint, data) => AxiosPostForm(`${URL}${endpoint}`, data);
export const ApiPut = (endpoint, data) => AxiosPut(`${URL}${endpoint}`, data);
export const ApiPutForm = (endpoint, data) => AxiosPutForm(`${URL}${endpoint}`, data);
export const ApiDelete = (endpoint) => AxiosDelete(`${URL}${endpoint}`);
