/**
 * TO BE DEPRECATED. Old table component we shall replace with AG GRid
 *
 * Imbue Table
 *
 * Props
 * -----
 *
 * config
 *
 * Config
 * ------
 *
 * > data
 *      list, list of dictionary represents a table
 *
 * > sorted
 *      list, which columns to put ahaed orderly
 *
 * > onSorted
 *      boolean, only display columns in sorted
 *
 * > excluded
 *      list, which columns to be hidden
 *
 * > keynamemap
 *      dictionary, key and display name mapping
 *      e.g {"col 1": "Name", "col 2": "Job"}
 *
 * > flexDict
 *      dictionary, specify flex for given columns
 *      e.g {"col 1": 3, "col 2": 0.5}
 *
 * > height, int:
 *      max height
 *
 */
import React, { Component } from "react";
import { toUnique, hasItem, notIn } from "../../helpers/Utils";

export default class RcTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: null,
      sortDir: null, // -1 ,null, 1
      ogData: [],
    };
    this.orderColumn = this.orderColumn.bind(this);
  }

  componentDidMount() {
    this.setState({
      ogData: this.props.config.data,
    });
  }

  orderColumn(columnName) {
    const { sortBy, sortDir } = this.state;
    if (columnName === sortBy) {
      if (!sortDir) {
        this.setState({ sortDir: 1 });
      } else if (sortDir === 1) {
        this.setState({ sortDir: -1 });
      } else {
        this.setState({ sortDir: null });
      }
    } else {
      this.setState({
        sortBy: columnName,
        sortDir: 1,
      });
    }
  }

  render() {
    // ===== props =====
    let { config } = this.props;

    let data = config.data; // list of dictionary
    let sorted = config.sorted;
    let flexDict = config.flexDict || {};
    let excluded = config.excluded;
    let keynamemap = config.keynamemap || {};
    let onSorted = config.onSorted;
    let height = config.height;

    const sortBy = this.state.sortBy;
    const sortDir = this.state.sortDir;
    let ogData = this.state.ogData;

    // -- Determine if there too many rows
    const THRESHOLD = 300;
    const tooManyRows = ogData.length > THRESHOLD;

    if (tooManyRows) {
      ogData = ogData.slice(0, THRESHOLD);
    }

    // 1. gather all columns
    let cols = ogData
      .map((ele) => Object.keys(ele))
      .reduce((a, b) => [...a, ...b], []);

    cols = toUnique(cols).sort();

    if (sorted && cols.length > 0) {
      // console.log(sorted, cols)
      const cols_sorted = sorted.filter((ele) => hasItem(ele, cols));
      const cols_unsorted = cols.filter((ele) => notIn(ele, sorted));
      // console.log(cols_sorted + cols_unsorted)
      if (onSorted) {
        cols = cols_sorted;
      } else {
        cols = [...cols_sorted, ...cols_unsorted];
      }
    }

    if (excluded) {
      cols = cols.filter((ele) => !hasItem(ele, excluded));
    }

    // console.log(sortBy, sortDir)

    const sortFlag = (col, sortBy, sortDir) => {
      if (col === sortBy) {
        if (sortDir === 1) {
          return <i className="sortIcon fas fa-sort-up" />;
        } else if (sortDir === -1) {
          return <i className="sortIcon fas fa-sort-down" />;
        } else {
          return <i className="sortIcon fas fa-sort" />;
        }
      } else {
        return <i className="sortIcon fas fa-sort" />;
      }
    };
    let sortedData = [];
    if (sortBy && sortDir) {
      sortedData = ogData.slice().sort((a, b) => {
        if (sortDir === 1) {
          if (typeof a[sortBy] === "string") {
            return (a[sortBy] || "").localeCompare(b[sortBy]);
          } else if (typeof a[sortBy] === "number") {
            return a[sortBy] - b[sortBy];
          } else {
            return true;
          }
        } else {
          if (typeof a[sortBy] === "string") {
            return (b[sortBy] || "").localeCompare(a[sortBy]);
          } else if (typeof a[sortBy] === "number") {
            return b[sortBy] - a[sortBy];
          } else {
            return true;
          }
        }
      });
    } else {
      sortedData = ogData;
    }

    return (
      <div className="RcTable">
        {data.length > 0 ? (
          <div className="table_wrapper">
            <div className="table_head_row">
              {cols.map((ele) => (
                <div
                  className="cell"
                  style={{
                    color: sortBy === ele && sortDir ? "#000" : undefined,
                    flex: flexDict[ele] || 1,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.orderColumn(ele);
                  }}
                >
                  {keynamemap[ele] || ele} {sortFlag(ele, sortBy, sortDir)}
                </div>
              ))}
            </div>
            <div
              className="table_body"
              style={{ maxHeight: height ? height + "px" : undefined }}
            >
              {sortedData.map((row) => {
                return (
                  <div className="table_row">
                    {cols.map((c) => {
                      return (
                        <div
                          className="cell"
                          style={{
                            flex: flexDict[c] || 1,
                          }}
                        >
                          {row[c] || "---"}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          ""
        )}
        {tooManyRows ? <div> Maximum {THRESHOLD} rows </div> : ""}
      </div>
    );
  }
}
