import { Box, LinearProgress, Stack } from "@mui/material";
import { theme } from "App";
import React from "react";

const LoadingBox = () => {
  return (
    <Box>
      <Stack
        minHeight={"250px"}
        justifyContent={"center"}
        alignItems={"center"}
        direction={"column"}
        width={"100%"}
      >
        <Box>
          <img src={theme.logo.main} alt="" height="50" />
        </Box>
        <Box width={"140px"} mt={1}>
          <LinearProgress color="secondary" />
        </Box>
      </Stack>
    </Box>
  );
};

export default LoadingBox;
