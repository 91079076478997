import { Box, Button, Stack, Typography, colors, IconButton } from "@mui/material";
import { PondManagerServices } from "api/pondManagerServices";
import CommonModal from "components/Modal/CommonModal";
import { AddCircleIcon, HelpOutlineIcon } from "components/Icons/MaterialIcons";
import Text, { getText } from "components/text/Text";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { modalIds, modalReducersActions } from "redux/modal";

import DocumentCard from "screens/Aquaculture/components/common/DocumentCard";
import DocumentForm from "screens/Aquaculture/components/common/DocumentForm";
import { getLocalStorageItem, saveLocalStorageItem } from "helpers/LocalStorage";
import { ReorderIcon } from "components/Icons/MaterialIcons";

import FarmDataTableView from "./FarmDataTableView";
import FarmDocumentTypes from "constants/FarmDocumentTypes";

const EditModes = {
  create: "create",
  edit: "edit",
  none: null,
};

const RecordViews = {
  grid: "grid",
  table: "table",
};

const FarmDataRoom = ({ farm }) => {
  const { farmid } = useParams();
  const reportRecordStorageData = getLocalStorageItem("farmReportRecordListView");
  const farm_id = farmid;

  const [editMode, setEditMode] = useState(EditModes.none);
  const [selectedDoc, setDoc] = useState(null);

  const [docs, setDocs] = useState([]);

  const [viewType, setviewType] = useState(
    // reportRecordStorageData || RecordViews.grid
    RecordViews.table
  );

  const dispatch = useDispatch();

  useEffect(() => {
    Actions.load();
  }, [farm_id]);

  const Actions = {
    load: async () => {
      const result = await PondManagerServices.getFarmDocuments(farm_id);
      setDocs(result.payload);
    },
    create: () => {
      setEditMode(EditModes.create);
      setTimeout(() => {
        modalReducersActions.openModal(dispatch, { id: modalIds.FARM_RECORD_MODAL });
      }, 100);
    },
    submitCreateForm: async (form) => {
      const toBase64 = (file) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = reject;
        });
      const fileEncoded = await toBase64(form.file);

      const requestForm = {
        action: "create",
        filename: form.filename,
        label: form.label,
        id: form.id,
        note: form.note,
        type: form.type,
        file64: fileEncoded,
      };
      console.log(requestForm);
      await PondManagerServices.createFarmDocument(farm_id, requestForm);
      modalReducersActions.closeModal(dispatch, { id: modalIds.FARM_RECORD_MODAL });
      setEditMode(EditModes.none);
      Actions.load();
    },
    submitUpdateForm: async (form) => {
      await PondManagerServices.updateFarmDocument(farm_id, form);
      modalReducersActions.closeModal(dispatch, { id: modalIds.FARM_RECORD_MODAL });
      setDoc(null);
      setEditMode(EditModes.none);
      Actions.load();
    },
    downloadDocument: async (docid) => {
      const url = (await PondManagerServices.downloadFarmDocument(farm_id, docid))?.payload?.url;
      window.open(url);
    },
    downloadDocumentUrl: async (docid) => {
      const url = (await PondManagerServices.downloadFarmDocument(farm_id, docid))?.payload?.url;
      return url;
    },
    deleteDocument: async (docid) => {
      const confirmed = window.confirm(getText("interface.alert.alert-are-you-sure"));
      if (confirmed) {
        const rsp = await PondManagerServices.deleteFarmDocument(farm_id, docid);
        Actions.load();
      }
    },
    editDocument: async (doc) => {
      setDoc(doc);
      setEditMode(EditModes.edit);
      setTimeout(() => {
        modalReducersActions.openModal(dispatch, { id: modalIds.FARM_RECORD_MODAL });
      }, 100);
    },
  };

  const docGroups = [
    {
      type: FarmDocumentTypes.COMPANYREG.name,
      id: FarmDocumentTypes.COMPANYREG.id,
    },
    {
      type: FarmDocumentTypes.CERTIFICATE.name,
      id: FarmDocumentTypes.CERTIFICATE.id,
    },
    {
      type: FarmDocumentTypes.FINANCIAL.name,
      id: FarmDocumentTypes.FINANCIAL.id,
    },
    {
      type: FarmDocumentTypes.OTHER.name,
      id: FarmDocumentTypes.OTHER.id,
    },
  ];

  const handleListViewSelector = (selectedTypeText) => {
    setviewType(selectedTypeText);
    saveLocalStorageItem("farmReportRecordListView", selectedTypeText);
  };

  const pullDocumentFromTable = (doc) => {
    setDoc(doc);
  };

  return (
    <Box p={2}>
      <CommonModal size="large" id={modalIds.FARM_RECORD_MODAL}>
        {editMode === EditModes.create && <DocumentForm documentTypes={_.values(FarmDocumentTypes)} onSubmit={Actions.submitCreateForm} />}
        {editMode === EditModes.edit && selectedDoc && <DocumentForm docId={selectedDoc.id} document={selectedDoc} documentTypes={_.values(FarmDocumentTypes)} onSubmit={Actions.submitUpdateForm} />}
      </CommonModal>
      <Stack direction="row" gap={1} alignItems={"center"}>
        <Button variant="contained" startIcon={<AddCircleIcon />} onClick={Actions.create}>
          <Text>interface.actions.create</Text>
        </Button>

        <Stack direction={"row"} spacing={1}>
          {/* <IconButton
            color={viewType === RecordViews.grid ? "secondary" : "primary"}
            size="small"
            variant="outline"
            onClick={() => handleListViewSelector(RecordViews.grid)}
          >
            <ViewModuleIcon />
          </IconButton> */}
          <IconButton color={viewType === RecordViews.table ? "secondary" : "primary"} size="small" variant="outline" onClick={() => handleListViewSelector(RecordViews.table)}>
            <ReorderIcon />
          </IconButton>
        </Stack>
      </Stack>
      <Stack gap={1}>
        {viewType === RecordViews.grid &&
          docGroups.map(({ type, id }) => {
            return (
              <Box>
                <Box my={2}>
                  <Typography fontWeight={"bold"} textTransform={"uppercase"}>
                    {type}
                  </Typography>
                </Box>
                <Stack direction={"row"} gap={1} flexWrap={"wrap"} my={1}>
                  {docs.filter((el) => el.type === id).length === 0 && (
                    <Box
                      sx={{
                        svg: {
                          fontSize: 80,
                          color: colors.grey[200],
                        },
                      }}
                    >
                      <HelpOutlineIcon />
                    </Box>
                  )}
                  {docs
                    .filter((el) => el.type === id)
                    .map((d, idx) => (
                      <DocumentCard document={d} onDownload={() => Actions.downloadDocument(d.id)} onDelete={() => Actions.deleteDocument(d.id)} onEdit={() => Actions.editDocument(d)} />
                    ))}
                </Stack>
              </Box>
            );
          })}

        {viewType === RecordViews.table && (
          <Box>
            <FarmDataTableView docs={docs} FarmDocumentTypes={FarmDocumentTypes} Actions={Actions} setSelectedDocument={(data) => pullDocumentFromTable(data)} />
          </Box>
        )}

        {/* {
        selectedDocument &&
        <DocumentForm 
          docId={selectedDocument.id}
          document={selectedDoc}
          documentTypes={_.values(FarmDocumentTypes)}
          onSubmit={Actions.submitUpdateForm}
        />
      } */}
      </Stack>
    </Box>
  );
};

export default FarmDataRoom;
