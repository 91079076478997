import { Box, Stack, Typography, colors } from "@mui/material";
import _ from "lodash";
import Formatters from "helpers/Formatters";
import HighchartsWrapper from "components/Highcharts/HighchartsWrapper";
import NumericFormatters from "helpers/NumericFormatters";
import { getText } from "components/text/Text";
import numeral from "numeral";

/**
 *
 * Dashboard
 *    >> Pond
 *        >> Feed/Additive/PondTreatment Usage
 *            >> Daily Chart
 *
 * @param {*} param0
 * @returns
 */
const FarmingProductAggregatedChart = ({ data, cycle }) => {
  // process data
  const aggregated = _.flatMap(data, "rows");

  // Step 1: Group by date (without time)
  const groupedByDate = _.groupBy(aggregated, (item) => item.datetime.split(" ")[0]);

  // Step 2: Calculate the daily total
  const dailyTotals = _.map(groupedByDate, (items, date) => {
    return {
      datetime: date,
      usage: _.sumBy(items, "usage"),
    };
  });

  // Step 3: Calculate daily average
  const totalDays = dailyTotals.length;
  const totalDailyValue = _.sumBy(dailyTotals, "usage");
  const dailyAverage = totalDailyValue / totalDays;

  // Step 4: Group by week (using ISO week number)
  const groupedByWeek = _.groupBy(aggregated, (item) => {
    const date = new Date(item.datetime);
    const yearStart = new Date(date.getFullYear(), 0, 1);
    const weekNumber = Math.ceil(((date - yearStart) / 86400000 + yearStart.getDay() + 1) / 7); // ISO week number
    return `${date.getFullYear()}-W${weekNumber}`;
  });

  // Step 5: Calculate weekly total and average
  const weeklyTotals = _.map(groupedByWeek, (items, week) => {
    return {
      week: week,
      usage: _.sumBy(items, "usage"),
    };
  });

  const totalWeeks = weeklyTotals.length;
  const totalWeeklyValue = _.sumBy(weeklyTotals, "usage");
  const weeklyAverage = totalWeeklyValue / totalWeeks;

  // Results
  const detailedChartConfigs = {
    chart: {
      height: "300px",
      animation: false,
    },
    xAxis: {
      type: "datetime",
      min: Formatters.toLocalDatetimeValue(cycle?.start_date),
      max: Formatters.toLocalDatetimeValue(cycle?.end_date),
    },
    yAxis: {
      title: {
        text: getText("interface.general.total-usage"),
      },
      plotLines: [
        {
          value: dailyAverage, // The average value
          color: colors.red[200], // Color of the line
          dashStyle: "Dash", // Dashed line
          width: 1, // Width of the line
          zIndex: 5, // Position above other elements
          label: {
            text: `AVG=${numeral(dailyAverage).format("0.00")}`, // Label text
            align: "right",
            style: {
              fontSize: "10px",
              color: colors.red[200], // Color of the label
            },
            useHTML: true,
          },
        },
      ],
    },
    plotOptions: {
      series: {
        stacking: "overlap",
        animation: false,
      },
      column: {
        /* Here is the setting to limit the maximum column width. */
        maxPointWidth: 30,
        grouping: true,
      },
    },
    series: [
      {
        name: getText("interface.time.daily"),
        type: "column",
        data: dailyTotals.map((ele) => [Formatters.toLocalDatetimeValue(ele.datetime), ele.usage]),
      },
    ],
  };

  return (
    <Box>
      <Stack direction={"row"} spacing={3}>
        <Typography fontSize={13} fontWeight={800}>
          <span style={{ color: colors.grey[500] }}>
            {" "}
            {getText("interface.general.average")}({getText("interface.time.day")}){" "}
          </span>{" "}
          {NumericFormatters.formatWeightInKg({ value: dailyAverage })}
        </Typography>
        <Typography fontSize={13} fontWeight={800}>
          <span style={{ color: colors.grey[500] }}>
            {" "}
            {getText("interface.general.average")}({getText("interface.time.week")}){" "}
          </span>{" "}
          {NumericFormatters.formatWeightInKg({ value: weeklyAverage })}
        </Typography>
      </Stack>
      <HighchartsWrapper options={detailedChartConfigs} />
    </Box>
  );
};

export default FarmingProductAggregatedChart;
