import NumericFormatters from "helpers/NumericFormatters";
import _ from "lodash";

export function ShrimplFieldCellRenderer({ colDef, value, ...props }, extra) {
  if (colDef.field === "feed_product") {
    const registeredProducts = extra?.registeredProducts || [];
    const product = _.find(registeredProducts, { id: value });
    return product?.name || (value ? `ID#${value}` : "-");
  }
  return NumericFormatters.format({ value: value });
}
