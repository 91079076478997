import { getText } from "components/text/Text";
import _ from "lodash";

const PondSubCategories = [
  {
    display: "Whiteleg Shrimp",
    value: "litopenaeus-vannamei",
  },
  {
    display: "Black Tiger Prawn",
    value: "penaeus-monodon",
  },
  {
    display: "Greasyback Shrimp",
    value: "metapenaeus-ensis",
  },
  {
    display: "Banana Prawn",
    value: "penaeus-merguiensis",
  },
  {
    display: "Indian White Prawn",
    value: "fenneropenaeus-indicus",
  },
  {
    display: "Giant Freshwater Prawn",
    value: "macrobrachium-rosenbergii",
  },
];

export const PrettifyPondSubCategory = (catValue) => {
  const subCategory = _.find(PondSubCategories, { value: catValue });
  if (!subCategory) return "";
  return getText(`interface.species.${subCategory?.value}`, subCategory?.display);
};

export default PondSubCategories;
