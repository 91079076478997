import { useQuery } from "@tanstack/react-query";
import { ClimateServices } from "api/climateServices";
import { addHours } from "screens/Aquaculture/components/Climate/ClimateDashboard/widgets/utilities";

const useClimateQueryHook = ({ farm }) => {
  const now = new Date();
  const startTime = now.toISOString();
  const endTime = addHours({ date: now, hours: 24 * 6 }).toISOString();

  return useQuery({
    queryKey: ["data", "climate", farm?.farm_id],
    queryFn: async () => {
      const rsp = await ClimateServices.getClimates({
        latitude: farm?.lat,
        longitude: farm?.lon,
        dataType: "daily",
        timesteps: "current,1d",
        hourlyStartDate: startTime,
        hourlyEndDate: endTime,
        fields: "precipitationIntensity,temperature,temperatureApparent,weatherCode",
      });
      return rsp.data;
    },
    enabled: !!farm?.farm_id, // Only run if farm exists
    staleTime: 1000 * 60 * 30, // Data is fresh for 5 minutes
    gcTime: 1000 * 60 * 60 * 24, // Data is fresh for 5 minutes
  });
};

export default useClimateQueryHook;
