import { Box, Stack, Tooltip, Typography, colors } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { DeleteIcon, EditIcon } from "components/Icons/MaterialIcons";
import { OutlinedTagCollections } from "components/Tags/OutlinedTag";
import { MuiBorderIconButton } from "components/buttons/MuiButtonVariants";
import { getText } from "components/text/Text";
import NumericFormatters from "helpers/NumericFormatters";
import React, { useEffect, useState } from "react";

const CycleTable = ({ cycles, onEdit, onDelete, editable, showall }) => {
  const [rowData, setRowData] = useState([]);
  const [colDefs, setColDefs] = useState([
    {
      field: "pond_label",
      headerName: getText("interface.general.pond"),
    },
    {
      field: "label",
      headerName: getText("interface.general.label"),
    },
    {
      field: "start_date",
      headerName: getText("interface.general.start-date"),
    },
    {
      field: "end_date",
      headerName: getText("interface.general.end-date"),
    },
    {
      field: "days",
      headerName: getText("interface.general.duration"),
      cellRenderer: ({ value }) =>
        !!value ? (
          <span>
            {value}d <small style={{ color: "grey" }}>( {NumericFormatters.format({ value: value / 7, decimalScale: 1, suffix: "w" })} )</small>
          </span>
        ) : (
          ""
        ),
    },
    {
      field: "ended",
      headerName: getText("interface.general.activity"),
      cellRenderer: ({ value }) => (!!value ? value ? <OutlinedTagCollections.CycleInactiveTag /> : <OutlinedTagCollections.CycleActiveTag /> : ""),
      maxWidth: 100,
    },
    {
      field: "progress",
      headerName: getText("interface.general.progress"),
      cellRenderer: ({ value, data }) =>
        value ? (
          <Tooltip placement="top" title={NumericFormatters.format({ value: value * 100, decimalScale: 1, suffix: "%" })}>
            <Box
              sx={{
                height: 12,
                width: "100%",
                position: "relative",
                background: "#EEE",
                borderRadius: 3,
                overflow: "hidden",
                border: `1px solid ${colors.grey[500]}`,
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  height: "100%",
                  width: `${value * 100}%`,
                  background: data.ended ? colors.grey[500] : colors.green[500],
                }}
              ></Box>
            </Box>
          </Tooltip>
        ) : (
          ""
        ),
    },
    {
      field: "id",
      headerName: "ID",
      cellRenderer: ({ value }) => (
        <Typography fontSize={9} color="grey">
          <code>{value}</code>
        </Typography>
      ),
    },
    {
      field: "",
      headerName: "",
      maxWidth: 100,
      cellRenderer: ({ data }) =>
        editable && data ? (
          <Stack direction={"row"} spacing={0.3}>
            {onEdit && <MuiBorderIconButton size={"sm"} onClick={() => onEdit(data)} icon={<EditIcon />} />}
            {onDelete && <MuiBorderIconButton size={"sm"} onClick={() => onDelete(data)} icon={<DeleteIcon />} />}
          </Stack>
        ) : (
          ""
        ),
      cellStyle: { display: "flex", alignItems: "center", justifyContent: "center" },
    },
  ]);

  useEffect(() => {
    setRowData(cycles);
  }, [cycles]);

  return (
    <Box className="ag-theme-clean" height={"calc(100vh - 250px)"}>
      <AgGridReact
        rowData={rowData}
        columnDefs={colDefs}
        rowHeight={28}
        defaultColDef={{
          flex: 1,
          cellStyle: { display: "flex", alignItems: "center" },
        }}
        pagination={showall ? false : true}
        paginationPageSize={50}
      />
    </Box>
  );
};

export default CycleTable;
