import { PondManagerServices } from "api/pondManagerServices";
import { Grid3x3Icon, GroupsIcon } from "components/Icons/MaterialIcons";
import Text, { getText } from "components/text/Text";
import _ from "lodash";
import { useEffect, useState } from "react";
import { GiSettingsKnobs } from "react-icons/gi";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTE_ORGANISATION_HOME } from "routes/paths";
import OrganisationEditPage from "screens/Aquaculture/Organisations/OrganisationEditPage";
import OrganisationAdminMemberPage from "screens/Aquaculture/Organisations/OrganisationAdminMemberPage";

import SideMenuLayout, { SideMenuLayoutThemes } from "screens/Aquaculture/components/Layout/SideMenuLayout";
import OrganisationAdminFarmPage from "screens/Aquaculture/Organisations/OrganisationAdminFarmPage";

const OrganisationAdminPage = () => {
  const params = useParams();
  const param__orgId = params["orgId"];

  const [org, setOrg] = useState(null);

  useEffect(() => {
    Actions.loadOrg(param__orgId);
  }, [param__orgId]);

  const Actions = {
    loadOrg: async (orgId) => {
      const orgs = await PondManagerServices.getOrgs();
      const org_ = _.find(orgs, { id: orgId });
      if (org_) {
        setOrg(org_);
      }
    },
  };

  const navigate = useNavigate();

  const MENULIST = [
    {
      label: <Text>interface.general.settings</Text>,
      description: "",
      value: 0,
      icon: <GiSettingsKnobs />,
      component: <OrganisationEditPage org={org} />,
    },
    {
      label: getText("interface.general.members"),
      description: "",
      value: 1,
      icon: <GroupsIcon />,
      component: <OrganisationAdminMemberPage org={org} />,
    },
    {
      label: getText("interface.general.farm-connect"),
      description: "",
      value: 2,
      icon: <Grid3x3Icon />,
      component: <OrganisationAdminFarmPage org={org} />,
    },
  ];

  return (
    <SideMenuLayout
      theme={SideMenuLayoutThemes.buttons}
      menulist={MENULIST}
      defaultViewMode={0}
      styles={{
        height: "100%",
      }}
      actions={{
        back: () => {
          navigate(ROUTE_ORGANISATION_HOME);
        },
      }}
      title={`${org?.name} - ${org?.org_type}`}
    />
  );
};

export default OrganisationAdminPage;
