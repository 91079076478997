import { Box, Button, Card, colors, Grid, Stack, Typography } from "@mui/material";
import { ArrowForwardIcon, SettingsIcon } from "components/Icons/MaterialIcons";
import NumberFormat from "react-number-format";
import FarmAccessLevelBadge from "./FarmAccessLevelBadge";
import { Link, useNavigate } from "react-router-dom";
import { ROUTE_POND_MANAGER_FARM_EDIT } from "routes/paths";
import Text from "components/text/Text";
import { ShrimplImagePlaceholerImg } from "components/Images";
import CountryFlagSvgIcon from "components/Icons/CountryFlagSvgIcon";

const Styles = {
  farmCardLayout: {
    border: "2px solid #edf1f2",
    "&:hover": {
      cursor: "pointer",
      border: "2px solid #ddd",
    },
    borderRadius: 3,
    "&:hover .flagWrapper": {
      top: "50px",
      opacity: 1,
    },
  },
};

const FarmCard = ({ variant = "farm", farm }) => {
  const navigate = useNavigate();

  const variableContent = {
    farm: {
      getViewLink: () => `${location.pathname}/${farm.farm_id}`,
      accessBadge: () => (
        <Box>
          <FarmAccessLevelBadge accessLevelId={farm.access_level_id} />
        </Box>
      ),
      buttons: () => [
        <Button startIcon={<SettingsIcon />} fullWidth component={Link} to={`${ROUTE_POND_MANAGER_FARM_EDIT}/${farm.farm_id}`}>
          <Text>interface.actions.edit</Text>
        </Button>,
        <Button fullWidth to={viewLink} endIcon={<ArrowForwardIcon />} variant="contained" component={Link}>
          <Text>interface.actions.view</Text>
        </Button>,
      ],
    },
    org: {
      getViewLink: () => `${location.pathname}/${farm.farm_id}`,
      accessBadge: () => "",
      buttons: () => [
        <Button fullWidth to={viewLink} endIcon={<ArrowForwardIcon />} variant="contained" color="org" component={Link}>
          <Text>interface.actions.view</Text>
        </Button>,
      ],
    },
  };

  const viewLink = variableContent[variant].getViewLink();

  // get farm images
  const farmImages = farm?.images || [];

  // get the featured farm image
  const farmDisplayImage = farmImages[0]?.compressed_url || ShrimplImagePlaceholerImg;

  return (
    <Card sx={Styles.farmCardLayout}>
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box
          onClick={() => {
            navigate(viewLink);
          }}
          height={"200px"}
          sx={{
            backgroundImage: `url("${farmDisplayImage}")`,
            backgroundPosition: "center",
            backgroundSize: "110%",
            backgroundColor: "#FFF",
            backgroundRepeat: "no-repeat",
            transition: "all .2s",
            filter: "brightness(80%)",
            ":hover": {
              filter: "brightness(100%)",
              backgroundSize: "115%",
            },
          }}
        ></Box>
        <Box
          p={1}
          textAlign={"center"}
          sx={{
            bgcolor: "#edf1f2",
          }}
        >
          <Typography fontWeight={"bold"} textOverflow={"ellipsis"} whiteSpace={"nowrap"} fontSize={14} color="primary">
            {farm.label}
          </Typography>
        </Box>

        <Box p={2}>
          <Stack direction="row" justifyContent={"space-between"} alignItems="flex-start">
            <Stack direction="row" flexWrap={"wrap"} alignItems="center">
              <Box>
                <Typography variant="body2">
                  <Stack direction={"row"} spacing={1} alignItems={"flex-start"}>
                    <CountryFlagSvgIcon countryCode={farm.country_code.toUpperCase()} />
                    <Box>
                      <Typography fontWeight={"bold"} color={colors.grey[700]} lineHeight={0.6}>
                        {farm.country}
                      </Typography>
                      <Box>
                        <Typography
                          variant="caption"
                          color="grey"
                          sx={{
                            width: "20px",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {[farm.region, farm.place].filter((e) => e).join(" • ")}
                        </Typography>
                      </Box>
                    </Box>
                  </Stack>
                </Typography>
              </Box>
            </Stack>
            {variableContent[variant].accessBadge()}
          </Stack>
        </Box>
      </Box>
      <Box borderTop={"1px solid #edf1f2"}>
        <Grid container>
          <Grid item xs={4} borderRight={"1px solid #edf1f2"} px={2} py={1}>
            <Typography variant="caption" color={colors.grey[600]}>
              <Text>interface.general.total-area</Text>
            </Typography>
            <Typography fontWeight={"bold"}>
              <NumberFormat value={farm.total_area} displayType="text" thousandSeparator={true} decimalScale={2} /> ha.
            </Typography>
          </Grid>
          <Grid item xs={4} px={2} py={1} borderRight={"1px solid #edf1f2"}>
            <Typography variant="caption" color={colors.grey[600]}>
              <Text>interface.general.total-ponds</Text>
            </Typography>
            <Typography fontWeight={"bold"}>{farm.total_ponds}</Typography>
          </Grid>
          <Grid item xs={4} px={2} py={1}>
            <Typography variant="caption" color={colors.grey[600]}>
              <Text>interface.general.currency</Text>
            </Typography>
            <Typography fontWeight={"bold"}>
              {farm?.settings?.currency || (
                <span
                  style={{
                    color: "#AAA",
                  }}
                >
                  -
                </span>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box borderTop={"1px solid #edf1f2"}>
        <Stack spacing={1} p={1} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
          {variableContent[variant].buttons().map((btn) => btn)}
        </Stack>
      </Box>
    </Card>
  );
};

export default FarmCard;
