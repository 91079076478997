const CollabMemberRoles = [
  // {
  //   label: "Owner",
  //   description: "User can create, view, manage, export data or share",
  //   value: 4,
  // },
  {
    label: "Admin",
    description: "User can create, view, manage, export data or share",
    value: 3,
  },
  {
    label: "Manager",
    description: "User can create, view, manage or export data",
    value: 2,
  },
  {
    label: "Advisor",
    description: "Advisor can view and export data",
    value: 5,
  },
  {
    label: "Viewer",
    description: "User can only view data",
    value: 1,
  },
  {
    label: "Technicial",
    description: "User can only submit data",
    value: 6,
  },
];

export default CollabMemberRoles;
