import React, { Component } from "react";
import styled from "styled-components";
import RcSelect from "../../../components/input/RcSelect";
import { generateSeasonal } from "helpers/ChartFunctions";
import MultiViewChart from "../../../components/chart/MultiViewChart";
import { api_data } from "../../../api/api";

const Layout = styled.div`
  box-sizing: border-box;
  .chartContainer {
    margin: 20px 0;
  }
`;

const datacolumns = [
  "rain",
  "mintemp",
  "maxtemp",
  "avg10mwindspeed",
  "evapotranspiration",
  "maxrelativehumidity",
  "minrelativehumidity",
  "panevaporation",
  "solarradiation",
];

export default class BomOnlineCard extends Component {
  state = {
    data: {
      rows: [],
    },
    isInit: 0,
    isLoading: 0,
    seasonalStart: 1,
    selectedDataType: datacolumns[0],
  };
  componentDidMount() {
    if (this.props.payload) {
      this.getData();
    }
  }

  getData = () => {
    this.setState({ isLoading: 1 }, () => {
      api_data({
        ticker: "bom_weather__" + this.props.payload.id,
        fields: "*",
        from: "2000-01-01",
        schema: "altv1",
      }).then((res) => {
        this.setState({ data: res.data, isInit: 1, isLoading: 0 });
      });
    });
  };

  onChangeSeasonalStart = (newStart) => {
    this.setState({
      seasonalStart: newStart,
    });
  };

  render() {
    const payload = this.props.payload;

    // ==== state ====
    const data = this.state.data;

    // ==== Other ====
    const seasonalStartOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
      (ele, idx) => ({
        label: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ][idx],
        value: ele,
      })
    );

    if (!payload) {
      return <div>SELECT A STATION</div>;
    } else {
      const dataOptions = datacolumns.map((ele) => ({
        label: ele,
        value: ele,
      }));
      let chartConfig = (column) => {
        let ifCumSum = false;
        if (["rain"].includes(column)) {
          ifCumSum = true;
        }
        const config = {
          line: [
            {
              name: column,
              data: this.state.data.rows.map((ele) => [
                Date.parse(ele._date),
                ele[column],
              ]),
            },
          ],
          seasonal: generateSeasonal(
            this.state.data.rows.map((ele) => [ele._date, ele[column]]),
            false,
            this.state.seasonalStart,
            ifCumSum
          ),
        };
        return config;
      };

      return (
        <Layout>
          <h1>
            <i className="fas fa-cloud-sun"></i> {payload.station_name}
          </h1>
          <div className="RcSelectWrapper">
            <RcSelect
              label="Datapoints"
              options={dataOptions}
              placeholder="select another data..."
              onChange={(e) => {
                this.setState({ selectedDataType: e.value });
              }}
            />
            <RcSelect
              label="Seasonal Start"
              options={seasonalStartOptions}
              onChange={(e) => {
                this.onChangeSeasonalStart(e.value);
              }}
            />
          </div>
          <div className="chartContainer">
            <h3>{this.state.selectedDataType}</h3>
            {this.state.isLoading ? (
              <div className="LoadingText">Loading...</div>
            ) : (
              <MultiViewChart
                chartid={this.state.selectedDataType + this.state.seasonalStart}
                height={500}
                data={chartConfig(this.state.selectedDataType)}
              />
            )}
          </div>
        </Layout>
      );
    }
  }
}
