import React, { useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import { GOOGLE_MAP_KEY } from "secrets";
import { colors } from "@mui/material";

const createMapOptions = (maps) => {
  return {
    disableDefaultUI: true,
    gestureHandling: "none", // Disables panning and other gestures
    draggable: false, // Disables panning via dragging
    fullscreenControl: false,
    zoomControl: false,
    mapTypeId: maps.MapTypeId.SATELLITE,
    minZoom: 5,
  };
};

const SimpleGeojsonMapView = ({ geojson }) => {
  const [center, setCenter] = useState([-2.585671742540449, -80.01776784245965]);
  const [zoom, setZoom] = useState(15);
  const mapRef = useRef(null);

  const handleApiLoaded = async (map, maps) => {
    map.data.addGeoJson(geojson, {
      idPropertyName: "jsonData",
    });
    map.data.setStyle((feature) => {
      return {
        fillColor: colors.blue[400],
        strokeColor: "#FFF",
        strokeWeight: 2,
        fillOpacity: 0.3,
      };
    });

    // feed bounds
    const bounds = new maps.LatLngBounds();
    map.data.forEach(function (feature) {
      feature.getGeometry().forEachLatLng(function (latlng) {
        bounds.extend(latlng);
      });
    });
    map.fitBounds(bounds, 50);
  };

  return (
    <GoogleMapReact
      ref={mapRef}
      bootstrapURLKeys={{ key: GOOGLE_MAP_KEY }}
      center={center}
      defaultZoom={zoom}
      options={createMapOptions}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
    ></GoogleMapReact>
  );
};

export default SimpleGeojsonMapView;
