import { Box, Divider, Grid, Stack, TextField, Typography } from "@mui/material";
import AppLocalStorage, { getLocalStorageItem, saveLocalStorageItem } from "helpers/LocalStorage";
import _ from "lodash";
import { useState } from "react";
import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import Text, { getText } from "components/text/Text";
import LoadingBox from "components/Loading/LoadingBox";
import MobileFarmCard from "screens/Mobile/components/MobileFarmCard";
import BorderCard from "ui/Card/BorderCard";
import NumericFormatters from "helpers/NumericFormatters";

const HomePageListViews = {
  grid: "grid",
  table: "table",
  map: "map",
};

const MobilePondManagerHome = () => {
  const local = new AppLocalStorage();

  const farmListViewStorageData = getLocalStorageItem("farmListView");

  const pondManagerStates = useSelector((state) => state.pond);

  const farms = pondManagerStates.farms;

  const [selectedListView, setSelectedListView] = useState(HomePageListViews.grid);

  const [searchTerm, setSearchTerm] = useState(null);

  const Variables = {
    stats: [
      {
        label: getText("interface.general.total-farms"),
        value: farms.length,
      },
      {
        label: getText("interface.general.total-ponds"),
        value: NumericFormatters.format({ value: _.sumBy(farms, "total_ponds") }),
      },
      {
        label: getText("interface.general.total-area") + " (ha.)",
        value: NumericFormatters.format({ value: _.sumBy(farms, "total_area"), decimalScale: 0 }),
      },
    ],
  };

  const displayFarms = farms.filter((ele) => {
    if (!searchTerm) return true;
    const normalizedSearchTerm = searchTerm.trim().toLowerCase();
    const normalizedItem = ele.label.trim().toLowerCase();
    return normalizedItem.includes(normalizedSearchTerm);
  });

  const recentFarms = _.take(
    local
      .getRecentViewedFarmIds()
      .map((ele) => _.find(farms, { farm_id: ele }))
      .filter((ele) => ele),
    4
  );

  return (
    <Box p={1} sx={{ position: "relative" }}>
      {/* Top Toolbar */}
      <Box
        sx={{
          position: "fixed",
          width: "100%",
          left: 0,
          top: 0,
          zIndex: 999,
          backdropFilter: "blur(20px)",
        }}
      >
        <BorderCard
          sx={{
            p: 2,
          }}
        >
          <Stack direction="row" justifyContent={"space-between"} alignItems={"center"} spacing={1}>
            {Variables.stats.map((ele, idx) => {
              return (
                <Box key={idx}>
                  <Typography fontSize={10} fontWeight={800} textAlign={"center"} color={"grey"}>
                    {ele.label}
                  </Typography>
                  <Typography fontSize={20} fontWeight={800} textAlign={"center"}>
                    {ele.value}
                  </Typography>
                </Box>
              );
            })}
          </Stack>
          <Box mt={1}>
            <TextField fullWidth variant="standard" size="small" placeholder={getText("interface.actions.search")} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
          </Box>
        </BorderCard>
      </Box>

      {/* ~~~~~ Placeholder ~~~~~ */}

      <Box mt={14} zIndex={10}>
        {pondManagerStates.loading ? (
          <LoadingBox />
        ) : (
          <>
            <Grid container spacing={1}>
              {selectedListView === HomePageListViews.grid && recentFarms.length > 0 && !searchTerm && (
                <>
                  <Grid item xs={12}>
                    <Typography fontWeight={800} fontSize={10} letterSpacing={1} color="grey" textTransform={"uppercase"}>
                      <Text>interface.general.recent</Text>
                    </Typography>
                  </Grid>
                  {recentFarms.map((f) => {
                    return (
                      <Grid key={f.farm_id} item xs={6}>
                        <MobileFarmCard farm={f} />
                      </Grid>
                    );
                  })}
                  <Grid item xs={12}>
                    <Box py={2}>
                      <Divider />
                    </Box>
                  </Grid>
                </>
              )}
              {selectedListView === HomePageListViews.grid &&
                displayFarms.map((f) => {
                  return (
                    <Grid key={f.farm_id} item xs={6}>
                      <MobileFarmCard farm={f} />
                    </Grid>
                  );
                })}
            </Grid>
          </>
        )}
      </Box>
    </Box>
  );
};

export default MobilePondManagerHome;
