import { InfoOutlined } from "@mui/icons-material";
import { Autocomplete, Box, Button, Chip, Grid, ListSubheader, MenuItem, Select, Slider, Stack, TextField, Tooltip, Typography, colors } from "@mui/material";
import CountryFlagSvgIcon from "components/Icons/CountryFlagSvgIcon";
import Text from "components/text/Text";
import Countries from "constants/Countries";
import { Form, Formik } from "formik";
import _, { upperCase } from "lodash";
import React from "react";

export const FormFieldTypes = {
  text: "text",
  numeric: "numeric",
  slider: "slider",
  tags: "tags",
  select: "select",
  country_select: "country_select",
};

const FormFieldCommon = {
  theLabel: ({ label, description }) => (
    <Stack direction={"row"} alignItems={"center"} spacing={0.3} mb={0.5}>
      <Typography variant="inputLabel" color="grey">
        {label}
      </Typography>
      {description && (
        <Tooltip title={description} placement="top">
          <InfoOutlined fontSize="10" />
        </Tooltip>
      )}
    </Stack>
  ),
};

const FormFieldRenderer = ({ type = FormFieldTypes.text, label, name, description, value, placeholder, options, handleChange, setFieldValue }) => {
  if (FormFieldTypes[type]) {
    //
    // pure text value
    //
    if (type === FormFieldTypes.text) {
      return (
        <Box>
          {FormFieldCommon.theLabel({ label, description })}
          <TextField placeholder={placeholder} variant="standard" fullWidth name={name} value={value} onChange={handleChange} size="large" {...options}></TextField>
        </Box>
      );
    }
    //
    // pure text value
    //
    if (type === FormFieldTypes.numeric) {
      return (
        <Box>
          {FormFieldCommon.theLabel({ label, description })}
          <TextField type="number" placeholder={placeholder} variant="standard" fullWidth name={name} value={value} onChange={handleChange} size="large" {...options}></TextField>
        </Box>
      );
    }
    //
    // tags (multi select and free input)
    //
    if (type === FormFieldTypes.tags) {
      const isValueString = _.isString(value);
      const valueOverride = isValueString ? value.split(",").filter((ele) => ele) : value;
      return (
        <Box>
          {FormFieldCommon.theLabel({ label, description })}
          <Autocomplete
            multiple
            name={name}
            options={options.options}
            freeSolo
            value={valueOverride}
            onChange={(e, newVal) => {
              setFieldValue(name, isValueString ? newVal.join(",") : newVal);
            }}
            renderTags={(value, getTagProps) => value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({ index })} size="small" />)}
            renderInput={(params) => <TextField {...params} variant="standard" placeholder={placeholder} size="large" />}
            sx={{
              ".MuiInput-root": {
                p: 0,
              },
            }}
          />
        </Box>
      );
    }
    //
    // slider (numeric)
    //
    if (type === FormFieldTypes.slider) {
      return (
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <Stack direction={"row"} alignItems={"center"} spacing={0.3}>
            <Typography variant="inputLabel" whiteSpace={"nowrap"}>
              {label}
            </Typography>
            {description && (
              <Tooltip title={description} placement="top">
                <InfoOutlined fontSize="10" />
              </Tooltip>
            )}
          </Stack>
          <Slider color="primary" fullWidth name={name} value={value} onChange={handleChange} {...options}></Slider>
          <Typography>
            <strong>{(options?.valueLabelFormat && options?.valueLabelFormat(value)) || value}</strong>
          </Typography>
        </Stack>
      );
    }
    //
    // country_select
    //
    if (type === FormFieldTypes.country_select) {
      const options_ = Countries;
      return (
        <Box>
          {FormFieldCommon.theLabel({ label, description })}
          <Autocomplete
            getOptionLabel={(option) => option.label}
            name={name}
            options={options_}
            value={_.find(Countries, { code: upperCase(value) })}
            onChange={(props, newVal) => {
              setFieldValue(name, newVal ? newVal.code : null);
            }}
            renderInput={(params) => <TextField {...params} variant="standard" placeholder={placeholder} size="large" {...options} />}
            renderOption={(props, option) => (
              <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                <CountryFlagSvgIcon countryCode={option.code} withName />
              </Box>
            )}
          />
        </Box>
      );
    }
    //
    // select
    // ---
    // options: [{ label *, valuem *, group }]   NOTE: * is required
    //
    if (type === FormFieldTypes.select) {
      const selectOptions = options?.options || [];
      return (
        <Box>
          {FormFieldCommon.theLabel({ label, description })}
          <Select variant="standard" onChange={(e) => setFieldValue(name, e.target.value)} value={value} {...options} fullWidth>
            {selectOptions.map((ele, idx) => {
              if (ele.children && ele.children?.length > 0) {
                return [
                  <ListSubheader
                    muiskiplisthighlight
                    sx={{
                      lineHeight: 2,
                      bgcolor: colors.grey[300],
                    }}
                  >
                    {ele.label}
                  </ListSubheader>,
                  ...ele.children.map((ele_, idx) => (
                    <MenuItem key={ele_.value} value={ele_.value}>
                      {ele_.label}
                    </MenuItem>
                  )),
                ];
              } else {
                return (
                  <MenuItem key={ele.value} value={ele.value}>
                    {ele.label}
                  </MenuItem>
                );
              }
            })}
          </Select>
        </Box>
      );
    }
  }
};

/**
 *
 * A common form component
 *
 *
 *
 * - fields
 *    - type<str>:   "text" || "slider" || "number" || "tags" || "country_select"
 *    - name<str>
 *
 *
 *
 * @returns
 */
const CommonForm = ({ fields, title, onSubmit, columns = 1, initialValues = {}, buttonLabel, buttonIcon, submitOnChange }) => {
  return (
    <Box>
      <Box mb={1}>{title && <Typography fontSize={10}>{title}</Typography>}</Box>
      <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={true}>
        {({ values, handleChange, setFieldValue }) => {
          const fieldsToRender = fields.map((f) => {
            return {
              type: f.type,
              name: f.name,
              value: values?.[f.name],
              label: f.label,
              options: f.options,
              description: f.description,
              placeholder: f.placeholder,
              span: f.span,
              handleChange,
              setFieldValue,
            };
          });
          return (
            <Form>
              <Grid container spacing={2} columns={columns}>
                {fieldsToRender.map((field, idx) => {
                  return (
                    <Grid item xs={field.span || 1} key={idx}>
                      <FormFieldRenderer {...field} />
                    </Grid>
                  );
                })}
              </Grid>
              <Box mt={3}>
                <Button fullWidth type="submit" variant="contained" size="large" startIcon={buttonIcon}>
                  {buttonLabel || <Text>interface.actions.submit</Text>}
                </Button>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default CommonForm;
