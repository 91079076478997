import Typography from "@mui/material/Typography";
const { colors } = require("@mui/material");

const TextFormatters = {
  horizontalLabelValue: ({ value, label, ...props }) => {
    return (
      <Typography fontWeight={800} {...props}>
        {label && <span style={{ color: colors.grey[500] }}>{label} </span>}
        {value}
      </Typography>
    );
  },
  verticalLabelValue: ({ value, label, labelFontSize = 10, valueFontSize = 14, align = "left", ...props }) => {
    return (
      <Typography fontWeight={800}>
        <Typography whiteSpace={"nowrap"} textAlign={align} fontWeight={800} color={colors.grey[500]} fontSize={labelFontSize} lineHeight={0.8}>
          {label}
        </Typography>
        <Typography textAlign={align} fontWeight={800} fontSize={valueFontSize}>
          {value}
        </Typography>
      </Typography>
    );
  },
};

export default TextFormatters;
