import { Box } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { getText } from "components/text/Text";
import NumericFormatters from "helpers/NumericFormatters";
import TimeFormatters from "helpers/TimeFormatters";
import { useRef } from "react";
import DataCalendarMonitor from "screens/Aquaculture/components/DataExplorer/components/DataCalendarMonitor";

const TableChart = ({ data, selectedField }) => {
  const gridRef = useRef();

  const colDefs = [
    {
      flex: 1,
      headerName: getText(`interface.time.datetime`),
      field: "datetime",
      sortable: true,
      cellRenderer: ({ value }) => TimeFormatters.formatUtcDatetime(value, "DD MMM yyyy HH:mm:ss"),
    },
    {
      flex: 1,
      headerName: `${getText(`fields.${selectedField?.field_id}`, selectedField?.field_name)} (${selectedField?.field_unit})`,
      field: selectedField?.field_id,
      cellRenderer: ({ value }) => NumericFormatters.format({ value, decimalScale: 2 }),
    },
  ];

  return (
    <Box>
      <Box my={2}>
        <DataCalendarMonitor data={data} />
      </Box>
      <Box overflow={"hidden"} className="ag-theme-shrimpl" sx={{ width: "100%", height: "600px" }}>
        <AgGridReact ref={gridRef} rowData={data} columnDefs={colDefs} rowHeight={20} headerHeight={30} pagination={true} paginationPageSize={50} />
      </Box>
    </Box>
  );
};

export default TableChart;
