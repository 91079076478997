import { Avatar, Box, Grid, Stack, Typography, colors } from "@mui/material";
import Formatters from "helpers/Formatters";
import _ from "lodash";
import React from "react";

const FarmingProductPreview = ({ product }) => {
  return (
    <Box
      p={{
        xs: 2,
        lg: 6,
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Avatar
              src={product.producer_logo_url}
              sx={{
                border: "1px solid #AAA",
              }}
            >
              {_.last(product.producer)}
            </Avatar>
            <Box>
              <Typography fontSize={16}>{product.producer_name}</Typography>
            </Box>
          </Stack>
          <Box mt={2}>
            <Box>{Formatters.Special.formatFarmingProductType({ value: product.product_type })}</Box>
            <Typography fontSize={32} fontWeight={600}>
              {product.name}
            </Typography>
          </Box>
          <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
            <Typography fontWeight={800} fontSize={13} color={"grey"}>
              {product.category || "-"}
            </Typography>
            <Typography fontWeight={800} fontSize={13} color={"grey"}>
              {"•"}
            </Typography>
            <Typography fontWeight={800} fontSize={13} color={"grey"}>
              {product.sub_category || "-"}
            </Typography>
          </Stack>
          <Stack>
            <Typography color={"grey"} fontSize={12}>
              <u>
                <a target="_blank" href={product.link}>
                  {product.link}
                </a>
              </u>
            </Typography>
          </Stack>
          <Stack direction={"row"} alignItems={"center"} spacing={0.5} mt={1}>
            <Typography fontWeight={800} fontSize={15} fontStyle={"italic"}>
              {product.net_weight || "-"}
            </Typography>
            <Typography fontWeight={800} fontSize={15} fontStyle={"italic"}>
              {product.net_weight_unit || "-"}
            </Typography>
          </Stack>
          <Box mt={3}>
            <Typography color="grey" whiteSpace={"pre-line"}>
              {product.description || "-- No Description --"}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box p={4} bgcolor={colors.grey[100]} overflow={"hidden"} borderRadius={4}>
            <img src={product.image_url} width={"100%"} alt="" />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FarmingProductPreview;
