import { ApiGet, ApiPostForm, ApiPut } from "api/apiUtils";

/** Admin Account Services */
const AdminServices = {
  
  // =============================================
  // SECTION: Field
  // =============================================

  // get all the fields (including all disabled because this is for admin)
  getFields: () => ApiGet("/admin/aqua/fields").then((res) => res.data.list),

  // create or update a field
  createOrUpdateField: (payload) => ApiPut("/admin/aqua/fields", payload).then((res) => res.data),

  // enable a field
  enableField: (fieldId) => ApiPut("/admin/aqua/fields", { field_id: fieldId, is_active: true }).then((res) => res.data),

  // disable a field
  disableField: (fieldId) => ApiPut("/admin/aqua/fields", { field_id: fieldId, is_active: false }).then((res) => res.data),
  
  // enabled a field upload
  enableFieldUpload: (fieldId) => ApiPut("/admin/aqua/fields", { field_id: fieldId, upload_enabled: true }).then((res) => res.data),
  
  // disable a field upload 
  disableFieldUpload: (fieldId) => ApiPut("/admin/aqua/fields", { field_id: fieldId, upload_enabled: false }).then((res) => res.data),
  
  // =============================================
  // SECTION: User
  // =============================================

  // get all registered users
  getAllUsers: () => ApiGet("/admin/aqua/users").then(res => res.data),


  // =============================================
  // SECTION: Farming Product
  // =============================================

  // fetch producer list
  fetchProducers: () => ApiGet("/admin/aqua/farming-products?action=PRODUCERS").then(res => res.data.payload),
  
  // get producer details
  getProducerDetails: (producerid) => ApiGet(`/admin/aqua/farming-products?action=PRODUCERS&producerid=${producerid}`).then(res => res.data.payload?.rows?.[0]),
  
  // create producer
  createProducer: ({ formData }) => ApiPostForm("/admin/aqua/farming-products", { action: "CREATE_PRODUCER", ...formData }).then(res => res.data),
  
  // update producer
  updateProducer: ({ formData }) => ApiPostForm("/admin/aqua/farming-products", { action: "UPDATE_PRODUCER", ...formData }).then(res => res.data),
  
  // delete a producer
  deleteProducer: ({ producerId }) => ApiPostForm("/admin/aqua/farming-products", { action: "DELETE_PRODUCER", producer_id: producerId }).then(res => res.data),

  // fetch product list
  fetchProducts: ({producerid, productType, limit}) => ApiGet(`/admin/aqua/farming-products?action=PRODUCTS&producerid=${producerid}&limit=${limit}${productType ? `&producttype=${productType}` : ""}`).then(res => res.data.payload),
  
  // create producer
  createProduct: ({ formData }) => ApiPostForm("/admin/aqua/farming-products", { action: "CREATE_PRODUCT", ...formData }).then(res => res.data),
  
  // update producer
  updateProduct: ({ formData }) => ApiPostForm("/admin/aqua/farming-products", { action: "UPDATE_PRODUCT", ...formData }).then(res => res.data),
  
  // delete a product
  deleteProduct: ({ productId }) => ApiPostForm("/admin/aqua/farming-products", { action: "DELETE_PRODUCT", product_id: productId }).then(res => res.data),

};

export default AdminServices;