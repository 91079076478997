import { Box, LinearProgress, Stack, colors } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { PondManagerServices } from "api/pondManagerServices";
import { VerticalAlertBoxCollections } from "components/Alerts/AlertBox";
import HighchartsWrapper from "components/Highcharts/HighchartsWrapper";
import { SatEyeLogoBlack } from "components/Images";
import { getText } from "components/text/Text";
import Formatters from "helpers/Formatters";
import NumericFormatters from "helpers/NumericFormatters";
import _ from "lodash";
import { DateTime } from "luxon";
import { spatialData } from "mock/spatialData";
import moment from "moment";
import { useEffect, useState } from "react";
import { AppSelectors } from "redux/AppReducers";
import { cycleManagementHelpers } from "redux/pondManagement/cycleManagement";
import palette from "themes/palette";
import WidgetCard from "ui/Card/WidgetCard";
import MuiTabs from "ui/tabs/MuiTabs";

const pageIndices = {
  biological: "biological",
  do: "do",
  water_temp: "water_temp",
  salnility: "salnility",
  salinity: "salinity",
  phosphorous: "phosphorous",
  nitrogen: "nitrogen",
  turbidity: "turbidity",
  suspended_solid: "suspended_solid",
  organic_matter: "organic_matter",
  chlorophyle: "chlorophyle",
  conductivity: "conductivity",
};

const TableComponent = ({ data, pageIndex }) => {
  if (!data) return null;
  const tableConfigs = {
    rowHeight: 25,
    headerHeight: 30,
    columnDefs: [
      {
        field: "datetime",
        headerName: "Datetime",
        flex: 3,
      },
      // {
      //   field: "gis_biomass",
      //   headerName: "BM",
      //   flex: 1,
      //   cellRenderer: ({ value }) => NumericFormatters.format({ value }),
      //   cellStyle: {
      //     color: pageIndex === pageIndices.biological && colors.purple[600],
      //     fontWeight: 800,
      //   },
      // },
      {
        field: "gis_water_temp",
        headerName: "WT",
        flex: pageIndex === pageIndices.water_temp ? 2 : 1,
        cellRenderer: ({ value }) => NumericFormatters.format({ value }),
        cellStyle: {
          color: pageIndex === pageIndices.water_temp && colors.purple[600],
          fontWeight: 800,
        },
      },
      {
        field: "gis_do",
        headerName: "DO",
        flex: pageIndex === pageIndices.do ? 2 : 1,
        cellRenderer: ({ value }) => NumericFormatters.format({ value }),
        cellStyle: {
          color: pageIndex === pageIndices.do && colors.purple[600],
          fontWeight: 800,
        },
      },
      {
        field: "gis_salinity",
        headerName: "SAL",
        flex: 1,
        cellRenderer: ({ value }) => NumericFormatters.format({ value }),
        cellStyle: {
          color: pageIndex === pageIndices.phosphorous && colors.purple[600],
          fontWeight: 800,
        },
      },
      {
        field: "gis_phosphorous",
        headerName: "P",
        flex: 1,
        cellRenderer: ({ value }) => NumericFormatters.format({ value }),
        cellStyle: {
          color: pageIndex === pageIndices.phosphorous && colors.purple[600],
          fontWeight: 800,
        },
      },
      {
        field: "gis_nitrogen",
        headerName: "N",
        flex: 1,
        cellRenderer: ({ value }) => NumericFormatters.format({ value }),
        cellStyle: {
          color: pageIndex === pageIndices.nitrogen && colors.purple[600],
          fontWeight: 800,
        },
      },
      {
        field: "gis_turbidity",
        headerName: "TB",
        flex: 1,
        cellRenderer: ({ value }) => NumericFormatters.format({ value }),
        cellStyle: {
          color: pageIndex === pageIndices.turbidity && colors.purple[600],
          fontWeight: 800,
        },
      },
      {
        field: "gis_suspended_solids",
        headerName: "SS",
        flex: 1,
        cellRenderer: ({ value }) => NumericFormatters.format({ value }),
        cellStyle: {
          color: pageIndex === pageIndices.suspended_solid && colors.purple[600],
          fontWeight: 800,
        },
      },
      {
        field: "gis_organic_matter",
        headerName: "OM",
        flex: 1,
        cellRenderer: ({ value }) => NumericFormatters.format({ value }),
        cellStyle: {
          color: pageIndex === pageIndices.organic_matter && colors.purple[600],
          fontWeight: 800,
        },
      },
      {
        field: "gis_chlorophyle",
        headerName: "Chl",
        flex: 1,
        cellRenderer: ({ value }) => NumericFormatters.format({ value }),
        cellStyle: {
          color: pageIndex === pageIndices.chlorophyle && colors.purple[600],
          fontWeight: 800,
        },
      },
      {
        field: "gis_conductivity",
        headerName: "COND",
        flex: 1,
        cellRenderer: ({ value }) => NumericFormatters.format({ value }),
        cellStyle: {
          color: pageIndex === pageIndices.chlorophyle && colors.purple[600],
          fontWeight: 800,
        },
      },
    ],
    rowData: data.filter((ele) => ele.gis_water_temp || ele.gis_do),
  };

  return (
    <Box
      className="ag-theme-clean"
      sx={{
        height: 300,
      }}
    >
      <AgGridReact {...tableConfigs} />
    </Box>
  );
};

const generateChartConfigs = {
  pair: ({ title, cycle, observedSeries, spatialSeries, forecastSeries }) => {
    const cycleStartDate = cycle.start_date;
    const cycleEndDate = cycle.end_date;

    const configs = {
      chart: {
        animation: {
          duration: 0,
        },
        height: 300,
      },
      title: {
        text: "",
      },
      xAxis: {
        type: "datetime",
        crosshair: true,
        min: DateTime.fromISO(cycleStartDate).valueOf(),
        max: DateTime.fromISO(cycleEndDate).valueOf(),
      },
      yAxis: {
        crosshair: true,
        title: {
          text: title,
        },
      },
      legend: {
        enabled: true,
      },
      plotOptions: {
        series: {
          animation: {
            duration: 0,
          },
        },
      },
      series: [
        // {
        //   name: "Observed",
        //   type: "line",
        //   data: observedSeries
        //     .filter((ele) => ele.value)
        //     .map((ele) => ({
        //       x: DateTime.fromFormat(ele.datetime, "yyyy-MM-dd hh:mm:ss").valueOf(),
        //       y: ele.value,
        //     })),
        //   color: colors.orange[500],
        //   connectNulls: true,
        //   visible: true,
        // },
        // {
        //   name: "harvest biomass",
        //   type: "column",
        //   color: colors.red[500],
        //   pointWidth: 10,
        //   data: biomassSeries.map((ele) => ({
        //     x: moment(ele.datetime).valueOf(),
        //     y: ele.harvest_biomass,
        //   })),
        //   visible: biomassSeries.length > 0,
        // },
        // {
        //   name: "forecasted",
        //   type: "line",
        //   color: colors.blue[500],
        //   pointWidth: 2,
        //   lineWidth: 1,
        //   data: biomassForecastTimeseries.map((ele) => ({
        //     x: moment(ele.date).valueOf(),
        //     y: ele.value,
        //   })),
        //   dashStyle: "ShortDash",
        //   visible: biomassForecastTimeseries.length > 0,
        // },
        {
          name: "Satellite Estimated",
          type: "line",
          color: palette.gis.main,
          marker: {
            enabled: true,
            radius: 3,
            symbol: "circle",
          },
          dashStyle: "ShortDash",
          lineWidth: 2,
          data: spatialSeries
            .filter((ele) => ele.value)
            .map((ele) => ({
              x: DateTime.fromFormat(ele.datetime, "yyyy-MM-dd hh:mm:ss").valueOf(),
              y: ele.value,
            })),
        },
      ],
      tooltip: {
        enabled: true,
      },
    };

    return configs;
  },
};

/**
 *
 * an interface visualising bionmass, water tempearture and dissolved oxygen data from Shrimpl GIS projects
 *
 * TODO:
 *  - table views
 *
 * @param {*} param0
 * @returns
 */
const SatEye = ({ farm, pond }) => {
  const [biomassChartConfigs, setBiomassChartConfigs] = useState(null);
  const [doChartConfigs, setDoChartConfigs] = useState(null);
  const [waterTempChartConfigs, setWaterTempChartConfigs] = useState(null);
  const [salnilityChartConfigs, setSalnilityChartConfigs] = useState(null);
  const [rows, setRows] = useState([]);

  const [pageIndex, setPageIndex] = useState(pageIndices.water_temp);

  const [loading, setLoading] = useState(false);

  const cycleStore = AppSelectors.cycleStore();
  const cycle = cycleManagementHelpers.getCurrentCycle({ pondId: pond?.id, cycle: cycleStore.selectedCycle, cycles: cycleStore.cycles });

  const Actions = {
    fetchData: async ({ pond, cycle }) => {
      setLoading(true);
      const cycleStartDate = cycle.start_date;
      const cycleEndDate = cycle.end_date;
      const data = await PondManagerServices.getTimeseriesData(
        pond.id,
        [
          "biomass",
          "dissolved_oxygen",
          "salinity",
          "water_temp",
          "gis_biomass",
          "gis_do",
          "gis_water_temp",
          "gis_salinity",
          "gis_phosphorous",
          "gis_nitrogen",
          "gis_turbidity",
          "gis_suspended_solids",
          "gis_organic_matter",
          "gis_chlorophyle",
          "gis_conductivity",
        ],
        cycleStartDate,
        cycleEndDate
      );
      const rows = data.rows;
      console.log(data);

      const biomassChartConfigs = generateChartConfigs.pair({
        observedSeries: rows.map((ele) => ({ value: ele.biomass, datetime: ele.datetime })),
        spatialSeries: rows.map((ele) => ({ value: ele.gis_biomass, datetime: ele.datetime })),
        forecastSeries: [],
        cycle: cycle,
        title: "Biomass",
      });
      setBiomassChartConfigs(biomassChartConfigs);

      const doChartConfigs = generateChartConfigs.pair({
        observedSeries: rows.map((ele) => ({ value: ele.dissolved_oxygen, datetime: ele.datetime })),
        spatialSeries: rows.map((ele) => ({ value: ele.gis_do, datetime: ele.datetime })),
        forecastSeries: [],
        cycle: cycle,
        title: "DO",
      });
      setDoChartConfigs(doChartConfigs);

      const waterTempChartConfigs = generateChartConfigs.pair({
        observedSeries: rows.map((ele) => ({ value: ele.water_temp, datetime: ele.datetime })),
        spatialSeries: rows.map((ele) => ({ value: ele.gis_water_temp, datetime: ele.datetime })),
        forecastSeries: [],
        cycle: cycle,
        title: "WT",
      });
      setWaterTempChartConfigs(waterTempChartConfigs);

      const salnilityChartConfigs = generateChartConfigs.pair({
        observedSeries: rows.map((ele) => ({ value: ele.salinity, datetime: ele.datetime })),
        spatialSeries: rows.map((ele) => ({ value: ele.gis_salinity, datetime: ele.datetime })),
        forecastSeries: [],
        cycle: cycle,
        title: "Salinity",
      });
      setSalnilityChartConfigs(salnilityChartConfigs);

      setRows(rows);

      // // generate table configs
      // const biomassTableConfigs = generateTableConfigs.biomass({
      //   biomassSeries: [],
      //   spatialBiomassSeries: dbGisData.map((ele) => ({ ...ele, value: ele.gis_biomass, date: ele.datetime })),
      //   biomassForecastTimeseries: [],
      // });
      // setBiomassTableConfigs(biomassTableConfigs);

      // // generate DO configs
      // const doChartConfigs = generateChartConfigs.do({
      //   spatialSeries: dbGisData.map((ele) => ({ datetime: ele.datetime, value: ele?.gis_do })),
      // });
      // setDoChartConfigs(doChartConfigs);

      // // generate DO configs
      // const waterTempChartConfigs = generateChartConfigs.waterTemp({
      //   spatialSeries: dbGisData.map((ele) => ({ datetime: ele.datetime, value: ele?.gis_water_temp })),
      // });
      // setWaterTempChartConfigs(waterTempChartConfigs);

      setLoading(false);
    },
  };

  useEffect(() => {
    if (farm && pond && cycle && cycle.pond_id === pond.id) {
      Actions.fetchData({ pond, cycle });
    }
  }, [farm, pond, cycle]);

  // return alert if no pond selected
  if (!pond) return <VerticalAlertBoxCollections.NoPondSelected />;

  return (
    <Box p={2}>
      <Box>
        <img src={SatEyeLogoBlack} alt="" width={150} />
      </Box>
      <MuiTabs
        options={[
          // { label: "Biomass", value: pageIndices.biological },
          { label: "Water Temperature", value: pageIndices.water_temp },
          { label: "Dissolved Oxygen", value: pageIndices.do },
          // { label: "Salinity", value: pageIndices.salnility },
        ]}
        value={pageIndex}
        onNewValue={setPageIndex}
      />
      <Box height={2} my={1}>
        {loading && <LinearProgress />}
      </Box>
      <Stack spacing={2}>
        {pageIndex === pageIndices.biological && biomassChartConfigs && (
          <WidgetCard title={getText("interface.general.biomass")}>
            <HighchartsWrapper options={biomassChartConfigs} />
          </WidgetCard>
        )}
        {pageIndex === pageIndices.do && doChartConfigs && (
          <WidgetCard title={getText("fields.dissolved_oxygen")}>
            <HighchartsWrapper options={doChartConfigs} />
          </WidgetCard>
        )}
        {pageIndex === pageIndices.water_temp && waterTempChartConfigs && (
          <WidgetCard title={getText("fields.water_temp")}>
            <HighchartsWrapper options={waterTempChartConfigs} />
          </WidgetCard>
        )}
        {pageIndex === pageIndices.salnility && salnilityChartConfigs && (
          <WidgetCard title={getText("fields.salinity")}>
            <HighchartsWrapper options={salnilityChartConfigs} />
          </WidgetCard>
        )}
      </Stack>
      <Box mt={2}>
        <WidgetCard title={""}>
          <TableComponent data={rows} pageIndex={pageIndex} />
        </WidgetCard>
      </Box>
    </Box>
  );
};

export default SatEye;
