import { Box, colors, Grid, Stack, Typography } from "@mui/material";
import { PondManagerServices } from "api/pondManagerServices";
import { useEffect, useState } from "react";
import _ from "lodash";
import { AppSelectors } from "redux/AppReducers";
import LoadingBox from "components/Loading/LoadingBox";
import { ErrorAlertBox, VerticalAlertBoxCollections } from "components/Alerts/AlertBox";
import HighchartsWrapper from "components/Highcharts/HighchartsWrapper";
import MiniLabelAndValueCard from "ui/Card/MiniLabelAndValueCard";
import NumberFormat from "react-number-format";
import { spatialData } from "mock/spatialData";
import moment from "moment";
import Text, { getText } from "components/text/Text";
import Formatters from "helpers/Formatters";
import { getShrimpSizeCategoryByWeight, getWeightRangeFromCategory } from "helpers/Aqua";
import FarmShrimpUnitPricesForm from "screens/Aquaculture/components/Forms/FarmShrimpUnitPricesForm";
import BorderCard from "ui/Card/BorderCard";
import CommonForm, { FormFieldTypes } from "components/Form/CommonForm";
import WidgetCard from "ui/Card/WidgetCard";
import { cycleManagementHelpers } from "redux/pondManagement/cycleManagement";
import PondManagerAccessWrapper from "components/Wrapper/PondManagerAccessWrapper";
import { ResourcePolicies } from "screens/Aquaculture/components/AccessControl/ResourcePolicies";

const appendSpatialResult = (forecastResult, farmId, pondId, startDate, endDate) => {
  if (spatialData?.[farmId]?.aquosmic?.biomass?.data) {
    const data = spatialData?.[farmId]?.aquosmic?.biomass?.data?.filter((ele) => ele.pond_id === pondId)?.filter((ele) => moment(ele.date) >= moment(startDate) && moment(ele.date) <= moment(endDate));
    // find seeded date and insert doc
    const seeded_date = forecastResult?.biomass?.real.filter((ele) => ele.doc === 0)?.[0]?.date;
    if (seeded_date) {
      const forecastResultNew = {
        ...forecastResult,
        biomass: {
          ...forecastResult.biomass,
          sat: data.map((ele) => ({
            ...ele,
            doc: moment(ele.date).diff(moment(seeded_date), "days"),
          })),
        },
      };
      return forecastResultNew;
    }
  }
  return forecastResult;
};

const cutSurplusPrediction = (result, cycle) => {
  if (cycle?.ended && cycle?.end_date) {
    const result_ = {
      ...result,
      biomass: {
        ...result.biomass,
        pred: result.biomass.pred.filter((ele) => moment(ele.date) < moment(cycle.end_date).subtract(1, "d")),
      },
      biomass_with_harvest: {
        ...result.biomass_with_harvest,
        pred: result.biomass_with_harvest.pred.filter((ele) => moment(ele.date) < moment(cycle.end_date).subtract(1, "d")),
      },
      feed: {
        ...result.feed,
        pred: result.feed.pred.filter((ele) => moment(ele.date) < moment(cycle.end_date).subtract(1, "d")),
      },
      population: {
        ...result.population,
        pred: result.population.pred.filter((ele) => moment(ele.date) < moment(cycle.end_date).subtract(1, "d")),
      },
      weight: {
        ...result.weight,
        pred: result.weight.pred.filter((ele) => moment(ele.date) < moment(cycle.end_date).subtract(1, "d")),
      },
      cost: {
        pred: result?.cost?.pred.filter((ele) => moment(ele.date) < moment(cycle.end_date).subtract(1, "d")),
      },
    };
    return result_;
  }
  return result;
};

const commonChartConfigs = {
  getSizesBands: (timeseries) => {
    if (!timeseries) return [];
    const sizes = _.uniq(timeseries.map((ele) => ele.size).filter((ele) => ele));
    const configs = sizes.map((s, idx) => {
      const sizeName = s.split(": ")[0];
      const sizeWeightRange = getWeightRangeFromCategory(s);
      const filteredRows = timeseries.filter((ele) => ele.size === s);
      const label = `<div><b>${sizeName}</b>: ${sizeWeightRange}</div>`;
      return {
        color: idx % 2 == 0 ? colors.grey[100] : colors.grey[200],
        from: _.minBy(filteredRows, "doc")?.doc,
        to: _.maxBy(filteredRows, "doc")?.doc + 1,
        label: {
          text: label,
          rotation: 90,
          align: "left",
          x: 5,
          y: 5,
          useHTML: true,
          style: {
            fontSize: "10px",
            color: colors.grey[500],
          },
        },
        zIndex: 1,
      };
    });
    return configs;
  },
};

/**
 *
 *
 * Pond Forecast Mk2
 * author:  Claude Chen <claude@shrimpl.com>
 * created: JAN 2024
 *
 * @param {*} param0
 * @returns
 *
 *
 */
const PondForecastMk2 = ({ pond, farm }) => {
  const [forecastResult, setForecastResult] = useState({});
  const [profitResult, setProfitResult] = useState({});

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [formValues, setFormValues] = useState({
    cost_of_opportunity: 13,
  });

  const cycleStore = AppSelectors.cycleStore();
  const selectedCycle = cycleManagementHelpers.getCurrentCycle({ pondId: pond?.id, cycle: cycleStore.selectedCycle, cycles: cycleStore.cycles });

  const pondManagementStore = AppSelectors.pondManagementStore();
  const farmCostFields = pondManagementStore.fields.filter((ele) => ele.field_type === "farm" && ele.field_group === "Cashflow Expense");
  const { shrimpUnitPrices } = pondManagementStore;

  useEffect(() => {
    // if cycle is belong to the pond
    if (selectedCycle?.pond_id === pond?.id) {
      Actions.fetchBiologicalParametricModelResult();
    } else {
      setLoading(false);
    }
  }, [pond, selectedCycle]);

  /** trigger after forecast result or shrimp unit prices is updated */
  useEffect(() => {
    const profitTimeseries = forecastResult?.biomass?.pred?.map((ele) => {
      const doc = ele.doc;

      const biomass_ = ele.value;
      const weight_ = _.find(forecastResult?.weight?.pred, { doc: doc })?.value;
      const priceCat = getShrimpSizeCategoryByWeight(weight_)?.category;
      const price = shrimpUnitPrices?.[priceCat] || 0;
      const revenue = biomass_ * price;
      const cost = _.find(forecastResult?.cost?.pred, { doc: doc })?.total || 0;
      const netProfit = revenue - cost;

      const npv = netProfit / (1 + formValues["cost_of_opportunity"] / 100 / 365) ** ele.doc;

      const result = {
        doc: ele.doc,
        date: ele.date,
        revenue: revenue,
        cost: cost,
        netProfit: netProfit,
        roi: (netProfit - cost) / cost,
        biomass: biomass_,
        weight: weight_,
        size: priceCat,
        weightRange: getWeightRangeFromCategory(priceCat),
        npv: npv,
      };
      return result;
    });

    const maxRoiAt = _.maxBy(profitTimeseries, "roi");
    const maxNetProfitAt = _.maxBy(profitTimeseries, "npv");
    const maxNetProfitDoc = maxNetProfitAt?.doc;
    const maxNetProfitBiomass = _.find(forecastResult?.biomass?.pred, { doc: maxNetProfitDoc })?.value;

    const stats = {
      maxRoiAt,
      maxNetProfitAt,
      maxNetProfitDoc,
      maxNetProfitBiomass,
    };

    setProfitResult({
      timeseries: profitTimeseries,
      stats: stats,
    });
  }, [forecastResult, shrimpUnitPrices, formValues]);

  const Actions = {
    fetchBiologicalParametricModelResult: async () => {
      setError(null);
      setLoading(true);
      try {
        let result = await PondManagerServices.fetchBiologicalParametricModelResultV2({
          pondId: pond.id,
          dateFrom: selectedCycle?.start_date || "2022-06-01",
          dateTo: selectedCycle?.end_date || "2022-12-31",
        });

        if (!result) {
          setError("Unknown Error!");
          setForecastResult({});
        } else if (result.error) {
          setError(result.message);
          setForecastResult({});
        } else {
          // append Spatial Data
          result = appendSpatialResult(result, farm.farm_id, pond.id, selectedCycle?.start_date, selectedCycle?.end_date);

          // cut surplus prediction
          result = cutSurplusPrediction(result, selectedCycle);

          // console.log(result.water_temp.real);
          // console.log(result.water_temp.pred);
          // console.log(result);

          setForecastResult(result);
        }
      } catch (err) {
        setForecastResult({});
      } finally {
        setLoading(false);
      }
    },
  };

  if (loading) return <LoadingBox />;

  if (error) {
    return (
      <Box p={2}>
        <ErrorAlertBox title={error} />
      </Box>
    );
  }

  if (_.isEmpty(selectedCycle) && pond)
    return (
      <Box>
        <VerticalAlertBoxCollections.NoCycleSelected pondId={pond?.id} />
      </Box>
    );

  if (_.isEmpty(forecastResult)) return "";

  const chartConfigs = {
    chart1: {
      chart: {
        type: "line",
        height: "400px",
      },
      title: {
        text: getText("interface.general.biomass"),
        align: "left",
      },
      xAxis: {
        title: {
          text: "DOC",
        },
        gridLineWidth: 0,
        lineWidth: 1,
      },
      yAxis: {
        title: {
          text: "",
        },
      },
      plotOptions: {
        bar: {
          borderRadius: "50%",
          dataLabels: {
            enabled: true,
          },
          groupPadding: 0.1,
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: getText("interface.general.satellite-estimated"),
          type: "scatter",
          color: colors.purple[500],
          data: forecastResult?.biomass?.sat?.map((item) => ({
            x: item.doc,
            y: item.value,
          })),
          tooltip: {
            pointFormat: `DOC: <b>{point.x}</b><br/><strong>{point.y}</strong><br/>`,
          },
        },
        {
          name: getText("interface.general.biomass-forecast"),
          type: "line",
          dashStyle: "DashDot",
          color: colors.blue[300],
          data: forecastResult?.biomass?.pred?.map((item) => ({
            x: item.doc,
            y: item.value,
          })),
        },
        {
          name: getText("interface.general.biomass-forecast-post-harvest"),
          type: "area",
          color: colors.blue[500],
          backgroundColor: colors.green[50],
          data: forecastResult?.biomass_with_harvest?.pred?.map((item) => ({
            x: item.doc,
            y: item.value,
          })),
          fillOpacity: 0.2,
        },
        {
          name: getText("interface.general.harvest"),
          type: "column",
          color: colors.red[800],
          data: forecastResult?.harvest?.map((item) => ({
            x: item.doc,
            y: item.value,
          })),
        },
        {
          name: getText("interface.general.biomass-observed"),
          type: "scatter",
          color: colors.red[500],
          data: forecastResult?.biomass?.real?.map((item) => ({
            x: item.doc,
            y: item.value,
          })),
          marker: {
            symbol: "cross", // You can change the marker symbol type
            lineColor: null,
            lineWidth: 2,
            radius: 3,
          },
          tooltip: {
            pointFormat: `DOC: <b>{point.x}</b><br/><strong>{point.y}</strong><br/>`,
          },
        },
      ],
    },
    chart2: {
      chart: {
        type: "line",
        height: "300px",
      },
      title: {
        text: getText("interface.general.weight"),
        align: "left",
      },
      xAxis: {
        title: {
          text: "DOC",
        },
        gridLineWidth: 0,
        lineWidth: 1,
      },
      yAxis: {
        title: {
          text: "",
        },
      },
      plotOptions: {
        bar: {
          borderRadius: "50%",
          dataLabels: {
            enabled: true,
          },
          groupPadding: 0.1,
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: getText("interface.general.observed"),
          type: "scatter",
          color: colors.red[600],
          data: forecastResult?.weight?.real?.map((item) => ({
            x: item.doc,
            y: item.value,
          })),
          marker: {
            symbol: "cross", // You can change the marker symbol type
            lineColor: null,
            lineWidth: 2,
          },
          tooltip: {
            pointFormat: `DOC: <b>{point.x}</b><br/><strong>{point.y}</strong><br/>`,
          },
        },
        {
          name: getText("interface.general.forecast"),
          type: "line",
          color: colors.blue[600],
          data: forecastResult?.weight?.pred?.map((item) => ({
            x: item.doc,
            y: item.value,
          })),
        },
      ],
    },
    chart3: {
      chart: {
        type: "line",
        height: "300px",
      },
      title: {
        text: getText("interface.general.population"),
        align: "left",
      },
      xAxis: {
        title: {
          text: "DOC",
        },
        gridLineWidth: 0,
        lineWidth: 1,
      },
      yAxis: {
        title: {
          text: "",
        },
      },
      plotOptions: {
        bar: {
          borderRadius: "50%",
          dataLabels: {
            enabled: true,
          },
          groupPadding: 0.1,
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: getText("interface.general.observed"),
          type: "scatter",
          color: colors.red[600],
          data: forecastResult?.population?.real?.map((item) => ({
            x: item.doc,
            y: item.value,
          })),
          marker: {
            symbol: "cross", // You can change the marker symbol type
            lineColor: null,
            lineWidth: 2,
          },
          tooltip: {
            pointFormat: `DOC: <b>{point.x}</b><br/><strong>{point.y}</strong><br/>`,
          },
        },
        {
          name: getText("interface.general.forecast"),
          type: "line",
          color: colors.blue[600],
          data: forecastResult?.population?.pred?.map((item) => ({
            x: item.doc,
            y: item.value,
          })),
        },
      ],
    },
    chart4: {
      chart: {
        type: "line",
        height: "300px",
      },
      title: {
        text: getText("interface.general.feed"),
        align: "left",
      },
      xAxis: {
        title: {
          text: "DOC",
        },
        gridLineWidth: 0,
        lineWidth: 1,
      },
      yAxis: {
        title: {
          text: "",
        },
      },
      plotOptions: {
        bar: {
          borderRadius: "50%",
          dataLabels: {
            enabled: true,
          },
          groupPadding: 0.1,
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: getText("interface.general.observed"),
          type: "scatter",
          color: colors.red[600],
          data: forecastResult?.feed?.real?.map((item) => ({
            x: item.doc,
            y: item.value,
          })),
          marker: {
            symbol: "cross", // You can change the marker symbol type
            lineColor: null,
            lineWidth: 2,
          },
          tooltip: {
            pointFormat: `DOC: <b>{point.x}</b><br/><strong>{point.y}</strong><br/>`,
          },
        },
        {
          name: getText("interface.general.forecast"),
          type: "line",
          color: colors.blue[600],
          data: forecastResult?.feed?.pred?.map((item) => ({
            x: item.doc,
            y: item.value,
          })),
        },
      ],
    },
    profitChart: {
      chart: {
        type: "line",
        height: "500px",
      },
      title: {
        text: "PROFIT FORECAST",
        align: "left",
      },
      xAxis: {
        title: {
          text: "DOC",
        },
        gridLineWidth: 0,
        lineWidth: 1,
        crosshair: true,
        plotLines: [
          {
            color: colors.green[800], // Red
            width: 1,
            dashStyle: "Dash",
            value: profitResult?.stats?.maxNetProfitAt?.doc,
            zIndex: 99,
          },
        ],
        plotBands: commonChartConfigs.getSizesBands(profitResult?.timeseries),
      },
      yAxis: [
        {
          title: {
            text: "",
          },
          crosshair: true,
        },
        {
          opposite: true,
          title: {
            text: "ROI",
          },
          labels: {
            formatter: function () {
              return this.value + "%"; // Add % sign to the value
            },
          },
        },
        {
          opposite: true,
          title: {
            text: "ROI",
          },
        },
      ],
      plotOptions: {
        series: {
          animation: false,
        },
        column: {
          stacking: "normal",
        },
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        shared: true,
        positioner: function () {
          return { x: 70, y: 100 };
        },
      },
      series: [
        {
          name: "NPV",
          type: "spline",
          color: colors.green[900],
          data: profitResult?.timeseries?.map((item) => ({
            x: item.doc,
            y: item.npv,
          })),
          dashStyle: "LongDash",
          lineWidth: 1,
        },
        {
          name: "ROI",
          type: "spline",
          color: colors.cyan[600],
          data: profitResult?.timeseries?.map((item) => ({
            x: item.doc,
            y: item.roi * 100,
          })),
          dashStyle: "LongDash",
          lineWidth: 1,
          yAxis: 1,
        },
        {
          name: "Revenue",
          type: "line",
          color: colors.orange[500],
          dashStyle: "LongDash",
          data: profitResult?.timeseries?.map((item) => ({
            x: item.doc,
            y: item.revenue,
          })),
        },
        {
          name: "Net Profit",
          type: "area",
          color: colors.green[400],
          fillOpacity: 0.2,
          lineWidth: 1,
          data: profitResult?.timeseries?.map((item) => ({
            x: item.doc,
            y: item.netProfit,
          })),
        },
        {
          name: "Optimal Profit",
          type: "scatter",
          marker: {
            radius: 5, // Set the radius of scatter dots
            symbol: "circle",
          },
          color: colors.green[600],
          data: [
            {
              x: profitResult?.stats?.maxNetProfitAt?.doc,
              y: profitResult?.stats?.maxNetProfitAt?.npv,
            },
          ],
        },
        {
          name: "Feed Cost",
          type: "column",
          color: colors.blue[100],
          data: forecastResult?.cost?.pred?.map((item) => ({
            x: item.doc,
            y: item.feed * -1,
          })),
        },
        {
          name: "Other Cost",
          type: "column",
          color: colors.indigo[100],
          data: forecastResult?.cost?.pred?.map((item) => ({
            x: item.doc,
            y: item.other * -1,
          })),
        },
        {
          name: "Seed Cost",
          type: "column",
          color: colors.purple[100],
          data: forecastResult?.cost?.pred?.map((item) => ({
            x: item.doc,
            y: item.seed * -1,
          })),
        },
        {
          name: "Toal Cost",
          type: "line",
          color: colors.red[800],
          data: forecastResult?.cost?.pred?.map((item) => ({
            x: item.doc,
            y: item.total * -1,
          })),
        },
      ],
    },
  };

  const stats = {
    latestBiomass: _.last(forecastResult?.biomass?.real)?.value,
    totalBiomass: _.last(forecastResult?.biomass?.real)?.value + _.sumBy(forecastResult?.harvest, "value"),
    totalHarvested: _.sumBy(forecastResult?.harvest, "value"),
    latestWeight: _.last(forecastResult?.weight?.real)?.value,
  };

  const statsToDisplay = [
    {
      row: 0,
      label: getText("interface.general.cycle"),
      value: `${Formatters.toDateString(forecastResult?.stats?.real_time_range?.start)} ~ ${Formatters.toDateString(forecastResult?.stats?.real_time_range?.end)}`,
    },
    { row: 0, label: getText("interface.general.status"), value: `${selectedCycle?.ended ? getText("interface.general.ended") : getText("interface.general.active")}` },
    { row: 0, label: getText("interface.general.total-harvested"), value: <NumberFormat displayType="text" value={stats?.totalHarvested / 1000} decimalScale={2} suffix=" T" /> },
    { row: 0, label: "Cur. Biomass", value: <NumberFormat displayType="text" value={stats?.latestBiomass / 1000} decimalScale={2} suffix=" T" />, hide: selectedCycle?.ended },
    { row: 0, label: "Cur. Weight", value: <NumberFormat displayType="text" value={stats?.latestWeight} decimalScale={2} suffix=" g" />, hide: selectedCycle?.ended },
    { row: 0, label: "Cur. Size", value: getShrimpSizeCategoryByWeight(stats?.latestWeight)?.category, hide: selectedCycle?.ended },
    // forecast overview
    { row: 1, label: "Max Profit Date", value: `${Formatters.toDateString(profitResult?.stats?.maxNetProfitAt?.date)} (Day ${profitResult?.stats?.maxNetProfitAt?.doc})` },
    { row: 1, label: "Max Profit", value: Formatters.formatDollar({ value: profitResult?.stats?.maxNetProfitAt?.npv }) },
    { row: 1, label: "Max Profit Biomass", value: <NumberFormat displayType="text" value={profitResult?.stats?.maxNetProfitAt?.biomass / 1000} decimalScale={2} suffix=" T" /> },
  ];

  return (
    <Box p={2}>
      <Stack spacing={2}>
        <Box>
          <Typography variant="h5" fontWeight={600} color="primary">
            <Text>interface.general.pond-forecast</Text>
          </Typography>
        </Box>

        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <WidgetCard title={getText("interface.general.production")} subtitle={getText("interface.general.overview")} sx={{ height: "100%" }}>
                <Stack direction={"row"} spacing={1}>
                  {statsToDisplay
                    .filter((ele) => ele.row === 0)
                    .filter((ele) => !ele.hide)
                    .map((ele) => {
                      return <MiniLabelAndValueCard key={ele.label} label={ele.label} value={ele.value} />;
                    })}
                </Stack>
              </WidgetCard>
            </Grid>
            <Grid item xs={6}>
              <WidgetCard title={getText("interface.general.forecast")} subtitle={getText("interface.general.overview")} sx={{ height: "100%" }}>
                <Stack direction={"row"} spacing={1}>
                  {statsToDisplay
                    .filter((ele) => ele.row === 1)
                    .filter((ele) => !ele.hide)
                    .map((ele) => {
                      return <MiniLabelAndValueCard key={ele.label} label={ele.label} value={ele.value} />;
                    })}
                </Stack>
              </WidgetCard>
            </Grid>
          </Grid>
        </Box>

        <WidgetCard title={"Profit"} subtitle="Forecast">
          <Grid container spacing={2} mt={2}>
            <Grid item xs={8}>
              <HighchartsWrapper options={chartConfigs.profitChart} />
            </Grid>
            <Grid item xs={4}>
              <BorderCard p={2} mb={1}>
                <Box mb={2}>
                  <CommonForm
                    title="Advanced Settings"
                    fields={[
                      {
                        label: "Cost of Opportunity",
                        name: "cost_of_opportunity",
                        description: (
                          <Box>
                            <p>The Discount Risk Rate refers to the interest rate used to determine the present value of future cash flows.</p>
                            <p>It reflects the time value of money and the risk associated with achieving future cash flows.</p>
                            <p>Shrimpl uses DCF analysis to influence and manage risk by adjusting the discount rate to reflect different time and risk levels and optimise harvest time. </p>
                            <p styles={{ color: "red" }}>
                              <strong>Producers who prefer to take a less risky should increase the discount rate; farmers who have a high-risk tolerance can reduce the discount rate.</strong>
                            </p>
                          </Box>
                        ),
                        type: FormFieldTypes.slider,
                        options: {
                          min: 0,
                          max: 100,
                          step: 1,
                          valueLabelDisplay: "off",
                          valueLabelFormat: (val) => `${val}%`,
                          marks: [
                            { label: "0%", value: 0 },
                            { label: "100%", value: 100 },
                          ],
                        },
                      },
                    ]}
                    initialValues={formValues}
                    onSubmit={(formValues) => {
                      setFormValues(formValues);
                    }}
                  />
                </Box>
                <FarmShrimpUnitPricesForm farmId={farm.id} />
              </BorderCard>
            </Grid>
          </Grid>
        </WidgetCard>

        <WidgetCard>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <HighchartsWrapper options={chartConfigs.chart1} />
            </Grid>
            <Grid item xs={12} lg={4}>
              <HighchartsWrapper options={chartConfigs.chart2} />
            </Grid>
            <Grid item xs={12} lg={4}>
              <HighchartsWrapper options={chartConfigs.chart3} />
            </Grid>
            <Grid item xs={12} lg={4}>
              <HighchartsWrapper options={chartConfigs.chart4} />
            </Grid>
          </Grid>
        </WidgetCard>
      </Stack>
    </Box>
  );
};

export default PondForecastMk2;
