import { useState } from "react";
import { Stack, Box, LinearProgress } from "@mui/material";
import Text from "components/text/Text";
import PondBiologicalDashboard from "screens/Aquaculture/components/Dashboard/PondBiologicalDashboard";
import MuiTabs, { MuiTabsThemes } from "ui/tabs/MuiTabs";
import PondWaterQualityDashboard from "screens/Aquaculture/components/Dashboard/PondWaterQualityDashboard";
import PondSummaryDashboard from "screens/Aquaculture/components/Dashboard/PondSummaryDashboard";
import { Navigate } from "react-router-dom";
import PondFeedDashboard from "screens/Aquaculture/components/Dashboard/PondFeedDashboard";
import PondForecastDashboard from "screens/Aquaculture/components/Dashboard/PondForecastDashboard";
import PondSateyeDashboard from "screens/Aquaculture/components/Dashboard/PondSateyeDashboard";
import PondTreatmentDashboard from "screens/Aquaculture/components/Dashboard/PondTreatmentDashboard";
import PondAdditiveDashboard from "screens/Aquaculture/components/Dashboard/PondAdditiveDashboard";
import PondFinancialDashboard from "screens/Aquaculture/components/Dashboard/PondFinancialDashboard";
import { DashboardIcon, InsertChartIcon, ListIcon } from "components/Icons/MaterialIcons";
import { FaShrimp } from "react-icons/fa6";
import { MonetizationOn, Satellite, WaterDrop } from "@mui/icons-material";
import { GiChemicalDrop, GiMedicinePills, GiSwapBag } from "react-icons/gi";
import PondManagerAccessWrapper from "components/Wrapper/PondManagerAccessWrapper";
import { ResourcePolicies } from "screens/Aquaculture/components/AccessControl/ResourcePolicies";

const viewModes = {
  OVERVIEW: {
    name: <Text>interface.general.summary</Text>,
    value: 0,
    icon: <DashboardIcon />,
  },
  BIOLOGICAL: {
    name: <Text>interface.general.biological</Text>,
    value: 1,
    icon: <FaShrimp />,
  },
  WATER_QUALITY: {
    name: <Text>interface.general.water-quality</Text>,
    value: 2,
    icon: <WaterDrop />,
  },
  FEED: {
    name: <Text>interface.general.feed</Text>,
    value: 3,
    icon: <GiSwapBag />,
  },
  ADDITIVE: {
    name: <Text>interface.general.additive</Text>,
    value: 4,
    icon: <GiMedicinePills />,
  },
  TREATMENT: {
    name: <Text>interface.general.pond-treatment</Text>,
    value: 5,
    icon: <GiChemicalDrop />,
  },
  FINANCIAL: {
    name: <Text>interface.general.financial</Text>,
    value: 6,
    icon: <MonetizationOn />,
  },
  FORECAST: {
    name: <Text>interface.general.forecast</Text>,
    value: 7,
    icon: <InsertChartIcon />,
  },
  SATEYE: {
    name: <Text fallback={"SATEYE"}>interface.general.sateye</Text>,
    value: 8,
    icon: <Satellite />,
  },
};

/**
 * Pond Dashboard Index
 * - Pond biological dashboard
 * - Pond summary dashboard
 * - Pond water quality dashboard
 * - Pond feed dashboard
 * - Pond farming product dashboard
 *
 * @param {*} param0
 * @returns
 */
const PondDashboardView = ({ farm, pond, group, onSwitchToDataViewField }) => {
  // const [mode, setMode] = useState(viewModes.OVERVIEW.value);
  const [mode, setMode] = useState(viewModes.OVERVIEW.value);

  const initialPanelComponents = _.orderBy(_.values(viewModes), "value");

  const Pages = {
    PondSummaryDashboard: () => (
      <PondManagerAccessWrapper policies={[ResourcePolicies["DASHBOARD:FULL"], ResourcePolicies["DASHBOARD:SUMMARY"]]}>
        <PondSummaryDashboard pond={pond} farm={farm} onSwitchToDataViewField={onSwitchToDataViewField} />
      </PondManagerAccessWrapper>
    ),
    PondBiologicalDashboard: (
      <PondManagerAccessWrapper policies={[ResourcePolicies["DASHBOARD:FULL"], ResourcePolicies["DASHBOARD:BIOLOGICAL"]]}>
        <PondBiologicalDashboard pond={pond} farm={farm} />
      </PondManagerAccessWrapper>
    ),
    PondWaterQualityDashboard: () => (
      <PondManagerAccessWrapper policies={[ResourcePolicies["DASHBOARD:FULL"], ResourcePolicies["DASHBOARD:WATERQUALITY"]]}>
        <PondWaterQualityDashboard pond={pond} farm={farm} />
      </PondManagerAccessWrapper>
    ),
    PondFeedDashboard: () => (
      <PondManagerAccessWrapper policies={[ResourcePolicies["DASHBOARD:FULL"], ResourcePolicies["DASHBOARD:WATERQUALITY"]]}>
        <PondFeedDashboard pond={pond} farm={farm} />
      </PondManagerAccessWrapper>
    ),
    PondForecastDashboard: () => (
      <PondManagerAccessWrapper policies={[ResourcePolicies["DASHBOARD:FULL"], ResourcePolicies["DASHBOARD:FORECAST"]]}>
        <PondForecastDashboard pond={pond} farm={farm} />
      </PondManagerAccessWrapper>
    ),
    PondSateyeDashboard: () => (
      <PondManagerAccessWrapper policies={[ResourcePolicies["DASHBOARD:FULL"], ResourcePolicies["DASHBOARD:SATEYE"]]}>
        <PondSateyeDashboard pond={pond} farm={farm} />
      </PondManagerAccessWrapper>
    ),
    PondTreatmentDashboard: () => (
      <PondManagerAccessWrapper policies={[ResourcePolicies["DASHBOARD:FULL"], ResourcePolicies["DASHBOARD:FARMING_PRODUCT"]]}>
        <PondTreatmentDashboard pond={pond} farm={farm} />
      </PondManagerAccessWrapper>
    ),
    PondAdditiveDashboard: () => (
      <PondManagerAccessWrapper policies={[ResourcePolicies["DASHBOARD:FULL"], ResourcePolicies["DASHBOARD:FARMING_PRODUCT"]]}>
        <PondAdditiveDashboard pond={pond} farm={farm} />
      </PondManagerAccessWrapper>
    ),
    PondFinancialDashboard: () => (
      <PondManagerAccessWrapper policies={[ResourcePolicies["DASHBOARD:FULL"], ResourcePolicies["DASHBOARD:FARMING_PRODUCT"]]}>
        <PondFinancialDashboard key="PondFinancialDashboard" pond={pond} farm={farm} />
      </PondManagerAccessWrapper>
    ),
  };

  return (
    <Box>
      <Box px={2}>
        <MuiTabs
          value={mode}
          onNewValue={(val) => setMode(val)}
          options={initialPanelComponents.map((ele) => ({
            label: ele.name,
            value: ele.value,
            icon: ele.icon,
          }))}
          theme={MuiTabsThemes.chip}
        />
      </Box>
      <Stack spacing={2}>
        {/* SUMMARY */}
        {mode === viewModes.OVERVIEW.value && <Pages.PondSummaryDashboard />}
        {/* BIOLOGICAL */}
        {mode === viewModes.BIOLOGICAL.value && Pages.PondBiologicalDashboard}
        {/* WATER QUALITY */}
        {mode === viewModes.WATER_QUALITY.value && <Pages.PondWaterQualityDashboard />}
        {/* FEED */}
        {mode === viewModes.FEED.value && <Pages.PondFeedDashboard />}
        {/* ADDITIVE */}
        {mode === viewModes.ADDITIVE.value && <Pages.PondAdditiveDashboard />}
        {/* TREATMENT */}
        {mode === viewModes.TREATMENT.value && <Pages.PondTreatmentDashboard />}
        {/* FINANCIAL */}
        {mode === viewModes.FINANCIAL.value && (
          <PondManagerAccessWrapper policies={[ResourcePolicies["DASHBOARD:FULL"], ResourcePolicies["DASHBOARD:FINANCE"]]}>
            <PondFinancialDashboard key="PondFinancialDashboard" pond={pond} farm={farm} />
          </PondManagerAccessWrapper>
        )}
        {/* FORECAST */}
        {mode === viewModes.FORECAST.value && <Pages.PondForecastDashboard />}
        {/* SATEYE */}
        {mode === viewModes.SATEYE.value && <Pages.PondSateyeDashboard />}
      </Stack>
    </Box>
  );
};

export default PondDashboardView;
