import { Box, Stack, Typography } from "@mui/material";
import SimpleGeojsonMapView from "components/map/SimpleGeojsonMapView";
import Text from "components/text/Text";
import NumericFormatters from "helpers/NumericFormatters";
import React from "react";
import { AppSelectors } from "redux/AppReducers";
import DataExplorerSummary from "screens/Aquaculture/components/DataExplorer/DataExplorerSummary";
import MobileWidgetCard from "screens/Mobile/components/ui/MobileWidgetCard";

const MobilePondViewHome = ({ pond }) => {
  const cycleStore = AppSelectors.cycleStore();
  const selectedCycle = cycleStore?.selectedCycle?.id ? cycleStore?.selectedCycle : cycleStore?.latestCycles?.[pond?.id];

  return (
    <Stack p={1} spacing={1}>
      <MobileWidgetCard padding={0}>
        <Box
          sx={{
            position: "relative",
            height: 200,
            overflow: "hidden",
          }}
        >
          {pond?.boundary_geojson && (
            <SimpleGeojsonMapView
              geojson={{
                type: "FeatureCollection",
                features: pond?.boundary_geojson,
              }}
            />
          )}
        </Box>
      </MobileWidgetCard>
      <MobileWidgetCard title={<Text>interface.general.summary</Text>}>{pond && selectedCycle && <DataExplorerSummary pond={pond} cycle={selectedCycle} />}</MobileWidgetCard>
    </Stack>
  );
};

export default MobilePondViewHome;
